import { Nullable, StoreType } from './index';

export enum SearchDateTerms {
  // 0부터 시작하면 안됨! (null을 체크하는 부분이 있어 falsy로 걸리게됨)
  'TODAY' = 1,
  '3_DAYS',
  '7_DAYS',
  '14_DAYS',
  '30_DAYS',
  '90_DAYS',
  '1_WEEK',
  '1_MONTH',
  '3_MONTHS',
  '6_MONTHS',
  '2022_YEAR',
  '2023_YEAR',
  'LAST_YEAR',
  'THIS_YEAR',
  'UNTIL_2099',
  'UNLIMITED',
  'ALL',
  '1_YEAR',
  '2_YEAR',
  '3_YEAR',
  'TWO_YEARS_AGO',
  'THREE_YEARS_AGO',
}

export type ButtonObject = {
  name: string;
  onClick: () => void;
  disabled?: boolean;
  selected?: boolean;
};

export type CheckBoxFilterValue = number | string;
export type CheckBoxFilterOption<T = CheckBoxFilterValue> = {
  label: string;
  value: T;
};

export const STORE_TITLE = 'Store';
export const STORE_OPTIONS = [
  { label: 'Connect', value: StoreType.CONNECT },
  { label: 'MD Store', value: StoreType.MD },
];

export enum SearchFilterType {
  DATE_RANGE_PICKER = 'dateRangePicker',
  MONTH_PICKER = 'monthPicker',
  YEAR_PICKER = 'yearPicker',
  CHECKBOX_GROUP = 'checkboxGroup',
  STRING = 'string',
}

export type SearchFilterDefs = SearchFilterDef[];

export interface ShortcutButtonFilterType {
  key: SearchDateTerms;
  name: string;
  intervalDate: string[];
  selected?: boolean;
}

export interface SearchFilterDef {
  key: string;
  label: string;
  type: SearchFilterType;
  options?: CheckBoxFilterOption<unknown>[];
  placeholder?: string; // keyword 일때
  maxLength?: number; // keyword 일때
  searchOnEnter?: boolean; // keyword 일때
  shortcutButtonFilters?: ShortcutButtonFilterType[];
}

export type SearchFilterValue = { [key: string]: any };

export interface SearchDateRangeFilterProps {
  dateFrom: string;
  dateTo: string;
  dateTerm: Nullable<number>;
}

export interface PaginationAndSortProps {
  sort: number;
  page: number;
}

export type CombinedSearchFilterParams<T> = T & SearchDateRangeFilterProps & PaginationAndSortProps;

export const NOT_INCLUDED_DATE_TERMS = -1;
