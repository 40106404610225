import * as React from 'react';
import { ReactElement } from 'react';
import classnames from 'classnames';
import { mq } from '@closet-design-system/theme';
import {
  AnimationDirection,
  LayoutType,
} from 'types/marketplace/operation/mainBanner/mainBannerPreview';
import { fadeInEffect, getMediaQueryRangeValues } from 'modules/mainBannerPreview';
import styled, { Keyframes } from 'styled-components';

interface Props {
  layoutType: LayoutType;
  mainObjectPath: string;
}

const MainObjectLayout = ({ layoutType, mainObjectPath }: Props): ReactElement => {
  const isLeftImageLayout = layoutType === LayoutType.TEXT_RIGHT;
  const isRightImageLayout = layoutType === LayoutType.TEXT_LEFT;
  const isCenterImageLayout =
    layoutType === LayoutType.TEXT_CENTER || layoutType === LayoutType.IMAGE;
  const animationDirection = isRightImageLayout
    ? AnimationDirection.FROM_RIGHT
    : AnimationDirection.FROM_LEFT;

  return (
    <MainObjectArea
      className={classnames({
        left: isLeftImageLayout,
        right: isRightImageLayout,
      })}
    >
      <AnimationImage
        className={classnames({
          center: isCenterImageLayout,
        })}
        src={mainObjectPath}
        animationDirection={animationDirection}
        center={isCenterImageLayout}
      />
    </MainObjectArea>
  );
};

export default MainObjectLayout;

const mainObjectWrapperMaxWidth = mq()({
  maxWidth: getMediaQueryRangeValues({ s: '380px', m: '470px', l: '740px' }),
});

const MainObjectArea = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  &.right {
    right: 0;
    ${mainObjectWrapperMaxWidth}
  }
  &.left {
    left: 0;
    ${mainObjectWrapperMaxWidth}
  }
`;

interface AnimationImageStyle {
  animationDirection: AnimationDirection;
  center: boolean;
}

const fadeInAnimation = ({ animationDirection, center }: AnimationImageStyle): Keyframes => {
  if (animationDirection === AnimationDirection.FROM_LEFT) {
    const from = center ? '-59%' : '-9%';
    const to = center ? '-50%' : '0%';
    return fadeInEffect({ from, to });
  }

  return fadeInEffect({ from: '9%' });
};

const AnimationImage = styled.img<AnimationImageStyle>`
  width: auto;
  height: 100%;
  animation: ${({ animationDirection, center }) => fadeInAnimation({ animationDirection, center })}
    1s ease-out;
  animation-fill-mode: backwards;
  animation-delay: 1s;

  &.center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;
