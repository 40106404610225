import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import {
  PostCouponInformationParams,
  GetCouponListParams,
  DeleteAccountParams,
  GetCouponListResponse,
  GetLoginHistoryResponse,
  GetMemberGeneralInformationResponse,
  PostMemberCommentParams,
  GetCommentResponse,
  PutMemberCommentParams,
  GetCouponListParams as GetCommentListParams,
  GetCouponListParams as GetLoginHistoryParams,
  DeleteMemberCommentParams,
  DeleteAccountResponse,
  BillingAddressResponse,
  GetBillingAddressParams,
  UpdateBrandSettingParams,
  BrandSettingForm,
  BrandSettingResponse,
  GetBrandSettingParams,
  PutConnectUserStatusParams,
  ConnectUserStatus,
  ConnectEverywearStatus,
  GetEmailVerificationLinkResponse,
  GetEmailChangeHistoryResponse,
  ConnectUserType,
  PutConnectUserTypeParams,
  ConnectCommunityUserStatus,
  ConnectCommunityUserType,
} from 'types/member';
import { AxiosError } from 'axios';

export interface MemberDetailState {
  comment: {
    loading: boolean;
    data: GetCommentResponse;
    error: any;
  };
  detail: {
    loading: boolean;
    data: GetMemberGeneralInformationResponse;
    error: any;
  };
  emailVerificationLink: {
    loading: boolean;
    data: GetEmailVerificationLinkResponse | null;
    error: AxiosError | null;
  };
  billingHistory: {
    loading: boolean;
    data: any;
    error: any;
  };
  loginHistory: {
    loading: boolean;
    data: GetLoginHistoryResponse;
    error: any;
  };
  coupon: {
    loading: boolean;
    data: GetCouponListResponse;
    error: any;
  };
  plan: {
    loading: boolean;
    data: any;
    error: any;
  };
  brandSetting: {
    loading: boolean;
    data: BrandSettingResponse;
    error: any;
  };
  billingAddress: {
    loading: boolean;
    data: BillingAddressResponse;
    error: any;
  };
  // 임시로 운영팀 사용하도록 account delete 추가함.
  delete: {
    loading: boolean;
    data: any;
    error: any;
  };
  connectAccountInformation: {
    data: { status: number; everywearStatus: number | null };
    loading: boolean;
    error: any;
    everywearStatusLoading: boolean;
    everywearStatusError: any;
    userTypeLoading: boolean;
    userTypeError: any;
    communityUserTypeLoading: boolean;
    communityUserTypeError: any;
  };
  emailChangeHistory: {
    loading: boolean;
    data: GetEmailChangeHistoryResponse;
    error: any;
  };
}

const initialState: MemberDetailState = {
  comment: {
    loading: false,
    data: {
      currentPage: 0,
      items: [
        {
          adminScreen: '',
          commentText: '',
          histories: [], // todo
          id: '',
          keys: { userid: '' },
          partitionKey: '',
          tags: '', // todo
          writeAt: '',
          writeBy: '',
        },
      ],
      links: {}, // todo
      totalItems: 0,
      totalPages: 0,
    },
    error: null,
  },
  detail: {
    // TODO backend api schema 맞게 나중에 변경
    loading: false,
    data: {
      email: '',
      externalProviders: [],
      groupId: '',
      groupMembers: {
        collaborator: -1,
        viewer: -1,
        editor: -1,
      },
      groupName: '',
      isGroupTrialUsed: false,
      marketingAgree: false,
      marketingAgreeDate: '',
      memberStatus: '',
      name: '',
      registrationDate: '',
      status: 1,
      userid: -1,
      connectMarketingAgree: false,
      connectMarketingAgreeDate: '',
      connectUserStatus: ConnectUserStatus.ACTIVE,
      connectCommunityUserStatus: ConnectCommunityUserStatus.ACTIVE,
      connectUserType: ConnectUserType.GENERAL,
      connectCommunityUserType: ConnectCommunityUserType.GENERAL,
      connectUserSupplierTypes: [],
      connectBlockChainWalletAddress: null,
      everyWearCreatorStatus: ConnectEverywearStatus.INACTIVE,
      verifyToken: null,
    },
    error: null,
  },
  emailVerificationLink: {
    loading: false,
    data: {
      userId: -1,
      email: '',
      link: '',
    },
    error: null,
  },
  billingHistory: {
    loading: false,
    data: {},
    error: null,
  },
  loginHistory: {
    loading: false,
    data: {
      currentPage: 0,
      items: [],
      links: {}, // todo
      totalItems: 0,
      totalPages: 0,
    },
    error: null,
  },
  coupon: {
    loading: false,
    data: {
      currentPage: 0,
      items: [],
      links: {}, // todo
      totalItems: 0,
      totalPages: 0,
    },
    error: null,
  },
  plan: {
    loading: false,
    data: [],
    error: null,
  },
  delete: {
    loading: false,
    data: '',
    error: null,
  },
  brandSetting: {
    loading: false,
    data: {
      isBrand: false,
      showOnPage: false,
    },
    error: null,
  },
  billingAddress: {
    loading: false,
    data: {
      userId: -1,
      country: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      vatNumber: '',
    },
    error: null,
  },
  connectAccountInformation: {
    data: { status: 1, everywearStatus: null },
    loading: false,
    error: false,
    everywearStatusLoading: false,
    everywearStatusError: false,
    userTypeLoading: false,
    userTypeError: false,
    communityUserTypeLoading: false,
    communityUserTypeError: false,
  },
  emailChangeHistory: {
    loading: false,
    data: [],
    error: false,
  },
};

export const memberDetailSlice = createSlice({
  name: 'memberDetail',
  initialState,
  reducers: {
    getMemberDetail: (state: MemberDetailState, _action: PayloadAction<{ userId: string }>) => {
      state.detail.loading = true;
    },
    getMemberDetailSuccess: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.detail.loading = false;
      state.detail.data = action.payload;
    },
    getMemberDetailFailure: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.detail.loading = false;
      state.detail.error = action.payload;
    },
    getBillingHistory: (state: MemberDetailState) => {
      state.billingHistory.loading = true;
    },
    getBillingHistorySuccess: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.billingHistory.loading = false;
      state.billingHistory.data = action.payload;
    },
    getBillingHistoryFailure: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.billingHistory.loading = false;
      state.billingHistory.error = action.payload;
    },
    getCommentList: (state: MemberDetailState, _action: PayloadAction<GetCommentListParams>) => {
      state.comment.loading = true;
    },
    getCommentListSuccess: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.comment.loading = false;
      state.comment.data = action.payload;
    },
    getCommentListFailure: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.comment.loading = false;
      state.comment.error = action.payload;
    },
    addMemberComment: (
      state: MemberDetailState,
      _action: PayloadAction<PostMemberCommentParams>,
    ) => {
      state.comment.loading = true;
    },
    addMemberCommentSuccess: (state: MemberDetailState, _action: PayloadAction<any>) => {
      state.comment.loading = false;
    },
    addMemberCommentFailure: (state: MemberDetailState, _action: PayloadAction<any>) => {
      state.comment.loading = false;
    },
    getLoginHistory: (state: MemberDetailState, _action: PayloadAction<GetLoginHistoryParams>) => {
      state.loginHistory.loading = true;
    },
    getLoginHistorySuccess: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.loginHistory.loading = false;
      state.loginHistory.data = action.payload;
    },
    getLoginHistoryFailure: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.loginHistory.loading = false;
      state.loginHistory.error = action.payload;
    },
    getCouponList: (state: MemberDetailState, _action: PayloadAction<GetCouponListParams>) => {
      state.coupon.loading = true;
    },
    getCouponListSuccess: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.coupon.loading = false;
      state.coupon.data = action.payload;
    },
    getCouponListFailure: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.coupon.loading = false;
      state.coupon.error = action.payload;
    },
    addNewCoupon: (
      state: MemberDetailState,
      _action: PayloadAction<PostCouponInformationParams>,
    ) => {
      state.coupon.loading = true;
    },
    addNewCouponSuccess: (state: MemberDetailState, _action: PayloadAction<any>) => {
      state.coupon.loading = false;
    },
    addNewCouponFailure: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.coupon.loading = false;
      state.coupon.error = action.payload;
    },
    getPricingPlan: (state: MemberDetailState) => {
      state.plan.loading = true;
    },
    getPricingPlanSuccess: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.plan.loading = false;
      state.plan.data = action.payload;
    },
    getPricingPlanFailure: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.plan.loading = false;
      state.plan.error = action.payload;
    },
    updateMemberComment: (
      state: MemberDetailState,
      _action: PayloadAction<PutMemberCommentParams>,
    ) => {
      state.comment.loading = true;
    },
    updateMemberCommentSuccess: (state: MemberDetailState, _action: PayloadAction<any>) => {
      state.comment.loading = false;
    },
    updateMemberCommentFailure: (state: MemberDetailState, _action: PayloadAction<any>) => {
      state.comment.loading = false;
    },
    deleteMemberComment: (
      state: MemberDetailState,
      _action: PayloadAction<DeleteMemberCommentParams>,
    ) => {
      state.comment.loading = true;
    },
    deleteMemberCommentSuccess: (state: MemberDetailState, _action: PayloadAction<any>) => {
      state.comment.loading = false;
    },
    deleteMemberCommentFailure: (state: MemberDetailState, _action: PayloadAction<any>) => {
      state.comment.loading = false;
    },
    //임시로 운영팀 사용하도록 account delete 추가함.
    deleteAccount: (state: MemberDetailState, _action: PayloadAction<DeleteAccountParams>) => {
      state.delete.loading = true;
      state.delete.data = false;
    },
    deleteAccountSuccess: (
      state: MemberDetailState,
      action: PayloadAction<DeleteAccountResponse>,
    ) => {
      state.delete.loading = false;
      state.delete.data = action.payload.data;
    },
    deleteAccountFailure: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.delete.loading = false;
      state.delete.data = action.payload;
    },
    resetBrandSetting: (state: MemberDetailState) => {
      state.brandSetting.loading = false;
      state.brandSetting.data = initialState.brandSetting.data;
    },
    getBrandSetting: (state: MemberDetailState, _action: PayloadAction<GetBrandSettingParams>) => {
      state.brandSetting.loading = true;
    },
    getBrandSettingSuccess: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.brandSetting.loading = false;
      state.brandSetting.data = action.payload;
    },
    getBrandSettingFailure: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.brandSetting.loading = false;
      state.brandSetting.error = action.payload;
    },
    updateBrandSetting: (
      state: MemberDetailState,
      _action: PayloadAction<UpdateBrandSettingParams<BrandSettingForm>>,
    ) => {
      state.brandSetting.loading = true;
    },
    updateBrandSettingSuccess: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.brandSetting.loading = false;
      state.brandSetting.data = action.payload;
    },
    updateBrandSettingFailure: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.brandSetting.loading = false;
      state.brandSetting.error = action.payload;
    },
    resetBillingAddress: (state: MemberDetailState) => {
      state.billingAddress.loading = false;
      state.billingAddress.data = initialState.billingAddress.data;
    },
    getBillingAddress: (
      state: MemberDetailState,
      _action: PayloadAction<GetBillingAddressParams>,
    ) => {
      state.billingAddress.loading = true;
    },
    getBillingAddressSuccess: (
      state: MemberDetailState,
      action: PayloadAction<BillingAddressResponse>,
    ) => {
      state.billingAddress.loading = false;
      state.billingAddress.data = action.payload;
    },
    getBillingAddressFailure: (state: MemberDetailState, action: PayloadAction<any>) => {
      state.billingAddress.loading = false;
      state.billingAddress.error = action.payload;
    },
    updateConnectUserStatus: (
      state: MemberDetailState,
      _action: PayloadAction<PutConnectUserStatusParams>,
    ) => {
      state.connectAccountInformation.loading = true;
      state.connectAccountInformation.error = false;
    },
    updateConnectUserStatusSuccess: (state: MemberDetailState) => {
      state.connectAccountInformation.loading = false;
      state.connectAccountInformation.error = false;
    },
    updateConnectUserStatusFailure: (state: MemberDetailState) => {
      state.connectAccountInformation.loading = false;
      state.connectAccountInformation.error = true;
    },
    updateConnectCommunityUserStatus: (
      state: MemberDetailState,
      _action: PayloadAction<PutConnectUserStatusParams>,
    ) => {
      state.connectAccountInformation.loading = true;
      state.connectAccountInformation.error = false;
    },
    updateConnectCommunityUserStatusSuccess: (state: MemberDetailState) => {
      state.connectAccountInformation.loading = false;
      state.connectAccountInformation.error = false;
    },
    updateConnectCommunityUserStatusFailure: (state: MemberDetailState) => {
      state.connectAccountInformation.loading = false;
      state.connectAccountInformation.error = true;
    },
    updateEverywearStatus: (
      state: MemberDetailState,
      _action: PayloadAction<PutConnectUserStatusParams>,
    ) => {
      state.connectAccountInformation.everywearStatusLoading = true;
      state.connectAccountInformation.everywearStatusError = false;
    },
    updateEverywearStatusSuccess: (state: MemberDetailState) => {
      state.connectAccountInformation.everywearStatusLoading = false;
      state.connectAccountInformation.everywearStatusError = false;
    },
    updateEverywearStatusFailure: (state: MemberDetailState) => {
      state.connectAccountInformation.everywearStatusLoading = false;
      state.connectAccountInformation.everywearStatusError = true;
    },
    updateUserType: (
      state: MemberDetailState,
      _action: PayloadAction<PutConnectUserTypeParams>,
    ): void => {
      state.connectAccountInformation.userTypeLoading = true;
    },
    updateUserTypeSuccess: (state: MemberDetailState): void => {
      state.connectAccountInformation.userTypeLoading = false;
      state.connectAccountInformation.userTypeError = false;
    },
    updateUserTypeFailure: (state: MemberDetailState): void => {
      state.connectAccountInformation.userTypeLoading = false;
      state.connectAccountInformation.userTypeError = true;
    },
    updateCommunityUserType: (
      state: MemberDetailState,
      _action: PayloadAction<PutConnectUserTypeParams>,
    ) => {
      state.connectAccountInformation.communityUserTypeLoading = true;
    },
    updateCommunityUserTypeSuccess: (state: MemberDetailState) => {
      state.connectAccountInformation.communityUserTypeLoading = false;
      state.connectAccountInformation.communityUserTypeError = false;
    },
    updateCommunityUserTypeFailure: (state: MemberDetailState) => {
      state.connectAccountInformation.communityUserTypeLoading = false;
      state.connectAccountInformation.communityUserTypeError = true;
    },
    getEmailVerificationLink: (
      state: MemberDetailState,
      _action: PayloadAction<{ userId: number }>,
    ) => {
      state.emailVerificationLink.loading = true;
    },
    getEmailVerificationEmailSuccess: (
      state: MemberDetailState,
      action: PayloadAction<GetEmailVerificationLinkResponse>,
    ) => {
      state.emailVerificationLink.loading = false;
      state.emailVerificationLink.data = action.payload;
    },
    getEmailVerificationEmailFailure: (state: MemberDetailState) => {
      state.emailVerificationLink.loading = false;
      state.emailVerificationLink.data = null;
    },
    getEmailChangeHistory: (
      state: MemberDetailState,
      _action: PayloadAction<{ userId: string }>,
    ) => {
      state.emailChangeHistory.loading = true;
    },
    getEmailChangeHistorySuccess: (
      state: MemberDetailState,
      action: PayloadAction<GetEmailChangeHistoryResponse>,
    ) => {
      state.emailChangeHistory.loading = false;
      state.emailChangeHistory.data = action.payload;
    },
    getEmailChangeHistoryFailure: (state: MemberDetailState) => {
      state.emailChangeHistory.loading = false;
      state.emailChangeHistory.data = [];
    },
  },
});

export const memberDetailReducer = memberDetailSlice.reducer;
export const memberDetailActions = memberDetailSlice.actions;

export const selectComment = (state: RootState) => state.member.memberDetail.comment.data;
export const selectMemberDetail = (state: RootState) => state.member.memberDetail.detail.data;
export const selectMemberDetailLoading = (state: RootState) =>
  state.member.memberDetail.detail.loading;
export const selectBillingHistory = (state: RootState) =>
  state.member.memberDetail.billingHistory.data;
export const selectAuthProvider = (state: RootState) =>
  state.member.memberDetail.detail.data.externalProviders;
export const selectLoginHistory = (state: RootState) => state.member.memberDetail.loginHistory.data;
export const selectCouponList = (state: RootState) => state.member.memberDetail.coupon.data;
export const selectPlan = (state: RootState) => state.member.memberDetail.plan.data;

export const selectCommentListLoading = (state: RootState) =>
  state.member.memberDetail.comment.loading;
export const selectLoginHistoryLoading = (state: RootState) =>
  state.member.memberDetail.loginHistory.loading;
export const selectCouponListLoading = (state: RootState) =>
  state.member.memberDetail.coupon.loading;

export const selectBrandSettingLoading = (state: RootState) =>
  state.member.memberDetail.brandSetting.loading;
export const selectBrandSettingData = (state: RootState) =>
  state.member.memberDetail.brandSetting.data;

export const selectBillingAddressData = (state: RootState) =>
  state.member.memberDetail.billingAddress.data;

export const selectConnectUserStatusLoading = (state: RootState): boolean =>
  state.member.memberDetail.connectAccountInformation.loading;
export const selectConnectEverywearStatusLoading = (state: RootState): boolean =>
  state.member.memberDetail.connectAccountInformation.everywearStatusLoading;
export const selectConnectUserStatusError = (state: RootState): boolean =>
  state.member.memberDetail.connectAccountInformation.error;
export const selectConnectUserTypeLoading = (state: RootState): boolean =>
  state.member.memberDetail.connectAccountInformation.userTypeLoading;
export const selectConnectCommunityUserTypeLoading = (state: RootState): boolean =>
  state.member.memberDetail.connectAccountInformation.communityUserTypeLoading;

// 임시로 운영팀 사용하도록 account delete 추가함.
export const selectDeleteAccountData = (state: RootState) => state.member.memberDetail.delete;
export const selectedIsDeletedAccount = (state: RootState) =>
  state.member.memberDetail.detail.data.status === -99;

export const selectEmailVerificationLinkLoadingStatus = (state: RootState) =>
  state.member.memberDetail.emailVerificationLink.loading;

export const selectEmailVerificationLinkData = (state: RootState) =>
  state.member.memberDetail.emailVerificationLink.data;

export const selectEmailChangeHistoryData = (state: RootState) =>
  state.member.memberDetail.emailChangeHistory.data;
export const selectEmailChangeHistoryLoading = (state: RootState) =>
  state.member.memberDetail.emailChangeHistory.loading;
