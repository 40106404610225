import { TabContainerContext } from 'components/common/tabs/TabContainer';
import * as React from 'react';
import { PropsWithChildren, useContext } from 'react';
import styled from 'styled-components';
import { TabValue } from 'types/common/tabs';

interface Props<T = TabValue> {
  value: T;
  spacing?: number;
}

const TabPanel = function <T = TabValue>({
  children,
  value,
  spacing = 4,
}: PropsWithChildren<Props<T>>) {
  const { selectedValue } = useContext(TabContainerContext);
  const isActive = value === selectedValue;

  return (
    <TabPanelWrapper isActive={isActive} spacing={spacing}>
      {isActive && children}
    </TabPanelWrapper>
  );
};

const TabPanelWrapper = styled.div<{ isActive: boolean; spacing: number }>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  margin: ${({ theme, spacing }) => theme.spacing(spacing)}px 0;
`;

export default TabPanel;
