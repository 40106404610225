import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentLayout from 'components/common/layouts/ContentLayout';
import { RouteComponentProps } from 'react-router';
import {
  backOrderActions,
  selectOrderInformationLoading,
  selectCommentListLoading,
  selectBackOrderGroupId,
} from 'features/member/reducer/backorderSlice';
import { GetOrderInformationParams } from 'types/member/backorder';
import styled from '@emotion/styled';

const BackOrder = ({ match }: RouteComponentProps<GetOrderInformationParams>) => {
  const {
    params: { userId },
  } = match;
  const dispatch = useDispatch();
  const orderInformationLoading = useSelector(selectOrderInformationLoading);
  const commentListLoading = useSelector(selectCommentListLoading);
  const groupId = useSelector(selectBackOrderGroupId);
  useEffect(() => {
    dispatch(backOrderActions.getOrderInformation({ userId }));
  }, []);

  useEffect(() => {
    if (!groupId) return;
    dispatch(backOrderActions.getCommentList({ groupId }));
  }, [groupId]);

  return (
    <ContentLayout pageTitle={'Back Order'} loading={orderInformationLoading || commentListLoading}>
      <TheEndBox>
        <img src="/static/img/the-end.png" alt="this page is closed" width={'30%'} />
      <div>
        <strong>
          2024. 11. 05 : 새로운 Pricing Plan을 도입하면서 이 페이지는 더 이상 사용하지 않습니다.
        </strong>
      </div>
      </TheEndBox>
    </ContentLayout>
  );
};

export default BackOrder;

const TheEndBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    margin: 40px;
`
