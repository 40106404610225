import * as React from 'react';
import InformationTable from 'components/common/table/InformationTable';
import { NATIONAL_CODE } from 'constants/common/nationalCode';
import { InformationObject } from 'types/common/informationTable';
import { BillingAddress } from 'types/plansales/billing';

interface Props {
  billingAddress: BillingAddress | null;
}

const defaultBillingAddress = {
  country: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  vatNumber: '',
};

const BillingAddressInformation = ({ billingAddress }: Props) => {
  const { country, address, address2, city, state, zipCode, vatNumber } =
    billingAddress ?? defaultBillingAddress;
  const billingAddressData: InformationObject[] = [
    {
      title: 'Country',
      render: country.length > 0 ? NATIONAL_CODE[country].label : '',
      requiredPoint: true,
      titleWidth: '30%',
    },
    {
      title: 'Address 1',
      render: address,
      requiredPoint: true,
      titleWidth: '30%',
    },
    {
      title: 'Address 2',
      render: address2,
      titleWidth: '30%',
    },
    {
      title: 'City',
      render: city,
      requiredPoint: true,
      titleWidth: '30%',
    },
    {
      title: 'State(US)',
      render: state,
      titleWidth: '30%',
    },
    {
      title: 'Zip Code',
      render: zipCode,
      requiredPoint: true,
      titleWidth: '30%',
    },
    {
      title: 'Vat Number (EU)',
      render: vatNumber,
      titleWidth: '30%',
    },
  ];
  return <InformationTable title={'Billing Address'} data={billingAddressData} />;
};

export default BillingAddressInformation;
