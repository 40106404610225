import * as React from 'react';
import { Hidden } from '@material-ui/core';
import ClosetSidebarContainer from 'containers/common/ClosetSidebarContainer';
import { RouteInfoType } from 'types/common/routes';
import styled from 'styled-components';

interface Props {
  routes: Array<RouteInfoType>;
  drawerWidth: number;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const ClosetDrawer = ({ routes, drawerWidth, mobileOpen, handleDrawerToggle }: Props) => {
  return (
    <Drawer drawerWidth={drawerWidth}>
      <Hidden mdUp implementation="js">
        <ClosetSidebarContainer
          routes={routes}
          PaperProps={{ style: { width: drawerWidth } }}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
        />
      </Hidden>
      <Hidden smDown implementation="css">
        <ClosetSidebarContainer routes={routes} PaperProps={{ style: { width: drawerWidth } }} />
      </Hidden>
    </Drawer>
  );
};

export default ClosetDrawer;

const Drawer = styled.div<{ drawerWidth: number }>`
  ${props => props.theme.breakpoints.up('md')} {
    width: ${props => props.drawerWidth}px;
    flex-shrink: 0;
  }
`;
