import { createApi } from '@reduxjs/toolkit/query/react';
import coreApiBaseQuery from 'api/coreApiBaseQuery';
import {
  OrderItemResponseData,
  OrderCouponDetailResponse,
  OrderShowMore,
} from 'types/api/marketplace/order';
import store from 'store/configureStore';
import { toastAction } from 'features/toast/toastSlice';
import { OrderDetailResponse, OrderRefundHistoryResponse } from 'types/api/marketplace/order';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { convertToSearchApiQueryString } from 'modules/confirmItem';

const ORDER_PATH = '/connects/orders';

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: coreApiBaseQuery(),
  endpoints(build) {
    return {
      getOrderDetail: build.query<OrderDetailResponse, string>({
        query: orderNo => {
          return {
            url: `${ORDER_PATH}/detail/${orderNo}`,
            method: 'get',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      getOrderItems: build.query<OrderItemResponseData, string>({
        query: queryString => ({
          url: `/connects/orders${queryString}`,
          method: 'get',
          onError: error => {
            store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
          },
        }),
      }),
      getOrderCouponDetail: build.query<OrderCouponDetailResponse, { orderNo: number }>({
        query: ({ orderNo }) => ({
          url: `/connects/orders/${orderNo}/coupon`,
          method: 'get',
          onError: error => {
            store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
          },
        }),
      }),
      getOrderShowMore: build.query<OrderShowMore, { orderNo: number }>({
        query: ({ orderNo }) => ({
          url: `/connects/orders/${orderNo}/showmore`,
          method: 'get',
          onError: error => {
            store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
          },
        }),
      }),
      requestOrderRefund: build.mutation<void, { orderNo: string; formData: FormData }>({
        query: ({ orderNo, formData }) => {
          return {
            url: `${ORDER_PATH}/${orderNo}/refund`,
            method: 'post',
            data: formData,
          };
        },
      }),
      getOrderRefund: build.query<OrderRefundHistoryResponse, string>({
        query: orderNo => {
          return {
            url: `${ORDER_PATH}/${orderNo}/refund`,
            method: 'get',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
    };
  },
});

export const {
  useGetOrderDetailQuery,
  useRequestOrderRefundMutation,
  useLazyGetOrderRefundQuery,
  useLazyGetOrderItemsQuery,
  useLazyGetOrderCouponDetailQuery,
  useLazyGetOrderShowMoreQuery,
} = orderApi;

export const useRefetchOrderApi = (
  invoiceNo: string,
): {
  refetchOrderDetail: (invoiceNo: string) => void;
  refetchOrderRefund: (invoiceNo: string) => void;
} => {
  const dispatch = useDispatch();

  const refetchOrderDetail = (): void => {
    dispatch(
      orderApi.endpoints?.getOrderDetail.initiate(`${invoiceNo}`, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
  };

  const refetchOrderRefund = (): void => {
    dispatch(
      orderApi.endpoints?.getOrderRefund.initiate(`${invoiceNo}`, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
  };

  return {
    refetchOrderDetail,
    refetchOrderRefund,
  };
};

export const useRefetchOrderItemApi = (): { refetchOrderItems: () => void } => {
  const dispatch = useDispatch();
  const history = useHistory();

  const queryString = convertToSearchApiQueryString(history.location.search);

  const refetchOrderItems = (): void => {
    dispatch(
      orderApi.endpoints.getOrderItems.initiate(`?${queryString}`, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
  };

  return {
    refetchOrderItems,
  };
};
