import dayjs from 'dayjs';
import {
  MidBanner,
  MidBannerFormData,
  MidBannerFormDataValidation,
  MidBannerLinkType,
  MidBannerStatus,
} from 'types/marketplace';
import { convertURLtoFile } from './utils/convert';

export function makeFormData(data: MidBannerFormData) {
  const { title, desktopImage, tabletImage, url, isNoLink, linkType, from, to } = data;
  const formData = new FormData();

  formData.set('Title', title);
  formData.set('DesktopImage', desktopImage as File);
  formData.set('TabletImage', tabletImage as File);

  if (isNoLink) {
    formData.set('UseLink', 'false');
  } else {
    formData.set('UseLink', 'true');
    formData.set('Link', url);
    formData.set('UseNewTab', linkType === MidBannerLinkType.NEW_TAB ? 'true' : 'false');
  }

  formData.set('FromDateTime', from.toUTCString());
  formData.set('ToDateTime', to.toUTCString());

  return formData;
}

export async function parseMidBanner({
  title,
  desktopImageUrl,
  tabletImageUrl,
  link,
  useLink,
  toDateTime,
  fromDateTime,
  useNewtab,
  status,
}: MidBanner): Promise<MidBannerFormData> {
  const [desktopImage, tabletImage] = await Promise.all([
    convertURLtoFile(desktopImageUrl),
    convertURLtoFile(tabletImageUrl),
  ]);

  return {
    title,
    desktopImage,
    tabletImage,
    url: link,
    isNoLink: !useLink,
    linkType: useNewtab ? MidBannerLinkType.NEW_TAB : MidBannerLinkType.CURRENT,
    isShowImmediately: false,
    to: new Date(toDateTime),
    from: new Date(fromDateTime),
    status,
  };
}

export function getInitialMidBannerFromData(): MidBannerFormData {
  // 분 단위를 버림하여, 10분 단위로 맞춤
  const from = new Date();
  from.setMinutes(Math.floor(from.getMinutes() / 10) * 10);
  // from 1주일 후
  const to = dayjs(from).add(1, 'weeks').toDate();

  return {
    title: '',
    desktopImage: null,
    tabletImage: null,
    isNoLink: false,
    url: '',
    linkType: MidBannerLinkType.CURRENT,
    isShowImmediately: false,
    from,
    to,
    status: MidBannerStatus.SHOW,
  };
}

export function getInitialMidBannerFromDataValidation(): MidBannerFormDataValidation {
  return {
    title: false,
    desktopImage: false,
    tabletImage: false,
    link: false,
    from: false,
    to: false,
  };
}

export function validateFormData({
  title,
  desktopImage,
  tabletImage,
  isNoLink,
  url,
  isShowImmediately,
  from,
  to,
}: MidBannerFormData): MidBannerFormDataValidation {
  const validation = getInitialMidBannerFromDataValidation();
  validation.title = !title;
  validation.desktopImage = !desktopImage;
  validation.tabletImage = !tabletImage;
  validation.link = !isNoLink && !url;
  validation.from = !isShowImmediately && !from;
  validation.to = !to;

  return validation;
}

export function checkMidBannerFormDataValidation({
  title,
  desktopImage,
  tabletImage,
  link,
  from,
  to,
}: MidBannerFormDataValidation): boolean {
  return title || desktopImage || tabletImage || link || from || to;
}
