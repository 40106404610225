import * as React from 'react';
import { PropsWithChildren, ReactElement, useEffect, useState, WheelEvent } from 'react';
import { Helmet } from 'react-helmet';
import { Typography, CircularProgress as MuiCircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { Divider } from 'components/common/Divider';
import { KeyboardArrowUp } from '@material-ui/icons';
import CommonButton from 'components/common/Button';
const CircularProgress = styled(MuiCircularProgress)(spacing);

interface Props {
  pageTitle?: string;
  loading?: boolean;
  error?: Error | null;
  scrollTop?: boolean;
}

const ContentStatus = ({
  error,
  loading,
  children,
}: PropsWithChildren<{ error?: Error | null; loading?: boolean }>): ReactElement => {
  if (error)
    return (
      <Typography variant="h5" gutterBottom display="block">
        {error.message}
      </Typography>
    );

  if (loading)
    return (
      <Loading>
        <CircularProgress />
      </Loading>
    );

  return <>{children}</>;
};

const ContentLayout: React.FC<Props> = ({
  children,
  pageTitle,
  loading = false,
  error,
  scrollTop,
}): ReactElement => {
  const [showScrollTop, setShowScrollTop] = useState<boolean>(false);

  const handleMouseWheel = (e: WheelEvent<HTMLDivElement>): void => {
    const isMouseScrollUp = e.deltaY < 0;
    setShowScrollTop(!isMouseScrollUp);
  };

  const onClickScrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // react router link 이동시 scroll position 유지되는 현상 방지
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet title={pageTitle} />
      <GridWrapper onWheel={scrollTop ? handleMouseWheel : undefined}>
        <Typography variant="h3" gutterBottom display="inline">
          {pageTitle}
        </Typography>
        <Divider my={3} />
        <ContentStatus loading={loading} error={error}>
          {children}
        </ContentStatus>
        {showScrollTop && (
          <ScrollUpWrapper>
            <Button variant={'contained'} color={'secondary'} onClick={onClickScrollTop}>
              <KeyboardArrowUp style={{ color: 'white', fontSize: '32px' }} />
            </Button>
          </ScrollUpWrapper>
        )}
      </GridWrapper>
    </>
  );
};

export default ContentLayout;

const GridWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 1200px;
`;

const Loading = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f7f9fcd9;
  z-index: 1;
`;

const ScrollUpWrapper = styled.div`
  position: fixed;
  z-index: 10000;
  right: 28px;
  bottom: 80px;
`;

const Button = styled(CommonButton)`
  min-width: auto;
  padding: 0;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  opacity: 0.8;
`;
