import * as React from 'react';
import { ReactElement } from 'react';
import { BaseTabPanel } from '@closet-design-system/core';
import ContestDetailTabs from 'components/marketplace/operation/contest/preview/ContestDetailTabs';
import { PREVIEW_TAB_VALUES } from 'constants/marketplace/contest';
import ContestInformationTab from 'components/marketplace/operation/contest/preview/ContestInformationTab';
import ContestAwardsTab from 'components/marketplace/operation/contest/preview/ContestAwardsTab';

interface Props {
  activeTab: string;
}

const ContestPreviewDetailTabsContainer = ({ activeTab }: Props): ReactElement => {
  const tabs = [
    {
      label: PREVIEW_TAB_VALUES.INFORMATION,
      value: PREVIEW_TAB_VALUES.INFORMATION,
    },
    {
      label: PREVIEW_TAB_VALUES.MY_WORKS,
      value: PREVIEW_TAB_VALUES.MY_WORKS,
    },
    {
      label: PREVIEW_TAB_VALUES.SUBMISSIONS,
      value: PREVIEW_TAB_VALUES.SUBMISSIONS,
    },
    {
      label: PREVIEW_TAB_VALUES.AWARDS,
      value: PREVIEW_TAB_VALUES.AWARDS,
    },
  ];

  return (
    <ContestDetailTabs tabs={tabs} activeTabValue={activeTab}>
      <BaseTabPanel value={PREVIEW_TAB_VALUES.INFORMATION}>
        <ContestInformationTab />
      </BaseTabPanel>
      <BaseTabPanel value={PREVIEW_TAB_VALUES.AWARDS}>
        <ContestAwardsTab />
      </BaseTabPanel>
    </ContestDetailTabs>
  );
};

export default ContestPreviewDetailTabsContainer;
