import * as React from 'react';
import { ReactElement } from 'react';
import BasicModal from '../../common/modal/BasicModal';
import styled from 'styled-components';
import CommonButton from '../../common/Button';
import { spacing } from '@material-ui/system';
import CopyContent from '../../worker/downloads/table/cells/CopyContent';

interface Props {
  modalVisibility: boolean;
  emailVerificationLink?: string;
  onCopyLink: () => void;
  onClose: () => void;
}
const EmailVerificationLinkModal = ({
  modalVisibility,
  emailVerificationLink,
  onClose,
}: Props): ReactElement => {
  return (
    <BasicModal
      maxWidth="xs"
      open={modalVisibility}
      onClose={onClose}
      title="Copy an email verification link"
      actionButton={
        <Button variant="text" color="secondary" size="small" onClick={onClose}>
          Cancel
        </Button>
      }
    >
      <ModalContent>
        <CopyContent content={emailVerificationLink ?? ''} width={400} />
      </ModalContent>
    </BasicModal>
  );
};

export default EmailVerificationLinkModal;

const ModalContent = styled.div`
  width: fit-content;
  height: fit-content;
`;

const Button = styled(CommonButton)(spacing);
