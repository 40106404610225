export interface MainBanner {
  id: string;
  seq: number;
  isShow: boolean;
  name: string;
  layout: LayoutType;
  backgroundImagePath: string;
  mainObjectPath: string;
  textType: TextType;
  subTitle: string | null;
  title: string | null;
  artworkSource: string | null;
  sourceLink: string;
  theme: BannerTheme;
  linkType: LinkType;
  link: string;
  buttonText: string | null;
  linkOpenTab: LinkOpenTabType;
}

export enum LayoutType {
  TEXT_CENTER = 'Type1',
  TEXT_LEFT = 'Type2',
  TEXT_RIGHT = 'Type3',
  IMAGE = 'Type4',
}

export enum TextType {
  TYPE1 = 'Type1',
  TYPE2 = 'Type2',
  TYPE3 = 'Type3',
  TYPE4 = 'Type4',
}

export enum LinkType {
  NONE = 'NoLink',
  BUTTON = 'ButtonLink',
  BANNER = 'BannerLink',
}

export enum LinkOpenTabType {
  CURRENT = 'CurrentTab',
  NEW = 'NewTab',
}

export enum BannerTheme {
  DARK = 'Dark',
  LIGHT = 'Light',
}

export type MainBannerMediaQueryRangeValue<T> = {
  s: T;
  m: T;
  l: T;
};

export enum AnimationDirection {
  FROM_LEFT = 1,
  FROM_RIGHT = 2,
}
