import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import _find from 'lodash/find';
import ContentLayout from 'components/common/layouts/ContentLayout';
import InvoiceInformation from 'components/plansales/billing/detail/information/InvoiceInformation';
import PaymentInformation from 'components/plansales/billing/detail/information/PaymentInformation';
import ActionButtons from 'components/plansales/billing/detail/ActionButtons';
import InvoiceSummaryDescription from 'components/plansales/billing/detail/invoice/InvoiceSummaryDescription';
import InvoiceTable from 'components/plansales/billing/detail/invoice/InvoiceTable';
import RefundModal from 'components/plansales/billing/detail/RefundModal';
import {
  ClosetPlanType,
  InvoiceDescriptionStatus,
  InvoiceItemType,
  PaymentMethod as PaymentMethodType,
  PaymentStatus,
} from 'types/plansales/billing';
import {
  getInvoiceDescription,
  getInvoiceDescriptionStatus,
} from 'modules/billing/BillingLanguage';
import {
  billingActions,
  selectBillingDetail,
  selectBillingDetailLoading,
  selectRefundState,
  selectRequestRefundLoading,
} from 'features/billing/billingSlice';
import { selectLoginUserInfo } from 'features/auth/authSlice';
import InformModal from 'components/common/modal/InformModal';
import BillingAddressInformation from 'components/plansales/billing/detail/information/BillingAddressInformation';
import {
  InvoiceItem,
  PaymentInformation as PaymentInformationType,
  RefundInformation,
} from 'types/plansales/billing/invoice';
import { useHistory } from 'react-router-dom';

const defaultInvoiceItem: InvoiceItem = {
  quantity: -1,
  unitPrice: -1,
  amount: -1,
  groupPlan: {
    planType: ClosetPlanType.FREE,
    planStartDate: '',
    planEndDate: '',
  },
  invoiceItemType: -1,
  refunded: false,
};

const defaultPaymentInformation: PaymentInformationType = {
  paymentMethod: PaymentMethodType.STRIPE,
  last4CardNo: '',
  stripeTaxId: '',
};

export const defaultRefundInformation: RefundInformation = {
  registeredBy: '',
  refundedAt: '',
  comment: '',
};

interface Props {
  invoiceNo: string;
}

const LegacyBillingDetailContainer = ({ invoiceNo }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginUserInfo = useSelector(selectLoginUserInfo);
  const billingDetail = useSelector(selectBillingDetail);
  const billingDetailLoading = useSelector(selectBillingDetailLoading);
  const requestRefundLoading = useSelector(selectRequestRefundLoading);
  const { error: refundError, refresh } = useSelector(selectRefundState);

  const [refundModalOpen, setRefundModalOpen] = useState<boolean>(false);
  const [refundErrorModalOpen, setRefundErrorModalOpen] = useState<boolean>(false);

  const [invoiceStatus, setInvoiceStatus] = useState<InvoiceDescriptionStatus>(
    InvoiceDescriptionStatus.NO_CASE,
  );

  const {
    adminUserId,
    invoiceItems = [],
    paymentSuccessedAt = '',
    paymentStatus = PaymentStatus.TRIED_YET,
    adminEmail,
    avalaraDocCode,
    registeredAt,
    billingAddress,
    taxAmount = 0,
    totalAmount = 0,
    subtotalAmount = 0,
    refund,
    groupInvoicePayment,
    planType,
    refundedAt: legacyRefund,
    invoiceItemDetail,
    pgInvoiceId,
  } = billingDetail;
  const { registeredBy, refundedAt } = refund ?? defaultRefundInformation;
  const { paymentMethod, last4CardNo, stripeTaxId } =
    groupInvoicePayment ?? defaultPaymentInformation;

  const isPending =
    paymentStatus === PaymentStatus.TRIED_YET || paymentStatus === PaymentStatus.CREATED;
  const isRefunded = (!!refund && paymentStatus === PaymentStatus.REFUND) || !!legacyRefund;
  const isVoid = paymentStatus === PaymentStatus.VOID;
  const isAdvancedPlanINV = planType === ClosetPlanType.ADVANCED_PLAN_INV;

  useEffect(() => {
    dispatch(billingActions.getBillingDetail({ invoiceNo }));
  }, []);

  useEffect(() => {
    if (refundError.length > 0) {
      // todo error model 맞춰서 조건 넣기
      setRefundErrorModalOpen(true);
      setRefundModalOpen(false);
    }
  }, [refundError]);

  useEffect(() => {
    setInvoiceStatus(
      getInvoiceDescriptionStatus(isRefunded, invoiceItems, invoiceItemDetail, totalAmount),
    );
  }, [isRefunded, invoiceItems, invoiceItemDetail, totalAmount]);
  useEffect(() => {
    if (refresh) {
      history.go(0);
    } // reload
  }, [refresh]);

  const getBillingTableData = (groupInvoiceItems: InvoiceItem[], isRefunded: boolean) => {
    // downgrade with credit pay 의 경우
    if (_find(groupInvoiceItems, { invoiceItemType: InvoiceItemType.DOWNGRADE_SUBSCRIPTION })) {
      const carryOverInformation =
        _find(groupInvoiceItems, {
          invoiceItemType: InvoiceItemType.CARRY_OVER_BALANCE,
        }) || defaultInvoiceItem;
      return [
        {
          amount: carryOverInformation.amount,
          unitPrice: carryOverInformation.unitPrice,
          quantity: carryOverInformation.quantity,
          groupPlan: {
            planType: ClosetPlanType.FREE,
            planStartDate: carryOverInformation.groupPlan.planStartDate,
            planEndDate: carryOverInformation.groupPlan.planEndDate,
          },
          invoiceItemType: InvoiceItemType.DOWNGRADE_SUBSCRIPTION,
          refunded: false,
        },
      ];
    }
    // refund 의 경우
    if (isRefunded) {
      return groupInvoiceItems.map((item: InvoiceItem) => ({ ...item, refunded: true }));
    }
    return groupInvoiceItems;
  };

  const onClickCloseErrorButton = () => {
    setRefundErrorModalOpen(false);
    dispatch(billingActions.resetRefundState());
  };

  const getAmount = (amount: number) => (isRefunded ? 0 : amount);
  const cannotRefund = isAdvancedPlanINV || isPending;
  return (
    <>
      <RefundModal
        invoiceNo={invoiceNo}
        refundModalOpen={refundModalOpen}
        isRefunded={isRefunded}
        paymentRefundDate={refund ? refund?.refundedAt : ''}
        loginUserName={loginUserInfo.name}
        setRefundModalOpen={setRefundModalOpen}
        refundErrorModalOpen={refundErrorModalOpen}
        refundInformation={refund}
      />
      <InformModal
        open={refundErrorModalOpen}
        onClose={onClickCloseErrorButton}
        description={refundError}
      />
      <ContentLayout pageTitle={'Billing Detail (Legacy)'} loading={billingDetailLoading}>
        <Grid container>
          <ActionButtons
            userId={adminUserId}
            setRefundModalOpen={setRefundModalOpen}
            requestRefundLoading={requestRefundLoading}
            refundDisabled={cannotRefund}
          />
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <InvoiceInformation
              refundRequestedBy={registeredBy}
              adminEmail={adminEmail}
              invoiceNo={invoiceNo}
              last4CardNumber={last4CardNo}
              paymentMethod={paymentMethod}
              paymentId={stripeTaxId}
              avalaraDocCode={avalaraDocCode || ''}
              paymentStatus={paymentStatus}
              registeredDate={registeredAt}
              paymentSucceedDate={paymentSuccessedAt}
              paymentRefundDate={refundedAt}
              isPending={isPending}
              isRefunded={isRefunded}
              isVoid={isVoid}
              planType={planType}
              invoiceStatus={invoiceStatus}
              paymentInvoiceId={pgInvoiceId}
              taxAmount={taxAmount}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid container direction={'column'}>
              <Grid item>
                <BillingAddressInformation billingAddress={billingAddress} />
              </Grid>
              <Grid item>
                <PaymentInformation
                  taxAmount={taxAmount}
                  subTotalAmount={subtotalAmount}
                  totalAmount={totalAmount}
                  isRefunded={isRefunded}
                  isPending={isPending || isVoid}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/*
            status : void (cancel before payment) 의 경우
            user 화면에서는 invoice 를 삭제하지만 back office 에서는 기존 결제상태 그대로 남겨둔다.
          */}
        <Grid>
          <InvoiceSummaryDescription
            descriptionMessage={
              getInvoiceDescription(billingDetail, invoiceItemDetail)()[invoiceStatus]
            }
          />
          <InvoiceTable
            invoiceItems={getBillingTableData(invoiceItems, isRefunded)}
            taxAmount={taxAmount}
            subTotalAmount={getAmount(subtotalAmount)}
            totalAmount={getAmount(totalAmount)}
            isRefunded={isRefunded}
            invoiceStatus={invoiceStatus}
          />
        </Grid>
      </ContentLayout>
    </>
  );
};

export default LegacyBillingDetailContainer;
