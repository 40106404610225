import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddAwardLayoutItemPayload,
  AddAwardLayoutPayload,
  AwardItemModalConfig,
  AwardItemPayload,
  AwardLayoutModalConfig,
  ContestAwardItemProps,
  ContestAwardProps,
  ContestItemListParams,
  ContestItemListProps,
  ContestLayoutProps,
  ContestParticipatingSortType,
  ContextMenuState,
  RemoveAwardLayoutPayload,
  UpdateAwardItemPayload,
  UpdateAwardLayoutMovePayload,
} from 'types/marketplace/operation/contest';
import { Nullable } from 'types/common';
import { RootState } from 'features/rootReducer';

interface ContestAwardState {
  contestItemsExcel: {
    isLoading: boolean;
    error: Nullable<Error>;
    data: ContestItemListProps;
  };
  contestItems: {
    isLoading: boolean;
    error: Nullable<Error>;
    data: ContestItemListProps;
  };
  contestAwards: {
    isLoading: boolean;
    error: Nullable<Error>;
    data: ContestAwardProps[];
    modalConfig: AwardLayoutModalConfig;
  };
  contestAwardItems: {
    isLoading: boolean;
    error: Nullable<Error>;
    data: ContestAwardItemProps[];
    modalConfig: AwardItemModalConfig;
  };
  contestContextMenuState: ContextMenuState;
}

const initialState: ContestAwardState = {
  contestItemsExcel: {
    isLoading: false,
    error: null,
    data: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      items: [],
    },
  },
  contestItems: {
    isLoading: false,
    error: null,
    data: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      items: [],
    },
  },
  contestAwards: {
    isLoading: false,
    error: null,
    data: [],
    modalConfig: {
      show: false,
    },
  },
  contestAwardItems: {
    isLoading: false,
    error: null,
    data: [],
    modalConfig: {
      show: false,
    },
  },
  contestContextMenuState: {
    contestSeq: -1,
    awardId: '',
    layoutId: '',
    endPosition: null,
  },
};

export const contestAwardSlice = createSlice({
  name: 'contest/awardItem',
  initialState,
  reducers: {
    resetContestItems: (state: ContestAwardState) => {
      state.contestItems = initialState.contestItems;
    },
    resetContestExcelItems: (state: ContestAwardState) => {
      state.contestItemsExcel = initialState.contestItemsExcel;
    },
    resetContestAward: (state: ContestAwardState) => {
      state.contestAwards = initialState.contestAwards;
    },
    setContestContextMenuState: (
      state: ContestAwardState,
      action: PayloadAction<ContextMenuState>,
    ) => {
      state.contestContextMenuState = action.payload;
    },
    setContestAwardLayoutModal: (
      state: ContestAwardState,
      action: PayloadAction<AwardLayoutModalConfig>,
    ) => {
      state.contestAwards.modalConfig = action.payload;
    },
    setContestAwardItemsModal: (
      state: ContestAwardState,
      action: PayloadAction<AwardItemModalConfig>,
    ) => {
      state.contestAwardItems.modalConfig = action.payload;
    },
    getContestAwardList: (state: ContestAwardState, _action: PayloadAction<number>) => {
      state.contestAwards.isLoading = true;
    },
    getContestAwardListSuccess: (
      state: ContestAwardState,
      action: PayloadAction<ContestAwardProps[]>,
    ) => {
      state.contestAwards.data = action.payload;
      state.contestAwards.isLoading = false;
    },
    getContestAwardListFailure: (state: ContestAwardState, action: PayloadAction<Error>) => {
      state.contestAwards.error = action.payload;
      state.contestAwards.isLoading = false;
    },
    addContestAwardLayout: (
      state: ContestAwardState,
      _action: PayloadAction<AddAwardLayoutPayload>,
    ) => {
      state.contestAwards.isLoading = true;
    },
    addContestAwardLayoutSuccess: (
      state: ContestAwardState,
      action: PayloadAction<ContestLayoutProps>,
    ) => {
      const { awardId } = action.payload;
      const findAward = state.contestAwards.data.find(award => award.awardId === awardId);
      if (findAward) {
        findAward?.layouts === null
          ? (findAward.layouts = [action.payload])
          : findAward.layouts.push(action.payload);
      }
      state.contestAwards.modalConfig = initialState.contestAwards.modalConfig;
      state.contestAwards.isLoading = false;
    },
    addContestAwardLayoutFailure: (state: ContestAwardState, action: PayloadAction<Error>) => {
      state.contestAwards.error = action.payload;
      state.contestAwards.isLoading = false;
    },
    removeContestAwardLayout: (
      state: ContestAwardState,
      _action: PayloadAction<RemoveAwardLayoutPayload>,
    ) => {
      state.contestAwards.isLoading = true;
    },
    removeContestAwardLayoutSuccess: (state: ContestAwardState, _action: PayloadAction) => {
      state.contestAwards.data;
      state.contestAwards.isLoading = false;
    },
    removeContestAwardLayoutFailure: (state: ContestAwardState, action: PayloadAction<Error>) => {
      state.contestAwards.error = action.payload;
      state.contestAwards.isLoading = false;
    },
    getContestItemList: (
      state: ContestAwardState,
      _action: PayloadAction<ContestItemListParams>,
    ) => {
      state.contestItems.isLoading = true;
    },
    getContestItemListSuccess: (
      state: ContestAwardState,
      action: PayloadAction<ContestItemListProps>,
    ) => {
      state.contestItems.data = action.payload;
      state.contestItems.isLoading = false;
    },
    getContestItemListFailure: (state: ContestAwardState, action: PayloadAction<Error>) => {
      state.contestItems.error = action.payload;
      state.contestItems.isLoading = false;
    },
    getContestAwardItemList: (
      state: ContestAwardState,
      _action: PayloadAction<AwardItemPayload>,
    ) => {
      state.contestAwardItems.isLoading = true;
    },
    getContestAwardItemListSuccess: (
      state: ContestAwardState,
      action: PayloadAction<ContestAwardItemProps[]>,
    ) => {
      state.contestAwardItems.data = action.payload;
      state.contestAwardItems.isLoading = false;
    },
    getContestAwardItemListFailure: (state: ContestAwardState, action: PayloadAction<Error>) => {
      state.contestAwardItems.error = action.payload;
      state.contestAwardItems.isLoading = false;
    },
    addContestAwardLayoutItem: (
      state: ContestAwardState,
      _action: PayloadAction<AddAwardLayoutItemPayload>,
    ) => {
      state.contestAwards.isLoading = true;
    },
    addContestAwardLayoutItemSuccess: (state: ContestAwardState, _action: PayloadAction) => {
      state.contestAwardItems.modalConfig = initialState.contestAwardItems.modalConfig;
      state.contestAwards.isLoading = false;
    },
    addContestAwardLayoutItemFailure: (state: ContestAwardState, _action: PayloadAction<Error>) => {
      state.contestAwards.isLoading = false;
    },
    updateContestAwardItem: (
      state: ContestAwardState,
      _action: PayloadAction<UpdateAwardItemPayload>,
    ) => {},
    updateContestAwardItemSuccess: (
      state: ContestAwardState,
      action: PayloadAction<{ itemId: string; awardId: Nullable<string> }>,
    ) => {
      const { itemId, awardId } = action.payload;
      const findItem = state.contestItems.data.items.find(item => item.itemId === itemId);
      if (findItem) {
        findItem.awardId = awardId;
      }
    },
    updateContestAwardItemFailure: (state: ContestAwardState, action: PayloadAction<Error>) => {
      state.contestItems.error = action.payload;
    },
    getContestExcelItemList: (
      state: ContestAwardState,
      _action: PayloadAction<{
        contestSeq: number;
        sort?: ContestParticipatingSortType;
      }>,
    ) => {
      state.contestItemsExcel.isLoading = true;
    },
    getContestExcelItemListSuccess: (
      state: ContestAwardState,
      action: PayloadAction<ContestItemListProps>,
    ) => {
      state.contestItemsExcel.data = action.payload;
      state.contestItemsExcel.isLoading = false;
    },
    getContestExcelItemListFailure: (state: ContestAwardState, action: PayloadAction<Error>) => {
      state.contestItemsExcel.error = action.payload;
      state.contestItemsExcel.isLoading = false;
    },
    updateContestAwardLayoutMove: (
      state: ContestAwardState,
      _action: PayloadAction<UpdateAwardLayoutMovePayload>,
    ) => {
      state.contestAwards.isLoading = true;
    },
    updateContestAwardLayoutMoveSuccess: (state: ContestAwardState, _action: PayloadAction) => {
      state.contestAwards.isLoading = false;
    },
    updateContestAwardLayoutMoveFailure: (
      state: ContestAwardState,
      action: PayloadAction<Error>,
    ) => {
      state.contestAwards.isLoading = false;
      state.contestAwards.error = action.payload;
    },
  },
});

export const contestAwardActions = contestAwardSlice.actions;
export const contestAwardReducer = contestAwardSlice.reducer;

export const selectContestAwardLayoutIsLoading = (rootState: RootState): boolean =>
  rootState.contestAward.contestAwards.isLoading;
export const selectContestAwardLayoutError = (rootState: RootState): Nullable<Error> =>
  rootState.contestAward.contestAwards.error;
export const selectContestAwardLayoutData = (rootState: RootState): ContestAwardProps[] =>
  rootState.contestAward.contestAwards.data;
export const selectContestAwardLayoutModal = (rootState: RootState): AwardLayoutModalConfig =>
  rootState.contestAward.contestAwards.modalConfig;
export const selectContestContextMenuState = (rootState: RootState): ContextMenuState =>
  rootState.contestAward.contestContextMenuState;
export const selectContestAwardItemModal = (rootState: RootState): AwardItemModalConfig =>
  rootState.contestAward.contestAwardItems.modalConfig;
export const selectContestAwardItemIsLoading = (rootState: RootState): boolean =>
  rootState.contestAward.contestAwardItems.isLoading;
export const selectContestAwardItemError = (rootState: RootState): Nullable<Error> =>
  rootState.contestAward.contestAwardItems.error;
export const selectContestAwardItemData = (rootState: RootState): ContestAwardItemProps[] =>
  rootState.contestAward.contestAwardItems.data;
export const selectActiveModalContestAwardItem = (
  rootState: RootState,
): ContestAwardProps | undefined => {
  const contestAward = rootState.contestAward;
  const awardId = contestAward.contestAwardItems.modalConfig.awardId;
  return contestAward.contestAwards.data.find(award => award.awardId === awardId);
};
export const selectContestItemsIsLoading = (rootState: RootState): boolean =>
  rootState.contestAward.contestItems.isLoading;
export const selectContestItemsError = (rootState: RootState): Nullable<Error> =>
  rootState.contestAward.contestItems.error;
export const selectContestItemsData = (rootState: RootState): ContestItemListProps =>
  rootState.contestAward.contestItems.data;
export const selectContestExcelItemsData = (rootState: RootState): ContestItemListProps =>
  rootState.contestAward.contestItemsExcel.data;
