import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { contestAPI } from 'api/marketplace/operation/contest';
import { PayloadAction } from '@reduxjs/toolkit';
import { contestAwardActions } from 'features/marketplace/operation/contest/contestAwardSlice';
import {
  AddAwardLayoutItemPayload,
  AddAwardLayoutPayload,
  AwardItemPayload,
  UpdateAwardLayoutMovePayload,
  RemoveAwardLayoutPayload,
  UpdateAwardItemPayload,
  ContestItemListParams,
} from 'types/marketplace/operation/contest';
import { EXCEL_EXPORT_LIMIT } from 'constants/marketplace/contest';

const {
  getContestAwardListSuccess,
  getContestAwardListFailure,
  addContestAwardLayoutSuccess,
  addContestAwardLayoutFailure,
  removeContestAwardLayoutSuccess,
  removeContestAwardLayoutFailure,
  getContestItemListSuccess,
  getContestItemListFailure,
  getContestAwardItemListSuccess,
  getContestAwardItemListFailure,
  addContestAwardLayoutItemSuccess,
  addContestAwardLayoutItemFailure,
  updateContestAwardItemSuccess,
  updateContestAwardItemFailure,
  getContestExcelItemListSuccess,
  getContestExcelItemListFailure,
  updateContestAwardLayoutMoveSuccess,
  updateContestAwardLayoutMoveFailure,
} = contestAwardActions;

export function* getContestAwardListSaga({
  payload: contestSeq,
}: PayloadAction<number>): SagaIterator {
  const { data, error } = yield call(contestAPI.getContestAwardList, contestSeq);
  if (data) {
    yield put(getContestAwardListSuccess(data));
  } else {
    yield put(getContestAwardListFailure(error));
  }
}

export function* addContestAwardLayoutSaga(
  action: PayloadAction<AddAwardLayoutPayload>,
): SagaIterator {
  const { data, error } = yield call(contestAPI.postContestAwardLayout, action.payload);
  if (data) {
    yield put(addContestAwardLayoutSuccess(data));
  } else {
    yield put(addContestAwardLayoutFailure(error));
  }
}

export function* removeContestAwardLayoutSaga(
  action: PayloadAction<RemoveAwardLayoutPayload>,
): SagaIterator {
  const { contestSeq } = action.payload;
  const { data, error } = yield call(contestAPI.removeContestAwardLayout, action.payload);
  if (data) {
    yield put(removeContestAwardLayoutSuccess());
    yield put(contestAwardActions.getContestAwardList(contestSeq));
  } else {
    yield put(removeContestAwardLayoutFailure(error));
  }
}

export function* getContestItemListSaga(
  action: PayloadAction<ContestItemListParams>,
): SagaIterator {
  const { data, error } = yield call(contestAPI.getContestItemList, action.payload);
  if (data) {
    yield put(getContestItemListSuccess(data));
  } else {
    yield put(getContestItemListFailure(error));
  }
}

export function* getContestAwardItemListSaga(
  action: PayloadAction<AwardItemPayload>,
): SagaIterator {
  const { data, error } = yield call(contestAPI.getContestAwardItemList, action.payload);
  if (data) {
    yield put(getContestAwardItemListSuccess(data));
  } else {
    yield put(getContestAwardItemListFailure(error));
  }
}

export function* addContestAwardLayoutItemSaga(
  action: PayloadAction<AddAwardLayoutItemPayload>,
): SagaIterator {
  const { contestSeq } = action.payload;
  const { data, error } = yield call(contestAPI.postContestAwardLayoutItem, action.payload);
  if (data) {
    yield put(addContestAwardLayoutItemSuccess());
    yield put(contestAwardActions.getContestAwardList(contestSeq));
  } else {
    yield put(addContestAwardLayoutItemFailure(error));
  }
}

export function* updateContestAwardItemSaga(
  action: PayloadAction<UpdateAwardItemPayload>,
): SagaIterator {
  const { error } = yield call(contestAPI.updateContestAwardItem, action.payload);
  const { itemId, awardId } = action.payload;

  if (error) {
    yield put(updateContestAwardItemFailure(error));
    return;
  }
  yield put(updateContestAwardItemSuccess({ itemId, awardId }));
}

export function* getContestExcelItemListSaga({
  payload,
}: PayloadAction<ContestItemListParams>): SagaIterator {
  const { data, error } = yield call(contestAPI.getContestItemList, {
    ...payload,
    page: 1,
    limit: EXCEL_EXPORT_LIMIT,
  });
  if (data) {
    yield put(getContestExcelItemListSuccess(data));
  } else {
    yield put(getContestExcelItemListFailure(error));
  }
}

export function* updateContestAwardLayoutMoveSaga(
  action: PayloadAction<UpdateAwardLayoutMovePayload>,
): SagaIterator {
  const { contestSeq } = action.payload;
  const { data, error } = yield call(contestAPI.updateContestLayoutMove, action.payload);
  if (data) {
    yield put(updateContestAwardLayoutMoveSuccess());
    yield put(contestAwardActions.getContestAwardList(contestSeq));
  } else {
    yield put(updateContestAwardLayoutMoveFailure(error));
  }
}

export default function* contestAwardSaga(): SagaIterator {
  yield all([
    takeLatest(contestAwardActions.getContestAwardList, getContestAwardListSaga),
    takeLatest(contestAwardActions.addContestAwardLayout, addContestAwardLayoutSaga),
    takeLatest(contestAwardActions.removeContestAwardLayout, removeContestAwardLayoutSaga),
    takeLatest(contestAwardActions.getContestItemList, getContestItemListSaga),
    takeLatest(contestAwardActions.getContestAwardItemList, getContestAwardItemListSaga),
    takeLatest(contestAwardActions.addContestAwardLayoutItem, addContestAwardLayoutItemSaga),
    takeLatest(contestAwardActions.updateContestAwardItem, updateContestAwardItemSaga),
    takeLatest(contestAwardActions.getContestExcelItemList, getContestExcelItemListSaga),
    takeLatest(contestAwardActions.updateContestAwardLayoutMove, updateContestAwardLayoutMoveSaga),
  ]);
}
