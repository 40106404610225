import * as React from 'react';
import { ChangeEvent, ReactElement } from 'react';
import {
  Checkbox as MuiCheckbox,
  createStyles,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

interface Props {
  label: string;
  value: string;
  checked?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
const FormCheckbox = ({ label, value, checked, onChange }: Props): ReactElement => {
  const formControlLabelStyles = makeStyles(() =>
    createStyles({
      root: {
        marginLeft: '-9px',
      },
    }),
  )();

  return (
    <FormControlLabel
      classes={formControlLabelStyles}
      label={label}
      control={<Checkbox value={value} checked={checked} onChange={onChange} p={1.5} />}
    />
  );
};

export default FormCheckbox;

const Checkbox = styled(MuiCheckbox)(spacing);
