import coreApi from '../coreApi';
import api from '../index';
import { AxiosError } from 'axios';
import {
  GetActualFilePathParams,
  GetDownloadListParams,
  GetFittingErrorListParams,
} from 'types/worker/downloads';

export const getDownloads = (params: GetDownloadListParams) => {
  return coreApi
    .get<GetFittingErrorListParams>(`/Workers/Download/Items`, {
      params: { ...params },
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getFittingErrors = (params: GetFittingErrorListParams) => {
  return coreApi
    .get<GetFittingErrorListParams>(`/Workers/Download/FittingErrors`, {
      params: { ...params },
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getActualFilePath = (params: GetActualFilePathParams) => {
  return coreApi
    .get<GetFittingErrorListParams>(`Workers/Download/Items/ActualFile`, {
      params: { ...params },
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getSRest = (params: GetActualFilePathParams) => {
  return coreApi
    .get<GetFittingErrorListParams>(`Workers/Download/Items/Srest`, {
      params: { ...params },
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const fittingRetry = ({ fittingId, taskId }: { fittingId: string; taskId: string }) => {
  return api
    .patch<{ fittingId: string }>(`api/fittings/${fittingId}/job/restart`, { taskId })
    .catch((error: AxiosError<Error>) => ({ error }));
};
