import {
  BannerThemeType,
  ImageValidation,
  LayoutType,
  LinkFormData,
  LinkType,
  LinkTypeValidation,
  MainBannerFormData,
  MainBannerFormValidation,
  TabType,
  TextFormData,
  TextFormDataKey,
  TextFormValidation,
  TextType,
} from 'types/marketplace/operation/mainBanner/mainBannerForm';
import {
  BannerTheme as PreviewBannerTheme,
  LayoutType as PreviewLayoutType,
  LinkType as PreviewLinkType,
  LinkOpenTabType as PreviewTabType,
  TextType as PreviewTextType,
} from 'types/marketplace/operation/mainBanner/mainBannerPreview';
import _cloneDeep from 'lodash/cloneDeep';
import { DEFAULT_URL, INITIAL_BANNER_THEME } from 'constants/marketplace/mainBanner';
import { MainBannerData } from 'types/api/marketplace/operation/mainBanner';
import { convertURLtoFile } from './utils/convert';
import { MainBannerPreviewParams } from 'pages/marketplace/mainbanner/MainBannerPreview';

export function getInitialMainBannerFormData(): MainBannerFormData {
  return {
    bannerTitle: '',
    bannerLayout: LayoutType.CENTER.toString(),
    image: {
      background: null,
      mainObject: null,
    },
    text: getInitialTextFormData(),
    bannerTheme: INITIAL_BANNER_THEME,
    link: getInitialLinkFormData(),
    isShow: false,
  };
}

export function getInitialTextFormData(): TextFormData {
  return {
    type: TextType.TYPE1.toString(),
    subtitle: '',
    title: '',
    artworkSource: '',
    sourceLink: DEFAULT_URL,
  };
}

export function getInitialLinkFormData(): LinkFormData {
  return {
    type: LinkType.NONE.toString(),
    buttonText: '',
    url: DEFAULT_URL,
    tab: TabType.CURRENT.toString(),
  };
}

export function getInitalValidation(): MainBannerFormValidation {
  return {
    bannerTitle: false,
    image: {
      background: false,
      mainObject: false,
    },
    text: {
      subtitle: false,
      title: false,
      artworkSource: false,
      sourceLink: false,
    },
    link: {
      buttonText: false,
      url: false,
    },
  };
}

export const validateFormData = (formData: MainBannerFormData): MainBannerFormValidation => {
  const { bannerTitle, image } = formData;
  const validation = getInitalValidation();

  validation.bannerTitle = !bannerTitle;
  validation.image.background = !image.background;
  validation.image.mainObject = !image.mainObject;

  validation.text.title = textValidator(formData, TextFormDataKey.TITLE);
  validation.text.subtitle = textValidator(formData, TextFormDataKey.SUBTITLE);
  validation.text.artworkSource = textValidator(formData, TextFormDataKey.ARTWORK_SOURCE);
  validation.text.sourceLink = textValidator(formData, TextFormDataKey.SOURCE_LINK);

  validation.link.buttonText = linkTypeValidator(formData, 'buttonText');
  validation.link.url = linkTypeValidator(formData, 'url');

  return validation;
};

// helpText가 보여야 하는 상황이면 true 반환
export const textValidator = (formData: MainBannerFormData, checkKey: TextFormDataKey): boolean => {
  const { bannerLayout, text } = formData;
  const isBannerLayoutImage = bannerLayout === LayoutType.IMAGE.toString();
  const isTextType1 = text.type === TextType.TYPE1.toString();
  const isInvisibleTextField =
    !isBannerLayoutImage && !isTextType1 && isTextType1UniqueField(checkKey);

  return !(text[checkKey] || isBannerLayoutImage || isInvisibleTextField);
};

// Text Type이 1일 때만 보이는 필드인지 여부
const isTextType1UniqueField = (key: TextFormDataKey) =>
  key === TextFormDataKey.ARTWORK_SOURCE || key === TextFormDataKey.SOURCE_LINK;

const linkTypeValidator = (formData: MainBannerFormData, checkKey: keyof LinkFormData): boolean => {
  const { text, link, bannerLayout } = formData;
  const isBannerLayoutImage: boolean = bannerLayout === LayoutType.IMAGE.toString();
  const isTextType1: boolean = text.type === TextType.TYPE1.toString();
  const isLinkTypeInvisible = !isBannerLayoutImage && isTextType1;
  const isNoLinkType: boolean = link.type === LinkType.NONE.toString();
  const isButtonLinkType: boolean = link.type === LinkType.BUTTON.toString();

  return !(
    link[checkKey] ||
    isLinkTypeInvisible ||
    isNoLinkType ||
    (!isButtonLinkType && checkKey === 'buttonText')
  );
};

export const isFormValid = ({
  bannerTitle,
  image,
  text,
  link,
}: MainBannerFormValidation): boolean => {
  const isBannerTitleValid = !bannerTitle;
  const isImageValid = checkIsValid<ImageValidation>(image);
  const isTextValid = checkIsValid<TextFormValidation>(text);
  const isLinkValid = checkIsValid<LinkTypeValidation>(link);

  return isBannerTitleValid && isImageValid && isTextValid && isLinkValid;
};

// true일때 유효하다는 의미 -> 에러상태 x
function checkIsValid<T extends Object>(object: T): boolean {
  return !Object.values(object).some(value => value);
}

export function initializeUnnecessaryData(data: MainBannerFormData) {
  const clonedData = _cloneDeep(data);

  const isBannerLayoutImage = data.bannerLayout === LayoutType.IMAGE.toString();
  const isTextType1 = data.text.type === TextType.TYPE1.toString();
  const isLinkTypeButton = data.link.type === LinkType.BUTTON.toString();
  const isLinkTypeNone = data.link.type === LinkType.NONE.toString();

  if (isBannerLayoutImage) {
    clonedData.text = getInitialTextFormData();
    clonedData.bannerTheme = INITIAL_BANNER_THEME;
    clonedData.link.buttonText = '';
  } else if (isTextType1) {
    clonedData.link = getInitialLinkFormData();
  } else {
    clonedData.text.artworkSource = '';
    clonedData.text.sourceLink = DEFAULT_URL;
  }

  if (!isLinkTypeButton) {
    clonedData.link.buttonText = '';
    if (isLinkTypeNone) {
      clonedData.link.url = DEFAULT_URL;
    }
  }

  return clonedData;
}

export function transformToFormData(data: MainBannerFormData) {
  const {
    bannerTitle,
    bannerLayout,
    image: { background, mainObject },
    text,
    bannerTheme,
    link,
    isShow,
  } = initializeUnnecessaryData(data);

  const formData = new FormData();
  formData.set('IsShow', isShow.toString());
  formData.set('Name', bannerTitle);
  formData.set('Layout', bannerLayout);
  formData.set('BackgroundImage', background as File);
  formData.set('MainObject', mainObject as File);
  formData.set('TextType', text.type);
  formData.set('SubTitle', text.subtitle);
  formData.set('Title', text.title);
  formData.set('ArtworkSource', text.artworkSource);
  formData.set('SourceLink', text.sourceLink);
  formData.set('Theme', bannerTheme);
  formData.set('LinkType', link.type);
  formData.set('Link', link.url);
  formData.set('ButtonText', link.buttonText);
  formData.set('LinkOpenTab', link.tab);

  return formData;
}

export async function parseMainBannerData({
  backgroundImagePath,
  mainObjectPath,
  name,
  layout,
  textType,
  subTitle,
  title,
  artworkSource,
  sourceLink,
  theme,
  linkType,
  link,
  buttonText,
  linkOpenTab,
  isShow,
}: MainBannerData): Promise<MainBannerFormData> {
  const [background, mainObject] = await Promise.all([
    convertURLtoFile(backgroundImagePath),
    convertURLtoFile(mainObjectPath),
  ]);

  return {
    bannerTitle: name,
    bannerLayout: layout.toString(),
    text: {
      type: textType.toString(),
      title: title || '',
      subtitle: subTitle || '',
      artworkSource: artworkSource || '',
      sourceLink: sourceLink || DEFAULT_URL,
    },
    image: {
      background,
      mainObject,
    },
    bannerTheme: theme.toString(),
    link: {
      type: linkType.toString(),
      buttonText: buttonText || '',
      url: link || DEFAULT_URL,
      tab: linkOpenTab.toString(),
    },
    isShow,
  };
}

export const transformToPreviewSearchParams = (formData: MainBannerFormData): URLSearchParams => {
  const toPreviewBannerTheme = {
    [BannerThemeType.DARK.toString()]: PreviewBannerTheme.DARK,
    [BannerThemeType.LIGHT.toString()]: PreviewBannerTheme.LIGHT,
  };

  const toPreviewLayout = {
    [LayoutType.CENTER.toString()]: PreviewLayoutType.TEXT_CENTER,
    [LayoutType.LEFT.toString()]: PreviewLayoutType.TEXT_LEFT,
    [LayoutType.RIGHT.toString()]: PreviewLayoutType.TEXT_RIGHT,
    [LayoutType.IMAGE.toString()]: PreviewLayoutType.IMAGE,
  };

  const toPreviewTextType = {
    [TextType.TYPE1.toString()]: PreviewTextType.TYPE1,
    [TextType.TYPE2.toString()]: PreviewTextType.TYPE2,
    [TextType.TYPE3.toString()]: PreviewTextType.TYPE3,
    [TextType.TYPE4.toString()]: PreviewTextType.TYPE4,
  };

  const toPreviewLinkType = {
    [LinkType.NONE.toString()]: PreviewLinkType.NONE,
    [LinkType.BUTTON.toString()]: PreviewLinkType.BUTTON,
    [LinkType.BANNER.toString()]: PreviewLinkType.BANNER,
  };

  const toPreviewTabType = {
    [TabType.CURRENT.toString()]: PreviewTabType.CURRENT,
    [TabType.NEW.toString()]: PreviewTabType.NEW,
  };

  const { bannerTitle, bannerLayout, image, text, bannerTheme, link } =
    initializeUnnecessaryData(formData);
  const previewSearchParams: MainBannerPreviewParams = {
    id: '',
    link: link.url,
    title: text.title,
    name: bannerTitle,
    theme: toPreviewBannerTheme[bannerTheme],
    seq: '0',
    isShow: 'true',
    layout: toPreviewLayout[bannerLayout],
    backgroundImagePath: image.background ? URL.createObjectURL(image.background) : '',
    mainObjectPath: image.mainObject ? URL.createObjectURL(image.mainObject) : '',
    textType: toPreviewTextType[text.type],
    subTitle: text.subtitle,
    artworkSource: text.artworkSource,
    sourceLink: text.sourceLink,
    linkType: toPreviewLinkType[link.type],
    buttonText: link.buttonText,
    linkOpenTab: toPreviewTabType[link.tab],
  };
  return new URLSearchParams(previewSearchParams);
};

export const checkIsKeyOfTextFormValidation = (
  textFormDataKey: keyof TextFormData,
  textValidation: TextFormValidation,
): textFormDataKey is keyof TextFormValidation =>
  textValidation[textFormDataKey as keyof TextFormValidation] !== undefined;

export const checkIsKeyOfLinkTypeValidation = (
  key: keyof LinkFormData,
  linkTypeValidation: LinkTypeValidation,
): key is keyof LinkTypeValidation =>
  linkTypeValidation[key as keyof LinkTypeValidation] !== undefined;
