import * as React from 'react';
import { ReactElement } from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  content: string;
  width?: number;
  open?: boolean;
  placement?: TooltipProps['placement'];
}

const EllipsisContent = ({ content, width, open, placement = 'bottom' }: Props): ReactElement => {
  return (
    <Tooltip title={content ?? ''} open={open} placement={placement}>
      <EllipsisContentWrapper width={width}>{content}</EllipsisContentWrapper>
    </Tooltip>
  );
};
export default EllipsisContent;

const EllipsisContentWrapper = styled.div<{ width?: number }>`
  width: ${({ width }) => width}px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
