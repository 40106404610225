import styled from 'styled-components';
import jinnyLogo from 'public/static/img/icon/jinny-icon.svg';

const JinnyLogo = styled.div`
  background: url(${jinnyLogo}) no-repeat;
  background-size: 100%;
  width: 17px;
  height: 19px;
`;

export default JinnyLogo;
