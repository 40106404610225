import * as React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { PaymentMethod } from 'types/plansales/billing';
import { LabelText } from 'components/plansales/billing/searchfilter/filtercontents/PayStatusFilters';
import { paymentMethodLabel } from 'constants/plansales/billing';
import { CheckObjectValue } from 'types/common';

interface Props {
  paymentMethod: CheckObjectValue;
  onChangePaymentMethod: (
    status: PaymentMethod,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PayMethodFilters = ({ paymentMethod, onChangePaymentMethod }: Props) => {
  const payMethodCheckboxes = [PaymentMethod.STRIPE, PaymentMethod.CREDITS];
  return (
    <>
      {payMethodCheckboxes.map(method => (
        <FormControlLabel
          key={method}
          control={
            <Checkbox
              value={method}
              color={'primary'}
              checked={paymentMethod[method]}
              onChange={onChangePaymentMethod(method)}
            />
          }
          label={<LabelText>{paymentMethodLabel[method]}</LabelText>}
        />
      ))}
    </>
  );
};

export default PayMethodFilters;
