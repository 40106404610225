import { all, takeLatest, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { downloadsActions } from '../reducer/downloadsSlice';
import * as api from 'api/worker/downloads';
import {
  GetActualFilePathParams,
  GetDownloadListParams,
  GetFittingErrorListParams,
} from 'types/worker/downloads';
import { fittingRetry } from 'api/worker/downloads';

export function* getDownloadListSaga(action: PayloadAction<GetDownloadListParams>) {
  const { data } = yield call(api.getDownloads, action.payload);
  if (data) {
    yield put(downloadsActions.getDownloadListSuccess(data));
  } else {
    yield put(downloadsActions.getDownloadListFailure(data));
  }
}

export function* getFittingErrorListSaga(action: PayloadAction<GetFittingErrorListParams>) {
  const { data } = yield call(api.getFittingErrors, action.payload);
  if (data) {
    yield put(downloadsActions.getFittingErrorListSuccess(data));
  } else {
    yield put(downloadsActions.getFittingErrorListFailure(data));
  }
}

export function* getActualFilePathSaga(action: PayloadAction<GetActualFilePathParams>) {
  const { data } = yield call(api.getActualFilePath, action.payload);
  if (data) {
    yield put(downloadsActions.getActualFilePathSuccess(data));
  } else {
    yield put(downloadsActions.getActualFilePathFailure(data));
  }
}

export function* getSRestSaga(action: PayloadAction<GetActualFilePathParams>) {
  const { data } = yield call(api.getSRest, action.payload);
  const jsonObj = JSON.stringify(data.result, null, 2);
  const file = new Blob([jsonObj], { type: 'text/json' });
  const sRestFile = { file, fileName: `${action.payload.styleId}.json` };
  if (data) {
    yield put(
      downloadsActions.getSRestSuccess({
        ...data,
        sRestFile,
      }),
    );
  } else {
    yield put(downloadsActions.getSRestFailure(data));
  }
}

export function* fittingRetrySaga(action: PayloadAction<{ fittingId: string; taskId: string }>) {
  const { data } = yield call(api.fittingRetry, action.payload);
  if (data) {
    yield put(downloadsActions.getDownloadListSuccess(data));
  } else {
    yield put(downloadsActions.getDownloadListFailure(data));
  }
}

export default function* downloadsSaga() {
  yield all([
    takeLatest(downloadsActions.getDownloadList.type, getDownloadListSaga),
    takeLatest(downloadsActions.getFittingErrorList.type, getFittingErrorListSaga),
    takeLatest(downloadsActions.getActualFilePath.type, getActualFilePathSaga),
    takeLatest(downloadsActions.getSRest.type, getSRestSaga),
  ]);
}
