import { sanitize } from 'isomorphic-dompurify';

export const createMarkup = (
  html: string,
): {
  __html: string;
} => ({ __html: sanitize(html) });

export const cleanMarkup = ({
  content,
  allowTags,
  allowAttrs,
}: {
  content: string;
  allowTags?: string[];
  allowAttrs?: string[];
}): string => {
  return sanitize(content, { ADD_ATTR: allowAttrs, ADD_TAGS: allowTags });
};
