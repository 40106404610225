export const EUROPEAN_UNION_CODE: { [key in string]: { label: string; value: string } } = {
  AT: { label: 'Austria', value: 'AT' },
  BE: { label: 'Belgium', value: 'BE' },
  BG: { label: 'Bulgaria', value: 'BG' },
  HR: { label: 'Croatia', value: 'HR' },
  CY: { label: 'Cyprus', value: 'CY' },
  CZ: { label: 'Czechia', value: 'CZ' },
  DK: { label: 'Denmark', value: 'DK' },
  EE: { label: 'Estonia', value: 'EE' },
  FI: { label: 'Finland', value: 'FI' },
  FR: { label: 'France', value: 'FR' },
  DE: { label: 'Germany', value: 'DE' },
  GR: { label: 'Greece', value: 'GR' },
  HU: { label: 'Hungary', value: 'HU' },
  IE: { label: 'Ireland', value: 'IE' },
  IT: { label: 'Italy', value: 'IT' },
  LV: { label: 'Latvia', value: 'LV' },
  LT: { label: 'Lithuania', value: 'LT' },
  LU: { label: 'Luxembourg', value: 'LU' },
  MT: { label: 'Malta', value: 'MT' },
  NL: { label: 'Netherlands', value: 'NL' },
  PL: { label: 'Poland', value: 'PL' },
  PT: { label: 'Portugal', value: 'PT' },
  RO: { label: 'Romania', value: 'RO' },
  SK: { label: 'Slovakia', value: 'SK' },
  SI: { label: 'Slovenia', value: 'SI' },
  ES: { label: 'Spain', value: 'ES' },
  SE: { label: 'Sweden', value: 'SE' },
};

export const EUROPEAN_UNION_CODE_ARRAY = Object.keys(EUROPEAN_UNION_CODE).map(
  item => EUROPEAN_UNION_CODE[item],
);

export const NATIONAL_CODE: { [key in string]: { label: string; value: string } } = {
  AF: { label: 'Afghanistan', value: 'AF' },
  AX: { label: 'Åland Islands', value: 'AX' },
  AL: { label: 'Albania', value: 'AL' },
  DZ: { label: 'Algeria', value: 'DZ' },
  AS: { label: 'American Samoa', value: 'AS' },
  AD: { label: 'Andorra', value: 'AD' },
  AO: { label: 'Angola', value: 'AO' },
  AI: { label: 'Anguilla', value: 'AI' },
  AQ: { label: 'Antarctica', value: 'AQ' },
  AG: { label: 'Antigua and Barbuda', value: 'AG' },
  AR: { label: 'Argentina', value: 'AR' },
  AM: { label: 'Armenia', value: 'AM' },
  AW: { label: 'Aruba', value: 'AW' },
  AU: { label: 'Australia', value: 'AU' },
  AT: { label: 'Austria', value: 'AT' },
  AZ: { label: 'Azerbaijan', value: 'AZ' },
  BS: { label: 'Bahamas', value: 'BS' },
  BH: { label: 'Bahrain', value: 'BH' },
  BD: { label: 'Bangladesh', value: 'BD' },
  BB: { label: 'Barbados', value: 'BB' },
  BY: { label: 'Belarus', value: 'BY' },
  BE: { label: 'Belgium', value: 'BE' },
  BZ: { label: 'Belize', value: 'BZ' },
  BJ: { label: 'Benin', value: 'BJ' },
  BM: { label: 'Bermuda', value: 'BM' },
  BT: { label: 'Bhutan', value: 'BT' },
  BO: { label: 'Bolivia (Plurinational State of)', value: 'BO' },
  BQ: { label: 'Bonaire, Sint Eustatius and Saba', value: 'BQ' },
  BA: { label: 'Bosnia and Herzegovina', value: 'BA' },
  BW: { label: 'Botswana', value: 'BW' },
  BV: { label: 'Bouvet Island', value: 'BV' },
  BR: { label: 'Brazil', value: 'BR' },
  IO: { label: 'British Indian Ocean Territory', value: 'IO' },
  BN: { label: 'Brunei Darussalam', value: 'BN' },
  BG: { label: 'Bulgaria', value: 'BG' },
  BF: { label: 'Burkina Faso', value: 'BF' },
  BI: { label: 'Burundi', value: 'BI' },
  CV: { label: 'Cabo Verde', value: 'CV' },
  KH: { label: 'Cambodia', value: 'KH' },
  CM: { label: 'Cameroon', value: 'CM' },
  CA: { label: 'Canada', value: 'CA' },
  KY: { label: 'Cayman Islands', value: 'KY' },
  CF: { label: 'Central African Republic', value: 'CF' },
  TD: { label: 'Chad', value: 'TD' },
  CL: { label: 'Chile', value: 'CL' },
  CN: { label: 'China', value: 'CN' },
  CX: { label: 'Christmas Island', value: 'CX' },
  CC: { label: 'Cocos (Keeling) Islands', value: 'CC' },
  CO: { label: 'Colombia', value: 'CO' },
  KM: { label: 'Comoros', value: 'KM' },
  CG: { label: 'Congo', value: 'CG' },
  CD: { label: 'Congo, Democratic Republic of the', value: 'CD' },
  CK: { label: 'Cook Islands', value: 'CK' },
  CR: { label: 'Costa Rica', value: 'CR' },
  CI: { label: 'Côte d"Ivoire', value: 'CI' },
  HR: { label: 'Croatia', value: 'HR' },
  CU: { label: 'Cuba', value: 'CU' },
  CW: { label: 'Curaçao', value: 'CW' },
  CY: { label: 'Cyprus', value: 'CY' },
  CZ: { label: 'Czechia', value: 'CZ' },
  DK: { label: 'Denmark', value: 'DK' },
  DJ: { label: 'Djibouti', value: 'DJ' },
  DM: { label: 'Dominica', value: 'DM' },
  DO: { label: 'Dominican Republic', value: 'DO' },
  EC: { label: 'Ecuador', value: 'EC' },
  EG: { label: 'Egypt', value: 'EG' },
  SV: { label: 'El Salvador', value: 'SV' },
  GQ: { label: 'Equatorial Guinea', value: 'GQ' },
  ER: { label: 'Eritrea', value: 'ER' },
  EE: { label: 'Estonia', value: 'EE' },
  SZ: { label: 'Eswatini', value: 'SZ' },
  ET: { label: 'Ethiopia', value: 'ET' },
  FK: { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  FO: { label: 'Faroe Islands', value: 'FO' },
  FJ: { label: 'Fiji', value: 'FJ' },
  FI: { label: 'Finland', value: 'FI' },
  FR: { label: 'France', value: 'FR' },
  GF: { label: 'French Guiana', value: 'GF' },
  PF: { label: 'French Polynesia', value: 'PF' },
  TF: { label: 'French Southern Territories', value: 'TF' },
  GA: { label: 'Gabon', value: 'GA' },
  GM: { label: 'Gambia', value: 'GM' },
  GE: { label: 'Georgia', value: 'GE' },
  DE: { label: 'Germany', value: 'DE' },
  GH: { label: 'Ghana', value: 'GH' },
  GI: { label: 'Gibraltar', value: 'GI' },
  GR: { label: 'Greece', value: 'GR' },
  GL: { label: 'Greenland', value: 'GL' },
  GD: { label: 'Grenada', value: 'GD' },
  GP: { label: 'Guadeloupe', value: 'GP' },
  GU: { label: 'Guam', value: 'GU' },
  GT: { label: 'Guatemala', value: 'GT' },
  GG: { label: 'Guernsey', value: 'GG' },
  GN: { label: 'Guinea', value: 'GN' },
  GW: { label: 'Guinea-Bissau', value: 'GW' },
  GY: { label: 'Guyana', value: 'GY' },
  HT: { label: 'Haiti', value: 'HT' },
  HM: { label: 'Heard Island and McDonald Islands', value: 'HM' },
  VA: { label: 'Holy See', value: 'VA' },
  HN: { label: 'Honduras', value: 'HN' },
  HK: { label: 'Hong Kong', value: 'HK' },
  HU: { label: 'Hungary', value: 'HU' },
  IS: { label: 'Iceland', value: 'IS' },
  IN: { label: 'India', value: 'IN' },
  ID: { label: 'Indonesia', value: 'ID' },
  IR: { label: 'Iran (Islamic Republic of)', value: 'IR' },
  IQ: { label: 'Iraq', value: 'IQ' },
  IE: { label: 'Ireland', value: 'IE' },
  IM: { label: 'Isle of Man', value: 'IM' },
  IL: { label: 'Israel', value: 'IL' },
  IT: { label: 'Italy', value: 'IT' },
  JM: { label: 'Jamaica', value: 'JM' },
  JP: { label: 'Japan', value: 'JP' },
  JE: { label: 'Jersey', value: 'JE' },
  JO: { label: 'Jordan', value: 'JO' },
  KZ: { label: 'Kazakhstan', value: 'KZ' },
  KE: { label: 'Kenya', value: 'KE' },
  KI: { label: 'Kiribati', value: 'KI' },
  KP: { label: 'Korea (Democratic People"s Republic of)', value: 'KP' },
  KR: { label: 'Korea, Republic of', value: 'KR' },
  KW: { label: 'Kuwait', value: 'KW' },
  KG: { label: 'Kyrgyzstan', value: 'KG' },
  LA: { label: 'Lao People"s Democratic Republic', value: 'LA' },
  LV: { label: 'Latvia', value: 'LV' },
  LB: { label: 'Lebanon', value: 'LB' },
  LS: { label: 'Lesotho', value: 'LS' },
  LR: { label: 'Liberia', value: 'LR' },
  LY: { label: 'Libya', value: 'LY' },
  LI: { label: 'Liechtenstein', value: 'LI' },
  LT: { label: 'Lithuania', value: 'LT' },
  LU: { label: 'Luxembourg', value: 'LU' },
  MO: { label: 'Macao', value: 'MO' },
  MG: { label: 'Madagascar', value: 'MG' },
  MW: { label: 'Malawi', value: 'MW' },
  MY: { label: 'Malaysia', value: 'MY' },
  MV: { label: 'Maldives', value: 'MV' },
  ML: { label: 'Mali', value: 'ML' },
  MT: { label: 'Malta', value: 'MT' },
  MH: { label: 'Marshall Islands', value: 'MH' },
  MQ: { label: 'Martinique', value: 'MQ' },
  MR: { label: 'Mauritania', value: 'MR' },
  MU: { label: 'Mauritius', value: 'MU' },
  YT: { label: 'Mayotte', value: 'YT' },
  MX: { label: 'Mexico', value: 'MX' },
  FM: { label: 'Micronesia (Federated States of)', value: 'FM' },
  MD: { label: 'Moldova, Republic of', value: 'MD' },
  MC: { label: 'Monaco', value: 'MC' },
  MN: { label: 'Mongolia', value: 'MN' },
  ME: { label: 'Montenegro', value: 'ME' },
  MS: { label: 'Montserrat', value: 'MS' },
  MA: { label: 'Morocco', value: 'MA' },
  MZ: { label: 'Mozambique', value: 'MZ' },
  MM: { label: 'Myanmar', value: 'MM' },
  NA: { label: 'Namibia', value: 'NA' },
  NR: { label: 'Nauru', value: 'NR' },
  NP: { label: 'Nepal', value: 'NP' },
  NL: { label: 'Netherlands', value: 'NL' },
  NC: { label: 'New Caledonia', value: 'NC' },
  NZ: { label: 'New Zealand', value: 'NZ' },
  NI: { label: 'Nicaragua', value: 'NI' },
  NE: { label: 'Niger', value: 'NE' },
  NG: { label: 'Nigeria', value: 'NG' },
  NU: { label: 'Niue', value: 'NU' },
  NF: { label: 'Norfolk Island', value: 'NF' },
  MK: { label: 'North Macedonia', value: 'MK' },
  MP: { label: 'Northern Mariana Islands', value: 'MP' },
  NO: { label: 'Norway', value: 'NO' },
  OM: { label: 'Oman', value: 'OM' },
  PK: { label: 'Pakistan', value: 'PK' },
  PW: { label: 'Palau', value: 'PW' },
  PS: { label: 'Palestine, State of', value: 'PS' },
  PA: { label: 'Panama', value: 'PA' },
  PG: { label: 'Papua New Guinea', value: 'PG' },
  PY: { label: 'Paraguay', value: 'PY' },
  PE: { label: 'Peru', value: 'PE' },
  PH: { label: 'Philippines', value: 'PH' },
  PN: { label: 'Pitcairn', value: 'PN' },
  PL: { label: 'Poland', value: 'PL' },
  PT: { label: 'Portugal', value: 'PT' },
  PR: { label: 'Puerto Rico', value: 'PR' },
  QA: { label: 'Qatar', value: 'QA' },
  RE: { label: 'Réunion', value: 'RE' },
  RO: { label: 'Romania', value: 'RO' },
  RU: { label: 'Russian Federation', value: 'RU' },
  RW: { label: 'Rwanda', value: 'RW' },
  BL: { label: 'Saint Barthélemy', value: 'BL' },
  SH: { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
  KN: { label: 'Saint Kitts and Nevis', value: 'KN' },
  LC: { label: 'Saint Lucia', value: 'LC' },
  MF: { label: 'Saint Martin (French part)', value: 'MF' },
  PM: { label: 'Saint Pierre and Miquelon', value: 'PM' },
  VC: { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  WS: { label: 'Samoa', value: 'WS' },
  SM: { label: 'San Marino', value: 'SM' },
  ST: { label: 'Sao Tome and Principe', value: 'ST' },
  SA: { label: 'Saudi Arabia', value: 'SA' },
  SN: { label: 'Senegal', value: 'SN' },
  RS: { label: 'Serbia', value: 'RS' },
  SC: { label: 'Seychelles', value: 'SC' },
  SL: { label: 'Sierra Leone', value: 'SL' },
  SG: { label: 'Singapore', value: 'SG' },
  SX: { label: 'Sint Maarten (Dutch part)', value: 'SX' },
  SK: { label: 'Slovakia', value: 'SK' },
  SI: { label: 'Slovenia', value: 'SI' },
  SB: { label: 'Solomon Islands', value: 'SB' },
  SO: { label: 'Somalia', value: 'SO' },
  ZA: { label: 'South Africa', value: 'ZA' },
  GS: { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  SS: { label: 'South Sudan', value: 'SS' },
  ES: { label: 'Spain', value: 'ES' },
  LK: { label: 'Sri Lanka', value: 'LK' },
  SD: { label: 'Sudan', value: 'SD' },
  SR: { label: 'Suriname', value: 'SR' },
  SJ: { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  SE: { label: 'Sweden', value: 'SE' },
  CH: { label: 'Switzerland', value: 'CH' },
  SY: { label: 'Syrian Arab Republic', value: 'SY' },
  TW: { label: 'Taiwan, Province of China', value: 'TW' },
  TJ: { label: 'Tajikistan', value: 'TJ' },
  TZ: { label: 'Tanzania, United Republic of', value: 'TZ' },
  TH: { label: 'Thailand', value: 'TH' },
  TL: { label: 'Timor-Leste', value: 'TL' },
  TG: { label: 'Togo', value: 'TG' },
  TK: { label: 'Tokelau', value: 'TK' },
  TO: { label: 'Tonga', value: 'TO' },
  TT: { label: 'Trinidad and Tobago', value: 'TT' },
  TN: { label: 'Tunisia', value: 'TN' },
  TR: { label: 'Turkey', value: 'TR' },
  TM: { label: 'Turkmenistan', value: 'TM' },
  TC: { label: 'Turks and Caicos Islands', value: 'TC' },
  TV: { label: 'Tuvalu', value: 'TV' },
  UG: { label: 'Uganda', value: 'UG' },
  UA: { label: 'Ukraine', value: 'UA' },
  AE: { label: 'United Arab Emirates', value: 'AE' },
  GB: { label: 'United Kingdom of Great Britain and Northern Ireland', value: 'GB' },
  US: { label: 'United States of America', value: 'US' },
  UM: { label: 'United States Minor Outlying Islands', value: 'UM' },
  UY: { label: 'Uruguay', value: 'UY' },
  UZ: { label: 'Uzbekistan', value: 'UZ' },
  VU: { label: 'Vanuatu', value: 'VU' },
  VE: { label: 'Venezuela (Bolivarian Republic of)', value: 'VE' },
  VN: { label: 'Viet Nam', value: 'VN' },
  VG: { label: 'Virgin Islands (British)', value: 'VG' },
  VI: { label: 'Virgin Islands (U.S.)', value: 'VI' },
  WF: { label: 'Wallis and Futuna', value: 'WF' },
  EH: { label: 'Western Sahara', value: 'EH' },
  YE: { label: 'Yemen', value: 'YE' },
  ZM: { label: 'Zambia', value: 'ZM' },
  ZW: { label: 'Zimbabwe', value: 'ZW' },
};

export const NATIONAL_CODE_ARRAY = Object.keys(NATIONAL_CODE).map(item => NATIONAL_CODE[item]);
export const USA_CODE = NATIONAL_CODE.US.value;

export const UNITED_STATE_CODE: { [key in string]: { label: string; value: string } } = {
  AL: {
    label: 'Alabama',
    value: 'AL',
  },
  AK: {
    label: 'Alaska',
    value: 'AK',
  },
  AS: {
    label: 'American Samoa',
    value: 'AS',
  },
  AZ: {
    label: 'Arizona',
    value: 'AZ',
  },
  AR: {
    label: 'Arkansas',
    value: 'AR',
  },
  CA: {
    label: 'California',
    value: 'CA',
  },
  CO: {
    label: 'Colorado',
    value: 'CO',
  },
  CT: {
    label: 'Connecticut',
    value: 'CT',
  },
  DE: {
    label: 'Delaware',
    value: 'DE',
  },
  DC: {
    label: 'District Of Columbia',
    value: 'DC',
  },
  FM: {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  FL: {
    label: 'Florida',
    value: 'FL',
  },
  GA: {
    label: 'Georgia',
    value: 'GA',
  },
  GU: {
    label: 'Guam',
    value: 'GU',
  },
  HI: {
    label: 'Hawaii',
    value: 'HI',
  },
  ID: {
    label: 'Idaho',
    value: 'ID',
  },
  IL: {
    label: 'Illinois',
    value: 'IL',
  },
  IN: {
    label: 'Indiana',
    value: 'IN',
  },
  IA: {
    label: 'Iowa',
    value: 'IA',
  },
  KS: {
    label: 'Kansas',
    value: 'KS',
  },
  KY: {
    label: 'Kentucky',
    value: 'KY',
  },
  LA: {
    label: 'Louisiana',
    value: 'LA',
  },
  ME: {
    label: 'Maine',
    value: 'ME',
  },
  MH: {
    label: 'Marshall Islands',
    value: 'MH',
  },
  MD: {
    label: 'Maryland',
    value: 'MD',
  },
  MA: {
    label: 'Massachusetts',
    value: 'MA',
  },
  MI: {
    label: 'Michigan',
    value: 'MI',
  },
  MN: {
    label: 'Minnesota',
    value: 'MN',
  },
  MS: {
    label: 'Mississippi',
    value: 'MS',
  },
  MO: {
    label: 'Missouri',
    value: 'MO',
  },
  MT: {
    label: 'Montana',
    value: 'MT',
  },
  NE: {
    label: 'Nebraska',
    value: 'NE',
  },
  NV: {
    label: 'Nevada',
    value: 'NV',
  },
  NH: {
    label: 'New Hampshire',
    value: 'NH',
  },
  NJ: {
    label: 'New Jersey',
    value: 'NJ',
  },
  NM: {
    label: 'New Mexico',
    value: 'NM',
  },
  NY: {
    label: 'New York',
    value: 'NY',
  },
  NC: {
    label: 'North Carolina',
    value: 'NC',
  },
  ND: {
    label: 'North Dakota',
    value: 'ND',
  },
  MP: {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  OH: {
    label: 'Ohio',
    value: 'OH',
  },
  OK: {
    label: 'Oklahoma',
    value: 'OK',
  },
  OR: {
    label: 'Oregon',
    value: 'OR',
  },
  PW: {
    label: 'Palau',
    value: 'PW',
  },
  PA: {
    label: 'Pennsylvania',
    value: 'PA',
  },
  PR: {
    label: 'Puerto Rico',
    value: 'PR',
  },
  RI: {
    label: 'Rhode Island',
    value: 'RI',
  },
  SC: {
    label: 'South Carolina',
    value: 'SC',
  },
  SD: {
    label: 'South Dakota',
    value: 'SD',
  },
  TN: {
    label: 'Tennessee',
    value: 'TN',
  },
  TX: {
    label: 'Texas',
    value: 'TX',
  },
  UT: {
    label: 'Utah',
    value: 'UT',
  },
  VT: {
    label: 'Vermont',
    value: 'VT',
  },
  VI: {
    label: 'Virgin Islands',
    value: 'VI',
  },
  VA: {
    label: 'Virginia',
    value: 'VA',
  },
  WA: {
    label: 'Washington',
    value: 'WA',
  },
  WV: {
    label: 'West Virginia',
    value: 'WV',
  },
  WI: {
    label: 'Wisconsin',
    value: 'WI',
  },
  WY: {
    label: 'Wyoming',
    value: 'WY',
  },
};

export const UNITED_STATE_CODE_ARRAY = Object.keys(UNITED_STATE_CODE).map(
  item => UNITED_STATE_CODE[item],
);
