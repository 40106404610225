import { SellerMonthlyPayout, SellerMonthlyPayoutItem } from 'types/marketplace/revenue';
import { MONTHLY_LIST } from 'constants/marketplace/revenue';
import { ExcelMultiDataSet } from 'types/react-export-excel';
import _times from 'lodash/times';

const convertExcelRow = ({ sellerName, email, paidAmount, ...row }: SellerMonthlyPayoutItem) => [
  sellerName,
  email,
  paidAmount,
  ...MONTHLY_LIST.map(month => row[month].amount ?? ''),
];

export const monthlyPayoutDataToExcelMultiDataSet = (
  data: SellerMonthlyPayout,
): ExcelMultiDataSet[] => {
  const { totalInfo, list: dataList } = data;

  return [
    {
      columns: [
        { value: 'Seller Info', widthPx: 80 },
        { value: '', widthPx: 80 },
        { value: 'Sales Info', widthPx: 60 },
        { value: '', widthPx: 60 },
        { value: '', widthPx: 60 },
        { value: 'Monthly Payout', widthPx: 60 },
        ..._times(12, () => ({ value: '', widthPx: 60 })),
      ],
      data: [],
    },
    {
      columns: [
        'Seller Name',
        'Account',
        'Paid',
        ...MONTHLY_LIST.map((_, index) => (index + 1).toString()),
      ],
      data: [
        ...(totalInfo ? [totalInfo].map(convertExcelRow) : []),
        ...dataList.map(convertExcelRow),
      ],
    },
  ];
};
