import * as React from 'react';
import { ReactElement } from 'react';
import { TypoSubTitle } from '@closet-design-system/core';
import { MainBannerTypoVariant } from '.';
import { createMarkup } from 'modules/utils/html';

interface Props {
  content: string;
  typoSubtitleVariant: MainBannerTypoVariant[];
}

const Subtitle = ({ content, typoSubtitleVariant }: Props): ReactElement => {
  return (
    <TypoSubTitle variant={typoSubtitleVariant}>
      <div dangerouslySetInnerHTML={createMarkup(content)} />
    </TypoSubTitle>
  );
};

export default Subtitle;
