import * as React from 'react';
import BasicModal from 'components/common/modal/BasicModal';
import { useState } from 'react';
import { getUtcUSFormatDateWithTime } from 'modules/utils/time';
import styled from 'styled-components';
import { Button as MuiButton, IconButton, Typography } from '@material-ui/core';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import { spacing } from '@material-ui/system';
import CommentInput from 'components/memberdetail/detail/commentmodal/CommentInput';

interface Props {
  commentHistoryModalOpen: boolean;
  closeCommentHistoryModal: () => void;
}

const commentdummy = [
  {
    text: '업체최초계약. 입금은 7/14예정. 크레딧 6,000 지급하기로협의완료함',
    user: 'Randy',
    timestamp: '09/13/2021 08:59:59',
  },
  {
    text: '계약 날짜 수정함 기존 7/13-7/30 에서 7/13-9/30일로 변경',
    user: 'Peona',
    timestamp: '09/13/2021 08:59:59',
  },
  {
    text: '추가로 크레딧 결제 진행. 어쩌고 저쩌고. 그렇다. so good.',
    user: 'Ted',
    timestamp: '09/13/2021 08:59:59',
  },
  {
    text: 'another plan added.',
    user: 'Sydney',
    timestamp: '09/13/2021 08:59:59',
  },
  {
    text: '계약 날짜 수정함 기존 7/13-7/30 에서 7/13-9/30일로 변경',
    user: 'Jessica',
    timestamp: '09/13/2021 08:59:59',
  },
  {
    text: '계약 날짜 수정함 기존 7/13-7/30 에서 7/13-9/30일로 변경. \n계약 날짜 수정함 기존 7/13-7/30 에서 7/13-9/30일로 변경\n참고 010-1111-1111',
    user: 'Randy',
    timestamp: '09/13/2021 08:59:59',
  },
  {
    text: '업체최초계약. 입금은 7/14예정. 크레딧 6,000 지급하기로협의완료함',
    user: 'Randy',
    timestamp: '09/13/2021 08:59:59',
  },
  {
    text: '계약 날짜 수정함 기존 7/13-7/30 에서 7/13-9/30일로 변경',
    user: 'Peona',
    timestamp: '09/13/2021 08:59:59',
  },
  {
    text: '추가로 크레딧 결제 진행. 어쩌고 저쩌고. 그렇다. so good.',
    user: 'Ted',
    timestamp: '09/13/2021 08:59:59',
  },
  {
    text: 'another plan added.',
    user: 'Sydney',
    timestamp: '09/13/2021 08:59:59',
  },
  {
    text: '계약 날짜 수정함 기존 7/13-7/30 에서 7/13-9/30일로 변경',
    user: 'Jessica',
    timestamp: '09/13/2021 08:59:59',
  },
  {
    text: '계약 날짜 수정함 기존 7/13-7/30 에서 7/13-9/30일로 변경. \n계약 날짜 수정함 기존 7/13-7/30 에서 7/13-9/30일로 변경\n참고 010-1111-1111',
    user: 'Randy',
    timestamp: '09/13/2021 08:59:59',
  },
];

const CommentHistoryModalContainer = ({
  commentHistoryModalOpen,
  closeCommentHistoryModal,
}: Props) => {
  const initialEditingComment = {
    id: -1,
    text: '',
  };
  const [newCommentText, setNewCommentText] = useState<string>('');
  const [editingComment, setEditingComment] =
    useState<{ id: number; text: string }>(initialEditingComment);

  const onChangeEditComment = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditingComment({
      ...editingComment,
      text: value,
    });
  };

  const onChangeNewComment = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewCommentText(value);
  };

  const saveNewComment = () => {
    // 저장
  };

  const updateComment = () => {
    alert('저장 완료');
    resetEditingComment();
  };

  const resetEditingComment = () => {
    setEditingComment(initialEditingComment);
  };

  const deleteComment = () => {
    confirm('삭제하시?');
    resetEditingComment();
    // get list
  };
  return (
    <BasicModal
      open={commentHistoryModalOpen}
      title={'Comment'}
      contentDividers={false}
      onClose={closeCommentHistoryModal}
      actionButton={
        <Button variant={'contained'} onClick={closeCommentHistoryModal}>
          Close
        </Button>
      }
    >
      <CommentWrapper>
        <CommentInput
          value={newCommentText}
          onChange={onChangeNewComment}
          onSave={saveNewComment}
          placeholder={'Create comments'}
        />
        {commentdummy.map((v, i) => (
          <CommentBox key={i}>
            <Typography variant={'subtitle2'}>{v.user}</Typography>
            {editingComment.id === i ? (
              <CommentInput
                value={editingComment.text}
                onChange={onChangeEditComment}
                onCancel={resetEditingComment}
                onSave={updateComment}
                saveButtonLabel={'Update'}
                showCancel
                autoFocus
              />
            ) : (
              <ContentSection>
                <CommentContent>
                  <CommentText
                    dangerouslySetInnerHTML={{ __html: v.text.replace(/\n/gi, '<br/>') }}
                  />
                  <Typography variant={'caption'} display={'block'} color={'textSecondary'}>
                    {`[Posted] UTC: ${v.timestamp} / KST: ${getUtcUSFormatDateWithTime(
                      v.timestamp,
                    )}`}
                  </Typography>
                  {i % 2 === 0 && ( // todo 나중에 고쳐야함
                    <Typography variant={'caption'} display={'block'} color={'textSecondary'}>
                      {`[Updated] UTC: ${v.timestamp} / KST: ${getUtcUSFormatDateWithTime(
                        v.timestamp,
                      )}`}
                    </Typography>
                  )}
                </CommentContent>
                <EditButtons>
                  <IconButton
                    size={'small'}
                    onClick={() => setEditingComment({ id: i, text: v.text })}
                  >
                    <Create fontSize={'small'} color={'action'} />
                  </IconButton>
                  <IconButton size={'small'} onClick={deleteComment}>
                    <Delete fontSize={'small'} />
                  </IconButton>
                </EditButtons>
              </ContentSection>
            )}
          </CommentBox>
        ))}
      </CommentWrapper>
    </BasicModal>
  );
};

export default CommentHistoryModalContainer;

const CommentContent = styled.div`
  flex-direction: column;
`;

const EditButtons = styled.div`
  display: block;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const Button = styled(MuiButton)(spacing);

const CommentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 0;
  border-bottom: 0.5px solid ${props => props.theme.palette.grey[200]};
  &:last-of-type {
    border-bottom: 0;
  }
`;

const CommentText = styled.p<{ fontSize?: number; color?: string }>`
  margin: 0;
  font-size: ${({ fontSize = 14 }) => fontSize}px;
  font-weight: 400;
  color: ${({ color = 'initial' }) => color};
`;

const CommentWrapper = styled.div`
  max-height: 70vh;
`;
