import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { parse } from 'modules/utils/parse';
import { BenchmarkData, BenchmarkListItem, GetBenchmarkResponse } from 'types/member';
import { RootState } from '../rootReducer';

export interface BenchmarkState {
  list: {
    loading: boolean;
    data: BenchmarkData;
    error: any;
  };
  detailItem: {
    loading: boolean;
    data: BenchmarkListItem | null;
    error: boolean;
  };
}

const initialState: BenchmarkState = {
  list: {
    loading: false,
    data: { currentPage: 1, totalPages: 0, items: [], totalItems: 0 },
    error: null,
  },
  detailItem: {
    loading: false,
    data: null,
    error: false,
  },
};

export const benchmarkSlice = createSlice({
  name: 'benchmark',
  initialState,
  reducers: {
    getBenchmarkData: (state: BenchmarkState, _action: PayloadAction<{ page: number }>) => {
      state.list.loading = true;
    },
    getBenchmarkDataSuccess: (
      state: BenchmarkState,
      { payload }: PayloadAction<GetBenchmarkResponse>,
    ) => {
      state.list.loading = false;
      payload.items = payload.items.map(({ result, ...rest }) => {
        return { ...rest, result: parse(result as string) };
      });
      state.list.data = payload;
    },
    getBenchmarkDataFailure: (state: BenchmarkState, action: PayloadAction<any>) => {
      state.list.loading = false;
      state.list.error = action.payload;
    },
    getBenchmarkItem: (state: BenchmarkState, action: PayloadAction<{ id: string }>) => {
      const item = state.list.data.items.find(item => item.seq === Number(action.payload.id));

      if (item) {
        state.detailItem = {
          loading: false,
          data: item,
          error: false,
        };
      } else {
        /**
         * TODO: 목록에서 찾지 못 했을 때 API 조회 하도록 API 추가
         * */
        state.detailItem = {
          loading: false,
          data: null,
          error: true,
        };
      }
    },
  },
});

export const benchmarkActions = benchmarkSlice.actions;
export const benchmarkReducer = benchmarkSlice.reducer;

export const selectBenchmarkDataLoading = (state: RootState): boolean =>
  state.benchmark.list.loading;
export const selectBenchmarkData = (state: RootState) => state.benchmark.list.data;
export const selectBenchmarkDetailLoading = (state: RootState): boolean =>
  state.benchmark.detailItem.loading;
export const selectBenchmarkDetailError = (state: RootState): boolean =>
  state.benchmark.detailItem.error;
export const selectBenchmarkDetailItem = (state: RootState): BenchmarkListItem | null =>
  state.benchmark.detailItem.data;
