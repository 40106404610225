import { getSubdomain, getTokenName } from 'modules/utils/domain';
export const NODE_ENV = process.env.NODE_ENV || 'development';
export const API_URL = process.env.API_URL || 'https://testadmin.clo-set.com';
export const CORE_API_URL = process.env.CORE_API_URL || 'https://test-admin.api.clo-set.com';
export const MD_STORE_DETAIL_URL =
  process.env.MD_STORE_DETAIL_URL || 'https://staging.marvelousdesigner.com/store/detail';
const TOKEN_PREFIX = process.env.SUB_DOMAIN === 'admin' ? 'admin' : 'test_admin';
export const subdomain = getSubdomain(API_URL);
export const tokenName = getTokenName(TOKEN_PREFIX);
export const GOOGLE_OAUTH_CLIENT_ID =
  '541142479039-fopc8h62j1ees628hp0kr7q5ie9hivqj.apps.googleusercontent.com'; // live === test
export const isDevelop = TOKEN_PREFIX === 'test_admin';
export const domain = API_URL; // 클라이언트, 서버 공용으로 사용 하는 domain
export const coreDomain = CORE_API_URL; // 새로운 azure backend admin project 에서 사용하는 domain
export const connectSubDomain = process.env.CONNECT_SUB_DOMAIN || 'test-connect';
export const STORAGE_LOCATION = process.env.STORAGE_LOCATION || 'teststoragefiles';
// TODO: 레거시 관련 코드 제거
