import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import { parseStoreQueryString } from 'modules/revenue';
import qs, { ParsedQs } from 'qs';
import { StoreType } from 'types/common';
import {
  CommissionNetIncomeFilter,
  CommissionNetIncomeInfo,
  ItemNetIncomeFilter,
} from 'types/marketplace/netIncome';

interface State {
  loading: boolean;
  data?: CommissionNetIncomeInfo;
  error: boolean;
  filter: CommissionNetIncomeFilter;
}

const defaultFilter: ItemNetIncomeFilter = {
  date: new Date(),
  store: [StoreType.CONNECT, StoreType.MD],
  keyword: '',
};

const initialState: State = {
  loading: false,
  error: false,
  filter: defaultFilter,
};

export const commissionNetIncomeSlice = createSlice({
  name: 'commissionNetIncome',
  initialState,
  reducers: {
    initialize: (state, { payload }: PayloadAction<ParsedQs>) => {
      const { year, month, store, keyword } = payload;

      if (!year || !month) {
        state.filter = initialState.filter;
      } else {
        const date = new Date();
        date.setFullYear(Number(year));
        date.setMonth(Number(month) - 1);
        state.filter = {
          date,
          store: parseStoreQueryString(store, initialState.filter.store),
          keyword: (keyword ?? initialState.filter.keyword) as string,
        };
      }
    },
    loadData: state => {
      state.loading = true;
      state.error = false;
      state.data = undefined;
    },
    loadDataSuccess: (state, { payload }: PayloadAction<CommissionNetIncomeInfo>) => {
      state.loading = false;
      state.data = payload;
    },
    loadDataFailure: state => {
      state.loading = false;
      state.error = true;
    },
    resetFilter: state => {
      state.filter = { ...defaultFilter };
    },
    search: (state, { payload }: PayloadAction<CommissionNetIncomeFilter>) => {
      state.filter = payload;
    },
  },
});

export const commissionNetIncomeActions = commissionNetIncomeSlice.actions;
export const commissionNetIncomeReducer = commissionNetIncomeSlice.reducer;

export const selectCommissionNetIncomeFilter = (state: RootState) =>
  state.netIncome.commission.filter;
export const selectCommissionNetIncomeIsLoading = (state: RootState) =>
  state.netIncome.commission.loading;

export const selectCommissionNetIncomeDataList = (state: RootState) =>
  state.netIncome.commission.data?.details;
export const selectCommissionNetIncomeTotalCount = (state: RootState) =>
  state.netIncome.commission.data?.totalCount;
export const selectCommissionNetIncomeTotalCommission = (state: RootState) =>
  state.netIncome.commission.data?.totalCommission;
export const selectCommissionNetIncomeQueryString = (state: RootState) => {
  const {
    filter: { date, ...rest },
  } = state.netIncome.commission;

  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  return qs.stringify({ year, month, ...rest }, { arrayFormat: 'repeat' });
};
