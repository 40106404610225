import * as React from 'react';
import styled from 'styled-components';
import { TableCell } from 'types/common/table';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';
import { UploadTestData } from 'types/member';
import { getUtcDateShortMonthWithTime } from '../../../modules/utils/time';

interface Props {
  uploadTestData: UploadTestData;
}

const UploadTest = ({ uploadTestData }: Props) => {
  const testColumns: TableCell<any>[] = [
    {
      align: 'center',
      name: 'Progress',
      key: '0',
      render: rowData => <div>{rowData?.progress}</div>,
    },
    {
      align: 'center',
      name: 'Start time',
      key: '1',
      render: rowData => <div>{getUtcDateShortMonthWithTime(rowData?.startTime)}</div>,
    },
    {
      align: 'center',
      name: 'Finish Time',
      key: '2',
      render: rowData => <div>{getUtcDateShortMonthWithTime(rowData?.finishTime)}</div>,
    },
    {
      align: 'center',
      name: 'Time',
      key: '3',
      render: rowData => <div>{`${rowData?.time}s`}</div>,
    },
    { align: 'center', name: 'bps', key: '4', render: rowData => <div>{rowData?.bps}</div> },
    { align: 'center', name: 'Status', key: '5', render: rowData => <div>{rowData?.status}</div> },
    {
      align: 'center',
      name: 'Loading',
      key: '6',
      render: rowData => <div>{String(rowData?.loading)}</div>,
    },
  ];

  return (
    <TableBlock margin={'20px 0 0 0'}>
      <DataTableLayout tableTitle={'Upload Test'}>
        <DataTableHeader header={testColumns} />
        <NewDataTableBody dataList={[uploadTestData]} columns={testColumns} />
      </DataTableLayout>
    </TableBlock>
  );
};

export default UploadTest;

export const TableBlock = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  margin: ${({ margin = '0' }) => margin};
`;
