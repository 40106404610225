import * as React from 'react';
import { ReactElement } from 'react';
import { TypoSubHeader } from '@closet-design-system/core';
import { MainBannerTypoVariant } from '.';
import { createMarkup } from 'modules/utils/html';

export interface Props {
  content: string;
  typoSubHeaderVariant: MainBannerTypoVariant[];
}

const Title = ({ content, typoSubHeaderVariant }: Props): ReactElement => {
  return (
    <TypoSubHeader variant={typoSubHeaderVariant}>
      <div dangerouslySetInnerHTML={createMarkup(content)} />
    </TypoSubHeader>
  );
};

export default Title;
