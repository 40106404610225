import * as React from 'react';
import { ReactElement } from 'react';
import DownloadsContainer from 'containers/worker/downloads/DownloadsContainer';

export const WORKER_DOWNLOADS_PATH = '/worker/downloads';

const Downloads = (): ReactElement => {
  return <DownloadsContainer />;
};

export default Downloads;
