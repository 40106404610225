import * as React from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import DataTableCell from 'components/common/table/datatable/DataTableCell';
import { makeRowStyleFormat } from 'components/common/table/TableStyle';
import { DataObject, TableCell } from 'types/common/table';
import { useMemo } from 'react';

interface Props<T> {
  row: T;
  columns: TableCell<T>[];
  rowStyle?: (rowData: T) => CSSProperties;
  rowIndex: number;
}

const DataTableRow = <T extends DataObject>({ row, columns, rowStyle, rowIndex }: Props<T>) => {
  /* Table 내부에서 input으로 값이 수정되는 경우 makeRowStyleFormat() 호출로
  input의 focus가 날라가는 현상이 발생.
  dependency를 제외한 useMemo를 이용하여 처음 한번만 작업이 수행되도록 처리. 새로운 방법을 발견할 경우 수정 by Hunter
  */
  const StyledCustomRow = useMemo(() => makeRowStyleFormat<T>(row, rowStyle), []);
  return (
    <StyledCustomRow key={rowIndex} hover>
      {columns.map((column, columnIndex) => {
        const { render, key = '', ...cellProps } = column;
        if (render) {
          return (
            <DataTableCell key={columnIndex} {...cellProps}>
              {render(row, column, rowIndex)}
            </DataTableCell>
          );
        }
        return (
          <DataTableCell key={columnIndex} {...cellProps}>
            {row[key]}
          </DataTableCell>
        );
      })}
    </StyledCustomRow>
  );
};

export default DataTableRow;
