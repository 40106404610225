import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import {
  GetBillingDetailParams,
  GetBillingInvoiceListParams,
  GetBillingInvoiceListResponse,
  InvoiceItem,
  InvoiceItemDetail,
  PaymentMethod,
  PostRefundRequestParams,
} from 'types/plansales/billing';
import { GetInvoiceDetailResponse } from 'types/plansales/billing/invoice';
import { BillingInvoice } from 'types/plansales/billing';

const DEFAULT_INVOICE_DETAIL = {
  registeredAt: '',
  avalaraDocCode: '',
  billingAddress: {
    country: '',
    address: '',
    address2: null,
    city: '',
    state: '',
    zipCode: '',
    vatNumber: '',
  },
  groupInvoicePayment: null,
  invoiceNo: '',
  groupId: '',
  groupName: '',
  adminEmail: '',
  adminUserId: -1,
  planType: -1,
  taxAmount: 0,
  totalAmount: 0,
  subtotalAmount: 0,
  paymentMethod: 0,
  paymentStatus: PaymentMethod.STRIPE,
  paymentSuccessedAt: '',
  invoiceItems: [],
  refund: null,
  refundedAt: null,
  invoiceItemDetail: {},
  pgInvoiceId: null,
};

export interface BillingState {
  list: {
    loading: boolean;
    data: GetBillingInvoiceListResponse;
    error: any;
  };
  detail: {
    loading: boolean;
    data: GetInvoiceDetailResponse;
    error: any;
  };
  export: {
    loading: boolean;
    data: BillingInvoice[];
    error: any;
    ready: boolean;
  };
  refund: {
    loading: boolean;
    data: any;
    error: any;
    refresh: boolean;
  };
}

const initialState: BillingState = {
  list: {
    loading: false,
    data: {
      currentPage: 0,
      invoices: [],
      links: {}, // todo
      totalItems: 0,
      totalPages: 0,
    },
    error: null,
  },
  detail: {
    loading: false,
    data: DEFAULT_INVOICE_DETAIL,
    error: null,
  },
  export: {
    loading: false,
    data: [],
    error: null,
    ready: false,
  },
  refund: {
    loading: false,
    data: {},
    error: '', // todo error model 맞춰서 조건 넣기
    refresh: false,
  },
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    getSearchResult: (state: BillingState, _action: PayloadAction<GetBillingInvoiceListParams>) => {
      state.list.loading = true;
    },
    getSearchResultSuccess: (
      state: BillingState,
      action: PayloadAction<GetBillingInvoiceListResponse>,
    ) => {
      state.list.loading = false;
      state.list.data = action.payload;
    },
    getSearchResultFailure: (state: BillingState, action: PayloadAction<any>) => {
      state.list.loading = false;
      state.list.error = action.payload;
    },
    setExportReady: (state: BillingState, action: PayloadAction<boolean>) => {
      state.export.ready = action.payload;
    },
    getExportData: (state: BillingState, _action: PayloadAction<GetBillingInvoiceListParams>) => {
      state.export.loading = true;
    },
    getExportDataSuccess: (state: BillingState, action: PayloadAction<any>) => {
      state.export.data = action.payload;
      state.export.loading = false;
    },
    getExportDataFailure: (state: BillingState, action: PayloadAction<any>) => {
      state.export.loading = false;
      state.export.error = action.payload;
    },
    getBillingDetail: (state: BillingState, _action: PayloadAction<GetBillingDetailParams>) => {
      state.detail.loading = true;
      state.detail.data = DEFAULT_INVOICE_DETAIL;
    },
    getBillingDetailSuccess: (
      state: BillingState,
      action: PayloadAction<GetInvoiceDetailResponse>,
    ) => {
      state.detail.data = action.payload;
      state.detail.data.invoiceItemDetail = action.payload.invoiceItems.reduce(
        (result: InvoiceItemDetail, current: InvoiceItem) => ({
          ...result,
          [current.invoiceItemType]: current,
        }),
        {},
      );
      state.detail.loading = false;
    },
    getBillingDetailFailure: (state: BillingState, _action: PayloadAction<any>) => {
      state.detail.loading = false;
    },
    requestRefund: (state: BillingState, _action: PayloadAction<PostRefundRequestParams>) => {
      state.refund.loading = true;
    },
    requestRefundSuccess: (state: BillingState, action: PayloadAction<any>) => {
      state.refund.data = action.payload;
      state.refund.loading = false;
      state.refund.refresh = true;
    },
    requestRefundFailure: (state: BillingState, _action: PayloadAction<any>) => {
      state.refund.loading = false;
      state.refund.error = 'The refund failed due to insufficient balance of the Stripe';
    },
    resetRefundState: (state: BillingState) => {
      // TODO 에러형태 보고 고쳐야함.
      state.refund.error = '';
      state.refund.refresh = false;
    },
  },
});

export const billingActions = billingSlice.actions;
export const billingReducer = billingSlice.reducer;

export const selectSearchResult = (state: RootState) => state.billing.list.data;
export const selectSearchResultLoading = (state: RootState) => state.billing.list.loading;
export const selectExportLoading = (state: RootState) => state.billing.export.loading;
export const selectExportData = (state: RootState) => state.billing.export.data || [];
export const selectBillingDetail = (state: RootState) => state.billing.detail.data;
export const selectBillingDetailLoading = (state: RootState) => state.billing.detail.loading;
export const selectRequestRefundLoading = (state: RootState) => state.billing.refund.loading;
export const selectExportReady = (state: RootState) => state.billing.export.ready;
export const selectRefundState = (state: RootState) => {
  const { error, refresh } = state.billing.refund;
  return { error, refresh };
};
