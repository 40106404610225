import * as React from 'react';
import { BaseTextFieldProps, TextField, Typography } from '@material-ui/core';
import RequiredMark from 'components/common/RequiredMark';
import { ReactElement } from 'react';

interface Props extends BaseTextFieldProps {
  title?: string;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
}

const InputText = ({
  title,
  required,
  onChange,
  helperText,
  error,
  placeholder,
  value,
  maxLength,
  ...rest
}: Props): ReactElement => {
  return (
    <>
      {title && (
        <Typography variant="h6" gutterBottom>
          {title}
          {required && <RequiredMark />}
        </Typography>
      )}
      <TextField
        error={error}
        helperText={error && helperText}
        variant="outlined"
        size="small"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        inputProps={{ maxLength }}
        {...rest}
      />
    </>
  );
};

export default InputText;
