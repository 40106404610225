import { coreDomain } from 'services/config';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import coreApi from 'api/coreApi';

export interface BaseQueryError {
  status?: number;
  data: any;
}
export type AxiosBaseQuery = BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    onError?: (queryError: BaseQueryError) => void;
  },
  unknown,
  BaseQueryError
>;
const coreApiBaseQuery =
  (): AxiosBaseQuery =>
  async ({ url, method, data, params, onError }) => {
    try {
      const result = await coreApi({ url: coreDomain + url, method, data, params });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      const errorResult = {
        status: err.response?.status,
        data: err.response?.data || err.message,
      };

      onError?.(errorResult);

      return { error: errorResult };
    }
  };

export default coreApiBaseQuery;
