import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import { Pagination as MuiPagination } from '@material-ui/lab';
import { spacing } from '@material-ui/system';

interface Props {
  mt?: number;
  count: number;
  onChange?: (pageIndex: number) => void;
  currentPageIndex: number;
}

const Pagination = ({ mt = 0, count, onChange, currentPageIndex }: Props) => {
  return (
    <TablePagination
      mt={mt}
      count={count}
      variant="text"
      shape="rounded"
      color="secondary"
      size={'small'}
      onChange={(event: object, page: number) => {
        onChange?.(page);
      }}
      page={currentPageIndex}
      siblingCount={1}
      boundaryCount={2}
    />
  );
};

export default memo(Pagination);

export const TablePagination = styled(MuiPagination)(spacing);
