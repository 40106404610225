import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import {
  ExcelMemberExport,
  ExcelMemberExportParams,
  GetMemberExcelResponse,
  GetMemberListParams,
  GetMembersResponse,
} from 'types/member';
import { Nullable } from 'types/common';

export interface MemberListState {
  list: {
    loading: boolean;
    data: GetMembersResponse;
    error: any;
  };
  mailListExport: {
    loading: boolean;
    data: GetMemberExcelResponse;
    error: any;
    ready: boolean;
  };
  marketingAgreeExport: {
    loading: {
      closet: boolean;
      connect: boolean;
    };
    data: GetMemberExcelResponse;
    error: any;
    ready: {
      closet: boolean;
      connect: boolean;
    };
  };
}

const initialState: MemberListState = {
  list: {
    loading: false,
    data: {
      currentPage: 0,
      items: [],
      links: {}, // todo
      totalItems: 0,
      totalPages: 0,
    },
    error: null,
  },
  mailListExport: {
    loading: false,
    data: {
      currentPage: 0,
      items: [],
      links: {}, // todo
      totalItems: 0,
      totalPages: 0,
    },
    error: null,
    ready: false,
  },
  marketingAgreeExport: {
    loading: { closet: false, connect: false },
    data: {
      currentPage: 0,
      items: [],
      links: {}, // todo
      totalItems: 0,
      totalPages: 0,
    },
    error: null,
    ready: { closet: false, connect: false },
  },
};

export const memberListSlice = createSlice({
  name: 'memberList',
  initialState,
  reducers: {
    setMailListExportReady: (state: MemberListState, action: PayloadAction<boolean>) => {
      state.mailListExport.ready = action.payload;
    },
    getMailListExportData: (
      state: MemberListState,
      _action: PayloadAction<ExcelMemberExportParams>,
    ) => {
      state.mailListExport.loading = true;
    },
    getMailListExportDataSuccess: (
      state: MemberListState,
      action: PayloadAction<GetMemberExcelResponse>,
    ) => {
      state.mailListExport.data = action.payload;
      state.mailListExport.loading = false;
    },
    getMailListExportDataFailure: (state: MemberListState, action: PayloadAction<any>) => {
      state.mailListExport.loading = false;
      state.mailListExport.error = action.payload;
    },
    setMarketingAgreeExportReady: (
      state: MemberListState,
      action: PayloadAction<{ value: boolean; type: Nullable<ExcelMemberExport> }>,
    ) => {
      state.marketingAgreeExport.ready = {
        closet: action.payload.type === ExcelMemberExport.CLOSET_MARKETING,
        connect: action.payload.type === ExcelMemberExport.CONNECT_MARKETING,
      };
    },
    getMarketingAgreeExportData: (
      state: MemberListState,
      action: PayloadAction<ExcelMemberExportParams>,
    ) => {
      state.marketingAgreeExport.loading = {
        closet: action.payload.type === ExcelMemberExport.CLOSET_MARKETING,
        connect: action.payload.type === ExcelMemberExport.CONNECT_MARKETING,
      };
    },
    getMarketingAgreeExportDataSuccess: (
      state: MemberListState,
      action: PayloadAction<GetMemberExcelResponse>,
    ) => {
      state.marketingAgreeExport.data = action.payload;
      state.marketingAgreeExport.loading = { closet: false, connect: false };
    },
    getMarketingAgreeExportDataFailure: (state: MemberListState, action: PayloadAction<any>) => {
      state.marketingAgreeExport.loading = { closet: false, connect: false };
      state.marketingAgreeExport.error = action.payload;
    },
    getMemberList: (state: MemberListState, _action: PayloadAction<GetMemberListParams>) => {
      state.list.loading = true;
    },
    getMemberListSuccess: (state: MemberListState, action: PayloadAction<GetMembersResponse>) => {
      state.list.loading = false;
      state.list.data = action.payload;
    },
    getMemberListFailure: (state: MemberListState, action: PayloadAction<any>) => {
      state.list.loading = false;
      state.list.error = action.payload;
    },
  },
});

export const memberListReducer = memberListSlice.reducer;
export const memberListActions = memberListSlice.actions;

export const selectMemberList = (state: RootState) => state.member.memberList.list.data;
export const selectMemberListLoading = (state: RootState) => state.member.memberList.list.loading;

export const selectMailListExportData = (state: RootState) =>
  state.member.memberList.mailListExport.data.items || [];
export const selectMailListExportReady = (state: RootState) =>
  state.member.memberList.mailListExport.ready;
export const selectMailListExportLoading = (state: RootState) =>
  state.member.memberList.mailListExport.loading;
export const selectMarketingAgreeExportData = (state: RootState) =>
  state.member.memberList.marketingAgreeExport.data.items || [];
export const selectMarketingAgreeExportReady = (state: RootState) =>
  state.member.memberList.marketingAgreeExport.ready;
export const selectMarketingAgreeExportLoading = (state: RootState) =>
  state.member.memberList.marketingAgreeExport.loading;
