import { PayloadAction } from '@reduxjs/toolkit';
import { ArtworkParams, HistoryType } from 'types/api/jinny';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { jinnyActions } from 'features/jinny/jinnySlice';
import * as api from 'api/jinny';
import { JINNY_ARTWORK_URLPATH } from 'routes/ClosetAdminRoutes';

export function* getArtworkSaga() {
  const { data } = yield call(api.getArtwork);
  if (data) {
    yield put(jinnyActions.getArtworkSuccess(data));
  } else {
    yield put(jinnyActions.getArtworkFailure(data));
  }
}

export function* editArtworkSaga(action: PayloadAction<ArtworkParams & HistoryType>) {
  const { formData, history } = action.payload;
  const { data, error } = yield call(api.editArtwork, { formData });
  if (error) {
    const errors = error.response?.data;
    yield put(jinnyActions.editArtworkFailure(errors));
  } else {
    yield put(jinnyActions.editArtworkSuccess(data));
    history.push(JINNY_ARTWORK_URLPATH);
  }
}

export default function* jinnySaga() {
  yield all([
    takeLatest(jinnyActions.getArtwork.type, getArtworkSaga),
    takeLatest(jinnyActions.editArtwork.type, editArtworkSaga),
  ]);
}
