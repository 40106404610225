import { select, takeEvery } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { createMidBanner, editMidBanner, getMidBanner } from 'api/marketplace/operation/midBanners';
import {
  midBannerFormActions,
  selectIsValidMidBannerFormData,
  selectMidBannerFormId,
  selectMidBannerFromData,
} from 'features/marketplace/operation/midBanner/midBannerFormSlice';
import { midBannerActions } from 'features/marketplace/operation/midBanner/midBannerSlice';
import { History } from 'history';
import { makeFormData, parseMidBanner } from 'modules/midBanner';
import { all, call, put } from 'redux-saga/effects';
import { MID_BANNER_PATH } from 'routes/marketplace/operation';
import { MidBannerFormData } from 'types/marketplace';

export function* createBannerSaga({ payload: history }: PayloadAction<History>) {
  // check validate form data
  yield put(midBannerFormActions.validateFromData());
  const isValidationFormData: Boolean = yield select(selectIsValidMidBannerFormData);
  if (isValidationFormData) return;

  const data: MidBannerFormData = yield select(selectMidBannerFromData);
  yield put(midBannerFormActions.setIsLoading(true));
  try {
    const { status } = yield call(createMidBanner, makeFormData(data));
    if (status === 200) {
      yield put(midBannerActions.loadOnGoingList());
      history.replace(`${MID_BANNER_PATH}`);
    }
  } catch (e) {
    alert('Error');
  }
  yield put(midBannerFormActions.setIsLoading(false));
}

export function* editBannerSaga({ payload: history }: PayloadAction<History>) {
  // check validate form data
  yield put(midBannerFormActions.validateFromData());
  const isValidationFormData: Boolean = yield select(selectIsValidMidBannerFormData);
  if (isValidationFormData) return;

  const data: MidBannerFormData = yield select(selectMidBannerFromData);
  const id: string | undefined = yield select(selectMidBannerFormId);
  if (!id) return;

  yield put(midBannerFormActions.setIsLoading(true));
  try {
    const { status } = yield call(editMidBanner, { id, formData: makeFormData(data) });
    if (status === 200) {
      yield put(midBannerActions.loadOnGoingList());
      history.replace(`${MID_BANNER_PATH}`);
    }
  } catch (err) {
    alert('Error');
  }
  yield put(midBannerFormActions.setIsLoading(false));
}

export function* loadFormDataSaga({ payload: id }: PayloadAction<string>) {
  yield put(midBannerFormActions.setIsLoading(true));
  const { data } = yield call(getMidBanner, { id });
  yield put(midBannerFormActions.setInitialFormData());
  yield put(midBannerFormActions.setFormData({ id, ...(yield call(parseMidBanner, data)) }));
  yield put(midBannerFormActions.setIsLoading(false));
}

export default function* midBannerFormSaga() {
  yield all([
    takeEvery(midBannerFormActions.createBanner, createBannerSaga),
    takeEvery(midBannerFormActions.loadFormData, loadFormDataSaga),
    takeEvery(midBannerFormActions.editBanner, editBannerSaga),
  ]);
}
