import * as React from 'react';
import { ButtonObject, SearchDateTerms } from 'types/common/searchfilter';
import { searchDateTermsLabel } from 'constants/common/searchfilter';
import { Dispatch, SetStateAction } from 'react';
import DateRangePicker from 'components/common/searchfilter/contents/DateRangePicker';
import { shortcuts } from './shorcutDates';
import FilterElementLayout from 'components/common/searchfilter/FilterElementLayout';

interface Props {
  label: string;
  dateInterval: string[];
  setDateInterval: Dispatch<SetStateAction<string[]>>;
  selectedShortcut: SearchDateTerms | null;
  setSelectedShortcut: Dispatch<SetStateAction<SearchDateTerms | null>>;
}

const DateRangeFilter = ({
  label,
  dateInterval,
  setDateInterval,
  selectedShortcut,
  setSelectedShortcut,
}: Props) => {
  const shortcutButtons: ButtonObject[] = shortcuts.map(
    ({ terms, date: { from, to }, selected }) => ({
      name: searchDateTermsLabel[terms as SearchDateTerms],
      onClick: () => {
        setDateInterval([from, to]);
        setSelectedShortcut(terms);
      },
      selected: selected ? selected : Number(selectedShortcut) === terms,
    }),
  );

  return (
    <FilterElementLayout label={label} gridSize={1}>
      <DateRangePicker
        dateInterval={dateInterval}
        setDateInterval={setDateInterval}
        shortcutButtons={shortcutButtons}
        setSelectedShortcut={setSelectedShortcut}
      />
    </FilterElementLayout>
  );
};

export default DateRangeFilter;
