import { CssBaseline, Paper as MuiPaper, withWidth } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import ClosetDrawer from 'components/common/sidebar/ClosetDrawer';
import * as React from 'react';
import { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { RouteInfoType } from 'types/common/routes';
import { GlobalStyleProps } from 'types/types';
import Footer from '../Footer';
import Header from '../Header';

const drawerWidth = 260;

const BasicLayoutGlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background}; 
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const BasicLayoutRoot = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

type DashboardPropsType = {
  routes: Array<RouteInfoType>;
  width?: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
};

const BasicLayout: React.FC<DashboardPropsType> = ({ children, routes }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <BasicLayoutRoot>
      <BasicLayoutGlobalStyle />
      <CssBaseline />
      <ClosetDrawer
        routes={routes}
        drawerWidth={drawerWidth}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent pt={5} pb={5} pl={7} pr={7}>
          {children}
        </MainContent>
        <Footer />
      </AppContent>
    </BasicLayoutRoot>
  );
};

export default withWidth()(BasicLayout);
