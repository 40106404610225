import * as React from 'react';
import { CircularProgress, createStyles, makeStyles, TableCell, TableRow } from '@material-ui/core';

interface Props {
  colspan: number;
  spinnerSize?: number;
}

const SpinnerRow = ({ colspan, spinnerSize = 24 }: Props) => {
  const classes = makeStyles(() =>
    createStyles({
      tableBody: {
        height: '100%',
      },
    }),
  )();
  return (
    <TableRow>
      <TableCell className={classes.tableBody} align={'center'} colSpan={colspan}>
        <CircularProgress size={spinnerSize} />
      </TableCell>
    </TableRow>
  );
};

export default SpinnerRow;
