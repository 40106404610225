import * as React from 'react';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getUtcDateDash } from 'modules/utils/time';

interface Props {
  onChange: (date: MaterialUiPickersDate) => void;
  value: string;
  autoOk?: boolean;
  format?: string;
  disabled?: boolean;
  minDate?: string;
  maxDate?: string;
  error?: boolean;
  helperText?: string;
}
const DatePicker = ({
  onChange,
  value,
  autoOk = true,
  format = 'yyyy-MM-dd',
  disabled = false,
  minDate,
  maxDate = getUtcDateDash(new Date('3000-12-31')),
  error,
  helperText,
}: Props) => {
  return (
    <MuiDatePicker
      autoOk={autoOk}
      format={format}
      variant="inline"
      disabled={disabled}
      value={value}
      onChange={onChange}
      maxDate={maxDate}
      minDate={minDate}
      error={error}
      helperText={helperText}
    />
  );
};

export default DatePicker;
