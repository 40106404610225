import * as React from 'react';
import { ReactElement } from 'react';
import { mq } from '@closet-design-system/theme';
import { AZURE_STORAGE_PREFIX } from 'constants/common/url';
import styled from 'styled-components';

const twoPieceType1FilledEllipseUrl = `${AZURE_STORAGE_PREFIX}/public/connect/contest/layout/twoPieceType1_filled_ellipse.svg`;
const twoPieceType1LineEllipseUrl = `${AZURE_STORAGE_PREFIX}/public/connect/contest/layout/twoPieceType1_line_ellipse.svg`;

const TwoPieceType1Item1Background = (): ReactElement => {
  return (
    <TwoPieceType1Item1BackgroundRoot>
      <InnerLayout>
        <FirstEllipseWrapper>
          <img src={twoPieceType1FilledEllipseUrl} alt={'ellipse'} />
        </FirstEllipseWrapper>
        <SecondEllipseWrapper>
          <img src={twoPieceType1LineEllipseUrl} alt={'ellipse'} />
        </SecondEllipseWrapper>
      </InnerLayout>
    </TwoPieceType1Item1BackgroundRoot>
  );
};

export default TwoPieceType1Item1Background;

const TwoPieceType1Item1BackgroundRoot = styled.div`
  position: absolute;
  height: 33%;
  width: 100%;
  top: 0;
  left: 0;
`;

const InnerLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const FirstEllipseWrapper = styled.div(
  ({
    theme: {
      breakpoints: { m, l },
    },
  }) =>
    mq({ m, l })({
      width: '100%',
      height: '100%',
      position: 'relative',
      ['img']: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        width: ['110%', '110%', 580],
        height: ['80%', '75%', 220],
      },
    }),
);

const SecondEllipseWrapper = styled.div(
  ({
    theme: {
      breakpoints: { m, l },
    },
  }) =>
    mq({ m, l })({
      width: ['110%', '110%', 580],
      height: ['80%', '75%', 220],
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      ['img']: {
        width: '100%',
        height: '100%',
      },
    }),
);
