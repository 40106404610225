/**
 * Connect 의 Mid Banner 를 관리하는 API Group
 * https://clo.atlassian.net/wiki/spaces/NX/pages/1838972973/Connect+Mid+Banner+API+Document
 * author: Henry
 */

import coreApi from 'api/coreApi';
import {
  DeleteMidBannerParams,
  EditMidBannerParams,
  EditMidBannerStatusParams,
  GetHistoriesParams,
  GetHistoriesResponse,
  GetMidBannerParams,
  GetOnGoingListResponse,
} from 'types/api/marketplace/operation/midBanner';

export const getOnGoingList = () =>
  coreApi.get<GetOnGoingListResponse>('/connects/midbanners/ongogings');

export const getHistories = (params: GetHistoriesParams) =>
  coreApi.get<GetHistoriesResponse>('/connects/midbanners/histories', { params });

export const createMidBanner = (data: FormData) =>
  coreApi.post('/connects/midbanners', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const editMidBanner = ({ id, formData }: EditMidBannerParams) =>
  coreApi.put(`/connects/midbanners/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getMidBanner = ({ id }: GetMidBannerParams) =>
  coreApi.get(`/connects/midbanners/${id}`);

export const deleteMidBanner = ({ id }: DeleteMidBannerParams) =>
  coreApi.delete(`/connects/midbanners/${id}`);

export const editMidBannerStatus = ({ id, status }: EditMidBannerStatusParams) =>
  coreApi.put(`/connects/midbanners/${id}/status`, { status });
