import * as React from 'react';
import { FeatureUpdateNoticeModalFormData } from 'types/notice/featureUpdateNotice';
import styled from 'styled-components';
import useImagePreview from 'hooks/useImagePreview';
import classNames from 'classnames';

interface Props {
  data: FeatureUpdateNoticeModalFormData;
  textModal: boolean;
}
const ImageWithTextModalContent = ({ data, textModal }: Props) => {
  const { imageUrl, title, subTitle, body, footNote } = data;
  const imageDataUrl = useImagePreview(imageUrl);
  return (
    <Wrapper className={classNames({ textModal })}>
      {!textModal && <Image imageUrl={imageDataUrl ?? ''} />}
      <TextWrapper>
        <TypoTitle>{title}</TypoTitle>
        {subTitle && <TypoSubTitle>{subTitle}</TypoSubTitle>}
        {body && <TypoBody>{body}</TypoBody>}
        {footNote && <TypoFootNote>{footNote}</TypoFootNote>}
      </TextWrapper>
    </Wrapper>
  );
};

export default ImageWithTextModalContent;

const Wrapper = styled.div`
  width: 700px;
  padding: 40px 40px 0 40px;
  display: flex;
  font-family: 'Avenir Next LT Pro';

  &.textModal {
    width: 480px;
  }
`;

const Image = styled.div<{ imageUrl: string }>`
  flex-basis: 200px;
  flex-shrink: 0;
  width: 200px;
  height: 200px;
  background: url(${props => props.imageUrl}) no-repeat center;
  background-size: contain;
  border-radius: 5px;
  margin-right: 20px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TypoTitle = styled.span`
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  /* GLOBAL/G-900 */
  color: #19191a;
`;

const TypoSubTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* GLOBAL/G-900 */
  color: #19191a;
`;

const TypoBody = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.3px;
  /* GLOBAL/G-600 */
  color: #444446;
`;

const TypoFootNote = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.2px;
  /* GLOBAL/G-500 */
  color: #646466;
`;
