import { ItemConfirmHistoryProps } from 'types/common/itemConfirm';
import { PaginationAndSortProps, SearchDateRangeFilterProps } from 'types/common/searchfilter';
import { ConnectCommunityUserType } from 'types/member';

export enum CommunityTab {
  COMMUNITY_POST = 'post',
  COMMUNITY_COMMENT = 'comment',
}

export type CommunityTabType = {
  tab: CommunityTab;
};

export type CommunityPostFilterProps = SearchDateRangeFilterProps &
  CommunityPostFilterForm &
  PaginationAndSortProps;

export interface CommunityPostFilterForm {
  statuses: PostItemStatus[];
  categories: PostItemCategory[];
  keywordType: PostKeywordType;
  searchKeyword: string;
  mainPick: boolean;
}

export type CommunityCommentFilterProps = SearchDateRangeFilterProps &
  CommunityCommentFilterForm &
  PaginationAndSortProps;

export interface CommunityCommentFilterForm {
  statuses: PostItemStatus[];
  userTypes: ConnectCommunityUserType[];
  keywordType: CommentKeywordType;
  searchKeyword: string;
}

export interface PostItemProps {
  postId: string;
  title: string;
  category: number;
  creatorAccount: string;
  registeredDate: string;
  status: PostItemStatus;
  tags: string[];
  mainPick: boolean;
}

export interface CommentItemProps {
  postId: string;
  seq: number;
  title: string;
  comment: string;
  creatorAccount: string;
  registeredDate: string;
  status: PostItemStatus;
}

export type PostItemConfirmHistoryProps = ItemConfirmHistoryProps<PostItemStatus>;
export type CommentItemConfirmHistoryProps = ItemConfirmHistoryProps<PostItemStatus>;

export enum PostItemStatus {
  BLOCKED = -2,
  // NOT_SET = 0, // Comment: This value is used for testing purposes by the backend and is not actually used.
  PUBLISHED = 1,
  DRAFT = 2,
  DELETED = 14,
}

export enum PostItemCategory {
  NONE = 0,
  NOTICE = 100,
  USER_SPOTLIGHT = 110,
  COMMUNITY_GUIDE = 130,
  GENERAL = 210,
  CHALLENGE = 215,
  QNA = 220,
  PROJECTS_AND_STEPS = 230,
  TIPS_TRICKS = 240,
  USER_FEEDBACK = 250,
  JOB_BOARD = 260,
}

export enum PostSort {
  LATEST_UPLOAD_DATE = 0,
}

export enum PostKeywordType {
  TITLE = 0,
  ACCOUNT = 1,
  TAG = 2,
  POST_ID = 3,
}

export enum CommentKeywordType {
  TITLE = 0,
  ACCOUNT = 1,
  POST_ID = 2,
  COMMENT = 3,
}
