import * as React from 'react';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import { BenchmarkListItem } from 'types/member';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';
import { benchmarkListColumns } from './BenchmarkListColumns';

interface Props {
  items: BenchmarkListItem[];
  totalPages: number;
  listLoading: boolean;
  onChangePage: (pageIndex: number) => void;
  currentPageIndex: number;
}

const BehchmarkListTable = ({
  items,
  totalPages,
  listLoading,
  onChangePage,
  currentPageIndex,
}: Props) => {
  const benchmarkListColumnData = benchmarkListColumns();

  return (
    <>
      <DataTableLayout
        showPagination
        totalPages={totalPages}
        onChangePage={onChangePage}
        currentPageIndex={currentPageIndex}
        maxHeight={'59vh'}
      >
        <DataTableHeader header={benchmarkListColumnData} />
        <NewDataTableBody<BenchmarkListItem>
          loading={listLoading}
          dataList={items}
          columns={benchmarkListColumnData}
        />
      </DataTableLayout>
    </>
  );
};
export default BehchmarkListTable;
