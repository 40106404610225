import * as React from 'react';
import {
  BillingInvoiceItem,
  ClosetPlanType,
  InvoiceItemType,
  PaymentStatus,
} from 'types/plansales/billing';
import { NULL_TEXT } from 'constants/common';
import { toMantissaWithThousandComma } from 'modules/utils/number';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { getUtcAndKstTime } from 'modules/billing';
import { billingInvoiceItemLabel } from 'constants/plansales/billing';

const isPaidSuccessfully = (paymentStatus: PaymentStatus) =>
  !(
    paymentStatus === PaymentStatus.TRIED_YET ||
    paymentStatus === PaymentStatus.FAIL ||
    paymentStatus === PaymentStatus.VOID ||
    paymentStatus === PaymentStatus.CREATED
  );

export const paymentPriceCellRenderer = (totalAmount: number, paymentStatus: PaymentStatus) => {
  if (!isPaidSuccessfully(paymentStatus)) return NULL_TEXT;
  return toMantissaWithThousandComma(totalAmount, 2);
};

export const paymentDateCellRenderer = (paymentDate: string, paymentStatus: PaymentStatus) => {
  if (!isPaidSuccessfully(paymentStatus)) return NULL_TEXT;
  return <PaymentDateStyle>{getUtcAndKstTime(paymentDate)}</PaymentDateStyle>;
};

export const advancedInvSuffix = (invoiceItem: InvoiceItemType, planType: ClosetPlanType) =>
  [InvoiceItemType.FIRST_SUBSCRIPTION, InvoiceItemType.RENEW_SUBSCRIPTION].includes(invoiceItem) &&
  planType === ClosetPlanType.ADVANCED_PLAN_INV
    ? ' INV'
    : '';

export const billingInvoiceItemRenderer = (
  items: BillingInvoiceItem[],
  planType: ClosetPlanType,
) => {
  return items.map(({ invoiceItemType, amount }, index: number) => {
    return (
      <ItemText key={index}>{`${billingInvoiceItemLabel[invoiceItemType]}${advancedInvSuffix(
        invoiceItemType,
        planType,
      )} / ${amount}`}</ItemText>
    );
  });
};

const ItemText = styled.div`
  display: block;
`;

const PaymentDateStyle = styled.span`
  font-size: 13px;
`;

export const PayStatusStyle = styled.span`
  &.emphasized {
    color: red;
    font-weight: 600;
  }
`;

export const RouteText = styled(RouterLink)`
  text-decoration: underline;
  word-break: break-all;
  color: ${props => props.theme.palette.grey[900]};
`;

export const SearchableText = styled.span`
  word-break: break-all;
  &:hover {
    cursor: pointer;
  }
`;
