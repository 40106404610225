import { all, takeLatest, put, call } from 'redux-saga/effects';
import * as api from 'api/marketplace/operation/sectionManage';
import { sectionManageActions } from 'features/marketplace/operation/sectionManage/sectionManageSlice';
import { alertApiError } from 'modules/utils/error';
import { PayloadAction } from '@reduxjs/toolkit';
import { PageSection } from 'types/api/marketplace/operation/sectionManage';
import { ascOrderFn } from 'modules/utils/sort';

export function* getPageSectionsSaga() {
  yield put(sectionManageActions.setLoading(true));

  const { data, error } = yield call(api.getPageSections);
  if (data) {
    yield put(sectionManageActions.setData([...data].sort((a, b) => ascOrderFn(a.sort, b.sort))));
  } else if (error) {
    alertApiError(error);
  }

  yield put(sectionManageActions.setLoading(false));
}

export function* postPageSectionsSaga({ payload: pageSections }: PayloadAction<PageSection[]>) {
  yield put(sectionManageActions.setLoading(true));

  const { error } = yield call(api.postPageSections, pageSections);
  if (error) {
    alertApiError(error);
  }

  yield put(sectionManageActions.setLoading(false));
}

export default function* sectionManageSaga() {
  yield all([
    takeLatest(sectionManageActions.getPageSections, getPageSectionsSaga),
    takeLatest(sectionManageActions.postPageSections, postPageSectionsSaga),
  ]);
}
