import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { LayoutType } from 'types/marketplace/operation/contest';
import { Grid } from '@material-ui/core';
import AwardCommonStyleWrapper from 'components/marketplace/operation/contest/preview/common/AwardCommonStyleWrapper';
import { mq } from '@closet-design-system/theme';
import styled from 'styled-components';

const TwoPieceTypeWrapped = ({
  children,
  layoutType,
}: {
  children: ReactNode;
  layoutType: LayoutType;
}): ReactElement => {
  switch (layoutType) {
    case LayoutType.TWO_PIECE_2:
    case LayoutType.TWO_PIECE_3:
      return <AwardCommonStyleWrapper>{children}</AwardCommonStyleWrapper>;
    case LayoutType.TWO_PIECE_4:
      return (
        <TwoPieceType4Root>
          <Grid container>{children}</Grid>
        </TwoPieceType4Root>
      );
    default:
      /* TWO_PIECE_1 */
      return <TwoPieceType1Root>{children}</TwoPieceType1Root>;
  }
};

const TwoPieceType1Root = styled.div(
  ({
    theme: {
      breakpoints: { l },
    },
  }) =>
    mq({ l })({
      width: ['88%', 1264],
      margin: '0 auto',
      height: ['76vw', 1100],
      display: 'flex',
      padding: '8px 0',
    }),
);

const TwoPieceType4Root = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
`;

export default TwoPieceTypeWrapped;
