import {
  ContestAwardItemProps,
  ContestBadge,
  ContestContentProps,
  LayoutBaseTypeKey,
} from 'types/marketplace/operation/contest';
import { Nullable } from 'types/common';
import { REGEX_URL_PATH } from 'constants/marketplace/contest';
import { TypoCSSObject } from '@closet-design-system/core';
import { AZURE_STORAGE_PREFIX } from 'constants/common/url';
import { cleanMarkup } from 'modules/utils/html';

/**
 * Serialize object to formData type
 * @param payload
 */
export const serializerFormData = (payload: ContestContentProps): FormData => {
  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    if (value === null) return;
    switch (key) {
      case 'attachment':
      case 'image':
        formData.append(key, value as File);
        break;
      case 'awards':
        formData.append(key, JSON.stringify(value));
        break;
      case 'description':
        formData.append(
          key,
          cleanMarkup({ content: value, allowTags: ['iframe'], allowAttrs: ['target'] }),
        );
        break;
      default:
        formData.append(key, String(value));
        break;
    }
  });
  return formData;
};

export const onValidationLength =
  (length: number) =>
  (value: string): Nullable<string> => {
    const isValidated = value?.length <= length;
    if (!isValidated && value) {
      return `Limit to less than ${length} characters`;
    }
    return null;
  };

export const onValidationURL = (value: string): Nullable<string> => {
  const isValidated = REGEX_URL_PATH.test(value);
  if (!isValidated && value) {
    return 'Incorrect URL format.';
  }
  return null;
};

export const getDynamicTypoStyles = (
  typoArray: TypoCSSObject[],
): Record<string, TypoCSSObject[]> => {
  return typoArray.reduce<Record<string, TypoCSSObject[]>>((beforeValue, currentValue) => {
    for (const key in currentValue) {
      const currentTypo = currentValue[key];
      if (beforeValue?.[key]) {
        beforeValue[key].push(<TypoCSSObject>currentTypo);
      } else {
        beforeValue[key] = [<TypoCSSObject>currentTypo];
      }
    }
    return beforeValue;
  }, {});
};

/** layoutOrder에 맞는 아이템이 없는 경우 null로 채운 array를 반환합니다
 * ex) 3 piece인데 layout order 2인 아이템만 있는 경우 -> [null, AwardItem, null]
 **/
export const fillNullIfLayoutOrderItemEmpty = ({
  items,
  piece,
}: {
  items: ContestAwardItemProps[];
  piece: number;
}): Nullable<ContestAwardItemProps>[] => {
  const resultItems: Nullable<ContestAwardItemProps>[] = [];

  for (let i = 0; i < piece; i++) {
    const index = items.findIndex(item => item.layoutOrder === i + 1);
    if (index > -1) {
      resultItems[i] = items[index];
    } else {
      resultItems[i] = null;
    }
  }

  return resultItems;
};

/* Returns the LayoutBaseTypeKey of LayoutTypeKey */
export const getLayoutBaseTypeKey = (layoutTypeKey: string): LayoutBaseTypeKey => {
  return layoutTypeKey.slice(0, -2) as LayoutBaseTypeKey;
};

/* return AwardBadge URL */
export const getBadgeImageUrl = ({
  badgeId,
  isMobile,
  extension = 'png',
}: {
  badgeId: ContestBadge;
  isMobile: boolean;
  extension?: string;
}): string =>
  `${AZURE_STORAGE_PREFIX}/public/connect/contest/badge/awardBadge.${badgeId}${
    isMobile ? '.m' : ''
  }.${extension}`;

export const getHtmlEmbedPreview = (embedUrl: string): string => {
  const content = `<div style="position: relative; padding-bottom: 100%; height: 0;">
              <iframe
                src="${embedUrl}"
                style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
              ></iframe>
            </div>`;
  return cleanMarkup({ content, allowTags: ['iframe'] });
};
