import { PayloadAction } from '@reduxjs/toolkit';
import { mainBannerApi } from 'api/marketplace/operation/mainBanner';
import { parseMainBannerData, transformToFormData } from 'modules/mainBannerForm';
import { alertApiError } from 'modules/utils/error';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { MainBannerData } from 'types/api/marketplace/operation/mainBanner';
import { CallbackOptions } from 'types/common/callback';
import { MainBannerFormData } from 'types/marketplace/operation/mainBanner/mainBannerForm';
import { mainBannerFormActions, selectFormData } from './mainBannerFormSlice';
import { ApiResponse } from 'types/api';

export function* createBannerSaga({ payload: { options } }: PayloadAction<CallbackOptions>) {
  const data: MainBannerFormData = yield select(selectFormData);

  yield put(mainBannerFormActions.setIsLoading(true));
  const { status, error }: ApiResponse = yield call(
    mainBannerApi.createMainBanner,
    transformToFormData(data),
  );

  if (error) {
    options?.onError?.(error);
  } else if (status === 201) options?.onSuccess?.();

  yield put(mainBannerFormActions.setIsLoading(false));
}

export function* loadFormDataSaga({ payload: id }: PayloadAction<string>) {
  yield put(mainBannerFormActions.setIsLoading(true));

  const { error, data }: ApiResponse<MainBannerData> = yield call(mainBannerApi.getMainBanner, id);

  if (error) {
    alertApiError(error);
  } else if (data) {
    const mainBannerFormData: MainBannerFormData = yield call(parseMainBannerData, data);
    yield put(mainBannerFormActions.setId(id));
    yield put(mainBannerFormActions.setFormData(mainBannerFormData));
    yield put(mainBannerFormActions.setInitialFormData(mainBannerFormData));
  }

  yield put(mainBannerFormActions.setIsLoading(false));
}

export function* updateBannerSaga({
  payload: { id, options },
}: PayloadAction<{ id: string } & CallbackOptions>) {
  const data: MainBannerFormData = yield select(selectFormData);

  yield put(mainBannerFormActions.setIsLoading(true));

  const formData: FormData = transformToFormData(data);
  formData.set('Id', id);

  const { error, status }: ApiResponse = yield call(mainBannerApi.updateMainBanner, formData);

  if (error) {
    options?.onError?.(error);
  } else if (status == 200) {
    options?.onSuccess?.();
  }

  yield put(mainBannerFormActions.setIsLoading(false));
}

export default function* mainBannerFormSaga() {
  yield all([
    takeEvery(mainBannerFormActions.createBanner, createBannerSaga),
    takeEvery(mainBannerFormActions.updateBanner, updateBannerSaga),
    takeEvery(mainBannerFormActions.loadFormData, loadFormDataSaga),
  ]);
}
