import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import { getInitalValidation, getInitialMainBannerFormData } from 'modules/mainBannerForm';
import { CallbackOptions } from 'types/common/callback';
import {
  ImagePayload,
  LinkFormData,
  MainBannerFormData,
  MainBannerFormValidation,
  TextFormData,
} from 'types/marketplace/operation/mainBanner/mainBannerForm';

export interface MainBannerFormState {
  id: string;
  formData: MainBannerFormData;
  initialFormData: MainBannerFormData;
  validation: MainBannerFormValidation;
  isLoading: boolean;
}

const initialState: MainBannerFormState = {
  id: '',
  formData: getInitialMainBannerFormData(),
  initialFormData: getInitialMainBannerFormData(),
  validation: getInitalValidation(),
  isLoading: false,
};

export const mainBannerFormSlice = createSlice({
  name: 'mainBannerForm',
  initialState,
  reducers: {
    setBannerTitle: (state: MainBannerFormState, { payload }: PayloadAction<string>) => {
      state.formData.bannerTitle = payload;
    },
    setBannerLayout: (state: MainBannerFormState, { payload }: PayloadAction<string>) => {
      state.formData.bannerLayout = payload;
    },
    setImage: (
      state: MainBannerFormState,
      { payload: { key, file } }: PayloadAction<ImagePayload>,
    ) => {
      state.formData.image[key] = file;
    },
    setText: (state: MainBannerFormState, { payload }: PayloadAction<TextFormData>) => {
      state.formData.text = payload;
    },
    setBannerTheme: (state: MainBannerFormState, { payload }: PayloadAction<string>) => {
      state.formData.bannerTheme = payload;
    },
    setLink: (state: MainBannerFormState, { payload }: PayloadAction<LinkFormData>) => {
      state.formData.link = payload;
    },
    setValidation: (
      state: MainBannerFormState,
      { payload }: PayloadAction<MainBannerFormValidation>,
    ) => {
      state.validation = payload;
    },
    setFormData: (state: MainBannerFormState, { payload }: PayloadAction<MainBannerFormData>) => {
      state.formData = payload;
    },
    reset: () => initialState,
    setIsLoading: (state: MainBannerFormState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    createBanner: (_, _action: PayloadAction<CallbackOptions>) => {},
    updateBanner: (_, _action: PayloadAction<{ id: string } & CallbackOptions>) => {},
    loadFormData: (_, _action: PayloadAction<string>) => {},
    setId: (state: MainBannerFormState, { payload: id }: PayloadAction<string>) => {
      state.id = id;
    },
    setInitialFormData: (
      state: MainBannerFormState,
      { payload }: PayloadAction<MainBannerFormData>,
    ) => {
      state.initialFormData = payload;
    },
  },
});

export const mainBannerFormActions = mainBannerFormSlice.actions;
export const mainBannerFormReducer = mainBannerFormSlice.reducer;

export const selectMainBannerFormState = (rootState: RootState): MainBannerFormState =>
  rootState.marketplace.mainBannerForm;
export const selectFormData = (rootState: RootState): MainBannerFormData =>
  rootState.marketplace.mainBannerForm.formData;
export const selectValidation = (rootState: RootState): MainBannerFormValidation =>
  rootState.marketplace.mainBannerForm.validation;
