import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import BenchmarkDetailContainer from '../../../containers/benchmark/BenchmarkDetailContainer';

const BenchmarkDetail = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { id } = match.params;
  return <BenchmarkDetailContainer id={id} />;
};

export default BenchmarkDetail;
