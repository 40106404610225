import { BannerThemeType } from 'types/marketplace/operation/mainBanner/mainBannerForm';

export const DEFAULT_URL: string = 'https://';

export const INITIAL_BANNER_THEME: string = `${BannerThemeType.DARK}`;

export const BANNER_TITLE_MAX = 50;
export const TEXT_TITLE_MAX = 50;
export const TEXT_SUBTITLE_MAX = 120;
export const TEXT_ARTWORK_SOURCE_MAX = 30;
export const LINK_BUTTON_TEXT_MAX = 20;
