import * as React from 'react';
import { ReactElement } from 'react';
import { Grid, GridSize } from '@material-ui/core';
import styled, { CSSProperties } from 'styled-components';

type TextAlignType = CSSProperties['textAlign'];
type JustifyContentType = CSSProperties['justifyContent'];

interface Props {
  label: string;
  children?: React.ReactNode;
  labelGridItemXs?: GridSize;
}

const FilterElement = ({ label, children, labelGridItemXs = 2 }: Props): ReactElement => {
  const childrenXs = 12 - (labelGridItemXs as number);
  return (
    <Grid container direction={'row'} alignItems={'center'}>
      <Grid item xs={labelGridItemXs}>
        <TextWrapper fontWeight={'600'}>{label}</TextWrapper>
      </Grid>
      <Grid item xs={childrenXs as GridSize}>
        {children}
      </Grid>
    </Grid>
  );
};
export default FilterElement;

export const TextWrapper = styled.div<{
  width?: string;
  justify?: JustifyContentType;
  fontWeight?: string;
  fontSize?: string;
  textAlign?: TextAlignType;
}>`
  width: ${({ width = 'initial' }) => width};
  max-width: 100%;
  min-width: 130px;
  word-break: break-word;
  min-height: 42px;
  height: 100%;
  display: flex;
  justify-content: ${({ justify = 'initial' }) => justify};
  align-items: center;
  font-weight: ${({ fontWeight = 'normal' }) => fontWeight};
  font-size: ${({ fontSize = '16px' }) => fontSize};
  text-align: ${({ textAlign = 'center' }) => textAlign};
`;
