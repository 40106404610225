import { getUtcUSFormatDateWithTime } from 'modules/utils/time';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { BENCHMARK_PATH } from 'routes/ClosetAdminRoutes';
import styled from 'styled-components';
import { TableCell } from 'types/common/table';
import { BenchmarkListItem } from 'types/member';

export const benchmarkListColumns = (): TableCell<BenchmarkListItem>[] => [
  {
    align: 'center',
    name: 'Email',
    key: 'email',
    render: (rowData: BenchmarkListItem) => (
      <EmailLink to={`${BENCHMARK_PATH}/${rowData.seq}`}>{rowData.email}</EmailLink>
    ),
  },
  {
    align: 'center',
    name: 'Country',
    key: 'country',
    render: ({
      result: {
        cfInfo: { country },
      },
    }: BenchmarkListItem) => <EmailText>{country}</EmailText>,
  },
  {
    align: 'center',
    name: 'Region',
    key: 'region',
    render: ({
      result: {
        cfInfo: { region },
      },
    }: BenchmarkListItem) => <EmailText>{region}</EmailText>,
  },
  {
    align: 'center',
    name: 'City',
    key: 'city',
    render: ({
      result: {
        cfInfo: { city },
      },
    }: BenchmarkListItem) => <EmailText>{city}</EmailText>,
  },
  {
    align: 'center',
    name: 'Timezone',
    key: 'timezone',
    render: ({
      result: {
        cfInfo: { timezone },
      },
    }: BenchmarkListItem) => <EmailText>{timezone}</EmailText>,
  },
  {
    align: 'center',
    name: 'Test Date',
    key: 'testDate',
    width: '220px',
    render: (rowData: BenchmarkListItem) =>
      getUtcUSFormatDateWithTime(rowData.registeredDate, true),
  },
];

const EmailLink = styled(Link)`
  text-decoration: underline;
  color: ${props => props.theme.palette.grey[900]};
`;

const EmailText = styled.span`
  word-break: break-all;
`;
