import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentLayout from 'components/common/layouts/ContentLayout';
import SearchFilter from 'components/member/SearchFilter';
import MemberListTable from 'components/member/MemberListTable';
import {
  memberListActions,
  selectMailListExportData,
  selectMailListExportLoading,
  selectMailListExportReady,
  selectMarketingAgreeExportData,
  selectMarketingAgreeExportLoading,
  selectMarketingAgreeExportReady,
  selectMemberList,
  selectMemberListLoading,
} from 'features/member/reducer/memberListSlice';
import { ExcelMemberExport } from 'types/member';
import useMemberSearchFilter from 'hooks/useMemberSearchFilter';

const MemberContainer = () => {
  const dispatch = useDispatch();
  const { items: memberList, totalPages, totalItems } = useSelector(selectMemberList);
  const memberListLoading = useSelector(selectMemberListLoading);
  const mailListExportData = useSelector(selectMailListExportData);
  const mailListExportReady = useSelector(selectMailListExportReady);
  const mailListExportLoading = useSelector(selectMailListExportLoading);
  const marketingAgreeExportData = useSelector(selectMarketingAgreeExportData);
  const marketingAgreeExportReady = useSelector(selectMarketingAgreeExportReady);
  const marketingAgreeExportLoading = useSelector(selectMarketingAgreeExportLoading);
  const mailListExportRef = useRef<HTMLButtonElement>(null);
  const closetMarketingAgreeExportRef = useRef<HTMLButtonElement>(null);
  const connectMarketingAgreeExportRef = useRef<HTMLButtonElement>(null);

  const {
    currentPageIndex,
    keyword,
    connectAbusingUserStatus,
    everywearCreatorStatus,
    connectUserType,
    connectCommunityUserType,
    onChangeKeyword,
    onKeyDownKeyword,
    getSearchResult,
    resetFilter,
    onChangeConnectAbusingUser,
    onChangeConnectEverywearStatus,
    onChangeConnectUserType,
    onChangeConnectCommunityUserType,
    onChangePage,
  } = useMemberSearchFilter();

  useEffect(() => {
    // excel export (mail list)
    if (mailListExportData.length && mailListExportRef.current && mailListExportReady) {
      mailListExportRef.current.click();
      dispatch(memberListActions.setMailListExportReady(false));
    }
    // excel export (marketing agree)
    const { closet: closetExportReady, connect: connectExportReady } = marketingAgreeExportReady;
    if (
      marketingAgreeExportData.length &&
      closetMarketingAgreeExportRef.current &&
      connectMarketingAgreeExportRef.current &&
      (closetExportReady || connectExportReady)
    ) {
      closetExportReady && closetMarketingAgreeExportRef.current.click();
      connectExportReady && connectMarketingAgreeExportRef.current.click();
      dispatch(memberListActions.setMarketingAgreeExportReady({ value: false, type: null }));
    }
  }, [
    mailListExportData,
    mailListExportReady,
    marketingAgreeExportData,
    marketingAgreeExportReady,
  ]);

  const onClickMailListExport = () => {
    dispatch(memberListActions.getMailListExportData({ type: ExcelMemberExport.All, keyword }));
  };

  const onClickMarketingAgreeExport = (type: ExcelMemberExport) => () => {
    dispatch(
      memberListActions.getMarketingAgreeExportData({
        type,
        keyword,
      }),
    );
  };

  return (
    <ContentLayout pageTitle={'Member'}>
      <SearchFilter
        keyword={keyword}
        onChangeKeyword={onChangeKeyword}
        onKeyDownKeyword={onKeyDownKeyword}
        connectAbusingUserStatus={connectAbusingUserStatus}
        connectEverywearStatus={everywearCreatorStatus}
        connectUserType={connectUserType}
        connectCommunityUserType={connectCommunityUserType}
        onChangeConnectAbusingUser={onChangeConnectAbusingUser}
        onChangeConnectEverywearStatus={onChangeConnectEverywearStatus}
        onChangeConnectUserType={onChangeConnectUserType}
        onChangeConnectCommunityUserType={onChangeConnectCommunityUserType}
        onClickSearch={getSearchResult}
        onClickReset={resetFilter}
      />
      <MemberListTable
        memberListLoading={memberListLoading}
        totalPages={totalPages}
        onChangePage={onChangePage}
        currentPageIndex={currentPageIndex}
        memberList={memberList}
        refs={{ mailListExportRef, closetMarketingAgreeExportRef, connectMarketingAgreeExportRef }}
        mailListExportData={mailListExportData}
        mailListExportLoading={mailListExportLoading}
        marketingAgreeExportData={marketingAgreeExportData}
        marketingAgreeExportLoading={marketingAgreeExportLoading}
        onClickMailListExport={onClickMailListExport}
        onClickMarketingAgreeExport={onClickMarketingAgreeExport}
        totalItems={totalItems}
      />
    </ContentLayout>
  );
};

export default MemberContainer;
