import * as React from 'react';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { GOOGLE_OAUTH_CLIENT_ID } from '../../services/config';

// Refer to https://developers.google.com/identity/oauth2/web/reference/js-reference?hl=ko
export interface GoogleAuthCallback {
  code: string;
  scope: string;
  authuser: string;
  hd: string;
  prompt: string;
}

const GoogleOAuthButton = ({
  onCallback,
}: {
  onCallback: (params: GoogleAuthCallback) => void;
}): ReactElement => {
  const signInWithGoogle = (): void => {
    window.google.accounts.oauth2
      .initCodeClient({
        client_id: GOOGLE_OAUTH_CLIENT_ID,
        scope: 'profile email openid',
        ux_mode: 'popup',
        callback: onCallback,
      })
      .requestCode();
  };
  return (
    <ButtonShape onClick={signInWithGoogle}>
      <div className={'button-state'} />
      <Image src={'static/img/logo/image-bi-google-emblem.svg'} alt={'GoogleOAuthButton'} />
      Sign in with Google
    </ButtonShape>
  );
};

// 이것은 구글의 정책을 따라야 하므로 디자인 시스템에서 벗어나 있다.
// https://developers.google.com/identity/branding-guidelines
const ButtonShape = styled.button`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 40px;
  padding: 11.5px 10px;
  align-items: center;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background: #fff;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.25px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;

  & .button-state {
    transition: opacity 0.218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover .button-state {
    background-color: #303030;
    opacity: 12%;
  }
`;

const Image = styled.img`
  float: left;
  margin-top: -2px;
  width: 20px;
  height: 20px;
`;

export default GoogleOAuthButton;
