import * as React from 'react';
import { ReactElement } from 'react';
import { mq } from '@closet-design-system/theme';
import { TypoSubTitle } from '@closet-design-system/core';
import { Nullable } from 'types/common';
import { ContestBadge } from 'types/marketplace/operation/contest';
import { ImageResize } from 'types/common/imageResize';
import styled from 'styled-components';
import { getBadgeImageUrl } from 'modules/contest';
import { UserDefaultImageIcon } from 'constants/marketplace/contest';
import { resizingImageUrl } from 'modules/common/imageResizingUrl';

const USER_PROFILE_IMAGE_SIZE: ImageResize = {
  resize: '1', // 확장자가 없는 경우 resize 파라미터 추가 ?resize=1
  w: 200,
  h: 200,
  fit: 'cover',
};

interface Props {
  profileImageUrl: Nullable<string>;
  nickName: string;
  badgeId: ContestBadge;
}

const WinnerBadge = ({ profileImageUrl, nickName, badgeId }: Props): ReactElement => {
  return (
    <WinnerBadgeRoot>
      <ProfileImage src={profileImageUrl} />
      <UserNickNameWrap>
        <TypoSubTitle variant={[7, 7, 7, 4]}>{nickName}</TypoSubTitle>
      </UserNickNameWrap>
      <PrizeBadge badgeId={badgeId} className={'prize-badge'} />
    </WinnerBadgeRoot>
  );
};

export default WinnerBadge;

const WinnerBadgeRoot = styled.div(
  ({
    theme: {
      breakpoints: { m },
    },
  }) =>
    mq({ m })({
      padding: [4, 8],
      background: 'rgba(0,0,0,0.5)',
      borderRadius: 40,
      minHeight: [24, 56],
      display: 'inline-flex',
      alignItems: 'center',
      maxWidth: '90%',
      justifyContent: 'space-between',
    }),
);

const ProfileImage = styled.div<{ src: Nullable<string> }>(
  ({
    theme: {
      breakpoints: { m },
      colors,
    },
    src,
  }) =>
    mq({ m })({
      position: 'relative',
      minWidth: [16, 40],
      width: [16, 40],
      height: [16, 40],
      borderRadius: '100%',
      overflow: 'hidden',
      marginRight: [4, 10],
      background: `url(${
        src ? resizingImageUrl(src, USER_PROFILE_IMAGE_SIZE) : UserDefaultImageIcon
      })`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: src ? 'transparent' : colors.GRAY_500,
    }),
);

const UserNickNameWrap = styled.div`
  max-width: 80%;
  min-width: 15px;
  text-align: center;
  overflow-wrap: break-word;
`;

const PrizeBadge = styled.div<{ badgeId: ContestBadge }>(
  ({
    badgeId,
    theme: {
      breakpoints: { m },
    },
  }) =>
    mq({ m })({
      minWidth: [16, 40],
      width: [16, 40],
      height: [16, 40],
      background: [
        `no-repeat center/cover url(${getBadgeImageUrl({ badgeId, isMobile: true })})`,
        `no-repeat center/40px url(${getBadgeImageUrl({ badgeId, isMobile: false })})`,
      ],
      marginLeft: [2, 10],
    }),
);
