import { SellerRevenueData } from 'types/marketplace/revenue';
import { ExcelMultiDataSet } from 'types/react-export-excel';

export const sellerRevenueDataToExcelMultiDataSet = (
  data: SellerRevenueData,
): ExcelMultiDataSet[] => {
  const { total, sellerSales } = data;

  const {
    itemSales,
    originalItemSales,
    originalItemRevenueShare,
    totalSales,
    feeRate,
    netIncome,
    revenue,
  } = total;

  return [
    {
      columns: [
        { value: 'Seller Info', widthPx: 80 },
        { value: '', widthPx: 80 },
        { value: 'Payment Info', widthPx: 60 },
        { value: '', widthPx: 60 },
        { value: '', widthPx: 60 },
        { value: 'Monthly Payment', widthPx: 60 },
        { value: '', widthPx: 60 },
        { value: '', widthPx: 60 },
        { value: '', widthPx: 60 },
      ],
      data: [],
    },
    {
      columns: [
        'Seller Name',
        'Account',
        'Item Sales',
        '(+) Original Item Sales',
        '(-) Original Item Sales',
        'Total Sales',
        'Share rate',
        '(Site) Net Income',
        '(Seller) Revenue',
      ],
      data: [
        [
          'total',
          '',
          itemSales,
          originalItemSales,
          originalItemRevenueShare,
          totalSales,
          feeRate,
          netIncome,
          revenue,
        ],
        ...(sellerSales?.map(
          ({
            sellerName,
            email,
            itemSales,
            originalItemSales,
            originalItemRevenueShare,
            totalSales,
            feeRate,
            netIncome,
            revenue,
          }) => [
            sellerName,
            email,
            itemSales,
            originalItemSales,
            originalItemRevenueShare,
            totalSales,
            feeRate,
            netIncome,
            revenue,
          ],
        ) ?? []),
      ],
    },
  ];
};
