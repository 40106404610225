import dayjs from 'dayjs';
import { ContestBadge, ContestParticipatingSortType } from 'types/marketplace/operation/contest';
import { getUtcDateTimeISO } from 'modules/utils/time';
import { SelectOption } from 'types/common/select';
import { TableCell as TableColumn } from 'types/common/table';
import { AZURE_STORAGE_PREFIX } from 'constants/common/url';
import { connectSubDomain } from 'services/config';

export const CONTEST_FIRST_YEAR = 2023; // Contest service first launch year
export const MAX_YEAR_RANGE = 2; // Last 3 year range(include the current year)
export const TODAY = getUtcDateTimeISO(new Date());

const START_DATE_INTERVAL = 14; // Contest Start Date Interval
const DATE_INTERVAL = 7;
export const INIT_NOTICE_DATE = dayjs(Date()).add(START_DATE_INTERVAL, 'days'); // Contest Notice Default start date.
export const INIT_SUBMISSION_DATE = INIT_NOTICE_DATE.add(DATE_INTERVAL, 'days'); // Contest Submission Default start date.
export const INIT_EVALUATION_DATE = INIT_SUBMISSION_DATE.add(DATE_INTERVAL, 'days'); // Contest Evaluation Default start date.
export const INIT_ANNOUNCEMENT_DATE = INIT_EVALUATION_DATE.add(DATE_INTERVAL, 'days'); // Contest Announcement Default start date.
export const INIT_CLOSED_DATE = INIT_ANNOUNCEMENT_DATE.add(DATE_INTERVAL, 'days'); // Contest Closed Default start date.

export const MESSAGE_REQUIRED_VALIDATED = 'This field is required';

export const CONTEST_BADGE_LIST = [
  { label: 'Platinum', value: ContestBadge.PLATINUM },
  { label: 'Gold', value: ContestBadge.GOLD },
  { label: 'Silver', value: ContestBadge.SILVER },
  { label: 'Bronze', value: ContestBadge.BRONZE },
];

export const REGEX_URL_PATH = /^(http|https):\/\/[^\s/$.?#]+\.\S+$/;

export const CONTEST_LIMIT_SIZE = 20;
export const SCHEDULE_DATE_COUNT = 5; // Stages of the contest

export const LAYOUT_BORDER_COLOR = '#545454'; // AwardLayout's border color
export const LAYOUT_BACKGROUND_COLOR = '#d9d9d9'; // AwardLayout's background color

export const LAYOUT_DEFAULT_WIDTH = 83; // AwardLayout's default width
export const LAYOUT_DEFAULT_HEIGHT = 49; // AwardLayout's default height
export const IMAGE_DEFAULT_HEIGHT = 36; // AwardLayoutImage's default height

export const SORT_OPTIONS: SelectOption[] = [
  { value: ContestParticipatingSortType.AWARD, label: 'Award' },
  { value: ContestParticipatingSortType.UPLOAD_DATE, label: 'Upload Date' },
  { value: ContestParticipatingSortType.CREATOR_ACCOUNT, label: 'Creator Account' },
  { value: ContestParticipatingSortType.LIKES, label: 'Likes' },
];

export const PARTICIPATING_HEADERS: TableColumn[][] = [
  [
    { align: 'center', name: 'Item Number.', key: 'itemNumber' },
    { align: 'center', name: 'Thumbnail', key: 'imagePath' },
    { align: 'center', name: 'Title', key: 'title' },
    { align: 'center', name: 'Creator', key: 'creator' },
    { align: 'center', name: 'Item Status', key: 'status' },
    { align: 'center', name: 'Likes', key: 'likeCount' },
    { align: 'center', name: 'Award', key: 'awardId' },
    { align: 'center', name: 'Upload Date', key: 'registeredDate' },
  ],
];

export const EXCEL_HEADER = [
  { header: 'Item Number.', key: 'itemNumber', width: 15 },
  { header: 'Thumbnail', key: 'imagePath' },
  { header: 'Title', key: 'title', width: 30 },
  { header: 'Creator.', key: 'creator', width: 20 },
  { header: 'Item Status', key: 'status', width: 20 },
  { header: 'Likes', key: 'likeCount', width: 15 },
  { header: 'Upload Date', key: 'registeredDate', width: 30 },
  { header: 'URL', key: 'detailPageUrl', width: 30 },
];

export const EXCEL_EXPORT_LIMIT = 99999;

export enum PREVIEW_TAB_VALUES {
  INFORMATION = 'information',
  SUBMISSIONS = 'submissions',
  MY_WORKS = 'myworks',
  AWARDS = 'awards',
}

export const UserDefaultImageIcon = `${AZURE_STORAGE_PREFIX}/public/backoffice/images/icon/userDefaultImage.svg`;
export const CONNECT_CONTEST_URL = `https://${connectSubDomain}.clo-set.com/contest`;

export const TITLE_LIMIT_LENGTH = 60;
export const HOST_NAME_LIMIT_LENGTH = 30;
export const HOST_SITE_LIMIT_LENGTH = 100;
