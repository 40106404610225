import * as React from 'react';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import GoogleOAuthButton, { GoogleAuthCallback } from './GoogleOAuthButton';
import coreApi from '../../api/coreApi';
import { setJWTToken } from '../../services/session';
import { redirectTo } from '../../services/redirect';
import Eastereggs from './Eastereggs';

const SignInContainer = (): ReactElement => {
  const signInWithGoogle = async (params: GoogleAuthCallback): Promise<void> => {
    coreApi
      .post<{ accessToken: string }>(`/id/admin/auth/google`, params)
      .then(res => {
        const jwt = res.data.accessToken;
        setJWTToken(jwt);
        redirectTo('/');
      })
      .catch(e => {
        alert(JSON.stringify(e));
      });
  };

  return (
    <Wrap>
      <Eastereggs />
      <img src={'static/img/logo/image-signin-logos.svg'} width={300} alt={'signin-logo'} />
      <LogoWrap>
        <Typography component="h1" variant="h1" align="center">
          {'Hello'.split('').map(s => (
            <span key={s} className={'active'}>
              {s}
            </span>
          ))}
        </Typography>
        <Typography component="h1" variant="h1" align="center">
          {'Admin'.split('').map(s => (
            <span key={s}>{s}</span>
          ))}
        </Typography>
      </LogoWrap>
      <ButtonWrap>
        <GoogleOAuthButton onCallback={signInWithGoogle} />
      </ButtonWrap>
      <Typography>
        This site requires you to sign in with your @clo3d.com Google account.
      </Typography>
    </Wrap>
  );
};

const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  color: #7f7f84;
  min-width: 768px;
`;

const LogoWrap = styled.div`
  color: white;
  gap: 2rem;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    height: 100px;
  }

  h1 span {
    position: relative;
    top: 20px;
    display: inline-block;
    font-size: 80px;
    color: #fff;

    &.active {
      animation: bounce 0.4s ease infinite alternate;
      text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc, 0 5px 0 #ccc,
        0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent, 0 9px 0 transparent,
        0 10px 10px rgba(0, 0, 0, 0.4);

      opacity: 0.5;
    }
  }

  h1 span:nth-child(2) {
    animation-delay: 0.1s;
  }
  h1 span:nth-child(3) {
    animation-delay: 0.2s;
  }
  h1 span:nth-child(4) {
    animation-delay: 0.3s;
  }
  h1 span:nth-child(5) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    100% {
      top: -20px;
      text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc, 0 5px 0 #ccc,
        0 6px 0 #ccc, 0 7px 0 #ccc, 0 8px 0 #ccc, 0 9px 0 #ccc, 0 50px 25px rgba(0, 0, 0, 0.2);
    }
  }
`;

const ButtonWrap = styled.div`
  width: 256px;
`;

export default SignInContainer;
