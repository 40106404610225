import {
  NewBackOrder,
  GetPlanDetail,
  SaveBackOrderRequest,
  SaveInitializeCommentParams,
  GetComment,
  PostPlanFreeParams,
  PostCreditParams,
  PutCurrentPlanEndDateParams,
  GetTrueUpFee,
  GetTrueUpFeeParams,
} from 'types/member/backorder';
import api from 'api/coreApi';
import { AxiosError, AxiosResponse } from 'axios';

export const getOrderInformation = async (userId: string) => {
  return api
    .get<GetPlanDetail>(`/groups/plandetail?userId=${userId}`)
    .then((res: AxiosResponse<GetPlanDetail>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getCommentList = async (groupId: string) => {
  return api
    .get(`/groups/comment`, {
      params: {
        GroupId: groupId,
        Ascending: false,
      },
    })
    .then((res: AxiosResponse<GetComment[]>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const addBackOrder = async (addData: SaveBackOrderRequest) => {
  const { requestData, groupId } = addData;
  return api
    .post<NewBackOrder>(`/groups/${groupId}/addbackorder`, requestData)
    .then((res: AxiosResponse) => {
      return { data: res.status };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const postInitializeCreditComment = async ({
  groupId,
  comment,
}: SaveInitializeCommentParams) => {
  return api
    .post(`/groups/${groupId}/initializeCredit`, {
      comment,
    })
    .then((res: AxiosResponse) => {
      return { data: res.status };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const putCurrentPlanEndDate = async (params: PutCurrentPlanEndDateParams) => {
  return api
    .put(`/groups/currentplan/enddate`, { ...params })
    .then((res: AxiosResponse) => {
      return { data: res.status };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};
export const postCredit = async (params: PostCreditParams) => {
  return api
    .post(`/groups/credit`, { ...params })
    .then((res: AxiosResponse) => {
      return { data: res.status };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};
export const postPlanFree = async (params: PostPlanFreeParams) => {
  return api
    .post(`/groups/plan/free`, { ...params })
    .then((res: AxiosResponse) => {
      return { data: res.status };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};
export const getTrueUpFee = async (params: GetTrueUpFeeParams) => {
  const { groupId, seq } = params;
  return api
    .get(`/groups/calc/true-up-fee/${groupId}`, {
      params: {
        seq,
      },
    })
    .then((res: AxiosResponse<GetTrueUpFee>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};
