import { TableCell } from 'types/common/table';
import {
  billingInvoiceItemRenderer,
  paymentDateCellRenderer,
  paymentPriceCellRenderer,
  PayStatusStyle,
  RouteText,
  SearchableText,
} from 'components/plansales/billing/billingtable/DataTableCellRenderer';
import { toMantissaWithThousandComma } from 'modules/utils/number';
import { paymentMethodLabel, paymentStatusLabel } from 'constants/plansales/billing';
import { BillingInvoice, PaymentMethod, PaymentStatus } from 'types/plansales/billing';
import classNames from 'classnames';
import * as React from 'react';
import { DEFAULT_PAGE_SIZE } from 'constants/common';
import { getTableRowNumber } from 'modules/dataTable';

export const billingTableColumns = (
  searchCellClick: (searchValue: string) => () => void,
  totalItems: number,
  currentPageIndex: number,
): TableCell<BillingInvoice>[] => [
  {
    align: 'center',
    name: 'No.',
    key: 'no',
    render: (rowData, columnData, rowIndex) =>
      getTableRowNumber(totalItems, DEFAULT_PAGE_SIZE, currentPageIndex, rowIndex),
  },
  {
    align: 'center',
    name: 'Invoice No.',
    key: 'invoiceNo',
    render: rowData => (
      <RouteText to={`/sales/billing/legacy/${rowData.invoiceNo}`}>{rowData.invoiceNo}</RouteText>
    ),
  },
  {
    align: 'center',
    name: 'Group ID',
    key: 'groupId',
    render: rowData => (
      <SearchableText onClick={searchCellClick(rowData.groupId)}>{rowData.groupId}</SearchableText>
    ),
  },
  {
    align: 'center',
    name: 'Group Name',
    key: 'groupName',
    render: rowData => (
      <SearchableText onClick={searchCellClick(rowData.groupName)}>
        {rowData.groupName}
      </SearchableText>
    ),
  },
  {
    align: 'center',
    name: 'Admin Email',
    key: 'adminEmail',
    render: rowData => (
      <RouteText to={`/member/${rowData.adminUserId}`}>{rowData.adminEmail}</RouteText>
    ),
  },
  {
    align: 'center',
    name: 'Item / Amount',
    key: 'itemAndAmount',
    render: rowData => billingInvoiceItemRenderer(rowData.invoiceItems, rowData.planType),
  },
  {
    align: 'center',
    name: 'Tax',
    key: 'tax',
    render: rowData => toMantissaWithThousandComma(rowData.taxAmount, 2),
  },
  {
    align: 'center',
    name: 'Payment Price',
    key: 'totalAmount',
    width: '100px',
    render: rowData => paymentPriceCellRenderer(rowData.totalAmount, rowData.paymentStatus),
  },
  {
    align: 'center',
    name: 'Payment Method',
    key: 'paymentMethod',
    width: '100px',
    render: rowData => paymentMethodLabel[rowData.paymentMethod as PaymentMethod],
  },
  {
    align: 'center',
    name: 'Pay Status',
    key: 'payStatus',
    render: rowData => (
      <PayStatusStyle
        className={classNames({
          emphasized:
            rowData.paymentStatus === PaymentStatus.REFUND ||
            rowData.paymentStatus === PaymentStatus.VOID,
        })}
      >
        {paymentStatusLabel[rowData.paymentStatus as PaymentStatus]}
      </PayStatusStyle>
    ),
  },
  {
    align: 'center',
    name: 'Payment Date',
    key: 'paymentDate',
    render: rowData => paymentDateCellRenderer(rowData.paymentSuccessedAt, rowData.paymentStatus),
  },
];
