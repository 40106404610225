import * as React from 'react';
import { ReactElement } from 'react';
import { mq } from '@closet-design-system/theme';
import { AZURE_STORAGE_PREFIX } from 'constants/common/url';
import styled from 'styled-components';

const twoPieceType1SquareEllipseUrl = `${AZURE_STORAGE_PREFIX}/public/connect/contest/layout/twoPieceType1_square_ellipse.svg`;

const TwoPieceType1Item2Background = (): ReactElement => {
  return (
    <TwoPieceType1Item2BackgroundRoot>
      <img src={twoPieceType1SquareEllipseUrl} alt={'square ellipse'} />
    </TwoPieceType1Item2BackgroundRoot>
  );
};

export default TwoPieceType1Item2Background;

const TwoPieceType1Item2BackgroundRoot = styled.div(
  ({
    theme: {
      breakpoints: { m, l },
    },
  }) =>
    mq({ m, l })({
      position: 'absolute',
      bottom: 0,
      left: [-16, -20, -24],
      width: '100%',
      ['img']: {
        width: '110%',
        height: '120%',
      },
    }),
);
