import * as React from 'react';
import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { TabContainerContextProps, TabValue } from 'types/common/tabs';

interface Props<T = TabValue> {
  selectedTab?: T;
}

export const TabContainerContext = createContext<TabContainerContextProps<any>>({});

const TabContainer = <T extends unknown>({
  selectedTab,
  children,
}: PropsWithChildren<Props<T>>) => {
  const [selectedValue, setSelectedValue] = useState<T>();
  useEffect(() => {
    setSelectedValue(selectedTab);
  }, [selectedTab]);

  return (
    <TabContainerContext.Provider
      value={{
        selectedValue,
        onChangeHandler: value => {
          setSelectedValue(value as unknown as T);
        },
      }}
    >
      {children}
    </TabContainerContext.Provider>
  );
};

export default TabContainer;
