import {
  HowToReg,
  ShoppingCart,
  Notifications,
  Settings,
  Search,
} from '@material-ui/icons';
import BackOrder from 'pages/member/BackOrder';
import Member from 'pages/member/Member';
import MemberDetail from 'pages/member/MemberDetail';

import LegacyBilling from 'pages/plansales/LegacyBilling';
import LegacyBillingDetail from 'pages/plansales/LegacyBillingDetail';
import * as React from 'react';
import {
  ContestRoutes,
  MainBannerFormRoutes,
  MidBannerFormRoutes,
} from 'routes/marketplace/operation';
// import { API_URL } from 'services/config';
import { RouteInfoType } from 'types/common/routes';
import Benchmark from '../pages/statistics/benchmark/Benchmark';
import BenchmarkDetail from '../pages/statistics/benchmark/BenchmarkDetail';
import JinnyIcon from 'constants/icon/jinnyIcon';
import View from 'pages/jinny/LauncherArtwork/View';
import Form from 'pages/jinny/LauncherArtwork/Form';
import Downloads, { WORKER_DOWNLOADS_PATH } from 'pages/worker/downloads/Downloads';
import ViewerLoadingTime from '../pages/monitor/ViewerLoadingTime';
import FeatureUpdateNotice from 'pages/notice/FeatureUpdateNotice';
import FeatureUpdateNoticeForm from 'pages/notice/FeatureUpdateNoticeForm';
import { MarketPlaceRoutes } from 'routes/marketplace';
import { RevenueRoutes } from 'routes/marketplace/revenueShare';
import { OrderRoutes } from 'routes/marketplace/order';

export const JINNY_ARTWORK_URLPATH = '/jinny/artwork';
export const BENCHMARK_PATH = '/statistics/benchmark';

// STATISTICS

const BenchmarkRoutes = {
  id: 'benchmark',
  icon: <HowToReg />,
  path: BENCHMARK_PATH,
  name: 'Benchmark',
  component: Benchmark,
};

const BenchmarkDetailRoutes = {
  id: 'benchmarkDetail',
  name: 'Benchmark Detail',
  path: `${BENCHMARK_PATH}/:id`,
  component: BenchmarkDetail,
  isExternal: false,
  children: null,
};

// Legacy admin 용 external link
// 레거시 페이지로 2025. 2 부터 운영 종료 순차적 리뉴얼 예정
// const legacyRoutes = [
//   {
//     id: 'member',
//     path: `${API_URL}/`,
//     icon: <Users />,
//     isExternal: true,
//     name: 'Member',
//   },
//   {
//     id: 'statistics',
//     path: '/statistics',
//     name: 'Statistics',
//     icon: <ShowChart />,
//     children: [
//       {
//         path: `${API_URL}/statistics/daily`,
//         name: 'Common',
//         icon: <ShowChart />,
//         isExternal: true,
//         children: [
//           {
//             path: `${API_URL}/statistics/daily`,
//             name: 'Date',
//             icon: <ShowChart />,
//             isExternal: true,
//             children: [
//               {
//                 path: `${API_URL}/statistics/daily`,
//                 name: '11Date',
//                 icon: <ShowChart />,
//                 isExternal: true,
//               },
//               {
//                 path: `${API_URL}/statistics/daily`,
//                 name: '22Date',
//                 icon: <ShowChart />,
//                 isExternal: true,
//               },
//             ],
//           },
//           {
//             path: `${API_URL}/statistics/downloads`,
//             name: 'Downloads',
//             isExternal: true,
//           },
//           {
//             path: `${API_URL}/statistics/mostusers`,
//             name: 'Most Users',
//             isExternal: true,
//           },
//           {
//             path: `${API_URL}/statistics/mostupload`,
//             name: 'Most Upload',
//             isExternal: true,
//           },
//           {
//             path: `${API_URL}/statistics/mostcomments`,
//             name: 'Most Comments',
//             isExternal: true,
//           },
//           {
//             path: `${API_URL}/statistics/mostviews`,
//             name: 'Most Views',
//             isExternal: true,
//           },
//         ],
//       },
//       {
//         path: `${API_URL}/statistics/domain`,
//         name: 'By Domain',
//         isExternal: true,
//       },
//       {
//         path: `${API_URL}/statistics/company`,
//         name: 'Company',
//         isExternal: true,
//       },
//       {
//         ...BenchmarkRoutes,
//       },
//     ],
//   },
// ];

// JINNY

const JinnyRoutes = {
  id: 'jinny',
  path: '/jinny',
  name: 'Jinny',
  icon: <JinnyIcon />,
  children: [
    {
      path: JINNY_ARTWORK_URLPATH,
      name: 'Launcher Artwork',
      component: View,
    },
  ],
};

const JinnyHideRoutes = [
  {
    id: 'artwork-edit',
    path: `${JINNY_ARTWORK_URLPATH}/edit`,
    name: 'Edit Launcher Artwork',
    component: Form,
    isExternal: false,
    children: null,
  },
];

// SALES

const PlanSalesRoutes = {
  id: 'planSales',
  path: '/sales',
  name: 'Plan Sales',
  icon: <ShoppingCart />,
  children: [
    {
      path: '/sales/billing/legacy',
      name: 'Billing (Legacy)',
      component: LegacyBilling,
    },
  ],
};

const BillingDetailRoutes = {
  id: 'billingDetail',
  name: 'Billing Detail (Legacy)',
  path: '/sales/billing/legacy/:invoiceNo',
  component: LegacyBillingDetail,
  isExternal: false,
};

// MEMBER

const MemberRoutes = {
  id: 'memberNew',
  path: '/member',
  name: 'Member',
  icon: <HowToReg />,
  component: Member,
};

const MemberDetailRoutes = {
  id: 'memberDetail',
  name: 'Member Detail',
  path: '/member/:userId',
  component: MemberDetail,
  isExternal: false,
  children: null,
};

const BackOrderRoutes = {
  id: 'backOrder',
  name: 'Back Order',
  path: '/member/backorder/:userId',
  component: BackOrder,
  icon: <HowToReg />,
};

const WorkerRoutes = {
  id: 'worker',
  path: '/worker',
  name: 'Worker',
  icon: <Settings />,
  children: [
    {
      path: `${WORKER_DOWNLOADS_PATH}/:tab?`,
      to: WORKER_DOWNLOADS_PATH,
      name: 'Downloads',
      component: Downloads,
    },
  ],
};

// NOTICE

export const FEATURE_UPDATE_NOTICE_PATH = '/notice/feature-update';

const MonitoringRoutes = {
  id: 'monitor',
  name: 'Monitor',
  path: '/monitor',
  icon: <Search />,
  children: [
    {
      path: '/monitor',
      name: 'Viewer Loading Time',
      component: ViewerLoadingTime,
    },
  ],
};

const NoticeRoutes = {
  id: 'notice',
  name: 'Notice',
  path: '/notice',
  icon: <Notifications />,
  children: [
    {
      path: `${FEATURE_UPDATE_NOTICE_PATH}/:tab?`,
      to: FEATURE_UPDATE_NOTICE_PATH,
      name: 'Feature Update Notice',
      component: FeatureUpdateNotice,
    },
  ],
};

const FeatureUpdateNoticeFormRoutes = [
  {
    id: 'feature-update-notice-form',
    name: 'Feature Update Notice Form',
    path: `${FEATURE_UPDATE_NOTICE_PATH}/create`,
    component: FeatureUpdateNoticeForm,
    isExternal: false,
    children: null,
  },
  {
    id: 'feature-update-notice-form',
    name: 'Feature Update Notice Form',
    path: `${FEATURE_UPDATE_NOTICE_PATH}/edit/:id?`,
    component: FeatureUpdateNoticeForm,
    isExternal: false,
    children: null,
  },
];

export const adminSidebarRoutes = [
  BenchmarkRoutes,
  MarketPlaceRoutes,
  JinnyRoutes,
  PlanSalesRoutes,
  MemberRoutes,
  WorkerRoutes,
  MonitoringRoutes,
  NoticeRoutes,
] as RouteInfoType[];

export const adminHideRoutes = [
  BillingDetailRoutes,
  MemberDetailRoutes,
  BenchmarkDetailRoutes,
  BackOrderRoutes,
  ...MainBannerFormRoutes,
  ...MidBannerFormRoutes,
  ...RevenueRoutes,
  ...OrderRoutes,
  ...JinnyHideRoutes,
  ...FeatureUpdateNoticeFormRoutes,
  ...ContestRoutes,
] as unknown as RouteInfoType[];
