import { useEffect, useState } from 'react';

export const isImage = (filename: string): boolean => {
  const lowercaseFileName = filename.toLowerCase().split('.').pop();
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

  return lowercaseFileName !== undefined && imageExtensions.includes(lowercaseFileName);
};

export default function useImagePreview(file: File | null): string | undefined {
  const [imageDataUrl, setImageDataUrl] = useState<string | undefined>();
  useEffect(() => {
    setImageDataUrl(undefined);

    if (!file) return;
    if (!isImage(file.name)) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const setUrl = (): void => {
      setImageDataUrl(reader.result as string);
      reader.removeEventListener('load', setUrl);
      reader.abort();
    };
    reader.addEventListener('load', setUrl, false);

    return () => {
      reader.removeEventListener('load', setUrl);
    };
  }, [file]);

  return imageDataUrl;
}
