import { Nullable } from 'types/common';
import { SearchDateRangeFilterProps } from 'types/common/searchfilter';

export enum ConfirmStatus {
  PENDING = 10,
  CHECKING = 3,
  PUBLISHED = 1,
  REJECTED = -1,
  WITHDRAW = 0,
  BLOCKED = -2,
  DELETED = 14,
  DRAFT = 2,
}

export enum ItemType {
  GARMENT = 'E6B30',
  FABRIC = '82B1A',
  TRIM = '36D98',
  AVATAR = 'C6997',
  SCENE = 'SCE00',
  EVERYWEAR = 'METAV',
}

export enum ItemTypeSubcategory {
  // -- Trim -- //
  BUTTONS = 'C5375',
  FASTENERS = '32337',
  ZIPPERS = 'BC576',
  HARDWARES = 'AC05A',
  NOTIONS = '258D3',

  // -- Avatar -- //
  MODEL = '48282',
  MOTION = 'MOTIN',
  POSE = '585BB',
  HAND_POSE = 'HNDPS',
  HAIR = '3EC8C',
  SHOES = '36620',
  GLASSES = '8B2Z5',
  EARRING = '9B1Z7',
  MANNEQUIN = '7A3D4',
}

export enum SalesType {
  PAID = 0,
  FREE = 1,
  GALLERY = 2,
}

export enum Software {
  CLO = 3,
  MD = 4,
  JINNY = 0,
  OTHERS = -99,
}

export enum Sort {
  LATEST_PUBLISHED_DATE = 0,
  OLDEST_PUBLISHED_DATE = 1,
  LATEST_UPDATE = 2,
  OLDEST_UPDATE = 3,
}

export type ConfirmItemArrayFilterValueType =
  | ConfirmStatus
  | ItemType
  | ItemTypeSubcategory
  | SalesType
  | Software
  | string;

export enum ZipStatus {
  ERROR = -1,
  COMPLETE = 1,
  UPDATING = 2,
}

export interface ConfirmItem {
  id: string;
  status: ConfirmStatus;
}

export interface CheckedItemInfo {
  id: string;
  itemTitle: string;
  requestStatus: Nullable<ConfirmStatus>;
  liveStatus: Nullable<ConfirmStatus>;
  isGarment: boolean;
}

export interface StatusSelectOption {
  label: string;
  value: string;
  isCommentRequired?: boolean;
  isReasonRequired?: boolean;
  isDisabled?: boolean;
  hideComment?: boolean;
}

export enum DigitalStampStatuses {
  UNKNOWN_FAILED = -4,
  MINTING_FAILED,
  TRANSFER_FAILED,
  EDIT_METADATA_FAILED,
  NONE,
  IN_PROGRESS,
  SUCCESS = 10,
}

export enum DigitalStampFilterStatus {
  MINTED = 'minted',
  FAILED = 'failed',
}

export interface ConfirmItemSearchParams extends SearchDateRangeFilterProps {
  statuses: number[];
  salesTypes: number[];
  itemTypes: string[];
  mainPages: string[];
  softwares: number[];
  mdPick: boolean;
  keyword: string;
  keywordType: ConfirmItemKeywordType;
  page: number;
  sort: number;
  digitalStampStatuses: number[];
  contestFilter: string[];
  convertingStatuses: number[];
  limit: number;
}

export enum ConfirmItemTab {
  MARKETPLACE_ITEM = 'confirm-item',
  EVERYWEAR_ITEM = 'everywear-item',
}

export type ConfirmItemTabType = {
  tab: ConfirmItemTab;
};

export interface ConfirmItemFilterForm {
  statuses: number[];
  itemTypes: string[];
  salesTypes: number[];
  softwares: number[];
  mainPages: string[];
  mdPick: boolean;
  keyword: string;
  keywordType: ConfirmItemKeywordType;
  digitalStampStatuses: number[];
  contestFilter: string[];
  convertingStatuses: number[];
  limit: number;
}

export enum ConfirmItemKeywordType {
  Account,
  CreatorName,
  ItemNumber,
  ItemId,
  ItemName,
}

export enum ConvertingFailStatus {
  ZIP_FAILED = 1,
  SREST_FAILED,
  STANDARD_FORMAT_FAILED,
}

export interface FilterForm {
  statuses: number[];
  itemTypes: string[];
  salesTypes: number[];
  softwares: number[];
  mainPages: string[];
  mdPick: boolean;
  keyword: string;
  keywordType: ConfirmItemKeywordType;
  digitalStampStatuses: number[];
  contestFilter: string[];
  convertingStatuses: number[];
}

export enum StandardFormatType {
  'FBX_THICK' = '_fbx_thick',
  'FBX_THIN' = '_fbx_thin',
  'GLTF_THICK' = '_gltf_thick',
  'GLTF_THIN' = '_gltf_thin',
}
