import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { TableCell } from 'types/common/table';
import { getUtcDateFullMonth } from 'modules/utils/time';
import {
  historyChargeRenderer,
  historyCreditRenderer,
  historyDescriptionRenderer,
  historyInvoiceRenderer,
  historyPaymentRenderer,
} from 'components/memberdetail/detail/pricingplan/legacybillinghistory/LegacyHistoryTableRenderer';
import { Button } from '@material-ui/core';
import Message from '@material-ui/icons/Message';
import { TextStyle } from 'components/common/table/TableStyle';

export const legacyBillingHistoryColumns = (
  setCommentHistoryModalOpen: Dispatch<SetStateAction<boolean>>,
): TableCell<any>[] => [
  {
    align: 'left',
    name: 'Date',
    key: 'date',
    render: (rowData: any) => (
      <TextStyle textAlign={'left'}>{getUtcDateFullMonth(rowData.BillDate)}</TextStyle>
    ),
  },
  {
    align: 'center',
    name: 'Invoice No.',
    key: 'invoiceNo',
    render: (rowData: any) => historyInvoiceRenderer(rowData.Invoice?.InvoiceId),
  },
  {
    align: 'left',
    name: 'Description',
    key: 'description',
    render: (rowData: any) => (
      <TextStyle textAlign={'left'}>{historyDescriptionRenderer(rowData)}</TextStyle>
    ),
  },
  {
    align: 'left',
    name: 'Charges',
    key: 'charges',
    render: (rowData: any) => (
      <TextStyle textAlign={'left'}>{historyChargeRenderer(rowData)}</TextStyle>
    ),
  },
  {
    align: 'right',
    name: 'Payment',
    key: 'payment',
    render: (rowData: any) => (
      <TextStyle textAlign={'right'}>{historyPaymentRenderer(rowData)}</TextStyle>
    ),
  },
  {
    align: 'right',
    name: 'Credit Balance',
    key: 'creditBalance',
    render: (rowData: any) => (
      <TextStyle textAlign={'right'}>{historyCreditRenderer(rowData)}</TextStyle>
    ),
  },
  {
    align: 'center',
    name: '',
    width: '80px',
    key: 'edit',
    render: (rowData: any) => (
      <Button
        size={'small'}
        startIcon={<Message color={'action'} />}
        onClick={() => setCommentHistoryModalOpen(true)}
      >
        5
      </Button>
    ),
  },
];
