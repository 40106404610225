import * as React from 'react';
import DataTableLayout from '../common/table/datatable/DataTableLayout';
import DataTableHeader from '../common/table/datatable/DataTableHeader';
import NewDataTableBody from '../common/table/datatable/NewDataTableBody';
import { TableCell } from '../../types/common/table';
import { ViewerLoadingItem } from '../../types/monitor';
import { getUtcDateWithTime } from '../../modules/utils/time';
import { bytesToMB } from '../../modules/calc';

interface Props {
  totalPages?: number;
  currentPageIndex?: number;
  data?: ViewerLoadingItem[];
  isLoading: boolean;
  onChangePage: (pageIndex: number) => void;
}
const ViewerLoadingTimeTable = ({
  totalPages = 0,
  currentPageIndex = 0,
  data = [],
  isLoading,
  onChangePage,
}: Props) => {
  return (
    <DataTableLayout
      showPagination
      totalPages={totalPages}
      onChangePage={onChangePage}
      currentPageIndex={currentPageIndex}
    >
      <DataTableHeader header={columns} />
      <NewDataTableBody dataList={data ?? []} columns={columns} loading={isLoading} />
    </DataTableLayout>
  );
};
export default ViewerLoadingTimeTable;

export const columns: TableCell<ViewerLoadingItem>[] = [
  {
    align: 'center',
    name: 'View Date',
    key: 'viewDate',
    render: rowData => getUtcDateWithTime(rowData.viewDate),
  },
  {
    align: 'center',
    name: 'Item Id',
    key: 'itemId',
  },
  {
    align: 'center',
    name: 'Version',
    key: 'version',
  },
  {
    align: 'center',
    name: 'Country',
    key: 'country',
  },
  {
    align: 'center',
    name: 'Total Seconds',
    key: 'totalSeconds',
    render: rowData => `${rowData.totalSeconds} (s)`,
  },
  {
    align: 'center',
    name: 'Original File Size',
    key: 'originalFileSize',
    render: rowData => `${bytesToMB(rowData.originalFileSize, 2)} MB`,
  },
  {
    align: 'center',
    name: 'Zrest File Size',
    key: 'zrestFileSize',
    render: rowData => `${bytesToMB(rowData.originalZrestFileSize, 2)} MB`,
  },
  {
    align: 'center',
    name: 'Speed',
    key: 'speed',
    render: rowData => `${rowData.fileSpeed.toFixed(2)} MB/s`,
  },
  // 2025. 02 부터 registeredBy 는 수집하지 않기로 했습니다 (GDPR 데이터 관리정책 by. Rafa)
  // {
  //   align: 'center',
  //   name: 'Email',
  //   key: 'email',
  // },
];
