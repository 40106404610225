import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { DateInterval } from 'types/plansales/billing';
import { getUtcDateDash, isValidDate } from 'modules/utils/time';
import styled from 'styled-components';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import InformModal from 'components/common/modal/InformModal';
import DatePicker from 'components/common/searchfilter/contents/DatePicker';
import { ButtonObject, SearchDateTerms } from 'types/common/searchfilter';
import { spacing } from '@material-ui/system';
import { shortcuts } from 'components/common/searchfilter/contents/shorcutDates';

interface Props {
  dateInterval: string[];
  setDateInterval: Dispatch<SetStateAction<string[]>>;
  shortcutButtons?: ButtonObject[];
  disableOnFrom?: boolean;
  disableOnTo?: boolean;
  fromMinDate?: string;
  toMinDate?: string;
  setSelectedShortcut?: Dispatch<SetStateAction<SearchDateTerms | null>>;
}

const DateRangePicker = ({
  dateInterval,
  setDateInterval,
  shortcutButtons,
  disableOnFrom,
  disableOnTo,
  fromMinDate,
  toMinDate,
  setSelectedShortcut,
}: Props) => {
  const [fromDate, toDate] = dateInterval;
  const [invalidDateModalOpen, setInvalidDateModalOpen] = useState<boolean>(false);

  const onChangeDate = (type: DateInterval) => (newDateValue: MaterialUiPickersDate) => {
    if (isValidDate({ type, newDateValue, from: fromDate, to: toDate })) {
      setInvalidDateModalOpen(true);
      return;
    }
    const newDateDashFormat = getUtcDateDash(newDateValue);
    const [newDateFrom, newDateTo] =
      type === DateInterval.FROM ? [newDateDashFormat, toDate] : [fromDate, newDateDashFormat];
    setDateInterval([newDateFrom, newDateTo]);

    const shortcut = shortcuts.find(
      info => info.date.from === newDateFrom && info.date.to === newDateTo,
    );

    if (shortcut) {
      setSelectedShortcut?.(shortcut.terms);
      return;
    }

    setSelectedShortcut?.(null);
  };

  const onCloseInformModal = () => {
    setInvalidDateModalOpen(false);
  };

  return (
    <>
      <InformModal
        open={invalidDateModalOpen}
        onClose={onCloseInformModal}
        description={'The end date cannot be in the past than the start date.'}
      />
      <DateFiltersBlock>
        <DatePickerSection>
          <DatePickerInput>
            <DatePicker
              value={fromDate}
              onChange={onChangeDate(DateInterval.FROM)}
              disabled={disableOnFrom}
              minDate={fromMinDate}
              maxDate={toDate}
            />
          </DatePickerInput>
          <Tilde>~</Tilde>
          <DatePickerInput>
            <DatePicker
              value={toDate}
              onChange={onChangeDate(DateInterval.TO)}
              disabled={disableOnTo}
              minDate={toMinDate ?? fromDate}
            />
          </DatePickerInput>
        </DatePickerSection>
        {shortcutButtons && (
          <ButtonSection>
            {shortcutButtons.map((button: ButtonObject, index: number) => (
              <Button
                key={index}
                variant="contained"
                color={button.selected ? 'secondary' : 'default'}
                size="small"
                onClick={button.onClick}
                disabled={button.disabled}
                mb={2}
              >
                {button.name}
              </Button>
            ))}
          </ButtonSection>
        )}
      </DateFiltersBlock>
    </>
  );
};

export default DateRangePicker;

const Button = styled(MuiButton)(spacing);

const Tilde = styled.div`
  font-size: 18px;
  margin: 0 8px;
`;

const ButtonSection = styled.div`
  & > button {
    margin-left: ${props => props.theme.spacing(3)}px;
  }
`;

export const DatePickerInput = styled.div`
  width: 150px;
  input {
    padding-left: 2px;
  }
`;

const DatePickerSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const DateFiltersBlock = styled.div`
  display: flex;
  align-items: center;
`;
