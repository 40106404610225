import { Grid } from '@material-ui/core';
import ContentLayout from 'components/common/layouts/ContentLayout';
import {
  benchmarkActions,
  selectBenchmarkDetailError,
  selectBenchmarkDetailItem,
  selectBenchmarkDetailLoading,
} from 'features/benchmark/benchmarkSlice';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BENCHMARK_PATH } from 'routes/ClosetAdminRoutes';
import BenchmarkTests from '../../components/benchmark/BenchmarkTests';
import BenchmarkUser from '../../components/benchmark/BenchmarkUser';

interface Props {
  id: string;
}

const BenchmarkDetailContainer = ({ id }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(selectBenchmarkDetailLoading);
  const isError = useSelector(selectBenchmarkDetailError);
  const detailItem = useSelector(selectBenchmarkDetailItem);

  useEffect(() => {
    dispatch(benchmarkActions.getBenchmarkItem({ id }));
  }, [id]);

  useEffect(() => {
    if (!isError) return;
    history.replace(BENCHMARK_PATH);
  }, [isError]);

  return (
    <>
      <ContentLayout pageTitle={'Benchmark Detail'} loading={isLoading}>
        <Grid container>
          {detailItem && <BenchmarkUser detailItem={detailItem} />}
          {detailItem && <BenchmarkTests {...detailItem.result} />}
        </Grid>
      </ContentLayout>
    </>
  );
};

export default BenchmarkDetailContainer;
