import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent as MuiDialogContent,
  createStyles,
  withStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import { MuiWidth } from 'types/common';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export type ModalType = 'error' | 'notice';

interface Props {
  open: boolean;
  onClose: () => void;
  description?: string;
  maxWidth?: MuiWidth;
  type?: ModalType;
}

const DialogContent = withStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  }),
)(MuiDialogContent);

const InformModal = ({ open, onClose, description, maxWidth = 'xs', type = 'error' }: Props) => {
  return (
    <Dialog open={open} fullWidth maxWidth={maxWidth} onClose={onClose}>
      <DialogContent dividers>
        {type === 'error' ? (
          <ErrorOutlineOutlinedIcon color={'error'} />
        ) : (
          <CheckCircleOutlineIcon htmlColor={'#039405'} />
        )}
        <ContentDescription>{description}</ContentDescription>
      </DialogContent>
      <DialogActions>
        <Button variant={'text'} color={'primary'} onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InformModal;

const ContentDescription = styled.div`
  margin-left: ${props => props.theme.spacing(5)}px;
`;
