import * as React from 'react';
import styled from 'styled-components';
import { ListItem, ListItemText } from '@material-ui/core';
import { darken, rgba } from 'polished';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import LinkBadge from './LinkBadge';
import { SidebarCategoryPropsType, CategoryType } from 'types/common/routes';

const SidebarCategory: React.FC<SidebarCategoryPropsType> = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  isSubMenu,
  ...rest
}) => {
  const categoryCollapseIcon = isOpen ? <CategoryIconMore /> : <CategoryIconLess />;
  return (
    <Category {...rest}>
      <Spacing isSubMenu={isSubMenu} />
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable && categoryCollapseIcon}
      {badge && <CategoryBadge label={badge} />}
    </Category>
  );
};

// ListItem에 CustomProps 를 추가하면 Warning이 생겨 Spacing을 위한 DOM 생성
const Spacing = styled.div<{ isSubMenu?: boolean }>`
  padding-left: ${props => (props.isSubMenu ? props.theme.spacing(11) : props.theme.spacing(6))}px;
`;

const Category = styled(ListItem)<CategoryType>`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-right: ${props => props.theme.spacing(5)}px;
  font-weight: ${props => props.theme.typography.fontWeightRegular};

  svg {
    color: ${props => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 2px 0 0 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.sidebar.category.fontWeight};
    padding: 0 ${props => props.theme.spacing(4)}px;
    line-height: initial;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

export default SidebarCategory;
