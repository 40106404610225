import * as React from 'react';
import { ReactElement } from 'react';
import ClosetAuthLayout from 'components/common/layouts/AuthLayout';
import ClosetBasicLayout from 'components/common/layouts/BasicLayout';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { adminHideRoutes, adminSidebarRoutes } from 'routes/ClosetAdminRoutes';
import { authLayoutRoutes, dashboardLayoutRoutes } from 'routes/TemplateRoutes';
import { exampleRoutes } from 'routes/ExampleRoutes';
import { decodeJwtToken, isExpiredToken } from '../modules/signin';
import { redirectSigIn } from '../services/redirect';
import Cookies from 'universal-cookie';
import { tokenName } from '../services/config';
import { generateRouteNode } from 'modules/common/routes';
import AuthLayout from '../components/template/layouts/Auth';
import BasicLayout from '../components/template/layouts/BasicLayout';

// template
import DashboardLayout from '../components/template/layouts/Dashboard';
import Page404 from '../pages/template/auth/Page404';
import MainBannerPreview from 'pages/marketplace/mainbanner/MainBannerPreview';
import ContestPreview from 'pages/marketplace/contest/ContestPreview';
import { ContestPreviewTabType } from 'types/marketplace/operation/contest';

const CONTEST_PREVIEW_ALLOWED_TABS = Object.values(ContestPreviewTabType).join('|');

/**
 * 로그인이 필요한 routes
 * @constructor
 */
const Routes = (): ReactElement => {
  const cookies = new Cookies(document.cookie);
  const headerToken = cookies.get(tokenName);

  if (!headerToken || isExpiredToken(decodeJwtToken(headerToken))) {
    redirectSigIn();
  }

  // TODO 임시코드: template 경우, template용 layout을 보여주기 위함 (개발시 참고하기위함임)
  const isTemplate = window.location.pathname.includes('/template');
  const layout = {
    dashboard: isTemplate ? DashboardLayout : ClosetBasicLayout,
    auth: isTemplate ? AuthLayout : ClosetAuthLayout,
    basic: isTemplate ? BasicLayout : ClosetBasicLayout,
  };

  return (
    <BrowserRouter>
      <Switch>
        <Redirect from="/" to="/sales/billing/legacy" exact />
        {generateRouteNode(layout.dashboard, dashboardLayoutRoutes)}
        {generateRouteNode(layout.auth, authLayoutRoutes)}
        {generateRouteNode(layout.basic, [...adminHideRoutes, ...adminSidebarRoutes])}
        {generateRouteNode(layout.dashboard, exampleRoutes)}
        <Route path={'/main-banner-preview'} component={MainBannerPreview} exact />
        <Route
          path={`/marketplace/operation/contest/preview/:contestSeq/:tab(${CONTEST_PREVIEW_ALLOWED_TABS})`}
          component={ContestPreview}
          exact
        />
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
