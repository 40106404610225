import * as React from 'react';
import { ReactElement } from 'react';
import { LayoutType, TwoThreePieceType } from 'types/marketplace/operation/contest';
import AwardThumbnail, {
  AwardThumbnailBox,
} from 'components/marketplace/operation/contest/preview/common/AwardThumbnail';
import TwoPieceTypeWrapped from 'components/marketplace/operation/contest/preview/desktop/twopiecelayout/TwoPieceTypeWrapped';
import TwoPieceTypeColumn from 'components/marketplace/operation/contest/preview/desktop/twopiecelayout/TwoPieceTypeColumn';

const MAX_HEIGHT = 835;
const TWO_PIECE_ITEM_LONG_RATIO = 1.533;
const TWO_PIECE_ITEM_SHORT_RATIO = 0.826;

const TwoPieceType = ({ badgeId, items, layoutType }: TwoThreePieceType): ReactElement => {
  const getTwoPieceAwardThumbnailRatio = (layoutType: LayoutType): [number, number] => {
    switch (layoutType) {
      case LayoutType.TWO_PIECE_2:
        return [TWO_PIECE_ITEM_SHORT_RATIO, TWO_PIECE_ITEM_LONG_RATIO];
      case LayoutType.TWO_PIECE_3:
        return [TWO_PIECE_ITEM_LONG_RATIO, TWO_PIECE_ITEM_SHORT_RATIO];
      case LayoutType.TWO_PIECE_4:
        return [0.765, 1.53];
      default:
        /* TWO_PIECE_1 */
        return [1.33, 1.33];
    }
  };

  const awardThumbnailRatios = getTwoPieceAwardThumbnailRatio(layoutType);

  return (
    <TwoPieceTypeWrapped layoutType={layoutType}>
      {items.map((item, index) => {
        return (
          <TwoPieceTypeColumn key={index} layoutType={layoutType} order={index}>
            {item ? (
              <AwardThumbnail
                badgeId={badgeId}
                imagePath={item.imagePath}
                creatorImagePath={item.creatorImagePath}
                creator={item.creator}
                ratio={awardThumbnailRatios[index]}
                maxHeight={layoutType === LayoutType.TWO_PIECE_1 ? MAX_HEIGHT : undefined}
                isBadgePositionTop={layoutType === LayoutType.TWO_PIECE_1 && index === 1}
              />
            ) : (
              <AwardThumbnailBox ratio={awardThumbnailRatios[index]} />
            )}
          </TwoPieceTypeColumn>
        );
      })}
    </TwoPieceTypeWrapped>
  );
};

export default TwoPieceType;
