import * as React from 'react';
import { TableCell } from 'types/common/table';
import {
  invoiceAmountRenderer,
  invoiceDescriptionRenderer,
  invoiceItemRenderer,
  invoiceQuantityRenderer,
  invoiceUnitPriceRenderer,
} from 'components/plansales/billing/detail/invoice/InvoiceTableRenderer';
import { TextStyle } from 'components/common/table/TableStyle';
import { InvoiceDescriptionStatus, InvoiceItem } from 'types/plansales/billing';

export const invoiceBillingTableColumns = (
  invoiceStatus: InvoiceDescriptionStatus,
): TableCell<InvoiceItem>[] => {
  return [
    {
      align: 'left',
      name: 'Item',
      key: 'item',
      render: (rowData: InvoiceItem) => (
        <TextStyle fontWeight={'600'} textAlign={'left'}>
          {invoiceItemRenderer(rowData)}
        </TextStyle>
      ),
    },
    {
      align: 'left',
      name: 'Description',
      key: 'description',
      render: (rowData: InvoiceItem) => (
        <TextStyle textAlign={'left'}>
          {invoiceDescriptionRenderer(rowData, invoiceStatus)}
        </TextStyle>
      ),
    },
    {
      align: 'center',
      name: 'Qty',
      key: 'qty',
      render: (rowData: InvoiceItem) => {
        return <TextStyle textAlign={'center'}>{invoiceQuantityRenderer(rowData)}</TextStyle>;
      },
    },
    {
      align: 'right',
      name: 'Unit Price',
      key: 'unitPrice',
      render: (rowData: InvoiceItem) => (
        <TextStyle textAlign={'right'}>{invoiceUnitPriceRenderer(rowData)}</TextStyle>
      ),
    },
    {
      align: 'right',
      name: 'Amount',
      key: 'amount',
      width: '10%',
      render: (rowData: InvoiceItem) => (
        <TextStyle textAlign={'right'}>{invoiceAmountRenderer(rowData)}</TextStyle>
      ),
    },
  ];
};
