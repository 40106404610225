import * as React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';

interface Props {
  resetFilter: () => void;
  getSearchResult: (newKeyword?: string) => void;
  disabled?: boolean;
}

const FilterActionButtons = ({ resetFilter, getSearchResult, disabled }: Props) => {
  return (
    <ButtonSectionBlock>
      <Button
        onClick={() => getSearchResult()}
        disabled={disabled}
        variant="contained"
        color="primary"
        size={'small'}
      >
        Search
      </Button>
      <Button onClick={resetFilter} variant="contained" color="primary" size={'small'} ml={3}>
        Reset
      </Button>
    </ButtonSectionBlock>
  );
};

export default FilterActionButtons;

const Button = styled(MuiButton)(spacing);

const ButtonSectionBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: ${props => props.theme.spacing(3)}px;
  margin-top: ${props => props.theme.spacing(3)}px;
`;
