import * as React from 'react';
import { ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  keyword: string;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDownKeyword?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  maxLength?: number;
  error?: boolean;
  helperText?: string;
}

const KeywordInput = ({
  error,
  helperText,
  keyword,
  onChangeKeyword,
  onKeyDownKeyword,
  placeholder = '',
  maxLength = 200,
}: Props) => {
  return (
    <KeywordInputBlock>
      <TextField
        error={error}
        helperText={helperText}
        value={keyword}
        onChange={onChangeKeyword}
        placeholder={placeholder}
        fullWidth
        inputProps={{
          maxLength,
        }}
        onKeyDown={onKeyDownKeyword}
      />
    </KeywordInputBlock>
  );
};

export default KeywordInput;

const KeywordInputBlock = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  padding: ${props => props.theme.spacing(1.5)}px 0;
`;
