import * as React from 'react';
import { TextField, Button as MuiButton } from '@material-ui/core';
import { LetterCounter } from 'components/memberdetail/detail/commenttable/AddCommentModal';
import { spacing } from '@material-ui/system';
import styled from 'styled-components';

interface Props {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  saveButtonLabel?: string;
  showCancel?: boolean;
  heightRows?: number;
  onCancel?: () => void;
  onSave: () => void;
  autoFocus?: boolean;
  placeholder?: string;
}

const CommentInput = ({
  value,
  onChange,
  saveButtonLabel = 'Post',
  showCancel = false,
  heightRows,
  onCancel,
  onSave,
  autoFocus = false,
  placeholder = '',
}: Props) => {
  return (
    <>
      <TextField
        fullWidth
        multiline
        autoFocus={autoFocus}
        rows={heightRows}
        variant={'outlined'}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        inputProps={{
          maxLength: 500,
        }}
      />
      <ActionButtons>
        <LetterCounter>{`(${value.length}/500)`}</LetterCounter>
        {showCancel && (
          <Button variant={'contained'} color={'primary'} size={'small'} ml={2} onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button variant={'contained'} color={'primary'} size={'small'} ml={2} onClick={onSave}>
          {saveButtonLabel}
        </Button>
      </ActionButtons>
    </>
  );
};

export default CommentInput;

const Button = styled(MuiButton)(spacing);

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;
