import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { LayoutType } from 'types/marketplace/operation/contest';
import { Grid } from '@material-ui/core';
import classnames from 'classnames';
import { getEnumKeyByStringValue } from 'modules/utils/enum';
import styled from 'styled-components';

const ThreePieceTypeColumn = ({
  children,
  layoutType,
}: {
  children: ReactNode;
  layoutType: LayoutType;
}): ReactElement => {
  const classLayoutType = getEnumKeyByStringValue(layoutType as number, LayoutType);
  return layoutType === LayoutType.THREE_PIECE_1 ? (
    <Grid item xs={4}>
      <ThreePiece1ItemSpacing>{children}</ThreePiece1ItemSpacing>
    </Grid>
  ) : (
    <ThreePiecesColumn className={classnames({ [classLayoutType]: classLayoutType })}>
      {children}
    </ThreePiecesColumn>
  );
};

const ThreePiece1ItemSpacing = styled.div`
  margin-left: 1px;
`;

const ThreePiecesColumn = styled.div`
  width: 100%;
  &.THREE_PIECE_2 {
    align-self: start;
    &:nth-child(odd) {
      align-self: end;
    }
  }
  &.THREE_PIECE_3 {
    align-self: start;
    &:nth-child(even) {
      align-self: end;
    }
  }
`;

export default ThreePieceTypeColumn;
