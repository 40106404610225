import { TableRow } from '@material-ui/core';
import * as React from 'react';
import { DataObject, TableCell } from 'types/common/table';
import { StyledHeaderCell } from '../TableStyle';

interface Props<T = DataObject> {
  cells: TableCell<T>[];
}
const DataTableHeaderRow = <T extends DataObject>({ cells }: Props<T>) => {
  return (
    <TableRow>
      {cells.map(
        ({ align = 'center', width, name, colSpan, rowSpan }: TableCell<T>, index: number) => (
          <StyledHeaderCell
            key={index}
            align={align}
            colSpan={colSpan}
            rowSpan={rowSpan}
            style={{ width }}
          >
            {name}
          </StyledHeaderCell>
        ),
      )}
    </TableRow>
  );
};
export default DataTableHeaderRow;
