import * as React from 'react';
import { useEffect, useState } from 'react';
import ContentLayout from 'components/common/layouts/ContentLayout';
import ViewerLoadingTimeTable from './ViewerLoadingTimeTable';
import { ViewerLoadingResponse } from '../../types/monitor';
import coreApi from '../../api/coreApi';

/**
 * ViewerLoadingContainer
 * viewer 의 loading time 을 기록한 테이블
 * 주드가 단순 참고로 필요로 하는 기능이라 필터 등은 포함하지 않고 paging 까지만 옮겨둠
 * @constructor
 */
const ViewerLoadingContainer = () => {
  const [data, setData] = useState<ViewerLoadingResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchViewerLoadingData = async (pageIndex: number = 1) => {
    setIsLoading(true);
    coreApi
      .get<ViewerLoadingResponse>(`/monitor/viewer-loading-time?page=${pageIndex}`)
      .then(response => {
        setData(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchViewerLoadingData().then();
  }, []);

  return (
    <ContentLayout pageTitle={'Viewer Loading Time'}>
      <ViewerLoadingTimeTable
        totalPages={data?.totalPages}
        currentPageIndex={data?.currentPage}
        data={data?.items ?? []}
        isLoading={isLoading}
        onChangePage={fetchViewerLoadingData}
      />
    </ContentLayout>
  );
};

export default ViewerLoadingContainer;
