import * as React from 'react';
import PricingTable from 'components/memberdetail/detail/pricingplan/PricingTable';
import styled from 'styled-components';
import LegacyBillingHistoryTable from 'components/memberdetail/detail/pricingplan/legacybillinghistory/LegacyBillingHistoryTable';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  userId: string;
  billingHistory: any;
  pricingPlan: any;
  setCommentHistoryModalOpen: Dispatch<SetStateAction<boolean>>;
  addBackOrderDisabled: boolean;
}

const PricingPlanInformation = ({
  billingHistory,
  pricingPlan,
  setCommentHistoryModalOpen,
  userId,
  addBackOrderDisabled,
}: Props) => {
  return (
    <PlanInformationBlock>
      <PricingTable
        userId={userId}
        pricingPlan={pricingPlan}
        setCommentHistoryModalOpen={setCommentHistoryModalOpen}
        addBackOrderDisabled={addBackOrderDisabled}
      />
      <LegacyBillingHistoryTable
        billingHistory={billingHistory}
        setCommentHistoryModalOpen={setCommentHistoryModalOpen}
      />
    </PlanInformationBlock>
  );
};

export default PricingPlanInformation;

export const PlanInformationBlock = styled.div`
  width: 100%;
  padding: 0 ${props => props.theme.spacing(2)}px;
`;
