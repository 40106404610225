import DateFnsUtils from '@date-io/date-fns';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ToastMessageContainer from 'containers/common/ToastMessageContainer';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import Cookies from 'universal-cookie';
import Routes from './routes/Routes';
import maTheme from './theme';
import { tokenName } from 'services/config';
import PublicRoutes from './routes/PublicRoutes';

function App() {
  const closetAdminTheme = maTheme[0];
  const cookies = new Cookies(document.cookie);
  const headerToken = cookies.get(tokenName);

  return (
    <React.Fragment>
      <Helmet titleTemplate="%s | CLO-SET Admin" />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={closetAdminTheme}>
            <ThemeProvider theme={closetAdminTheme}>
              {headerToken ? <Routes /> : <PublicRoutes />}
              <ToastMessageContainer />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
