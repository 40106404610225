import { Dispatch, useState, SetStateAction } from 'react';
import { COPY_TEXT } from 'constants/worker/downloads';

const useCopy = (): {
  copyTooltip: string;
  setCopyTooltip: Dispatch<SetStateAction<string>>;
  onCopy: (copyText: string) => void;
} => {
  const [copyTooltip, setCopyTooltip] = useState<string>(COPY_TEXT);
  const onCopy = async (copyText: string) => {
    try {
      await navigator.clipboard.writeText(copyText);
      setCopyTooltip('copied!');
    } catch (e) {
      console.log('an error occurs when copy text!', e);
    }
  };

  return { copyTooltip, setCopyTooltip, onCopy };
};

export default useCopy;
