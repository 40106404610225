import * as React from 'react';
import ContentLayout from 'components/common/layouts/ContentLayout';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { jinnyActions, selectArtworkData, selectArtworkLoading } from 'features/jinny/jinnySlice';
import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import ContentTop from 'components/jinny/artwork/view/ContentTop';
import { Edit } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { JINNY_ARTWORK_URLPATH } from 'routes/ClosetAdminRoutes';
import { Divider } from 'components/common/Divider';
import { Skeleton } from '@material-ui/lab';

const ArtworkViewContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(selectArtworkLoading);
  const artworkInfo = useSelector(selectArtworkData);

  const handleEdit = (_evt: React.SyntheticEvent<HTMLButtonElement>) => {
    history.push(`${JINNY_ARTWORK_URLPATH}/edit`);
  };

  useEffect(() => {
    dispatch(jinnyActions.getArtwork());
  }, []);

  return (
    <ContentLayout pageTitle={'Launcher Artwork'}>
      <ContentTop
        title={artworkInfo?.artworkTitle}
        registeredBy={artworkInfo?.registeredBy}
        registeredDate={artworkInfo?.registeredDate}
        isLoading={isLoading}
      />
      <Divider my={4} />
      <ContentMidSpace>
        {isLoading ? (
          <Skeleton variant={'rect'} width={'70%'} height={500}/>
        ) : (
          <ArtworkImage src={artworkInfo?.imageFilePath} alt={artworkInfo?.imageFileName} />
        )}
      </ContentMidSpace>
      <Divider my={4} />
      <ContentBottomSpace>
        <Button startIcon={<Edit />} variant={'outlined'} color={'primary'} onClick={handleEdit}>
          Edit
        </Button>
      </ContentBottomSpace>
    </ContentLayout>
  );
};

const ContentMidSpace = styled.div`
  display: flex;
  justify-content: center;
`;
const ArtworkImage = styled.img`
  width: 80%;
`;
const ContentBottomSpace = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

export default ArtworkViewContainer;
