import { RevenueStatus } from 'types/marketplace/revenue';
import { OrderPaymentStatus } from '../../types/marketplace/order';

export const revenueStatusLabel = {
  [OrderPaymentStatus.REFUNDED]: 'Refunded',
  [OrderPaymentStatus.INCOMPLETE]: 'Incomplete',
  [OrderPaymentStatus.COMPLETED]: 'Completed',
  [OrderPaymentStatus.ERROR]: 'Error',
};

export enum SectionType {
  'ROW' = 'row',
  'TAIL' = 'tail',
  'HEADER' = 'header',
}

export enum SectionTextColor {
  'DEFAULT' = 'textPrimary',
  'SECOND' = 'textSecondary',
  'ERROR' = 'error',
}

export const MONTHLY_LIST: (
  | 'jan'
  | 'feb'
  | 'mar'
  | 'apr'
  | 'may'
  | 'jun'
  | 'jul'
  | 'aug'
  | 'sep'
  | 'oct'
  | 'nov'
  | 'dec'
)[] = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export const MONTHLY_PAYOUT_START_YEAR = '2022';
export const WITHHOLDING_TAX_START_DATE = '2022-06';
