import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Select from 'components/common/form/Select';
import _times from 'lodash/times';

interface Props {
  maxDays: number;
  numberOfDays: number;
  onChange: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
}
const FeatureUpdateNoticeFormDays = ({ maxDays, numberOfDays, onChange }: Props) => {
  const days = maxDays < 1 ? 1 : maxDays;
  const options = _times(days, i => {
    return { value: i + 1, label: i + 1 };
  });

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Number of Days to Display the Modal
      </Typography>
      <Grid container alignItems={'center'} spacing={2}>
        <Grid item>
          <Select value={numberOfDays} options={options} variant={'outlined'} onChange={onChange} />
        </Grid>
        <Grid item>
          <Typography variant="body2">days</Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default FeatureUpdateNoticeFormDays;
