import { all, takeLatest, put, call, delay } from 'redux-saga/effects';
import * as api from 'api/member';
import { PayloadAction } from '@reduxjs/toolkit';
import { memberListActions } from '../reducer/memberListSlice';
import { ExcelMemberExportParams, GetMemberListParams } from 'types/member';

export function* getMemberListSaga(action: PayloadAction<GetMemberListParams>) {
  const { data } = yield call(api.getMemberList, action.payload);
  if (data) {
    yield put(memberListActions.getMemberListSuccess(data));
  } else {
    yield put(memberListActions.getMemberListFailure(data));
  }
}

export function* getMailListExportDataSaga(action: PayloadAction<ExcelMemberExportParams>) {
  const { data } = yield call(api.getExportData, action.payload);
  yield delay(1500);
  if (data) {
    yield put(memberListActions.getMailListExportDataSuccess(data));
    yield put(memberListActions.setMailListExportReady(true));
  } else {
    yield put(memberListActions.getMailListExportDataFailure(data));
  }
}
export function* getMarketingAgreeExportDataSaga(action: PayloadAction<ExcelMemberExportParams>) {
  const { data } = yield call(api.getExportData, action.payload);
  yield delay(1500);
  if (data) {
    yield put(memberListActions.getMarketingAgreeExportDataSuccess(data));
    yield put(
      memberListActions.setMarketingAgreeExportReady({ value: true, type: action.payload.type }),
    );
  } else {
    yield put(memberListActions.getMarketingAgreeExportDataFailure(data));
  }
}

export default function* memberListSaga() {
  yield all([
    takeLatest(memberListActions.getMailListExportData.type, getMailListExportDataSaga),
    takeLatest(memberListActions.getMarketingAgreeExportData.type, getMarketingAgreeExportDataSaga),
    takeLatest(memberListActions.getMemberList.type, getMemberListSaga),
  ]);
}
