import {
  FeatureUpdateNoticeFormData,
  FeatureUpdateNoticeFormValidation,
  FeatureUpdateNoticeLinkButtonValidation,
  FeatureUpdateNoticeLinkOpenType,
  FeatureUpdateNoticeModalFormData,
  FeatureUpdateNoticeModalLinkButton,
  FeatureUpdateNoticeModalType,
  FeatureUpdateNoticeModalTypeValidation,
  FeatureUpdateNoticeTargetSpace,
  FeatureUpdateNoticeLanguage,
  FeatureUpdateNoticeColorType,
} from 'types/notice/featureUpdateNotice';
import dayjs from 'dayjs';
import { getUtcLocalDateType, isToDateAfterFromDate } from 'modules/utils/time';
import { GetFeatureUpdateNoticeDetailResponse } from '../../types/api/notice/featureUpdateNotice';
import { convertURLtoFile } from 'modules/utils/convert';

export const getFeatureUpdateNoticeInitialFormData = (): FeatureUpdateNoticeFormData => {
  // 분 단위를 버림하여, 10분 단위로 맞춤
  const from = new Date();
  from.setMinutes(Math.floor(from.getMinutes() / 10) * 10);
  // from 1주일 후
  const to = dayjs(from).add(1, 'weeks').toDate();

  return {
    title: '',
    targetSpace: FeatureUpdateNoticeTargetSpace.MAIN_DASHBOARD,
    modalType: FeatureUpdateNoticeModalType.IMAGE_TEXT,
    colorType: FeatureUpdateNoticeColorType.DARK,
    modals: {
      english: {
        imageUrl: null,
        title: '',
        subTitle: '',
        body: '',
        footNote: '',
        language: FeatureUpdateNoticeLanguage.ENGLISH,
      },
      chinese: {
        imageUrl: null,
        title: '',
        subTitle: '',
        body: '',
        footNote: '',
        language: FeatureUpdateNoticeLanguage.CHINESE,
      },
    },
    useLinkButton: true,
    linkButton: {
      linkUrl: '',
      buttonNameCn: '',
      buttonNameEng: '',
      linkOpenType: FeatureUpdateNoticeLinkOpenType.NEW_TAB,
    },
    displayModalFrom: from,
    displayModalTo: to,
    hidingPediod: 1,
  };
};

export const getFeatureUpdateNoticeInitialFormValidation =
  (): FeatureUpdateNoticeFormValidation => {
    return {
      title: false,
      modals: {
        imageCn: false,
        imageEn: false,
        titleCn: false,
        titleEn: false,
      },
      linkButton: {
        linkUrl: false,
        buttonNameCn: false,
        buttonNameEng: false,
      },
      date: false,
    };
  };

const getFeatureUpdateNoticeModalsValidation = (
  modalType: FeatureUpdateNoticeModalType,
  modals: {
    english: FeatureUpdateNoticeModalFormData;
    chinese: FeatureUpdateNoticeModalFormData;
  },
): FeatureUpdateNoticeModalTypeValidation => {
  const useTitle = modalType !== FeatureUpdateNoticeModalType.IMAGE;
  const useImage = modalType !== FeatureUpdateNoticeModalType.TEXT;

  return {
    imageCn: useImage && !modals.chinese.imageUrl,
    imageEn: useImage && !modals.english.imageUrl,
    titleCn: useTitle && !modals.chinese.title,
    titleEn: useTitle && !modals.english.title,
  };
};

const getFeatureUpdateNoticeLinkButtonValidation = (
  useLinkButton: boolean,
  linkButton: FeatureUpdateNoticeModalLinkButton,
): FeatureUpdateNoticeLinkButtonValidation => {
  if (useLinkButton) {
    return {
      linkUrl: !linkButton.linkUrl,
      buttonNameCn: !linkButton.buttonNameCn,
      buttonNameEng: !linkButton.buttonNameEng,
    };
  }

  return {
    linkUrl: false,
    buttonNameCn: false,
    buttonNameEng: false,
  };
};

export const getFeatureUpdateNoticeFormValidation = (
  data: FeatureUpdateNoticeFormData,
): FeatureUpdateNoticeFormValidation => {
  return {
    title: !data.title,
    modals: getFeatureUpdateNoticeModalsValidation(data.modalType, data.modals),
    linkButton: getFeatureUpdateNoticeLinkButtonValidation(data.useLinkButton, data.linkButton),
    date: !isToDateAfterFromDate(data.displayModalFrom, data.displayModalTo),
  };
};

export const checkFeatureUpdateNoticeFormValidation = (
  validation: FeatureUpdateNoticeFormValidation,
): boolean => {
  const { title, modals, linkButton, date } = validation;
  return (
    title ||
    Object.values(modals).some(value => value) ||
    Object.values(linkButton).some(value => value) ||
    date
  );
};

export const makeFeatureUpdateNoticeFormData = (data: FeatureUpdateNoticeFormData): FormData => {
  const {
    title,
    targetSpace,
    modalType,
    modals,
    useLinkButton,
    linkButton,
    displayModalFrom,
    displayModalTo,
    hidingPediod,
    colorType,
  } = data;
  const formData = new FormData();
  formData.set('Title', title);
  formData.set('TargetSpace', String(targetSpace));
  formData.set('ModalType', String(modalType));
  formData.set('ColorType', String(colorType));
  formData.set('UseLinkButton', String(useLinkButton));
  formData.set(
    'LinkOpenType',
    String(linkButton.linkOpenType ?? FeatureUpdateNoticeLinkOpenType.NEW_TAB),
  );
  formData.set('LinkUrl', linkButton.linkUrl ?? '');
  formData.set('ButtonNameEng', linkButton.buttonNameEng ?? '');
  formData.set('ButtonNameCn', linkButton.buttonNameCn ?? '');
  formData.set('HidingPediod', String(hidingPediod));
  formData.set('DisplayModalFrom', displayModalFrom.toUTCString());
  formData.set('DisplayModalTo', displayModalTo.toUTCString());

  const enModal = modals.english;
  const cnModal = modals.chinese;
  [enModal, cnModal].map((modal, index) => {
    const { imageUrl, title, subTitle, body, footNote, language } = modal;
    formData.append(`Modals[${index}].Image`, imageUrl as File);
    formData.append(`Modals[${index}].Title`, title ?? '');
    formData.append(`Modals[${index}].SubTitle`, subTitle ?? '');
    formData.append(`Modals[${index}].Body`, body ?? '');
    formData.append(`Modals[${index}].FootNote`, footNote ?? '');
    formData.append(`Modals[${index}].Language`, String(language));
  });

  return formData;
};

export const parseFeatureUpdateNoticeForm = async (
  formData: GetFeatureUpdateNoticeDetailResponse,
): Promise<FeatureUpdateNoticeFormData> => {
  const { displayModalTo, displayModalFrom, modals } = formData;

  const [enImageUrl, cnImageUrl] = await Promise.all([
    modals.english.imageUrl ? convertURLtoFile(modals.english.imageUrl) : null,
    modals.chinese.imageUrl ? convertURLtoFile(modals.chinese.imageUrl) : null,
  ]);

  return {
    ...formData,
    modals: {
      english: {
        ...modals.english,
        imageUrl: enImageUrl,
      },
      chinese: {
        ...modals.chinese,
        imageUrl: cnImageUrl,
      },
    },
    displayModalFrom: getUtcLocalDateType(displayModalFrom),
    displayModalTo: getUtcLocalDateType(displayModalTo),
  };
};
