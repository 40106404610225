import { convertStringArray, ParseQsValue } from 'modules/utils/queryString';
import { MD_STORE_DETAIL_URL } from 'services/config';
import { StoreType } from 'types/common';
import { CONNECT_STORE_DETAIL_URL } from 'constants/marketplace/confirmItem';

export const ItemDetailUrl = ({ itemId, store }: { itemId: string; store: StoreType }): string =>
  `${store === StoreType.CONNECT ? CONNECT_STORE_DETAIL_URL : MD_STORE_DETAIL_URL}/${itemId}`;

export const parseStoreQueryString = (
  store: ParseQsValue,
  initialStore: StoreType[],
): StoreType[] => {
  return convertStringArray(store)?.map(store => Number(store)) ?? initialStore;
};
