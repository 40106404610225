import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Eastereggs = () => {
  const [isVisible, setIsVisible] = useState(false); // 10초 후에 div가 보이도록 설정
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 18000);
    return () => clearTimeout(timer); // 컴포넌트가 unmount될 때 타이머 정리
  }, []);

  if (!isVisible) return <></>;
  return (
    <>
      <Position4>
        <img src="/static/img/etc/img_signin_dummy.gif" alt="rotating image1" />
      </Position4>
      <Position2>
        <Image2 src="/static/img/etc/img_signin_dummy_2.png" alt="rotating image2" />
      </Position2>
      <Position3>
        <Image2 src="/static/img/etc/img_signin_dummy_2.png" alt="rotating image3" />
      </Position3>
      <Position>
        <ImageWrap>
          <Image src="/static/img/etc/img_signin_dummy_1.png" alt="rotating image4" />
          <Overlay />
        </ImageWrap>
      </Position>
    </>
  );
};

export default Eastereggs;

const Position4 = styled.div`
  position: fixed;
  bottom: -2px;
  left: -10px;
  opacity: 0.4;
  img {
    width: 240px;
  }
`;
const Position = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: moveInTriangle 4s infinite linear;
  @keyframes moveInTriangle {
    0% {
      transform: translate(0, 0) scale(1); /* 시작 지점 */
    }
    33% {
      transform: translate(200px, -300px) scale(0.8); /* 삼각형의 두 번째 꼭짓점 */
    }
    66% {
      transform: translate(400px, 0) scale(0.6); /* 삼각형의 세 번째 꼭짓점 */
    }
    100% {
      transform: translate(0, 0); /* 다시 처음으로 돌아오기 */
    }
  }
`;

const ImageWrap = styled.div`
  opacity: 0.4;
`;

const Image = styled.img`
  width: 280px;
  height: 280px;
  animation: rotate 2s linear infinite; /* 2초 동안 회전하고, 무한 반복 */
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Position2 = styled.div`
  position: fixed;
  top: 0; /* 비가 처음에는 화면 위에서 시작 */
  right: 30px;
  animation: rainDrop 3s linear infinite;
  @keyframes rainDrop {
    0% {
      transform: translateY(100vh) scale(1); /* 비가 화면 위에서 시작하며 크기가 작음 */
      opacity: 0.4; /* 비가 처음에는 투명도가 낮음 */
    }
    100% {
      transform: translateY(0) scale(3); /* 비가 화면 아래로 떨어지며 크기가 커짐 */
      opacity: 1; /* 비가 떨어지면서 오파시티가 진해짐 */
    }
  }
`;

const Position3 = styled(Position2)`
  right: 60px;
`;

const Image2 = styled(Image)`
  width: 50px;
  height: 50px;
  animation: rotate 1s linear infinite; /* 2초 동안 회전하고, 무한 반복 */
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    red,
    orange,
    yellow,
    green,
    cyan,
    blue,
    violet
  ); /* 무지개 색상 */
  border-radius: 50%;
  opacity: 0.6; /* 투명도 조정 */
  pointer-events: none; /* 클릭 이벤트 무시 */
  animation: rotate-overlay 2s linear infinite; /* 무지개 오버레이도 회전 */
  @keyframes rotate-overlay {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`;
