import { green, grey, purple, red } from '@material-ui/core/colors';
import { getUtcDateDash, getUtcDateDashDifference } from 'modules/utils/time';
import { SearchDateTerms } from 'types/common/searchfilter';
import {
  CommentKeywordType,
  CommunityCommentFilterProps,
  CommunityPostFilterProps,
  PostItemCategory,
  PostItemStatus,
  PostKeywordType,
  PostSort,
} from 'types/marketplace/community';
import { StatusSelectOption } from 'types/marketplace/confirmItem';
import { connectSubDomain } from 'services/config';
import { ConnectCommunityUserType } from 'types/member';

export const communityPostUrl = `https://${connectSubDomain}.clo-set.com/community`;

export const initialPostSearchParams: CommunityPostFilterProps = {
  dateFrom: getUtcDateDashDifference(new Date(), 6, 'month'),
  dateTo: getUtcDateDash(new Date()),
  dateTerm: SearchDateTerms['6_MONTHS'],
  statuses: [PostItemStatus.PUBLISHED, PostItemStatus.BLOCKED],
  categories: [],
  keywordType: PostKeywordType.TITLE,
  searchKeyword: '',
  mainPick: false,
  sort: PostSort.LATEST_UPLOAD_DATE,
  page: 1,
};

export const initialCommentSearchParams: CommunityCommentFilterProps = {
  dateFrom: getUtcDateDashDifference(new Date(), 6, 'month'),
  dateTo: getUtcDateDash(new Date()),
  dateTerm: SearchDateTerms['6_MONTHS'],
  statuses: [PostItemStatus.PUBLISHED, PostItemStatus.BLOCKED],
  userTypes: [
    ConnectCommunityUserType.GENERAL,
    ConnectCommunityUserType.CONTRIBUTOR,
    ConnectCommunityUserType.CLOVER,
  ],
  keywordType: CommentKeywordType.COMMENT,
  searchKeyword: '',
  sort: 0,
  page: 1,
};

export const SELECT_MENU_ITEMS = [
  { label: 'Latest upload date', value: PostSort.LATEST_UPLOAD_DATE },
];

export const POST_STATUS_OPTIONS = [
  { label: 'Published', value: PostItemStatus.PUBLISHED },
  { label: 'Blocked', value: PostItemStatus.BLOCKED },
  { label: 'Deleted', value: PostItemStatus.DELETED },
];

export const POST_CATEGORY_OPTIONS = [
  { label: 'Notice', value: PostItemCategory.NOTICE },
  { label: 'User Spotlight', value: PostItemCategory.USER_SPOTLIGHT },
  { label: 'General', value: PostItemCategory.GENERAL },
  { label: 'Challenge', value: PostItemCategory.CHALLENGE },
  { label: 'Projects & Steps', value: PostItemCategory.PROJECTS_AND_STEPS },
  { label: 'Tips & Tricks', value: PostItemCategory.TIPS_TRICKS },
  { label: 'QnA', value: PostItemCategory.QNA },
  { label: 'User Feedback', value: PostItemCategory.USER_FEEDBACK },
  { label: 'Job Board', value: PostItemCategory.JOB_BOARD },
  { label: 'Community Guide', value: PostItemCategory.COMMUNITY_GUIDE },
];

export const POST_KEYWORD_TYPE_OPTIONS = [
  { label: 'Title', value: PostKeywordType.TITLE },
  { label: 'Account', value: PostKeywordType.ACCOUNT },
  { label: 'Tag', value: PostKeywordType.TAG },
  { label: 'Post ID', value: PostKeywordType.POST_ID },
];

export const COMMENT_KEYWORD_TYPE_OPTIONS = [
  { label: 'Title', value: CommentKeywordType.TITLE },
  { label: 'Account', value: CommentKeywordType.ACCOUNT },
  { label: 'Post ID', value: CommentKeywordType.POST_ID },
  { label: 'Comment', value: CommentKeywordType.COMMENT },
];

export const POST_ITEM_STATUS_COLOR = {
  [PostItemStatus.BLOCKED]: red['900'],
  [PostItemStatus.PUBLISHED]: green['500'],
  [PostItemStatus.DRAFT]: grey['500'],
  [PostItemStatus.DELETED]: purple['500'],
};

export const POST_ITEM_STATUS_LABEL = {
  [PostItemStatus.BLOCKED]: 'Blocked',
  [PostItemStatus.PUBLISHED]: 'Published',
  [PostItemStatus.DRAFT]: 'Draft',
  [PostItemStatus.DELETED]: 'Deleted',
};

export const COMMENT_ITEM_STATUS_LABEL = POST_ITEM_STATUS_LABEL;

export const POST_ITEM_CATEGORY_LABEL = {
  [PostItemCategory.NONE]: 'None',
  [PostItemCategory.NOTICE]: 'Notice',
  [PostItemCategory.USER_SPOTLIGHT]: 'User Spotlight',
  [PostItemCategory.COMMUNITY_GUIDE]: 'Community Guide',
  [PostItemCategory.GENERAL]: 'General',
  [PostItemCategory.CHALLENGE]: 'Challenge',
  [PostItemCategory.QNA]: 'QnA',
  [PostItemCategory.PROJECTS_AND_STEPS]: 'Projects & Steps',
  [PostItemCategory.TIPS_TRICKS]: 'Tips & Tricks',
  [PostItemCategory.USER_FEEDBACK]: 'User Feedback',
  [PostItemCategory.JOB_BOARD]: 'Job Board',
};

export const postItemCategorySelectOptions = [
  {
    label: POST_ITEM_CATEGORY_LABEL[PostItemCategory.NOTICE],
    value: PostItemCategory.NOTICE,
  },
  {
    label: POST_ITEM_CATEGORY_LABEL[PostItemCategory.USER_SPOTLIGHT],
    value: PostItemCategory.USER_SPOTLIGHT,
  },
  {
    label: POST_ITEM_CATEGORY_LABEL[PostItemCategory.COMMUNITY_GUIDE],
    value: PostItemCategory.COMMUNITY_GUIDE,
  },
  {
    label: POST_ITEM_CATEGORY_LABEL[PostItemCategory.GENERAL],
    value: PostItemCategory.GENERAL,
  },
  {
    label: POST_ITEM_CATEGORY_LABEL[PostItemCategory.CHALLENGE],
    value: PostItemCategory.CHALLENGE,
  },
  {
    label: POST_ITEM_CATEGORY_LABEL[PostItemCategory.QNA],
    value: PostItemCategory.QNA,
  },
  {
    label: POST_ITEM_CATEGORY_LABEL[PostItemCategory.PROJECTS_AND_STEPS],
    value: PostItemCategory.PROJECTS_AND_STEPS,
  },
  {
    label: POST_ITEM_CATEGORY_LABEL[PostItemCategory.TIPS_TRICKS],
    value: PostItemCategory.TIPS_TRICKS,
  },
  {
    label: POST_ITEM_CATEGORY_LABEL[PostItemCategory.USER_FEEDBACK],
    value: PostItemCategory.USER_FEEDBACK,
  },
  {
    label: POST_ITEM_CATEGORY_LABEL[PostItemCategory.JOB_BOARD],
    value: PostItemCategory.JOB_BOARD,
  },
];

export const statusSelectOptions: StatusSelectOption[] = [
  {
    label: POST_ITEM_STATUS_LABEL[PostItemStatus.PUBLISHED],
    value: `${PostItemStatus.PUBLISHED}`,
    isCommentRequired: true,
  },
  {
    label: POST_ITEM_STATUS_LABEL[PostItemStatus.BLOCKED],
    value: `${PostItemStatus.BLOCKED}`,
    isCommentRequired: true,
    isReasonRequired: true,
  },
  {
    label: POST_ITEM_STATUS_LABEL[PostItemStatus.DELETED],
    value: `${PostItemStatus.DELETED}`,
    isDisabled: true,
    hideComment: true,
  },
];

export const POST_ARRAY_VALUE_KEYS = ['statuses', 'categories'];
export const COMMENT_ARRAY_VALUE_KEYS = ['statuses', 'userTypes'];
