import api from 'api/coreApi';
import { AxiosError, AxiosResponse } from 'axios';
import {
  AuthProvider,
  PostCouponInformationParams,
  DeleteAccountParams,
  GetBenchmarkResponse,
  GetLoginHistoryResponse,
  GetMemberGeneralInformationResponse,
  GetMembersResponse,
  Member,
  PostMemberCommentParams,
  GetCouponListResponse,
  GetCommentResponse,
  PutMemberCommentParams,
  GetCouponListParams as GetCommentListParams,
  DeleteMemberCommentParams,
  GetMemberListParams,
  GetCouponListParams as GetLoginHistoryParams,
  GetCouponListParams,
  LoginProvider,
  ExcelMemberExportParams,
  GetBrandSettingParams,
  BrandSettingResponse,
  UpdateBrandSettingParams,
  GetBillingAddressParams,
  BillingAddressResponse,
  PutConnectUserStatusParams,
  GetEmailVerificationLinkResponse,
  GetEmailChangeHistoryResponse,
  PutConnectUserTypeParams,
} from 'types/member';
import { FIRST_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'constants/common';
import { convertObjectToArrayQuery } from 'modules/utils/queryString';

export const getMemberList = async (data: GetMemberListParams) => {
  const {
    keyword,
    page = FIRST_PAGE_INDEX,
    pageSize = DEFAULT_PAGE_SIZE,
    connectUserStatus,
    connectCommunityUserStatus,
    everywearCreatorStatus,
    connectUserType,
    connectCommunityUserType,
  } = data;
  const baseRequestUrl = `/members?Keyword=${keyword}&page=${page}&limit=${pageSize}`;
  const connectUserStatusQuery =
    connectUserStatus && convertObjectToArrayQuery(connectUserStatus, 'connectUserStatus');
  const connectCommunityUserStatusQuery =
    connectCommunityUserStatus &&
    convertObjectToArrayQuery(connectCommunityUserStatus, 'connectCommunityUserStatus');
  const everywearCreatorStatusQuery = convertObjectToArrayQuery(
    everywearCreatorStatus,
    'everyWearCreatorStatus',
  );
  const connectUserTypeQuery = convertObjectToArrayQuery(connectUserType, 'connectUserType');
  const connectCommunityUserTypeQuery = convertObjectToArrayQuery(
    connectCommunityUserType,
    'connectCommunityUserType',
  );

  const queries = [
    everywearCreatorStatusQuery,
    connectUserTypeQuery,
    connectCommunityUserTypeQuery,
  ];
  connectUserStatusQuery && queries.push(connectUserStatusQuery);
  connectCommunityUserStatusQuery && queries.push(connectCommunityUserStatusQuery);

  const finalRequestUrl = queries.reduce((result: string, current: string) => {
    return current.length ? `${result}&${current}` : result;
  }, baseRequestUrl);

  return api
    .get<GetMembersResponse>(finalRequestUrl)
    .then((res: AxiosResponse<GetMembersResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getCommentList = async (data: GetCommentListParams) => {
  const { userId, page = FIRST_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE } = data;
  return api
    .get<GetCommentResponse>(`/members/${userId}/comments?page=${page}&limit=${pageSize}`)
    .then((res: AxiosResponse<GetCommentResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getMemberDetail = async (data: { userId: string }) => {
  const { userId } = data;
  return api
    .get<GetMemberGeneralInformationResponse>(`/members/${userId}`)
    .then((res: AxiosResponse<GetMemberGeneralInformationResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getBillingHistory = async () => {
  return { data: { GroupBills: [] } };
};

export const addMemberComment = async (data: PostMemberCommentParams) => {
  const { userId, commentText, page = FIRST_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE } = data;
  return api
    .post<any>(`/members/${userId}/comment?page=${page}&limit=${pageSize}`, {
      CommentText: commentText,
    })
    .then((res: AxiosResponse<boolean>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getAutoProvider = async () => {
  return { data: [] };
};

export const getBenchmarkData = async (data: { page: number }) => {
  const { page = 1 } = data;
  return api
    .get<GetBenchmarkResponse>(`/members/benchmarks?page=${page}&limit=10`)
    .then((res: AxiosResponse<GetBenchmarkResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getLoginHistory = async (data: GetLoginHistoryParams) => {
  const { userId, page = FIRST_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE } = data;
  return api
    .get<GetLoginHistoryResponse>(`/members/${userId}/logins?page=${page}&limit=${pageSize}`)
    .then((res: AxiosResponse<GetLoginHistoryResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getCoupon = async (data: GetCouponListParams) => {
  const { userId, page = FIRST_PAGE_INDEX, pageSize = DEFAULT_PAGE_SIZE } = data;
  return api
    .get<GetCouponListResponse>(`/members/${userId}/coupons?page=${page}&limit=${pageSize}`)
    .then((res: AxiosResponse<GetCouponListResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getPricingPlan = async () => {
  return { data: [] };
};

export const getExportData = async (data: ExcelMemberExportParams) => {
  const { type, keyword } = data;
  // connectUserStatus
  // 1 active / 2 inactive
  return api
    .get<GetMembersResponse>(
      `/members?MarketingAgree=${String(
        type,
      )}&page=1&limit=9999999&keyword=${keyword}&connectUserStatus=1&connectUserStatus=2`,
    )
    .then((res: AxiosResponse<GetMembersResponse>) => {
      const newData = {
        ...res.data,
        items: res.data.items.map((data: Member) => ({
          // array로 되어 있는 부분을 string 으로 바꿈 (excel export data 구조 때문에)
          ...data,
          externalProviders: data.externalProviders
            .map((provider: AuthProvider) => {
              if (
                provider.authProvider === LoginProvider.CLO ||
                provider.authProvider === LoginProvider.MD
              )
                return `${provider.authProviderName.toUpperCase()}(${provider.providerKey})`;
              return '';
            })
            .filter((provider: string) => provider.length > 0)
            .join('/'),
        })),
      };
      return { data: newData }; // response 확인 필요
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const addNewCoupon = async (data: PostCouponInformationParams) => {
  const {
    userId,
    amount,
    dateInterval: [_, couponExpirationDate],
    description,
    isUnlimited,
  } = data;
  return api
    .post(`/members/${userId}/coupon `, {
      Userid: userId,
      Amount: amount,
      Expirationdate: couponExpirationDate,
      Unlimited: isUnlimited,
      Description: description,
    })
    .then((res: AxiosResponse<boolean>) => {
      return res;
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const updateComment = async (data: PutMemberCommentParams) => {
  const { userId, id, partitionKey, commentText } = data;
  return api
    .put(`/members/${userId}/comment`, {
      id,
      partitionKey,
      commentText,
    })
    .then((res: AxiosResponse<boolean>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const deleteComment = async (data: DeleteMemberCommentParams) => {
  const { userId, id, partitionKey, writeBy } = data;
  return api
    .delete(
      `/members/${userId}/comment?UserId=${userId}&Id=${id}&PartitionKey=${partitionKey}&WriteBy=${writeBy}`,
    )
    .then((res: AxiosResponse<boolean>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error: error.response?.data }));
};

// 임시로 운영팀 사용하도록 account delete 추가함.
export const deleteMemberAccountTemp = async (data: DeleteAccountParams) => {
  const { userEmail } = data;
  return api
    .delete(`/members/temp-leave?email=${userEmail}`)
    .then((res: AxiosResponse<boolean>) => {
      return res.data;
    })
    .catch((error: AxiosError<Error>) => ({ error: error.response?.data }));
};

// TODO: BrandPage API 미구현., API 구현시에 수정 필요
export const getBrandSetting = async ({ userId }: GetBrandSettingParams) => {
  return api
    .get<BrandSettingResponse>(`/connects/BrandSettings/${userId}`)
    .then((res: AxiosResponse<BrandSettingResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const updateBrandSetting = async ({ userId, formData }: UpdateBrandSettingParams) => {
  return api
    .put(`/connects/BrandSettings/${userId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res: AxiosResponse<BrandSettingResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getConnectBillingAddress = async ({ userId }: GetBillingAddressParams) => {
  return api
    .get(`/members/${userId}/ConnectBillingAddress`)
    .then((res: AxiosResponse<BillingAddressResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const updateConnectUserStatus = async ({ userId, status }: PutConnectUserStatusParams) => {
  return api
    .put(`/members/${userId}/connect/status`, { status })
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const updateConnectCommunityUserStatus = async ({
  userId,
  status,
}: PutConnectUserStatusParams) => {
  return api
    .put(`/members/${userId}/connect/community/status`, { status })
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const updateEverywearStatus = async ({ userId, status }: PutConnectUserStatusParams) => {
  return api
    .put(`/members/${userId}/everywear/status`, { status })
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const updateConnectUserType = async ({
  userId,
  type,
  supplierTypes = [],
}: PutConnectUserTypeParams) => {
  return api
    .put(`/members/${userId}/connect/type`, { type, supplierTypes })
    .then((response: AxiosResponse) => response)
    .catch(error => ({ error }));
};

export const updateConnectCommunityUserType = async ({
  userId,
  type,
}: PutConnectUserTypeParams) => {
  return api
    .put(`/members/${userId}/connect/community/type`, { type })
    .then((response: AxiosResponse) => response)
    .catch(error => ({ error }));
};

export const getEmailVerificationLink = async ({
  userId,
}: {
  userId: number;
}): Promise<GetEmailVerificationLinkResponse | { error: AxiosError }> => {
  try {
    const { data } = await api.get(`/members/${userId}/verify`);
    return data;
  } catch (error: any) {
    return { error: error as AxiosError<Error> };
  }
};

export const getEmailChangeHistoryList = async ({
  userId,
}: {
  userId: number;
}): Promise<{ data: GetEmailChangeHistoryResponse } | { error: AxiosError<Error> }> => {
  return api
    .get(`/members/${userId}/email`)
    .then((res: AxiosResponse<GetEmailChangeHistoryResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};
