import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  featureUpdateNoticeFormActions,
  selectCheckFormValidation,
  selectFeatureUpdateNoticeFormData,
} from './featureUpdateNoticeFormSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { select } from '@redux-saga/core/effects';
import { History } from 'history';
import * as api from 'api/notice/featureupdate';
import { featureUpdateNoticeListActions } from './featureUpdateNoticeListSlice';
import {
  FeatureUpdateNoticeFormData,
  FeatureUpdateNoticeStatus,
} from 'types/notice/featureUpdateNotice';
import { FEATURE_UPDATE_NOTICE_PATH } from 'routes/ClosetAdminRoutes';
import {
  makeFeatureUpdateNoticeFormData,
  parseFeatureUpdateNoticeForm,
} from 'modules/notice/featureUpdateNotice';
import { GetFeatureUpdateNoticeDetailParams } from 'types/api/notice/featureUpdateNotice';

export function* createModalSaga({ payload: history }: PayloadAction<History>) {
  yield put(featureUpdateNoticeFormActions.validateFormData());
  const isValidationFormData: boolean = yield select(selectCheckFormValidation);
  if (isValidationFormData) return;

  yield put(featureUpdateNoticeFormActions.setLoading(true));
  const data: FeatureUpdateNoticeFormData = yield select(selectFeatureUpdateNoticeFormData);
  const { status } = yield call(
    api.createFeatureUpdateNotice,
    makeFeatureUpdateNoticeFormData(data),
  );

  if (status === 200) {
    yield put(featureUpdateNoticeFormActions.createModalSuccess());
    yield put(
      featureUpdateNoticeListActions.getOnGoingList({
        page: 1,
        statuses: [FeatureUpdateNoticeStatus.SHOWING, FeatureUpdateNoticeStatus.UPCOMING],
      }),
    );
    history.replace(`${FEATURE_UPDATE_NOTICE_PATH}`);
  }
}

export function* editModalSaga({ payload: history }: PayloadAction<History>) {
  yield put(featureUpdateNoticeFormActions.validateFormData());
  const isValidationFormData: boolean = yield select(selectCheckFormValidation);
  if (isValidationFormData) return;

  yield put(featureUpdateNoticeFormActions.setLoading(true));
  const data: FeatureUpdateNoticeFormData = yield select(selectFeatureUpdateNoticeFormData);
  const { seq } = data;
  const { status } = yield call(api.editFeatureUpdateNotice, {
    data: makeFeatureUpdateNoticeFormData(data),
    seq: seq!,
  });

  if (status === 200) {
    yield put(featureUpdateNoticeFormActions.editModalSuccess());
    history.goBack();
  }
}

export function* getModalDetailSaga(action: PayloadAction<GetFeatureUpdateNoticeDetailParams>) {
  const { data, error } = yield call(api.getFeatureUpdateNoticeDetail, action.payload);
  if (data) {
    yield put(
      featureUpdateNoticeFormActions.getDetailDataSuccess(
        yield call(parseFeatureUpdateNoticeForm, data),
      ),
    );
  } else {
    yield put(featureUpdateNoticeFormActions.getDetailDataFailure(error));
  }
}

export default function* featureUpdateNoticeFormSaga() {
  yield all([
    takeLatest(featureUpdateNoticeFormActions.createModal.type, createModalSaga),
    takeLatest(featureUpdateNoticeFormActions.editModal.type, editModalSaga),
    takeLatest(featureUpdateNoticeFormActions.getDetailData.type, getModalDetailSaga),
  ]);
}
