import { all, call, put, takeLatest } from 'redux-saga/effects';
import { confirmSellerActions } from 'features/confirmsellerinfo/confirmSellerSlice';
import * as api from 'api/marketplace/confirmSellerInfo';
import { PayloadAction } from '@reduxjs/toolkit';
import { GetSellerListParams, PutNewSellerStatus } from 'types/api/marketplace/confirmSellerInfo';

function* getSellerListSaga(action: PayloadAction<GetSellerListParams>) {
  const { data } = yield call(api.getSellerList, action.payload);
  if (data) {
    yield put(confirmSellerActions.getSellerListSuccess(data));
  } else {
    yield put(confirmSellerActions.getSellerListFailure());
  }
}

function* getSellerConfirmCommentHistorySaga(action: PayloadAction<{ userId: number }>) {
  const { data } = yield call(api.getSellerConfirmCommentHistory, action.payload);
  if (data) {
    yield put(confirmSellerActions.getSellerConfirmCommentHistorySuccess(data));
  } else {
    yield put(confirmSellerActions.getSellerConfirmCommentHistoryFailure());
  }
}

function* putNewSellerStatusSaga(action: PayloadAction<PutNewSellerStatus>) {
  const { data } = yield call(api.putNewSellerStatus, action.payload);
  if (data) {
    yield put(confirmSellerActions.putNewSellerStatusSuccess(data));
  } else {
    yield put(confirmSellerActions.putNewSellerStatusFailure('ㅁ'));
  }
}

export default function* confirmSellerSaga() {
  yield all([
    takeLatest(confirmSellerActions.getSellerList, getSellerListSaga),
    takeLatest(confirmSellerActions.putNewSellerStatus, putNewSellerStatusSaga),
    takeLatest(
      confirmSellerActions.getSellerConfirmCommentHistory,
      getSellerConfirmCommentHistorySaga,
    ),
  ]);
}
