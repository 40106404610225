import * as React from 'react';
import { LayoutType } from 'types/marketplace/operation/contest';
import { ReactElement, ReactNode } from 'react';
import Grid from '@material-ui/core/Grid';
import AwardCommonStyleWrapper from 'components/marketplace/operation/contest/preview/common/AwardCommonStyleWrapper';
import styled from 'styled-components';

const ThreePieceTypeWrapped = ({
  children,
  layoutType,
}: {
  children: ReactNode;
  layoutType: LayoutType;
}): ReactElement =>
  layoutType === LayoutType.THREE_PIECE_1 ? (
    <ThreePieceType1Spacing>
      <ThreePieceType1GridWrap>
        <Grid container>{children}</Grid>
      </ThreePieceType1GridWrap>
    </ThreePieceType1Spacing>
  ) : (
    <AwardCommonStyleWrapper>
      <ThreePiecesRowWrapper>
        <ThreePiecesRow>{children}</ThreePiecesRow>
      </ThreePiecesRowWrapper>
    </AwardCommonStyleWrapper>
  );

const ThreePieceType1Spacing = styled.div`
  width: 100%;
  margin-top: 1px;
`;

const ThreePieceType1GridWrap = styled.div`
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
`;

const ThreePiecesRowWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(0.633 * 100%);
  margin: 8px 0;
`;

const ThreePiecesRow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  gap: 1px;
`;

export default ThreePieceTypeWrapped;
