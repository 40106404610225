import netIncomeSaga from 'features/netincome/netIncomeSaga';
import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import themeSaga from './theme/themeSaga';
import marketplaceSaga from './marketplace/marketplaceSaga';
import billingSaga from 'features/billing/billingSaga';
import memberRootSaga from 'features/member/saga';
import authSaga from 'features/auth/authSaga';
import benchmarkSaga from './benchmark/benchmarkSaga';
import revenueSaga from 'features/revenue/revenueSaga';
import confirmSellerSaga from 'features/confirmsellerinfo/confirmSellerSaga';
import jinnySaga from 'features/jinny/jinnySaga';
import featureUpdateNoticeSaga from 'features/featureupdatenotice/featureUpdateNoticeSaga';
import downloadsSaga from 'features/worker/saga/downloadsSaga';
import contestSaga from 'features/marketplace/operation/contest/contestSaga';
import contestAwardSaga from 'features/marketplace/operation/contest/contestAwardSaga';

function* rootSaga(): SagaIterator {
  yield all([
    fork(themeSaga), // for template changing theme
    fork(authSaga),
    fork(marketplaceSaga),
    fork(billingSaga),
    fork(memberRootSaga),
    fork(benchmarkSaga),
    fork(revenueSaga),
    fork(netIncomeSaga),
    fork(confirmSellerSaga),
    fork(jinnySaga),
    fork(featureUpdateNoticeSaga),
    fork(downloadsSaga),
    fork(contestSaga),
    fork(contestAwardSaga),
  ]);
}

export default rootSaga;
