import { theme } from '@closet-design-system/theme';
import { createGlobalStyle } from 'styled-components';
import { connectResetStyle } from './connectReset';

/* Connect 사이트 GlobalStyle - 메인 배너 미리보기에 사용 */
const ConnectGlobalStyle = createGlobalStyle`
  ${connectResetStyle}
  html {
    font-size: 10px;
    font-family: 'AvenirNextLTPro', sans-serif;
    color: ${theme.colors.GRAY_50};
    background-color: ${theme.colors.GRAY_800};
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
  }
`;

export default ConnectGlobalStyle;
