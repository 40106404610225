import * as React from 'react';
import { ReactElement, useState } from 'react';
import EllipsisContent from './EllipsisContent';
import { Tooltip, TooltipProps } from '@material-ui/core';
import styled from 'styled-components';
import useCopy from 'hooks/useCopy';
import { COPY_TEXT } from 'constants/worker/downloads';

interface Props {
  content: string;
  width?: number;
  placement?: TooltipProps['placement'];
}
const CopyContent = ({ content, width, placement = 'top' }: Props): ReactElement => {
  const [hovered, setHovered] = useState<boolean>(false);
  const { copyTooltip, setCopyTooltip, onCopy } = useCopy();

  return (
    <CellWrapper
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      maxWidth={width}
    >
      <EllipsisContent
        width={width}
        content={content}
        open={hovered}
        placement={placement === 'top' ? 'bottom' : 'bottom-start'}
      />

      <Tooltip title={copyTooltip} onClose={() => setCopyTooltip(COPY_TEXT)} placement={placement}>
        <TooltipWrapper onClick={() => onCopy(content)} />
      </Tooltip>
    </CellWrapper>
  );
};

export default CopyContent;

const CellWrapper = styled.div<{ maxWidth?: number }>`
  position: relative;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  ${({ maxWidth }) => maxWidth && `max-width:${maxWidth}px`}
`;

const TooltipWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
`;
