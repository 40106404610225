import * as React from 'react';
import { ReactElement, useState } from 'react';
import { InformationObject } from 'types/common/informationTable';
import InformationTable from 'components/common/table/InformationTable';
import styled from 'styled-components';
import StatusDropdown from 'components/memberdetail/detail/connect/connectinformation/StatusDropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  memberDetailActions,
  selectConnectCommunityUserTypeLoading,
  selectConnectEverywearStatusLoading,
  selectConnectUserTypeLoading,
} from 'features/member/reducer/memberDetailSlice';
import { CircularProgress } from '@material-ui/core';
import {
  connectEverywearStatusOption,
  connectUserTypeOption,
  connectUserSupplierTypes,
  CONNECT_ABUSING_PAYMENT,
  CONNECT_ABUSING_COMMUNITY,
  connectCommunityUserTypeOption,
} from 'constants/member/connect';
import {
  ConnectCommunityUserStatus,
  ConnectCommunityUserType,
  ConnectEverywearStatus,
  ConnectUserStatus,
  ConnectUserSupplierType,
  ConnectUserType,
} from 'types/member';
import Checkbox from 'components/common/form/Checkbox';
import CheckboxGroup from 'components/common/form/CheckboxGroup';
import { Nullable } from 'types/common';
import FormCheckbox from 'components/common/searchfilter/FormCheckbox';

interface Props {
  status: ConnectUserStatus;
  communityStatus: ConnectCommunityUserStatus;
  walletAddress: Nullable<string>;
  userId: string;
  everywearCreatorStatus: Nullable<ConnectEverywearStatus>;
  userType: ConnectUserType;
  communityUserType: ConnectCommunityUserType;
  userSupplierTypes: ConnectUserSupplierType[];
}
const ConnectInformationContainer = ({
  status,
  communityStatus,
  walletAddress,
  userId,
  everywearCreatorStatus,
  userType,
  communityUserType,
  userSupplierTypes,
}: Props): ReactElement => {
  const dispatch = useDispatch();
  const everywearStatusLoading = useSelector(selectConnectEverywearStatusLoading);
  const userTypeLoading = useSelector(selectConnectUserTypeLoading);
  const communityUserTypeLoading = useSelector(selectConnectCommunityUserTypeLoading);

  const [checkedAbusingUserStatus, setCheckedAbusingUserStatus] = useState<boolean>(
    status === ConnectUserStatus.INACTIVE,
  );
  const [checkedAbusingCommunityStatus, setCheckedAbusingCommunityStatus] = useState<boolean>(
    communityStatus === ConnectCommunityUserStatus.INACTIVE,
  );

  const [currentEverywearStatus, setCurrentEverywearStatus] = useState<number>(
    everywearCreatorStatus ?? ConnectEverywearStatus.INACTIVE,
  );
  const [currentUserType, setCurrentUserType] = useState<number>(userType);
  const [currentCommunityUserType, setCurrentCommunityUserType] =
    useState<number>(communityUserType);
  const isSupplier = currentUserType === ConnectUserType.SUPPLIER;
  const [currentSupplierTypes, setCurrentSupplierTypes] =
    useState<ConnectUserSupplierType[]>(userSupplierTypes);

  const onChangeStatus = (_event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const connectUserStatus = checkedAbusingUserStatus
      ? ConnectUserStatus.ACTIVE
      : ConnectUserStatus.INACTIVE;
    setCheckedAbusingUserStatus(!checkedAbusingUserStatus);
    dispatch(memberDetailActions.updateConnectUserStatus({ userId, status: connectUserStatus }));
  };

  const onChangeCommunityStatus = (
    _event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const connectCommunityStatus = checkedAbusingCommunityStatus
      ? ConnectCommunityUserStatus.ACTIVE
      : ConnectCommunityUserStatus.INACTIVE;
    setCheckedAbusingCommunityStatus(!checkedAbusingCommunityStatus);
    dispatch(
      memberDetailActions.updateConnectCommunityUserStatus({
        userId,
        status: connectCommunityStatus,
      }),
    );
  };

  const onChangeEverywearStatus = ({
    target: { value },
  }: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setCurrentEverywearStatus(Number(value));
    dispatch(
      memberDetailActions.updateEverywearStatus({
        userId,
        status: Number(value) === ConnectEverywearStatus.INACTIVE ? null : Number(value),
      }),
    );
  };

  const onChangeUserType = ({
    target: { value },
  }: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
    setCurrentUserType(Number(value));
    setCurrentSupplierTypes([]);
    dispatch(
      memberDetailActions.updateUserType({
        userId,
        type: Number(value),
        supplierTypes: [],
      }),
    );
  };

  const onChangeCommunityUserType = ({
    target: { value },
  }: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
    setCurrentCommunityUserType(Number(value));
    dispatch(
      memberDetailActions.updateCommunityUserType({
        userId,
        type: Number(value),
      }),
    );
  };

  const onChangeSupplierTypes = (supplierTypes: ConnectUserSupplierType[]) => {
    setCurrentSupplierTypes(supplierTypes);
    dispatch(
      memberDetailActions.updateUserType({
        userId,
        type: currentUserType,
        supplierTypes: supplierTypes,
      }),
    );
  };

  const memberInformation: InformationObject[] = [
    {
      title: 'Abusing User',
      render: (
        <>
          <FormCheckbox
            label="Payment"
            value={CONNECT_ABUSING_PAYMENT}
            checked={checkedAbusingUserStatus}
            onChange={onChangeStatus}
          />
          <FormCheckbox
            label="Community"
            value={CONNECT_ABUSING_COMMUNITY}
            checked={checkedAbusingCommunityStatus}
            onChange={onChangeCommunityStatus}
          />
        </>
      ),
    },
    {
      title: 'EveryWear Status',
      render: (
        <>
          <StatusDropdown
            optionList={connectEverywearStatusOption}
            currentValue={currentEverywearStatus}
            onChangeValue={onChangeEverywearStatus}
          />
          {everywearStatusLoading && (
            <SpinnerSpacing>
              <CircularProgress size={19} thickness={4} />
            </SpinnerSpacing>
          )}
        </>
      ),
    },
    {
      title: 'User Type',
      render: (
        <UserTypeBlock>
          <StatusDropdown
            optionList={connectUserTypeOption}
            currentValue={currentUserType}
            onChangeValue={onChangeUserType}
          />
          {isSupplier && (
            <SupplierType>
              <CheckboxGroup value={currentSupplierTypes} onChange={onChangeSupplierTypes}>
                {connectUserSupplierTypes.map(({ label, value }) => (
                  <Checkbox key={label} label={label} value={value} checked={false} p={0.5} />
                ))}
              </CheckboxGroup>
            </SupplierType>
          )}
          {userTypeLoading && (
            <SpinnerSpacing>
              <CircularProgress size={19} thickness={4} />
            </SpinnerSpacing>
          )}
        </UserTypeBlock>
      ),
    },
    {
      title: 'Community User Type',
      render: (
        <UserTypeBlock>
          <StatusDropdown
            optionList={connectCommunityUserTypeOption}
            currentValue={currentCommunityUserType}
            onChangeValue={onChangeCommunityUserType}
          />
          {communityUserTypeLoading && (
            <SpinnerSpacing>
              <CircularProgress size={19} thickness={4} />
            </SpinnerSpacing>
          )}
        </UserTypeBlock>
      ),
    },
    {
      title: 'Wallet Public Key',
      render: walletAddress,
    },
  ];

  return (
    <ConnectInformationWrapper>
      <InformationTable title={'[CONNECT] Account Information'} data={memberInformation} />
    </ConnectInformationWrapper>
  );
};

export default ConnectInformationContainer;

const ConnectInformationWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
`;

const SpinnerSpacing = styled.div`
  display: inline-flex;
  height: 28px;
  align-items: center;
  margin-left: 15px;
`;

const UserTypeBlock = styled.div`
  display: flex;
`;

const SupplierType = styled.div`
  margin-left: 20px;
  display: inline;
`;
