import qs, { ParsedQs } from 'qs';
import _omitBy from 'lodash/omit';
import _size from 'lodash/size';
import { History } from 'history';
import { CheckObjectValue } from 'types/common';

export type QueryObjectValue = {
  [key: string]: number[] | string[] | string | number | boolean | null;
};

type QueryString = {
  history: History;
  reset?: boolean;
  objValue?: QueryObjectValue;
};

type ParsedQueryObject = {
  [key: string]: string[];
};

// history : useHistory 객체
// reset : reset 여부
// objValue : 한번에 객체를 전달해서 query 연결
export const addQueryString = ({ history, reset = false, objValue }: QueryString) => {
  if (reset) {
    history.push({
      pathname: history.location.pathname,
    });
    return;
  }

  const queries = qs.parse(history.location.search.replace(/\?/g, ''));
  if (objValue && Object.keys(objValue).length > 0) {
    const newObjValue: { [key: string]: string } = Object.keys(objValue).reduce(
      (result, current) => ({
        ...result,
        [current]: String(
          typeof objValue[current] === 'object'
            ? (objValue[current] as Array<string | number>)
                .map((value: string | number) => String(value))
                .join('+')
            : objValue[current],
        ),
      }),
      {},
    );

    const filterEmptyQuery = (obj: { [key: string]: any }) => {
      return Object.keys(obj).filter(key => {
        if (obj[key].length === 0 || obj[key] === '') {
          return key;
        }
      });
    };

    if (_size(queries) === 0) {
      const queries = _omitBy(newObjValue, filterEmptyQuery(newObjValue));
      history.push({
        pathname: history.location.pathname,
        search: qs.stringify(queries),
      });
      return;
    }

    const notEmptyQueries = _omitBy(
      { ...queries, ...newObjValue },
      filterEmptyQuery({ ...queries, ...newObjValue }),
    );
    history.push({
      pathname: history.location.pathname,
      search: `?${qs.stringify(notEmptyQueries)}`,
    });
  }
};

// ?a=dog+cat&b=apple --> { a: ['dog', 'cat], b: ['apple'] }
export const getParsedQuery = (queries: string): ParsedQueryObject => {
  const parsed = qs.parse(queries, { ignoreQueryPrefix: true });
  return Object.keys(parsed).reduce(
    (result: ParsedQueryObject, current: string) => ({
      ...result,
      [current]: String(parsed?.[current] ?? '').split('+'),
    }),
    {},
  );
};

export const getNewValueForQuery = (object: CheckObjectValue) =>
  Object.keys(object).filter(key => {
    if (object[key]) return key;
  });

export const getParsedQueryByName = (queries: string, name: string): ParseQsValue => {
  const parsed = qs.parse(queries, { ignoreQueryPrefix: true });
  return parsed[name];
};

export type ParseQsValue = undefined | string | string[] | ParsedQs | ParsedQs[];

export const convertStringArray = (query: ParseQsValue): string[] | undefined => {
  if (!query) return;
  if (typeof query === 'string') return [query];
  return query as string[];
};

// { a: true, b: true, c: false } >>> queryName=a&queryName=b 형태로 변경 (api 전달 위한 url query 추가하기 위해)
export const convertObjectToArrayQuery = (value: CheckObjectValue, queryName: string): string => {
  return Object.keys(value)
    .reduce((result: string[], key: string) => {
      if (value[key]) result.push(`${queryName}=${key}`);
      return result;
    }, [])
    .join('&');
};

// 값이 없는 경우 '' return
export const arrayValueToQueryString = (
  paramName: string,
  arrayValue: (string | number)[],
): string =>
  arrayValue.length > 0 ? arrayValue.map(value => `${paramName}=${value}`).join('&') : '';

// 값이 없는 경우 '' return
export const valueToQueryString = (paramName: string, value: number | string | boolean): string =>
  value || value === 0 ? qs.stringify({ [paramName]: value }) : '';

export const getQueryPrefix = (url: string): '&' | '?' => {
  const query = new URL(url).search;
  const hasQueryString = Object.entries(qs.parse(query)).length > 0;

  return hasQueryString ? '&' : '?';
};
