import * as React from 'react';
import { TableCell } from 'types/common/table';
import { getUtcUsFormatDate } from 'modules/utils/time';
import { TextWrapper as DescriptionText } from 'components/member/MemberListColumns';
import { Coupon } from 'types/member';
import { couponStatusLabel } from '../../../../constants/member/connect';

export const couponTableColumns = (): TableCell<Coupon>[] => [
  { align: 'center', name: 'Coupon ID', key: 'couponId', render: rowData => rowData.couponId },
  {
    align: 'center',
    name: 'Description',
    key: 'couponDesc',
    render: rowData => <DescriptionText>{rowData.couponDesc}</DescriptionText>,
  },
  { align: 'center', name: 'Amount', key: 'amount', render: rowData => rowData.amount },
  {
    align: 'center',
    name: 'Status',
    key: 'status',
    render: rowData => couponStatusLabel[rowData.status] ?? 'Error',
  },
  {
    align: 'center',
    name: 'Created Date',
    key: 'createdDate',
    render: rowData => getUtcUsFormatDate(rowData.createdDate),
  },
  {
    align: 'center',
    name: 'Expiration Date',
    key: 'expirationDate',
    render: rowData => getUtcUsFormatDate(rowData.expirationDate),
  },
  { align: 'center', name: 'Created By', key: 'createdBy', render: rowData => rowData.createdBy },
];
