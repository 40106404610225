import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetCommentListParams,
  GetOrderInformationParams,
  GetPlanDetail,
  SaveBackOrderRequest,
  SaveInitializeCommentParams,
  GetComment,
  PostCreditParams,
  PostPlanFreeParams,
  PutCurrentPlanEndDateParams,
  GetTrueUpFeeParams,
  GetTrueUpFee
} from 'types/member/backorder';
import { RootState } from 'features/rootReducer';

export interface BackOrderState {
  data: GetPlanDetail;
  loading: boolean;
  error: any;
  commentList: GetComment[];
  commentListLoading: boolean;
  trueUpFeeList: GetTrueUpFee[];
  trueUpFeeListLoading: boolean;
  saveInitializeCreditCommentLoading: boolean;
}

const initialState: BackOrderState = {
  data: {
    closetUserId: -1,
    email: '',
    group: {
      groupId: '',
      groupName: '',
    },
    currentPlan: {
      planType: 0,
      planStartDate: '',
      planEndDate: '',
      orderType: 0,
      seq: 0,
      invalidDate: false,
    },
    currentMeasurementPeriodPlan: {
      planType: 0,
      currentPeriodPlanStartDate: '',
      currentPeriodPlanEndDate: '',
    },
    previousPlan: [],
    currentCredit: 0,
  },
  loading: false,
  trueUpFeeList: [],
  trueUpFeeListLoading: false,
  commentList: [],
  commentListLoading: false,
  error: null,
  saveInitializeCreditCommentLoading: false,
};

export const backOrderSlice = createSlice({
  name: 'backOrder',
  initialState,
  reducers: {
    onCloseErrorModal: (state: BackOrderState, _action: PayloadAction) => {
      state.error = null;
    },
    // OrderInformation
    getOrderInformation: (
      state: BackOrderState,
      _action: PayloadAction<GetOrderInformationParams>,
    ) => {
      state.loading = true;
    },
    getOrderInformationSuccess: (state: BackOrderState, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = action.payload;
    },
    getOrderInformationFailure: (state: BackOrderState, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //CommentList
    getCommentList: (state: BackOrderState, _action: PayloadAction<GetCommentListParams>) => {
      state.commentListLoading = true;
    },
    getCommentListSuccess: (state: BackOrderState, action: PayloadAction<any>) => {
      state.commentListLoading = false;
      state.commentList = action.payload;
    },
    getCommentListFailure: (state: BackOrderState, action: PayloadAction<any>) => {
      state.commentListLoading = false;
      state.error = action.payload;
    },
    // PlanFree
    savePlanFree: (state: BackOrderState, _action: PayloadAction<PostPlanFreeParams>) => {},
    // Credit
    saveCredit: (state: BackOrderState, _action: PayloadAction<PostCreditParams>) => {},
    saveCreditFailure: (state: BackOrderState, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    // CurrentPlanEnddate
    updateCurrentPlanEnddate: (
      state: BackOrderState,
      _action: PayloadAction<PutCurrentPlanEndDateParams>,
    ) => {},
    // TrueUpFee
    getTrueUpFee: (state: BackOrderState, _action: PayloadAction<GetTrueUpFeeParams>) => {},
    getTrueUpFeeSuccess: (state: BackOrderState, action: PayloadAction<any>) => {
      state.trueUpFeeList = [...state.trueUpFeeList, action.payload];
    },
    getTrueUpFeeFailure: (state: BackOrderState, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    // BackOrder
    saveBackOrder: (state: BackOrderState, _action: PayloadAction<SaveBackOrderRequest>) => {
      state.loading = false;
    },
    saveBackOrderSuccess: (state: BackOrderState, _action: PayloadAction<any>) => {
      state.loading = false;
    },
    saveBackOrderFailure: (state: BackOrderState, _action: PayloadAction<any>) => {
      state.loading = false;
    },
    // InitializeCreditComment
    saveInitializeCreditComment: (
      state: BackOrderState,
      _action: PayloadAction<SaveInitializeCommentParams>,
    ) => {
      state.saveInitializeCreditCommentLoading = true;
    },
    saveInitializeCreditCommentSuccess: (state: BackOrderState) => {
      state.saveInitializeCreditCommentLoading = false;
    },
    saveInitializeCreditCommentFailure: (state: BackOrderState, action: PayloadAction<any>) => {
      state.saveInitializeCreditCommentLoading = false;
      state.error = action.payload;
    },
  },
});

export const selectOrderInformation = (state: RootState) => state.member.backOrder.data;
export const selectOrderInformationLoading = (state: RootState) => state.member.backOrder.loading;

export const selectCommentList = (state: RootState) => state.member.backOrder.commentList;
export const selectCommentListLoading = (state: RootState) =>
  state.member.backOrder.commentListLoading;

export const selectTrueUpFeeList = (state: RootState) => state.member.backOrder.trueUpFeeList;
export const selectTrueUpFeeListLoading = (state: RootState) =>
  state.member.backOrder.trueUpFeeListLoading;

export const selectSaveInitializeCreditCommentLoading = (state: RootState) =>
  state.member.backOrder.saveInitializeCreditCommentLoading;

export const selectBackOrderGroupId = (state: RootState) =>
  state.member.backOrder.data.group.groupId;

export const selectBackOrderError = (state: RootState) => state.member.backOrder.error;

export const backOrderActions = backOrderSlice.actions;
export const backOrderReducer = backOrderSlice.reducer;
