import { TabContainerContext } from 'components/common/tabs/TabContainer';
import * as React from 'react';
import { createContext, PropsWithChildren, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  TabBarOnChangeHandler,
  TabBarProps,
  TabValue,
  TabValueContextProps,
} from 'types/common/tabs';
import classNames from 'classnames';

export const TabBarContext = createContext<TabValueContextProps<any>>({
  setIndicatorProperties: () => {},
});
export const TAB_PADDING = 8;

const TabBar = function <T = TabValue>({
  children,
  selectedValue,
  onChange,
}: PropsWithChildren<TabBarProps<T>>) {
  const { onChangeHandler: ContainerOnChangeHandler } = useContext(TabContainerContext);
  const [indicatorLeft, setIndicatorLeft] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);

  const onChangeHandler: TabBarOnChangeHandler<T> = value => {
    onChange?.(value);
    ContainerOnChangeHandler?.(value);
  };

  const setIndicatorProperties = ({ width, left }: { width: number; left: number }) => {
    setIndicatorWidth(width);
    setIndicatorLeft(left);
  };

  return (
    <TabBarContext.Provider value={{ selectedValue, onChangeHandler, setIndicatorProperties }}>
      <TabBarWrapper className={classNames('closet-tab-bar')}>
        {children}
        <Indicator style={{ width: indicatorWidth, left: indicatorLeft }} />
      </TabBarWrapper>
    </TabBarContext.Provider>
  );
};

const TabBarWrapper = styled.div`
  position: relative;
  white-space: nowrap;
  display: flex;
  width: 100%;
  margin-left: ${-TAB_PADDING}px;
  margin-right: ${-TAB_PADDING}px;
`;

const Indicator = styled.span`
  bottom: 0;
  height: 2px;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: black;
`;

export default TabBar;
