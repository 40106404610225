import { takeEvery } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import * as api from 'api/marketplace/operation/midBanners';
import {
  midBannerActions,
  selectDeleteTargetBannerId,
  selectHistoriesPageNo,
  selectHistoriesPageSize,
} from 'features/marketplace/operation/midBanner/midBannerSlice';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { MidBanner, MidBannerStatus } from 'types/marketplace';

const {
  loadOnGoingList,
  setOnGoingList,
  loadHistories,
  setHistories,
  toggleStatus,
  closeDeleteModal,
  deleteBanner,
  removeBanner,
} = midBannerActions;

function* loadOnGoingListSaga() {
  const { data } = yield call(api.getOnGoingList);
  if (data) {
    yield put(setOnGoingList(data));
  }
}

function* loadHistoriesSaga() {
  const pageNo: number = yield select(selectHistoriesPageNo);
  const pageSize: number = yield select(selectHistoriesPageSize);

  const { data } = yield call(api.getHistories, { pageNo, pageSize });
  if (data) {
    yield put(setHistories(data));
  }
}

function* toggleStatusSaga({ payload: { id, status } }: PayloadAction<MidBanner>) {
  const toggledStatus =
    status === MidBannerStatus.SHOW ? MidBannerStatus.HIDE : MidBannerStatus.SHOW;
  yield call(api.editMidBannerStatus, {
    id,
    status: toggledStatus,
  });
  yield put(loadOnGoingList());

  // TODO: implement Error Toast message
  // if (httpStatus !== 200)
}

function* deleteBannerSaga() {
  const id: string | undefined = yield select(selectDeleteTargetBannerId);
  if (!id) return;
  /*const { status: httpStatus } =  */
  yield call(api.deleteMidBanner, { id });
  yield put(removeBanner());

  // FIXME: API 가 실제로 구현되면, 해당 주석 되돌리기
  yield put(loadOnGoingList());
  yield put(closeDeleteModal());

  // TODO: implement Error Toast message
  // if (httpStatus !== 200)
}

export default function* midBannerSaga() {
  yield all([
    takeLatest(loadOnGoingList, loadOnGoingListSaga),
    takeLatest(loadHistories, loadHistoriesSaga),
    takeEvery(toggleStatus, toggleStatusSaga),
    takeEvery(deleteBanner, deleteBannerSaga),
  ]);
}
