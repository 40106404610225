import * as React from 'react';
import {
  FeatureUpdateNoticeColorType,
  FeatureUpdateNoticeModalFormData,
  FeatureUpdateNoticeModalType,
} from 'types/notice/featureUpdateNotice';
import ImageBackgroundModalContent from './modal/ImageBackgroundModalContent';
import ImageWithTextModalContent from './modal/ImageWithTextModalContent';
import { Dialog } from '@material-ui/core';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

interface Props {
  open: boolean;
  onClose: () => void;
  modalType: FeatureUpdateNoticeModalType;
  data: FeatureUpdateNoticeModalFormData;
  linkButton: string | null;
  useLinkButton: boolean;
  colorType: FeatureUpdateNoticeColorType;
}
const FeatureUpdateNoticePreviewModal = ({
  open,
  onClose,
  modalType,
  data,
  linkButton,
  useLinkButton,
  colorType,
}: Props) => {
  const imageModal = modalType === FeatureUpdateNoticeModalType.IMAGE;
  const textModal = modalType === FeatureUpdateNoticeModalType.TEXT;
  const lightMode = colorType === FeatureUpdateNoticeColorType.LIGHT;
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <CloseButtonSpacing onClick={onClose} className={classNames({ light: lightMode })}>
        <CloseIcon fontSize={'inherit'} color={'inherit'} />
      </CloseButtonSpacing>
      {imageModal ? (
        <ImageBackgroundModalContent data={data} />
      ) : (
        <ImageWithTextModalContent data={data} textModal={textModal} />
      )}
      <Footer className={classNames({ imageModal })}>
        <CheckboxWrapper>
          <Checkbox />
          <TypoBody>Do not open anymore.</TypoBody>
        </CheckboxWrapper>
        {useLinkButton && (
          <Button className={classNames({ light: lightMode })}>{linkButton}</Button>
        )}
      </Footer>
    </Dialog>
  );
};

export default FeatureUpdateNoticePreviewModal;

const CloseButtonSpacing = styled.div`
  z-index: 1;
  position: absolute;
  right: ${props => props.theme.spacing(3)}px;
  top: ${props => props.theme.spacing(3)}px;
  color: #303032; // CDS: G-700
  font-size: 24px; // CDS: Icon Size-L
  cursor: pointer;

  &.light {
    color: #ffffff; // CDS: G-0
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  color: #444446;

  &.imageModal {
    position: absolute;
    bottom: 0;
    color: #ffffff;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Checkbox = styled.div`
  width: 12px;
  height: 12px;
  background: #646466;
  border-radius: 3px;
  margin-right: 6px;
`;

// CDS Body-4
const TypoBody = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: inherit;
`;

const Button = styled.button`
  background: #19191a; // CDS G-900
  padding: 8px 24px;
  border: none;
  color: #ffffff;

  &.light {
    background: #ffffff; // CDS G-0
    color: #19191a; // CDS G-900
  }
`;
