import * as api from 'api/marketplace/netIncome';
import {
  itemNetIncomeActions,
  selectItemNetIncomeQueryString,
  selectItemNetIncomeStatus,
} from 'features/netincome/ItemNetIncomeSlice';
import { toastAction } from 'features/toast/toastSlice';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { NetIncomeStatusTab } from 'types/marketplace/netIncome';
import { AxiosError } from 'axios';

const { loadData, loadDataSuccess, loadDataFailure, setStatus, search, resetFilter, initialize } =
  itemNetIncomeActions;

function* loadDataSaga() {
  const query: string = yield select(selectItemNetIncomeQueryString);
  const status: NetIncomeStatusTab = yield select(selectItemNetIncomeStatus);

  try {
    const { data } = yield call(api.getItemNetIncome, { query, status });
    yield put(loadDataSuccess(data));
  } catch (error) {
    yield put(toastAction.errorResponse(error as AxiosError));
    yield put(loadDataFailure());
  }
}

function* updateDataSaga() {
  yield put(loadData());
}

export default function* itemNetIncomeSaga() {
  yield all([
    takeLatest(initialize, updateDataSaga),
    takeLatest(loadData, loadDataSaga),
    takeLatest(setStatus, updateDataSaga),
    takeLatest(search, updateDataSaga),
    takeLatest(resetFilter, updateDataSaga),
  ]);
}
