import { FeatureUpdateNoticeTableData } from 'types/notice/featureUpdateNotice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import {
  DeleteFeatureUpdateNoticeParams,
  GetFeatureUpdateNoticeParams,
  GetFeatureUpdateNoticeResponse,
} from 'types/api/notice/featureUpdateNotice';

export interface FeatureUpdateNoticeListState {
  onGoingList: {
    loading: boolean;
    data: FeatureUpdateNoticeTableData;
    error: any;
  };
  historyList: {
    loading: boolean;
    data: FeatureUpdateNoticeTableData;
    error: any;
  };
  delete: {
    loading: boolean;
    error: any;
  };
}

export const initialState: FeatureUpdateNoticeListState = {
  onGoingList: {
    loading: false,
    data: {
      currentPage: 1,
      totalItems: 0,
      totalPages: 1,
      items: [],
    },
    error: null,
  },
  historyList: {
    loading: false,
    data: {
      currentPage: 1,
      totalItems: 0,
      totalPages: 1,
      items: [],
    },
    error: null,
  },
  delete: {
    loading: false,
    error: null,
  },
};

export const featureUpdateNoticeListSlice = createSlice({
  name: 'featureUpdateNoticeList',
  initialState,
  reducers: {
    getOnGoingList: (
      state: FeatureUpdateNoticeListState,
      _action: PayloadAction<GetFeatureUpdateNoticeParams>,
    ) => {
      // 탭 이동 시 UI 깜빡임 방지
      state.historyList.data.items = [];
      state.onGoingList.data.items = [];
      state.onGoingList.loading = true;
    },
    getOnGoingListSuccess: (
      state: FeatureUpdateNoticeListState,
      action: PayloadAction<GetFeatureUpdateNoticeResponse>,
    ) => {
      state.onGoingList.data = action.payload;
      state.onGoingList.loading = false;
    },
    getOnGoingListFailure: (state: FeatureUpdateNoticeListState, action: PayloadAction<any>) => {
      state.onGoingList.error = action.payload;
      state.onGoingList.loading = false;
    },
    getHistoryList: (
      state: FeatureUpdateNoticeListState,
      _action: PayloadAction<GetFeatureUpdateNoticeParams>,
    ) => {
      // 탭 이동 시 UI 깜빡임 방지
      state.onGoingList.data.items = [];
      state.historyList.data.items = [];
      state.historyList.loading = true;
    },
    getHistoryListSuccess: (
      state: FeatureUpdateNoticeListState,
      action: PayloadAction<GetFeatureUpdateNoticeResponse>,
    ) => {
      state.historyList.data = action.payload;
      state.historyList.loading = false;
    },
    getHistoryListFailure: (state: FeatureUpdateNoticeListState, action: PayloadAction<any>) => {
      state.historyList.error = action.payload;
      state.historyList.loading = false;
    },
    deleteModal: (
      state: FeatureUpdateNoticeListState,
      _action: PayloadAction<DeleteFeatureUpdateNoticeParams>,
    ) => {
      state.delete.loading = true;
    },
    deleteModalSuccess: (state: FeatureUpdateNoticeListState) => {
      state.delete.loading = false;
    },
    deleteModalFailure: (state: FeatureUpdateNoticeListState, action: PayloadAction<any>) => {
      state.delete.loading = false;
      state.delete.error = action.payload;
    },
  },
});

export const featureUpdateNoticeListActions = featureUpdateNoticeListSlice.actions;
export const featureUpdateNoticeListReducer = featureUpdateNoticeListSlice.reducer;

export const selectOnGoingList = (state: RootState) =>
  state.featureUpdateNotice.featureUpdateNoticeList.onGoingList.data;
export const selectHistoryList = (state: RootState) =>
  state.featureUpdateNotice.featureUpdateNoticeList.historyList.data;
export const selectDeleteLoading = (state: RootState) =>
  state.featureUpdateNotice.featureUpdateNoticeList.delete.loading;
export const selectOnGoingListLoading = (state: RootState) =>
  state.featureUpdateNotice.featureUpdateNoticeList.onGoingList.loading;
export const selectHistoryListLoading = (state: RootState) =>
  state.featureUpdateNotice.featureUpdateNoticeList.historyList.loading;
