import * as React from 'react';
import { createContext, PropsWithChildren } from 'react';
import {
  CheckboxContextGroupChangeEventHandler,
  CheckboxGroupChangeEventHandler,
  CheckboxGroupContextProps,
} from 'types/common/checkbox';

export const CheckboxGroupContext = createContext<CheckboxGroupContextProps<any>>({
  hasGroup: false,
});

export interface CheckboxGroupProps<T = string | number> {
  /** 선택된 array */
  value: T[];
  name?: string;
  /** checkbox 클릭 callback 함수 */
  onChange: CheckboxGroupChangeEventHandler<T>;
}

const CheckboxGroup = <T extends any>({
  value,
  name,
  onChange,
  children,
}: PropsWithChildren<CheckboxGroupProps<T>>) => {
  const onCheckboxChange: CheckboxContextGroupChangeEventHandler<T> = (
    event,
    newValue,
    checked,
  ) => {
    if (checked) {
      onChange([...value, newValue], event);
    } else {
      onChange(
        value.filter((value: T) => value !== newValue),
        event,
      );
    }
  };

  const contextValue: CheckboxGroupContextProps<T> = {
    hasGroup: true,
    name,
    value,
    onChange: onCheckboxChange,
  };

  return (
    <CheckboxGroupContext.Provider value={contextValue}>{children}</CheckboxGroupContext.Provider>
  );
};

export default CheckboxGroup;
