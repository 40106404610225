import monthlyPayoutSaga from 'features/revenue/monthlyPayoutSaga';
import revenueDetailSaga from 'features/revenue/revenueDetailSaga';
import sellerRevenueSaga from 'features/revenue/sellerRevenueSaga';
import withholdingTaxSaga from 'features/revenue/withholdingTaxSaga';
import { all, fork } from 'redux-saga/effects';

export default function* revenueSaga() {
  yield all([
    fork(sellerRevenueSaga),
    fork(monthlyPayoutSaga),
    fork(revenueDetailSaga),
    fork(withholdingTaxSaga),
  ]);
}
