import { getUtcDateShortMonth } from 'modules/utils/time';

type languageValues = { [key: string]: string | number };
type languageObject = { [key: string]: string };

import invoiceLanguage from 'languages/billing.json';
import {
  InvoiceItemType,
  InvoiceDescriptionStatus,
  InvoiceItemDetail,
} from 'types/plansales/billing';
import { ReactText } from 'react';
import { toMantissaWithDollarSign } from 'modules/utils/number';
import { InvoiceItem, GetInvoiceDetailResponse } from 'types/plansales/billing/invoice';
import { planTypeLabel } from 'constants/plansales/billing';

export const getBillingMessage = (languageKey: string = '', values?: languageValues) => {
  if (!values) return (invoiceLanguage as languageObject)[languageKey];
  let message = (invoiceLanguage as languageObject)[languageKey];
  Object.keys(values).map(key => {
    message = message.replace(new RegExp(`{{ ${key} }}`, 'g'), String(values[key]));
  });
  return message;
};

/**
 * billing invoice description selector (private closet 과 동일)
 */
export const getInvoiceDescription =
  (invoiceDetail: GetInvoiceDetailResponse, invoiceItemDetail: InvoiceItemDetail) => () => {
    const {
      groupName,
      paymentSuccessedAt = '',
      totalAmount: rawTotalAmount,
      planType,
      refund, // back office 로 refund 진행된 경우 데이터가 있음 (수동처리의 경우 해당 데이터는 null)
      refundedAt, // back office 로 refund 되지 않고 개발자 수동처리 한 경우 날짜가 있음
    } = invoiceDetail;
    const paid = !!paymentSuccessedAt;
    // back office 에서는 stamp 는 사용하지 않음.
    const paidDate = getUtcDateShortMonth(paymentSuccessedAt || '');
    const refundedDate = getUtcDateShortMonth(refund?.refundedAt || refundedAt || '');

    const startInformation = invoiceItemDetail[InvoiceItemType.FIRST_SUBSCRIPTION];
    const carryOverInformation = invoiceItemDetail[InvoiceItemType.CARRY_OVER_BALANCE];

    const totalAmount = Math.abs(rawTotalAmount || 0);
    const startCardAmount = Math.abs(startInformation?.amount || 0);
    const offerCredit = Math.abs(carryOverInformation?.amount || 0);
    const planName = planTypeLabel[planType];

    const refundMessage = {
      summaryMessage: {
        languageKey: paid ? 'billing_payment_canceled' : '',
        values: {
          canceledDate: refundedDate,
        },
      },
    };

    const getSummaryMessageWithGroupName = (languageKey: string) => ({
      languageKey,
      values: { groupName, planName },
    });

    const getAdditionalMessageWithPaidInformation = (
      languageKey: string,
      additionalValues?: { [key: string]: ReactText },
    ) => ({
      languageKey: paid ? languageKey : '',
      values: {
        amount: toMantissaWithDollarSign(totalAmount, 2),
        paidDate,
        ...additionalValues,
      },
    });

    return {
      [InvoiceDescriptionStatus.START_PAID_BY_ONLY_CARD]: {
        summaryMessage: getSummaryMessageWithGroupName('billing_start_plan_description'),
        additionalMessage: getAdditionalMessageWithPaidInformation('billing_payment_by_card_only'),
      },
      [InvoiceDescriptionStatus.START_PAID_BY_MULTI_METHOD]: {
        summaryMessage: getSummaryMessageWithGroupName('billing_start_plan_description'),
        additionalMessage: getAdditionalMessageWithPaidInformation(
          'billing_payment_by_card_and_credit',
          {
            paidByCredits: toMantissaWithDollarSign(offerCredit, 2),
            paidByCard: toMantissaWithDollarSign(startCardAmount, 2),
          },
        ),
      },
      [InvoiceDescriptionStatus.START_PAID_BY_ONLY_CREDIT]: {
        summaryMessage: getSummaryMessageWithGroupName('billing_start_plan_description'),
        additionalMessage: getAdditionalMessageWithPaidInformation(
          'billing_payment_by_credit_only',
          { amount: toMantissaWithDollarSign(offerCredit, 2) },
        ),
      },
      [InvoiceDescriptionStatus.RENEW_PAID_BY_ONLY_CREDIT]: {
        summaryMessage: getSummaryMessageWithGroupName('billing_renew_plan_description'),
        additionalMessage: getAdditionalMessageWithPaidInformation(
          'billing_payment_by_credit_only',
          { amount: toMantissaWithDollarSign(offerCredit, 2) },
        ),
      },
      [InvoiceDescriptionStatus.RENEW_PAID_BY_MULTI_METHOD]: {
        summaryMessage: getSummaryMessageWithGroupName('billing_renew_plan_description'),
        additionalMessage: getAdditionalMessageWithPaidInformation(
          'billing_payment_by_card_and_credit',
          {
            paidByCredits: toMantissaWithDollarSign(offerCredit, 2),
          },
        ),
      },
      [InvoiceDescriptionStatus.RENEW_PAID_BY_ONLY_CARD]: {
        summaryMessage: getSummaryMessageWithGroupName('billing_renew_plan_description'),
        additionalMessage: getAdditionalMessageWithPaidInformation('billing_payment_by_card_only'),
      },
      // refund: 결제수단, cancel 등으로 확장할 가능성 있으므로 일단 각각 status 로 구분해두었음.
      [InvoiceDescriptionStatus.REFUND_START_PAID_BY_ONLY_CARD]: refundMessage,
      [InvoiceDescriptionStatus.REFUND_START_PAID_BY_MULTI_METHOD]: refundMessage,
      [InvoiceDescriptionStatus.REFUND_START_PAID_BY_ONLY_CREDIT]: refundMessage,
      [InvoiceDescriptionStatus.REFUND_RENEW_PAID_BY_ONLY_CREDIT]: refundMessage,
      [InvoiceDescriptionStatus.REFUND_RENEW_PAID_BY_ONLY_CARD]: refundMessage,
      [InvoiceDescriptionStatus.REFUND_RENEW_PAID_BY_MULTI_METHOD]: refundMessage,
      [InvoiceDescriptionStatus.DOWNGRADE_WITH_MINUS_CREDIT]: {
        summaryMessage: {
          languageKey: 'billing_down_to_free_minus_credits',
        },
        additionalMessage: getAdditionalMessageWithPaidInformation('billing_payment_by_card_only'),
      },

      [InvoiceDescriptionStatus.NO_CASE]: {
        summaryMessage: { languageKey: 'contact_help_center' },
      },
    };
  };

/**
 * billing invoice status selector (private closet 과 동일)
 */
export const getInvoiceDescriptionStatus = (
  refund: boolean,
  invoiceItems: InvoiceItem[],
  invoiceItemDetail: InvoiceItemDetail,
  totalAmount: number,
) => {
  // TODO void 의 경우 refund 와 다른 status 를 가져야함 -> message 는 결제내용 그대로 보여줘야함. 가능한가 ?
  const isAboutStartPlan = !!invoiceItemDetail[InvoiceItemType.FIRST_SUBSCRIPTION];
  const isAboutRenewPlan = !!invoiceItemDetail[InvoiceItemType.RENEW_SUBSCRIPTION];
  const isAboutDowngrade = !!invoiceItemDetail[InvoiceItemType.DOWNGRADE_SUBSCRIPTION];

  // carry over 0 보다 작은경우 : multi payment 가 아니라 card 에 credit 금액만큼 포함되서 결국 card only 결제
  // fixme 결제포함/미포함 carry over 정보 추후 backend 에서 분기전달해서 type별로 처리 예정(w/ted). 현재는 front 임시처리
  const isAboutCarryOver = (invoiceItemDetail[InvoiceItemType.CARRY_OVER_BALANCE]?.amount || 0) < 0;
  const isMultiPayMethod =
    isAboutCarryOver && invoiceItems.filter(item => item.amount !== 0)?.length > 1;

  if (isAboutDowngrade) {
    return InvoiceDescriptionStatus.DOWNGRADE_WITH_MINUS_CREDIT;
  }
  if (isMultiPayMethod && isAboutStartPlan && !isAboutRenewPlan && isAboutCarryOver) {
    // start plan (credit only)
    if (totalAmount === 0) {
      return refund
        ? InvoiceDescriptionStatus.REFUND_START_PAID_BY_ONLY_CREDIT
        : InvoiceDescriptionStatus.START_PAID_BY_ONLY_CREDIT;
    }
    // start plan (card and credit)
    return refund
      ? InvoiceDescriptionStatus.REFUND_START_PAID_BY_MULTI_METHOD
      : InvoiceDescriptionStatus.START_PAID_BY_MULTI_METHOD;
  }
  if (isMultiPayMethod && !isAboutStartPlan && isAboutRenewPlan && isAboutCarryOver) {
    // renew plan (credit only)
    if (totalAmount === 0) {
      return refund
        ? InvoiceDescriptionStatus.REFUND_RENEW_PAID_BY_ONLY_CREDIT
        : InvoiceDescriptionStatus.RENEW_PAID_BY_ONLY_CREDIT;
    }
    // renew plan (card and credit)
    return refund
      ? InvoiceDescriptionStatus.REFUND_RENEW_PAID_BY_MULTI_METHOD
      : InvoiceDescriptionStatus.RENEW_PAID_BY_MULTI_METHOD;
  }

  if (isAboutStartPlan && !isAboutRenewPlan && !isAboutCarryOver) {
    // start plan (card only)
    return refund
      ? InvoiceDescriptionStatus.REFUND_START_PAID_BY_ONLY_CARD
      : InvoiceDescriptionStatus.START_PAID_BY_ONLY_CARD;
  }

  if (!isAboutStartPlan && isAboutRenewPlan && !isAboutCarryOver) {
    // renew plan (card only)
    return refund
      ? InvoiceDescriptionStatus.REFUND_RENEW_PAID_BY_ONLY_CARD
      : InvoiceDescriptionStatus.RENEW_PAID_BY_ONLY_CARD;
  }
  // etc : contact help center
  return InvoiceDescriptionStatus.NO_CASE;
};
