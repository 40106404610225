import * as React from 'react';
import { ReactElement } from 'react';
import MainBanner from 'components/marketplace/operation/mainbannerpreview/MainBanner';
import {
  BannerTheme,
  LayoutType,
  LinkOpenTabType,
  LinkType,
  MainBanner as MainBannerData,
  TextType,
} from 'types/marketplace/operation/mainBanner/mainBannerPreview';
import { useHistory } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { mq, theme as connectTheme } from '@closet-design-system/theme';
import queryString from 'querystring';
import { getMediaQueryRangeValues } from 'modules/mainBannerPreview';
import ConnectGlobalStyle from 'styles/ConnectGlobalStyle';

type MainBannerKeys = keyof MainBannerData;
export type MainBannerPreviewParams = {
  [key in MainBannerKeys]: string;
};

const MainBannerPreview = (): ReactElement => {
  const {
    location: { search },
  } = useHistory();

  const {
    id,
    seq,
    name,
    layout,
    backgroundImagePath,
    mainObjectPath,
    textType,
    subTitle,
    title,
    artworkSource,
    sourceLink,
    theme,
    linkType,
    link,
    buttonText,
    linkOpenTab,
  } = queryString.parse(search) as MainBannerPreviewParams;

  const data: MainBannerData = {
    id,
    seq: Number(seq),
    isShow: true,
    name,
    layout: layout as LayoutType,
    backgroundImagePath,
    mainObjectPath,
    textType: textType as TextType,
    subTitle,
    title,
    artworkSource,
    sourceLink,
    theme: theme as BannerTheme,
    linkType: linkType as LinkType,
    link,
    buttonText,
    linkOpenTab: linkOpenTab as LinkOpenTabType,
  };

  return (
    <ThemeProvider theme={connectTheme}>
      <ConnectGlobalStyle />
      <TempHeader />
      <Wrapper>
        <MainBanner data={data} showBanner />
      </Wrapper>
    </ThemeProvider>
  );
};

export default MainBannerPreview;

const TempHeader = styled.div`
  height: 64px;
  border-top: 5px solid ${({ theme }) => theme.colors.GRAY_600};
  background-color: ${({ theme }) => theme.colors.GRAY_900};
`;

const bannerHeightSize = mq()({
  height: getMediaQueryRangeValues<string>({ s: '420px', m: '420px', l: '630px' }),
});

const Wrapper = styled.div`
  ${bannerHeightSize};
  background-color: ${({ theme }) => theme.colors.GRAY_850};
`;
