import * as React from 'react';
import RequiredMark from 'components/common/RequiredMark';
import { Grid, Typography } from '@material-ui/core';
import DateTimePicker from 'components/common/form/DateTimePicker';
import { DATE_HELP_TEXT } from 'constants/notice/featureUpdateNotice';

interface Props {
  fromDate: Date;
  toDate: Date;
  onChangeFromDate: (date: Date) => void;
  onChangeToDate: (date: Date) => void;
  validation: boolean;
}
const FeatureUpdateNoticeFormDate = ({
  fromDate,
  toDate,
  onChangeFromDate,
  onChangeToDate,
  validation,
}: Props) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Date (KST)
        <RequiredMark />
      </Typography>
      <Grid container spacing={4} alignItems={'center'}>
        <Grid item md={1} sm={1}>
          <Typography variant="body2">From</Typography>
        </Grid>
        <Grid item>
          <DateTimePicker date={fromDate} onChange={onChangeFromDate} useTimezoneText />
        </Grid>
      </Grid>
      <Grid container spacing={4} alignItems={'center'}>
        <Grid item md={1} sm={1}>
          <Typography variant="body2">To</Typography>
        </Grid>
        <Grid item>
          <DateTimePicker
            date={toDate}
            onChange={onChangeToDate}
            useTimezoneText
            error={validation}
            helperText={DATE_HELP_TEXT}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default FeatureUpdateNoticeFormDate;
