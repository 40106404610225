import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { mq } from '@closet-design-system/theme';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
}

/* TwoPieceType2,3, ThreePieceType2,3에서 사용하는 common style wrapper */
const AwardCommonStyleWrapper = ({ children }: Props): ReactElement => {
  return (
    <TwoPieceCommonStyleWrapperRoot>
      <ItemsWrapper>{children}</ItemsWrapper>
    </TwoPieceCommonStyleWrapperRoot>
  );
};

export default AwardCommonStyleWrapper;

export const TwoPieceCommonStyleWrapperRoot = styled.div(
  ({
    theme: {
      breakpoints: { m, l },
    },
  }) =>
    mq({ m, l })({
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: ['0 16px', '0 40px', 0],
    }),
);

const ItemsWrapper = styled.div`
  display: flex;
  height: 100%;
  max-width: 1600px;
  width: 100%;
`;
