import { createApi } from '@reduxjs/toolkit/query/react';
import coreApiBaseQuery from 'api/coreApiBaseQuery';
import store from 'store/configureStore';
import { toastAction } from 'features/toast/toastSlice';
import {
  OperationCategoriesResponse,
  OperationMessageListResponse,
} from 'types/api/marketplace/operation/message';

export const operationMessageApi = createApi({
  reducerPath: 'operationMessageApi',
  baseQuery: coreApiBaseQuery(),
  endpoints(build) {
    return {
      getSearchResult: build.query<OperationMessageListResponse, string>({
        query: queryString => {
          return {
            url: `/connects/operation/messages${queryString}`,
            method: 'get',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      getOperationCategories: build.query<OperationCategoriesResponse, string>({
        query: queryString => {
          return {
            url: `/connects/operation/categories${queryString}`,
            method: 'get',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      getOperationItemCategoryByItemId: build.query<number, string>({
        query: queryString => {
          return {
            url: `/connects/operation/itemCategoryId${queryString}`,
            method: 'get',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      createOperationMessage: build.mutation<number, FormData>({
        query: data => {
          return {
            url: `/connects/operation/messages`,
            data,
            method: 'post',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      createOperationSubCategory: build.mutation<number, FormData>({
        query: data => {
          return {
            url: `/connects/operation/categories`,
            data,
            method: 'post',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      updateOperationMessage: build.mutation<number, { data: FormData; messageId: number }>({
        query: ({ data, messageId }) => {
          return {
            url: `/connects/operation/messages/${messageId}`,
            data,
            method: 'put',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      updateOperationSubCategory: build.mutation<number, FormData>({
        query: data => {
          return {
            url: `/connects/operation/categories`,
            data,
            method: 'put',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      deleteOperationMessage: build.mutation<boolean, number>({
        query: messageId => {
          return {
            url: `/connects/operation/messages/${messageId}`,
            method: 'delete',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      deleteOperationSubCategory: build.mutation<boolean, FormData>({
        query: data => {
          return {
            url: `/connects/operation/categories`,
            data,
            method: 'delete',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
    };
  },
});

export const {
  useLazyGetSearchResultQuery,
  useGetOperationCategoriesQuery,
  useLazyGetOperationCategoriesQuery,
  useCreateOperationMessageMutation,
  useUpdateOperationMessageMutation,
  useDeleteOperationMessageMutation,
  useCreateOperationSubCategoryMutation,
  useUpdateOperationSubCategoryMutation,
  useDeleteOperationSubCategoryMutation,
  useGetOperationItemCategoryByItemIdQuery,
} = operationMessageApi;
