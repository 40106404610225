import { SelectOption } from 'types/common/select';
import {
  ConnectCommunityUserType,
  ConnectEverywearStatus,
  ConnectInitObjectProps,
  ConnectUserStatus,
  ConnectUserSupplierType,
  ConnectUserType,
} from 'types/member';

export const connectStatusLabel = {
  [ConnectUserStatus.ACTIVE]: 'Active',
  [ConnectUserStatus.INACTIVE]: 'Inactive',
};

export const CONNECT_ABUSING_PAYMENT = 'payment';
export const CONNECT_ABUSING_COMMUNITY = 'community';

export const CONNECT_ABUSING_USER = [
  { label: 'Payment', value: CONNECT_ABUSING_PAYMENT },
  { label: 'Community', value: CONNECT_ABUSING_COMMUNITY },
];

export const connectStatusOption: SelectOption[] = [
  {
    label: connectStatusLabel[ConnectUserStatus.ACTIVE],
    value: ConnectUserStatus.ACTIVE,
  },
  {
    label: connectStatusLabel[ConnectUserStatus.INACTIVE],
    value: ConnectUserStatus.INACTIVE,
  },
];

export const connectEverywearStatusLabel = {
  [ConnectEverywearStatus.ACTIVE]: 'Active',
  [ConnectEverywearStatus.INACTIVE]: 'Inactive',
};

export const connectEverywearStatusOption: SelectOption<ConnectEverywearStatus>[] = [
  {
    label: connectEverywearStatusLabel[ConnectEverywearStatus.ACTIVE],
    value: ConnectEverywearStatus.ACTIVE,
  },
  {
    label: connectEverywearStatusLabel[ConnectEverywearStatus.INACTIVE],
    value: ConnectEverywearStatus.INACTIVE,
  },
];

export const connectUserTypeOption: SelectOption<ConnectUserType>[] = [
  {
    label: 'General',
    value: ConnectUserType.GENERAL,
  },
  {
    label: 'Official',
    value: ConnectUserType.OFFICIAL,
  },
  {
    label: 'Supplier',
    value: ConnectUserType.SUPPLIER,
  },
  {
    label: 'CLO Creator Collective',
    value: ConnectUserType.CLO_CREATOR_COLLECTIVE,
  },
];

export enum CouponStatus {
  CANCELED = -1,
  USED,
  ISSUED,
}

export const couponStatusLabel = {
  [CouponStatus.CANCELED]: 'Canceled',
  [CouponStatus.USED]: 'Used',
  [CouponStatus.ISSUED]: 'Issued',
};

export const connectUserSupplierTypes = [
  {
    label: 'Fabric',
    value: ConnectUserSupplierType.Fabric,
  },
  {
    label: 'Trim',
    value: ConnectUserSupplierType.Trim,
  },
];

export const connectCommunityUserTypeOption: SelectOption<ConnectCommunityUserType>[] = [
  {
    label: 'CLOver',
    value: ConnectCommunityUserType.CLOVER,
    divider: 'bottom',
  },
  {
    label: 'General',
    value: ConnectCommunityUserType.GENERAL,
  },
  {
    label: 'Contributor',
    value: ConnectCommunityUserType.CONTRIBUTOR,
  },
];

export const INITIAL_MEMBER_FILTER_VALUES: ConnectInitObjectProps = {
  connectAbusingUserStatus: {
    [CONNECT_ABUSING_PAYMENT]: false,
    [CONNECT_ABUSING_COMMUNITY]: false,
  },
  everywearCreatorStatus: {
    [ConnectEverywearStatus.ACTIVE]: true,
    [ConnectEverywearStatus.INACTIVE]: true,
  },
  connectUserType: {
    [ConnectUserType.GENERAL]: true,
    [ConnectUserType.OFFICIAL]: true,
    [ConnectUserType.SUPPLIER]: true,
    [ConnectUserType.CLO_CREATOR_COLLECTIVE]: true,
  },
  connectCommunityUserType: {
    [ConnectCommunityUserType.CLOVER]: true,
    [ConnectCommunityUserType.GENERAL]: true,
    [ConnectCommunityUserType.CONTRIBUTOR]: true,
  },
};
