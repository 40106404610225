import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { UnderlineTabs } from '@closet-design-system/core';
import { mq } from '@closet-design-system/theme';
import styled from 'styled-components';

interface Tab {
  label: string;
}

interface Props {
  tabs: Tab[];
  activeTabValue: string;
  children: ReactNode;
}

const ContestDetailTabs = ({ tabs, activeTabValue, children }: Props): ReactElement => {
  return (
    <TabsWrapper>
      <UnderlineTabs
        tabs={tabs}
        value={activeTabValue}
        minWidth={'unset'}
        justifyContent={['normal', 'normal', 'center']}
        tabSize={['s', 's', 'm']}
        tabUnderline
      >
        {children}
      </UnderlineTabs>
    </TabsWrapper>
  );
};

export default ContestDetailTabs;

const TabsWrapper = styled.div(
  ({
    theme: {
      breakpoints: { s, m },
    },
  }) =>
    mq({ s, m })({
      padding: ['0 16px', '0 16px', 0],
      minHeight: '30vh',
    }),
);
