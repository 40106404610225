import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { TextWrapper } from 'components/common/searchfilter/FilterElement';
import { GridSize } from '@material-ui/core';

interface Props {
  label?: ReactNode;
  children: ReactNode;
  gridSize?: GridSize;
}

const FilterElementLayout = ({ label, children, gridSize = 2 }: Props): ReactElement => {
  const maxWidth = typeof gridSize === 'number' ? `${(gridSize / 12) * 100}%` : 'initial';
  return (
    <FilterElementLayoutRoot>
      {label && (
        <FilterElementLabel maxWidth={maxWidth}>
          {typeof label === 'string' ? (
            <TextWrapper fontWeight={'600'}>{label}</TextWrapper>
          ) : (
            label
          )}
        </FilterElementLabel>
      )}
      <FilterFormElement>{children}</FilterFormElement>
    </FilterElementLayoutRoot>
  );
};

export default FilterElementLayout;

const FilterElementLayoutRoot = styled.div`
  display: flex;
  align-items: center;
`;

const FilterElementLabel = styled.div<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;
  min-width: 140px;
  min-height: 42px;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const FilterFormElement = styled.div`
  width: 100%;
`;
