import * as React from 'react';
import { ArrowTriangleDownIcon } from '@closet-design-system/core';
import { theme } from '@closet-design-system/theme';
import { ReactElement } from 'react';
import styled from 'styled-components';

const ContestTimelineMobile = (): ReactElement => {
  return (
    <MobileProgressbarLayout>
      <MobileProgressbarBackground>
        <MobileProgressbar>
          <MobileProgressCircle>
            <ArrowTriangleDownIcon size={24} color={theme.colors.GRAY_900} />
          </MobileProgressCircle>
        </MobileProgressbar>
      </MobileProgressbarBackground>
    </MobileProgressbarLayout>
  );
};

const MobileProgressbarLayout = styled.div`
  width: 28px;
  padding: 14px 10px;
`;

const MobileProgressbarBackground = styled.div`
  width: 8px;
  height: 188px;
  border-radius: 30px;
  background: ${({ theme }) => theme.colors.GRAY_600};
`;

const MobileProgressbar = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.AQUA_200};
  height: 100%;
  border-radius: 30px;
  z-index: 10;
`;

const MobileProgressCircle = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 24px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.AQUA_200};
  bottom: -14px;
`;

export default ContestTimelineMobile;
