import * as React from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import RequiredMark from 'components/common/RequiredMark';
import {
  colorTypes,
  featureUpdateNoticeColorTypeLabel,
  featureUpdateNoticeModalTypeLabel,
  modalTypes,
} from 'constants/notice/featureUpdateNotice';
import { Divider } from 'components/common/Divider';
import FeatureUpdateNoticeFormModalContent from './FeatureUpdateNoticeFormModalContent';
import {
  FeatureUpdateNoticeColorType,
  FeatureUpdateNoticeModalFormData,
  FeatureUpdateNoticeModalLinkButton,
  FeatureUpdateNoticeModalType,
  FeatureUpdateNoticeModalTypeValidation,
} from 'types/notice/featureUpdateNotice';

interface Props {
  selectedModalType: FeatureUpdateNoticeModalType;
  enModalForm: FeatureUpdateNoticeModalFormData;
  cnModalForm: FeatureUpdateNoticeModalFormData;
  onChangeModalType: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  onChangeEnModalForm: (data: FeatureUpdateNoticeModalFormData) => void;
  onChangeCnModalForm: (data: FeatureUpdateNoticeModalFormData) => void;
  validation: FeatureUpdateNoticeModalTypeValidation;
  linkButton: FeatureUpdateNoticeModalLinkButton;
  useLinkButton: boolean;
  selectedColorType: FeatureUpdateNoticeColorType;
  onChangeColorType: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}
const FeatureUpdateNoticeFormModalType = ({
  selectedModalType,
  enModalForm,
  cnModalForm,
  onChangeModalType,
  onChangeCnModalForm,
  onChangeEnModalForm,
  validation,
  linkButton,
  useLinkButton,
  selectedColorType,
  onChangeColorType,
}: Props) => {
  const modalContents = [
    {
      title: 'English',
      data: enModalForm,
      onChange: onChangeEnModalForm,
      imageValidation: validation.imageEn,
      titleValidation: validation.titleEn,
      linkButton: linkButton.buttonNameEng,
    },
    {
      title: 'Chinese',
      data: cnModalForm,
      onChange: onChangeCnModalForm,
      imageValidation: validation.imageCn,
      titleValidation: validation.titleCn,
      linkButton: linkButton.buttonNameCn,
    },
  ];
  return (
    <>
      <Grid container spacing={4}>
        <Grid md={12} sm={12} item>
          <Typography variant="h6" gutterBottom>
            Modal Type
            <RequiredMark />
          </Typography>
          <RadioGroup
            aria-label="ModalType"
            name="modal-type"
            value={selectedModalType}
            onChange={onChangeModalType}
            row
          >
            {modalTypes.map(modalType => (
              <FormControlLabel
                key={modalType}
                value={modalType}
                control={<Radio />}
                label={featureUpdateNoticeModalTypeLabel[modalType]}
              />
            ))}
          </RadioGroup>
        </Grid>
        <Grid md={12} sm={12} item>
          <Typography variant="h6" gutterBottom>
            Color Type
          </Typography>
          <RadioGroup
            aria-label="ColorType"
            name="modal-type"
            value={selectedColorType}
            onChange={onChangeColorType}
            row
          >
            {colorTypes.map(colorType => (
              <FormControlLabel
                key={colorType}
                value={colorType}
                control={<Radio />}
                label={featureUpdateNoticeColorTypeLabel[colorType]}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
      <Divider my={2} />
      <Grid container spacing={4}>
        {modalContents.map(
          ({ title, data, onChange, imageValidation, titleValidation, linkButton }, index) => (
            <Grid item md={6} sm={12} key={index}>
              <FeatureUpdateNoticeFormModalContent
                typeTitle={title}
                selectedModalType={selectedModalType}
                data={data}
                onChange={onChange}
                imageValidation={imageValidation}
                titleValidation={titleValidation}
                linkButton={linkButton}
                useLinkButton={useLinkButton}
                selectedColorType={selectedColorType}
              />
            </Grid>
          ),
        )}
      </Grid>
    </>
  );
};

export default FeatureUpdateNoticeFormModalType;
