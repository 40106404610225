import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';

type StyleTextTransform = 'none' | 'capitalize' | 'uppercase' | 'lowercase';
interface Props {
  fontSize?: number;
  textTransform?: StyleTextTransform;
  children: ReactNode;
  className?: string;
  color?: string;
}

const Text = ({
  fontSize = 13,
  children,
  className,
  textTransform = 'none',
  color,
}: Props): ReactElement => {
  return (
    <StyledText
      fontSize={fontSize}
      textTransform={textTransform}
      className={className}
      color={color}
    >
      {children}
    </StyledText>
  );
};

export default Text;

interface StyledTextProps {
  fontSize: number;
  textTransform: StyleTextTransform;
}

const StyledText = styled.p<StyledTextProps>`
  font-size: ${({ fontSize }) => fontSize}px;
  text-transform: ${({ textTransform }) => textTransform};
  margin: 0;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;
