import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ContentLayout from 'components/common/layouts/ContentLayout';
import {
  FeatureUpdateNoticeColorType,
  FeatureUpdateNoticeFormType,
  FeatureUpdateNoticeLinkOpenType,
  FeatureUpdateNoticeModalFormData,
  FeatureUpdateNoticeModalLinkButton,
  FeatureUpdateNoticeModalType,
  FeatureUpdateNoticeTargetSpace,
} from 'types/notice/featureUpdateNotice';
import { FEATURE_UPDATE_NOTICE_PATH } from 'routes/ClosetAdminRoutes';
import { Grid } from '@material-ui/core';
import FeatureUpdateNoticeFormTitle from 'components/notice/featureupdate/form/FeatureUpdateNoticeFormTitle';
import FeatureUpdateNoticeFormTargetSpace from 'components/notice/featureupdate/form/FeatureUpdateNoticeFormTargetSpace';
import FeatureUpdateNoticeFormModalType from 'components/notice/featureupdate/form/FeatureUpdateNoticeFormModalType';
import FeatureUpdateNoticeFormLink from 'components/notice/featureupdate/form/FeatureUpdateNoticeFormLink';
import { Divider } from 'components/common/Divider';
import FeatureUpdateNoticeFormDate from 'components/notice/featureupdate/form/FeatureUpdateNoticeFormDate';
import FeatureUpdateNoticeFormDays from 'components/notice/featureupdate/form/FeatureUpdateNoticeFormDays';
import Button from 'components/common/Button';
import {
  featureUpdateNoticeFormActions,
  selectFeatureUpdateNoticeFormData,
  selectFeatureUpdateNoticeFormLoading,
  selectFeatureUpdateNoticeFormValidation,
} from 'features/featureupdatenotice/featureUpdateNoticeFormSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getDateDifference } from 'modules/utils/time';
import CancelModal from 'components/common/modal/CancelModal';

const CREATE_PAGE_TITLE = 'Create a new modal';
const EDIT_PAGE_TITLE = 'Edit a modal';
const { CREATE, EDIT } = FeatureUpdateNoticeFormType;

const FeatureUpdateNoticeFormContainer = () => {
  const dispatch = useDispatch();

  const { id } = useParams<{ id?: string }>();
  const { pathname } = useLocation();
  const [formType, setFormType] = useState<FeatureUpdateNoticeFormType>(CREATE);
  const isCreateForm = formType === CREATE;

  useEffect(() => {
    const matched = pathname.match(
      RegExp(`${FEATURE_UPDATE_NOTICE_PATH}/(${CREATE}|${EDIT}).{0,}`),
    );
    if (!matched) return;
    const [, pageType] = matched;
    const type = pageType.toLowerCase() as FeatureUpdateNoticeFormType;
    setFormType(type);

    if (type === CREATE) {
      dispatch(featureUpdateNoticeFormActions.resetFormData());
      return;
    }
    if (type === EDIT) {
      dispatch(featureUpdateNoticeFormActions.getDetailData({ seq: Number(id!) }));
      return;
    }
  }, [pathname, id]);

  const {
    title,
    targetSpace,
    modalType,
    modals,
    useLinkButton,
    linkButton,
    displayModalFrom,
    displayModalTo,
    hidingPediod,
    colorType
  } = useSelector(selectFeatureUpdateNoticeFormData);
  const validation = useSelector(selectFeatureUpdateNoticeFormValidation);
  const loading = useSelector(selectFeatureUpdateNoticeFormLoading);

  const onChangeTitle = (evt: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(featureUpdateNoticeFormActions.setTitle(evt.target.value));
  const onChangeTargetModalSpace = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(
      featureUpdateNoticeFormActions.setTargetModalSpace(
        Number(value) as FeatureUpdateNoticeTargetSpace,
      ),
    );
  };
  const onChangeModalType = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(
      featureUpdateNoticeFormActions.setModalType(Number(value) as FeatureUpdateNoticeModalType),
    );
  };
  const onChangeColorType =  (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(
      featureUpdateNoticeFormActions.setColorType(Number(value) as FeatureUpdateNoticeColorType),
    );
  };
  const onChangeUseLinkButton = () =>
    dispatch(featureUpdateNoticeFormActions.setUseLinkButton(!useLinkButton));
  const onChangeLinkOpenType = (_event: React.ChangeEvent<HTMLInputElement>, value: string) =>
    dispatch(
      featureUpdateNoticeFormActions.setLinkOpenType(
        Number(value) as FeatureUpdateNoticeLinkOpenType,
      ),
    );
  const onChangeFromDate = (date: Date) =>
    dispatch(featureUpdateNoticeFormActions.setFromDate(date));
  const onChangeToDate = (date: Date) => dispatch(featureUpdateNoticeFormActions.setToDate(date));
  const onChangeNumberOfDays = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => dispatch(featureUpdateNoticeFormActions.setNumberOfDays(Number(event.target.value)));
  const onChangeEnModalForm = (data: FeatureUpdateNoticeModalFormData) =>
    dispatch(featureUpdateNoticeFormActions.setEnModalForm(data));
  const onChangeCnModalForm = (data: FeatureUpdateNoticeModalFormData) =>
    dispatch(featureUpdateNoticeFormActions.setCnModalForm(data));
  const onChangeLinkButton = (data: FeatureUpdateNoticeModalLinkButton) =>
    dispatch(featureUpdateNoticeFormActions.setLinkButton(data));

  const [isCancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const goToBackPage = () => history.goBack();

  const onSave = () => {
    if (formType === CREATE) {
      dispatch(featureUpdateNoticeFormActions.createModal(history));
      return;
    }
    if (formType === EDIT) {
      dispatch(featureUpdateNoticeFormActions.editModal(history));
      return;
    }
  };

  return (
    <ContentLayout pageTitle={isCreateForm ? CREATE_PAGE_TITLE : EDIT_PAGE_TITLE} loading={loading}>
      <form>
        <Grid container spacing={8}>
          <Grid md={12} sm={12} item>
            <FeatureUpdateNoticeFormTitle
              title={title}
              onChange={onChangeTitle}
              validation={validation.title}
            />
          </Grid>
          <Grid md={12} sm={12} item>
            <FeatureUpdateNoticeFormTargetSpace
              selectedTargetModalSpace={targetSpace}
              onChange={onChangeTargetModalSpace}
            />
          </Grid>
          <Grid md={12} sm={12} item>
            <FeatureUpdateNoticeFormModalType
              selectedModalType={modalType}
              enModalForm={modals.english}
              cnModalForm={modals.chinese}
              onChangeModalType={onChangeModalType}
              onChangeEnModalForm={onChangeEnModalForm}
              onChangeCnModalForm={onChangeCnModalForm}
              validation={validation.modals}
              linkButton={linkButton}
              useLinkButton={useLinkButton}
              selectedColorType={colorType}
              onChangeColorType={onChangeColorType}
            />
          </Grid>
          <Grid md={12} sm={12} item>
            <FeatureUpdateNoticeFormLink
              useLinkButton={useLinkButton}
              linkButton={linkButton}
              onChangeUseLinkButton={onChangeUseLinkButton}
              onChangeLinkOpenType={onChangeLinkOpenType}
              onChangeLinkButton={onChangeLinkButton}
              validation={validation.linkButton}
            />
          </Grid>
          <Grid md={12} sm={12} item>
            <FeatureUpdateNoticeFormDate
              fromDate={displayModalFrom}
              toDate={displayModalTo}
              onChangeFromDate={onChangeFromDate}
              onChangeToDate={onChangeToDate}
              validation={validation.date}
            />
          </Grid>
          <Grid md={12} sm={12} item>
            <FeatureUpdateNoticeFormDays
              maxDays={getDateDifference(displayModalFrom, displayModalTo, 'day')}
              numberOfDays={hidingPediod}
              onChange={onChangeNumberOfDays}
            />
          </Grid>
        </Grid>
        <Divider my={6} />
        <Grid md={12} container item justify={'flex-end'} spacing={3}>
          <Grid item>
            <Button variant="outlined" onClick={() => setCancelModalOpen(true)}>
              CANCEL
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onSave}>
              SAVE
            </Button>
          </Grid>
        </Grid>
      </form>
      <CancelModal
        open={isCancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        content={'Are you sure you want to cancel?'}
        onCancel={goToBackPage}
      />
    </ContentLayout>
  );
};

export default FeatureUpdateNoticeFormContainer;
