import * as React from 'react';
import { ReactElement } from 'react';
import OnePieceLayoutTemplate from 'components/marketplace/operation/contest/preview/desktop/onepiecelayout/OnePieceLayoutTemplate';
import { AwardLayoutPreviewType } from 'types/marketplace/operation/contest';
import AwardThumbnail, {
  AwardThumbnailBox,
} from 'components/marketplace/operation/contest/preview/common/AwardThumbnail';

const OnePieceLayout = ({ items, layoutType, badgeId }: AwardLayoutPreviewType): ReactElement => {
  if (!items) {
    return (
      <OnePieceLayoutTemplate layoutType={layoutType}>
        <AwardThumbnailBox />
      </OnePieceLayoutTemplate>
    );
  }
  return (
    <>
      {items.map(item => (
        <OnePieceLayoutTemplate layoutType={layoutType} key={item.itemId}>
          <AwardThumbnail
            badgeId={badgeId}
            imagePath={item.imagePath}
            creatorImagePath={item.creatorImagePath}
            creator={item.creator}
            maxHeight={941}
          />
        </OnePieceLayoutTemplate>
      ))}
    </>
  );
};

export default OnePieceLayout;
