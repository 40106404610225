import * as React from 'react';
import { TextField, Button } from '@material-ui/core';
import BasicModal from 'components/common/modal/BasicModal';
import styled from 'styled-components';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Comment } from 'types/member';
import { memberDetailActions } from 'features/member/reducer/memberDetailSlice';
import { useDispatch } from 'react-redux';
import { DEFAULT_COMMENT_INFO } from 'containers/memberdetail/MemberCommentContainer';

interface Props {
  userId: string;
  commentModalOpen: boolean;
  closeCommentModal: () => void;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  setInvalidDateModalOpen: Dispatch<SetStateAction<boolean>>;
  setCommentModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedComment: Comment;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

const AddCommentModal = ({
  userId,
  commentModalOpen,
  closeCommentModal,
  setErrorMessage,
  setInvalidDateModalOpen,
  setCommentModalOpen,
  selectedComment,
  isEditing,
  setIsEditing,
}: Props) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState<Comment>(DEFAULT_COMMENT_INFO);

  useEffect(() => {
    if (isEditing) setComment(selectedComment);
  }, [isEditing]);

  const onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment({ ...comment, commentText: e.target.value });
  };

  const saveNewComment = () => {
    const { commentText, id, partitionKey } = comment;
    if (commentText.length === 0) {
      setErrorMessage('Please enter the comment.');
      setInvalidDateModalOpen(true);
      return;
    }
    setCommentModalOpen(false);
    id.length > 0
      ? dispatch(
          memberDetailActions.updateMemberComment({
            userId,
            commentText,
            id,
            partitionKey,
            reFetch: true,
          }),
        )
      : dispatch(
          memberDetailActions.addMemberComment({
            userId,
            commentText,
            reFetch: true,
          }),
        );
    setIsEditing(false);
  };
  const { commentText } = comment;
  return (
    <BasicModal
      open={commentModalOpen}
      onClose={closeCommentModal}
      title={'Comment'}
      contentDividers={false}
      actionButton={
        <>
          <Button variant={'text'} color={'primary'} onClick={closeCommentModal}>
            Cancel
          </Button>
          <Button
            variant={'text'}
            color={'primary'}
            onClick={saveNewComment}
            disabled={commentText.trim().length === 0}
          >
            Confirm
          </Button>
        </>
      }
    >
      <TextField
        fullWidth
        multiline
        rows={8}
        variant={'outlined'}
        value={commentText}
        onChange={onChangeComment}
        autoFocus
        placeholder={'You can leave a comment. It is not delivered to the user.'}
        inputProps={{
          maxLength: 500,
        }}
      />
      <LetterCounter>{`(${commentText.length}/500)`}</LetterCounter>
    </BasicModal>
  );
};

export default AddCommentModal;

export const LetterCounter = styled.div`
  margin-top: 5px;
  float: right;
  color: #292929;
`;
