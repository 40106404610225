import { PayloadAction } from '@reduxjs/toolkit';
import * as api from 'api/marketplace/revenue';
import {
  monthlyPayoutActions,
  selectMonthlyPayoutAllQueryString,
  selectMonthlyPayoutFilter,
  selectMonthlyPayoutQueryString,
  selectPayoutDetailModal,
  selectSellerStatusModal,
} from 'features/revenue/monthlyPayoutSlice';
import { toastAction } from 'features/toast/toastSlice';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { SellerPayoutStatusParams } from 'types/marketplace/revenue';
import { AxiosError } from 'axios';
import { SagaIterator } from 'redux-saga';

const {
  initialize,
  setSortType,
  loadData,
  loadDataSuccess,
  loadDataFailure,
  loadExcelData,
  loadExcelDataSuccess,
  loadExcelDataFailure,
  search,
  pageChange,
  resetFilter,
  openSellerStatus,
  loadSellerPayoutStatus,
  loadSellerPayoutStatusSuccess,
  loadSellerPayoutStatusFailure,
  changeSellerStatus,
  putSellerStatus,
  openSellerPayoutDetail,
  openTotalPayoutDetail,
  loadSellerDetail,
  loadSellerDetailSuccess,
  loadSellerDetailFailure,
  confirmPayoutDetail,
  confirmPayoutDetailSuccess,
  confirmPayoutDetailFailure,
  executePayoutDetail,
  executePayoutDetailFailure,
  executePayoutDetailSuccess,
  resetPayoutDetail,
  resetPayoutDetailSuccess,
  resetPayoutDetailFailure,
  loadPaypalData,
  loadPaypalDataSuccess,
  loadPaypalDataFailure,
} = monthlyPayoutActions;

function* updateDataSaga(): SagaIterator {
  yield put(loadData());
}

function* loadDataSaga(): SagaIterator {
  const query: string = yield select(selectMonthlyPayoutQueryString);
  try {
    const { data } = yield call(api.getSellerMonthlyPayout, query);
    yield put(loadDataSuccess(data));
  } catch (error) {
    yield put(toastAction.errorResponse(error as AxiosError));
    yield put(loadDataFailure());
  }
}

function* loadExcelDataSaga(): SagaIterator {
  const query: string = yield select(selectMonthlyPayoutAllQueryString);
  try {
    const { data } = yield call(api.getSellerMonthlyPayout, query);
    yield put(loadExcelDataSuccess(data));
  } catch (error) {
    yield put(toastAction.errorResponse(error as AxiosError));
    yield put(loadExcelDataFailure());
  }
}

function* openSellerStatusSaga(): SagaIterator {
  yield put(loadSellerPayoutStatus());
}

function* loadSellerPayoutStatusSaga(): SagaIterator {
  const { userId } = yield select(selectSellerStatusModal);
  try {
    const { data } = yield call(api.getSellerPayoutStatusInfo, userId);
    yield put(loadSellerPayoutStatusSuccess(data));
  } catch (error) {
    yield put(toastAction.errorResponse(error as AxiosError));
    yield put(loadSellerPayoutStatusFailure());
  }
}

function* changeSellerPayoutStatusSaga({
  payload: { comment, status },
}: PayloadAction<SellerPayoutStatusParams>): SagaIterator {
  const { userId } = yield select(selectSellerStatusModal);
  if (!comment) {
    yield put(toastAction.toastMessage({ content: 'Please write a comment.' }));
    return;
  }
  yield put(putSellerStatus());
  try {
    const { data } = yield call(api.editSellerPayoutStatus, { userId, status, comment });
    yield put(loadSellerPayoutStatusSuccess(data));
    yield put(loadData());
  } catch (error) {
    yield put(toastAction.errorResponse(error as AxiosError));
    yield put(loadSellerPayoutStatusFailure());
  }
}

function* openSellerPayoutDetailSaga(): SagaIterator {
  const { year } = yield select(selectMonthlyPayoutFilter);
  const { userId, month } = yield select(selectPayoutDetailModal);
  if (!userId) {
    yield put(toastAction.toastMessage({ content: 'no user ID' }));
    return;
  }

  yield put(loadSellerDetail());
  const { data, error } = yield call(api.getSellerPayoutDetail, { userId, year, month });
  if (error) {
    yield put(toastAction.errorResponse(error));
    yield put(loadSellerDetailFailure());
    return;
  }
  yield put(loadSellerDetailSuccess(data));
}

function* openTotalPayoutDetailSaga({ payload: month }: PayloadAction<number>): SagaIterator {
  const { year } = yield select(selectMonthlyPayoutFilter);

  yield put(loadSellerDetail());
  const { data, error } = yield call(api.getTotalPayoutDetail, { month, year });
  if (error) {
    yield put(toastAction.errorResponse(error));
    yield put(loadSellerDetailFailure());
    return;
  }
  yield put(loadSellerDetailSuccess(data));
}

function* confirmPayoutDetailSaga(): SagaIterator {
  const { year } = yield select(selectMonthlyPayoutFilter);
  const { month } = yield select(selectPayoutDetailModal);

  yield put(loadSellerDetail());
  const { error } = yield call(api.editTotalPayoutConfirm, { year, month });
  if (error) {
    yield put(toastAction.errorResponse(error));
    yield put(confirmPayoutDetailFailure());
    return;
  }
  yield put(confirmPayoutDetailSuccess());
  yield put(openTotalPayoutDetail(month));
}

function* executePayoutDetailSaga(): SagaIterator {
  const { year } = yield select(selectMonthlyPayoutFilter);
  const { month } = yield select(selectPayoutDetailModal);

  yield put(loadSellerDetail());
  const { error } = yield call(api.editTotalPayoutExecution, { year, month });
  if (error) {
    yield put(toastAction.errorResponse(error));
    yield put(executePayoutDetailFailure());
    return;
  }
  yield put(executePayoutDetailSuccess());
  yield put(openTotalPayoutDetail(month));
}

function* resetPayoutDetailSaga(): SagaIterator {
  const { year } = yield select(selectMonthlyPayoutFilter);
  const { month } = yield select(selectPayoutDetailModal);

  // yield put(loadSellerDetail());
  const { error } = yield call(api.resetPayoutConfirm, { year, month });
  if (error) {
    yield put(toastAction.errorResponse(error));
    yield put(resetPayoutDetailFailure());
    return;
  }
  yield put(resetPayoutDetailSuccess());
  yield put(openTotalPayoutDetail(month));
}

function* loadPaypalDataSaga(): SagaIterator {
  const { year } = yield select(selectMonthlyPayoutFilter);
  const { month } = yield select(selectPayoutDetailModal);
  try {
    const { data } = yield call(api.getPaypalPayoutCSV, { year: year, month: month });
    yield put(loadPaypalDataSuccess(data));
  } catch (error) {
    yield put(toastAction.errorResponse(error as AxiosError));
    yield put(loadPaypalDataFailure());
  }
}

export default function* monthlyPayoutSaga(): SagaIterator {
  yield all([
    takeLatest(loadData, loadDataSaga),
    takeLatest(loadExcelData, loadExcelDataSaga),
    takeLatest(initialize, updateDataSaga),
    takeLatest(search, updateDataSaga),
    takeLatest(pageChange, updateDataSaga),
    takeLatest(resetFilter, updateDataSaga),
    takeLatest(setSortType, updateDataSaga),
    takeLatest(openSellerStatus, openSellerStatusSaga),
    takeLatest(loadSellerPayoutStatus, loadSellerPayoutStatusSaga),
    takeLatest(changeSellerStatus, changeSellerPayoutStatusSaga),
    takeLatest(openTotalPayoutDetail, openTotalPayoutDetailSaga),
    takeLatest(openSellerPayoutDetail, openSellerPayoutDetailSaga),
    takeLatest(confirmPayoutDetail, confirmPayoutDetailSaga),
    takeLatest(executePayoutDetail, executePayoutDetailSaga),
    takeLatest(loadPaypalData, loadPaypalDataSaga),
    takeLatest(resetPayoutDetail, resetPayoutDetailSaga),
  ]);
}
