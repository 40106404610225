import * as React from 'react';

import async from '../components/template/Async';

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  Monitor,
  PieChart,
  ShoppingCart,
  Sliders,
  User,
  Users,
} from 'react-feather';

// AuthLayout components
const SignIn = async(() => import('../pages/template/auth/SignIn'));
const SignUp = async(() => import('../pages/template/auth/SignUp'));
const ResetPassword = async(() => import('../pages/template/auth/ResetPassword'));
const Page404 = async(() => import('../pages/template/auth/Page404'));
const Page500 = async(() => import('../pages/template/auth/Page500'));

// Components components
const Alerts = async(() => import('../pages/template/components/Alerts'));
const Avatars = async(() => import('../pages/template/components/Avatars'));
const Badges = async(() => import('../pages/template/components/Badges'));
const Buttons = async(() => import('../pages/template/components/Buttons'));
const Cards = async(() => import('../pages/template/components/Cards'));
const Chips = async(() => import('../pages/template/components/Chips'));
const Dialogs = async(() => import('../pages/template/components/Dialogs'));
const ExpPanels = async(() => import('../pages/template/components/ExpansionPanels'));
const Lists = async(() => import('../pages/template/components/Lists'));
const Menus = async(() => import('../pages/template/components/Menus'));
const Pagination = async(() => import('../pages/template/components/Pagination'));
const Progress = async(() => import('../pages/template/components/Progress'));
const Snackbars = async(() => import('../pages/template/components/Snackbars'));
const Tooltips = async(() => import('../pages/template/components/Tooltips'));

// Dashboards components
const Default = async(() => import('../pages/template/dashboards/Default'));
const Analytics = async(() => import('../pages/template/dashboards/Analytics'));

// Forms components
const Pickers = async(() => import('../pages/template/forms/Pickers'));
const SelectionCtrls = async(() => import('../pages/template/forms/SelectionControls'));
const Selects = async(() => import('../pages/template/forms/Selects'));
const TextFields = async(() => import('../pages/template/forms/TextFields'));
const Dropzone = async(() => import('../pages/template/forms/Dropzone'));
const Editors = async(() => import('../pages/template/forms/Editors'));

// Icons components
const MaterialIcons = async(() => import('../pages/template/icons/MaterialIcons'));
const FeatherIcons = async(() => import('../pages/template/icons/FeatherIcons'));

// Pages components
const Blank = async(() => import('../pages/template/pages/Blank'));
const InvoiceDetails = async(() => import('../pages/template/pages/InvoiceDetails'));
const InvoiceList = async(() => import('../pages/template/pages/InvoiceList'));
const Orders = async(() => import('../pages/template/pages/Order'));
const Pricing = async(() => import('../pages/template/pages/Pricing'));
const Profile = async(() => import('../pages/template/pages/Profile'));
const Settings = async(() => import('../pages/template/pages/Settings'));
const Tasks = async(() => import('../pages/template/pages/Tasks'));
const Projects = async(() => import('../pages/template/pages/Projects'));
const Calendar = async(() => import('../pages/template/pages/Calendar'));

// Tables components
const SimpleTable = async(() => import('../pages/template/tables/SimpleTable'));
const AdvancedTable = async(() => import('../pages/template/tables/AdvancedTable'));

// Chart components
const Chartjs = async(() => import('../pages/template/charts/Chartjs'));

// Maps components
const GoogleMaps = async(() => import('../pages/template/maps/GoogleMaps'));
const VectorMaps = async(() => import('../pages/template/maps/VectorMaps'));

// Documentation
const Welcome = async(() => import('../pages/template/docs/Welcome'));
const GettingStarted = async(() => import('../pages/template/docs/GettingStarted'));
const EnvironmentVariables = async(() => import('../pages/template/docs/EnvironmentVariables'));
const Deployment = async(() => import('../pages/template/docs/Deployment'));
const Theming = async(() => import('../pages/template/docs/Theming'));
const StateManagement = async(() => import('../pages/template/docs/StateManagement'));
const Support = async(() => import('../pages/template/docs/Support'));
const Changelog = async(() => import('../pages/template/docs/Changelog'));
const Presentation = async(() => import('../pages/template/docs/Presentation'));

const dashboardsRoutes = {
  id: 'Dashboard',
  path: '/dashboard',
  header: 'Pages',
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: '/dashboard/default',
      name: 'Default',
      component: Default,
    },
    {
      path: '/dashboard/analytics',
      name: 'Analytics',
      component: Analytics,
    },
  ],
  component: null,
};

const pagesRoutes = {
  id: 'Pages',
  path: '/template/pages',
  icon: <Layout />,
  children: [
    {
      path: '/template/pages/settings',
      name: 'Settings',
      component: Settings,
    },
    {
      path: '/template/pages/pricing',
      name: 'Pricing',
      component: Pricing,
    },
    {
      path: '/template/pages/blank',
      name: 'Blank Page',
      component: Blank,
    },
  ],
  component: null,
};

const profileRoutes = {
  id: 'Profile',
  path: '/template/profile',
  icon: <User />,
  component: Profile,
  children: null,
};

const projectsRoutes = {
  id: 'Projects',
  path: '/template/projects',
  icon: <Briefcase />,
  badge: '8',
  component: Projects,
  children: null,
};

const invoiceRoutes = {
  id: 'Invoices',
  path: '/template/invoices',
  icon: <CreditCard />,
  children: [
    {
      path: '/template/invoices',
      name: 'List',
      component: InvoiceList,
    },
    {
      path: '/template/invoices/detail',
      name: 'Details',
      component: InvoiceDetails,
    },
  ],
  component: null,
};

const orderRoutes = {
  id: 'Orders',
  path: '/template/orders',
  icon: <ShoppingCart />,
  component: Orders,
  children: null,
};

const tasksRoutes = {
  id: 'Tasks',
  path: '/template/tasks',
  icon: <CheckSquare />,
  badge: '17',
  component: Tasks,
  children: null,
};

const calendarRoutes = {
  id: 'Calendar',
  path: '/template/calendar',
  icon: <CalendarIcon />,
  component: Calendar,
  children: null,
};

const authRoutes = {
  id: 'Auth',
  path: '/template/auth',
  icon: <Users />,
  children: [
    {
      path: '/template/auth/sign-in',
      name: 'Sign In',
      component: SignIn,
    },
    {
      path: '/template/auth/sign-up',
      name: 'Sign Up',
      component: SignUp,
    },
    {
      path: '/template/auth/reset-password',
      name: 'Reset Password',
      component: ResetPassword,
    },
    {
      path: '/template/auth/404',
      name: '404 Page',
      component: Page404,
    },
    {
      path: '/template/auth/500',
      name: '500 Page',
      component: Page500,
    },
  ],
  component: null,
};

const componentsRoutes = {
  id: 'Components',
  path: '/template/components',
  header: 'Elements',
  icon: <Grid />,
  children: [
    {
      path: '/template/components/alerts',
      name: 'Alerts',
      component: Alerts,
    },
    {
      path: '/template/components/avatars',
      name: 'Avatars',
      component: Avatars,
    },
    {
      path: '/template/components/badges',
      name: 'Badges',
      component: Badges,
    },
    {
      path: '/template/components/buttons',
      name: 'Buttons',
      component: Buttons,
    },
    {
      path: '/template/components/cards',
      name: 'Cards',
      component: Cards,
    },
    {
      path: '/template/components/chips',
      name: 'Chips',
      component: Chips,
    },
    {
      path: '/template/components/dialogs',
      name: 'Dialogs',
      component: Dialogs,
    },
    {
      path: '/template/components/expansion-panels',
      name: 'Expansion Panels',
      component: ExpPanels,
    },
    {
      path: '/template/components/lists',
      name: 'Lists',
      component: Lists,
    },
    {
      path: '/template/components/menus',
      name: 'Menus',
      component: Menus,
    },
    {
      path: '/template/components/pagination',
      name: 'Pagination',
      component: Pagination,
    },
    {
      path: '/template/components/progress',
      name: 'Progress',
      component: Progress,
    },
    {
      path: '/template/components/snackbars',
      name: 'Snackbars',
      component: Snackbars,
    },
    {
      path: '/template/components/tooltips',
      name: 'Tooltips',
      component: Tooltips,
    },
  ],
  component: null,
};

const formsRoutes = {
  id: 'Forms',
  path: '/template/forms',
  icon: <CheckSquare />,
  children: [
    {
      path: '/template/forms/pickers',
      name: 'Pickers',
      component: Pickers,
    },
    {
      path: '/template/forms/selection-controls',
      name: 'Selection Controls',
      component: SelectionCtrls,
    },
    {
      path: '/template/forms/selects',
      name: 'Selects',
      component: Selects,
    },
    {
      path: '/template/forms/text-fields',
      name: 'Text Fields',
      component: TextFields,
    },
    {
      path: '/template/forms/dropzone',
      name: 'Dropzone',
      component: Dropzone,
    },
    {
      path: '/template/forms/editors',
      name: 'Editors',
      component: Editors,
    },
  ],
  component: null,
};

const tablesRoutes = {
  id: 'Tables',
  path: '/template/tables',
  icon: <List />,
  children: [
    {
      path: '/template/tables/simple-table',
      name: 'Simple Table',
      component: SimpleTable,
    },
    {
      path: '/template/tables/advanced-table',
      name: 'Advanced Table',
      component: AdvancedTable,
    },
  ],
  component: null,
};

const iconsRoutes = {
  id: 'Icons',
  path: '/template/icons',
  icon: <Heart />,
  children: [
    {
      path: '/template/icons/material-icons',
      name: 'Material Icons',
      component: MaterialIcons,
    },
    {
      path: '/template/icons/feather-icons',
      name: 'Feather Icons',
      component: FeatherIcons,
    },
  ],
  component: null,
};

const chartRoutes = {
  id: 'Charts',
  path: '/template/charts',
  icon: <PieChart />,
  component: Chartjs,
  children: null,
};

const mapsRoutes = {
  id: 'Maps',
  path: '/template/maps',
  icon: <Map />,
  children: [
    {
      path: '/template/maps/google-maps',
      name: 'Google Maps',
      component: GoogleMaps,
    },
    {
      path: '/template/maps/vector-maps',
      name: 'Vector Maps',
      component: VectorMaps,
    },
  ],
  component: null,
};

const presentationRoutes = {
  id: 'Presentation',
  path: '/template',
  header: 'Docs',
  icon: <Monitor />,
  component: Presentation,
  children: null,
};

const documentationRoutes = {
  id: 'Documentation',
  path: '/documentation',
  icon: <BookOpen />,
  children: [
    {
      path: '/documentation/welcome',
      name: 'Welcome',
      component: Welcome,
    },
    {
      path: '/documentation/getting-started',
      name: 'Getting Started',
      component: GettingStarted,
    },
    {
      path: '/documentation/environment-variables',
      name: 'Environment Variables',
      component: EnvironmentVariables,
    },
    {
      path: '/documentation/deployment',
      name: 'Deployment',
      component: Deployment,
    },
    {
      path: '/documentation/theming',
      name: 'Theming',
      component: Theming,
    },
    {
      path: '/documentation/state-management',
      name: 'State Management',
      component: StateManagement,
    },
    {
      path: '/documentation/support',
      name: 'Support',
      component: Support,
    },
  ],
  component: null,
};

const changelogRoutes = {
  id: 'Changelog',
  path: '/changelog',
  badge: 'v1.2.0',
  icon: <List />,
  component: Changelog,
  children: null,
};

// This route is not visisble in the sidebar
const privateRoutes = {
  id: 'Private',
  path: '/private',
  component: Blank,
  children: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  pagesRoutes,
  profileRoutes,
  projectsRoutes,
  orderRoutes,
  invoiceRoutes,
  tasksRoutes,
  calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  documentationRoutes,
  changelogRoutes,
  presentationRoutes,
  privateRoutes,
];

// Routes using the AuthLayout layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar (template)
export const sidebarRoutes = [
  dashboardsRoutes,
  pagesRoutes,
  profileRoutes,
  projectsRoutes,
  orderRoutes,
  invoiceRoutes,
  tasksRoutes,
  calendarRoutes,
  authRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  presentationRoutes,
  documentationRoutes,
  changelogRoutes,
];
