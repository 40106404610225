import * as React from 'react';
import { ReactElement } from 'react';
import { AZURE_STORAGE_PREFIX } from 'constants/common/url';
import styled from 'styled-components';

const onePieceType1EllipseUrl = `${AZURE_STORAGE_PREFIX}/public/connect/contest/layout/onePieceType1_ellipse.svg`;

const OnePieceType1Background = (): ReactElement => {
  return (
    <OnePieceType1BackgroundRoot>
      <img src={onePieceType1EllipseUrl} alt={'ellipse'} />
    </OnePieceType1BackgroundRoot>
  );
};

export default OnePieceType1Background;

const OnePieceType1BackgroundRoot = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  img {
    width: 85%;
  }
`;
