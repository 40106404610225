import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { contestAPI } from 'api/marketplace/operation/contest';
import {
  contestActions,
  selectContestFilter,
} from 'features/marketplace/operation/contest/contestSlice';
import { ContestFilterType, ContestVisibilityType } from 'types/marketplace/operation/contest';
import { serializerFormData } from 'modules/contest';
import {
  ContestRequest,
  CreateContestRequest,
  RemoveContestRequest,
  UpdateContestRequest,
} from 'types/api/marketplace/operation/contest';
import { SagaIterator } from 'redux-saga';
import { toastAction } from 'features/toast/toastSlice';

const {
  getContestListSuccess,
  getContestListFailure,
  getContestSuccess,
  getContestFailure,
  getContestHistorySuccess,
  getContestHistoryFailure,
  setContestEvaluationOpenFailure,
  setContestEvaluationOpenSuccess,
  createContestSuccess,
  createContestFailure,
  updateContestSuccess,
  updateContestFailure,
  removeContestSuccess,
  removeContestFailure,
  getContestOngoingListSuccess,
  getContestOngoingListFailure,
} = contestActions;

export function* uploadImageSaga(action: PayloadAction<{ formData: FormData }>): SagaIterator {
  const { formData } = action.payload;
  const { data, error } = yield call(contestAPI.uploadImage, { formData });
  if (data) {
    yield put(contestActions.uploadImageSuccess(data));
  } else {
    yield put(contestActions.uploadImageFailure(error));
  }
}

export function* getContestListSaga(): SagaIterator {
  const filter: ContestFilterType = yield select(selectContestFilter);
  const { data, error } = yield call(contestAPI.getContestList, filter);
  if (data) {
    yield put(getContestListSuccess(data));
  } else {
    yield put(getContestListFailure(error));
  }
}

export function* visibilitySaga(
  action: PayloadAction<{ contestSeq: number; visibility: ContestVisibilityType }>,
): SagaIterator {
  yield call(contestAPI.updateShowContest, action.payload);
}

export function* getContestSaga(action: PayloadAction<ContestRequest>): SagaIterator {
  const { contestSeq, callback } = action.payload;
  const { data, error } = yield call(contestAPI.getContest, contestSeq);
  if (data) {
    yield put(getContestSuccess(data));
  } else {
    yield put(getContestFailure(error));
    yield put(
      toastAction.toastMessage({ autoHide: true, content: error?.response?.data?.resultMessage }),
    );
    callback?.();
  }
}

export function* getContestHistorySaga({
  payload: contestSeq,
}: PayloadAction<number>): SagaIterator {
  const { data, error } = yield call(contestAPI.getContestHistory, contestSeq);
  if (data) {
    yield put(getContestHistorySuccess(data));
  } else {
    yield put(getContestHistoryFailure(error));
  }
}

export function* setContestEvaluationOpenSaga({
  payload: contestSeq,
}: PayloadAction<number>): SagaIterator {
  const { data, error } = yield call(contestAPI.postContestOpen, contestSeq);
  if (data) {
    yield put(setContestEvaluationOpenSuccess(contestSeq));
  } else {
    yield put(setContestEvaluationOpenFailure(error));
  }
}

export function* createContestSaga(action: PayloadAction<CreateContestRequest>): SagaIterator {
  const { payload, callback } = action.payload;
  const { data, error } = yield call(contestAPI.postContest, serializerFormData(payload));
  if (data) {
    yield put(createContestSuccess());
    callback?.();
  } else {
    yield put(
      toastAction.toastMessage({
        content: error?.response?.data?.resultMessage || 'Contest Create Error',
        autoHide: true,
      }),
    );
    yield put(createContestFailure(error));
  }
}

export function* updateContestSaga(action: PayloadAction<UpdateContestRequest>): SagaIterator {
  const { contestSeq, payload, callback } = action.payload;
  const { data, error } = yield call(contestAPI.updateContest, {
    contestSeq,
    payload: serializerFormData(payload),
  });
  if (data) {
    yield put(updateContestSuccess());
    callback?.();
  } else {
    yield put(
      toastAction.toastMessage({
        content: error?.response?.data?.resultMessage || 'Contest Update Error',
        autoHide: true,
      }),
    );
    yield put(updateContestFailure(error));
  }
}

export function* removeContestSaga(action: PayloadAction<RemoveContestRequest>): SagaIterator {
  const { contestSeq, callback } = action.payload;
  const { data, error } = yield call(contestAPI.removeContest, contestSeq);
  if (data) {
    yield put(removeContestSuccess());
    callback?.();
  } else {
    yield put(removeContestFailure(error));
  }
}

export function* getContestOngoingListSaga(_action: PayloadAction): SagaIterator {
  const { data, error } = yield call(contestAPI.getContestOngoingList);
  if (data) {
    yield put(getContestOngoingListSuccess(data));
  } else {
    yield put(getContestOngoingListFailure(error));
  }
}

export default function* contestSaga(): SagaIterator {
  yield all([
    takeLatest(contestActions.uploadImage, uploadImageSaga),
    takeLatest(contestActions.getContestList, getContestListSaga),
    takeLatest(contestActions.updateVisibility, visibilitySaga),
    takeLatest(contestActions.getContest, getContestSaga),
    takeLatest(contestActions.getContestHistory, getContestHistorySaga),
    takeLatest(contestActions.setContestEvaluationOpen, setContestEvaluationOpenSaga),
    takeLatest(contestActions.createContest, createContestSaga),
    takeLatest(contestActions.updateContest, updateContestSaga),
    takeLatest(contestActions.removeContest, removeContestSaga),
    takeLatest(contestActions.getContestOngoingList, getContestOngoingListSaga),
  ]);
}
