import { TableCell } from 'types/common/table';
import { AuthProvider, LoginProvider, Member } from 'types/member';
import { getUtcUSFormatDateWithTime } from 'modules/utils/time';
import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from 'constants/common';
import { getTableRowNumber } from 'modules/dataTable';
import CreateAnEmailVerificationLinkButton from './memberListCell/CreateAnEmailVerificationLinkButton';

export const memberListColumns = (
  totalItems: number,
  currentPageIndex: number,
): TableCell<Member>[] => [
  {
    align: 'center',
    name: 'No.',
    key: 'no',
    render: (rowData, columnData, rowIndex) =>
      getTableRowNumber(totalItems, DEFAULT_PAGE_SIZE, currentPageIndex, rowIndex),
  },
  {
    key: 'name',
    align: 'center',
    name: 'Name',
    render: (rowData: Member) => (
      <MemberNameLink to={`/member/${rowData.userid}`}>{rowData.name}</MemberNameLink>
    ),
  },
  {
    align: 'center',
    name: 'Email',
    key: 'email',
    render: (rowData: Member) => <TextWrapper>{rowData.email}</TextWrapper>,
  },
  {
    align: 'center',
    name: 'Email Verification',
    key: 'EmailVerification',
    render: (rowData: Member) => {
      if (rowData.verifyToken === null) {
        return <TextWrapper>Verified</TextWrapper>;
      }

      return <CreateAnEmailVerificationLinkButton userId={rowData.userid} />;
    },
  },
  {
    align: 'center',
    name: 'External',
    key: 'external',
    render: (rowData: Member) =>
      rowData.externalProviders.map((provider: AuthProvider, index: number) => {
        if (
          provider.authProvider === LoginProvider.CLO ||
          provider.authProvider === LoginProvider.MD
        )
          return (
            <ProviderText key={index}>{`${provider.authProviderName.toUpperCase()} : ${
              provider.providerKey
            }`}</ProviderText>
          );
        return '';
      }),
  },
  {
    align: 'center',
    name: 'CLO-SET Status',
    key: 'closetStatus',
    width: '110px',
    render: (rowData: Member) => rowData.status,
  },
  {
    align: 'center',
    name: 'Marketing Agreement',
    key: 'marketingAgreement',
    width: '150px',
    render: (rowData: Member) => (rowData.marketingAgree ? 'Yes' : 'No'),
  },
  {
    align: 'center',
    name: 'Registered Date',
    key: 'registeredDate',
    width: '220px',
    render: (rowData: Member) => getUtcUSFormatDateWithTime(rowData.registrationDate, true),
  },
];

const MemberNameLink = styled(Link)`
  text-decoration: underline;
  color: ${props => props.theme.palette.grey[900]};
  word-break: break-all;
`;

export const TextWrapper = styled.span`
  word-break: break-all;
`;

const ProviderText = styled.div`
  display: block;
`;
