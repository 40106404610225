import * as React from 'react';
import { ReactElement } from 'react';
import { mq, theme } from '@closet-design-system/theme';
import styled from 'styled-components';
import EditorPreview from 'components/common/EditorPreview';
import { useSelector } from 'react-redux';
import { selectContentsData } from 'features/marketplace/operation/contest/contestSlice';

const ContestInformationTab = (): ReactElement => {
  const { description } = useSelector(selectContentsData);

  return (
    <ContestInformationWrapped>
      <InformationContent>
        <SpacingWrap top={[24, 24, 24, 64]}>
          <EditorPreview content={description} />
        </SpacingWrap>
      </InformationContent>
    </ContestInformationWrapped>
  );
};

export default ContestInformationTab;

const InformationContent = styled.div(
  ({
    theme: {
      breakpoints: { m, l, xl },
    },
  }) =>
    mq({ m, l, xl })({
      padding: [0, '0 32px', '0 120px', '0 240px'],
      minHeight: '30vh',
      color: `${theme.colors.GRAY_50}`,
    }),
);

const SpacingWrap = styled.div<{
  top?: number[] | number;
  bottom?: number[] | number;
}>(({ top, bottom }) =>
  mq()({
    marginTop: top,
    marginBottom: bottom,
  }),
);

const ContestInformationWrapped = styled.div`
  position: relative;
  width: 100%;
  min-height: 30vh;
`;
