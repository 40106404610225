import { combineReducers } from '@reduxjs/toolkit';
import {
  featureUpdateNoticeListReducer,
  FeatureUpdateNoticeListState,
} from './featureUpdateNoticeListSlice';
import {
  featureUpdateNoticeFormReducer,
  FeatureUpdateNoticeFormState,
} from './featureUpdateNoticeFormSlice';

export interface FeatureUpdateNoticeState {
  featureUpdateNoticeList: FeatureUpdateNoticeListState;
  featureUpdateNoticeForm: FeatureUpdateNoticeFormState;
}

export default combineReducers({
  featureUpdateNoticeList: featureUpdateNoticeListReducer,
  featureUpdateNoticeForm: featureUpdateNoticeFormReducer,
});
