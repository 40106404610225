import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import { parseStoreQueryString } from 'modules/revenue';
import qs, { ParsedQs } from 'qs';
import { StoreType } from 'types/common';
import {
  RevenueSortType,
  SellerRevenueDetailFilter,
  SellerRevenueDetailInfo,
} from 'types/marketplace/revenue';

interface RevenueDetailSliceState {
  filter?: SellerRevenueDetailFilter;
  loading: boolean;
  data?: SellerRevenueDetailInfo;
  error?: boolean;
}

const initialState: RevenueDetailSliceState = {
  loading: false,
};

export const revenueDetailSlice = createSlice({
  name: 'revenueDetail',
  initialState,
  reducers: {
    loadData: (state, { payload }: PayloadAction<ParsedQs>) => {
      const { email, startDate, endDate, store, keyword, sortType, userId, tab } = payload;
      if (!startDate || !endDate || !userId) {
        state.loading = false;
        return;
      } else {
        state.loading = true;
        state.data = undefined;
        state.filter = {
          email,
          startDate,
          endDate,
          store: parseStoreQueryString(store, [StoreType.CONNECT, StoreType.MD]),
          keyword: keyword ?? '',
          sortType: sortType ?? RevenueSortType.SELLER_REVENUE_DESC,
          userId,
          tab: tab ?? 'all',
        } as unknown as SellerRevenueDetailFilter;
      }
    },
    loadDataSuccess: (state, { payload }: PayloadAction<SellerRevenueDetailInfo>) => {
      state.loading = false;
      state.data = payload;
    },
    loadDataFailure: state => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const revenueDetailActions = revenueDetailSlice.actions;
export const revenueDetailReducer = revenueDetailSlice.reducer;

export const selectRevenueDetailError = (state: RootState) => state.revenue.detail.error;
export const selectRevenueDetailLoading = (state: RootState) => state.revenue.detail.loading;
export const selectRevenueDetailData = (state: RootState) => state.revenue.detail.data;
export const selectRevenueDetailQueryString = (state: RootState): string =>
  qs.stringify(state.revenue.detail.filter, { arrayFormat: 'repeat' });
