export interface OnGoingList {
  showingBanners: MidBanner[];
  upcomingBanners: MidBanner[];
}

export interface Histories {
  pageNo: number;
  pageSize: number;
  totalCount: number;
  banners: MidBanner[];
}

export interface MidBanner {
  id: string; // Banner Id(Key)
  title: string;
  desktopImageUrl: string;
  tabletImageUrl: string;
  link: string;
  useLink: boolean;
  useNewtab: boolean;
  status: MidBannerStatus;
  fromDateTime: string; // UTC
  toDateTime: string; // UTC
}

export enum MidBannerStatus {
  SHOW = 1,
  HIDE = 2,
}

export enum BannerTab {
  ON_GOING_LIST = 'ongoing',
  HISTORIES = 'history',
}

export enum MidBannerFormType {
  CREATE = 'create',
  EDIE = 'edit',
  COPY = 'copy',
}

export enum MidBannerLinkType {
  CURRENT = 'current',
  NEW_TAB = 'new_tab',
}

export interface MidBannerFormData {
  title: string;
  desktopImage: File | null;
  tabletImage: File | null;
  isNoLink: boolean;
  url: string;
  linkType: MidBannerLinkType;
  isShowImmediately: boolean;
  from: Date;
  to: Date;
  status: MidBannerStatus;
}

export interface MidBannerFormDataValidation {
  title: boolean;
  desktopImage: boolean;
  tabletImage: boolean;
  link: boolean;
  from: boolean;
  to: boolean;
}

export enum RevenueShareTab {
  SELLER_REVENUE = 'seller',
  MONTHLY_PAYOUT = 'monthly',
  WITHHOLDING_TAX = 'withholding-tax',
}

export enum SellerRevenueDetailTab {
  ALL = 'all',
  ITEM = 'item',
  ORIGINAL_ITEM = 'originalItem',
}
export enum NetIncomeTab {
  TOTAL = 'total',
  ITEM = 'item',
  COMMISSION = 'commission',
}
