import {
  arrayValueToQueryString,
  getParsedQuery,
  valueToQueryString,
} from 'modules/utils/queryString';
import {
  initialCommentSearchParams,
  initialPostSearchParams,
} from 'constants/marketplace/community';
import { SearchDateRangeFilterProps } from 'types/common/searchfilter';
import { CommunityCommentFilterProps, CommunityPostFilterProps } from 'types/marketplace/community';

export const getSearchApiQueryString = <T extends SearchDateRangeFilterProps>(
  searchParams: T,
  arrayKeys: string[],
): string => {
  const arrayValueKey = arrayKeys.join('|');
  return Object.entries(searchParams)
    .map(([key, value]) => {
      if (key === 'keyword') {
        return valueToQueryString('searchKeyword', value);
      }
      if (arrayValueKey.includes(key)) {
        const arrayValues = value as Array<any>;
        return arrayValueToQueryString(key, arrayValues);
      }
      return valueToQueryString(key, value);
    })
    .filter(querystring => !!querystring)
    .join('&');
};

export const getPostParamsFromQuery = (queryString: string): CommunityPostFilterProps => {
  const parsedQuery = getParsedQuery(queryString);
  return {
    dateFrom: parsedQuery['dateFrom']?.[0] ?? initialPostSearchParams.dateFrom,
    dateTo: parsedQuery['dateTo']?.[0] ?? initialPostSearchParams.dateTo,
    dateTerm: Number(parsedQuery['dateTerm']?.[0] ?? initialPostSearchParams.dateTerm),
    statuses: parsedQuery['statuses']
      ? parsedQuery['statuses'].map(status => Number(status))
      : initialPostSearchParams.statuses,
    categories: parsedQuery['categories']
      ? parsedQuery['categories'].map(category => Number(category))
      : initialPostSearchParams.categories,
    sort: Number(parsedQuery['sort'] ?? initialPostSearchParams.sort),
    mainPick: parsedQuery['mainPick']
      ? JSON.parse(parsedQuery['mainPick'][0])
      : initialPostSearchParams.mainPick,
    keywordType: Number(parsedQuery['keywordType'] ?? initialPostSearchParams.keywordType),
    searchKeyword: parsedQuery['searchKeyword']?.[0] ?? initialPostSearchParams.searchKeyword,
    page: Number(parsedQuery['page']?.[0] ?? initialPostSearchParams.page),
  };
};

export const getCommentParamsFromQuery = (queryString: string): CommunityCommentFilterProps => {
  const parsedQuery = getParsedQuery(queryString);
  return {
    dateFrom: parsedQuery['dateFrom']?.[0] ?? initialCommentSearchParams.dateFrom,
    dateTo: parsedQuery['dateTo']?.[0] ?? initialCommentSearchParams.dateTo,
    dateTerm: Number(parsedQuery['dateTerm']?.[0] ?? initialCommentSearchParams.dateTerm),
    statuses: parsedQuery['statuses']
      ? parsedQuery['statuses'].map(status => Number(status))
      : initialCommentSearchParams.statuses,
    userTypes: parsedQuery['userTypes']
      ? parsedQuery['userTypes'].map(userType => Number(userType))
      : initialCommentSearchParams.userTypes,
    keywordType: Number(parsedQuery['keywordType'] ?? initialCommentSearchParams.keywordType),
    searchKeyword: parsedQuery['searchKeyword']?.[0] ?? initialCommentSearchParams.searchKeyword,
    sort: Number(parsedQuery['sort'] ?? initialCommentSearchParams.sort),
    page: Number(parsedQuery['page']?.[0] ?? initialCommentSearchParams.page),
  };
};
