import { combineReducers } from 'redux';
import { memberListReducer } from 'features/member/reducer/memberListSlice';
import { backOrderReducer } from 'features/member/reducer/backorderSlice';
import { memberDetailReducer } from 'features/member/reducer/memberDetailSlice';

export default combineReducers({
  memberList: memberListReducer,
  backOrder: backOrderReducer,
  memberDetail: memberDetailReducer,
});
