/**
 * Connect의 메인 배너를 관리하는 API Group
 * https://clo.atlassian.net/wiki/spaces/NX/pages/2332426271/MainBanner
 * author: Greg
 */

import coreApi from 'api/coreApi';
import { GetMainBannerListData, MainBannerData } from 'types/api/marketplace/operation/mainBanner';
import { PagingParams } from 'types/marketplace/operation/mainBanner/mainBanner';
import { ApiResponse } from 'types/api';

export const mainBannerApi = {
  createMainBanner: async (data: FormData): Promise<ApiResponse> => {
    return coreApi
      .post('/connects/mainbanners', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ status }) => ({ status }))
      .catch(error => ({ error }));
  },
  getMainBannerList: async (params: PagingParams): Promise<ApiResponse<GetMainBannerListData>> => {
    return coreApi
      .get('/connects/mainbanners', { params })
      .then(({ data }) => ({
        data,
      }))
      .catch(error => ({ error }));
  },
  getMainBanner: async (id: string): Promise<ApiResponse<MainBannerData>> => {
    return coreApi
      .get(`connects/mainbanners/${id}`)
      .then(({ data }) => ({ data }))
      .catch(error => ({ error }));
  },
  updateMainBanner: async (data: FormData): Promise<ApiResponse> => {
    return coreApi
      .put('/connects/mainbanners', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ status }) => ({ status }))
      .catch(error => ({ error }));
  },
  deleteMainBanner: async (id: string): Promise<ApiResponse> => {
    return coreApi
      .delete(`connects/mainbanners/${id}`)
      .then(({ status }) => ({ status }))
      .catch(error => ({ error }));
  },
  updateMainBannerShow: async ({
    id,
    isShow,
  }: {
    id: string;
    isShow: boolean;
  }): Promise<ApiResponse> => {
    return coreApi
      .put(`connects/mainbanners/${id}/show?isShow=${isShow}`)
      .then(({ status }) => ({ status }))
      .catch(error => ({ error }));
  },
};
