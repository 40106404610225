import * as React from 'react';
import { Button } from '@material-ui/core';
import { Dispatch, SetStateAction } from 'react';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';
import { commentListColumns } from 'components/memberdetail/detail/commenttable/CommentListColumns';
import { PricingPlanTableBlock as CommentTableBlock } from 'components/memberdetail/detail/pricingplan/PricingTable';
import { Comment } from 'types/member';
import { DEFAULT_COMMENT_INFO } from 'containers/memberdetail/MemberCommentContainer';

interface Props {
  commentList: Comment[];
  setCommentModalOpen: Dispatch<SetStateAction<boolean>>;
  setCommentDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  totalPages: number;
  onChangePage: (pageIndex: number) => void;
  currentPageIndex: number;
  setSelectedComment: Dispatch<SetStateAction<Comment>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  commentListLoading: boolean;
  totalItems: number;
}

const CommentList = ({
  commentList,
  setCommentModalOpen,
  setCommentDeleteModalOpen,
  totalPages,
  onChangePage,
  currentPageIndex,
  setSelectedComment,
  setIsEditing,
  commentListLoading,
  totalItems,
}: Props) => {
  const openCommentInputModal = (comment: Comment) => () => {
    setIsEditing(true);
    setSelectedComment(comment);
    setCommentModalOpen(true);
  };

  const openDeleteConfirmModal = (comment: Comment) => () => {
    setSelectedComment(comment);
    setCommentDeleteModalOpen(true);
  };

  const commentTableColumnData = commentListColumns(
    openCommentInputModal,
    openDeleteConfirmModal,
    totalItems,
    currentPageIndex,
  );

  return (
    <CommentTableBlock margin={'20px 0 0 0'}>
      <DataTableLayout
        showPagination
        tableTitle={'Comment'}
        maxHeight={'450px'}
        totalPages={totalPages}
        onChangePage={onChangePage}
        currentPageIndex={currentPageIndex}
        upperButton={
          <Button
            variant={'contained'}
            size={'small'}
            color={'primary'}
            onClick={openCommentInputModal(DEFAULT_COMMENT_INFO)}
          >
            Add Comment
          </Button>
        }
      >
        <DataTableHeader header={commentTableColumnData} />
        <NewDataTableBody<Comment>
          dataList={commentList}
          columns={commentTableColumnData}
          loading={commentListLoading}
        />
      </DataTableLayout>
    </CommentTableBlock>
  );
};

export default CommentList;
