import * as React from 'react';
import { ReactElement } from 'react';
import { HideInMobile, ShowInMobile } from 'modules/utils/style';
import ContestTimelineMobile from 'components/marketplace/operation/contest/preview/ContestTimelineMobile';
import ContestTimelineDesktop from 'components/marketplace/operation/contest/preview/ContestTimelineDesktop';
import { TypoBody, TypoSubHeader } from '@closet-design-system/core';
import { mq, theme } from '@closet-design-system/theme';
import styled from 'styled-components';
import { ContestTimelineProps } from 'types/marketplace/operation/contest';

interface Props {
  timelineList: ContestTimelineProps[];
}

const ContestPreviewTimelineContainer = ({ timelineList }: Props): ReactElement => {
  const isLastDate = (index: number): boolean => index === timelineList.length - 1;

  const renderStartDate = (date: string, isLastDate: boolean): ReactElement => {
    const MONTH_DAY_INDEX = 0;
    const YEAR_INDEX = 1;
    const TIME_INDEX = 2;

    const splitDateByComma: string[] = date.split(',');
    const monthDay = splitDateByComma[MONTH_DAY_INDEX];
    const year = splitDateByComma[YEAR_INDEX];
    const time = splitDateByComma[TIME_INDEX];

    return (
      <>
        <TypoBody variant={[4, 4, 4, 3]} color={'inherit'}>
          {monthDay},&nbsp;{year},&nbsp;
        </TypoBody>
        <TypoBody variant={[4, 4, 4, 3]} color={'inherit'}>
          {time}&nbsp;{!isLastDate && '~'}
        </TypoBody>
      </>
    );
  };

  return (
    <ContestTimelineRoot>
      <ShowInMobile>
        <ContestTimelineMobile />
      </ShowInMobile>
      <HideInMobile>
        <ContestTimelineDesktop />
      </HideInMobile>
      <TimelineListWrap>
        <ListWrap>
          {timelineList.map((item, index) => {
            return (
              <ListItem key={item.statusText}>
                <TypoSubHeader variant={[7, 7, 7, 5]} color={'inherit'} textTransform={'uppercase'}>
                  {item.statusText}
                </TypoSubHeader>
                <DateWrap>{renderStartDate(item.startDate, isLastDate(index))}</DateWrap>
              </ListItem>
            );
          })}
        </ListWrap>
      </TimelineListWrap>
    </ContestTimelineRoot>
  );
};

const ContestTimelineRoot = styled.div(
  ({
    theme: {
      breakpoints: { s, m },
    },
  }) =>
    mq({ s, m })({
      maxWidth: 960,
      width: '100%',
      margin: '0 auto',
      display: ['flex', 'block'],
      alignItems: ['center', 'unset'],
      justifyContent: ['center', 'unset'],
      marginBottom: [20, 32, 40],
    }),
);

const TimelineListWrap = styled.div(
  ({
    theme: {
      breakpoints: { s, m },
    },
  }) =>
    mq({ s, m })({
      maxWidth: ['', 662, 960],
      margin: ['0 0 0 20px', '0 auto'],
    }),
);

const ListWrap = styled.ul(
  ({
    theme: {
      breakpoints: { s, m },
    },
  }) =>
    mq({ s, m })({
      display: 'flex',
      flexDirection: ['column', 'row'],
      justifyContent: ['', 'space-between'],
      marginTop: [0, 16, 20],
    }),
);

const ListItem = styled.li(
  ({
    theme: {
      breakpoints: { s, m },
      colors,
    },
  }) =>
    mq({ s, m })({
      display: 'flex',
      flexDirection: 'column',
      alignItems: ['', 'center'],
      marginTop: [16, 0],
      width: ['', 172, 240],
      color: colors.GRAY_400,
      gap: [0, 8],
      ['&:first-of-type']: {
        marginTop: 0,
      },
      ['&.active']: {
        color: colors.GRAY_50,
      },
    }),
);

const DateWrap = styled.div(
  ({
    theme: {
      breakpoints: { s },
    },
  }) =>
    mq({ s })({
      display: 'flex',
      flexDirection: ['row', 'column'],
      color: `${theme.colors.GRAY_50}`,
    }),
);

export default ContestPreviewTimelineContainer;
