import * as React from 'react';
import LegacyBillingDetailContainer from 'containers/plansales/billing/LegacyBillingDetailContainer';
import { RouteComponentProps } from 'react-router';

const LegacyBillingDetail = (routeInfo: RouteComponentProps<{ invoiceNo: string }>) => {
  const { invoiceNo } = routeInfo.match.params;
  return <LegacyBillingDetailContainer invoiceNo={invoiceNo} />;
};

export default LegacyBillingDetail;
