import * as React from 'react';
import styled from 'styled-components';
import InformationTable from 'components/common/table/InformationTable';
import { InformationObject } from 'types/common/informationTable';
import { GetMemberGeneralInformationResponse, GroupMembers } from 'types/member';

interface Props {
  generalInformation: GetMemberGeneralInformationResponse;
}

const memberRoles = ['collaborator', 'editor', 'viewer'];
const GeneralInformation = ({ generalInformation }: Props) => {
  const { userid, name, email, groupName, groupId, groupMembers, status } = generalInformation;

  const getMemberCount = (role: string, count: number) => {
    return `${count ?? 0} ${role}${count === 1 ? '' : 's'}`;
  };

  const getRoles = (role: string) => {
    return getMemberCount(role, groupMembers[role as keyof GroupMembers]);
  };

  const memberInformation: InformationObject[] = [
    {
      title: 'User ID',
      render: userid,
      titleWidth: '20%',
    },
    { title: 'Name', render: name, titleWidth: '20%' },
    { title: 'Email', render: email, titleWidth: '20%' },
    { title: 'Status', render: status, titleWidth: '20%' },
    // {
    //   title: 'Password',
    //   render: (
    //     <>
    //       {/* 210817 비활성화처리 -> sign in/sign up renewal 이후 사용량 확인하여 삭제 or 활성화 여부 결정  */}
    //       <InputBlock>
    //         <TextField type="password" fullWidth size={'small'} value={groupId} disabled />
    //       </InputBlock>
    //       <Button
    //         variant="contained"
    //         size="small"
    //         color={'primary'}
    //         disabled
    //         startIcon={<Sync />}
    //         onClick={() => {}}
    //       >
    //         Change Password
    //       </Button>
    //     </>
    //   ),
    //   titleWidth: '20%',
    // },
    { title: 'Group Name', render: groupName, titleWidth: '20%' },
    { title: 'Group ID', render: groupId, titleWidth: '20%' },
    { title: 'Group Member', render: memberRoles.map(getRoles).join(', '), titleWidth: '20%' },
  ];

  return <InformationTable title={'[CLO-SET] General Information'} data={memberInformation} />;
};

export default GeneralInformation;
//
// const InputBlock = styled.div`
//   display: inline-block;
//   width: 10%;
//   margin-right: ${props => props.theme.spacing(2)}px;
// `;
