import * as React from 'react';
import styled from 'styled-components';
import { TableCell } from 'types/common/table';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';
import { ApiTestData } from '../../../types/member';

interface Props {
  apiTestData: ApiTestData[][];
}

const ApiTest = ({ apiTestData }: Props) => {
  const arr = Array(10).fill(0);

  const apiTestColumns: any[] = arr.map((item, index) => {
    return {
      align: 'center',
      name: index,
      key: index,
      render: (rowData: any) => <div>{`${rowData?.[index]?.time}s`}</div>,
    };
  });

  return (
    <TableBlock margin={'20px 0 0 0'}>
      <DataTableLayout tableTitle={'Api Test'}>
        <DataTableHeader header={apiTestColumns} />
        <NewDataTableBody dataList={apiTestData} columns={apiTestColumns} />
      </DataTableLayout>
    </TableBlock>
  );
};

export default ApiTest;

export const TableBlock = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  margin: ${({ margin = '0' }) => margin};
`;
