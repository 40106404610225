import { createStyles, TableCell, TableRow, Theme, withStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';

export const StyledHeaderCell = withStyles((theme: Theme) => {
  return createStyles({
    root: {
      fontSize: '14px',
    },
    head: {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
      fontWeight: 600,
      borderRight: `1px ${theme.palette.grey[300]} solid`,
      verticalAlign: 'middle',
    },
  });
})(TableCell);

export const StyledBodyTitleCell = withStyles((theme: Theme) => {
  return createStyles({
    body: {
      fontWeight: 600,
      backgroundColor: theme.palette.grey[200],
    },
  });
})(TableCell);

export const NoBorderCell = withStyles(() => {
  return createStyles({
    body: {
      border: 0,
    },
  });
})(TableCell);

// row style formatter (component 만들어서 사용)
export const makeRowStyleFormat = <T extends {}>(
  rowData: T,
  styleFunction?: (rowData: T) => CSSProperties,
) =>
  withStyles(() => ({
    root: styleFunction?.(rowData) || {},
  }))(TableRow);

export const TextStyle = styled.div<{ textAlign?: string; fontWeight?: string; fontSize?: string }>`
  text-align: ${({ textAlign = 'center' }) => textAlign};
  font-weight: ${({ fontWeight = 'normal' }) => fontWeight};
  font-size: ${({ fontSize = 'inherit' }) => fontSize};
`;
