import async from 'components/template/Async';
import { RouteInfoType } from 'types/common/routes';

// export const RevenueShare = async(() => import('pages/marketplace/revenue/RevenueShare'));
export const OrderList = async(() => import('pages/marketplace/Order'));
export const OrderDetail = async(() => import('pages/marketplace/order/OrderDetail'));

export const ORDER_LIST_PATH = '/marketplace/order';

export const OrderRoutes: RouteInfoType[] = [
  {
    name: 'Order Detail',
    path: `${ORDER_LIST_PATH}/detail/:orderNo`,
    to: ORDER_LIST_PATH,
    component: OrderDetail,
  },
];
