import { toMantissaWithDollarSign } from 'modules/utils/number';
import { DataObject, TableCell as TableColumn } from 'types/common/table';

export const dataTablePriceRenderer = (row: DataObject, column: TableColumn) =>
  toMantissaWithDollarSign(row[column.key as string]);

export const getTableRowNumber = (
  totalItems: number,
  defaultPageSize: number,
  currentPageIndex: number,
  rowIndex: number,
): number => totalItems - defaultPageSize * (currentPageIndex - 1) - rowIndex;
