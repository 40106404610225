import * as React from 'react';
import { TableRow, TableCell, Table, Paper, TableBody, TableContainer } from '@material-ui/core';
import { toMantissaWithDollarSign } from 'modules/utils/number';
import styled from 'styled-components';
import { TextStyle } from 'components/common/table/TableStyle';
import { ColorText } from 'components/plansales/billing/detail/invoice/InvoiceTableRenderer';

interface Props {
  subTotalAmount: number;
  totalAmount: number;
  taxAmount: number;
  isRefunded: boolean;
}

const InvoiceTableBottomSummary = ({
  subTotalAmount,
  totalAmount,
  taxAmount,
  isRefunded,
}: Props) => {
  // refund tax 가 0 일때는 그냥 안보여줌.
  const showTaxRefund = isRefunded && taxAmount !== 0;
  return (
    <TableWrapper>
      <TableContainer component={Paper}>
        <Table size={'small'}>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextStyle textAlign={'right'} fontWeight={'600'}>
                  Sub Total
                </TextStyle>
              </TableCell>
              <TableCell width={'10%'} align="right">
                {toMantissaWithDollarSign(subTotalAmount, 2)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextStyle textAlign={'right'} fontWeight={'600'}>
                  Tax
                </TextStyle>
              </TableCell>
              <TableCell align="right">
                <>{toMantissaWithDollarSign(taxAmount, 2)}</>
                {showTaxRefund && (
                  <ColorText color={'red'}>
                    <br />
                    {toMantissaWithDollarSign(-1 * taxAmount, 2)}
                  </ColorText>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextStyle textAlign={'right'} fontWeight={'600'}>
                  Total
                </TextStyle>
              </TableCell>
              <TableCell align="right">{toMantissaWithDollarSign(totalAmount, 2)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default InvoiceTableBottomSummary;

const TableWrapper = styled.div<{ maxHeight?: string }>`
  display: flex;
  width: 100%;
  max-height: ${({ maxHeight = 'initial' }) => maxHeight};
`;
