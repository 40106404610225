import { TypoBody } from '@closet-design-system/core';
import * as React from 'react';
import { ReactElement } from 'react';
import { MainBannerTypoVariant } from '.';
import LinkLayout from '../LinkLayout';

interface Props {
  url: string;
  content: string;
  typoBodyVariant: MainBannerTypoVariant[];
}

const ArtworkSourceLink = ({ url, content, typoBodyVariant }: Props): ReactElement => {
  return (
    <LinkLayout url={url} openNewTab>
      <TypoBody variant={typoBodyVariant}>{content}</TypoBody>
    </LinkLayout>
  );
};

export default ArtworkSourceLink;
