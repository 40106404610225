import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  ChildElementType,
  RouteInfoType,
  SidebarPropsType,
  RouteOptions,
} from 'types/common/routes';
import 'vendor/perfect-scrollbar.css';
import { spacing } from '@material-ui/system';
import closetLogo from 'public/static/img/logo/closet-aqua-logo.svg';

import {
  Avatar,
  Badge,
  Box as MuiBox,
  Collapse,
  Drawer as MuiDrawer,
  Grid,
  List as MuiList,
  ListItem,
  Typography,
} from '@material-ui/core';

import SidebarCategory from 'components/common/sidebar/SidebarCategory';
import NavLink from 'components/common/sidebar/NavLink';
import SidebarLink from 'components/common/sidebar/SidebarLink';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, selectLoginUserInfo } from 'features/auth/authSlice';
import { adminSidebarRoutes } from 'routes/ClosetAdminRoutes';
import PerfectScrollbar from 'react-perfect-scrollbar';

const ClosetSidebarContainer: React.FC<RouteComponentProps & SidebarPropsType> = ({
  classes,
  staticContext,
  location,
  ...rest
}) => {
  const dispatch = useDispatch();
  const selectLoginUser = useSelector(selectLoginUserInfo);

  useEffect(() => {
    dispatch(authActions.getLoginUser({ userId: 'a' }));
  }, []);

  const initOpenRoutes = (): RouteOptions => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};
    adminSidebarRoutes.forEach((route: RouteInfoType, index: number) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === '/';

      const subMenuOpenStatus = route?.children?.reduce((result, children, index) => {
        const isActive =
          children.path !== undefined ? pathName.indexOf(children.path) === 0 : false;
        return {
          ...result,
          [index]: isActive,
        };
      }, {});

      _routes = Object.assign({}, _routes, {
        [index]: { isOpen: isActive || isOpen || isHome, subMenuOpenStatus },
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const initSubMenuOpenStatus = (subMenuOpenStatus: object) => {
    return Object.keys(subMenuOpenStatus).reduce((result, cur, subMenuIndex) => {
      return {
        ...result,
        [subMenuIndex]: false,
      };
    }, {});
  };

  const toggle = (index: number, subMenuIndex?: number) => {
    Object.keys(openRoutes).forEach(
      item =>
        openRoutes[index].isOpen ||
        setOpenRoutes(openRoutes => {
          return Object.assign({}, openRoutes, {
            [item]: {
              isOpen: openRoutes[index].isOpen || false,
              subMenuOpenStatus: initSubMenuOpenStatus(openRoutes[index].subMenuOpenStatus),
            },
          });
        }),
    );

    setOpenRoutes(openRoutes =>
      Object.assign({}, openRoutes, {
        [index]: {
          ...openRoutes[index],
          isOpen: subMenuIndex !== undefined ? openRoutes[index].isOpen : !openRoutes[index].isOpen,
          subMenuOpenStatus:
            subMenuIndex !== undefined
              ? {
                  ...initSubMenuOpenStatus(openRoutes[index].subMenuOpenStatus),
                  [subMenuIndex]: !openRoutes[index].subMenuOpenStatus[subMenuIndex],
                }
              : { ...openRoutes[index].subMenuOpenStatus },
        },
      }),
    );
  };

  return (
    <Drawer variant="permanent" {...rest}>
      <SidebarTitle button>
        <ClosetLogo /> <Box ml={3}>CLO-SET Admin</Box>
      </SidebarTitle>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {/* sidebar menu depth 의 경우 legacy 를 기준으로 최대 3 depth 까지만 해두었음 */}
            {adminSidebarRoutes.map((menu: RouteInfoType, index: number) => (
              <React.Fragment key={index}>
                {menu.header ? <SidebarSection>{menu.header}</SidebarSection> : null}
                {menu.children && menu.icon ? (
                  <React.Fragment key={index}>
                    {/* menu (1 depth) */}
                    <SidebarCategory
                      isOpen={!openRoutes[index].isOpen}
                      isCollapsable={true}
                      name={menu.name || ''}
                      icon={menu.icon}
                      button={true}
                      onClick={() => toggle(index)}
                    />
                    <Collapse in={openRoutes[index].isOpen} timeout="auto" unmountOnExit>
                      <List disablePadding>
                        <SubItems>
                          {menu.children.map((subMenu: ChildElementType, subMenuIndex: number) => (
                            // sub menu (2 depth)
                            <React.Fragment key={subMenuIndex}>
                              {/* sub menu (3 depth) */}
                              {subMenu.children ? (
                                <>
                                  <SidebarCategory
                                    isOpen={!openRoutes[index].subMenuOpenStatus[subMenuIndex]}
                                    isCollapsable={true}
                                    name={subMenu.name || ''}
                                    button={true}
                                    onClick={() => toggle(index, subMenuIndex)}
                                    isSubMenu
                                  />
                                  <Collapse
                                    in={openRoutes[index].subMenuOpenStatus[subMenuIndex]}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {subMenu?.children.map((childMenu: ChildElementType) => (
                                      <SidebarLink
                                        key={childMenu.path}
                                        name={childMenu.name}
                                        to={childMenu.to ?? childMenu.path}
                                        badge={childMenu.badge}
                                        isExternal={childMenu.isExternal}
                                        isSubMenuLink
                                      />
                                    ))}
                                  </Collapse>
                                </>
                              ) : (
                                <SidebarLink
                                  key={subMenu.path}
                                  name={subMenu.name}
                                  to={subMenu.to ?? subMenu.path}
                                  badge={subMenu.badge}
                                  isExternal={subMenu.isExternal}
                                />
                              )}
                            </React.Fragment>
                          ))}
                        </SubItems>
                      </List>
                    </Collapse>
                  </React.Fragment>
                ) : (
                  <SidebarCategory
                    isCollapsable={false}
                    name={menu.name || ''}
                    to={menu.path}
                    activeClassName="active"
                    component={NavLink}
                    icon={menu.icon}
                    exact
                    button
                    badge={menu.badge}
                    isExternal={menu.isExternal}
                  />
                )}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
      <SidebarFooter>
        <Grid container spacing={2}>
          <Grid item>
            <StyledBadge
              overlap="circle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant="dot"
            >
              <Avatar alt="firstLetter">
                {selectLoginUser.name.length > 0 && selectLoginUser.name[0].toUpperCase()}
              </Avatar>
            </StyledBadge>
          </Grid>
          <Grid item>
            <SidebarFooterText variant="body2">{selectLoginUser.name}</SidebarFooterText>
          </Grid>
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
};

const Box = styled(MuiBox)(spacing);

const ClosetLogo = styled.div`
  background: url(${closetLogo}) no-repeat;
  background-size: 100%;
  width: 30px;
  height: 30px;
`;

const Drawer = styled(MuiDrawer)`
  border-right: 0;
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

export const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
  height: 100%;
`;

const Items = styled.div`
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;

const SubItems = styled.div`
  padding-top: 0;
  padding-bottom: 0;
`;

const SidebarTitle = styled(ListItem)`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(6)}px;
  cursor: default;

  ${props => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${props => props.theme.sidebar.header.background};
  }
`;

const SidebarSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px ${props => props.theme.spacing(6)}px
    ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${props => props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(2.75)}px ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
`;

const SidebarFooterText = styled(Typography)`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${props => props.theme.sidebar.footer.color};
`;

const StyledBadge = styled(Badge)`
  margin-right: ${props => props.theme.spacing(1)}px;

  span {
    background-color: ${props => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${props => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

export default withRouter(ClosetSidebarContainer);
