import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TabValue } from 'types/common/tabs';
import { TAB_PADDING, TabBarContext } from './TabBar';

interface Props {
  label: string;
  to?: string;
  value: TabValue;
}

const Tab = ({ label, value, to }: Props) => {
  const { onChangeHandler, selectedValue, setIndicatorProperties } = useContext(TabBarContext);
  const tabRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const isSelected = value === selectedValue;

  const updateIndicator = () => {
    if (!tabRef.current) return;

    const tab = tabRef.current;
    setIndicatorProperties({
      width: tab.offsetWidth - TAB_PADDING * 2,
      left: tab.offsetLeft + TAB_PADDING,
    });
  };

  const onChange = () => {
    onChangeHandler?.(value);
    updateIndicator();
    if (to) history.replace(to);
  };

  useEffect(() => {
    if (!isSelected) return;
    updateIndicator();
  }, [selectedValue]);

  return (
    <TabWrapper
      ref={tabRef}
      className={classNames({
        selected: isSelected,
      })}
      onClick={onChange}
    >
      <Typography variant="h5">{label}</Typography>
    </TabWrapper>
  );
};

const TabWrapper = styled.div`
  text-align: center;
  padding: ${TAB_PADDING}px;
  cursor: pointer;
`;

export default Tab;
