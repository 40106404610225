import { getMediaQueryRangeValues, replaceToBreak } from 'modules/mainBannerPreview';
import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import styled from 'styled-components';
import { LayoutType, TextType } from 'types/marketplace/operation/mainBanner/mainBannerPreview';
import ArtworkSourceLink from './ArtworkSourceLink';
import BaseLayout from './BaseLayout';
import Subtitle from './Subtitle';
import Title from './Title';

interface Props {
  textType: TextType;
  title: string;
  subtitle: string;
  artworkSource: string;
  sourceLink: string;
  layoutType: LayoutType;
  buttonText: string;
  buttonUrl: string;
  darkMode: boolean;
  openNewTab: boolean;
  hasLinkButton: boolean;
}

export type MainBannerTypoVariant = 1 | 2 | 3 | 4;

const TextLayout = ({
  textType,
  title,
  subtitle,
  artworkSource,
  sourceLink,
  darkMode,
  buttonText,
  buttonUrl,
  layoutType,
  openNewTab,
  hasLinkButton,
}: Props): ReactElement => {
  const isTextType1 = textType === TextType.TYPE1;
  const isTextType4 = textType === TextType.TYPE4;
  const artWorkSourceAndLinkValid = artworkSource && sourceLink;

  const getTitleVariant = useCallback((): MainBannerTypoVariant[] => {
    switch (textType) {
      case TextType.TYPE1:
      case TextType.TYPE2:
        return getMediaQueryRangeValues<MainBannerTypoVariant>({ s: 3, m: 2, l: 1 });
      case TextType.TYPE3:
      default:
        return getMediaQueryRangeValues<MainBannerTypoVariant>({ s: 4, m: 3, l: 2 });
    }
  }, [textType]);

  if (isTextType1 || isTextType4) {
    return (
      <BaseLayout
        layoutType={layoutType}
        darkMode={darkMode}
        buttonText={buttonText}
        buttonUrl={buttonUrl}
        openNewTab={openNewTab}
        hasLinkButton={hasLinkButton}
      >
        <Subtitle
          content={replaceToBreak(subtitle)}
          typoSubtitleVariant={getMediaQueryRangeValues<MainBannerTypoVariant>({
            s: 4,
            m: 2,
            l: 1,
          })}
        />
        <MarginTopWrapper>
          <Title content={replaceToBreak(title)} typoSubHeaderVariant={getTitleVariant()} />
        </MarginTopWrapper>
        {artWorkSourceAndLinkValid && (
          <ArtworkSourceWrapper>
            <ArtworkSourceLink
              url={sourceLink}
              content={artworkSource}
              typoBodyVariant={getMediaQueryRangeValues({ s: 3, m: 3, l: 3 })}
            />
          </ArtworkSourceWrapper>
        )}
      </BaseLayout>
    );
  }

  return (
    <BaseLayout
      layoutType={layoutType}
      darkMode={darkMode}
      buttonText={buttonText}
      buttonUrl={buttonUrl}
      openNewTab={openNewTab}
      hasLinkButton={hasLinkButton}
    >
      <Title content={replaceToBreak(title)} typoSubHeaderVariant={getTitleVariant()} />
      <MarginTopWrapper>
        <Subtitle
          content={replaceToBreak(subtitle)}
          typoSubtitleVariant={getMediaQueryRangeValues({ s: 4, m: 2, l: 1 })}
        />
      </MarginTopWrapper>
    </BaseLayout>
  );
};

export default TextLayout;

const MarginTopWrapper = styled.div`
  margin-top: 8px;
`;

const ArtworkSourceWrapper = styled.div`
  margin-top: 50px;
`;
