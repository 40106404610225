import * as React from 'react';
import { ReactNode } from 'react';
import BasicModal from './BasicModal';
import Button from '../Button';
import { Typography } from '@material-ui/core';

interface Props {
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  content?: ReactNode;
}
const CancelModal = ({ open, onClose, content, onCancel }: Props) => {
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      contentDividers={false}
      actionButton={
        <>
          <Button variant={'text'} color={'default'} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={'text'} color={'primary'} onClick={onCancel}>
            OK
          </Button>
        </>
      }
    >
      {typeof content === 'string' ? <Typography variant={'h6'}>{content}</Typography> : content}
    </BasicModal>
  );
};

export default CancelModal;
