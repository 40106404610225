import { AxiosError } from 'axios';

export type BasicListInfo = {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  links?: any;
};

export type GetDataListResponse<T> = BasicListInfo & {
  items: T[];
};

export type GetPageParams = {
  page?: number;
  pageSize?: number;
};

// https://gist.github.com/scokmen/f813c904ef79022e84ab2409574d1b45
export enum HttpStatusCode {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}

export interface ApiResponse<T = undefined> {
  status?: number;
  data?: T;
  error?: ApiError;
}

export type ApiError = AxiosError<ErrorResult>;

export interface ErrorResult {
  resultMessage: string;
  resultCode: number;
}
