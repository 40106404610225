import dayjs, { Dayjs, ConfigType, OpUnitType } from 'dayjs';
import 'dayjs/locale/ko';
import 'dayjs/locale/zh-cn';

import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateInterval, ValidationParams } from 'types/plansales/billing';
import { DatetimeType } from 'types/common/time';

const thresholds = [
  { l: 's', r: 1 },
  { l: 'ss', r: 59, d: 'second' },
  { l: 'm', r: 1 },
  { l: 'mm', r: 60, d: 'minute' },
  { l: 'h', r: 1 },
  { l: 'hh', r: 24, d: 'hour' },
  { l: 'd', r: 1 },
  { l: 'dd', r: 27, d: 'day' },
  { l: 'M', r: 28 },
  { l: 'MM', r: 365, d: 'month' },
  { l: 'y', r: 12 },
  { l: 'yy', r: 12, d: 'year' },
];

const config = {
  rounding: Math.floor,
  thresholds,
};

dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime, config);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'just now',
    ss: 'just now',
    m: '%d min ago',
    mm: '%d min ago',
    h: 'an hour ago',
    hh: '%d hours ago',
    d: 'a day ago', // 1d
    dd: '%d days ago', // 2d~
    M: 'a month ago',
    MM: '%d months ago',
    y: 'a year ago',
    yy: '%d years ago',
  },
});

// 2011-10-10
export const getUtcDateDash = (s: ConfigType | null): string => {
  const date = dayjs.utc(s ?? new Date());
  return date.isValid() ? date.format('YYYY-MM-DD') : '0000-00-00';
};

// 03/15/2021
export const getUtcUsFormatDate = (s: ConfigType): string => {
  const date = dayjs.utc(s);
  return date.isValid() ? date.format('MM/DD/YYYY') : '00/00/0000';
};

// 20211025
export const getUtcDateNoDash = (s: ConfigType): string => {
  const date = dayjs.utc(s);
  return date.isValid() ? date.format('YYYYMMDD') : '00000000';
};

// 2023-07-01T12:34:56
export const getUtcDateTimeISO = (s: ConfigType): string => {
  const date = dayjs.utc(s);
  return date.isValid() ? date.format('YYYY-MM-DDTHH:mm:ss') : '0000-00-00T00:00:00';
};

// Date(local) 2023-07-01T12:34:56로 format
export const getLocalDateTimeISO = (s: ConfigType): string => {
  const date = dayjs.utc(s).local();
  return date.isValid() ? date.format('YYYY-MM-DDTHH:mm:ss') : '0000-00-00T00:00:00';
};

// unit 단위 이전/이후 time difference 계산
export const getUtcDateDashDifference = (
  from: ConfigType, // 기준날짜
  gap: number, // 기준날짜로부터의 difference 양
  unit: OpUnitType, // difference 단위
  method?: 'add' | 'subtract', // 차이만큼 더할지 뺄지 전달
): string => {
  const date = dayjs.utc(from)[method || 'subtract'](gap, unit);
  return date.isValid() ? date.format('YYYY-MM-DD') : '0000-00-00';
};

// unit 단위 첫번째 day (target 이 있을 경우 해당 target 의 첫번째 날짜)
export const getUtcFirstDayDash = (unit: 'year' | 'month', target?: number): string => {
  let dateFormat = String(new Date());
  if (target)
    dateFormat =
      unit === 'year' ? String(target) : `${dayjs().year()}-${String(target).padStart(2, '0')}`;
  const date = dayjs.utc(dateFormat);
  return date.isValid() ? date.startOf(unit).format('YYYY-MM-DD') : '0000-00-00';
};

// unit 단위 마지막 day (target 이 있을 경우 해당 target 의 마지막 날짜)
export const getUtcLastDayDash = (unit: 'year' | 'month', target?: number): string => {
  let dateFormat = String(new Date());
  if (target)
    dateFormat =
      unit === 'year' ? String(target) : `${dayjs().year()}-${String(target).padStart(2, '0')}`;
  const date = dayjs.utc(dateFormat);
  return date.isValid() ? date.endOf(unit).format('YYYY-MM-DD') : '0000-00-00';
};

// from ~ to time difference 계산
export const getDateDifference = (
  from: ConfigType | null,
  to: ConfigType | null,
  unit?: 'day' | 'millisecond' | 'month' | 'year',
): number => {
  // diff 함수의 unit default 값이 millisecond 이다
  // https://day.js.org/docs/en/display/difference
  return dayjs(to ?? new Date()).diff(dayjs(from ?? new Date()), unit || 'millisecond');
};

// Jan 10, 2020
export const getUtcDateShortMonth = (s: ConfigType): string => {
  const date = dayjs.utc(s);
  return date.format('ll');
};

// Jan 10, 2020 00:45:08 (AM)
export const getUtcDateShortMonthWithTime = (s: ConfigType): string => {
  const date = dayjs.utc(s);
  // ll YYYY hh:mm:ss A 기존 YYYY가 있어 년도가 중복되어 삭제 by Bran
  return date.format('ll hh:mm:ss A');
};

// January 10, 2020
export const getUtcDateFullMonth = (s: ConfigType): string => {
  const date = dayjs.utc(s);
  return date.format('LL');
};

// 2021-06-08 00:45:08 (AM)
export const getUtcDateWithTime = (s: ConfigType, showAmPm?: boolean): string => {
  const date = dayjs.utc(s);
  const format = `YYYY-MM-DD ${showAmPm ? 'hh' : 'HH'}:mm:ss` + (showAmPm ? ' A' : '');
  return date.isValid() ? date.format(format) : '0000-00-00 00-00-00';
};

// 08/06/2021 00:45:08 (AM)
export const getUtcUSFormatDateWithTime = (s: ConfigType, showAmPm?: boolean): string => {
  const date = dayjs.utc(s);
  const format = `MM/DD/YYYY ${showAmPm ? 'hh' : 'HH'}:mm:ss` + (showAmPm ? ' A' : '');
  return date.isValid() ? date.format(format) : '0000-00-00 00-00-00';
};

// 08/06/2021 00:45:08 (AM)
export const getKstUSFormatDateWithTime = (s: ConfigType, showAmPm?: boolean): string => {
  const date = dayjs.utc(s).tz('Asia/Seoul');
  const format = `MM/DD/YYYY ${showAmPm ? 'hh' : 'HH'}:mm:ss` + (showAmPm ? ' A' : '');
  return date.isValid() ? date.format(format) : '0000-00-00 00-00-00';
};

// 2021-06-08 12:45:08 (AM) (local time)
export const getUtcLocalDateWithTime = (s: ConfigType, showAmPm?: boolean): string => {
  const date = dayjs.utc(s).local();
  const format = `YYYY-MM-DD ${showAmPm ? 'hh' : 'HH'}:mm:ss` + (showAmPm ? ' A' : '');
  return date.isValid() ? date.format(format) : '0000-00-00 00-00-00';
};

// 정확한 date 형태인가
export const isValidDateFormat = (s: ConfigType | null): boolean =>
  s === null ? false : dayjs(s ?? '0000-00-00').isValid();

// date range 에서 toDate 와 fromDate 의 validation (toDate - fromDate >= 0)
export const isValidDate = ({ type, newDateValue, from, to }: ValidationParams): boolean => {
  const difference = {
    from: type === DateInterval.FROM ? getUtcDateDash(newDateValue) : from,
    to: type === DateInterval.FROM ? to : getUtcDateDash(newDateValue),
  };
  return getDateDifference(difference.from, difference.to) < 0;
};

// 06/28 7:20 AM
export const getShortDateByTimezone = (inputDate: Date, timezone: string): string => {
  if (timezone === 'utc') {
    return dayjs(inputDate).utc().format('MM/DD hh:mm A');
  }
  return dayjs(inputDate).tz(timezone).format('MM/DD hh:mm A');
};

// [ 2011-10-10, 2011-10-10 ]
export const getDefaultDashFormatInterval = ({
  date = new Date(),
  gap,
  unit,
  options,
}: {
  date?: Date;
  gap: number; // 기준날짜로부터의 difference 양
  unit: 'year' | 'month'; // difference 단위
  options?: {
    useFirstDay?: boolean; // 시작일로 현재 unit 단위의 첫 날을 사용 [ 2011-10-01, 2011,10-10 ]
  };
}): [string, string] => {
  if (options?.useFirstDay)
    return [getUtcFirstDayDash(unit, date.getUTCMonth() + 1), getUtcDateDash(date)];

  return [getUtcDateDashDifference(date, gap, unit), getUtcDateDash(date)];
};

/**
 * 날짜기간 체크 함수., day기준
 * @param day: target 날짜
 * @param startDay: 기간 시작일
 * @param endDay: 기간 종료일
 */
export const getIsBetweenDays = ({
  day,
  startDay,
  endDay,
}: {
  day: string;
  startDay: string;
  endDay: string;
}): boolean => {
  return dayjs(day).isBetween(startDay, endDay, 'day', '[]');
};

/**
 * TimeZone설정 가능한 US Format의 Date 출력 'MM/DD/YYYY'., default: UTC
 * @param s
 * @param options {
 *  timeZone: string; // ex) Seoul: 'Asia/Seoul'
 * }
 */
export const getUSFormatDate = (s: ConfigType, options?: { timeZone: string }): string => {
  let date = dayjs.utc(s);
  if (options?.timeZone) {
    date = date.tz(options.timeZone);
  }
  return date.isValid() ? date.format('MM/DD/YYYY') : '00/00/0000';
};

/**
 * TimeZone설정 가능한 US Format의 DateTime 출력 'MM/DD/YYYY hh:mm:ss'., default: UTC
 * @param s
 * @param options {
 *  showAmPm: boolean;
 *  timeZone: string; // ex) Seoul: 'Asia/Seoul'
 * }
 */
export const getUSFormatDateWithTime = (
  s: ConfigType,
  options?: {
    showAmPm?: boolean;
    timeZone?: string;
  },
): string => {
  let date = dayjs.utc(s);
  if (options?.timeZone) {
    date = date.tz(options.timeZone);
  }
  const format =
    `MM/DD/YYYY ${options?.showAmPm ? 'hh' : 'HH'}:mm:ss` + (options?.showAmPm ? ' A' : '');
  return date.isValid() ? date.format(format) : '00/00/0000 00:00:00';
};

/**
 * TimeZone설정 가능한 Dash 포맷의 Date 출력 'YYYY-MM-DD'., default: UTC
 * @param s
 * @param options {
 *  timeZone: string; // ex) Seoul: 'Asia/Seoul'
 * }
 */
export const getDateDash = (s: ConfigType | null, options?: { timeZone: string }): string => {
  let date = dayjs.utc(s ?? new Date());
  if (options?.timeZone) {
    date = date.tz(options.timeZone);
  }
  return date.isValid() ? date.format('YYYY-MM-DD') : '0000-00-00';
};

// to date 가 from date 보다 이후 날짜인지 체크
export const isToDateAfterFromDate = (fromDate: ConfigType, toDate: ConfigType): boolean => {
  return dayjs(toDate).isAfter(fromDate);
};

// 2019-01-02T06:32:32.837 => Jan 2, 2019 3:32 PM  // Local Date
export const getUtcLocalDate = (s: string | number | Dayjs | Date): string => {
  const date = dayjs.utc(s);
  return date.local().format('lll');
};

//2022-07-27T14:00:15 (utc) -> Wed Jul 27 2022 23:00:15 GMT+0900 (한국 표준시) (local)// Local Date type
export const getUtcLocalDateType = (s: string | number | Dayjs | Date): Date => {
  const date = dayjs.utc(s);
  return date.local().toDate();
};

// 2023/07/01/12:34
export const getUtcDateTimeSlash = (s: ConfigType): string => {
  const date = dayjs.utc(s);
  return date.isValid() ? date.format('YYYY/MM/DD/HH:mm') : '0000/00/00/00:00';
};

/* ('2023-07-01', '2023-07-10') -> '2023-07-10' */
export const getMaxDatetime = (valueA: DatetimeType, valueB: DatetimeType): Date => {
  const datetimeA = dayjs(valueA);
  const datetimeB = dayjs(valueB);
  return datetimeA.isAfter(datetimeB) ? datetimeA.toDate() : datetimeB.toDate();
};

/* ('2023-07-01', '2023-07-10') -> '2023-07-01' */
export const getMinDatetime = (valueA: DatetimeType, valueB: DatetimeType): Date => {
  const datetimeA = dayjs(valueA);
  const datetimeB = dayjs(valueB);
  return datetimeA.isBefore(datetimeB) ? datetimeA.toDate() : datetimeB.toDate();
};

// 2019-01-02T06:32:32.837 => January 2, 2019, 3:32 PM UTC
export const getUtcSuffixDate = (s: ConfigType): string => {
  const date = dayjs.utc(s);
  return `${date.format('MMMM DD, YYYY, hh:mmA')} UTC`;
};
// 2019-01-22T06:32:32.837 => UTC : 01/22/2019 12:30:05 AM
export const getUtcPrefixDate = (s: ConfigType): string => {
  const date = dayjs(s);
  return `${date.format('UTC : MM/DD/YYYY hh:mm:ss')}`;
};
