import * as React from 'react';
import { ReactElement } from 'react';
import styled from 'styled-components';
import contestContentStyle from 'styles/ContestContentStyle';
import { Nullable } from 'types/common';
import { cleanMarkup } from 'modules/utils/html';

interface Props {
  content: Nullable<string>;
}

const EditorPreview = ({ content }: Props): ReactElement => {
  const cleanContent = cleanMarkup({
    content: content ?? '',
    allowTags: ['iframe'],
    allowAttrs: ['target'],
  });
  return (
    <EditorStyleWrapper>
      <div
        className="contest-content"
        dangerouslySetInnerHTML={{
          __html: cleanContent,
        }}
      />
    </EditorStyleWrapper>
  );
};

const EditorStyleWrapper = styled.div`
  ${contestContentStyle}
`;

export default EditorPreview;
