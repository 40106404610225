export type MuiWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export enum LicenseType {
  BASIC = 0,
  EXTENDED = 1,
  FREE = 2,
}

export const licenseTypeText: { [key in LicenseType]: string } = {
  [LicenseType.FREE]: 'Free',
  [LicenseType.BASIC]: 'Basic',
  [LicenseType.EXTENDED]: 'Extended',
};

export enum StoreType {
  CONNECT = 0,
  MD = 1,
}

export const storeTypeText: { [key in StoreType]: string } = {
  [StoreType.CONNECT]: 'Connect',
  [StoreType.MD]: 'MD Store',
};

export type ValueOf<T> = T[keyof T];

export type CheckObjectValue = {
  [key: string]: boolean;
};

export type Nullable<T> = T | null;
