import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import { History } from 'history';
import {
  checkMidBannerFormDataValidation,
  getInitialMidBannerFromData,
  getInitialMidBannerFromDataValidation,
  validateFormData,
} from 'modules/midBanner';
import {
  MidBannerFormData,
  MidBannerFormDataValidation,
  MidBannerLinkType,
} from 'types/marketplace';

/**
 * Mid Banner Form page 의 store
 */

export interface MidBannerFormState {
  id?: string;
  isLoading: boolean;
  formData: MidBannerFormData;
  validation: MidBannerFormDataValidation;
  helperText: string;
}
export const initialState: MidBannerFormState = {
  isLoading: false,
  formData: getInitialMidBannerFromData(),
  validation: getInitialMidBannerFromDataValidation(),
  helperText: 'This field is required.',
};

export const midBannerFormSlice = createSlice({
  name: 'midBannerForm',
  initialState,
  reducers: {
    setTitle: (state, { payload }: PayloadAction<string>) => {
      state.formData.title = payload;
    },
    setDesktopImage: (state, { payload }: PayloadAction<File | null>) => {
      state.formData.desktopImage = payload;
    },
    setTabletImage: (state, { payload }: PayloadAction<File | null>) => {
      state.formData.tabletImage = payload;
    },
    toggleIsNoLink: state => {
      state.formData.isNoLink = !state.formData.isNoLink;
    },
    setUrl: (state, { payload }: PayloadAction<string>) => {
      state.formData.url = payload;
    },
    setLinkType: (state, { payload }: PayloadAction<MidBannerLinkType>) => {
      state.formData.linkType = payload;
    },
    toggleIsShowImmediately: state => {
      state.formData.isShowImmediately = !state.formData.isShowImmediately;
    },
    setFrom: (state, { payload }: PayloadAction<Date>) => {
      state.formData.from = payload;
    },
    setTo: (state, { payload }: PayloadAction<Date>) => {
      state.formData.to = payload;
    },
    setInitialFormData: state => {
      state.id = undefined;
      state.formData = getInitialMidBannerFromData();
      state.validation = getInitialMidBannerFromDataValidation();
      state.isLoading = false;
    },
    setFormData: (
      state,
      { payload: { id, ...data } }: PayloadAction<MidBannerFormData & { id: string }>,
    ) => {
      state.id = id;
      state.formData = data;
    },
    loadFormData: (_, _action: PayloadAction<string>) => {},
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    createBanner: (_, _action: PayloadAction<History>) => {},
    editBanner: (_, _action: PayloadAction<History>) => {},
    validateFromData: state => {
      state.validation = validateFormData(state.formData);
    },
  },
});

export const midBannerFormActions = midBannerFormSlice.actions;
export const midBannerFormReducer = midBannerFormSlice.reducer;

export const selectMidBannerFormIsLoading = (root: RootState): boolean =>
  root.marketplace.midBannerForm.isLoading;
export const selectMidBannerFromData = (root: RootState): MidBannerFormData =>
  root.marketplace.midBannerForm.formData;
export const selectMidBannerFormId = (root: RootState): string | undefined =>
  root.marketplace.midBannerForm.id;
export const selectHelperText = (root: RootState) => root.marketplace.midBannerForm.helperText;
export const selectMidBannerFromDataValidation = (root: RootState) =>
  root.marketplace.midBannerForm.validation;
export const selectIsValidMidBannerFormData = (root: RootState) =>
  checkMidBannerFormDataValidation(root.marketplace.midBannerForm.validation);
