import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import {
  ContestContentProps,
  ContestContentsDatetime,
  ContestContentsValidations,
  ContestFilterProps,
  ContestFilterType,
  ContestHistoriesProps,
  ContestItemType,
  ContestListProps,
  ContestModalConfig,
  ContestNumberOfWork,
  ContestOngoingProps,
  ContestScheduleKeys,
  ContestType,
  ContestVisibilityType,
} from 'types/marketplace/operation/contest';
import { Nullable } from 'types/common';
import {
  INIT_ANNOUNCEMENT_DATE,
  INIT_CLOSED_DATE,
  INIT_EVALUATION_DATE,
  INIT_NOTICE_DATE,
  INIT_SUBMISSION_DATE,
} from 'constants/marketplace/contest';
import { getUtcDateDash, getUtcDateDashDifference } from 'modules/utils/time';
import qs from 'qs';
import {
  ContestRequest,
  CreateContestRequest,
  RemoveContestRequest,
  UpdateContestRequest,
} from 'types/api/marketplace/operation/contest';

const TODAY = getUtcDateDash(new Date());
const START_DATE = getUtcDateDashDifference(TODAY, 6, 'month');
const END_DATE = TODAY;

interface ContestState {
  uploadImage: {
    imageUrl: Nullable<string>;
    isLoading: boolean;
    error: Nullable<Error>;
  };
  filter: ContestFilterType;
  contents: {
    isInitialLoading: boolean;
    isLoading: boolean;
    error: Nullable<Error>;
    data: ContestContentProps;
    validations: ContestContentsValidations;
    modalConfig: ContestModalConfig;
  };
  contests: {
    isLoading: boolean;
    error: Nullable<Error>;
    data: ContestListProps;
    modalConfig: ContestModalConfig;
  };
  contestHistories: {
    isLoading: boolean;
    error: Nullable<Error>;
    data: ContestHistoriesProps;
    modalConfig: ContestModalConfig;
  };
  contestOngoingList: {
    isLoading: boolean;
    error: Nullable<Error>;
    data: ContestOngoingProps[];
  };
}

const initialState: ContestState = {
  uploadImage: {
    imageUrl: null,
    isLoading: false,
    error: null,
  },
  filter: {
    startDate: START_DATE,
    endDate: END_DATE,
    keyword: '',
    page: 1,
  },
  contents: {
    isInitialLoading: true,
    isLoading: false,
    error: null,
    data: {
      contestType: ContestType.LIVE,
      title: '',
      url: '',
      [ContestScheduleKeys.NOTICE]: INIT_NOTICE_DATE.toString(),
      [ContestScheduleKeys.SUBMISSION]: INIT_SUBMISSION_DATE.toString(),
      [ContestScheduleKeys.EVALUATION]: INIT_EVALUATION_DATE.toString(),
      [ContestScheduleKeys.ANNOUNCEMENT]: INIT_ANNOUNCEMENT_DATE.toString(),
      [ContestScheduleKeys.CLOSED]: INIT_CLOSED_DATE.toString(),
      checkLikesDate: false,
      likesDate: null,
      hostName: '',
      hostSite: null,
      uploadItemType: ContestItemType.GALLERY,
      numberOfWorkType: ContestNumberOfWork.SINGLE,
      imagePath: null,
      description: '',
      awards: [],
      attachmentPath: null,
      attachmentFileName: null,
    },
    validations: {
      title: null,
      url: null,
      hostName: null,
      image: null,
      description: null,
      awards: null,
    },
    modalConfig: {
      show: false,
      contestSeq: undefined,
    },
  },
  contests: {
    isLoading: false,
    error: null,
    data: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      items: [],
    },
    modalConfig: {
      show: false,
      contestSeq: undefined,
    },
  },
  contestHistories: {
    isLoading: false,
    error: null,
    data: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      items: [],
    },
    modalConfig: {
      show: false,
      contestSeq: undefined,
    },
  },
  contestOngoingList: {
    isLoading: false,
    error: null,
    data: [],
  },
};

export const contestSlice = createSlice({
  name: 'contest',
  initialState,
  reducers: {
    uploadImage: (
      state: ContestState,
      _action: PayloadAction<{
        formData: FormData;
      }>,
    ) => {
      state.uploadImage.isLoading = true;
    },
    uploadImageSuccess: (
      state: ContestState,
      action: PayloadAction<{ url: string; imageSeq: number }>,
    ) => {
      state.uploadImage.imageUrl = action.payload.url;
      state.uploadImage.isLoading = false;
    },
    uploadImageFailure: (state: ContestState, action: PayloadAction<Error>) => {
      state.uploadImage.error = action.payload;
      state.uploadImage.isLoading = false;
    },
    initContents: (state: ContestState) => {
      state.contents = initialState.contents;
    },
    updateContents: (state: ContestState, action: PayloadAction<Partial<ContestContentProps>>) => {
      state.contents.data = { ...state.contents.data, ...action.payload };
    },
    setValidations: (
      state: ContestState,
      action: PayloadAction<Partial<ContestContentsValidations>>,
    ) => {
      state.contents.validations = { ...state.contents.validations, ...action.payload };
    },
    resetFilter: (state: ContestState) => {
      state.filter = initialState.filter;
    },
    getContestList: (state: ContestState, action: PayloadAction<ContestFilterProps>) => {
      let payload = action?.payload;
      if (!payload) {
        payload = initialState.filter;
      }
      state.filter = payload;
      state.contests.isLoading = true;
    },
    getContestListSuccess: (state: ContestState, action: PayloadAction<ContestListProps>) => {
      state.contests.data = action.payload;
      state.contests.isLoading = false;
    },
    getContestListFailure: (state: ContestState, action: PayloadAction<Error>) => {
      state.contests.isLoading = false;
      state.contests.error = action.payload;
    },
    updateVisibility: (
      state: ContestState,
      action: PayloadAction<{ contestSeq: number; visibility: ContestVisibilityType }>,
    ) => {
      const { contestSeq, visibility } = action.payload;
      const findItem = state.contests.data.items.find(item => item.contestSeq === contestSeq);
      if (findItem) {
        findItem.visibility = visibility;
      }
    },
    getContest: (state: ContestState, _action: PayloadAction<ContestRequest>) => {
      state.contents.isLoading = true;
    },
    getContestSuccess: (state: ContestState, action: PayloadAction<ContestContentProps>) => {
      state.contents.data = action.payload;
      state.contents.isLoading = false;
      state.contents.isInitialLoading = false;
    },
    getContestFailure: (state: ContestState, action: PayloadAction<Error>) => {
      state.contents.error = action.payload;
      state.contents.isLoading = false;
      state.contents.isInitialLoading = false;
    },
    createContest: (state: ContestState, _action: PayloadAction<CreateContestRequest>) => {
      state.contents.isLoading = true;
    },
    createContestSuccess: (state: ContestState, _action: PayloadAction) => {
      state.contents.isLoading = false;
    },
    createContestFailure: (state: ContestState, action: PayloadAction<Error>) => {
      state.contents.isLoading = false;
      state.contents.error = action.payload;
    },
    updateContest: (state: ContestState, _action: PayloadAction<UpdateContestRequest>) => {
      state.contents.isLoading = true;
    },
    updateContestSuccess: (state: ContestState, _action: PayloadAction) => {
      state.contents.isLoading = false;
    },
    updateContestFailure: (state: ContestState, action: PayloadAction<Error>) => {
      state.contents.isLoading = false;
      state.contents.error = action.payload;
    },
    removeContest: (state: ContestState, _action: PayloadAction<RemoveContestRequest>) => {
      state.contents.isLoading = true;
    },
    removeContestSuccess: (state: ContestState, _action: PayloadAction) => {
      state.contents.isLoading = false;
    },
    removeContestFailure: (state: ContestState, action: PayloadAction<Error>) => {
      state.contents.isLoading = false;
      state.contents.error = action.payload;
    },
    getContestHistory: (state: ContestState, _action: PayloadAction<number>) => {
      state.contestHistories.isLoading = true;
    },
    getContestHistorySuccess: (
      state: ContestState,
      action: PayloadAction<ContestHistoriesProps>,
    ) => {
      state.contestHistories.isLoading = false;
      state.contestHistories.data = action.payload;
    },
    getContestHistoryFailure: (state: ContestState, action: PayloadAction<Error>) => {
      state.contestHistories.isLoading = false;
      state.contestHistories.error = action.payload;
    },
    setContestHistoryModal: (state: ContestState, action: PayloadAction<ContestModalConfig>) => {
      state.contestHistories.modalConfig = action.payload;
    },
    setContestEvaluationOpenModal: (
      state: ContestState,
      action: PayloadAction<ContestModalConfig>,
    ) => {
      state.contests.modalConfig = action.payload;
    },
    setContestEvaluationOpen: (state: ContestState, _action: PayloadAction<number>) => {},
    setContestEvaluationOpenSuccess: (state: ContestState, action: PayloadAction<number>) => {
      state.contests.modalConfig = initialState.contests.modalConfig;
      const findItem = state.contests.data.items.find(item => item.contestSeq === action.payload);
      if (findItem) {
        findItem.canOpen = false;
      }
    },
    setContestEvaluationOpenFailure: (state: ContestState, action: PayloadAction<Error>) => {
      state.contests.error = action.payload;
    },
    setContestRemoveModal: (state: ContestState, action: PayloadAction<ContestModalConfig>) => {
      state.contents.modalConfig = action.payload;
    },
    getContestOngoingList: (state: ContestState) => {
      state.contestOngoingList.isLoading = true;
    },
    getContestOngoingListSuccess: (
      state: ContestState,
      action: PayloadAction<ContestOngoingProps[]>,
    ) => {
      state.contestOngoingList.data = action.payload;
      state.contestOngoingList.isLoading = false;
    },
    getContestOngoingListFailure: (state: ContestState, action: PayloadAction<Error>) => {
      state.contestOngoingList.error = action.payload;
      state.contestOngoingList.isLoading = false;
    },
  },
});

export const contestActions = contestSlice.actions;
export const contestReducer = contestSlice.reducer;

export const selectUploadImageURL = (rootState: RootState): Nullable<string> => {
  return rootState.contest.uploadImage.imageUrl;
};
export const selectContentsIsInitialLoading = (rootState: RootState): boolean =>
  rootState.contest.contents.isInitialLoading;
export const selectContentsIsLoading = (rootState: RootState): boolean =>
  rootState.contest.contents.isLoading;
export const selectContentsError = (rootState: RootState): Nullable<Error> =>
  rootState.contest.contents.error;
export const selectContentsData = (rootState: RootState): ContestContentProps =>
  rootState.contest.contents.data;
export const selectContentsSchedule = (rootState: RootState): ContestContentsDatetime => {
  return {
    [ContestScheduleKeys.NOTICE]: rootState.contest.contents.data.noticeDate,
    [ContestScheduleKeys.SUBMISSION]: rootState.contest.contents.data.submissionDate,
    [ContestScheduleKeys.EVALUATION]: rootState.contest.contents.data.evaluationDate,
    [ContestScheduleKeys.ANNOUNCEMENT]: rootState.contest.contents.data.announcementDate,
    [ContestScheduleKeys.CLOSED]: rootState.contest.contents.data.closedDate,
  };
};
export const selectContentsValidations = (rootState: RootState): ContestContentsValidations =>
  rootState.contest.contents.validations;

export const selectContestFilter = (rootState: RootState): ContestFilterType =>
  rootState.contest.filter;
export const selectContestQueryString = (rootState: RootState): string =>
  qs.stringify(rootState.contest.filter, { arrayFormat: 'repeat' });
export const selectContestListIsLoading = (rootState: RootState): boolean =>
  rootState.contest.contests.isLoading;
export const selectContestListError = (rootState: RootState): Nullable<Error> =>
  rootState.contest.contests.error;
export const selectContestList = (rootState: RootState): ContestListProps =>
  rootState.contest.contests.data;
export const selectContestHistoryIsLoading = (rootState: RootState): boolean =>
  rootState.contest.contestHistories.isLoading;
export const selectContestHistoryError = (rootState: RootState): Nullable<Error> =>
  rootState.contest.contestHistories.error;
export const selectContestHistories = (rootState: RootState): ContestHistoriesProps =>
  rootState.contest.contestHistories.data;
export const selectContestHistoryModal = (rootState: RootState): ContestModalConfig =>
  rootState.contest.contestHistories.modalConfig;
export const selectContestEvaluationOpenModal = (rootState: RootState): ContestModalConfig =>
  rootState.contest.contests.modalConfig;
export const selectContestRemoveModal = (rootState: RootState): ContestModalConfig =>
  rootState.contest.contents.modalConfig;
export const selectContestOngoingIsLoading = (rootState: RootState): boolean =>
  rootState.contest.contestOngoingList.isLoading;
export const selectContestOngoingError = (rootState: RootState): Nullable<Error> =>
  rootState.contest.contestOngoingList.error;
export const selectContestOngoingData = (rootState: RootState): ContestOngoingProps[] =>
  rootState.contest.contestOngoingList.data;
