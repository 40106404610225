import { ImageResize } from 'types/common/imageResize';
import qs from 'qs';

/* Returns the size queryParam appended to the Image URL */
export const resizingImageUrl = (
  url: string | undefined,
  queryImageResize: ImageResize,
): string => {
  if (!url) {
    return '';
  }
  // base64인 경우가 추가되어 기존 url을 http로 체크해 준다. base64 string에도 http가 있어서 0으로 체크
  if (url.indexOf('http') === 0) {
    // ?w=350&h=467&fit=cover
    const q = qs.stringify(queryImageResize, { addQueryPrefix: true });
    return url + q;
  }
  return url;
};
