import * as React from 'react';
import { ReactNode } from 'react';
import RouteChildComponent from 'components/common/RouteChildComponent';
import { RouteInfoType } from 'types/common/routes';
import { Route } from 'react-router-dom';

export const generateRouteNode = (
  Layout: React.ElementType,
  routes: Array<RouteInfoType>,
): ReactNode => {
  return routes.map(({ component: Component, children, path }, index: number) => {
    if (children) {
      return children.reduce<ReactNode[]>((list, element, index) => {
        return [
          ...list,
          RouteChildComponent({ Layout, element, key: index }),
          element.children
            ? element.children.map((element, childIndex) =>
                RouteChildComponent({ Layout, element, key: `${index}-${childIndex}` }),
              )
            : [],
        ];
      }, []);
    }

    if (Component) {
      return (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      );
    }

    return null;
  });
};
