import * as React from 'react';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import { invoiceBillingTableColumns } from './InvoiceTableColumns';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';
import InvoiceTableBottomSummary from 'components/plansales/billing/detail/invoice/InvoiceTableBottomSummary';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import { InvoiceDescriptionStatus, InvoiceItem } from 'types/plansales/billing/invoice';

interface Props {
  invoiceItems: InvoiceItem[];
  subTotalAmount: number;
  totalAmount: number;
  taxAmount: number;
  isRefunded: boolean;
  invoiceStatus: InvoiceDescriptionStatus;
}

const InvoiceTable = ({
  invoiceItems,
  subTotalAmount,
  totalAmount,
  taxAmount,
  isRefunded,
  invoiceStatus,
}: Props) => {
  const invoiceBillingColumnData = invoiceBillingTableColumns(invoiceStatus);

  return (
    <>
      <DataTableLayout tableTitle={'Item List'}>
        <DataTableHeader header={invoiceBillingColumnData} />
        <NewDataTableBody<InvoiceItem> dataList={invoiceItems} columns={invoiceBillingColumnData} />
      </DataTableLayout>
      <InvoiceTableBottomSummary
        isRefunded={isRefunded}
        subTotalAmount={subTotalAmount}
        totalAmount={totalAmount}
        taxAmount={taxAmount}
      />
    </>
  );
};

export default InvoiceTable;
