import * as React from 'react';
import { Divider } from 'components/common/Divider';
import FilterActionButtons from 'components/common/searchfilter/FilterActionButtons';
import styled from 'styled-components';

interface Props {
  resetFilter: () => void;
  getSearchResult: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}
const FilterLayout = ({ resetFilter, getSearchResult, children, disabled }: Props) => {
  return (
    <>
      <FilterContentWrapper>{children}</FilterContentWrapper>
      <Divider my={3} />
      <FilterActionButtons
        disabled={disabled}
        resetFilter={resetFilter}
        getSearchResult={getSearchResult}
      />
    </>
  );
};

const FilterContentWrapper = styled.div`
  width: 100%;
  padding: 0 ${props => props.theme.spacing(3)}px;
`;

export default FilterLayout;
