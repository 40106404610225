import * as React from 'react';
import CommentList from 'components/memberdetail/detail/commenttable/CommentList';
import { useDispatch, useSelector } from 'react-redux';
import {
  memberDetailActions,
  selectComment,
  selectCommentListLoading,
} from 'features/member/reducer/memberDetailSlice';
import AddCommentModal from 'components/memberdetail/detail/commenttable/AddCommentModal';
import DeleteModal from 'components/common/modal/DeleteModal';
import { useEffect, useState } from 'react';
import InformModal from 'components/common/modal/InformModal';
import { PlanInformationBlock as MemberCommentBlock } from 'components/memberdetail/detail/pricingplan/PricingPlanInformation';
import { FIRST_PAGE_INDEX } from 'constants/common';
import { Comment } from 'types/member';

interface Props {
  userId: string;
}

export const DEFAULT_COMMENT_INFO = {
  adminScreen: '',
  commentText: '',
  histories: [],
  id: '',
  keys: { userid: '' },
  partitionKey: '',
  tags: '',
  writeAt: '',
  writeBy: '',
};

const MemberCommentContainer = ({ userId }: Props) => {
  const dispatch = useDispatch();
  const { items: commentList, totalPages, totalItems } = useSelector(selectComment);
  const commentListLoading = useSelector(selectCommentListLoading);
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [invalidDateModalOpen, setInvalidDateModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [commentDeleteModalOpen, setCommentDeleteModalOpen] = useState<boolean>(false);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(FIRST_PAGE_INDEX);
  const [selectedComment, setSelectedComment] = useState<Comment>(DEFAULT_COMMENT_INFO);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    dispatch(memberDetailActions.getCommentList({ userId }));
  }, []);

  const onChangeCommentListPage = (pageIndex: number) => {
    // addQueryString({ history, name: 'page', value: pageIndex });
    setCurrentPageIndex(pageIndex);
    dispatch(memberDetailActions.getCommentList({ userId, page: pageIndex }));
  };

  const deleteComment = () => {
    const { id, partitionKey, writeBy } = selectedComment;
    dispatch(
      memberDetailActions.deleteMemberComment({ userId, id, partitionKey, writeBy, reFetch: true }),
    );
    setCommentDeleteModalOpen(false);
  };

  const onClickCancelButton = () => {
    setCommentModalOpen(false);
    setIsEditing(false);
  };

  return (
    <>
      <InformModal
        open={invalidDateModalOpen}
        onClose={() => setInvalidDateModalOpen(false)}
        description={errorMessage}
      />
      <AddCommentModal
        userId={userId}
        commentModalOpen={commentModalOpen}
        closeCommentModal={onClickCancelButton}
        setErrorMessage={setErrorMessage}
        setInvalidDateModalOpen={setInvalidDateModalOpen}
        setCommentModalOpen={setCommentModalOpen}
        selectedComment={selectedComment}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
      <DeleteModal
        open={commentDeleteModalOpen}
        onClose={() => setCommentDeleteModalOpen(false)}
        content={<>Are you sure you want to delete ?</>}
        onDelete={deleteComment}
      />
      <MemberCommentBlock>
        <CommentList
          commentList={commentList}
          setCommentModalOpen={setCommentModalOpen}
          setCommentDeleteModalOpen={setCommentDeleteModalOpen}
          totalPages={totalPages}
          onChangePage={onChangeCommentListPage}
          currentPageIndex={currentPageIndex}
          setSelectedComment={setSelectedComment}
          setIsEditing={setIsEditing}
          commentListLoading={commentListLoading}
          totalItems={totalItems}
        />
      </MemberCommentBlock>
    </>
  );
};

export default MemberCommentContainer;
