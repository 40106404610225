import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { LayoutType } from 'types/marketplace/operation/contest';
import classnames from 'classnames';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { getEnumKeyByStringValue } from 'modules/utils/enum';
import TwoPieceType1Item1Background from 'components/marketplace/operation/contest/preview/desktop/twopiecelayout/TwoPieceType1Item1Background';
import { mq } from '@closet-design-system/theme';
import TwoPieceType1Item2Background from 'components/marketplace/operation/contest/preview/desktop/twopiecelayout/TwoPieceType1Item2Background';

const TWO_PIECE_ITEM_LONG_WIDTH = 65;
const TWO_PIECE_ITEM_SHORT_WIDTH = 35;

const TwoPieceTypeColumn = ({
  children,
  layoutType,
  order,
}: {
  children: ReactNode;
  layoutType: LayoutType;
  order: number;
}): ReactElement => {
  const layoutTypeKey = getEnumKeyByStringValue(layoutType, LayoutType);
  const isFirstItem = order === 0;

  switch (layoutType) {
    case LayoutType.TWO_PIECE_2:
    case LayoutType.TWO_PIECE_3:
      return (
        <TwoPieceItemsWrapped className={classnames({ [layoutTypeKey]: layoutTypeKey })}>
          {children}
        </TwoPieceItemsWrapped>
      );
    case LayoutType.TWO_PIECE_4:
      return (
        <Grid item xs={isFirstItem ? 8 : 4}>
          {children}
        </Grid>
      );
    default:
      /* TWO_PIECE_1 */
      return (
        <TwoPieceType1ItemBlock className={classnames({ [layoutTypeKey]: layoutTypeKey })}>
          {isFirstItem && <TwoPieceType1Item1Background />}
          <TwoPieceType1ThumbnailBlock className={classnames({ first: isFirstItem })}>
            {children}
          </TwoPieceType1ThumbnailBlock>
          {!isFirstItem && <TwoPieceType1Item2Background />}
        </TwoPieceType1ItemBlock>
      );
  }
};

const TwoPieceType1ItemBlock = styled.div(
  ({
    theme: {
      breakpoints: { m, l },
    },
  }) =>
    mq({ m, l })({
      position: 'relative',
      width: '100%',
      ['&:first-child']: {
        minWidth: 363,
        display: 'flex',
        alignItems: 'flex-end',
        paddingRight: [16, 20, 24],
        overflow: ['hidden', 'initial'],
      },
      ['&:last-child']: {
        zIndex: 2,
        height: '100%',
      },
    }),
);

const TwoPieceType1ThumbnailBlock = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 0 0 400px 400px;
  &.first {
    border-radius: 400px 400px 0 0;
  }
`;

const TwoPieceItemsWrapped = styled.div`
  &.TWO_PIECE_2 {
    &:first-child {
      width: ${TWO_PIECE_ITEM_LONG_WIDTH}%;
    }
    &:last-child {
      width: ${TWO_PIECE_ITEM_SHORT_WIDTH}%;
    }
  }
  &.TWO_PIECE_3 {
    &:first-child {
      width: ${TWO_PIECE_ITEM_SHORT_WIDTH}%;
    }
    &:last-child {
      width: ${TWO_PIECE_ITEM_LONG_WIDTH}%;
    }
  }
`;

export default TwoPieceTypeColumn;
