import * as React from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import Checkbox from 'components/common/form/Checkbox';
import styled from 'styled-components';
import {
  featureUpdateNoticeLinkOpenTypeLabel,
  linkOpenTypes,
  REQUIRED_HELP_TEXT,
} from 'constants/notice/featureUpdateNotice';
import {
  FeatureUpdateNoticeLinkButtonValidation,
  FeatureUpdateNoticeLinkOpenType,
  FeatureUpdateNoticeModalLinkButton,
} from 'types/notice/featureUpdateNotice';
import InputText from 'components/common/form/InputText';

interface Props {
  useLinkButton: boolean;
  linkButton: FeatureUpdateNoticeModalLinkButton;
  onChangeUseLinkButton: () => void;
  onChangeLinkOpenType: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  onChangeLinkButton: (data: FeatureUpdateNoticeModalLinkButton) => void;
  validation: FeatureUpdateNoticeLinkButtonValidation;
}

type FeatureUpdateNoticeLinkButtonText = 'linkUrl' | 'buttonNameCn' | 'buttonNameEng';

const FeatureUpdateNoticeFormLink = ({
  useLinkButton,
  linkButton,
  onChangeUseLinkButton,
  onChangeLinkOpenType,
  onChangeLinkButton,
  validation,
}: Props) => {
  const { linkUrl, buttonNameEng, buttonNameCn, linkOpenType } = linkButton;
  const onChangeText =
    (type: FeatureUpdateNoticeLinkButtonText) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      onChangeLinkButton({
        ...linkButton,
        [type]: evt.target.value,
      });
    };

  const buttons = [
    {
      title: 'English',
      value: buttonNameEng,
      type: 'buttonNameEng',
      error: validation.buttonNameEng,
    },
    {
      title: 'Chinese',
      value: buttonNameCn,
      type: 'buttonNameCn',
      error: validation.buttonNameCn,
    },
  ];

  return (
    <>
      <Grid container spacing={6} alignItems={'center'}>
        <Grid item>
          <Typography variant="h6">Link Button</Typography>
        </Grid>
        <Grid item>
          <Checkbox label={'No Link'} value={!useLinkButton} onChange={onChangeUseLinkButton} />
        </Grid>
      </Grid>
      <Typography variant="subtitle1" gutterBottom>
        Button Name
      </Typography>
      <Grid container spacing={4}>
        {buttons.map(button => (
          <Grid item md={6} sm={6} key={button.title}>
            <Typography variant={'subtitle2'}>{button.title}</Typography>
            <InputText
              size={'small'}
              variant={'outlined'}
              fullWidth
              value={button.value}
              disabled={!useLinkButton}
              onChange={onChangeText(button.type as FeatureUpdateNoticeLinkButtonText)}
              helperText={REQUIRED_HELP_TEXT}
              error={button.error}
              maxLength={15}
            />
          </Grid>
        ))}
      </Grid>
      <Spacing spacing={2} />
      <Typography variant="subtitle1">Link URL</Typography>
      <RadioGroup
        aria-label="linkOpenType"
        name="link-type"
        value={linkOpenType ?? FeatureUpdateNoticeLinkOpenType.NEW_TAB}
        onChange={onChangeLinkOpenType}
        row
        aria-disabled={!useLinkButton}
      >
        {linkOpenTypes.map(linkType => (
          <FormControlLabel
            key={linkType}
            value={linkType}
            control={<Radio disabled={!useLinkButton} />}
            label={featureUpdateNoticeLinkOpenTypeLabel[linkType]}
          />
        ))}
      </RadioGroup>
      <InputText
        size={'small'}
        variant={'outlined'}
        fullWidth
        placeholder={'Please enter the link to connect'}
        value={linkUrl}
        disabled={!useLinkButton}
        onChange={onChangeText('linkUrl')}
        helperText={REQUIRED_HELP_TEXT}
        error={validation.linkUrl}
      />
    </>
  );
};

export default FeatureUpdateNoticeFormLink;

const Spacing = styled.div<{ spacing: number }>`
  padding: ${props => props.theme.spacing(props.spacing)}px;
`;
