import async from 'components/template/Async';
import { RouteInfoType } from 'types/common/routes';

export const RevenueShare = async(() => import('pages/marketplace/revenue/RevenueShare'));
export const RevenueDetail = async(() => import('pages/marketplace/revenue/RevenueDetail'));

export const REVENUE_SHARE_PATH = '/marketplace/revenue';

export const RevenueRoutes: RouteInfoType[] = [
  {
    name: 'Revenue Detail',
    path: `${REVENUE_SHARE_PATH}/detail/:tab`,
    to: REVENUE_SHARE_PATH,
    component: RevenueDetail,
  },
];
