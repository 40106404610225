import * as React from 'react';
import { ReactElement } from 'react';
import { mq } from '@closet-design-system/theme';
import styled from 'styled-components';
import { ContestAwardItemProps, ContestBadge } from 'types/marketplace/operation/contest';
import WinnerBadge from 'components/marketplace/operation/contest/preview/common/WinnerBadge';
import classNames from 'classnames';
import { getBadgeImageUrl } from 'modules/contest';

export interface AwardThumbnailProps
  extends Omit<ContestAwardItemProps, 'itemId' | 'layoutOrder' | 'userId'> {
  badgeId: ContestBadge;
  ratio?: number;
  maxHeight?: number | string;
  isBadgePositionTop?: boolean;
}

const AwardThumbnail = ({
  imagePath,
  creatorImagePath,
  creator,
  badgeId,
  ratio = 1.33,
  maxHeight = 941,
  isBadgePositionTop = false,
}: AwardThumbnailProps): ReactElement => {
  const badgeImageUrl: string = getBadgeImageUrl({
    badgeId,
    isMobile: false,
    extension: 'webp',
  });

  return (
    <AwardThumbnailRoot imgUrl={badgeImageUrl}>
      <AwardThumbnailBox ratio={ratio} maxHeight={maxHeight}>
        <ThumbnailImageWrapped>
          <ThumbnailImage src={imagePath} />
        </ThumbnailImageWrapped>
        <WinnerBadgeLayout
          className={classNames({
            isBadgePositionTop,
          })}
        >
          <WinnerBadge badgeId={badgeId} profileImageUrl={creatorImagePath} nickName={creator} />
        </WinnerBadgeLayout>
      </AwardThumbnailBox>
    </AwardThumbnailRoot>
  );
};

export default AwardThumbnail;

export const ThumbnailImageWrapped = styled.span`
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: inherit;
  height: inherit;
  background: none;
  opacity: 1;
`;

export const ThumbnailImage = styled.img`
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  border: none;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;

const AwardThumbnailRoot = styled.div<{ imgUrl: string }>(
  ({
    imgUrl,
    theme: {
      breakpoints: { m },
    },
  }) =>
    mq({ m })({
      cursor: 'pointer',
      ['&:hover .prize-badge']: {
        background: ['', `no-repeat center/40px url(${imgUrl})`],
      },
      ['&::after']: {
        /* image 소스 다운로드용(처음 hover시 이미지 늦게 나오는 현상 방지) */
        position: 'absolute',
        width: 0,
        height: 0,
        overflow: 'hidden',
        zIndex: -1,
        content: ['" "', `url(${imgUrl})`],
      },
    }),
);

export const AwardThumbnailBox = styled.div<{
  alignSelf?: string;
  ratio?: number;
  maxHeight?: number | string;
}>(({ ratio = 1.33 }) => ({
  position: 'relative',
  minHeight: '274px',
  width: '100%',
  paddingTop: `calc(${ratio} * 100%)`,
  background: 'rgba(46, 46, 51, 0.5)',
  cursor: 'pointer',
}));

const WinnerBadgeLayout = styled.div(
  ({
    theme: {
      breakpoints: { s },
    },
  }) =>
    mq({ s })({
      position: 'absolute',
      bottom: [8, 24],
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      ['&.isBadgePositionTop']: {
        top: [8, 24],
        bottom: 'unset',
      },
    }),
);
