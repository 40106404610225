import {
  SalesType,
  Software,
  ConfirmStatus,
  ZipStatus,
  DigitalStampStatuses,
} from 'types/marketplace/confirmItem';

export interface ItemHistory {
  itemId: string;
  status: ConfirmStatus;
  changedBy: string | null;
  changedDate: string;
  comment: string | null;
  rejection: string | null;
}

export interface ConfirmItemResponseData {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: Item[];
}

export interface Item {
  itemId: string;
  itemNumber: number;
  thumbnail: string;
  title: string;
  creatorName: string;
  creatorAccount: string;
  store: number;
  individual: number | null;
  enterprise: number | null;
  salesType: number;
  mainPage: MainPage | null;
  liveInfo: LiveInfo | null;
  requestInfo: RequestInfo | null;
  latestUpdate: string;
  firstPublishedDate: string | null;
  changedBy: string;
  uploadStatus: boolean;
  swType: Software | null;
  mdPick: boolean;
  digitalStampStatus: DigitalStampStatuses | null;
  isGarment: boolean;
  garmentCount: number;
  convertingStatus: ConvertingStatus;
}

export interface ConvertingStatus {
  [key: string]: boolean;
  isZipConvertingFailed: boolean;
  isSrestConvertingFailed: boolean;
  isStandardFormatConvertingFailed: boolean;
}

export interface MainPage {
  name: string;
  mainPageId: string;
}

export interface RequestInfo {
  liveStatus: null;
  itemId: string;
  status: ConfirmStatus;
  fileCount: number;
  salesType: SalesType;
}

export interface LiveInfo {
  itemId: string;
  status: ConfirmStatus;
  fileCount: number;
  salesType: SalesType;
}

export interface ItemDownloadInfo {
  cloFiles: ItemFile[];
  standardFormatFile: {
    standardFormatFiles: ItemFile[];
    isConvertingSuccess: boolean;
    dllError: string;
    serverError: string;
    dllVersion: string;
  };
}

export interface ItemFile {
  itemId: string;
  fileName: string;
  downloadPath: string;
  fileType: FileType;
}

export enum FileType {
  ZIP = 0,
  FILE = 1,
  ATTACH = 2,
  STANDARD_FILE = 3,
}

export interface ConnectCategory {
  categoryId: string;
  parentId: null | string;
  children: ConnectCategory[];
  name: string;
  sort: number;
  frequentlyType: null;
  flow: string[];
}

export interface ItemCategory {
  itemId: string;
  categoryId: string;
  isTemp: boolean;
}

export interface UpdateItemCategoryPayload {
  itemId: string;
  categories: string[];
}
