import { spacing, SpacingProps } from '@material-ui/system';
import * as React from 'react';
import styled from 'styled-components';

/**
 * 필수 항목을 표기하는 '*' 마크, spacing props 를 적용받음
 * ex)
 * <Typography variant="h6"> Title <RequiredMark /></Typography>
 * <Typography variant="h6"> Title <RequiredMark ml={2} /></Typography>
 */
const RequiredMark = ({ ml = 1, ...props }: SpacingProps) => {
  return (
    <Wrapper ml={ml} {...props}>
      *
    </Wrapper>
  );
};
const Wrapper = styled(styled.span(spacing))`
  color: ${({ theme }) => theme.palette.error.main};
`;

export default RequiredMark;
