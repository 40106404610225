import * as React from 'react';
import AccordionBox from 'components/memberdetail/detail/AccordionBox';
import { Button } from '@material-ui/core';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import { Dispatch, SetStateAction } from 'react';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';
import { couponTableColumns } from 'components/memberdetail/detail/coupon/CouponListColumns';
import { Coupon } from 'types/member';
import { TotalCount } from 'components/memberdetail/detail/AuthProvider';

interface Props {
  setCouponModalOpen: Dispatch<SetStateAction<boolean>>;
  couponList: Coupon[];
  onChangeDisplay: (event: React.ChangeEvent, expanded: boolean, name: string) => void;
  couponListLoading: boolean;
  onChangePage: (pageIndex: number) => void;
  currentPageIndex: number;
  totalPages: number;
  addButtonDisabled: boolean;
  totalItems: number;
}

const CouponList = ({
  setCouponModalOpen,
  couponList,
  onChangeDisplay,
  couponListLoading,
  onChangePage,
  currentPageIndex,
  totalPages,
  addButtonDisabled,
  totalItems,
}: Props) => {
  const couponListColumnData = couponTableColumns();

  return (
    <AccordionBox
      title={'Coupon List'}
      margin={'15px 0 0 0'}
      onChangeDisplay={onChangeDisplay}
      name={'coupon'}
    >
      <DataTableLayout
        maxHeight={'300px'}
        upperButton={
          <Button
            size={'small'}
            variant={'contained'}
            color={'primary'}
            onClick={() => setCouponModalOpen(true)}
            disabled={addButtonDisabled}
          >
            Add Coupon
          </Button>
        }
        showPagination
        totalPages={totalPages}
        onChangePage={onChangePage}
        currentPageIndex={currentPageIndex}
        customTitleComponent={<TotalCount>total {totalItems}</TotalCount>}
      >
        <DataTableHeader header={couponListColumnData} />
        <NewDataTableBody
          columns={couponListColumnData}
          dataList={couponList}
          loading={couponListLoading}
        />
      </DataTableLayout>
    </AccordionBox>
  );
};

export default CouponList;
