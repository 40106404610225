import * as React from 'react';
import { ReactElement } from 'react';
import styled from 'styled-components';

const BackgroundAnimationView = (): ReactElement => {
  return (
    <Background viewBox="0 0 1440 900" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M654.369 1279.95C623.34 1279.95 592.312 1266.92 568.71 1240.91L-66.5246 540.965C-89.393 515.767 -102 482.277 -102 446.633C-102 410.99 -89.393 377.446 -66.5246 352.248L326.539 -80.8569C349.408 -106.055 379.85 -120 412.198 -120C444.546 -120 474.989 -106.109 497.857 -80.9107L743.4 189.592H792.117L928.937 340.349H736.901C704.553 340.349 674.11 326.458 651.242 301.26L412.198 37.8648L41.3187 446.633L654.369 1122.14L928.497 819.651H1122L740.028 1240.96C716.427 1266.97 685.398 1280 654.369 1280V1279.95Z"
          fill="#161617"
        />
        <path
          d="M719.929 820.238C688.577 820 659.027 832.283 636.83 854.564L546 945.735L639.913 1040L726.284 953.304H773.383L906 820L719.929 820.238Z"
          fill="#272728"
        />
        <path
          d="M315.219 1016.28L41.5767 717.426L510 205.854L413.075 100L-66.4607 623.708C-89.3703 648.728 -102 682.034 -102 717.426C-102 752.817 -89.3703 786.124 -66.4607 811.144L327.31 1241.19C350.22 1266.21 380.717 1280 413.124 1280C445.53 1280 476.027 1266.21 498.937 1241.19L510 1229.1L315.219 1016.38V1016.28Z"
          fill="#272728"
        />
        <path
          d="M924.946 340L1122 339.516L732.181 -80.9449C708.84 -106.121 677.768 -120 644.751 -120C611.734 -120 580.663 -106.121 557.321 -80.9449L546 -68.7335L924.946 340Z"
          fill="#272728"
        />
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="translate"
          values="0;320;0"
          dur="30s"
          repeatCount="indefinite"
        />
      </g>
    </Background>
  );
};

export default BackgroundAnimationView;

const Background = styled.svg`
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100vh; /* SVG의 높이를 컨테이너 높이에 맞춤 */
  width: auto; /* 가로 폭은 종횡비에 따라 자동 조정 */
  overflow: visible;
  position: absolute;
`;
