import * as React from 'react';
import { ReactElement } from 'react';
import { mq } from '@closet-design-system/theme';
import styled from 'styled-components';
import classNames from 'classnames';
import { getMediaQueryRangeValues } from 'modules/mainBannerPreview';

interface Props {
  buttonText: string;
  url: string;
  openNewTab: boolean;
  darkMode: boolean;
}

const BannerLinkButton = ({ buttonText, url, openNewTab, darkMode }: Props): ReactElement => {
  return (
    <BannerButton
      className={classNames({
        darkMode,
      })}
      href={url}
      target={openNewTab ? '_blank' : '_self'}
      rel="noreferrer noopener"
    >
      {buttonText}
    </BannerButton>
  );
};

export default BannerLinkButton;

const bannerButtonMarginTop = mq()({
  marginTop: getMediaQueryRangeValues({ s: '20px', m: '20px', l: '40px' }),
  padding: getMediaQueryRangeValues({ s: '10px 40px', m: '10px 43px', l: '10px 43px' }),
  lineHeight: getMediaQueryRangeValues({ s: '16px', m: '20px', l: '20px' }),
  fontSize: getMediaQueryRangeValues({ s: '12px', m: '14px', l: '14px' }),
});

/** 메인 배너에서만 사용되는 버튼  */
const BannerButton = styled.a`
  display: block;
  background-color: transparent;
  border-radius: 55px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1.75px;
  text-transform: uppercase;
  text-decoration: none;
  ${bannerButtonMarginTop}
  color: ${({ theme }) => theme.colors.GRAY_900};
  border: 1px solid ${({ theme }) => theme.colors.GRAY_900};

  &:hover {
    color: ${({ theme }) => theme.colors.GRAY_50};
    background-color: ${({ theme }) => theme.colors.GRAY_900};
  }

  &.darkMode {
    color: ${({ theme }) => theme.colors.GRAY_50};
    border: 1px solid ${({ theme }) => theme.colors.GRAY_50};

    &:hover {
      color: ${({ theme }) => theme.colors.GRAY_900};
      background-color: ${({ theme }) => theme.colors.GRAY_50};
    }
  }
`;
