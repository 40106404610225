import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jinnyActions, selectArtworkError, selectArtworkLoading } from 'features/jinny/jinnySlice';
import { useHistory } from 'react-router-dom';
import ContentLayout from 'components/common/layouts/ContentLayout';
import InputText from 'components/common/form/InputText';
import FileUploadArea from 'components/common/form/FileUploadArea';
import {useEffect, useState} from 'react';
import { Button } from '@material-ui/core';
import { JINNY_ARTWORK_URLPATH } from 'routes/ClosetAdminRoutes';
import styled from 'styled-components';
import { Divider } from 'components/common/Divider';

const ArtworkFormContainer = () => {
  const dispatch = useDispatch();
  const errors = useSelector(selectArtworkError);
  const isLoading = useSelector(selectArtworkLoading);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const history = useHistory();

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>): void => {
    evt.preventDefault();
    const form = evt.currentTarget;
    if (form) {
      const formData = new FormData();
      formData.append('ArtworkTitle', form?.['artworkTitle']?.value);
      if (imageFile) {
        formData.append('Image', imageFile);
      }
      dispatch(jinnyActions.editArtwork({ formData, history }));
    }
  };

  const handleCancel = () => {
    history.push(`${JINNY_ARTWORK_URLPATH}`);
  };

  const handleChangeImage = (image: File | null) => {
    setImageFile(image);
  };

  useEffect(() => {
    return  (() => {
      dispatch(jinnyActions.resetArtwork())
    })
  }, [])

  return (
    <ContentLayout pageTitle={'Edit Launcher Artwork'}>
      <form onSubmit={handleSubmit}>
        <FormTopSpace>
          <InputText
            title={'Artwork Title'}
            required={true}
            placeholder={'Please enter the name of the Artwork'}
            maxLength={50}
            name={'artworkTitle'}
            error={Boolean(errors?.['artworkTitle'])}
            helperText={errors?.['artworkTitle'] && errors['artworkTitle'].join()}
          />
        </FormTopSpace>
        <FormMidSpace>
          <FileUploadArea
            title={'Image'}
            required={true}
            file={imageFile}
            onChangeFile={handleChangeImage}
            descriptionUploadSpce={'Optional Image Size 2000*1200'}
            labelCreate={'Add Image'}
            labelReplace={'Replace Image'}
            error={Boolean(errors?.['image'])}
            helperText={errors?.['image'] && errors['image'].join()}
          />
        </FormMidSpace>
        <Divider my={4} />
        <FormBottomSpace>
          <Button variant={'outlined'} onClick={handleCancel}>
            Back
          </Button>
          <Button color={'primary'} variant={'contained'} disabled={isLoading} type={'submit'}>
            Save
          </Button>
        </FormBottomSpace>
      </form>
    </ContentLayout>
  );
};

const FormTopSpace = styled.div`
  margin-bottom: 39px;
`;
const FormMidSpace = styled.div`
  margin-bottom: 30px;
`;

const FormBottomSpace = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  gap: 10px;
`;

export default ArtworkFormContainer;
