import coreApi from 'api/coreApi';
import { AxiosError } from 'axios';
import {
  GetSellerCommentHistoryResponse,
  GetSellerListParams,
  GetSellerListResponse,
  PutNewSellerStatus,
} from 'types/api/marketplace/confirmSellerInfo';
import qs from 'qs';

export const getSellerList = (data: GetSellerListParams) => {
  const {
    dateInterval: [startDate, endDate],
    sellerStatus,
    keyword,
    sortBy: sortType,
    pageNo,
  } = data;

  const statusQueries = Object.keys(sellerStatus).reduce((result, current) => {
    if (sellerStatus[current]) result += `&status=${current}`;
    return result;
  }, '');

  const params = {
    startDate,
    endDate,
    sortType,
    pageNo,
    keyword,
    pageSize: 20,
  };

  return coreApi
    .get<GetSellerListResponse>(`/connects/Sellers/PaymentInfoes`, {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' }).concat(statusQueries);
      },
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getSellerConfirmCommentHistory = (data: { userId: number }) => {
  const { userId } = data;
  return coreApi
    .get<GetSellerCommentHistoryResponse>(`/connects/Sellers/${userId}/PaymentInfoStatus`)
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const putNewSellerStatus = (data: PutNewSellerStatus) => {
  const { userId, comment, sellerStatus, reason } = data;
  return coreApi
    .put<GetSellerCommentHistoryResponse>(`/connects/Sellers/${userId}/PaymentInfoStatus`, {
      status: sellerStatus,
      comment,
      reason,
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};
