import styled from 'styled-components';
import * as React from 'react';
import { ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import { getUtcUSFormatDateWithTime } from 'modules/utils/time';
import { Skeleton } from '@material-ui/lab';

interface Props {
  title: string | undefined;
  registeredBy: string | undefined;
  registeredDate: string | undefined;
  isLoading: boolean;
}

const ContentTop = ({ title, registeredBy, registeredDate, isLoading }: Props): ReactElement => {
  return (
    <>
      <Typography variant="h5" gutterBottom component="div">
        <ContentTopSpace>
          {isLoading ? (
            <>
              <Skeleton variant={'text'} width={'30%'} />
              <Skeleton variant={'text'} width={'40%'} />
            </>
          ) : (
            <>
              <TopLeftSpace>{title ?? '-'}</TopLeftSpace>
              <TopRightSpace>
                {(registeredBy && `Updated by ${registeredBy}`) ?? '-'} |{' '}
                {(registeredDate && getUtcUSFormatDateWithTime(registeredDate, true)) ?? '-'}
              </TopRightSpace>
            </>
          )}
        </ContentTopSpace>
      </Typography>
    </>
  );
};

const ContentTopSpace = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TopLeftSpace = styled.div``;
const TopRightSpace = styled.div`
  color: #5f5f68;
`;

export default ContentTop;
