import * as React from 'react';
import {
  getDateDifference,
  getUtcDateDash,
  getKstUSFormatDateWithTime,
  getUtcUSFormatDateWithTime,
} from 'modules/utils/time';
import { NULL_TEXT } from 'constants/common';
import { DateInterval, ValidationParams } from 'types/plansales/billing';

export const getUtcAndKstTime = (time: string) => (
  <>
    {`UTC : ${getUtcUSFormatDateWithTime(time, true)}`}
    <br />
    {`KST : ${getKstUSFormatDateWithTime(time, true)}`}
  </>
);

export const getBlankUtcAndKstTime = () => (
  <>
    {`UTC : ${NULL_TEXT}`}
    <br />
    {`KST : ${NULL_TEXT}`}
  </>
);

export const isValidDate = ({ type, newDateValue, from, to }: ValidationParams) => {
  const difference = {
    from: type === DateInterval.FROM ? getUtcDateDash(newDateValue) : from,
    to: type === DateInterval.FROM ? to : getUtcDateDash(newDateValue),
  };
  return getDateDifference(difference.from, difference.to) < 0;
};
