// private closet BillingTableRenderer.ts 파일내용과 동일함.

import * as React from 'react';
import {
  ClosetPlanType,
  InvoiceDescriptionStatus,
  InvoiceItem,
  InvoiceItemType,
} from 'types/plansales/billing';
import { getUtcDateDash } from 'modules/utils/time';
import { toMantissaWithDollarSign } from 'modules/utils/number';
import { getBillingMessage } from 'modules/billing/BillingLanguage';
import { planTypeLabel } from 'constants/plansales/billing';
import styled from 'styled-components';

const noShowValueInvoice = [
  InvoiceItemType.CARRY_OVER_BALANCE,
  InvoiceItemType.DOWNGRADE_SUBSCRIPTION,
];

export const invoiceItemRenderer = (rowData: InvoiceItem) => {
  const {
    invoiceItemType,
    groupPlan: { planType },
  } = rowData;
  switch (invoiceItemType) {
    case InvoiceItemType.CARRY_OVER_BALANCE:
    case InvoiceItemType.DOWNGRADE_SUBSCRIPTION:
      return getBillingMessage('text_carryover_balance'); // Carryover balance
    case InvoiceItemType.FIRST_SUBSCRIPTION:
    case InvoiceItemType.RENEW_SUBSCRIPTION:
      return planTypeLabel[planType]; // Advanced Plan / Advanced Plan
    default:
      return '';
  }
};

export const invoiceDescriptionRenderer = (
  rowData: InvoiceItem,
  invoiceStatus: InvoiceDescriptionStatus,
) => {
  const isRefunded = rowData.refunded || false;
  return (
    <>
      {description(rowData, invoiceStatus)}
      {isRefunded && (
        <>
          <br />
          {getBillingMessage('billing_payment_canceled_summary')}
        </>
      )}
    </>
  );
};

export const description = (detail: InvoiceItem, invoiceStatus: InvoiceDescriptionStatus) => {
  const {
    groupPlan: { planStartDate, planEndDate, planType },
    invoiceItemType: invoiceItemType,
  } = detail;
  const planName = planTypeLabel[planType];
  const isPaidByCreditOnly = [
    InvoiceDescriptionStatus.START_PAID_BY_ONLY_CREDIT,
    InvoiceDescriptionStatus.RENEW_PAID_BY_ONLY_CREDIT,
  ].includes(invoiceStatus);
  switch (invoiceItemType) {
    case InvoiceItemType.FIRST_SUBSCRIPTION:
      return `${getBillingMessage('billing_start_plan_summary', { planName })} ${getBillingMessage(
        'billing_plan_period',
        {
          fromDate: getUtcDateDash(planStartDate),
          toDate: getUtcDateDash(planEndDate),
        },
      )}`;
    case InvoiceItemType.RENEW_SUBSCRIPTION:
      return `${getBillingMessage('billing_renew_plan_summary', { planName })} ${getBillingMessage(
        'billing_plan_period',
        {
          fromDate: getUtcDateDash(planStartDate),
          toDate: getUtcDateDash(planEndDate),
        },
      )}`;
    case InvoiceItemType.CARRY_OVER_BALANCE:
      return isPaidByCreditOnly
        ? `${getBillingMessage('billing_deduction_credits')}`
        : `${getBillingMessage('billing_group_member_changed')} ${getBillingMessage(
            'billing_plan_period',
            {
              fromDate: getUtcDateDash(planStartDate),
              toDate: getUtcDateDash(planEndDate),
            },
          )}`;
    case InvoiceItemType.REFUND:
      return getBillingMessage('billing_payment_canceled_summary');
    case InvoiceItemType.DOWNGRADE_SUBSCRIPTION:
      return getBillingMessage('billing_clear_off_credit_summary');
    default:
      return '';
  }
};

export const invoiceQuantityRenderer = (rowData: InvoiceItem) => {
  const { invoiceItemType, quantity, refunded } = rowData;
  // carry over, downgrade 의 경우 quantity 보여주지 않음
  if (noShowValueInvoice.includes(invoiceItemType)) return '';
  return (
    <>
      {quantity}
      {refunded && <BlankBox />} {/*refund 인 경우 아래쪽에 공간두어 위치 조정*/}
    </>
  );
};

export const invoiceUnitPriceRenderer = (rowData: InvoiceItem) => {
  const { invoiceItemType, unitPrice, refunded } = rowData;
  // carry over, downgrade 의 경우 unitprice 보여주지 않음
  if (noShowValueInvoice.includes(invoiceItemType)) return '';
  return (
    <>
      {toMantissaWithDollarSign(unitPrice, 2)}
      {refunded && <BlankBox />} {/*refund 인 경우 아래쪽에 공간두어 위치 조정*/}
    </>
  );
};

export const invoiceAmountRenderer = (rowData: InvoiceItem) => {
  const { amount, refunded = false } = rowData;
  const isRefunded = refunded || false;
  return (
    <>
      {
        <ColorText color={amount < 0 ? 'red' : undefined}>
          {toMantissaWithDollarSign(amount, 2)}
        </ColorText>
      }
      {isRefunded && (
        <>
          <br />
          <ColorText color={-1 * amount < 0 ? 'red' : undefined}>
            {toMantissaWithDollarSign(-1 * amount, 2)}
          </ColorText>
        </>
      )}
    </>
  );
};

export const ColorText = styled.span<{ color?: string }>`
  color: ${({ color = 'initial' }) => color};
`;

const BlankBox = styled.div`
  height: 20px;
`;
