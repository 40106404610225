import * as api from 'api/marketplace/revenue';
import {
  selectSellerRevenueQueryString,
  sellerRevenueActions,
} from 'features/revenue/sellerRevenueSlice';
import { toastAction } from 'features/toast/toastSlice';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

const {
  initialize,
  loadDataSuccess,
  loadDataFailure,
  search,
  loadData,
  resetFilter,
  setSortType,
  pageChange,
} = sellerRevenueActions;

function* updateDataSaga() {
  yield put(loadData());
}

function* loadDataSaga() {
  const query: string = yield select(selectSellerRevenueQueryString);
  const { data, error } = yield call(api.getSellerRevenue, query);
  yield put(loadDataSuccess(data));

  if (error) {
    yield put(toastAction.errorResponse(error));
    yield put(loadDataFailure());
    return;
  }
  yield put(loadDataSuccess(data));
}

export default function* sellerRevenueSaga() {
  yield all([
    takeLatest(loadData, loadDataSaga),
    takeLatest(initialize, updateDataSaga),
    takeLatest(search, updateDataSaga),
    takeLatest(resetFilter, updateDataSaga),
    takeLatest(setSortType, updateDataSaga),
    takeLatest(pageChange, updateDataSaga),
  ]);
}
