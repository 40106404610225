import { tokenName } from 'services/config';
import { removeCookie } from 'services/session';

export const redirectTo = (target: string) => {
  window.location.href = target;
};

// export const redirectExternal = (target: string) => {
//   window.location.href = target;
// };

export const redirectSigIn = () => {
  removeCookie(tokenName);
  redirectTo(`/`);
};

