export const convertURLtoFile = async (url: string, fileName?: string): Promise<File> => {
  const response = await fetch(url);
  const data = await response.blob();
  const filename = fileName ?? url.split('/').pop();
  const metadata = { type: data.type };
  return new File([data], filename!, metadata);
};

export const convertBytesToMB = (bytes: number): number => {
  return bytes / 1000000;
};

export const formatEllipsisMiddle = (text: string, showLengthChar: number = 5): string => {
  const frontChars = showLengthChar;
  const backChars = showLengthChar;

  return text.length <= frontChars + backChars
    ? text
    : `${text.substring(0, frontChars)}...${text.substring(text.length - backChars)}`;
};
