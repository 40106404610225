import { createApi } from '@reduxjs/toolkit/query/react';
import coreApiBaseQuery from 'api/coreApiBaseQuery';
import store from 'store/configureStore';
import { toastAction } from 'features/toast/toastSlice';
import {
  EverywearDownloadFileListResponse,
  EverywearItemResponse,
} from 'types/api/marketplace/everywearItem';

export const everywearConfirmItemApi = createApi({
  reducerPath: 'everywearConfirmItemApi',
  baseQuery: coreApiBaseQuery(),
  endpoints(build) {
    return {
      getEverywearItems: build.query<EverywearItemResponse, string>({
        query: queryString => {
          return {
            url: `/connects/everywear/items${queryString}`,
            method: 'get',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      getEverywearFileList: build.query<EverywearDownloadFileListResponse, string>({
        query: itemId => {
          return {
            url: `/connects/everywear/items/${itemId}/downloads`,
            method: 'get',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      getEverywearZipDownloadUrl: build.query<string, string>({
        query: itemId => {
          return {
            url: `/connects/metaverse/items/${itemId}/downloads/zip`,
            method: 'get',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
    };
  },
});

export const {
  useLazyGetEverywearItemsQuery,
  useLazyGetEverywearFileListQuery,
  useLazyGetEverywearZipDownloadUrlQuery,
} = everywearConfirmItemApi;
