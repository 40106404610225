import api from 'api/coreApi';
import { AxiosError, AxiosResponse } from 'axios';
import {
  DeleteFeatureUpdateNoticeParams,
  EditModalParams,
  GetFeatureUpdateNoticeDetailParams,
  GetFeatureUpdateNoticeDetailResponse,
  GetFeatureUpdateNoticeParams,
  GetFeatureUpdateNoticeResponse,
} from 'types/api/notice/featureUpdateNotice';

export const getFeatureUpdateNotice = ({
  page,
  limit = 10,
  statuses,
}: GetFeatureUpdateNoticeParams) => {
  const statusParams = statuses.reduce((cur, status) => `&Statuses=${status}` + cur, '');
  return api
    .get<GetFeatureUpdateNoticeResponse>(`/notices?Page=${page}&Limit=${limit}${statusParams}`)
    .then((res: AxiosResponse<GetFeatureUpdateNoticeResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const createFeatureUpdateNotice = (data: FormData) => {
  return api
    .post(`/notices`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getFeatureUpdateNoticeDetail = ({ seq }: GetFeatureUpdateNoticeDetailParams) => {
  return api
    .get<GetFeatureUpdateNoticeDetailResponse>(`/notices/${seq}`)
    .then((res: AxiosResponse<GetFeatureUpdateNoticeDetailResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const editFeatureUpdateNotice = ({ data, seq }: EditModalParams) => {
  return api
    .put(`/notices/${seq}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};
export const deleteFeatureUpdateNotice = ({ seq }: DeleteFeatureUpdateNoticeParams) => {
  return api.delete(`/notices/${seq}`).catch((error: AxiosError<Error>) => ({ error }));
};
