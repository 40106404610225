import * as React from 'react';
import { lazy, Suspense, ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import TabContainer from 'components/common/tabs/TabContainer';
import TabBar from 'components/common/tabs/TabBar';
import Tab from 'components/common/tabs/Tab';
import { DownloadTab } from 'types/worker/downloads';
import styled from 'styled-components';
import useDownload from 'hooks/useDownload';
import { useSelector } from 'react-redux';
import { selectActualFilePath, selectSRest } from 'features/worker/reducer/downloadsSlice';
import ContentLayout from 'components/common/layouts/ContentLayout';
export const WORKER_DOWNLOADS_PATH = '/worker/downloads';
const DownloadsTabContainer = lazy(
  () => import('containers/worker/downloads/tab/DownloadsTabContainer'),
);
const FittingErrorTabContainer = lazy(
  () => import('containers/worker/downloads/tab/FittingErrorTabContainer'),
);

const TAB_LIST = [
  {
    label: 'Downloads',
    value: DownloadTab.DOWNLOADS,
    to: `${WORKER_DOWNLOADS_PATH}/${DownloadTab.DOWNLOADS}`,
  },
  {
    label: 'Fitting Errors',
    value: DownloadTab.FITTING_ERRORS,
    to: `${WORKER_DOWNLOADS_PATH}/${DownloadTab.FITTING_ERRORS}`,
  },
];

const DownloadsContainer = (): ReactElement => {
  const { tab } = useParams<{ tab: DownloadTab }>();
  const selectedTab = tab ?? DownloadTab.DOWNLOADS;
  const actualFilePath = useSelector(selectActualFilePath);
  const sRest = useSelector(selectSRest);

  const { setActualFilePath, setSRest } = useDownload();
  useEffect(() => {
    setActualFilePath(actualFilePath);
  }, [actualFilePath]);
  useEffect(() => {
    setSRest(sRest.sRestFile);
  }, [sRest.sRestFile]);

  useEffect(() => {
    setActualFilePath('');
    setSRest({
      file: null,
      fileName: '',
    });
  }, [selectedTab]);

  return (
    <ContentLayout pageTitle="Downloads">
      <TabContainerContainer>
        <TabContainer selectedTab={selectedTab}>
          <Grid container justify="space-between">
            <Grid item>
              <TabBar<DownloadTab> selectedValue={selectedTab}>
                {TAB_LIST.map((tabProps, index) => (
                  <Tab key={index} {...tabProps} />
                ))}
              </TabBar>
            </Grid>
          </Grid>
          <Suspense fallback="loading...">
            <TabContentContainer>
              {selectedTab === DownloadTab.DOWNLOADS ? (
                <DownloadsTabContainer />
              ) : (
                <FittingErrorTabContainer />
              )}
            </TabContentContainer>
          </Suspense>
        </TabContainer>
      </TabContainerContainer>
    </ContentLayout>
  );
};

export default DownloadsContainer;

const TabContainerContainer = styled.div`
  height: auto;
  width: auto;
  min-width: 656px;
  max-width: calc(100vw - 316px);
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: calc(100vw - 70px);
  }
`;

const TabContentContainer = styled.div`
  padding: 0 20px;
`;
