import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { Add as AddIcon } from '@material-ui/icons';
import { Chip, FormHelperText } from '@material-ui/core';
import styled, { css } from 'styled-components';
import Button from 'components/common/Button';
import { useDispatch } from 'react-redux';
import { toastAction } from 'features/toast/toastSlice';
import useImagePreview, { isImage } from '../../../hooks/useImagePreview';
import { ReactElement } from 'react';

interface DropzoneRootProps {
  error?: boolean;
  width?: string;
}
export interface Props extends DropzoneRootProps {
  file: File | null;
  acceptType?: string[]; // 추후 File Image를 추가하여 보완 예정. 그전까지는 직접입력하여 설정
  labelCreate?: string;
  labelReplace?: string;
  onChangeFile: (file: File | null) => void;
  helperText?: string;
  disabled?: boolean;
  errorToastAutoHide?: boolean;
}

const ACCEPT_TYPES = ['image/jpeg', 'image/png'];

/** DragDropZone
 * 기존 Marketplace의 midBanner에 위치한 component를 공통 위치에 신규추가.,(기존 component는 수정하지 않음)
 * (acceptType 속성은 추후 File Image를 추가하여 보완 예정. 그전까지는 직접등록하여 설정)
 * @param file
 * @param onChangeFile
 * @param acceptType
 * @param labelCreate
 * @param labelReplace
 * @param error
 * @param helperText
 * @param width
 * @param disabled
 * @param errorToastAutoHide
 * @constructor
 */
const DragDropZone = ({
  file,
  onChangeFile,
  acceptType = ACCEPT_TYPES,
  labelCreate = 'Create',
  labelReplace = 'Replace',
  error,
  helperText,
  disabled = false,
  width,
  errorToastAutoHide = false,
}: Props): ReactElement => {
  const dispatch = useDispatch();

  const previewDataUrl = useImagePreview(file);
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptType,
    maxFiles: 1,
    onDrop: files => {
      const [file] = files;
      onChangeFile(file ?? null);
    },
    validator: file => {
      if (file && !acceptType.includes(file.type)) {
        const message = 'Not supported Format';
        dispatch(toastAction.toastMessage({ content: message, autoHide: errorToastAutoHide }));
        return { code: 'unsupported format', message };
      }
      return null;
    },
    disabled,
  });

  const onDeleteImage = (event: React.MouseEvent): void => {
    event.stopPropagation();
    onChangeFile(null);
  };

  const showFileName = file && !isImage(file.name);

  return (
    <>
      <DropzoneRoot
        {...getRootProps()}
        error={error}
        className={classNames({ image: previewDataUrl, showFileName })}
        width={width}
      >
        <Preview preview={previewDataUrl}>
          <AddFileButton variant="outlined" color="default" disabled={disabled}>
            <AddIcon />
            {labelCreate}
          </AddFileButton>
          <ReplaceFileChipsCover>
            <ReplaceFileChip label={labelReplace} color="default" clickable={true} />
            <ReplaceFileChip
              label={'Delete'}
              color="default"
              onClick={onDeleteImage}
              clickable={true}
            />
          </ReplaceFileChipsCover>
          <input {...getInputProps()} />
          {showFileName && <FileName>{file?.name}</FileName>}
        </Preview>
      </DropzoneRoot>
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </>
  );
};

const AddFileButton = styled(Button)``;
const ReplaceFileChip = styled(Chip)``;
const ReplaceFileChipsCover = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgb(0 0 0 / 60%);
  justify-content: center;
  gap: 20px;
  z-index: 2;
`;

const Preview = styled.div<{ preview?: string }>`
  width: 100%;
  height: 100%;

  ${({ preview }) => preview && `background: url('${preview}') no-repeat center;`}
  background-size: contain;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
  line-break: anywhere;
`;

const DropzoneRoot = styled.div<DropzoneRootProps>`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  height: 240px;

  border-width: 2px;
  border-color: ${({ error, theme }) =>
    error ? theme.palette.error.main : theme.palette.grey[400]};
  border-style: dashed;

  padding: ${({ theme }) => theme.spacing(4)}px;

  &.image ${AddFileButton} {
    display: none;
  }
  &.showFileName ${AddFileButton} {
    display: none;
  }

  ${ReplaceFileChip}, ${ReplaceFileChipsCover} {
    display: none;
  }

  &:hover.showFileName ${ReplaceFileChip}, &:hover.showFileName ${ReplaceFileChipsCover} {
    display: flex;
  }

  &:hover.image ${ReplaceFileChipsCover}, &:hover.image ${ReplaceFileChip} {
    display: flex;
  }
`;

const FileName = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
`;

export default DragDropZone;
