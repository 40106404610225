import * as React from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { LinkButton } from 'components/plansales/billing/detail/ActionButtons';
import { TableCell } from 'types/common/table';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import { getUtcUsFormatDate } from 'modules/utils/time';
import { Dispatch, SetStateAction } from 'react';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';

interface Props {
  pricingPlan: any;
  setCommentHistoryModalOpen: Dispatch<SetStateAction<boolean>>;
  userId: string;
  addBackOrderDisabled: boolean;
}

const PricingTable = ({
  pricingPlan,
  setCommentHistoryModalOpen,
  userId,
  addBackOrderDisabled,
}: Props) => {
  const pricingTableColumns: TableCell<any>[] = [
    // 아직 api 나오지 않음
    { align: 'center', name: 'Plan', key: 'plan', render: rowData => rowData.plan },
    { align: 'center', name: 'Order Type', key: 'orderType', render: rowData => rowData.orderType },
    {
      align: 'center',
      name: 'Order Date',
      key: 'orderDate',
      render: rowData => getUtcUsFormatDate(rowData.orderDate),
    },
    {
      align: 'center',
      name: 'Registered Date',
      key: 'registeredDate',
      render: rowData => getUtcUsFormatDate(rowData.registeredDate),
    },
    {
      align: 'center',
      name: 'End date',
      key: 'endDate',
      render: rowData => getUtcUsFormatDate(rowData.endDate),
    },
    // {
    //   align: 'center',
    //   name: '',
    //   width: '70px',
    //   key: 'comment',
    //   render: rowData => (
    //     <Button
    //       size={'small'}
    //       startIcon={<Message color={'action'} />}
    //       onClick={() => setCommentHistoryModalOpen(true)}
    //     >
    //       5
    //     </Button>
    //   ),
    // },
  ];
  return (
    <PricingPlanTableBlock margin={'20px 0 0 0'}>
      <DataTableLayout
        tableTitle={'Pricing Plan'}
        // api 개발전이라 pagination 일단 숨김 처리 (버그처럼보임)
        showPagination={false}
        upperButton={
          <LinkButton
            to={addBackOrderDisabled ? '#' : `/member/backorder/${userId}`}
            disabled={addBackOrderDisabled}
          >
            <Button
              color={'primary'}
              variant={'contained'}
              size={'small'}
              disabled={addBackOrderDisabled}
            >
              Add Back Order
            </Button>
          </LinkButton>
        }
      >
        <DataTableHeader header={pricingTableColumns} />
        <NewDataTableBody dataList={pricingPlan} columns={pricingTableColumns} />
      </DataTableLayout>
    </PricingPlanTableBlock>
  );
};

export default PricingTable;

export const PricingPlanTableBlock = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  margin: ${({ margin = '0' }) => margin};
`;
