import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import qs, { ParsedQs } from 'qs';
import {
  MonthlyPayoutFilter,
  MonthlyPayoutModalType,
  MonthlyPayoutSortType,
  PayoutDetailModalInfo,
  SellerMonthlyPayout,
  SellerPayoutDetailInfo,
  SellerPayoutStatusInfo,
  SellerPayoutStatusParams,
  SellerStatusModalInfo,
  TotalPayoutDetailInfo,
} from 'types/marketplace/revenue';
import { ExcelMultiDataSet } from 'types/react-export-excel';
import { monthlyPayoutDataToExcelMultiDataSet } from 'modules/excelTemplate/revenue/monthlyPayout';

export interface MonthlyPayoutState {
  filter: MonthlyPayoutFilter;
  loading: boolean;
  data?: SellerMonthlyPayout;
  error: boolean;
  excelLoading: boolean;
  excelData?: SellerMonthlyPayout;
  excelError: boolean;
  statusModal?: SellerStatusModalInfo;
  detailModal?: PayoutDetailModalInfo;
  sellerPayoutStatusInfo: {
    loading: boolean;
    data?: SellerPayoutStatusInfo;
    error?: boolean;
  };
  payoutDetailInfo: {
    loading: boolean;
    sellerData?: SellerPayoutDetailInfo;
    totalData?: TotalPayoutDetailInfo;
    error?: boolean;
  };
  confirmPayoutDetailInfo: {
    loading: boolean;
  };
  executePayoutDetailInfo: {
    loading: boolean;
  };
  paypalInfo: {
    loading: boolean;
    data?: any;
    error?: boolean;
  };
}

const initialState: MonthlyPayoutState = {
  filter: {
    year: new Date().getFullYear().toString(),
    sortType: MonthlyPayoutSortType.SELLER_REVENUE_DESC,
    keyword: '',
    pageNo: 1,
  },
  loading: false,
  excelLoading: false,
  error: false,
  excelError: false,
  sellerPayoutStatusInfo: {
    loading: false,
  },
  payoutDetailInfo: {
    loading: false,
  },
  confirmPayoutDetailInfo: {
    loading: false,
  },
  executePayoutDetailInfo: {
    loading: false,
  },
  paypalInfo: {
    loading: false,
  },
};

export const monthlyPayoutSlice = createSlice({
  name: 'monthlyPayout',
  initialState: initialState,
  reducers: {
    resetFilter: state => {
      state.filter = initialState.filter;
    },
    search: (state, { payload }: PayloadAction<MonthlyPayoutFilter>) => {
      state.filter = payload;
    },
    pageChange: (state, { payload }: PayloadAction<number>) => {
      const pageNo = payload;
      if (!pageNo) {
        state.filter.pageNo = 1;
      }
      state.filter.pageNo = pageNo;
    },
    initialize: (state, { payload }: PayloadAction<ParsedQs>) => {
      const { year, keyword, sortType, pageNo } = payload;

      if (!year) {
        state.filter = initialState.filter;
      } else {
        state.filter = {
          year,
          keyword: keyword ?? '',
          sortType: sortType ?? MonthlyPayoutSortType.SELLER_REVENUE_DESC,
          pageNo: pageNo ?? 1,
        } as unknown as MonthlyPayoutFilter;
      }
    },
    loadData: state => {
      state.loading = true;
      state.data = undefined;
    },
    loadDataSuccess: (state, { payload }: PayloadAction<SellerMonthlyPayout>) => {
      state.loading = false;
      state.data = payload;
      state.error = false;
    },
    loadDataFailure: state => {
      state.loading = false;
      state.data = undefined;
      state.error = true;
    },
    setSortType: (state, { payload }: PayloadAction<MonthlyPayoutSortType>) => {
      state.filter.sortType = payload;
    },
    openSellerStatus: (state, { payload }: PayloadAction<SellerStatusModalInfo>) => {
      state.statusModal = payload;
    },
    loadSellerPayoutStatus: state => {
      state.sellerPayoutStatusInfo = {
        loading: true,
      };
    },
    loadSellerPayoutStatusSuccess: (state, { payload }: PayloadAction<SellerPayoutStatusInfo>) => {
      state.sellerPayoutStatusInfo.loading = false;
      state.sellerPayoutStatusInfo.data = payload;
    },
    loadSellerPayoutStatusFailure: state => {
      state.sellerPayoutStatusInfo.loading = false;
      state.sellerPayoutStatusInfo.error = true;
    },
    closeSellerStatus: state => {
      state.statusModal = undefined;
      state.sellerPayoutStatusInfo = {
        loading: false,
      };
    },
    changeSellerStatus: (_, _action: PayloadAction<Omit<SellerPayoutStatusParams, 'userId'>>) => {},
    putSellerStatus: state => {
      state.sellerPayoutStatusInfo = {
        loading: true,
      };
    },
    openSellerPayoutDetail: (
      state,
      { payload }: PayloadAction<{ userId: number; month: number }>,
    ) => {
      state.detailModal = {
        type: MonthlyPayoutModalType.USER,
        ...payload,
      };
    },
    openTotalPayoutDetail: (state, { payload }: PayloadAction<number>) => {
      state.detailModal = {
        type: MonthlyPayoutModalType.TOTAL,
        month: payload,
      };
    },
    closePayoutDetail: state => {
      state.detailModal = undefined;
    },
    confirmPayoutDetail: state => {
      state.confirmPayoutDetailInfo.loading = true;
    },
    confirmPayoutDetailSuccess: state => {
      state.confirmPayoutDetailInfo.loading = false;
    },
    confirmPayoutDetailFailure: state => {
      state.confirmPayoutDetailInfo.loading = false;
    },
    executePayoutDetail: state => {
      state.executePayoutDetailInfo.loading = true;
    },
    executePayoutDetailSuccess: state => {
      state.executePayoutDetailInfo.loading = false;
    },
    executePayoutDetailFailure: state => {
      state.executePayoutDetailInfo.loading = false;
    },
    resetPayoutDetail: state => {
      state.confirmPayoutDetailInfo.loading = true;
    },
    resetPayoutDetailSuccess: state => {
      state.confirmPayoutDetailInfo.loading = false;
    },
    resetPayoutDetailFailure: state => {
      state.confirmPayoutDetailInfo.loading = false;
    },
    loadSellerDetail: state => {
      state.payoutDetailInfo.loading = true;
      state.payoutDetailInfo.sellerData = undefined;
      state.payoutDetailInfo.totalData = undefined;
      state.payoutDetailInfo.error = undefined;
    },
    loadSellerDetailSuccess: (
      state,
      { payload }: PayloadAction<SellerPayoutDetailInfo | TotalPayoutDetailInfo>,
    ) => {
      state.payoutDetailInfo.loading = false;
      if (state.detailModal?.type === MonthlyPayoutModalType.USER) {
        state.payoutDetailInfo.sellerData = payload as SellerPayoutDetailInfo;
      } else {
        state.payoutDetailInfo.totalData = payload as TotalPayoutDetailInfo;
      }
    },
    loadSellerDetailFailure: state => {
      state.payoutDetailInfo.loading = false;
    },
    resetExcelData: state => {
      state.excelLoading = false;
      state.excelData = undefined;
    },
    loadExcelData: state => {
      state.excelLoading = true;
      state.excelData = undefined;
    },
    loadExcelDataSuccess: (state, { payload }: PayloadAction<SellerMonthlyPayout>) => {
      state.excelLoading = false;
      state.excelData = payload;
    },
    loadExcelDataFailure: state => {
      state.excelLoading = false;
      state.excelError = true;
    },
    resetPaypalData: state => {
      state.paypalInfo.loading = false;
      state.paypalInfo.data = undefined;
    },
    loadPaypalData: state => {
      state.paypalInfo.loading = true;
      state.paypalInfo.data = undefined;
    },
    loadPaypalDataSuccess: (state, { payload }: PayloadAction<SellerMonthlyPayout>) => {
      state.paypalInfo.loading = false;
      state.paypalInfo.data = payload;
    },
    loadPaypalDataFailure: state => {
      state.paypalInfo.loading = false;
      state.paypalInfo.error = true;
    },
  },
});

export const monthlyPayoutActions = monthlyPayoutSlice.actions;
export const monthlyPayoutReducer = monthlyPayoutSlice.reducer;

export const selectMonthlyPayoutQueryString = (state: RootState) =>
  qs.stringify(state.revenue.monthly.filter, { arrayFormat: 'repeat' });
export const selectMonthlyPayoutAllQueryString = (state: RootState) => {
  const filter = state.revenue.monthly.filter;
  // 별도의 API param 없이 해당방식으로 진행키로 BE(henry)와 협의 by hunter
  return qs.stringify({ ...filter, pageNo: 1, pageSize: 999999999 }, { arrayFormat: 'repeat' });
};
export const selectMonthlyPayoutSortType = (state: RootState) =>
  state.revenue.monthly.filter.sortType;
export const selectMonthlyPayoutFilter = (state: RootState) => state.revenue.monthly.filter;
export const selectMonthlyPayoutLoading = (state: RootState) => state.revenue.monthly.loading;
export const selectMonthlyPayoutError = (state: RootState) => state.revenue.monthly.error;
export const selectMonthlyPayoutData = (state: RootState) => state.revenue.monthly.data;
export const selectMonthlyPayoutExcelLoading = (state: RootState) =>
  state.revenue.monthly.excelLoading;
export const selectMonthlyPayoutExcelData = (state: RootState) => {
  const data = state.revenue.monthly.excelData;
  return data ? monthlyPayoutDataToExcelMultiDataSet(data) : undefined;
};
export const selectMonthlyPayoutExcelMultiDataSet = (
  state: RootState,
): ExcelMultiDataSet[] | undefined => {
  const data = state.revenue.monthly.data;
  return data ? monthlyPayoutDataToExcelMultiDataSet(data) : undefined;
};

// Status Modal
export const selectPayoutStatusEmail = (state: RootState): string =>
  state.revenue.monthly.statusModal?.email ?? '';
export const selectIsOpenedStatus = (state: RootState) =>
  state.revenue.monthly.statusModal !== undefined;
export const selectSellerStatusModal = (state: RootState) => state.revenue.monthly.statusModal;
export const selectSellerStatusInfo = (state: RootState) =>
  state.revenue.monthly.sellerPayoutStatusInfo;

// Detail Modal
export const selectIsOpenedDetail = (state: RootState) =>
  state.revenue.monthly.detailModal !== undefined;
export const selectPayoutDetailModal = (state: RootState) => state.revenue.monthly.detailModal;
export const selectPayoutDetailInfo = (state: RootState) => state.revenue.monthly.payoutDetailInfo;
export const selectConfirmPayoutDetailInfo = (state: RootState) =>
  state.revenue.monthly.confirmPayoutDetailInfo;
export const selectExecutePayoutDetailInfo = (state: RootState) =>
  state.revenue.monthly.executePayoutDetailInfo;
export const selectPaypalInfo = (state: RootState) => state.revenue.monthly.paypalInfo;
