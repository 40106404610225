import * as React from 'react';
import styled from 'styled-components';
import { Button, CircularProgress } from '@material-ui/core';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  setRefundModalOpen: Dispatch<SetStateAction<boolean>>;
  requestRefundLoading: boolean;
  userId: number;
  refundDisabled: boolean;
}

const ActionButtons = ({
  setRefundModalOpen,
  requestRefundLoading,
  userId,
  refundDisabled,
}: Props) => {
  const openRefundModal = () => {
    setRefundModalOpen(true);
  };

  return (
    <ButtonSection>
      <LinkButton to={`/member/${userId}`}>
        <Button variant={'contained'} color={'primary'} size={'small'}>
          Billing History
        </Button>
      </LinkButton>
      <RefundButton
        variant={'contained'}
        color={'primary'}
        onClick={openRefundModal}
        size={'small'}
        disabled={refundDisabled}
      >
        {requestRefundLoading ? (
          <CircularProgress size={20} thickness={5} color={'inherit'} />
        ) : (
          'Refund'
        )}
      </RefundButton>
    </ButtonSection>
  );
};

export default ActionButtons;

const RefundButton = styled(Button)`
  width: 80px;
  margin-left: ${props => props.theme.spacing(3)}px;
`;

export const ButtonSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: ${props => props.theme.spacing(3)}px;
`;

export const LinkButton = styled(Link)<{ disabled?: boolean }>`
  text-decoration: none;
  cursor: ${({ disabled = false }) => (disabled ? 'initial' : 'pointer')};
`;
