import * as React from 'react';
import { ReactElement } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import SignIn from '../pages/signin/SiginIn';
import AuthLayout from '../components/template/layouts/Auth';
import Page404 from '../pages/template/auth/Page404';

/**
 * jwt token 없이 접근 가능한 routes 는 이곳에 둔다.
 */
const PublicRoutes = (): ReactElement => {
  return (
    <BrowserRouter>
      <Switch>
        <Route from="/" to="/signin" component={SignIn} exact />
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default PublicRoutes;
