import async from 'components/template/Async';
import { RouteInfoType } from 'types/common/routes';
import { ContestDetailTabType } from 'types/marketplace/operation/contest';

export const SectionManage = async(() => import('pages/marketplace/SectionManage'));
export const MainBanner = async(() => import('pages/marketplace/mainbanner/MainBanner'));
const MainBannerForm = async(() => import('pages/marketplace/mainbanner/MainBannerForm'));
export const MidBanner = async(() => import('pages/marketplace/midbanner/List'));
const MidBannerForm = async(() => import('pages/marketplace/midbanner/Form'));
export const Contest = async(() => import('pages/marketplace/contest/Contest'));
export const ContestDetail = async(() => import('pages/marketplace/contest/ContestDetail'));
export const OperationMessage = async(() => import('pages/marketplace/OperationMessage'));

const OPERATION_PATH = '/marketplace/operation';
export const SECTION_MANAGE_PATH = `${OPERATION_PATH}/section-manager`;
export const MAIN_BANNER_PATH = `${OPERATION_PATH}/main-banner`;
export const MID_BANNER_PATH = `${OPERATION_PATH}/mid-banner`;
export const CONTEST_PATH = `${OPERATION_PATH}/contest`;
export const OPERATION_MESSAGE_PATH = `${OPERATION_PATH}/message`;

const CONTEST_DETAIL_ALLOWED_TABS = Object.values(ContestDetailTabType).join('|');

export const MidBannerFormRoutes: RouteInfoType[] = [
  {
    id: 'mid-banner-form',
    name: 'Mid Banner Form',
    path: `${MID_BANNER_PATH}/create`,
    component: MidBannerForm,
    isExternal: false,
    children: null,
  },
  {
    id: 'mid-banner-form',
    name: 'Mid Banner Form',
    path: `${MID_BANNER_PATH}/(copy|edit)/:id?`,
    component: MidBannerForm,
    isExternal: false,
    children: null,
  },
];

export const MainBannerFormRoutes: RouteInfoType[] = [
  {
    id: 'main-banner-form',
    name: 'Main Banner Form',
    path: `${MAIN_BANNER_PATH}/form/:id?`,
    component: MainBannerForm,
    isExternal: false,
    children: null,
  },
];

export const ContestRoutes: RouteInfoType[] = [
  {
    id: 'contest-detail',
    name: 'Contest Detail',
    path: `${CONTEST_PATH}/detail/:contestSeq(new|[0-9]+)/:tab(${CONTEST_DETAIL_ALLOWED_TABS})?`,
    component: ContestDetail,
    isExternal: false,
    children: null,
  },
];
