import * as React from 'react';
import { ReactElement, useEffect } from 'react';
import { mq } from '@closet-design-system/theme';
import styled from 'styled-components';
import { TypoSubTitle } from '@closet-design-system/core';
import ContestPreviewDetailTabsContainer from 'containers/marketplace/operation/contest/preview/ContestPreviewDetailTabsContainer';
import ContestDetailBanner from 'components/marketplace/operation/contest/preview/ContestDetailBanner';
import { useDispatch, useSelector } from 'react-redux';
import {
  contestActions,
  selectContentsData,
} from 'features/marketplace/operation/contest/contestSlice';
import { useParams } from 'react-router-dom';
import {
  ContestContentProps,
  ContestScheduleKeys,
  ContestTabType,
  ContestTimelineProps,
} from 'types/marketplace/operation/contest';
import { getUtcSuffixDate } from 'modules/utils/time';
import ContestPreviewTimelineContainer from 'containers/marketplace/operation/contest/preview/ContestPreviewTimelineContainer';

const ContestPreviewContainer = (): ReactElement => {
  const dispatch = useDispatch();
  const { contestSeq, tab } = useParams<ContestTabType>();
  const contents = useSelector(selectContentsData);

  const getTimelineList = (contents: ContestContentProps): ContestTimelineProps[] => {
    const scheduleKeys = Object.keys(ContestScheduleKeys).slice(0, -1);
    return scheduleKeys.map(label => {
      const scheduleKey = ContestScheduleKeys?.[label as keyof typeof ContestScheduleKeys];
      return {
        statusText: label,
        startDate: getUtcSuffixDate(contents[scheduleKey]),
      };
    });
  };

  useEffect(() => {
    dispatch(contestActions.getContest({ contestSeq: Number(contestSeq) }));
  }, []);

  return (
    <Root>
      <ContestDetailBanner
        imagePath={contents?.imagePath ?? ''}
        statusTagText={'Preview'}
        hostName={contents.hostName}
        title={contents.title}
      />
      <Content>
        <CenterLayout>
          <SpacingWrap bottom={[16, 16, 16, 24]}>
            <TypoSubTitle variant={[3, 3, 3, 2]} textTransform={'uppercase'}>
              Contest Timeline
            </TypoSubTitle>
          </SpacingWrap>
          <ContestPreviewTimelineContainer timelineList={getTimelineList(contents)} />
        </CenterLayout>
        <ContestPreviewDetailTabsContainer activeTab={tab} />
      </Content>
    </Root>
  );
};

const Root = styled.div`
  min-width: 350px;
  background: ${({ theme }) => theme.colors.GRAY_900};
`;

const Content = styled.div(
  ({
    theme: {
      breakpoints: { s, m, l, xl },
    },
  }) =>
    mq({ s, m, l, xl })({
      paddingTop: [24, 40],
      paddingBottom: 70,
      margin: '0 auto',
      maxWidth: ['unset', 688, 1024, 1200, 1440],
    }),
);

const CenterLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SpacingWrap = styled.div<{
  top?: number[] | number;
  bottom?: number[] | number;
}>(({ top, bottom }) =>
  mq()({
    marginTop: top,
    marginBottom: bottom,
  }),
);

export default ContestPreviewContainer;
