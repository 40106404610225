import * as React from 'react';
import { MuiWidth } from 'types/common';
import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { forwardRef, ReactNode, Ref } from 'react';
import { ClassNameMap } from '@material-ui/styles/withStyles';

interface Props {
  open: boolean;
  onClose: () => void;
  disableBackdropClick?: boolean;
  title?: ReactNode;
  contentDividers?: boolean;
  maxWidth?: MuiWidth;
  children?: ReactNode;
  actionButton?: ReactNode;
  classes?: ClassNameMap<string>;
  fullWidth?: boolean;
}

const BasicModal = forwardRef(
  (
    {
      open,
      onClose,
      disableBackdropClick = true,
      title,
      contentDividers = true,
      maxWidth = 'sm',
      children,
      actionButton,
      classes,
      fullWidth = true,
    }: Props,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        disableBackdropClick={disableBackdropClick}
        classes={classes}
      >
        <StyledDialogTitle>
          {title}
          <CloseButtonSpacing>
            <IconButton size={'small'} onClick={onClose}>
              <CloseIcon fontSize={'small'} />
            </IconButton>
          </CloseButtonSpacing>
        </StyledDialogTitle>
        <DialogContent ref={ref} dividers={contentDividers}>
          {children}
        </DialogContent>
        {actionButton && <DialogActions>{actionButton}</DialogActions>}
      </Dialog>
    );
  },
);
export default BasicModal;

const StyledDialogTitle = styled(DialogTitle)`
  min-height: 50px; // title 없을 경우 close icon 이 정렬 안되는 이슈 방지
`;

const CloseButtonSpacing = styled.div`
  position: absolute;
  right: ${props => props.theme.spacing(3)}px;
  top: ${props => props.theme.spacing(3)}px;
`;
