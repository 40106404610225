import React, { useEffect, useState } from 'react';
import ContentLayout from '../../components/common/layouts/ContentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BenchmarkListTable from '../../components/benchmark/BehchmarkListTable';
import {
  benchmarkActions,
  selectBenchmarkData,
  selectBenchmarkDataLoading,
} from 'features/benchmark/benchmarkSlice';
import useSearchParam from '../../hooks/useSearchParam';
import Button from '../../components/common/Button';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';

const BenchmarkContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useSearchParam();
  const { items, totalPages } = useSelector(selectBenchmarkData);
  const listLoading = useSelector(selectBenchmarkDataLoading);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(Number(query.get('page')) || 1);

  const onChangePage = (pageIndex: number) => {
    history.push(`?page=${pageIndex}`);
  };

  useEffect(() => {
    const pageNum = Number(query.get('page')) || currentPageIndex;
    setCurrentPageIndex(pageNum);
    dispatch(benchmarkActions.getBenchmarkData({ page: pageNum }));
  }, [query]);

  const copyUrl = () => {
    copy('https://style.clo-set.com/benchmark');
  };

  return (
    <ContentLayout pageTitle={'Benchmark'}>
      <COPYURL>
        <TitleBlock>Benchmarking Test Page</TitleBlock>
        <Button variant="contained" color="primary" onClick={copyUrl}>
          COPY URL
        </Button>
      </COPYURL>
      <BenchmarkListTable
        listLoading={listLoading}
        totalPages={totalPages}
        onChangePage={onChangePage}
        currentPageIndex={currentPageIndex}
        items={items}
      />
    </ContentLayout>
  );
};

export default BenchmarkContainer;

const COPYURL = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TitleBlock = styled.span`
  margin-right: 10px;
`;
