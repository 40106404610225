import { combineReducers } from '@reduxjs/toolkit';
import { netIncomeReducer } from 'features/netincome/netIncomeReducer';
import { revenueReducer } from 'features/revenue/revenueReducer';
import { toastReducer } from 'features/toast/toastSlice';
import themeReducer from './theme/themeSlice';
import authReducer from './auth/authSlice';
import marketplaceReducer from './marketplace/marketplaceSlice';
import { billingReducer } from 'features/billing/billingSlice';
import memberReducer from 'features/member/reducer';
import { benchmarkReducer } from './benchmark/benchmarkSlice';
import { confirmSellerReducer } from 'features/confirmsellerinfo/confirmSellerSlice';
import { jinnyReducer } from 'features/jinny/jinnySlice';
import featureUpdateNoticeReducer from 'features/featureupdatenotice/featureUpdateNoticeSlice';
import workerReducer from 'features/worker/reducer';
import { confirmItemApi } from 'api/marketplace/confirmItem';
import { pageSectionApi } from 'api/marketplace/operation/sectionManage';
import { everywearConfirmItemApi } from 'api/marketplace/everywearConfirmItem';
import { contestReducer } from 'features/marketplace/operation/contest/contestSlice';
import { contestAwardReducer } from 'features/marketplace/operation/contest/contestAwardSlice';
import { orderApi } from 'api/marketplace/order';
import { communityApi } from 'api/marketplace/community';
import { operationMessageApi } from '../api/marketplace/operation/message';

export const rootReducer = combineReducers({
  theme: themeReducer,
  auth: authReducer,
  marketplace: marketplaceReducer,
  billing: billingReducer,
  member: memberReducer,
  benchmark: benchmarkReducer,
  revenue: revenueReducer,
  netIncome: netIncomeReducer,
  toast: toastReducer,
  confirmSeller: confirmSellerReducer,
  jinny: jinnyReducer,
  featureUpdateNotice: featureUpdateNoticeReducer,
  worker: workerReducer,
  contest: contestReducer,
  contestAward: contestAwardReducer,
  // redux toolkit
  [confirmItemApi.reducerPath]: confirmItemApi.reducer,
  [pageSectionApi.reducerPath]: pageSectionApi.reducer,
  [everywearConfirmItemApi.reducerPath]: everywearConfirmItemApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [communityApi.reducerPath]: communityApi.reducer,
  [operationMessageApi.reducerPath]: operationMessageApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
