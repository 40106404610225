import * as React from 'react';
import { RouteInfoType } from 'types/common/routes';
import async from '../components/template/Async';

const Buttons = async(() => import('../pages/example/Buttons'));
const Tabs = async(() => import('../pages/example/Tabs'));

const ExampleRoutes: RouteInfoType = {
  id: 'example',
  path: '/example',
  name: 'example',
  component: null,
  children: [
    {
      name: 'buttons',
      path: '/example/buttons',
      component: Buttons,
    },
    {
      name: 'tabs',
      path: '/example/tabs/:tab?',
      component: Tabs,
    },
  ],
};

export const exampleRoutes: RouteInfoType[] = [ExampleRoutes];
