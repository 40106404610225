import {
  FormControl as MuiFormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select as MuiSelect,
  SelectClassKey,
} from '@material-ui/core';
import { spacing, SpacingProps } from '@material-ui/system';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { SelectOption, SelectValue } from 'types/common/select';
import _uniqueId from 'lodash/uniqueId';
import { ClassNameMap } from '@material-ui/styles/withStyles';

interface Props extends SpacingProps {
  variant?: 'standard' | 'outlined' | 'filled';
  value: SelectValue;
  onChange?: (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode,
  ) => void;
  options: SelectOption[];
  width?: string;
  minWidth?: string;
  title?: string;
  classes?: Partial<ClassNameMap<SelectClassKey>>;
}

const SELECT_ID_PREFIX = 'select';

const Select = ({ value, onChange, options, width, title, variant, classes, ...rest }: Props) => {
  // rest = spacing props
  const [selectId] = useState<string>(_uniqueId(SELECT_ID_PREFIX)); // select_123
  const [labelWidth, setLabelWidth] = useState<number>(0);
  const labelRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    setLabelWidth(labelRef.current?.offsetWidth ?? 0);
  }, [labelRef.current]);

  return (
    <FormControl variant={variant} width={width} {...rest}>
      {title && (
        <InputLabel ref={labelRef} htmlFor={selectId}>
          {title}
        </InputLabel>
      )}
      <MuiSelect
        value={value}
        onChange={onChange}
        input={
          variant === 'outlined' ? (
            <StyledOutlinedInput labelWidth={labelWidth} id={selectId} />
          ) : undefined
        }
        inputProps={{ id: selectId }}
        classes={classes}
      >
        {options.map(({ value, label }: SelectOption) => (
          <MenuItem value={value} key={value}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)<{ width?: string }>`
  width: ${props => props.width};
`;
const StyledOutlinedInput = styled(OutlinedInput)`
  .MuiOutlinedInput-input {
    padding: 10px 14px;
  }
`;

export default Select;
