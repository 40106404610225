import * as React from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import RequiredMark from 'components/common/RequiredMark';
import {
  featureUpdateNoticeTargetSpaceLabel,
  targetSpaces,
} from 'constants/notice/featureUpdateNotice';
import { FeatureUpdateNoticeTargetSpace } from 'types/notice/featureUpdateNotice';

interface Props {
  selectedTargetModalSpace: FeatureUpdateNoticeTargetSpace;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

const FeatureUpdateNoticeFormTargetSpace = ({ selectedTargetModalSpace, onChange }: Props) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Target Modal Space
        <RequiredMark />
      </Typography>
      <RadioGroup
        aria-label="TargetSpace"
        name="target-space"
        value={selectedTargetModalSpace}
        onChange={onChange}
        row
      >
        {targetSpaces.map(targetSpaces => (
          <FormControlLabel
            key={targetSpaces}
            value={targetSpaces}
            control={<Radio />}
            label={featureUpdateNoticeTargetSpaceLabel[targetSpaces]}
          />
        ))}
      </RadioGroup>
    </>
  );
};

export default FeatureUpdateNoticeFormTargetSpace;
