import * as React from 'react';
import { createStyles, FormControl, makeStyles, Select } from '@material-ui/core';
import { ReactText } from 'react';
import { SelectOption } from 'types/common/select';
import { MenuItemWrapper } from 'components/common/searchfilter/MultipleSelectFilter';
import classNames from 'classnames';

interface Props<T> {
  currentValue: number;
  onChangeValue: (e: React.ChangeEvent<{ label?: string; value: unknown }>) => void;
  optionList: SelectOption<T>[];
}
const StatusDropdown = <T extends ReactText>({
  currentValue,
  onChangeValue,
  optionList,
}: Props<T>) => {
  const classes = makeStyles(() =>
    createStyles({
      formControl: {
        minWidth: 130,
      },
    }),
  )();

  return (
    <FormControl className={classes.formControl}>
      <Select value={currentValue} onChange={onChangeValue}>
        {optionList.map(({ value, label, disabled, divider }: SelectOption<T>) => (
          <MenuItemWrapper
            className={classNames({
              [`divider-${divider}`]: divider,
            })}
            key={value}
            value={value}
            disabled={disabled}
          >
            {label}
          </MenuItemWrapper>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusDropdown;
