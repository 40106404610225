import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';

interface Props {
  url: string;
  openNewTab: boolean;
  children: ReactNode;
}

const LinkLayout = ({ url, openNewTab, children }: Props): ReactElement => {
  return (
    <BlockLink href={url} target={openNewTab ? '_blank' : '_self'} rel="noreferrer noopener">
      {children}
    </BlockLink>
  );
};

export default LinkLayout;

const BlockLink = styled.a`
  text-decoration: none;
`;
