import * as React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { PaymentStatus } from 'types/plansales/billing';
import styled from 'styled-components';
import { paymentStatusLabel } from 'constants/plansales/billing';
import { CheckObjectValue } from 'types/common';

interface Props {
  payStatus: CheckObjectValue;
  onChangePayStatus: (status: PaymentStatus) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PayStatusFilters = ({ payStatus, onChangePayStatus }: Props) => {
  const payStatusCheckboxes: PaymentStatus[] = [
    PaymentStatus.SUCCESS,
    PaymentStatus.REFUND,
    PaymentStatus.TRIED_YET,
    PaymentStatus.VOID,
  ];
  return (
    <>
      {payStatusCheckboxes.map(status => (
        <FormControlLabel
          key={status}
          control={
            <Checkbox
              value={status}
              color={'primary'}
              checked={payStatus[status]}
              onChange={onChangePayStatus(status)}
            />
          }
          label={<LabelText>{paymentStatusLabel[status]}</LabelText>}
        />
      ))}
    </>
  );
};

export default PayStatusFilters;

export const LabelText = styled.span`
  text-transform: capitalize;
`;
