import * as React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

interface Props {
  colspan: number;
  description?: string;
}

const NoResultRow = ({ colspan, description = 'No Result' }: Props) => {
  return (
    <TableRow>
      <TableCell align={'center'} colSpan={colspan}>
        {description}
      </TableCell>
    </TableRow>
  );
};

export default NoResultRow;
