import * as React from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FormGroup, Grid, TextField, TextFieldProps, Typography } from '@material-ui/core';
import { getShortDateByTimezone } from 'modules/utils/time';
import styled from 'styled-components';
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import { timezones } from 'constants/common/time';

interface Props {
  date: Date;
  onChange: (date: Date) => void;
  error?: boolean;
  helperText?: string;
  useTimezoneText?: boolean;
  direction?: 'row' | 'column';
}

const DateTimePicker = ({
  date,
  onChange,
  error,
  helperText,
  useTimezoneText,
  direction = 'row',
}: Props) => {
  const handleOnChange = (date: MaterialUiPickersDate) => date && onChange(date);

  return (
    <Grid container direction={direction} alignItems={'center'} spacing={2}>
      <Grid item>
        <FormGroup>
          <MuiDateTimePicker
            value={date}
            inputVariant="outlined"
            format={'P p'}
            onChange={handleOnChange}
            minutesStep={10}
            TextFieldComponent={DateInput}
            error={error}
            helperText={error && helperText}
          />
        </FormGroup>
      </Grid>
      {useTimezoneText && (
        <Grid item>
          <Typography variant="caption">
            {timezones.map(({ label, timezone }) => (
              <TimeZoneSpacer key={label}>
                [{label}] {getShortDateByTimezone(date, timezone)}
              </TimeZoneSpacer>
            ))}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const DateInput = (props: TextFieldProps) => (
  <TextField {...props} variant="outlined" size="small" />
);

const TimeZoneSpacer = styled.span`
  & + & {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export default DateTimePicker;
