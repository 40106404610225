import * as React from 'react';
import { toMantissaWithDollarSign } from 'modules/utils/number';
import { NULL_TEXT } from 'constants/common';
import styled, { css } from 'styled-components';
import InformationTable from 'components/common/table/InformationTable';
import { InformationObject } from 'types/common/informationTable';

interface Props {
  subTotalAmount: number;
  totalAmount: number;
  taxAmount: number;
  isRefunded: boolean;
  isPending: boolean;
}

const PaymentInformation = ({
  subTotalAmount,
  totalAmount,
  taxAmount,
  isRefunded,
  isPending,
}: Props) => {
  const isPaidByCreditOnly = false;

  const pendingCheck = (value: string) => {
    return isPending ? NULL_TEXT : value;
  };

  const creditPayment = (price: number) => {
    return isPaidByCreditOnly ? 0 : price;
  };

  const paymentInformation: InformationObject[] = [
    {
      title: 'Sub Total',
      render: (
        <PriceText isRefunded={isRefunded}>
          {pendingCheck(toMantissaWithDollarSign(creditPayment(subTotalAmount), 2))}
        </PriceText>
      ),
      textAlign: 'right',
      titleWidth: '30%',
    },
    {
      title: 'Tax',
      render: (
        <PriceText isRefunded={isRefunded}>
          {pendingCheck(toMantissaWithDollarSign(creditPayment(taxAmount), 2))}
        </PriceText>
      ),
      textAlign: 'right',
      titleWidth: '30%',
    },
    {
      title: 'Payment Price',
      render: (
        <PriceText isRefunded={isRefunded}>
          {pendingCheck(toMantissaWithDollarSign(creditPayment(totalAmount), 2))}
        </PriceText>
      ),
      textAlign: 'right',
      titleWidth: '30%',
    },
  ];

  return <InformationTable title={'Payment'} data={paymentInformation} />;
};

export default PaymentInformation;

const PriceText = styled.span<{ isRefunded?: boolean }>`
  ${props =>
    props.isRefunded &&
    css`
      text-decoration: line-through red;
    `};
`;
