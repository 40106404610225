import {
  ConfirmItemKeywordType,
  ConfirmItemSearchParams,
  ConfirmStatus,
  ConvertingFailStatus,
  DigitalStampFilterStatus,
  DigitalStampStatuses,
  ItemType,
  ItemTypeSubcategory,
  Software,
  Sort,
  StatusSelectOption,
} from 'types/marketplace/confirmItem';
import { getUtcDateDash, getUtcDateDashDifference } from 'modules/utils/time';
import { SearchDateTerms } from 'types/common/searchfilter';
import { ContestOngoingFilterType } from 'types/marketplace/operation/contest';
import { connectSubDomain } from 'services/config';

export const liveSelectOptions: StatusSelectOption[] = [
  {
    label: 'Published',
    value: `${ConfirmStatus.PUBLISHED}`,
  },
  {
    label: 'Rejected',
    value: `${ConfirmStatus.REJECTED}`,
    isCommentRequired: true,
    isReasonRequired: true,
  },
  {
    label: 'Withdraw',
    value: `${ConfirmStatus.WITHDRAW}`,
    isCommentRequired: true,
    isReasonRequired: true,
  },
  {
    label: 'Blocked',
    value: `${ConfirmStatus.BLOCKED}`,
    isCommentRequired: true,
    isReasonRequired: true,
  },
];

export const requestSelectOptions: StatusSelectOption[] = [
  {
    label: 'Pending',
    value: `${ConfirmStatus.PENDING}`,
  },
  {
    label: 'Checking',
    value: `${ConfirmStatus.CHECKING}`,
  },
  {
    label: 'Published',
    value: `${ConfirmStatus.PUBLISHED}`,
  },
  {
    label: 'Rejected',
    value: `${ConfirmStatus.REJECTED}`,
    isCommentRequired: true,
    isReasonRequired: true,
  },
];

export const DEFAULT_SEARCH_RESULTS_LIMIT = 40;

export const initialSearchParams: ConfirmItemSearchParams = {
  dateFrom: getUtcDateDashDifference(new Date(), 6, 'month'),
  dateTo: getUtcDateDash(new Date()),
  dateTerm: SearchDateTerms['6_MONTHS'],
  statuses: [
    ConfirmStatus.PENDING,
    ConfirmStatus.CHECKING,
    ConfirmStatus.PUBLISHED,
    ConfirmStatus.REJECTED,
  ],
  itemTypes: [],
  salesTypes: [],
  softwares: [],
  digitalStampStatuses: [],
  mainPages: [],
  contestFilter: [String(ContestOngoingFilterType.NONE), String(ContestOngoingFilterType.CLOSED)],
  convertingStatuses: [],
  mdPick: false,
  keyword: '',
  keywordType: ConfirmItemKeywordType.ItemName,
  page: 1,
  sort: Sort.LATEST_UPDATE,
  limit: DEFAULT_SEARCH_RESULTS_LIMIT,
};

export const ConvertingStatusFilterLabels = {
  [ConvertingFailStatus.ZIP_FAILED]: 'Zip Failed',
  [ConvertingFailStatus.SREST_FAILED]: 'SRest Failed',
  [ConvertingFailStatus.STANDARD_FORMAT_FAILED]: 'Standard Format Failed',
};

export const DigitalStampFilterLabels = {
  [DigitalStampFilterStatus.MINTED]: 'Minted',
  [DigitalStampFilterStatus.FAILED]: 'Failed',
};

export const DigitalStampCheckedGroup = {
  [DigitalStampFilterStatus.MINTED]: [DigitalStampStatuses.SUCCESS],
  [DigitalStampFilterStatus.FAILED]: [
    DigitalStampStatuses.UNKNOWN_FAILED,
    DigitalStampStatuses.MINTING_FAILED,
    DigitalStampStatuses.TRANSFER_FAILED,
    DigitalStampStatuses.EDIT_METADATA_FAILED,
  ],
};

export const DigitalStampStatusLabels = {
  [DigitalStampStatuses.UNKNOWN_FAILED]: 'Failed Unknown',
  [DigitalStampStatuses.MINTING_FAILED]: 'Failed Minting',
  [DigitalStampStatuses.TRANSFER_FAILED]: 'Failed Transfer',
  [DigitalStampStatuses.EDIT_METADATA_FAILED]: 'Failed Edit Metadata',
  [DigitalStampStatuses.NONE]: '',
  [DigitalStampStatuses.IN_PROGRESS]: 'Minting',
  [DigitalStampStatuses.SUCCESS]: 'Minted',
};

export const SoftwareLabels = {
  [Software.CLO]: 'CLO',
  [Software.MD]: 'MD',
  [Software.JINNY]: 'Jinny',
  [Software.OTHERS]: 'Others',
};

export const itemTypeFilterValues: {
  mainCategory: ItemType;
  subcategories: ItemTypeSubcategory[];
}[] = [
  { mainCategory: ItemType.GARMENT, subcategories: [] },
  { mainCategory: ItemType.FABRIC, subcategories: [] },
  {
    mainCategory: ItemType.TRIM,
    subcategories: [
      ItemTypeSubcategory.BUTTONS,
      ItemTypeSubcategory.FASTENERS,
      ItemTypeSubcategory.ZIPPERS,
      ItemTypeSubcategory.HARDWARES,
      ItemTypeSubcategory.NOTIONS,
    ],
  },
  {
    mainCategory: ItemType.AVATAR,
    subcategories: [
      ItemTypeSubcategory.MODEL,
      ItemTypeSubcategory.MANNEQUIN,
      ItemTypeSubcategory.MOTION,
      ItemTypeSubcategory.POSE,
      ItemTypeSubcategory.HAND_POSE,
      ItemTypeSubcategory.HAIR,
      ItemTypeSubcategory.SHOES,
      ItemTypeSubcategory.GLASSES,
      ItemTypeSubcategory.EARRING,
    ],
  },
  {
    mainCategory: ItemType.SCENE,
    subcategories: [],
  },
  {
    mainCategory: ItemType.EVERYWEAR,
    subcategories: [],
  },
];

export const avatarSubcategories = itemTypeFilterValues[3].subcategories;

/* If queryParam of search purpose is Array type, declare it here */
export const ARRAY_VALUE_KEYS = [
  'statuses',
  'salesTypes',
  'itemTypes',
  'mainPages',
  'softwares',
  'digitalStampStatuses',
  'contestFilter',
  'convertingStatuses',
];

export const CONNECT_STORE_DETAIL_URL = `https://${connectSubDomain}.clo-set.com/detail`;

export const CATEGORY_GARMENT_INDEX = 0;
export const GarmentCategoryIndex = {
  GENDER: 0,
  STYLE: 1,
  GENRE_MOODS: 2,
} as const;
export const StyleSubcategory = {
  SINGLE: 'SIGLE',
  OUTFIT: 'OUTFT',
} as const;
export const CATEGORY_VALUE_DIVIDER = '|';

export const CATEGORY_GENDER_AGE_LIMIT = 2;
export const CATEGORY_GENRE_MOOD_LIMIT = 2;
export const SINGLE_CATEGORY_LIMITS = [
  {
    categoryId: 'STSHT',
    name: 'T-shirt',
    limit: 2,
  },
  {
    categoryId: 'SSTBS',
    name: 'Shirts / Blouse',
    limit: 1,
  },
  {
    categoryId: 'SSWTR',
    name: 'Sweater',
    limit: 1,
  },
  {
    categoryId: 'SVTCD',
    name: 'Vest / Cardigan',
    limit: 1,
  },
  {
    categoryId: 'SJUPR',
    name: 'Jumper',
    limit: 2,
  },
  {
    categoryId: 'SJKCT',
    name: 'Jacket / Coat',
    limit: 2,
  },
  {
    categoryId: 'SPNTS',
    name: 'Pants',
    limit: 2,
  },
  {
    categoryId: 'SSKTS',
    name: 'Skirt',
    limit: 2,
  },
  {
    categoryId: 'SDSJS',
    name: 'Dresses / Jumpsuits',
    limit: 2,
  },
  {
    categoryId: 'SITSW',
    name: 'Intimates / Swimwear',
    limit: 2,
  },
  {
    categoryId: 'SACC1',
    name: 'ACC',
    limit: 2,
  },
  {
    categoryId: 'SOTHS',
    name: 'Others',
    limit: 1,
  },
] as const;

export const confirmItemKeywordTypeOptions = [
  {
    label: 'Account',
    value: ConfirmItemKeywordType.Account,
  },
  {
    label: 'Creator Name',
    value: ConfirmItemKeywordType.CreatorName,
  },
  {
    label: 'Item Number',
    value: ConfirmItemKeywordType.ItemNumber,
  },
  {
    label: 'Item Name',
    value: ConfirmItemKeywordType.ItemName,
  },
  {
    label: 'Item ID',
    value: ConfirmItemKeywordType.ItemId,
  },
];

export const IGNORE_LETTERS = `\\/:*?"'<>|.`;

export const ROWS_SELECT_MENUS = [
  {
    label: '8',
    value: 8,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: `${DEFAULT_SEARCH_RESULTS_LIMIT}`,
    value: DEFAULT_SEARCH_RESULTS_LIMIT,
  },
];
