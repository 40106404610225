import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RootState } from 'features/rootReducer';
import { ReactNode } from 'react';
import { ToastType } from 'types/common/toast';

interface State {
  open: boolean;
  type?: ToastType;
  content?: ReactNode;
  autoHide?: boolean;
}

const initialState: State = {
  open: false,
  autoHide: false,
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    toastMessage: (
      state,
      {
        payload: { content, autoHide = false, toastType = ToastType.ERROR },
      }: PayloadAction<{ content: string; autoHide?: boolean; toastType?: ToastType }>,
    ) => {
      state.type = toastType;
      state.open = true;
      state.content = content;
      state.autoHide = autoHide;
    },
    errorResponse: (state, { payload: { response } }: PayloadAction<AxiosError>) => {
      state.type = ToastType.ERROR;
      state.open = true;

      if (!response) {
        state.content = 'Internal Error: No response';
        return;
      }
      if (!response.data?.message) {
        state.content = 'Internal Error';
        return;
      }

      state.content = response.data.message;
    },
    close: state => {
      state.open = false;
      state.autoHide = false;
    },
  },
});

export const toastAction = toastSlice.actions;
export const toastReducer = toastSlice.reducer;

export const selectToastOpen = ({ toast: { open } }: RootState): boolean => open;
export const selectToastType = ({ toast: { type } }: RootState): ToastType | undefined => type;
export const selectToastContent = ({ toast: { content } }: RootState): ReactNode | undefined =>
  content;
export const selectToastAutoHide = ({ toast: { autoHide } }: RootState): boolean | undefined =>
  autoHide;
