import * as React from 'react';
import { Table, TableBody, TableRow, TextField, Button } from '@material-ui/core';
import styled from 'styled-components';
import { NoBorderCell } from 'components/common/table/TableStyle';
import { NULL_TEXT } from 'constants/common';
import { getUtcAndKstTime } from 'modules/billing';
import BasicModal from 'components/common/modal/BasicModal';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { billingActions } from 'features/billing/billingSlice';
import { useDispatch } from 'react-redux';
import { RefundInformation } from 'types/plansales/billing/invoice';
import { Capitalize } from 'components/plansales/billing/detail/information/InvoiceInformation';
import { defaultRefundInformation } from 'containers/plansales/billing/LegacyBillingDetailContainer';
import { getUserNameFromEmail } from 'modules/utils/user';

interface Props {
  refundModalOpen: boolean;
  isRefunded: boolean;
  paymentRefundDate: string;
  loginUserName: string;
  setRefundModalOpen: Dispatch<SetStateAction<boolean>>;
  refundErrorModalOpen: boolean;
  invoiceNo: string;
  refundInformation: RefundInformation | null;
}

const RefundModal = ({
  refundModalOpen,
  isRefunded,
  paymentRefundDate,
  loginUserName,
  setRefundModalOpen,
  refundErrorModalOpen,
  invoiceNo,
  refundInformation,
}: Props) => {
  const dispatch = useDispatch();
  const { comment, registeredBy } = refundInformation ?? defaultRefundInformation;
  const [refundComment, setRefundComment] = useState<string>(comment);

  useEffect(() => {
    if (refundErrorModalOpen) setRefundComment('');
  }, [refundErrorModalOpen]);

  useEffect(() => {
    setRefundComment(comment);
  }, [paymentRefundDate]);

  const closeRefundModal = () => {
    setRefundModalOpen(false);
  };

  const submitRefund = () => {
    dispatch(billingActions.requestRefund({ comment: refundComment, invoiceNo }));
    closeRefundModal();
  };

  const onChangeRefundComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRefundComment(e.target.value);
  };

  return (
    <BasicModal
      open={refundModalOpen}
      onClose={closeRefundModal}
      title={'Refund'}
      actionButton={
        <>
          <Button variant={'text'} color={'primary'} onClick={closeRefundModal}>
            Cancel
          </Button>
          {!isRefunded && (
            <Button
              variant={'text'}
              color={'primary'}
              onClick={submitRefund}
              disabled={refundComment.trim().length === 0}
            >
              Confirm
            </Button>
          )}
        </>
      }
    >
      <Table size={'small'}>
        <TableBody>
          <TableRow>
            <NoBorderCell>
              <BoldText>
                Comment <RedPoint>*</RedPoint>
              </BoldText>
            </NoBorderCell>
            <NoBorderCell>
              <TextField
                fullWidth
                multiline
                rows={5}
                variant={'outlined'}
                disabled={isRefunded}
                autoFocus
                value={refundComment}
                onChange={onChangeRefundComment}
                placeholder={'Please enter the reason for the refund'}
                inputProps={{
                  maxLength: 500,
                }}
              />
              <LetterCounter>{`(${refundComment.length}/500)`}</LetterCounter>
            </NoBorderCell>
          </TableRow>
          <TableRow>
            <NoBorderCell>
              <BoldText>Refunded Date</BoldText>
            </NoBorderCell>
            <NoBorderCell>
              {isRefunded && paymentRefundDate ? getUtcAndKstTime(paymentRefundDate) : NULL_TEXT}
            </NoBorderCell>
          </TableRow>
          <TableRow>
            <NoBorderCell>
              <BoldText>Confirmed</BoldText>
            </NoBorderCell>
            <NoBorderCell>
              {paymentRefundDate ? (
                <Capitalize>{getUserNameFromEmail(registeredBy)}</Capitalize>
              ) : (
                loginUserName
              )}
            </NoBorderCell>
          </TableRow>
        </TableBody>
      </Table>
    </BasicModal>
  );
};

export default RefundModal;

const RedPoint = styled.span`
  color: red;
`;

const BoldText = styled.span`
  font-weight: 600;
`;

const LetterCounter = styled.div`
  margin-top: 5px;
  float: right;
  color: #292929;
`;
