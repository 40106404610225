import * as React from 'react';
import { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import DragDropZone from 'components/common/form/DragDropZone';
import InputText from 'components/common/form/InputText';
import styled from 'styled-components';
import Button from 'components/common/Button';
import {
  FeatureUpdateNoticeColorType,
  FeatureUpdateNoticeModalFormData,
  FeatureUpdateNoticeModalType,
} from 'types/notice/featureUpdateNotice';
import { REQUIRED_HELP_TEXT } from 'constants/notice/featureUpdateNotice';
import FeatureUpdateNoticePreviewModal from '../FeatureUpdateNoticePreviewModal';
import { InputContentType } from 'types/common/input';

interface Props {
  typeTitle: string;
  selectedModalType: FeatureUpdateNoticeModalType;
  data: FeatureUpdateNoticeModalFormData;
  onChange: (data: FeatureUpdateNoticeModalFormData) => void;
  imageValidation: boolean;
  titleValidation: boolean;
  linkButton: string | null;
  useLinkButton: boolean;
  selectedColorType: FeatureUpdateNoticeColorType;
}

const OPTIMIZE_SIZE: { [key in FeatureUpdateNoticeModalType]: string } = {
  [FeatureUpdateNoticeModalType.NONE]: 'none',
  [FeatureUpdateNoticeModalType.TEXT]: 'none',
  [FeatureUpdateNoticeModalType.IMAGE]: '700 * 390',
  [FeatureUpdateNoticeModalType.IMAGE_TEXT]: '200 * 200',
};

const FeatureUpdateNoticeFormModalContent = ({
  typeTitle,
  selectedModalType,
  data,
  onChange,
  imageValidation,
  titleValidation,
  linkButton,
  useLinkButton,
  selectedColorType,
}: Props) => {
  const textDisabled = selectedModalType === FeatureUpdateNoticeModalType.IMAGE;
  const imageDisabled = selectedModalType === FeatureUpdateNoticeModalType.TEXT;
  const { imageUrl, title, subTitle, footNote, body } = data;
  const [isPreviewModalOpen, setPreviewModalOpen] = useState<boolean>(false);

  const onChangeImage = (file: File | null) => {
    onChange({
      ...data,
      imageUrl: file,
    });
  };

  const onChangeText = (type: InputContentType) => (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...data,
      [type]: evt.target.value,
    });
  };

  const inputs: {
    placeholder: string;
    value: string;
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    helperText?: string;
    error?: boolean;
    useMultiline: boolean;
  }[] = [
    {
      placeholder: 'Please enter a title',
      value: title ?? '',
      onChange: onChangeText(InputContentType.TITLE),
      helperText: REQUIRED_HELP_TEXT,
      error: titleValidation,
      useMultiline: false,
    },
    {
      placeholder: 'Please enter a subtitle',
      value: subTitle ?? '',
      onChange: onChangeText(InputContentType.SUBTITLE),
      useMultiline: true,
    },
    {
      placeholder: 'Please enter a body',
      value: body ?? '',
      onChange: onChangeText(InputContentType.BODY),
      useMultiline: true,
    },
    {
      placeholder: 'Please enter a footnote',
      value: footNote ?? '',
      onChange: onChangeText(InputContentType.FOOTNOTE),
      useMultiline: false,
    },
  ];

  return (
    <>
      <Grid container justify={'space-between'} alignItems={'center'}>
        <Typography variant="subtitle2">{typeTitle}</Typography>
        <Button variant="outlined" color="default" onClick={() => setPreviewModalOpen(true)}>
          Preview
        </Button>
      </Grid>
      <Wrapper>
        {!imageDisabled && (
          <Typography variant="caption">
            (Optimal Image Size {OPTIMIZE_SIZE[selectedModalType]})
          </Typography>
        )}
        <DragDropZone
          file={imageUrl}
          onChangeFile={onChangeImage}
          labelCreate={'Upload Image'}
          disabled={imageDisabled}
          helperText={REQUIRED_HELP_TEXT}
          error={imageValidation}
          acceptType={['image/jpeg']}
        />
        {inputs.map((input, index) => (
          <InputText
            key={index}
            disabled={textDisabled}
            multiline={input.useMultiline}
            {...input}
          />
        ))}
      </Wrapper>
      <FeatureUpdateNoticePreviewModal
        open={isPreviewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
        modalType={selectedModalType}
        data={data}
        linkButton={linkButton}
        useLinkButton={useLinkButton}
        colorType={selectedColorType}
      />
    </>
  );
};

export default FeatureUpdateNoticeFormModalContent;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;
