import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import { parseStoreQueryString } from 'modules/revenue';
import { getDefaultDashFormatInterval } from 'modules/utils/time';
import qs, { ParsedQs } from 'qs';
import { StoreType } from 'types/common';
import { RevenueSortType, SellerRevenueData, SellerRevenueFilter } from 'types/marketplace/revenue';
import { ExcelMultiDataSet } from 'types/react-export-excel';
import { sellerRevenueDataToExcelMultiDataSet } from 'modules/excelTemplate/revenue/sellerRevenue';

export interface SellerRevenueState {
  filter: SellerRevenueFilter;
  sellerListData: {
    data?: SellerRevenueData;
    loading: boolean;
    error?: boolean;
  };
}

const [startDate, endDate] = getDefaultDashFormatInterval({
  gap: 0,
  unit: 'month',
  options: { useFirstDay: true },
});

const initialState: SellerRevenueState = {
  filter: {
    startDate,
    endDate,
    keyword: '',
    store: [StoreType.CONNECT, StoreType.MD],
    sortType: RevenueSortType.SELLER_REVENUE_DESC,
    // userId는 container에서 넣어줌.
    pageNo: 1,
    pageSize: 100,
  },
  sellerListData: {
    loading: false,
  },
};

export const sellerRevenueSlice = createSlice({
  name: 'revenueSeller',
  initialState: initialState,
  reducers: {
    resetFilter: (state: SellerRevenueState) => {
      state.filter = initialState.filter;
    },
    search: (state: SellerRevenueState, { payload }: PayloadAction<SellerRevenueFilter>) => {
      state.filter = payload;
    },
    pageChange: (state: SellerRevenueState, { payload: pageNo }: PayloadAction<number>) => {
      if (!pageNo) {
        state.filter.pageNo = 1;
      }
      state.filter.pageNo = pageNo;
    },
    initialize: (state: SellerRevenueState, { payload }: PayloadAction<ParsedQs>) => {
      const { startDate, endDate, store, keyword, sortType } = payload;

      if (!startDate || !endDate) {
        state.filter = initialState.filter;
      } else {
        state.filter = {
          startDate,
          endDate,
          store: parseStoreQueryString(store, initialState.filter.store),
          keyword: keyword ?? initialState.filter.keyword,
          sortType: sortType !== undefined ? Number(sortType) : initialState.filter.sortType,
        } as unknown as SellerRevenueFilter;
      }
    },
    setSortType: (state: SellerRevenueState, { payload }: PayloadAction<RevenueSortType>) => {
      state.filter.sortType = payload;
    },
    loadData: (state: SellerRevenueState) => {
      state.sellerListData = { loading: true };
    },
    loadDataSuccess: (state: SellerRevenueState, { payload }: PayloadAction<SellerRevenueData>) => {
      state.sellerListData = { loading: false, data: payload };
    },
    loadDataFailure: (state: SellerRevenueState) => {
      state.sellerListData = { loading: false, error: true };
    },
  },
});

export const sellerRevenueActions = sellerRevenueSlice.actions;
export const sellerRevenueReducer = sellerRevenueSlice.reducer;

export const selectSellerRevenueError = (state: RootState) =>
  state.revenue.seller.sellerListData.error;
export const selectSellerRevenueIsLoading = (state: RootState) =>
  state.revenue.seller.sellerListData.loading;
export const selectSellerRevenueFilter = (state: RootState) => state.revenue.seller.filter;
export const selectSellerRevenueQueryString = (state: RootState): string =>
  qs.stringify(state.revenue.seller.filter, { arrayFormat: 'repeat' });
export const selectSellerRevenueData = (state: RootState) =>
  state.revenue.seller.sellerListData.data;
export const selectExcelMultiDataSet = (state: RootState): ExcelMultiDataSet[] | undefined => {
  const data = state.revenue.seller.sellerListData.data;
  return data ? sellerRevenueDataToExcelMultiDataSet(data) : undefined;
};
