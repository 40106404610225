import * as React from 'react';
import { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import { mq } from '@closet-design-system/theme';
import { HideInMobile, ShowInMobile } from 'modules/utils/style';
import { useDispatch, useSelector } from 'react-redux';
import {
  contestAwardActions,
  selectContestAwardLayoutData,
} from 'features/marketplace/operation/contest/contestAwardSlice';
import DesktopAwardsTabContent from 'components/marketplace/operation/contest/preview/desktop/DesktopAwardsTabContent';
import MobileAwardsTabContent from 'components/marketplace/operation/contest/preview/mobile/MobileAwardsTabContent';
import { ContestTabType } from 'types/marketplace/operation/contest';
import { useParams } from 'react-router-dom';

const ContestAwardsTab = (): ReactElement => {
  const dispatch = useDispatch();
  const { contestSeq } = useParams<ContestTabType>();
  const awards = useSelector(selectContestAwardLayoutData);

  useEffect(() => {
    dispatch(contestAwardActions.getContestAwardList(Number(contestSeq)));
  }, []);

  return (
    <AwardsTabRoot>
      <>
        {awards?.map(award => (
          <AwardsTabRootInner key={award.awardId}>
            <ShowInMobile>
              <MobileAwardsTabContent
                awardTitle={award.title}
                awardLayouts={award.layouts}
                badgeId={award.badgeId}
              />
            </ShowInMobile>
            <HideInMobile>
              <DesktopAwardsTabContent
                awardTitle={award.title}
                awardLayouts={award.layouts}
                badgeId={award.badgeId}
              />
            </HideInMobile>
          </AwardsTabRootInner>
        ))}
      </>
    </AwardsTabRoot>
  );
};

const AwardsTabRoot = styled.div(
  ({
    theme: {
      breakpoints: { s },
    },
  }) =>
    mq({ s })({
      width: '100vw',
      marginLeft: [0, 'calc(-50vw + 50%)'],
    }),
);

const AwardsTabRootInner = styled.div(
  ({
    theme: {
      breakpoints: { s, xl },
    },
  }) =>
    mq({ s, xl })({
      minWidth: '350px',
      width: ['100vw', '100vw', '100%'],
      marginLeft: ['-16px', 'calc(-50vw + 50%)', 0],
    }),
);

export const Title = styled.div(
  ({
    theme: {
      breakpoints: { s, m },
    },
  }) =>
    mq({
      s,
      m,
    })({
      marginLeft: [8, 0, 'calc(-50vw + 50%)'],
      textAlign: ['left', 'center'],
      marginTop: [24, 48, 80],
      marginBottom: [4, 8, 16],
    }),
);

export default ContestAwardsTab;
