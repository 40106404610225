import * as React from 'react';
import { ChangeEvent, ReactElement, memo } from 'react';
import FormCheckbox from 'components/common/searchfilter/FormCheckbox';
import _capitalize from 'lodash/capitalize';
import FilterElementLayout from 'components/common/searchfilter/FilterElementLayout';
import { GridSize } from '@material-ui/core';
import { SelectOption } from 'types/common/select';

interface Props<T> {
  statuses: T[];
  onChangeChecked: (statuses: T[]) => void;
  checkedOptions: SelectOption<T>[];
  filterLabel?: string;
  gridSize?: GridSize;
  isCapitalized?: boolean;
}
const MultipleCheckboxFilter = <T extends string | number>({
  statuses,
  onChangeChecked,
  checkedOptions,
  filterLabel,
  gridSize,
  isCapitalized = false,
}: Props<T>): ReactElement => {
  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
    const parsedValue = !isNaN(Number(value)) ? Number(value) : value;
    const index = statuses.findIndex(status => {
      return status === parsedValue;
    });
    const alreadyChecked = index > -1;

    if (alreadyChecked) {
      statuses.splice(index, 1);
      onChangeChecked([...statuses]);
      return;
    }
    onChangeChecked([...statuses, parsedValue as T]);
  };

  return (
    <FilterElementLayout label={filterLabel} gridSize={gridSize}>
      {checkedOptions.map(({ label, value }) => (
        <FormCheckbox
          key={value}
          value={String(value)}
          label={isCapitalized ? _capitalize(String(label)) : String(label)}
          checked={statuses.includes(value)}
          onChange={handleChange}
        />
      ))}
    </FilterElementLayout>
  );
};

export default memo(MultipleCheckboxFilter) as <T>(props: Props<T>) => ReactElement;
