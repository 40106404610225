import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { mq } from '@closet-design-system/theme';
import BannerLinkButton from '../BannerLinkButton';
import { LayoutType } from 'types/marketplace/operation/mainBanner/mainBannerPreview';
import { fadeInEffect, getMediaQueryRangeValues } from 'modules/mainBannerPreview';

interface Props {
  layoutType: LayoutType;
  buttonText: string;
  buttonUrl: string;
  darkMode: boolean;
  openNewTab: boolean;
  children: ReactNode;
  hasLinkButton: boolean;
}

const BaseLayout = ({
  layoutType,
  darkMode,
  children,
  buttonText,
  buttonUrl,
  openNewTab,
  hasLinkButton,
}: Props): ReactElement => {
  return (
    <TextArea layoutType={layoutType} className={classNames({ darkMode })}>
      {children}
      {hasLinkButton && (
        <BannerLinkButton
          buttonText={buttonText}
          url={buttonUrl}
          openNewTab={openNewTab}
          darkMode={darkMode}
        />
      )}
    </TextArea>
  );
};

export default BaseLayout;

const textLayoutTypeStyle = {
  [LayoutType.TEXT_CENTER]: mq()({
    maxWidth: getMediaQueryRangeValues({ s: '768px', m: '934px', l: '1400px' }),
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  }),
  [LayoutType.TEXT_LEFT]: mq()({
    maxWidth: getMediaQueryRangeValues({ s: '382px', m: '600px', l: '906px' }),
    left: '0',
    alignItems: 'flex-start',
  }),
  [LayoutType.TEXT_RIGHT]: mq()({
    maxWidth: getMediaQueryRangeValues({ s: '382px', m: '600px', l: '906px' }),
    right: '0',
    alignItems: 'flex-start',
  }),
  [LayoutType.IMAGE]: mq()({
    display: 'none',
  }),
};

const TextArea = styled.div<{ layoutType: LayoutType }>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${fadeInEffect({ from: '-5%' })} 0.7s ease-out;
  animation-fill-mode: backwards;
  animation-delay: 2s;
  color: ${({ theme }) => theme.colors.GRAY_900};
  &.darkMode {
    color: ${({ theme }) => theme.colors.GRAY_50};
  }
  ${({ layoutType }) => textLayoutTypeStyle[layoutType]}
`;
