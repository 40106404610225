import midBannerFormSaga from 'features/marketplace/operation/midBanner/midBannerFormSaga';
import midBannerSaga from 'features/marketplace/operation/midBanner/midBannerSaga';
import sectionManageSaga from 'features/marketplace/operation/sectionManage/sectionManageSaga';
import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import mainBannerSaga from './operation/mainBanner/mainBannerSaga';
import mainBannerFormSaga from './operation/mainBannerForm/mainBannerFormSaga';

function* marketplaceSaga(): SagaIterator {
  yield all([
    fork(sectionManageSaga),
    fork(midBannerSaga),
    fork(mainBannerSaga),
    fork(midBannerFormSaga),
    fork(mainBannerFormSaga),
  ]);
}

export default marketplaceSaga;
