import * as React from 'react';
import { forwardRef, Ref } from 'react';
import ExcelExportButton from 'components/common/file/ExcelExportButton';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';
import { billingTableColumns } from 'components/plansales/billing/billingtable/BillingListColumns';
import { BillingInvoice } from 'types/plansales/billing';

interface Props {
  dateInterval: string[];
  searchCellClick: (searchValue: string) => () => void;
  searchResultData: BillingInvoice[];
  exportData: BillingInvoice[];
  exportLoading: boolean;
  onClickExport: () => void;
  searchResultDataLoading: boolean;
  onChangePage: (pageIndex: number) => void;
  currentPageIndex: number;
  totalPages: number;
  totalItems: number;
}
const BillingList = forwardRef(
  (
    {
      dateInterval: [dateFrom, dateTo],
      searchCellClick,
      searchResultData,
      exportData,
      exportLoading,
      onClickExport,
      searchResultDataLoading,
      onChangePage,
      currentPageIndex,
      totalPages,
      totalItems,
    }: Props,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const fileName = `Billing_${dateFrom}_${dateTo}.csv`.replace(new RegExp('-', 'g'), '_');
    const billingListColumnData = billingTableColumns(
      searchCellClick,
      totalItems,
      currentPageIndex,
    );
    return (
      <DataTableLayout
        maxHeight={'60vh'}
        showPagination
        totalPages={totalPages}
        onChangePage={onChangePage}
        currentPageIndex={currentPageIndex}
        upperButton={
          <ExcelExportButton
            ref={ref}
            width={100}
            buttonText={'Export List'}
            fileName={fileName}
            exportData={exportData}
            onClickExport={onClickExport}
            exportLoading={exportLoading}
          />
        }
      >
        <DataTableHeader header={billingListColumnData} />
        <NewDataTableBody
          dataList={searchResultData ?? []}
          columns={billingListColumnData}
          loading={searchResultDataLoading}
        />
      </DataTableLayout>
    );
  },
);

export default BillingList;
