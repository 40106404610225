// template 소스 나중에 걷어낼것.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';

interface ThemeState {
  currentTheme: number;
}

const initialState: ThemeState = {
  currentTheme: 0,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state: ThemeState, action: PayloadAction<number>) => {
      state.currentTheme = action.payload;
    },
  },
});

export const { setTheme } = themeSlice.actions;

export const selectCurrentTheme = (state: RootState) => state.theme.currentTheme;

export default themeSlice.reducer;
