import * as React from 'react';
import { Typography } from '@material-ui/core';
import RequiredMark from 'components/common/RequiredMark';
import DragDropZone, { Props as DragDropZoneProps } from 'components/common/form/DragDropZone';

interface Props extends DragDropZoneProps {
  title?: string;
  descriptionUploadSpce?: string;
  required?: boolean;
  onChangeFile: (file: File | null) => void;
}

const FileUploadArea = ({
  title,
  descriptionUploadSpce,
  required,
  file,
  onChangeFile,
  ...rest
}: Props) => {
  return (
    <>
      {title && (
        <Typography variant="h6" gutterBottom>
          {title}
          {required && <RequiredMark />}
        </Typography>
      )}
      {descriptionUploadSpce && (
        <Typography variant="body2" gutterBottom>
          {descriptionUploadSpce}
        </Typography>
      )}
      <DragDropZone file={file} onChangeFile={onChangeFile} {...rest} />
    </>
  );
};

export default FileUploadArea;
