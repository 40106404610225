import api from 'api';
import coreApi from 'api/coreApi';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { coreDomain, domain, tokenName } from 'services/config';
import preRequest from 'services/request';
import { camelizeKeys } from 'humps';
import Cookies from 'universal-cookie';

let axiosReqInterceptor: number | null = null;
let axiosResInterceptor: number | null = null;
let coreReqInterceptor: number | null = null;
let coreResInterceptor: number | null = null;
export const run = () => {
  if (axiosReqInterceptor !== null) api.interceptors.request.eject(axiosReqInterceptor);
  if (axiosResInterceptor !== null) api.interceptors.response.eject(axiosResInterceptor);
  if (coreReqInterceptor !== null) coreApi.interceptors.request.eject(coreReqInterceptor);
  if (coreResInterceptor !== null) coreApi.interceptors.response.eject(coreResInterceptor);
  axiosReqInterceptor = null;
  axiosResInterceptor = null;
  coreReqInterceptor = null;
  coreResInterceptor = null;

  const requestInterceptor = async (config: AxiosRequestConfig) => {
    const cookies = config?.headers.cookie ? new Cookies(config?.headers.cookie) : new Cookies();
    await preRequest(cookies);
    const token = cookies.get(tokenName);
    const authConfig =
      token !== ''
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {};
    return {
      ...config,
      headers: {
        ...config.headers,
        ...authConfig,
      },
    };
  };

  const responseInterceptor = (response: AxiosResponse) => {
    response.data = camelizeKeys(response.data);
    return response;
  };

  axiosReqInterceptor = api.interceptors.request.use(requestInterceptor);
  axiosResInterceptor = api.interceptors.response.use(responseInterceptor);
  coreReqInterceptor = coreApi.interceptors.request.use(requestInterceptor);
  coreResInterceptor = coreApi.interceptors.response.use(responseInterceptor);
  api.defaults.baseURL = domain;
  coreApi.defaults.baseURL = coreDomain;
};

export default {
  run,
};
