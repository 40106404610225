import { Nullable } from 'types/common';
import { DataObject } from 'types/common/table';
import { ChangeEvent } from 'react';
import { SelectChangeEvent } from 'types/common/select';
import { BasicListInfo } from 'types/api';
import { ConfirmStatus } from 'types/marketplace/confirmItem';
import { AxiosError } from 'axios';

export type ContentsValueType = unknown;

export interface ContestFilterType {
  startDate: string;
  endDate: string;
  keyword: string;
  page: string | number;
  limit?: number;
}

export interface ContestParticipatingFilterType {
  page: number;
  sort: ContestParticipatingSortType;
  limit?: number;
  keywordType?: ContestItemsKeywordType;
  searchKeyword?: string;
}

export type ContestFilterProps = ContestFilterType | undefined;

export type ContestTabType = {
  contestSeq: string;
  tab: ContestDetailTabType;
};

export enum ContestDetailTabType {
  CONTENTS = 'contents',
  ITEMS = 'items',
}

export interface ContestItemDateProps {
  type: string;
  value: string;
}

export enum ContestScheduleKeys {
  NOTICE = 'noticeDate',
  SUBMISSION = 'submissionDate',
  EVALUATION = 'evaluationDate',
  ANNOUNCEMENT = 'announcementDate',
  CLOSED = 'closedDate',
}

export enum ContestItemType {
  GALLERY,
  STORE,
}

export enum ContestNumberOfWork {
  SINGLE,
  MULTI,
}

export interface ContestContentsDatetime {
  [ContestScheduleKeys.NOTICE]: string;
  [ContestScheduleKeys.SUBMISSION]: string;
  [ContestScheduleKeys.EVALUATION]: string;
  [ContestScheduleKeys.ANNOUNCEMENT]: string;
  [ContestScheduleKeys.CLOSED]: string;
}

export interface ContestContentProps {
  contestType: ContestType;
  title: string;
  url: string;
  hostName: string;
  hostSite: Nullable<string>;
  description: string;
  noticeDate: string;
  submissionDate: string;
  evaluationDate: string;
  announcementDate: string;
  closedDate: string;
  checkLikesDate: boolean;
  likesDate: Nullable<string>;
  numberOfWorkType: ContestNumberOfWork;
  uploadItemType: ContestItemType;
  imagePath: Nullable<string>;
  awards: ContestAwardProps[];
  status?: ContestStatusType;
  visibility?: ContestVisibilityType;
  attachmentPath: Nullable<string>;
  attachmentFileName: Nullable<string>;
  image?: Nullable<File>; // Not received from api, used only when registering in api or within FE
  attachment?: Nullable<File>; // Not received from api, used only when registering in api or within FE
}

export interface ContestContentsValidations {
  title: Nullable<string>;
  url: Nullable<string>;
  hostName: Nullable<string>;
  image: Nullable<string>;
  description: Nullable<string>;
  awards: Nullable<string>;
}

export type ContestCellFunction = (
  rowData: DataObject,
  rowIndex: number,
  event: SelectChangeEvent | ChangeEvent<HTMLInputElement>,
) => void;

export enum ContestAwardFieldType {
  TITLE = 'title',
  MEMBER = 'member',
  BADGE = 'badgeId',
}
export enum ContestBadge {
  PLATINUM = 1,
  GOLD = 2,
  SILVER = 3,
  BRONZE = 4,
}

export enum ContestVisibilityType {
  SHOW,
  HIDE,
}

export interface ContestProps extends ContestContentProps {
  contestSeq: number;
  openDate: Nullable<string>;
  registeredBy: string;
  registeredDate: string;
  changedBy: Nullable<string>;
  changedDate: Nullable<string>;
  contestDetailUrl: string;
  canOpen: boolean;
  viewCount: number;
  url: string;
  attachmentDownloadCount: number;
  uploadCount: number;
}

export interface ContestAwardProps {
  awardId: string;
  contestSeq: number;
  badgeId: number;
  title: string;
  member: number;
  layouts: Nullable<ContestLayoutProps[]>;
}

export interface ContestLayoutProps {
  contestSeq: number;
  layoutId: string;
  awardId: string;
  layoutType: number;
  registeredBy: number;
  items: Nullable<ContestAwardItemProps[]>;
}

export interface ContestAwardItemProps {
  itemId: string;
  imagePath: string;
  userId: number;
  creator: string;
  creatorImagePath: Nullable<string>;
  layoutOrder: number;
}

export interface ContestItemProps extends ContestAwardItemProps {
  itemNumber: number;
  title: string;
  creator: string;
  status: ConfirmStatus;
  likeCount: number;
  awardName: Nullable<string>;
  awardId: Nullable<string>;
  detailPageUrl: string;
  registeredDate: string;
}

export interface ContestItemListParams extends ContestParticipatingFilterType {
  contestSeq: number;
}

export interface ContestItemListProps extends Omit<BasicListInfo, 'links'> {
  items: ContestItemProps[];
}

export enum ContestStatusType {
  READY,
  NOTICE,
  SUBMISSION,
  EVALUATION,
  ANNOUNCEMENT,
  CLOSED,
}

export enum ContestHistoryStatusType {
  CREATED,
  UPDATED,
  SHOW,
  HIDE,
  DELETED,
}

export enum ContestHistoryStatusMessage {
  CREATED = 'Created Contest',
  UPDATED = 'Last Updated',
  SHOW = 'Show',
  HIDE = 'Hide',
  DELETED = 'Deleted',
}

export interface ContestHistoryProps {
  changedBy: string;
  type: ContestHistoryStatusType;
  changedDate: string;
}

export interface ContestListProps extends Omit<BasicListInfo, 'links'> {
  items: ContestProps[];
}

export interface ContestHistoriesProps extends Omit<BasicListInfo, 'links'> {
  items: ContestHistoryProps[];
}

export interface ContestModalConfig {
  show: boolean;
  contestSeq?: number;
}

export interface AwardLayoutModalConfig extends ContestModalConfig {
  awardId?: string;
}

export interface AwardItemModalConfig extends AwardLayoutModalConfig {
  layoutId?: string;
  layoutOrder?: number;
}

export type ChangeValueFunctionType = (
  key: string,
  value: ContentsValueType,
  isEmpty?: boolean,
) => void;

export enum LayoutBaseType {
  ONE_PIECE = 1,
  TWO_PIECE,
  THREE_PIECE,
}

export enum LayoutType {
  ONE_PIECE_1 = 1,
  ONE_PIECE_2 = 2,
  ONE_PIECE_3 = 3,
  TWO_PIECE_1 = 11,
  TWO_PIECE_2 = 12,
  TWO_PIECE_3 = 13,
  TWO_PIECE_4 = 14,
  THREE_PIECE_1 = 21,
  THREE_PIECE_2 = 22,
  THREE_PIECE_3 = 23,
}

export interface AwardItemPayload {
  contestSeq: number;
  awardId: string;
}

export interface AddAwardLayoutPayload extends AwardItemPayload {
  layoutType: LayoutType;
}

export interface RemoveAwardLayoutPayload extends AwardItemPayload {
  layoutId: string;
}

export interface AddAwardLayoutItemPayload extends AwardItemPayload {
  layoutId: string;
  layoutOrder: number;
  itemId: string;
}

export interface UpdateAwardItemPayload {
  contestSeq: number;
  itemId: string;
  awardId: Nullable<string>;
}

export interface ContextMenuState extends AwardItemPayload {
  layoutId: string;
  endPosition: Nullable<AwardLayoutEndPositionType>;
}

export type AwardLayoutFunctionParameters = Parameters<
  (
    contestSeq: number,
    awardId: string,
    layoutId: string,
    endPosition: Nullable<AwardLayoutEndPositionType>,
  ) => void
>;

export enum ContestParticipatingSortType {
  AWARD = 0,
  UPLOAD_DATE,
  CREATOR_ACCOUNT,
  LIKES,
}

export interface ContestOngoingProps extends Pick<ContestAwardProps, 'contestSeq' | 'title'> {}

export enum ContestOngoingFilterType {
  NONE = -2,
  CLOSED,
}

export interface ContestTimelineProps {
  statusText: string;
  startDate: string;
}

export enum ContestPreviewTabType {
  INFORMATION = 'information',
  AWARDS = 'awards',
}

export interface AwardLayoutPreviewType {
  items: Nullable<ContestAwardItemProps[]>;
  layoutType: LayoutType;
  badgeId: ContestBadge;
}

export interface TwoThreePieceType {
  badgeId: ContestBadge;
  items: Nullable<ContestAwardItemProps & { alignSelf?: string }>[];
  layoutType: LayoutType;
}

export type LayoutBaseTypeKey = keyof typeof LayoutBaseType;

export type UploadImageErrorToastType = (
  message: AxiosError | string,
  isAxiosError: boolean,
) => void;

export enum ContestLayoutMoveOptions {
  RIGHT,
  LEFT,
  RIGHT_MOST,
  LEFT_MOST,
}

export enum AwardLayoutEndPositionType {
  LEFT = 1,
  RIGHT,
  LEFT_AND_RIGHT,
}

export interface ContextMenuMoveOptionProp {
  position: ContestLayoutMoveOptions;
  endPosition: AwardLayoutEndPositionType;
  label: string;
}

export interface UpdateAwardLayoutMovePayload extends RemoveAwardLayoutPayload {
  move: ContestLayoutMoveOptions;
}

export enum ContestType {
  LIVE,
  TEST,
}

export enum ContestItemsKeywordType {
  Account,
  ItemName,
  ItemNumber,
}
