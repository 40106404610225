import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';

interface Props {
  title: string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  margin?: string;
  onChangeDisplay?: (event: React.ChangeEvent, expanded: boolean, name: string) => void;
  name?: string;
}

const AccordionBox = ({
  title,
  children,
  defaultExpanded = false,
  margin = '0 0 0 0',
  onChangeDisplay,
  name = '',
}: Props) => {
  const onChange = (event: React.ChangeEvent<{}>, expanded: boolean) => {
    onChangeDisplay?.(event as React.ChangeEvent, expanded, name);
  };
  return (
    <AccordionBoxBlock margin={margin}>
      <Accordion defaultExpanded={defaultExpanded} onChange={onChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionTitle variant={'subtitle1'}>{title}</AccordionTitle>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </AccordionBoxBlock>
  );
};

export default AccordionBox;

const AccordionBoxBlock = styled.div<{ margin: string }>`
  width: 100%;
  margin: ${props => props.margin};
  & .MuiAccordion-root {
    background-color: ${props => props.theme.palette.grey[200]};
    border: 1px solid ${props => props.theme.palette.grey[300]};
    box-sizing: border-box;
  }
`;

const AccordionTitle = styled(Typography)`
  padding-left: ${props => props.theme.spacing(2)}px;
`;
