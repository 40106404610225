import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { Button, Table, TableBody, TableRow, TextField, Checkbox } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import styled from 'styled-components';
import { NoBorderCell } from 'components/common/table/TableStyle';
import { DateInterval } from 'types/plansales/billing';
import BasicModal from 'components/common/modal/BasicModal';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateRangePicker from 'components/common/searchfilter/contents/DateRangePicker';

interface Props {
  couponModalOpen: boolean;
  setCouponModalOpen: Dispatch<SetStateAction<boolean>>;
  couponDateInterval: string[];
  setCouponDateInterval: Dispatch<SetStateAction<string[]>>;
  isUnlimited: boolean;
  setIsUnlimited: Dispatch<SetStateAction<boolean>>;
  saveNewCoupon: () => void;
  couponAmount: string;
  couponDescription: string;
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCouponDate: (type: DateInterval) => (newDateValue: MaterialUiPickersDate) => void;
  onChangeUnlimited: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddCouponModal = ({
  couponModalOpen,
  setCouponModalOpen,
  couponDateInterval,
  isUnlimited,
  saveNewCoupon,
  couponAmount,
  couponDescription,
  onChangeValue,
  onChangeUnlimited,
  setCouponDateInterval,
}: Props) => {
  const isDisabled =
    couponDescription.length === 0 || couponAmount.length === 0 || Number(couponAmount) <= 0;
  return (
    <BasicModal
      open={couponModalOpen}
      onClose={() => setCouponModalOpen(false)}
      title={'Add Coupon'}
      actionButton={
        <>
          <Button variant={'text'} color={'primary'} onClick={() => setCouponModalOpen(false)}>
            Cancel
          </Button>
          <Button variant={'text'} color={'primary'} onClick={saveNewCoupon} disabled={isDisabled}>
            Confirm
          </Button>
        </>
      }
    >
      <Table size={'small'}>
        <TableBody>
          <TableRow>
            <NoBorderCell>
              <BoldText>Amount</BoldText>
            </NoBorderCell>
            <NoBorderCell align={'left'}>
              <TextField
                type={'number'}
                variant={'outlined'}
                size={'small'}
                value={couponAmount}
                name={'amount'}
                onChange={onChangeValue}
                placeholder={'0.00'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MonetizationOnIcon fontSize={'small'} />
                    </InputAdornment>
                  ),
                }}
              />
            </NoBorderCell>
          </TableRow>
          <TableRow>
            <NoBorderCell>
              <BoldText>Date</BoldText>
            </NoBorderCell>
            <NoBorderCell>
              <DateRangePicker
                dateInterval={couponDateInterval}
                setDateInterval={setCouponDateInterval}
                disableOnFrom
                disableOnTo={isUnlimited}
              />
            </NoBorderCell>
          </TableRow>
          <TableRow>
            <NoBorderCell>
              <BoldText>Unlimited</BoldText>
            </NoBorderCell>
            <NoBorderCell>
              <Checkbox checked={isUnlimited} onChange={onChangeUnlimited} />
            </NoBorderCell>
          </TableRow>
          <TableRow>
            <NoBorderCell>
              <BoldText>Coupon Code</BoldText>
            </NoBorderCell>
            <NoBorderCell>
              <TextField
                fullWidth
                multiline
                rows={5}
                value={couponDescription}
                name={'description'}
                onChange={onChangeValue}
                variant={'outlined'}
                placeholder={'Please enter the coupon code name. \nThis will be shown to users.'}
                inputProps={{
                  maxLength: 250,
                }}
              />
            </NoBorderCell>
          </TableRow>
        </TableBody>
      </Table>
    </BasicModal>
  );
};

export default AddCouponModal;

export const BoldText = styled.span`
  font-weight: 600;
`;
