import * as React from 'react';
import { ReactElement } from 'react';
import {
  AwardLayoutPreviewType,
  ContestAwardItemProps,
  LayoutBaseType,
} from 'types/marketplace/operation/contest';
import { Nullable } from 'types/common';
import { fillNullIfLayoutOrderItemEmpty } from 'modules/contest';
import TwoPieceType from 'components/marketplace/operation/contest/preview/desktop/twopiecelayout/TwoPieceType';
import ThreePieceType from 'components/marketplace/operation/contest/preview/desktop/threepiecelayout/ThreePieceType';

const TwoThreePieceLayout = ({
  items,
  layoutType,
  badgeId,
  layoutBaseType,
}: AwardLayoutPreviewType & {
  layoutBaseType: LayoutBaseType;
}): ReactElement => {
  const pieceLayoutItems: Nullable<ContestAwardItemProps>[] = fillNullIfLayoutOrderItemEmpty({
    items: items ?? [],
    piece: layoutBaseType,
  });
  const PieceTypeComponent =
    layoutBaseType === LayoutBaseType.TWO_PIECE ? TwoPieceType : ThreePieceType;

  return <PieceTypeComponent badgeId={badgeId} items={pieceLayoutItems} layoutType={layoutType} />;
};

export default TwoThreePieceLayout;
