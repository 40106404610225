import { all, takeLatest, put, call } from 'redux-saga/effects';
import { benchmarkActions } from './benchmarkSlice';
import * as api from 'api/member/index';
import { PayloadAction } from '@reduxjs/toolkit';

export function* getBenchmarkSaga(action: PayloadAction<{ page: number }>) {
  const { data } = yield call(api.getBenchmarkData, action.payload);
  if (data) {
    yield put(benchmarkActions.getBenchmarkDataSuccess(data));
  } else {
    yield put(benchmarkActions.getBenchmarkDataFailure(data));
  }
}

export default function* benchmarkSaga() {
  yield all([takeLatest(benchmarkActions.getBenchmarkData.type, getBenchmarkSaga)]);
}
