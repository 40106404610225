import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import { Histories, MidBanner, OnGoingList } from 'types/marketplace';

/**
 * Mid Banner Mage page의 store
 */

export interface MidBannerState {
  onGoingList: OnGoingList | null;
  histories: Histories | null;
  historiesPageNo: number;
  historiesPageSize: number;
  deleteTargetBanner: MidBanner | null;
}
export const initialState: MidBannerState = {
  onGoingList: null,
  histories: null,
  deleteTargetBanner: null,
  historiesPageNo: 1,
  historiesPageSize: 10,
};

export const midBannerSlice = createSlice({
  name: 'midBannerMange',
  initialState,
  reducers: {
    loadOnGoingList: _ => {},
    setOnGoingList: (state, { payload }: PayloadAction<OnGoingList>) => {
      state.onGoingList = payload;
    },
    loadHistories: _ => {},
    setHistories: (state, { payload }: PayloadAction<Histories>) => {
      state.histories = payload;
    },
    toggleStatus: (_, _action: PayloadAction<MidBanner>) => {},
    openDeleteModal: (state, { payload }: PayloadAction<MidBanner>) => {
      state.deleteTargetBanner = payload;
    },
    closeDeleteModal: state => {
      state.deleteTargetBanner = null;
    },
    deleteBanner: _ => {},
    removeBanner: state => {
      if (!state.deleteTargetBanner) return;

      // 리스트에서 삭제된 banner를 제거함.
      if (state.onGoingList) {
        state.onGoingList = {
          showingBanners: state.onGoingList?.showingBanners.filter(
            banner => banner.id !== state.deleteTargetBanner?.id,
          ),
          upcomingBanners: state.onGoingList?.upcomingBanners.filter(
            banner => banner.id !== state.deleteTargetBanner?.id,
          ),
        };
      }
      if (state.histories) {
        state.histories.banners = state.histories.banners.filter(
          banner => banner.id !== state.deleteTargetBanner?.id,
        );
      }
    },
  },
});

export const midBannerActions = midBannerSlice.actions;
export const midBannerReducer = midBannerSlice.reducer;

export const selectOnGoingList = (state: RootState) => state.marketplace.midBanner.onGoingList;
export const selectHistories = (state: RootState) => state.marketplace.midBanner.histories?.banners;
export const selectHistoriesPageNo = (state: RootState) =>
  state.marketplace.midBanner.historiesPageNo;
export const selectHistoriesPageSize = (state: RootState) =>
  state.marketplace.midBanner.historiesPageSize;
export const selectHistoriesTotalCount = (state: RootState) =>
  state.marketplace.midBanner.histories?.totalCount ?? 0;
export const selectIsOpenDeleteModal = (state: RootState) =>
  state.marketplace.midBanner.deleteTargetBanner !== null;
export const selectDeleteTargetBannerId = (state: RootState) =>
  state.marketplace.midBanner.deleteTargetBanner?.id;
