import { GetFeatureUpdateNoticeResponse } from '../api/notice/featureUpdateNotice';

export enum FeatureUpdateNoticeTab {
  ON_GOING = 'ongoing',
  HISTORY = 'history',
}

export interface FeatureUpdateNoticeData {
  seq: string;
  imageUrl: string | null;
  title: string;
  linkUrl: string;
  displayModalFrom: string;
  displayModalTo: string;
  status: FeatureUpdateNoticeStatus;
  targetSpace: FeatureUpdateNoticeTargetSpace;
}

export enum FeatureUpdateNoticeStatus {
  NONE,
  SHOWING,
  UPCOMING,
  EXPIRED,
}

export type FeatureUpdateNoticeTableData = GetFeatureUpdateNoticeResponse;

export enum FeatureUpdateNoticeFormType {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum FeatureUpdateNoticeTargetSpace {
  NONE,
  MAIN_DASHBOARD,
  MY_PAGE,
  ADMIN_CONSOLE,
  RENDERING,
  FAVORITE,
  COMPANY,
  COMPANY_SETTING,
  BRAND,
  WORKROOM,
  ASSORTMENT,
  CONTENT,
  VIRTUAL_SHOWROOM,
  VISUAL_BOARD,
}

export enum FeatureUpdateNoticeModalType {
  NONE,
  IMAGE_TEXT,
  IMAGE,
  TEXT,
}

export enum FeatureUpdateNoticeColorType {
  LIGHT = 1,
  DARK = 2,
}

export enum FeatureUpdateNoticeLinkOpenType {
  NONE,
  CURRENT,
  NEW_TAB,
}

export interface FeatureUpdateNoticeFormData {
  seq?: number;
  title: string;
  targetSpace: FeatureUpdateNoticeTargetSpace;
  modalType: FeatureUpdateNoticeModalType;
  colorType: FeatureUpdateNoticeColorType;
  modals: {
    english: FeatureUpdateNoticeModalFormData;
    chinese: FeatureUpdateNoticeModalFormData;
  };
  useLinkButton: boolean;
  linkButton: FeatureUpdateNoticeModalLinkButton;
  displayModalFrom: Date;
  displayModalTo: Date;
  hidingPediod: number;
}

export interface FeatureUpdateNoticeModalFormData {
  imageUrl: File | null;
  title: string | null;
  subTitle: string | null;
  body: string | null;
  footNote: string | null;
  language: FeatureUpdateNoticeLanguage;
}

export interface FeatureUpdateNoticeModalLinkButton {
  linkUrl: string | null;
  buttonNameEng: string | null;
  buttonNameCn: string | null;
  linkOpenType: FeatureUpdateNoticeLinkOpenType | null;
}

export enum FeatureUpdateNoticeLanguage {
  NONE,
  ENGLISH,
  CHINESE,
}

export interface FeatureUpdateNoticeModalTypeValidation {
  imageEn: boolean;
  imageCn: boolean;
  titleEn: boolean;
  titleCn: boolean;
}

export interface FeatureUpdateNoticeLinkButtonValidation {
  linkUrl: boolean;
  buttonNameEng: boolean;
  buttonNameCn: boolean;
}

export interface FeatureUpdateNoticeFormValidation {
  title: boolean;
  modals: FeatureUpdateNoticeModalTypeValidation;
  linkButton: FeatureUpdateNoticeLinkButtonValidation;
  date: boolean;
}
