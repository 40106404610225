import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../rootReducer';
import {
  Download,
  FittingError,
  GetDownloadListParams,
  GetActualFilePathParams,
  GetFittingErrorListParams,
} from 'types/worker/downloads';

interface GetFittingErrorListResponse {
  fittingErrors: FittingError[];
  pageNo: number;
  totalCount: number;
}

interface GetSRestResponse {
  result: { [key: string]: any };
  sRestFile: { file: File | null; fileName: string };
}

interface DownloadsState {
  downloadList: {
    loading: boolean;
    data: Download[];
    error: Error | null;
  };
  fittingErrorList: {
    loading: boolean;
    data: GetFittingErrorListResponse;
    error: Error | null;
  };
  actualFilePath: {
    loading: boolean;
    path: string;
    error: Error | null;
  };
  sRest: {
    loading: boolean;
    data: GetSRestResponse;
    error: Error | null;
  };
}
const initialState: DownloadsState = {
  downloadList: {
    loading: false,
    data: [],
    error: null,
  },
  fittingErrorList: {
    loading: false,
    data: {
      fittingErrors: [],
      pageNo: 1,
      totalCount: 0,
    },
    error: null,
  },
  actualFilePath: {
    loading: false,
    path: '',
    error: null,
  },
  sRest: {
    loading: false,
    data: { result: {}, sRestFile: { file: null, fileName: '' } },
    error: null,
  },
};

export const downloadsSlice = createSlice({
  name: 'downloads',
  initialState,
  reducers: {
    getDownloadList: (state: DownloadsState, _action: PayloadAction<GetDownloadListParams>) => {
      state.downloadList.loading = true;
    },
    resetDownloadList: (state: DownloadsState) => {
      state.downloadList = initialState.downloadList;
    },
    getDownloadListSuccess: (state: DownloadsState, action: PayloadAction<Download[]>) => {
      state.downloadList.loading = false;
      state.downloadList.data = action.payload;
    },
    getDownloadListFailure: (state: DownloadsState, action: PayloadAction<any>) => {
      state.downloadList.loading = false;
      state.downloadList.error = action.payload ?? new Error('error occurs!');
      state.downloadList = initialState.downloadList;
    },
    getFittingErrorList: (
      state: DownloadsState,
      _action: PayloadAction<GetFittingErrorListParams>,
    ) => {
      state.fittingErrorList.loading = true;
    },
    resetFittingErrorList: (state: DownloadsState) => {
      state.fittingErrorList = initialState.fittingErrorList;
    },
    getFittingErrorListSuccess: (
      state: DownloadsState,
      action: PayloadAction<GetFittingErrorListResponse>,
    ) => {
      state.fittingErrorList.loading = false;
      state.fittingErrorList.data = action.payload;
    },
    getFittingErrorListFailure: (state: DownloadsState, action: PayloadAction<any>) => {
      state.fittingErrorList.loading = false;
      state.fittingErrorList.error = action.payload ?? new Error('error occurs!');
      state.fittingErrorList = initialState.fittingErrorList;
    },
    getActualFilePath: (state: DownloadsState, _action: PayloadAction<GetActualFilePathParams>) => {
      state.actualFilePath.loading = true;
    },
    getActualFilePathSuccess: (state: DownloadsState, action: PayloadAction<string>) => {
      state.actualFilePath.loading = false;
      state.actualFilePath.path = action.payload;
    },
    getActualFilePathFailure: (state: DownloadsState, action: PayloadAction<any>) => {
      state.actualFilePath.loading = false;
      state.actualFilePath.error = action.payload;
    },
    getSRest: (state: DownloadsState, _action: PayloadAction<GetActualFilePathParams>) => {
      state.sRest.loading = true;
    },
    getSRestSuccess: (state: DownloadsState, action: PayloadAction<GetSRestResponse>) => {
      state.sRest.loading = false;
      state.sRest.data = action.payload;
    },
    getSRestFailure: (state: DownloadsState, action: PayloadAction<any>) => {
      state.sRest.loading = false;
      state.sRest.error = action.payload;
    },
    resetActualFilePath: (state: DownloadsState) => {
      state.actualFilePath.path = initialState.actualFilePath.path;
    },
    resetSRestFile: (state: DownloadsState) => {
      state.sRest.data.sRestFile = initialState.sRest.data.sRestFile;
    },
  },
});

export const downloadsReducer = downloadsSlice.reducer;
export const downloadsActions = downloadsSlice.actions;

export const selectDownloadList = (state: RootState) => state.worker.downloads.downloadList;
export const selectFittingErrorList = (state: RootState) => state.worker.downloads.fittingErrorList;
export const selectActualFilePath = (state: RootState) =>
  state.worker.downloads.actualFilePath.path;
export const selectSRest = (state: RootState) => state.worker.downloads.sRest.data;
