import * as React from 'react';
import { PricingPlanTableBlock as BillingHistoryTableBlock } from 'components/memberdetail/detail/pricingplan/PricingTable';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import { Dispatch, SetStateAction } from 'react';
import { legacyBillingHistoryColumns } from 'components/memberdetail/detail/pricingplan/legacybillinghistory/LegacyBillingHistoryColumns';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';

interface Props {
  billingHistory: any;
  setCommentHistoryModalOpen: Dispatch<SetStateAction<boolean>>;
}

const LegacyBillingHistoryTable = ({ billingHistory, setCommentHistoryModalOpen }: Props) => {
  const billingHistoryColumnData = legacyBillingHistoryColumns(setCommentHistoryModalOpen);

  const rowStyle = (rowData: any) => {
    if (rowData.Invoice)
      return {
        backgroundColor: '#fffabe',
        '&:hover': {
          backgroundColor: '#fffabe !important',
        },
      };
    // if (void)  todo 추후 정리 필요
    //   return {
    //     backgroundColor: '#dcd9d9',
    //   };
    return {};
  };
  return (
    <BillingHistoryTableBlock margin={'20px 0 0 0'}>
      {/* api 개발전이라 pagination 일단 숨김 처리 (버그처럼보임) */}
      <DataTableLayout showPagination={false} tableTitle={'Billing History (Legacy)'} maxHeight={'600px'}>
        <DataTableHeader header={billingHistoryColumnData} />
        <NewDataTableBody
          dataList={billingHistory?.GroupBills ?? []}
          columns={billingHistoryColumnData}
          rowStyle={rowStyle}
        />
      </DataTableLayout>
    </BillingHistoryTableBlock>
  );
};

export default LegacyBillingHistoryTable;
