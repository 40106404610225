import { PayloadAction } from '@reduxjs/toolkit';
import { mainBannerApi } from 'api/marketplace/operation/mainBanner';
import { UpdateMainBannerArg } from 'hooks/useMainBannerApi';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { GetMainBannerListData } from 'types/api/marketplace/operation/mainBanner';
import { PagingParams } from 'types/marketplace/operation/mainBanner/mainBanner';
import { mainBannerActions, selectPagingParams } from './mainBannerSlice';
import { ApiResponse } from 'types/api';

export function* getMainBannerListSaga() {
  const params: PagingParams = yield select(selectPagingParams);
  const { data, error }: ApiResponse<GetMainBannerListData> = yield call(
    mainBannerApi.getMainBannerList,
    params,
  );

  if (error) {
    yield put(mainBannerActions.getMainBannerListFailure(error));
    return;
  }

  if (data) {
    yield put(mainBannerActions.getMainBannerListSuccess(data));
  }
}

export function* updateMainBannerShowSaga({
  payload: { id, isShow, options },
}: PayloadAction<UpdateMainBannerArg>) {
  const { error, status }: ApiResponse = yield call(mainBannerApi.updateMainBannerShow, {
    id,
    isShow,
  });

  if (error) {
    options?.onError?.(error);
  } else if (status === 200) {
    options?.onSuccess?.();
  }

  yield put(mainBannerActions.getMainBannerList({ isRefetching: true }));
}

export default function* mainBannerSaga() {
  yield all([
    takeLatest(mainBannerActions.getMainBannerList, getMainBannerListSaga),
    takeEvery(mainBannerActions.updateMainBannerShow, updateMainBannerShowSaga),
  ]);
}
