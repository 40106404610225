import * as React from 'react';
import { ReactElement } from 'react';
import { mq } from '@closet-design-system/theme';
import { RoundSquareTag, TypoSubHeader, TypoSubTitle } from '@closet-design-system/core';
import styled from 'styled-components';

interface ContestDetailBannerProps {
  imagePath: string;
  hostName: string;
  title: string;
  statusTagText: string;
}

const ContestDetailBanner = ({
  imagePath,
  hostName,
  title,
  statusTagText,
}: ContestDetailBannerProps): ReactElement => {
  return (
    <BannerWrap>
      <ImageWrapper>
        <BannerImage src={imagePath} alt={title} />
        <DimLayer />
      </ImageWrapper>
      <Content>
        <RoundSquareTag color={'black'} size={['xs', 'xs', 's', 'm']}>
          {statusTagText}
        </RoundSquareTag>
        <SubTitleWrap>
          <TypoSubTitle variant={[4, 4, 4, 1]} isEllipsis multiLineEllipsis={1}>
            {hostName}
          </TypoSubTitle>
        </SubTitleWrap>
        <TypoSubHeader variant={[4, 4, 4, 1]} isEllipsis multiLineEllipsis={2}>
          {title}
        </TypoSubHeader>
      </Content>
    </BannerWrap>
  );
};

export default ContestDetailBanner;

const BannerWrap = styled.div(
  ({
    theme: {
      breakpoints: { s, m, xl },
    },
  }) =>
    mq({ s, m, xl })({
      position: 'relative',
      width: ['100%', '100%', '100%', 1920],
      margin: ['', '', '', '32px auto 0 auto'],
      borderRadius: ['', '', '', 24],
      overflow: ['', '', '', 'hidden'],
      height: ['49vw', '35vw', 420],
      maxHeight: [270, '35vw', 'unset'],
      minHeight: 176,
      userSelect: 'none',
    }),
);

const ImageWrapper = styled.span(
  ({
    theme: {
      breakpoints: { m, xl },
    },
  }) =>
    mq({ m, xl })({
      height: 'inherit',
      display: 'flex',
      justifyContent: 'center',
      [`${DimLayer}`]: {
        maxWidth: ['', '1920px'],
        left: ['', '50%', ''],
        transform: ['', 'translateX(-50%) !important', ''],
      },
    }),
);

const Content = styled.div`
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SubTitleWrap = styled.div(
  ({
    theme: {
      breakpoints: { m },
    },
  }) =>
    mq({ m })({
      marginTop: [8, 20],
    }),
);

export const DimLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.GRAY_500};
  opacity: 0.1;
  top: 0;
  left: 0;
`;

export const BannerImage = styled.img`
  position: absolute;
  object-fit: cover;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
`;
