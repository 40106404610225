import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import { UpdateMainBannerArg } from 'hooks/useMainBannerApi';
import { GetMainBannerListData } from 'types/api/marketplace/operation/mainBanner';
import {
  MainBannerListStatus,
  PagingParams,
} from 'types/marketplace/operation/mainBanner/mainBanner';

export interface MainBannerState {
  params: PagingParams;
  mainBannerList: MainBannerListStatus;
}

const initialState: MainBannerState = {
  params: {
    pageNo: 1,
    pageSize: 10,
  },
  mainBannerList: {
    isLoading: false,
    data: {
      totalCount: 0,
      pageNo: 0,
      page: 0,
      banners: [],
      showingCount: 0,
    },
    error: null,
  },
};

export const mainBannerSlice = createSlice({
  name: 'mainBanner',
  initialState,
  reducers: {
    getMainBannerList: (
      state: MainBannerState,
      { payload }: PayloadAction<{ isRefetching: boolean } | undefined>,
    ) => {
      if (payload && payload.isRefetching) {
        return;
      }

      state.mainBannerList.isLoading = true;
    },
    getMainBannerListSuccess: (
      state: MainBannerState,
      { payload }: PayloadAction<GetMainBannerListData>,
    ) => {
      state.mainBannerList.data = payload;
      state.mainBannerList.isLoading = false;
    },
    getMainBannerListFailure: (state: MainBannerState, { payload }: PayloadAction<Error>) => {
      state.mainBannerList.isLoading = false;
      state.mainBannerList.error = payload;
    },
    setPageNo: (state: MainBannerState, { payload }: PayloadAction<number>) => {
      state.params.pageNo = payload;
    },
    updateMainBannerShow: (_, _action: PayloadAction<UpdateMainBannerArg>) => {},
  },
});

export const mainBannerActions = mainBannerSlice.actions;
export const mainBannerReducer = mainBannerSlice.reducer;

export const selectPagingParams = (rootState: RootState): PagingParams =>
  rootState.marketplace.mainBanner.params;
export const selectMainBannerList = (rootState: RootState): MainBannerListStatus =>
  rootState.marketplace.mainBanner.mainBannerList;
