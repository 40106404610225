import * as React from 'react';
import InputText from 'components/common/form/InputText';
import { REQUIRED_HELP_TEXT } from 'constants/notice/featureUpdateNotice';

interface Props {
  title: string;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  validation: boolean;
}
const FeatureUpdateNoticeFormTitle = ({ title, onChange, validation }: Props) => {
  return (
    <InputText
      title={'Modal Title'}
      placeholder="Place Enter a name for the modal."
      required
      value={title}
      onChange={onChange}
      helperText={REQUIRED_HELP_TEXT}
      error={validation}
    />
  );
};

export default FeatureUpdateNoticeFormTitle;
