import {
  FeatureUpdateNoticeColorType,
  FeatureUpdateNoticeLinkOpenType,
  FeatureUpdateNoticeModalType,
  FeatureUpdateNoticeStatus,
  FeatureUpdateNoticeTargetSpace,
} from 'types/notice/featureUpdateNotice';

export const featureUpdateNoticeStatusLabel: { [key in FeatureUpdateNoticeStatus]: string } = {
  [FeatureUpdateNoticeStatus.NONE]: 'None',
  [FeatureUpdateNoticeStatus.UPCOMING]: 'Upcoming',
  [FeatureUpdateNoticeStatus.SHOWING]: 'Showing',
  [FeatureUpdateNoticeStatus.EXPIRED]: 'Expired',
};

export const targetSpaces: FeatureUpdateNoticeTargetSpace[] = [
  FeatureUpdateNoticeTargetSpace.MAIN_DASHBOARD,
  FeatureUpdateNoticeTargetSpace.MY_PAGE,
  FeatureUpdateNoticeTargetSpace.ADMIN_CONSOLE,
  FeatureUpdateNoticeTargetSpace.RENDERING,
  FeatureUpdateNoticeTargetSpace.FAVORITE,
  FeatureUpdateNoticeTargetSpace.COMPANY,
  FeatureUpdateNoticeTargetSpace.COMPANY_SETTING,
  FeatureUpdateNoticeTargetSpace.BRAND,
  FeatureUpdateNoticeTargetSpace.WORKROOM,
  FeatureUpdateNoticeTargetSpace.ASSORTMENT,
  FeatureUpdateNoticeTargetSpace.CONTENT,
  FeatureUpdateNoticeTargetSpace.VIRTUAL_SHOWROOM,
  FeatureUpdateNoticeTargetSpace.VISUAL_BOARD,
];

export const featureUpdateNoticeTargetSpaceLabel: {
  [key in FeatureUpdateNoticeTargetSpace]: string;
} = {
  [FeatureUpdateNoticeTargetSpace.NONE]: 'None',
  [FeatureUpdateNoticeTargetSpace.MAIN_DASHBOARD]: 'Main Dashboard',
  [FeatureUpdateNoticeTargetSpace.MY_PAGE]: 'My Page',
  [FeatureUpdateNoticeTargetSpace.ADMIN_CONSOLE]: 'Admin Console',
  [FeatureUpdateNoticeTargetSpace.RENDERING]: 'Rendering',
  [FeatureUpdateNoticeTargetSpace.FAVORITE]: 'Favorite',
  [FeatureUpdateNoticeTargetSpace.COMPANY]: 'Company',
  [FeatureUpdateNoticeTargetSpace.COMPANY_SETTING]: 'Company Setting',
  [FeatureUpdateNoticeTargetSpace.BRAND]: 'Brand',
  [FeatureUpdateNoticeTargetSpace.WORKROOM]: 'Workroom',
  [FeatureUpdateNoticeTargetSpace.ASSORTMENT]: 'Assortment',
  [FeatureUpdateNoticeTargetSpace.CONTENT]: 'Content Page',
  [FeatureUpdateNoticeTargetSpace.VIRTUAL_SHOWROOM]: 'Virtual Showroom',
  [FeatureUpdateNoticeTargetSpace.VISUAL_BOARD]: 'Visual Board',
};

export const modalTypes: FeatureUpdateNoticeModalType[] = [
  FeatureUpdateNoticeModalType.IMAGE_TEXT,
  FeatureUpdateNoticeModalType.IMAGE,
  FeatureUpdateNoticeModalType.TEXT,
];

export const featureUpdateNoticeModalTypeLabel: {
  [key in FeatureUpdateNoticeModalType]: string;
} = {
  [FeatureUpdateNoticeModalType.NONE]: 'None',
  [FeatureUpdateNoticeModalType.IMAGE_TEXT]: 'Image + Text',
  [FeatureUpdateNoticeModalType.IMAGE]: 'Image',
  [FeatureUpdateNoticeModalType.TEXT]: 'Text',
};

export const colorTypes: FeatureUpdateNoticeColorType[] = [
  FeatureUpdateNoticeColorType.DARK,
  FeatureUpdateNoticeColorType.LIGHT,
];

export const featureUpdateNoticeColorTypeLabel: {
  [key in FeatureUpdateNoticeColorType]: string;
} = {
  [FeatureUpdateNoticeColorType.DARK]: 'Dark',
  [FeatureUpdateNoticeColorType.LIGHT]: 'Light',
};

export const linkOpenTypes: FeatureUpdateNoticeLinkOpenType[] = [
  FeatureUpdateNoticeLinkOpenType.CURRENT,
  FeatureUpdateNoticeLinkOpenType.NEW_TAB,
];

export const featureUpdateNoticeLinkOpenTypeLabel: {
  [key in FeatureUpdateNoticeLinkOpenType]: string;
} = {
  [FeatureUpdateNoticeLinkOpenType.NONE]: 'None',
  [FeatureUpdateNoticeLinkOpenType.CURRENT]: 'Current',
  [FeatureUpdateNoticeLinkOpenType.NEW_TAB]: 'New Tab',
};

export const REQUIRED_HELP_TEXT: string = 'This field is required';
export const DATE_HELP_TEXT = 'Please enter a date after the start date';
