import { keyframes, Keyframes } from 'styled-components';
import { MainBannerMediaQueryRangeValue } from 'types/marketplace/operation/mainBanner/mainBannerPreview';

/* main banner 관련 Util 함수들 */
export const replaceToBreak = (text: string): string => {
  return text.replace(/\r\n|\n/gi, '<br/>');
};

export const getMediaQueryRangeValues = <T>({
  s,
  m,
  l,
}: MainBannerMediaQueryRangeValue<T>): T[] => [s, s, s, m, m, l];

export const fadeInEffect = ({ from, to }: { from: string; to?: string }): Keyframes => keyframes`
  from {
    transform: translateX(${from});
    opacity: 0;
  }
  to {
    transform: translateX(${to || 0});
    opacity: 1;
  }
`;
