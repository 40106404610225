import { all, put, takeLatest } from 'redux-saga/effects';
import { authActions } from 'features/auth/authSlice';
import { tokenName } from 'services/config';
import { getCookie } from 'services/session';
import { decodeJwtToken } from '../../modules/signin';

function* getLoginUserSaga() {
  const token = getCookie(tokenName);
  const decoded = decodeJwtToken(token);

  if (!decoded) return null;

  const { userId, email } = decoded;
  const data = {
    userId,
    name: email,
    email,
  };

  if (data) {
    yield put(authActions.getLoginUserSuccess(data));
  } else {
    yield put(authActions.getLoginUserFailure(data));
  }
}

export default function* authSaga() {
  yield all([takeLatest(authActions.getLoginUser.type, getLoginUserSaga)]);
}
