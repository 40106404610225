import * as React from 'react';
import { TableCell } from 'types/common/table';
import { getUtcAndKstTime } from 'modules/billing';
import { IconButton } from '@material-ui/core';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import styled from 'styled-components';
import { Comment } from 'types/member';
import { DEFAULT_PAGE_SIZE, NULL_TEXT } from 'constants/common';
import { getTableRowNumber } from 'modules/dataTable';
import { getUserNameFromEmail } from 'modules/utils/user';

export const commentListColumns = (
  openCommentInputModal: (comment: Comment) => () => void,
  setCommentDeleteModalOpen: (comment: Comment) => () => void,
  totalItems: number,
  currentPageIndex: number,
): TableCell<Comment>[] => [
  {
    align: 'center',
    name: 'No.',
    width: '5%',
    key: 'no',
    render: (rowData, columnData, rowIndex) =>
      getTableRowNumber(totalItems, DEFAULT_PAGE_SIZE, currentPageIndex, rowIndex),
  },
  {
    align: 'left',
    name: 'Description',
    key: 'description',
    render: rowData => <CommentText>{rowData.commentText}</CommentText>,
  },
  {
    align: 'center',
    width: '18%',
    name: 'Posted Date',
    key: 'postedDate',
    render: rowData => {
      const { histories, writeAt } = rowData;
      const length = histories.length;
      return getUtcAndKstTime(length > 0 ? histories[length - 1].writeAt! : writeAt);
    },
  },
  {
    align: 'center',
    width: '18%',
    name: 'Updated Date',
    key: 'postedDate',
    render: rowData => {
      const { histories } = rowData;
      const length = histories.length;
      return length === 0 ? NULL_TEXT : getUtcAndKstTime(rowData.writeAt);
    },
  },
  {
    align: 'center',
    width: '5%',
    name: 'Operator',
    key: 'operator',
    render: rowData => <Capitalize>{getUserNameFromEmail(rowData.writeBy)}</Capitalize>,
  },
  {
    align: 'center',
    width: '5%',
    name: '',
    key: 'edit',
    render: rowData => (
      <EditButtons>
        <IconButton size={'small'} onClick={openCommentInputModal(rowData)}>
          <Create fontSize={'small'} color={'action'} />
        </IconButton>
        <IconButton size={'small'} onClick={setCommentDeleteModalOpen(rowData)}>
          <Delete fontSize={'small'} />
        </IconButton>
      </EditButtons>
    ),
  },
];

const EditButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;

const CommentText = styled.div`
  word-break: break-all;
`;
