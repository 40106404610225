export enum DownloadTab {
  DOWNLOADS = 'download',
  FITTING_ERRORS = 'fitting',
}

export interface File {
  itemId: string;
  fileName: string;
  version: number;
  actualFileDownloadApi: string;
  sRestDownloadApi: string;
  hasPermission: boolean;
}

export interface Download extends File {
  email: string;
}

export interface FittingError extends File {
  avatarId: string;
  grading: string;
  gradingIndex: number;
  taskId: string;
  assignFittingId: string;
  completedCount: number;
  totalCount: number;
  startedUtcTime: Date;
  completedUtcTime: Date;
  filePath: string;
  status: number;
  latestDrapingUpdateTime: Date;
  estimatedTime: number;
  registeredBy: string;
  registeredDate: Date;
  errorCode: number;
  errorMessage: string;
}

export interface GetDownloadListParams {
  keyword?: string;
}
export interface GetFittingErrorListParams {
  keyword?: string;
  startDate: string;
  endDate: string;
  pageNo?: number;
  pageSize?: number;
}

export interface GetActualFilePathParams {
  styleId: string;
  version: number;
}
