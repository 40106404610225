import { createApi } from '@reduxjs/toolkit/query/react';
import coreApiBaseQuery from 'api/coreApiBaseQuery';
import {
  ConfirmItemResponseData,
  ConnectCategory,
  ItemCategory,
  ItemDownloadInfo,
  ItemFile,
  ItemHistory,
  UpdateItemCategoryPayload,
} from 'types/api/marketplace/confirmItem';
import { convertToSearchApiQueryString } from 'modules/confirmItem';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toastAction } from 'features/toast/toastSlice';
import store from 'store/configureStore';

export const confirmItemApi = createApi({
  reducerPath: 'confirmItemApi',
  baseQuery: coreApiBaseQuery(),
  endpoints(build) {
    return {
      getConfirmItems: build.query<ConfirmItemResponseData, string>({
        query: queryString => ({
          url: `/connects/confirm/items${queryString}`,
          method: 'get',
          onError: error => {
            store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
          },
        }),
      }),
      updateItemMainPage: build.mutation<void, { itemId: string; formData: FormData }>({
        query: ({ itemId, formData }) => {
          return {
            url: `/connects/confirm/items/${itemId}/mainpage`,
            method: 'put',
            data: formData,
          };
        },
      }),
      updateItemMdPick: build.mutation<void, { itemId: string; mdPick: boolean }>({
        query: ({ itemId, mdPick }) => ({
          url: `/connects/confirm/items/${itemId}/mdpick?mdPick=${mdPick}`,
          method: 'put',
        }),
      }),
      getItemHistories: build.query<ItemHistory[], { itemId: string }>({
        query: ({ itemId }) => ({
          url: `/connects/confirm/items/${itemId}/histories`,
          method: 'get',
        }),
      }),
      requestItemConfirm: build.mutation<void, { itemId: string; formData: FormData }>({
        query: ({ itemId, formData }) => {
          return {
            url: `/connects/confirm/items/${itemId}/confirm`,
            method: 'post',
            data: formData,
          };
        },
      }),
      getItemFiles: build.query<ItemDownloadInfo, { itemId: string; isRequest: boolean }>({
        query: ({ itemId, isRequest }) => ({
          url: `/connects/confirm/items/${itemId}/files?isRequest=${isRequest}`,
          method: 'get',
        }),
      }),
      /* 아이템들의 메인 파일 다운로드 */
      getItemsFiles: build.query<ItemFile[], { queryString: string }>({
        query: ({ queryString }) => ({
          url: `/connects/confirm/items/files${queryString}`,
          method: 'get',
        }),
      }),
      /* item category */
      getConnectCategories: build.query<ConnectCategory[], void>({
        query: () => ({
          url: '/connects/categories',
          method: 'get',
        }),
      }),
      getItemCategories: build.query<ItemCategory[], { itemId: string }>({
        query: ({ itemId }) => ({
          url: `/connects/confirm/items/${itemId}/categories`,
          method: 'get',
        }),
      }),
      updateItemCategories: build.mutation<
        UpdateItemCategoryPayload[],
        { payload: UpdateItemCategoryPayload[] }
      >({
        query: ({ payload }) => {
          return {
            url: `/connects/confirm/items/categories`,
            method: 'put',
            data: payload,
          };
        },
      }),
    };
  },
});

export const {
  useLazyGetConfirmItemsQuery,
  useUpdateItemMainPageMutation,
  useUpdateItemMdPickMutation,
  useLazyGetItemHistoriesQuery,
  useRequestItemConfirmMutation,
  useLazyGetItemFilesQuery,
  useLazyGetItemsFilesQuery,
  useGetConnectCategoriesQuery,
  useLazyGetItemCategoriesQuery,
  useGetItemCategoriesQuery,
  useUpdateItemCategoriesMutation,
} = confirmItemApi;

export const useRefetchConfirmItemApi = (): { refetchConfirmItems: () => void } => {
  const dispatch = useDispatch();
  const history = useHistory();

  const queryString = convertToSearchApiQueryString(history.location.search);

  const refetchConfirmItems = (): void => {
    dispatch(
      confirmItemApi.endpoints.getConfirmItems.initiate(`?${queryString}`, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
  };

  return {
    refetchConfirmItems,
  };
};
