import * as React from 'react';
import { ReactElement } from 'react';
import styled from 'styled-components';
import ConnectGlobalStyle from '../../styles/ConnectGlobalStyle';
import BackgroundAnimationView from '../../components/signin/BackgroundAnimationView';

const SignInLayout = ({ children }: { children: ReactElement }): ReactElement => {
  return (
    <LayoutWrapper>
      <ConnectGlobalStyle />
      <BackgroundAnimationView />
      <ContentWrapper>{children}</ContentWrapper>
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled.div`
  height: 100vh; /* 세로 폭을 부모 요소의 높이와 동일하게 설정 */
  width: 100vw; /* 가로 폭은 자동으로 설정 */
  overflow: hidden;
  position: relative;
  background-color: #000000;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export default SignInLayout;
