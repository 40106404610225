import * as React from 'react';
import styled from 'styled-components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { StyledBodyTitleCell, StyledHeaderCell } from 'components/common/table/TableStyle';
import { InformationObject } from 'types/common/informationTable';
import { ColorText } from 'components/plansales/billing/detail/invoice/InvoiceTableRenderer';

interface Props {
  title: string;
  data: InformationObject[];
}

const InformationTable = ({ title, data }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <StyledHeaderCell colSpan={2}>{title}</StyledHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(
            (
              {
                title,
                render,
                requiredPoint = false,
                textAlign = 'left',
                titleWidth = '20%',
                isShow = true,
              }: InformationObject,
              index: number,
            ) =>
              isShow && (
                <TableRow key={index}>
                  <StyledBodyTitleCell style={{ width: titleWidth }}>
                    {title}
                    {requiredPoint && <ColorText color={'red'}> *</ColorText>}
                  </StyledBodyTitleCell>
                  <TableCell align={textAlign}>{render}</TableCell>
                </TableRow>
              ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InformationTable;
