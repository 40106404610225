import {
  SellerWithholdingTax,
  SellerWithholdingTaxFilter,
  WithholdingTaxSortType,
} from 'types/marketplace/revenue';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import qs, { ParsedQs } from 'qs';
import { withholdingTaxDataToExcelMultiDataSet } from 'modules/excelTemplate/revenue/withholdingTax';

interface WithholdingTaxState {
  filter: SellerWithholdingTaxFilter;
  loading: boolean;
  data?: SellerWithholdingTax;
  error: boolean;
  excelLoading: boolean;
  excelData?: SellerWithholdingTax;
  excelError: boolean;
}

const initialState: WithholdingTaxState = {
  filter: {
    year: new Date().getFullYear().toString(),
    month: `${new Date().getMonth() + 1}`, // getMonth의 시작월은 0 부터
    sortBy: WithholdingTaxSortType.SELLER_COUNTRY,
    keyword: '',
    pageNo: 1,
  },
  loading: false,
  excelLoading: false,
  error: false,
  excelError: false,
};

export const withholdingTaxSlice = createSlice({
  name: 'withholdingTax',
  initialState: initialState,
  reducers: {
    resetFilter: state => {
      state.filter = initialState.filter;
    },
    search: (state, { payload }: PayloadAction<SellerWithholdingTaxFilter>) => {
      state.filter = payload;
    },
    pageChange: (state, { payload }: PayloadAction<number>) => {
      const pageNo = payload;
      if (!pageNo) {
        state.filter.pageNo = 1;
      }
      state.filter.pageNo = pageNo;
    },
    initialize: (state, { payload }: PayloadAction<ParsedQs>) => {
      const { year, month, keyword, sortBy, pageNo } = payload;

      if (!year) {
        state.filter = initialState.filter;
      } else {
        state.filter = {
          year,
          month,
          keyword: keyword ?? '',
          sortBy: sortBy ?? WithholdingTaxSortType.SELLER_COUNTRY,
          pageNo: pageNo ?? 1,
        } as unknown as SellerWithholdingTaxFilter;
      }
    },
    loadData: state => {
      state.loading = true;
      state.data = undefined;
    },
    loadDataSuccess: (state, { payload }: PayloadAction<SellerWithholdingTax>) => {
      state.loading = false;
      state.data = payload;
      state.error = false;
    },
    loadDataFailure: state => {
      state.loading = false;
      state.data = undefined;
      state.error = true;
    },
    setSortType: (state, { payload }: PayloadAction<WithholdingTaxSortType>) => {
      state.filter.sortBy = payload;
    },
    resetExcelData: state => {
      state.excelLoading = false;
      state.excelData = undefined;
    },
    loadExcelData: state => {
      state.excelLoading = true;
      state.excelData = undefined;
    },
    loadExcelDataSuccess: (state, { payload }: PayloadAction<SellerWithholdingTax>) => {
      state.excelLoading = false;
      state.excelData = payload;
    },
    loadExcelDataFailure: state => {
      state.excelLoading = false;
      state.excelError = true;
    },
  },
});

export const withholdingTaxActions = withholdingTaxSlice.actions;
export const withholdingTaxReducer = withholdingTaxSlice.reducer;

export const selectWithholdingTaxFilter = (state: RootState) => state.revenue.withholdingTax.filter;
export const selectWithholdingTaxQueryString = (state: RootState): string =>
  qs.stringify(state.revenue.withholdingTax.filter);
export const selectWithholdingTaxAllQueryString = (state: RootState) => {
  const filter = state.revenue.withholdingTax.filter;
  return qs.stringify({ ...filter, pageNo: 1, pageSize: 999999999 }, { arrayFormat: 'repeat' });
};
export const selectWithholdingTaxSortBy = (state: RootState) =>
  state.revenue.withholdingTax.filter.sortBy;
export const selectWithholdingTaxError = (state: RootState) => state.revenue.withholdingTax.error;
export const selectWithholdingTaxLoading = (state: RootState) =>
  state.revenue.withholdingTax.loading;
export const selectWithholdingTaxData = (state: RootState) => state.revenue.withholdingTax.data;
export const selectWithholdingTaxExcelLoading = (state: RootState) =>
  state.revenue.withholdingTax.excelLoading;
export const selectWithholdingTaxExcelData = (state: RootState) => {
  const data = state.revenue.withholdingTax.excelData;
  return data ? withholdingTaxDataToExcelMultiDataSet(data) : undefined;
};
