import api from 'api/coreApi';
import { AxiosResponse, AxiosError } from 'axios';
import {
  GetBillingDetailParams,
  GetBillingInvoiceListParams,
  GetBillingInvoiceListResponse,
  PaymentMethod,
  PaymentStatus,
  PostRefundRequestParams,
} from 'types/plansales/billing';
import { FIRST_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'constants/common';
import { GetInvoiceDetailResponse } from 'types/plansales/billing/invoice';
import {
  billingInvoiceItemLabel,
  paymentMethodLabel,
  paymentStatusLabel,
} from 'constants/plansales/billing';
import { advancedInvSuffix } from 'components/plansales/billing/billingtable/DataTableCellRenderer';
import { getUtcDateWithTime, getUtcLocalDateWithTime } from 'modules/utils/time';
import { convertObjectToArrayQuery } from 'modules/utils/queryString';

export const getSearchResult = async (data: GetBillingInvoiceListParams) => {
  const {
    dateInterval: [dateFrom, dateTo],
    keyword,
    paymentMethod,
    payStatus,
    page = FIRST_PAGE_INDEX,
    pageSize = DEFAULT_PAGE_SIZE,
  } = data;
  const baseRequestUrl = `groups/invoices?page=${page}&limit=${pageSize}&dateFrom=${dateFrom}&dateTo=${dateTo}`;

  const paymentMethodQuery = convertObjectToArrayQuery(paymentMethod, 'paymentMethods');
  const payStatusQuery = convertObjectToArrayQuery(payStatus, 'invoicePaymentStatuses');
  const keywordQuery = keyword.length ? `&searchWord=${keyword}` : '';

  const finalRequestUrl = [paymentMethodQuery, payStatusQuery, keywordQuery].reduce(
    (result: string, current: string) => {
      return current.length ? `${result}&${current}` : result;
    },
    baseRequestUrl,
  );

  return api
    .get<GetBillingInvoiceListResponse>(finalRequestUrl)
    .then((res: AxiosResponse<GetBillingInvoiceListResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getBillingDetail = async (data: GetBillingDetailParams) => {
  const { invoiceNo } = data;
  return api
    .get<GetInvoiceDetailResponse>(`/groups/invoices/${invoiceNo}`)
    .then((res: AxiosResponse<GetInvoiceDetailResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getExportData = async (data: GetBillingInvoiceListParams) => {
  const {
    dateInterval: [dateFrom, dateTo],
    keyword,
    paymentMethod,
    payStatus,
    page = FIRST_PAGE_INDEX,
    pageSize = DEFAULT_PAGE_SIZE,
  } = data;
  const baseRequestUrl = `groups/invoices?page=${page}&limit=${pageSize}&dateFrom=${dateFrom}&dateTo=${dateTo}`;

  const paymentMethodQuery = convertObjectToArrayQuery(paymentMethod, 'paymentMethods');
  const payStatusQuery = convertObjectToArrayQuery(payStatus, 'invoicePaymentStatuses');
  const keywordQuery = keyword.length ? `&searchWord=${keyword}` : '';

  const finalRequestUrl = [paymentMethodQuery, payStatusQuery, keywordQuery].reduce(
    (result: string, current: string) => {
      return current.length ? `${result}&${current}` : result;
    },
    baseRequestUrl,
  );

  return api
    .get<GetBillingInvoiceListResponse>(finalRequestUrl)
    .then((res: AxiosResponse<GetBillingInvoiceListResponse>) => {
      const { invoices } = res.data;
      return {
        data: invoices.map(
          ({
            adminUserId,
            invoiceNo,
            groupId,
            groupName,
            adminEmail,
            invoiceItems,
            planType,
            taxAmount,
            totalAmount,
            paymentMethod,
            paymentStatus,
            paymentSuccessedAt,
            pgInvoiceId,
            avalaraDocCode,
          }) => ({
            // excel cols 순서
            'Admin User Id': adminUserId,
            'Invoice No.': invoiceNo,
            'Group Id': groupId,
            'Group Name': groupName,
            'Admin Email': adminEmail,
            'Item(Amount)': invoiceItems
              .map(({ invoiceItemType, amount }) => {
                return `${billingInvoiceItemLabel[invoiceItemType]}${advancedInvSuffix(
                  invoiceItemType,
                  planType,
                )}(${amount})`;
              })
              .join(' / '),
            Tax: taxAmount,
            'Payment Price': totalAmount,
            'Payment Method': paymentMethodLabel[paymentMethod as PaymentMethod],
            'Payment Status': paymentStatusLabel[paymentStatus as PaymentStatus],
            'Payment Date (UTC)': getUtcDateWithTime(paymentSuccessedAt, true),
            'Payment Date (KST)': getUtcLocalDateWithTime(paymentSuccessedAt, true),
            PaymentTxId: pgInvoiceId ?? '',
            'Avalara Doc Code': avalaraDocCode ?? '',
          }),
        ),
      };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const postRefundRequest = async (data: PostRefundRequestParams) => {
  const { invoiceNo, comment } = data;
  return api
    .post<boolean>(`/groups/invoices/${invoiceNo}/refund`, { Comment: comment })
    .then((res: AxiosResponse<boolean>) => res)
    .catch((error: AxiosError<Error>) => ({ error }));
};
