import coreApi from 'api/coreApi';
import { AxiosError } from 'axios';
import {
  GetDetailRevenueResponse,
  GetMonthlyPayoutResponse,
  GetRevenueResponse,
  GetSellerPayoutStatusInfoResponse,
  PutSellerPayoutStatusParams,
} from 'types/api/marketplace/revenue';
import {
  MonthYearParams,
  SellerPayoutDetailInfo,
  SellerWithholdingTax,
  TotalPayoutDetailInfo,
} from 'types/marketplace/revenue';

/**
 * Revenue
 * https://clo.atlassian.net/wiki/spaces/NX/pages/1937309737/Connect+Seller+Revenue+part1
 * author: Henry
 */

//////////* Seller Revenue *//////////

export const getSellerRevenue = (query: string) => {
  return coreApi
    .get<GetRevenueResponse>(`/connects/revenue?${query}`)
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getSellerRevenueDetails = (tab: string, query: string) => {
  return coreApi
    .get<GetDetailRevenueResponse>(`/connects/revenue/details/${tab}?${query}`)
    .catch((error: AxiosError<Error>) => ({ error }));
};

//////////* Monthly Payout *//////////
export const getSellerMonthlyPayout = (query: string) => {
  return coreApi.get<GetMonthlyPayoutResponse>(`/connects/revenue/monthlyPayout?${query}`);
};

export const getSellerPayoutStatusInfo = (userId: number) => {
  return coreApi
    .get<GetSellerPayoutStatusInfoResponse>(
      `/connects/revenue/monthlyPayout/sellerStatus/${userId}`,
    )
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getTotalPayoutDetail = (params: MonthYearParams) => {
  return coreApi
    .get<TotalPayoutDetailInfo>(`/connects/revenue/TotalSellerPayoutDetail`, {
      params,
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getSellerPayoutDetail = ({
  userId,
  year,
  month,
}: MonthYearParams & { userId: number }) => {
  return coreApi
    .get<SellerPayoutDetailInfo>(`/connects/revenue/monthlyPayout/sellerPayoutDetails/${userId}`, {
      params: { year, month },
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const editTotalPayoutConfirm = (payload: MonthYearParams) => {
  return coreApi
    .post<SellerPayoutDetailInfo>(`/connects/revenue/totalSellerPayoutDetail/confirm`, payload)
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const editTotalPayoutExecution = (payload: MonthYearParams) => {
  return coreApi
    .post<SellerPayoutDetailInfo>(`/connects/revenue/totalSellerPayoutDetail/payout`, payload)
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const editSellerPayoutStatus = ({ userId, ...data }: PutSellerPayoutStatusParams) => {
  return coreApi
    .put<GetSellerPayoutStatusInfoResponse>(
      `/connects/revenue/monthlyPayout/sellerStatus/${userId}`,
      data,
      { headers: { 'Content-Type': 'application/json' } },
    )
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getPaypalPayoutCSV = (params: MonthYearParams) => {
  return coreApi
    .get('/connects/Revenue/PaypalPayouts/Csv', {
      params,
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};

/** Test 환경에서만 QA목적으로 사용키로 진행. Reset Payout/Confirm */
export const resetPayoutConfirm = (data: MonthYearParams) => {
  return coreApi
    .delete('/connects/Revenue/TotalSellerPayoutDetail', { data })
    .catch((error: AxiosError<Error>) => ({ error }));
};

export const getWithholdingTax = (queries: string) => {
  return coreApi
    .get<SellerWithholdingTax>(`/connects/Revenue/WithholdingTax?${queries}`)
    .catch((error: AxiosError<Error>) => ({ error }));
};
