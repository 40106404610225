import * as React from 'react';
import { ReactElement } from 'react';
import { createStyles, Grid, makeStyles, TextField } from '@material-ui/core';
import { ConnectCommunityUserType, ConnectEverywearStatus, ConnectUserType } from 'types/member';
import { CheckObjectValue } from 'types/common';
import {
  CONNECT_ABUSING_USER,
  connectCommunityUserTypeOption,
  connectEverywearStatusOption,
  connectUserTypeOption,
} from 'constants/member/connect';
import MultipleCheckboxFilter from 'components/common/searchfilter/MultipleCheckboxFilter';
import MultipleSelectFilter from 'components/common/searchfilter/MultipleSelectFilter';
import FilterLayout from 'components/common/searchfilter/FilterLayout';
import FilterElementLayout from 'components/common/searchfilter/FilterElementLayout';

interface Props {
  keyword: string;
  onChangeKeyword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDownKeyword: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  connectAbusingUserStatus: CheckObjectValue;
  connectEverywearStatus: CheckObjectValue;
  connectUserType: CheckObjectValue;
  connectCommunityUserType: CheckObjectValue;
  onChangeConnectAbusingUser: (values: string[]) => void;
  onChangeConnectEverywearStatus: (values: ConnectEverywearStatus[]) => void;
  onChangeConnectUserType: (values: ConnectUserType[]) => void;
  onChangeConnectCommunityUserType: (values: ConnectCommunityUserType[]) => void;
  onClickSearch: () => void;
  onClickReset: () => void;
}

const SearchFilter = ({
  keyword,
  onChangeKeyword,
  onKeyDownKeyword,
  connectAbusingUserStatus,
  connectEverywearStatus,
  connectUserType,
  connectCommunityUserType,
  onChangeConnectEverywearStatus,
  onChangeConnectAbusingUser,
  onChangeConnectUserType,
  onChangeConnectCommunityUserType,
  onClickSearch,
  onClickReset,
}: Props): ReactElement => {
  const connectAbusingUserCheckboxes = Object.entries(connectAbusingUserStatus).map(
    ([key, value]) => {
      return value ? key : '';
    },
  );

  const connectEverywearStatusCheckboxes = Object.entries(connectEverywearStatus)
    .filter(([_, value]) => value)
    .map(([key, _]) => Number(key));

  const connectUserTypes = Object.entries(connectUserType)
    .filter(([_userType, isChecked]) => isChecked)
    .map(([userType, _isChecked]) => Number(userType));

  const connectCommunityUserTypes = Object.entries(connectCommunityUserType)
    .filter(([_userType, isChecked]) => isChecked)
    .map(([userType, _isChecked]) => Number(userType));

  const handleChangeConnectAbusing = (selectedValues: string[]): void => {
    onChangeConnectAbusingUser(selectedValues);
  };

  const handleChangeEverywearStatus = (selectedValues: ConnectEverywearStatus[]): void => {
    onChangeConnectEverywearStatus(selectedValues);
  };

  const classes = makeStyles(() =>
    createStyles({
      root: {
        width: '30%',
      },
    }),
  )();

  return (
    <FilterLayout getSearchResult={onClickSearch} resetFilter={onClickReset}>
      <Grid container direction={'row'} alignItems={'center'}>
        <Grid item xs={10}>
          <FilterElementLayout label={'Keyword'} gridSize={3}>
            <TextField
              classes={classes}
              placeholder="Email, External, Group ID"
              value={keyword}
              onChange={onChangeKeyword}
              onKeyDown={onKeyDownKeyword}
            />
          </FilterElementLayout>
        </Grid>
      </Grid>
      <Grid container direction={'row'} alignItems={'center'}>
        <Grid item xs={6}>
          <MultipleCheckboxFilter<string>
            statuses={connectAbusingUserCheckboxes}
            onChangeChecked={(values: string[]) => handleChangeConnectAbusing(values)}
            filterLabel={'CONNECT Abusing User'}
            checkedOptions={CONNECT_ABUSING_USER}
            gridSize={5}
          />
        </Grid>
      </Grid>
      <Grid container direction={'row'} alignItems={'center'}>
        <Grid item xs={6}>
          <MultipleCheckboxFilter<ConnectEverywearStatus>
            statuses={connectEverywearStatusCheckboxes}
            onChangeChecked={(values: ConnectEverywearStatus[]) =>
              handleChangeEverywearStatus(values)
            }
            filterLabel={'CONNECT EveryWear Status'}
            checkedOptions={connectEverywearStatusOption}
            gridSize={5}
          />
        </Grid>
      </Grid>
      <Grid container direction={'row'} alignItems={'center'}>
        <Grid item xs={6}>
          <MultipleSelectFilter<ConnectUserType>
            label={'CONNECT User Type'}
            values={connectUserTypes}
            options={connectUserTypeOption}
            onChange={onChangeConnectUserType}
            gridSize={5}
          />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelectFilter<ConnectCommunityUserType>
            label={'CONNECT Community User Type'}
            values={connectCommunityUserTypes}
            options={connectCommunityUserTypeOption}
            onChange={onChangeConnectCommunityUserType}
            gridSize={5}
          />
        </Grid>
      </Grid>
    </FilterLayout>
  );
};

export default SearchFilter;
