import { combineReducers } from '@reduxjs/toolkit';
import {
  midBannerFormReducer,
  MidBannerFormState,
} from 'features/marketplace/operation/midBanner/midBannerFormSlice';
import {
  midBannerReducer,
  MidBannerState,
} from 'features/marketplace/operation/midBanner/midBannerSlice';
import {
  sectionManageReducer,
  SectionManageState,
} from 'features/marketplace/operation/sectionManage/sectionManageSlice';
import {
  mainBannerFormReducer,
  MainBannerFormState,
} from 'features/marketplace/operation/mainBannerForm/mainBannerFormSlice';
import { mainBannerReducer } from './operation/mainBanner/mainBannerSlice';

export interface MarketPlaceState {
  sectionManage: SectionManageState;
  midBanner: MidBannerState;
  midBannerForm: MidBannerFormState;
  mainBannerForm: MainBannerFormState;
}

export default combineReducers({
  sectionManage: sectionManageReducer,
  midBanner: midBannerReducer,
  mainBanner: mainBannerReducer,
  midBannerForm: midBannerFormReducer,
  mainBannerForm: mainBannerFormReducer,
});
