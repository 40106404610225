import Cookies from 'universal-cookie';
import { tokenName } from './config';
const isBrowser = typeof window !== 'undefined';

// TODO Type(any)
export const setCookie = (key: any, value: any, options = {}) => {
  if (isBrowser) {
    const cookies = new Cookies();
    cookies.set(key, value, { path: '/', ...options });
  }
};

export const setJWTToken = (token: string) => {
  setCookie(tokenName, token, {
    path: '/',
    sameSite: 'lax',
    domain: '.clo-set.com',
    maxAge: 60 * 60 * 24 * 15, // 15 days
  });
};
export const removeCookie = (key: any) => {
  if (isBrowser) {
    const cookies = new Cookies();
    cookies.remove(key, { path: '/', domain: '.clo-set.com' });
  }
};

export const getCookie = (key: any, cookie?: any) => {
  return isBrowser ? getCookieFromBrowser(key) : getCookieFromServer(key, cookie);
};

const getCookieFromBrowser = (key: any) => {
  const cookies = new Cookies();
  return cookies.get(key);
};

const getCookieFromServer = (key: any, cookie: any) => {
  const cookies = new Cookies(cookie);
  return cookies.get(key);
};
