import api from 'api/coreApi';
import { AxiosError, AxiosResponse } from 'axios';
import { ArtworkParams, ArtworkResponse } from 'types/api/jinny';

export const getArtwork = async () => {
  return api
    .get('/Jinny/Artwork')
    .then((res: AxiosResponse<ArtworkResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({
      error,
    }));
};

export const editArtwork = async ({ formData }: ArtworkParams) => {
  return api
    .post('/Jinny/Artwork', formData)
    .then((res: AxiosResponse<ArtworkResponse>) => {
      return { data: res.data };
    })
    .catch((error: AxiosError<Error>) => ({ error }));
};
