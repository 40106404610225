import { TableBody } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import DataTableRow from 'components/common/table/datatable/DataTableRow';
import NoResultRow from 'components/common/table/datatable/NoResultRow';
import SpinnerRow from 'components/common/table/datatable/SpinnerRow';
import * as React from 'react';
import { DataObject, TableCell } from 'types/common/table';

interface Props<T> {
  dataList?: T[];
  columns: TableCell<T>[];
  loading?: boolean;
  spinnerSize?: number;
  rowStyle?: (rowData: T) => CSSProperties;
}

const NewDataTableBody = <T extends DataObject>({
  dataList,
  columns,
  loading,
  spinnerSize,
  rowStyle,
}: Props<T>) => {
  if (loading)
    return (
      <TableBody>
        <SpinnerRow colspan={100} spinnerSize={spinnerSize} />
      </TableBody>
    );

  if (dataList?.length === 0)
    return (
      <TableBody>
        <NoResultRow colspan={100} />
      </TableBody>
    );

  return (
    <TableBody>
      {dataList?.map((row, rowIndex) => (
        <DataTableRow
          row={row}
          key={rowIndex}
          rowStyle={rowStyle}
          columns={columns}
          rowIndex={rowIndex}
        />
      ))}
    </TableBody>
  );
};

export default NewDataTableBody;
