export const getEnumKeyByStringValue = <T extends Object>(
  value: string | number,
  enumObject: T,
): string => {
  return Object.keys(enumObject)[Object.values(enumObject).indexOf(value)];
};

export const getEnumValues = <T extends Record<string, string | number>>(enumObj: T): number[] => {
  return Object.keys(enumObj)
    .map(key => enumObj[key as keyof T])
    .filter(value => typeof value === 'number') as number[];
};
