import { HistoryBoxProps } from 'components/common/modal/itemStatusConfirmModal/HistoryBox';
import {
  ConfirmItemArrayFilterValueType,
  ConfirmItemSearchParams,
  ConfirmStatus,
  FilterForm,
  ItemType,
  ItemTypeSubcategory,
  StandardFormatType,
} from 'types/marketplace/confirmItem';
import { green, grey, purple, red, yellow } from '@material-ui/core/colors';
import { ConnectCategory, FileType, ItemHistory } from 'types/api/marketplace/confirmItem';
import { DateForm } from 'hooks/commons/useSearchFilterForm';
import _cloneDeep from 'lodash/cloneDeep';
import { PageSection } from 'types/api/marketplace/operation/sectionManage';
import {
  arrayValueToQueryString,
  getParsedQuery,
  valueToQueryString,
} from 'modules/utils/queryString';
import {
  ARRAY_VALUE_KEYS,
  avatarSubcategories,
  CATEGORY_VALUE_DIVIDER,
  IGNORE_LETTERS,
  initialSearchParams,
  StyleSubcategory,
} from 'constants/marketplace/confirmItem';
import { CheckboxItem } from 'components/marketplace/confirmitem/searchResult/itemTable/ItemTableBody/itemDataCells/itemcategorycellcontent/garmentcategorymodal/garmentcategoryselects/CheckboxGroup';
import _flatMap from 'lodash/flatMap';
import { Nullable } from 'types/common';
import { NOT_INCLUDED_DATE_TERMS } from 'types/common/searchfilter';
import { replaceIgnoreText, splitTrailingPattern } from 'modules/utils/string';

export const getStatusColor = (status: ConfirmStatus): string => {
  const StatusColor: { [key in ConfirmStatus]: string } = {
    [ConfirmStatus.PENDING]: yellow['700'],
    [ConfirmStatus.CHECKING]: yellow['900'],
    [ConfirmStatus.PUBLISHED]: green['500'],
    [ConfirmStatus.REJECTED]: red['500'],
    [ConfirmStatus.WITHDRAW]: red['800'],
    [ConfirmStatus.BLOCKED]: red['900'],
    [ConfirmStatus.DELETED]: purple['500'],
    [ConfirmStatus.DRAFT]: grey['500'],
  };

  return StatusColor[status];
};

export const convertToHistoryBoxProps = (historyData: ItemHistory[]): HistoryBoxProps[] => {
  return historyData.map(data => {
    const { status, changedBy, comment, rejection, changedDate } = data;
    return {
      status: status.toString(),
      label: ConfirmStatus[status],
      changedBy: changedBy ?? '',
      bulletColor: getStatusColor(status),
      comment: comment ?? '',
      reason: rejection ?? '',
      changedDate,
    };
  });
};

export const getFilterForm = (searchParams: ConfirmItemSearchParams): FilterForm => {
  return _cloneDeep({
    statuses: searchParams.statuses,
    itemTypes: searchParams.itemTypes,
    salesTypes: searchParams.salesTypes,
    softwares: searchParams.softwares,
    mainPages: searchParams.mainPages,
    mdPick: searchParams.mdPick,
    keyword: searchParams.keyword,
    keywordType: searchParams.keywordType,
    digitalStampStatuses: searchParams.digitalStampStatuses,
    contestFilter: searchParams.contestFilter,
    convertingStatuses: searchParams.convertingStatuses,
  });
};

export const getDateForm = (searchParams: ConfirmItemSearchParams): DateForm => {
  return _cloneDeep({
    dateInterval: [searchParams.dateFrom, searchParams.dateTo],
    dateTerm: searchParams.dateTerm,
  });
};

export const getFormattedPageSections = (data: PageSection[]): PageSection[] =>
  data.filter(({ isChecked }) => isChecked).sort((prev, next) => prev.sort - next.sort);

export const getSearchParams = (pageQueryString: string): ConfirmItemSearchParams => {
  const parsedQuery = getParsedQuery(pageQueryString);
  const {
    dateFrom,
    dateTo,
    dateTerm,
    statuses,
    salesTypes,
    itemTypes,
    mainPages,
    softwares,
    mdPick,
    keyword,
    keywordType,
    digitalStampStatuses,
    contestFilter,
    convertingStatuses,
    page,
    sort,
    limit,
  } = initialSearchParams;

  let dateTermQueryValue = dateTerm;
  if (parsedQuery['dateTerm']) {
    const parsedQueryValue = Number(parsedQuery['dateTerm'][0]);
    dateTermQueryValue = parsedQueryValue === NOT_INCLUDED_DATE_TERMS ? null : parsedQueryValue;
  }

  return {
    dateFrom: parsedQuery['dateFrom']?.[0] ?? dateFrom,
    dateTo: parsedQuery['dateTo']?.[0] ?? dateTo,
    dateTerm: dateTermQueryValue,
    statuses: parsedQuery['statuses']
      ? parsedQuery['statuses'].map(status => Number(status))
      : statuses,
    salesTypes: parsedQuery['salesTypes']
      ? parsedQuery['salesTypes'].map(salesType => Number(salesType))
      : salesTypes,
    itemTypes: parsedQuery['itemTypes'] ?? itemTypes,
    mainPages: parsedQuery['mainPages'] ?? mainPages,
    softwares: parsedQuery['softwares']
      ? parsedQuery['softwares'].map(software => Number(software))
      : softwares,
    mdPick: parsedQuery['mdPick'] ? JSON.parse(parsedQuery['mdPick'][0]) : mdPick,
    digitalStampStatuses: parsedQuery['digitalStampStatuses']
      ? parsedQuery['digitalStampStatuses'].map(digitalStamp => Number(digitalStamp))
      : digitalStampStatuses,
    convertingStatuses: parsedQuery['convertingStatuses']
      ? parsedQuery['convertingStatuses'].map(convertingStatus => Number(convertingStatus))
      : convertingStatuses,
    contestFilter: parsedQuery['contestFilter'] ?? contestFilter,
    keyword: parsedQuery['keyword']?.[0] ?? keyword,
    keywordType: parsedQuery['keywordType']?.[0]
      ? Number(parsedQuery['keywordType']?.[0])
      : keywordType,
    page: parsedQuery['page'] ? Number(parsedQuery['page'][0]) : page,
    sort: parsedQuery['sort'] ? Number(parsedQuery['sort'][0]) : sort,
    limit: parsedQuery['limit'] ? Number(parsedQuery['limit'][0]) : limit,
  };
};

// confirm item API의 queryString을 만드는 메소드
export const getSearchApiQueryString = (searchParams: ConfirmItemSearchParams): string => {
  const arrayValueKey = ARRAY_VALUE_KEYS.join('|');

  return Object.entries(searchParams)
    .map(([key, value]) => {
      if (key === 'keyword') {
        return valueToQueryString('searchKeyword', value);
      }
      if (arrayValueKey.includes(key)) {
        let arrayValues = value as Array<ConfirmItemArrayFilterValueType>;
        // avatar type만 최상위 categoryId를 보내줍니다. 참고: https://clo.atlassian.net/browse/CP-1185
        if (key === 'itemTypes' && arrayValues.includes(ItemType.AVATAR)) {
          // avatar subcategory id 제거
          arrayValues = arrayValues.filter(
            itemTypeValue => !avatarSubcategories.includes(itemTypeValue as ItemTypeSubcategory),
          );
        }
        return arrayValueToQueryString(key, arrayValues);
      }
      return valueToQueryString(key, value);
    })
    .filter(querystring => !!querystring)
    .join('&');
};

/* Confirm Page의 queryString을 item을 조회하는 API의 querystring로 변환 */
export const convertToSearchApiQueryString = (pageQueryString: string): string => {
  const searchParams = getSearchParams(pageQueryString);

  return getSearchApiQueryString(searchParams);
};

export const showStatusChangeButton = (status: Nullable<ConfirmStatus>): boolean =>
  status !== null && status !== ConfirmStatus.DRAFT && status !== ConfirmStatus.DELETED;

export const commonCategoryUtils = {
  isSelected: (categoryId: string, checkedValues: string[]): boolean =>
    checkedValues.some(valueDividerLabel => valueDividerLabel.includes(categoryId)),
  getSelectedCount: (children: ConnectCategory[], checkedValues: string[]): number =>
    children.filter(({ categoryId }) => commonCategoryUtils.isSelected(categoryId, checkedValues))
      .length,
  filterSelectedCategories: (
    categories: ConnectCategory[],
    checkedValues: string[],
  ): ConnectCategory[] => {
    const result: ConnectCategory[] = [];
    categories.forEach(category => {
      const selectedChildren = commonCategoryUtils.filterSelectedCategories(
        category.children,
        checkedValues,
      );
      if (
        commonCategoryUtils.isSelected(category.categoryId, checkedValues) ||
        selectedChildren.length > 0
      ) {
        result.push({
          ...category,
          children: selectedChildren,
        });
      }
    });
    return result;
  },
  findCategoriesWithSameCategoryId: (
    categories: ConnectCategory[],
    categoryIdToFilter: string,
  ): ConnectCategory[] => {
    return _flatMap(categories, category => {
      if (category.categoryId === categoryIdToFilter) {
        return category;
      } else if (category.children && category.children.length > 0) {
        return commonCategoryUtils.findCategoriesWithSameCategoryId(
          category.children,
          categoryIdToFilter,
        );
      }
      return [];
    });
  },
  convertToCheckboxItems: ({
    children,
    disabled,
    checkedValues,
  }: {
    children: ConnectCategory[];
    disabled: boolean;
    checkedValues: string[];
  }): CheckboxItem[] => {
    return children.map(({ categoryId, name, flow }) => {
      return {
        label: name,
        value: `${categoryId}${CATEGORY_VALUE_DIVIDER}${flow.join(' > ')}`,
        disabled: commonCategoryUtils.isSelected(categoryId, checkedValues) ? false : disabled,
      };
    });
  },
};

export const isSingleCategory = (categoryId: string): boolean =>
  categoryId === StyleSubcategory.SINGLE;

/**
 * replace from FileName to ItemTitle
 * @param {string} fileName
 * @param {string} itemTitle
 * @param {FileType} fileType
 */
export const convertItemTitleFromFileName = (
  fileName: string,
  itemTitle: string,
  fileType: FileType,
): string => {
  const lastIndexOf = fileName.lastIndexOf('.');
  let [pureFileName, fileExtension]: string[] = [fileName, ''];
  if (lastIndexOf !== -1) {
    [pureFileName, fileExtension] = [
      fileName.slice(0, lastIndexOf),
      fileName.slice(lastIndexOf + 1),
    ];
  }

  switch (fileType) {
    case FileType.ZIP:
    case FileType.FILE:
      return `${replaceIgnoreText(itemTitle, IGNORE_LETTERS)}.${fileExtension}`;
    case FileType.STANDARD_FILE: {
      const resultSplitText = splitTrailingPattern(pureFileName, StandardFormatType);
      const [_, standardFormatExtension] = resultSplitText ?? ['', ''];
      return `${replaceIgnoreText(
        itemTitle,
        IGNORE_LETTERS,
      )}${standardFormatExtension}.${fileExtension}`;
    }
    default:
      return fileName;
  }
};
