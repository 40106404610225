import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  memberDetailActions,
  selectCouponList,
  selectCouponListLoading,
  selectedIsDeletedAccount,
} from 'features/member/reducer/memberDetailSlice';
import CouponList from 'components/memberdetail/detail/coupon/CouponList';
import AddCouponModal from 'components/memberdetail/detail/coupon/AddCouponModal';
import { getUtcDateDash, getUtcDateDashDifference, isValidDateFormat } from 'modules/utils/time';
import { DateInterval } from 'types/plansales/billing';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { isValidDate } from 'modules/billing';
import InformModal from 'components/common/modal/InformModal';
import { FIRST_PAGE_INDEX } from 'constants/common';

interface Props {
  userId: string;
}

const defaultInterval = [
  getUtcDateDash(new Date()),
  getUtcDateDashDifference(new Date(), 1, 'week', 'add'),
];

const CouponContainer = ({ userId }: Props) => {
  const dispatch = useDispatch();
  const { items: couponList, totalPages, totalItems } = useSelector(selectCouponList);
  const couponListLoading = useSelector(selectCouponListLoading);
  const [couponModalOpen, setCouponModalOpen] = useState<boolean>(false);
  const [couponDateInterval, setCouponDateInterval] = useState<string[]>(defaultInterval);
  const [isUnlimited, setIsUnlimited] = useState<boolean>(false);
  const [couponAmount, setCouponAmount] = useState<string>('');
  const [couponDescription, setCouponDescription] = useState<string>('');
  const [invalidDateModalOpen, setInvalidDateModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(FIRST_PAGE_INDEX);
  const isDeletedAccount = useSelector(selectedIsDeletedAccount);
  const onChangeDisplay = (event: React.ChangeEvent, expanded: boolean, name: string) => {
    if (expanded) {
      name === 'coupon' && dispatch(memberDetailActions.getCouponList({ userId: userId }));
    }
  };

  const saveNewCoupon = () => {
    if (couponAmount.length === 0 || couponDescription.length === 0) {
      setErrorMessage('Amount and description are required.');
      setInvalidDateModalOpen(true);
      return;
    }
    setCouponModalOpen(false);
    dispatch(
      memberDetailActions.addNewCoupon({
        userId: userId,
        amount: Number(couponAmount),
        dateInterval: couponDateInterval,
        isUnlimited,
        description: couponDescription,
        reFetch: true,
      }),
    );
    setCouponAmount('');
    setCouponDescription('');
    setIsUnlimited(false);
    setCouponDateInterval(defaultInterval);
  };

  const onChangeValue = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>) => {
    name === 'amount' && setCouponAmount(value);
    name === 'description' && setCouponDescription(value);
  };

  const onChangeUnlimited = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    const [couponFromDate] = couponDateInterval;
    setIsUnlimited(checked);
    if (checked)
      setCouponDateInterval([
        couponFromDate,
        getUtcDateDashDifference(couponFromDate, 100, 'year', 'add'),
      ]);
    else
      setCouponDateInterval([
        couponFromDate,
        getUtcDateDashDifference(couponFromDate, 1, 'week', 'add'),
      ]);
  };

  const onChangeCouponDate = (type: DateInterval) => (newDateValue: MaterialUiPickersDate) => {
    if (isValidDateFormat(newDateValue)) {
      const [couponFromDate, couponToDate] = couponDateInterval;

      if (isValidDate({ type, newDateValue, from: couponFromDate, to: couponToDate })) {
        setErrorMessage('Please set the proper inquiry period.');
        setInvalidDateModalOpen(true);
        return;
      }
      const newDateDashFormat = getUtcDateDash(newDateValue);
      setCouponDateInterval(
        type === DateInterval.FROM
          ? [newDateDashFormat, couponToDate]
          : [couponFromDate, newDateDashFormat],
      );
    }
  };

  const onChangeCouponListPage = (pageIndex: number) => {
    // addQueryString({ history, name: 'page', value: pageIndex });
    setCurrentPageIndex(pageIndex);
    dispatch(memberDetailActions.getCouponList({ userId: userId, page: pageIndex }));
  };

  return (
    <>
      <InformModal
        open={invalidDateModalOpen}
        onClose={() => setInvalidDateModalOpen(false)}
        description={errorMessage}
      />
      <AddCouponModal
        couponDateInterval={couponDateInterval}
        couponModalOpen={couponModalOpen}
        setCouponModalOpen={setCouponModalOpen}
        setCouponDateInterval={setCouponDateInterval}
        isUnlimited={isUnlimited}
        setIsUnlimited={setIsUnlimited}
        saveNewCoupon={saveNewCoupon}
        couponAmount={couponAmount}
        couponDescription={couponDescription}
        onChangeValue={onChangeValue}
        onChangeUnlimited={onChangeUnlimited}
        onChangeCouponDate={onChangeCouponDate}
      />
      <CouponList
        couponList={couponList}
        setCouponModalOpen={setCouponModalOpen}
        onChangeDisplay={onChangeDisplay}
        couponListLoading={couponListLoading}
        onChangePage={onChangeCouponListPage}
        totalPages={totalPages}
        currentPageIndex={currentPageIndex}
        addButtonDisabled={isDeletedAccount}
        totalItems={totalItems}
      />
    </>
  );
};

export default CouponContainer;
