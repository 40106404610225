import { ReactText } from 'react';
import { Plan } from 'types/member';
import { BillingAddress, ClosetPlanType, PaymentMethod } from 'types/plansales/billing/index';

export enum BillType {
  CREDIT = 1,
  CHARGE = 2,
  REFUND = 3,
}

export enum ExtraDescriptionStatus {
  NO_MESSAGE = -1,
  SHOW_PAYING_USER,
  SHOW_CREDIT_SETTLEMENT,
}

export enum BillDetailType {
  ADD_MEMBER = 1, // 2 Additional Paying Users
  DELETE_MEMBER = 2, // 1 Paying User Removed
  FIRST_SUBSCRIPTION = 3, // Started the Advanced Plan
  RENEW_SUBSCRIPTION = 4, // Renewed the Advanced Plan
  DOWNGRADE_SUBSCRIPTION = 5, // Downgrade to free plan
}

export enum PaymentStatus {
  CREATED = -2, // db 에만 invoice 있고, stripe 에는 아직 생성 전 일때 (백엔드 내부적으로만 사용되는 코드이며, 싱크를 맞추면 좋겠다고 해서 추가해둠 by. Ted)
  FAIL = -1,
  TRIED_YET = 0, // db 에 invoice 가 생성되고, stripe 에도 invoice 생성되었으나 아직 해당 invoice 가 결제진행되지는 않은 경우
  SUCCESS = 1,
  REFUND = 2,
  VOID = 3,
}

export enum InvoiceItemType {
  FIRST_SUBSCRIPTION = 1,
  RENEW_SUBSCRIPTION = 2,
  CARRY_OVER_BALANCE = 3,
  DOWNGRADE_SUBSCRIPTION = 4,
  REFUND = 5,
}

export interface InvoiceDescriptionInformation {
  summaryMessage: { languageKey: string; values?: { [key: string]: ReactText } };
  additionalMessage?: { languageKey: string; values?: { [key: string]: ReactText } };
}

//  description 관리용
export enum InvoiceDescriptionStatus {
  NO_CASE = -1,
  // pay
  START_PAID_BY_ONLY_CARD,
  START_PAID_BY_MULTI_METHOD,
  START_PAID_BY_ONLY_CREDIT,
  RENEW_PAID_BY_ONLY_CARD,
  RENEW_PAID_BY_MULTI_METHOD,
  RENEW_PAID_BY_ONLY_CREDIT,
  // refund
  REFUND_START_PAID_BY_ONLY_CARD,
  REFUND_START_PAID_BY_MULTI_METHOD,
  REFUND_START_PAID_BY_ONLY_CREDIT,
  REFUND_RENEW_PAID_BY_ONLY_CARD,
  REFUND_RENEW_PAID_BY_MULTI_METHOD,
  REFUND_RENEW_PAID_BY_ONLY_CREDIT,
  // etc
  DOWNGRADE_WITH_MINUS_CREDIT,
}

export interface InvoiceItem {
  invoiceItemType: InvoiceItemType;
  amount: number;
  quantity: number;
  unitPrice: number;
  groupPlan: GroupPlan;
  refunded?: boolean;
}

export type GroupPlan = Omit<Plan, 'orderType'>;

export type InvoiceItemDetail = { [key: number]: InvoiceItem | undefined };

export type PaymentInformation = {
  paymentMethod: PaymentMethod;
  last4CardNo: string;
  stripeTaxId: string;
};

export type RefundInformation = {
  refundedAt: string;
  comment: string;
  registeredBy: string;
};

export type GetInvoiceDetailResponse = {
  registeredAt: string;
  avalaraDocCode: string | null;
  billingAddress: BillingAddress | null;
  groupInvoicePayment: PaymentInformation | null;
  groupId: string;
  invoiceNo: string;
  groupName: string;
  adminEmail: string;
  adminUserId: number;
  planType: ClosetPlanType; //GroupPlanType;
  taxAmount: number;
  totalAmount: number;
  subtotalAmount: number;
  paymentMethod: number;
  paymentStatus: number;
  paymentSuccessedAt: string;
  invoiceItems: InvoiceItem[];
  refund: RefundInformation | null;
  refundedAt: string | null;
  pgInvoiceId: string | null;
} & { invoiceItemDetail: InvoiceItemDetail };
