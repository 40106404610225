import * as React from 'react';
import { ReactElement } from 'react';
import { TypoHeader } from '@closet-design-system/core';
import OnePieceLayout from 'components/marketplace/operation/contest/preview/desktop/onepiecelayout/OnePieceLayout';
import { Nullable } from 'types/common';
import { Title } from 'components/marketplace/operation/contest/preview/ContestAwardsTab';
import {
  ContestBadge,
  ContestLayoutProps,
  LayoutBaseType,
  LayoutType,
} from 'types/marketplace/operation/contest';
import { getEnumKeyByStringValue } from 'modules/utils/enum';
import TwoThreePieceLayout from 'components/marketplace/operation/contest/preview/desktop/common/TwoThreePieceLayout';
import { getLayoutBaseTypeKey } from 'modules/contest';

interface Props {
  awardTitle: string;
  awardLayouts: Nullable<ContestLayoutProps[]>;
  badgeId: ContestBadge;
}

const DesktopAwardsTabContent = ({ awardTitle, awardLayouts, badgeId }: Props): ReactElement => {
  return (
    <>
      <Title>
        <TypoHeader variant={[5, 5, 3, 2]}>{awardTitle}</TypoHeader>
      </Title>
      {awardLayouts?.map(({ layoutId, layoutType, items }) => {
        const layoutTypeKey = getEnumKeyByStringValue(layoutType, LayoutType);
        const layoutBaseTypeKey = getLayoutBaseTypeKey(layoutTypeKey);
        const layoutBaseType = LayoutBaseType[layoutBaseTypeKey];

        switch (layoutBaseType) {
          case LayoutBaseType.ONE_PIECE:
            return (
              <OnePieceLayout
                key={layoutId}
                items={items}
                layoutType={layoutType}
                badgeId={badgeId}
              />
            );
          case LayoutBaseType.TWO_PIECE:
            return (
              <TwoThreePieceLayout
                key={layoutId}
                layoutType={layoutType}
                badgeId={badgeId}
                items={items}
                layoutBaseType={layoutBaseType}
              />
            );
          case LayoutBaseType.THREE_PIECE:
            return (
              <TwoThreePieceLayout
                key={layoutId}
                layoutType={layoutType}
                badgeId={badgeId}
                items={items}
                layoutBaseType={layoutBaseType}
              />
            );
        }
      })}
    </>
  );
};

export default DesktopAwardsTabContent;
