import * as React from 'react';
import { TableHead } from '@material-ui/core';
import { TableCell, DataObject } from 'types/common/table';
import DataTableHeaderRow from 'components/common/table/datatable/DataTableHeaderRow';

interface Props<T> {
  header?: TableCell<T>[];
  headers?: TableCell<T>[][]; // colSpan, rowSpan 을 사용하여 header 가 2줄 이상일 경우
}

const DataTableHeader = <T extends DataObject>({ header, headers }: Props<T>) => {
  return (
    <TableHead>
      {header && <DataTableHeaderRow cells={header} />}
      {headers && headers.map((header, index) => <DataTableHeaderRow cells={header} key={index} />)}
    </TableHead>
  );
};

export default DataTableHeader;
