import * as React from 'react';
import styled from 'styled-components';
import {
  ClosetPlanType,
  InvoiceDescriptionStatus,
  PaymentMethod,
  PaymentStatus,
} from 'types/plansales/billing';
import { NULL_TEXT } from 'constants/common';
import { paymentMethodLabel, paymentStatusLabel, planTypeLabel } from 'constants/plansales/billing';
import { getBlankUtcAndKstTime, getUtcAndKstTime } from 'modules/billing';
import InformationTable from 'components/common/table/InformationTable';
import { InformationObject } from 'types/common/informationTable';
import { getUserNameFromEmail } from 'modules/utils/user';

interface Props {
  invoiceNo: string;
  paymentStatus: PaymentStatus;
  registeredDate: string;
  paymentSucceedDate: string | null;
  paymentRefundDate: string | null;
  adminEmail: string;
  refundRequestedBy?: string;
  last4CardNumber: string;
  paymentMethod: PaymentMethod;
  paymentId: string;
  avalaraDocCode: string;
  isPending: boolean;
  isRefunded: boolean;
  isVoid: boolean;
  planType: ClosetPlanType;
  invoiceStatus: InvoiceDescriptionStatus;
  paymentInvoiceId: string | null;
  taxAmount: number;
}

const InvoiceInformation = ({
  invoiceNo,
  paymentStatus,
  registeredDate,
  paymentSucceedDate,
  paymentRefundDate,
  adminEmail,
  refundRequestedBy,
  paymentId,
  paymentMethod,
  avalaraDocCode,
  last4CardNumber,
  isPending,
  isRefunded,
  isVoid,
  planType,
  invoiceStatus,
  paymentInvoiceId,
  taxAmount,
}: Props) => {
  const showPaymentDate = ![
    PaymentStatus.VOID,
    PaymentStatus.TRIED_YET,
    PaymentStatus.FAIL,
  ].includes(paymentStatus);
  const isPaidByCreditOnly =
    // credit 결제 표시 케이스
    // 1. advanced plan inv
    // 2. 실제 credit 으로 결제한 경우
    planType === ClosetPlanType.ADVANCED_PLAN_INV ||
    paymentMethod === PaymentMethod.CREDITS ||
    [
      InvoiceDescriptionStatus.START_PAID_BY_ONLY_CREDIT,
      InvoiceDescriptionStatus.RENEW_PAID_BY_ONLY_CREDIT,
    ].includes(invoiceStatus);
  const isAdvancedPlanINV = planType === ClosetPlanType.ADVANCED_PLAN_INV;
  const showRefundOperator = isRefunded || isVoid;

  const pendingCheck = (value: string | React.ReactNode) => {
    return isPending || isVoid ? NULL_TEXT : value;
  };

  const paymentMethodText = isPaidByCreditOnly
    ? paymentMethodLabel[PaymentMethod.CREDITS]
    : `${paymentMethodLabel[paymentMethod]} : Card Ending in ${last4CardNumber}`;

  const invoiceInformationData: InformationObject[] = [
    { title: 'Plan', render: planTypeLabel[planType], titleWidth: '30%' },
    {
      title: 'Invoice No.',
      render: invoiceNo,
      titleWidth: '30%',
    },
    {
      title: 'Invoice Creation Date',
      render: getUtcAndKstTime(registeredDate),
      titleWidth: '30%',
    },
    { title: 'Admin Email', render: adminEmail, titleWidth: '30%' },
    {
      title: 'Status',
      render: showRefundOperator ? (
        <>
          <PointText>{paymentStatusLabel[paymentStatus]}</PointText>
          <br />
          Confirmed by <Capitalize>{getUserNameFromEmail(refundRequestedBy)}</Capitalize>
          <br />
          {paymentRefundDate ? getUtcAndKstTime(paymentRefundDate) : getBlankUtcAndKstTime()}
        </>
      ) : (
        paymentStatusLabel[paymentStatus]
      ),
      titleWidth: '30%',
    },
    {
      title: 'Payment Method',
      render: isAdvancedPlanINV
        ? paymentMethodLabel[PaymentMethod.CREDITS]
        : pendingCheck(paymentMethodText),
      titleWidth: '30%',
    },
    {
      title: 'Payment Invoice No.',
      render: isPaidByCreditOnly ? NULL_TEXT : pendingCheck(paymentInvoiceId),
      titleWidth: '30%',
    },
    {
      title: 'PaymentTxId',
      render: isPaidByCreditOnly ? NULL_TEXT : pendingCheck(paymentId),
      titleWidth: '30%',
    },
    {
      title: 'AvalaraDocCode',
      render: taxAmount === 0 || isPaidByCreditOnly ? NULL_TEXT : pendingCheck(avalaraDocCode),
      titleWidth: '30%',
    },
    {
      title: 'Payment Date',
      render:
        showPaymentDate && paymentSucceedDate
          ? getUtcAndKstTime(paymentSucceedDate)
          : getBlankUtcAndKstTime(),
      titleWidth: '30%',
    },
  ];
  return <InformationTable title={'Info'} data={invoiceInformationData} />;
};

export default InvoiceInformation;

const PointText = styled.span`
  font-weight: 600;
  color: red;
`;

export const Capitalize = styled.span`
  text-transform: capitalize;
`;
