import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
} from '@material-ui/core';
import * as React from 'react';
import { useContext } from 'react';
import { CheckboxContextGroupChangeEventHandler } from 'types/common/checkbox';
import { CheckboxGroupContext } from './CheckboxGroup';
import styled from '@emotion/styled';
import { spacing } from '@material-ui/system';

export interface CheckboxProps<T = boolean>
  extends Omit<MuiCheckboxProps, 'onChange' | 'size' | 'value'> {
  /** 선택된 값 (boolean 권장) */
  value: T;
  /** checkbox 클릭 callback 함수 (단독사용시 필수값) */
  onChange?: CheckboxContextGroupChangeEventHandler<T>;
  /** checkbox의 label text*/
  label?: string | React.ReactNode;
}

function CheckboxInner<T = boolean>(
  { value, onChange, label = '', ...checkBoxProps }: CheckboxProps<T>,
  ref: React.Ref<HTMLInputElement>,
) {
  const { hasGroup, value: groupValue, onChange: groupOnChange } = useContext(CheckboxGroupContext);

  const checked = Boolean(hasGroup ? groupValue?.includes(value) : value);
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    if (hasGroup) {
      groupOnChange?.(event, value, checked);
    } else {
      onChange?.(event, value, checked);
    }
  };

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          {...checkBoxProps}
          inputRef={ref}
          value={value}
          onChange={changeHandler}
          checked={checked}
        />
      }
      label={label}
    />
  );
}

const Checkbox = React.forwardRef(CheckboxInner) as <T = boolean>(
  props: CheckboxProps<T> & { ref?: React.Ref<HTMLInputElement> },
) => ReturnType<typeof CheckboxInner>;

const StyledCheckbox = styled(Checkbox)(spacing);

export default StyledCheckbox;
