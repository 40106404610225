import * as React from 'react';
import FilterContents from 'components/plansales/billing/searchfilter/FilterContents';
import { Dispatch, SetStateAction, ChangeEvent } from 'react';
import { PaymentMethod, PaymentStatus } from 'types/plansales/billing';
import FilterLayout from 'components/common/searchfilter/FilterLayout';
import { SearchDateTerms } from 'types/common/searchfilter';
import { CheckObjectValue } from 'types/common';

interface Props {
  dateInterval: string[];
  setDateInterval: Dispatch<SetStateAction<string[]>>;
  payStatus: CheckObjectValue;
  onChangePayStatus: (status: PaymentStatus) => (e: ChangeEvent<HTMLInputElement>) => void;
  paymentMethod: CheckObjectValue;
  onChangePaymentMethod: (status: PaymentMethod) => (e: ChangeEvent<HTMLInputElement>) => void;
  keyword: string;
  resetFilter: () => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  getSearchResult: (newKeyword?: string) => void;
  selectedShortcut: SearchDateTerms | null;
  setSelectedShortcut: Dispatch<SetStateAction<SearchDateTerms | null>>;
  onKeyDownKeyword: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const SearchFilter = ({
  dateInterval,
  setDateInterval,
  payStatus,
  paymentMethod,
  keyword,
  onChangePayStatus,
  onChangePaymentMethod,
  resetFilter,
  onChangeKeyword,
  getSearchResult,
  selectedShortcut,
  setSelectedShortcut,
  onKeyDownKeyword,
}: Props) => (
  <FilterLayout resetFilter={resetFilter} getSearchResult={getSearchResult}>
    <FilterContents
      dateInterval={dateInterval}
      setDateInterval={setDateInterval}
      payStatus={payStatus}
      paymentMethod={paymentMethod}
      keyword={keyword}
      onChangePayStatus={onChangePayStatus}
      onChangePaymentMethod={onChangePaymentMethod}
      onChangeKeyword={onChangeKeyword}
      selectedShortcut={selectedShortcut}
      setSelectedShortcut={setSelectedShortcut}
      onKeyDownKeyword={onKeyDownKeyword}
    />
  </FilterLayout>
);

export default SearchFilter;
