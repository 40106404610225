import * as api from 'api/marketplace/netIncome';
import {
  commissionNetIncomeActions,
  selectCommissionNetIncomeQueryString,
} from 'features/netincome/commissionNetIncomeSlice';
import { toastAction } from 'features/toast/toastSlice';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosError } from 'axios';

const { loadData, loadDataSuccess, loadDataFailure, search, resetFilter, initialize } =
  commissionNetIncomeActions;

function* loadDataSaga() {
  const query: string = yield select(selectCommissionNetIncomeQueryString);

  try {
    const { data } = yield call(api.getCommissionNetIncome, query);
    yield put(loadDataSuccess(data));
  } catch (error) {
    yield put(toastAction.errorResponse(error as AxiosError));
    yield put(loadDataFailure());
  }
}

function* updateDataSaga() {
  yield put(loadData());
}

export default function* itemNetIncomeSaga() {
  yield all([
    takeLatest(initialize, updateDataSaga),
    takeLatest(loadData, loadDataSaga),
    takeLatest(search, updateDataSaga),
    takeLatest(resetFilter, updateDataSaga),
  ]);
}
