import { GetDataListResponse, GetPageParams } from 'types/api';
import { BillingAddress, ClosetPlanType } from 'types/plansales/billing';
import { CheckObjectValue, Nullable } from 'types/common';
import { CouponStatus } from 'constants/member/connect';

export enum OrderType {
  NONE = 0,
  ONLINE,
  BACK_ORDER,
}

export type PostCouponInformationParams = {
  userId: string;
  amount: number;
  dateInterval: string[];
  isUnlimited: boolean;
  description: string;
  reFetch?: boolean;
};

export type Plan = {
  planType: ClosetPlanType;
  planStartDate: string;
  planEndDate: string;
  orderType: OrderType;
};
export interface CurrentPlan extends Plan {
  invalidDate: boolean;
  seq: number;
}

export type Member = {
  userid: number;
  status: number;
  name: string;
  memberStatus: string;
  email: string;
  marketingAgree: boolean;
  registrationDate: string;
  marketingAgreeDate: string;
  connectMarketingAgree: boolean;
  connectMarketingAgreeDate: string;
  externalProviders: AuthProvider[];
  connectUserStatus: ConnectUserStatus;
  connectCommunityUserStatus: ConnectCommunityUserStatus;
  connectUserType: ConnectUserType;
  connectCommunityUserType: ConnectCommunityUserType;
  connectUserSupplierTypes: ConnectUserSupplierType[];
  connectBlockChainWalletAddress: Nullable<string>;
  everyWearCreatorStatus: Nullable<ConnectEverywearStatus>;
  verifyToken: string | null;
};

export type MemberExcel = { externalProviders: string } & Omit<Member, 'externalProviders'>;

export type GetMemberExcelResponse = GetDataListResponse<MemberExcel>;

export type LoginHistory = {
  loginDate: string;
  seq: number;
  userid: number;
};

// 현재(2022/02/18) backend 에서 관리하는 Login provider
// https://clo.atlassian.net/browse/CLOSET-4066?focusedCommentId=47310
export enum LoginProvider {
  CLOSET = 0,
  FACEBOOK = 1,
  GOOGLE = 2,
  CLO = 3,
  MD = 4,
  GROUP_TOKEN = 5,
  NIKE_OKTA = 6,
  HUGOBOSS_ADFS = 105,
  ENT_DEMO_ADFS = 99999,
}

export type AuthProvider = {
  authProvider: number;
  authProviderName: string;
  providerKey: string | null;
  registrationDate: string;
};

export type Coupon = {
  amount: number;
  couponDesc: string;
  couponId: string;
  couponType: number;
  createdBy: number;
  createdDate: string;
  expirationDate: string;
  isPublic: boolean;
  status: CouponStatus;
  userid: number;
};

export type GetMembersResponse = GetDataListResponse<Member>;
export type GetLoginHistoryResponse = GetDataListResponse<LoginHistory>;
export type GetCouponListResponse = GetDataListResponse<Coupon>;
export type GetCommentResponse = GetDataListResponse<Comment>;

export type GroupMembers = { [key in 'editor' | 'viewer' | 'collaborator']: number };

export type GetMemberGeneralInformationResponse = Member & {
  groupId: string;
  groupMembers: GroupMembers;
  groupName: string;
  isGroupTrialUsed: boolean;
};

export enum ExcelMemberExport {
  All = -1,
  CLOSET_MARKETING = 1,
  CONNECT_MARKETING = 2,
}

export type GetMemberListParams = {
  keyword?: string;
  connectUserStatus?: CheckObjectValue;
  connectCommunityUserStatus?: CheckObjectValue;
  everywearCreatorStatus: CheckObjectValue;
  connectUserType: CheckObjectValue;
  connectCommunityUserType: CheckObjectValue;
} & GetPageParams;

export type PostMemberCommentParams = {
  userId: string;
  commentText: string;
  reFetch?: boolean;
} & GetPageParams;

export type PutMemberCommentParams = PostMemberCommentParams & {
  id: string;
  partitionKey: string;
};

export type GetCouponListParams = {
  userId: string;
} & GetPageParams;

export type CommentDetail = {
  commentText: string;
  writeAt: string;
  writeBy: string;
};

export type Comment = {
  adminScreen: string;
  histories: CommentDetail[]; // todo
  id: string;
  keys?: { userid: string }; // ???
  partitionKey: string;
  tags: any; // todo
} & CommentDetail;

export type DeleteMemberCommentParams = Omit<PostMemberCommentParams, 'commentText'> & {
  id: string;
  partitionKey: string;
  writeBy: string;
} & GetPageParams;

export type GetBrandSettingParams = {
  userId: string;
};

export type GetBillingAddressParams = GetBrandSettingParams;

export interface BenchmarkData {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: BenchmarkListItem[];
}

export interface GetBenchmarkResponse extends Omit<BenchmarkData, 'items'> {
  items: (BenchmarkListItem & { result: string })[];
}

export type BrandSettingForm = {
  isBrand: boolean;
  showOnPage: boolean;
  file?: File;
};

export type UpdateBrandSettingParams<T = FormData> = {
  userId: string;
  formData: T;
};

export interface BenchmarkListItem {
  seq: number;
  userId: number;
  email: string;
  result: BenchmarkResult;
  registeredDate: string;
}

export interface ApiTestData {
  time: number;
}

export interface DownloadTestData {
  no?: number;
  url: string;
  progress: number;
  time: number;
  bps: string;
  cached: 'MISS' | 'HIT';
}

export interface ViewerTestData {
  no?: number;
  startTime: number;
  finishTime: number;
  progress: number;
  time: number;
  complete: boolean;
}

export interface UploadTestData {
  progress: number;
  startTime: number;
  finishTime: number;
  time: number;
  bps: string;
  status: string;
  loading: boolean;
}

export interface CFInfoData {
  region: string;
  country: string;
  timezone: string;
  city: string;
}

export interface BenchmarkResult {
  apiTestData: ApiTestData[];
  downloadData: DownloadTestData[];
  uploadData: UploadTestData;
  viewer1Data: ViewerTestData;
  viewer2Data: ViewerTestData;
  cfInfo: CFInfoData;
}

//   임시로 운영팀 사용하도록 account delete 추가함.
export interface DeleteAccountParams {
  userId: string;
  userEmail: string;
}

export interface DeleteAccountResponse {
  data: boolean;
}

export interface ExcelMemberExportParams {
  type: ExcelMemberExport;
  keyword: string;
}

export interface BrandSettingResponse {
  isBrand: boolean;
  brandImageUrl?: string;
  brandImageFileName?: string;
  showOnPage: boolean;
}

export interface BillingAddressResponse extends BillingAddress {
  userId: number;
}

export enum ConnectUserStatus {
  ACTIVE = 1,
  INACTIVE,
}

export enum ConnectCommunityUserStatus {
  ACTIVE = 1,
  INACTIVE,
}

export enum ConnectEverywearStatus {
  INACTIVE = 0, // 신청안함
  ACTIVE = 1,
}

export enum ConnectUserSupplierType {
  Fabric = 0,
  Trim = 1,
}

export type PutConnectUserStatusParams = {
  userId?: string;
  status: Nullable<number>;
};

export type PutConnectUserTypeParams = {
  userId: string;
  type: number;
  supplierTypes?: number[];
};

export interface GetEmailVerificationLinkResponse {
  userId: number;
  email: string;
  link: string;
}

export interface EmailChangeHistory {
  changedDate: string;
  previousEmail: string;
  changedEmail: string;
}
export type GetEmailChangeHistoryResponse = EmailChangeHistory[];

export enum ConnectUserType {
  GENERAL = 0,
  SUPPLIER,
  CLO_CREATOR_COLLECTIVE,
  OFFICIAL,
}

export enum ConnectCommunityUserType {
  GENERAL = 0,
  CONTRIBUTOR = 1,
  CLOVER = 100,
}

export interface ConnectInitItemProps {
  [key: string | number]: boolean;
}

export interface ConnectInitObjectProps {
  [key: string | number]: ConnectInitItemProps;
}
