import { ApiResponse } from 'types/api';
import coreApi from 'api/coreApi';
import {
  ContestAwardProps,
  ContestFilterType,
  ContestHistoriesProps,
  ContestLayoutProps,
  ContestProps,
  ContestListProps,
  ContestVisibilityType,
  LayoutType,
  ContestItemListProps,
  ContestAwardItemProps,
  ContestParticipatingSortType,
  ContestOngoingProps,
  ContestLayoutMoveOptions,
  ContestItemListParams,
} from 'types/marketplace/operation/contest';
import { CONTEST_LIMIT_SIZE } from 'constants/marketplace/contest';
import { Nullable } from 'types/common';

const CONTEST_PATH = '/connects/contests';

export const contestAPI = {
  uploadImage: async ({ formData }: { formData: FormData }): Promise<ApiResponse> => {
    return coreApi
      .post(`${CONTEST_PATH}/Description/Image`, formData)
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  getContestList: async (params: ContestFilterType): Promise<ApiResponse<ContestListProps>> => {
    return coreApi
      .get(`${CONTEST_PATH}`, { params: { ...params, limit: CONTEST_LIMIT_SIZE } })
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  getContest: async (contestSeq: number): Promise<ApiResponse<ContestProps>> => {
    return coreApi
      .get(`${CONTEST_PATH}/${contestSeq}`)
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  updateShowContest: async ({
    contestSeq,
    visibility,
  }: {
    contestSeq: number;
    visibility: ContestVisibilityType;
  }): Promise<ApiResponse> => {
    const params = { visibility };
    return coreApi
      .put(`${CONTEST_PATH}/${contestSeq}/Visibility`, {}, { params })
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  postContest: async (data: FormData): Promise<ApiResponse<ContestProps>> => {
    return coreApi
      .post(`${CONTEST_PATH}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  updateContest: async ({
    contestSeq,
    payload,
  }: {
    contestSeq: number;
    payload: FormData;
  }): Promise<ApiResponse<ContestProps>> => {
    return coreApi
      .patch(`${CONTEST_PATH}/${contestSeq}`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  removeContest: async (contestSeq: number): Promise<ApiResponse> => {
    return coreApi
      .delete(`${CONTEST_PATH}/${contestSeq}`)
      .then(res => ({ data: res.data })) // Always responds true on success
      .catch(error => ({ error }));
  },
  getContestHistory: async (contestSeq: number): Promise<ApiResponse<ContestHistoriesProps>> => {
    return coreApi
      .get(`${CONTEST_PATH}/${contestSeq}/Histories`)
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  postContestOpen: async (contestSeq: number): Promise<ApiResponse> => {
    return coreApi
      .post(`${CONTEST_PATH}/${contestSeq}/open`)
      .then(res => ({ data: res.data })) // Always responds true on success
      .catch(error => ({ error }));
  },
  getContestAwardList: async (contestSeq: number): Promise<ApiResponse<ContestAwardProps[]>> => {
    return coreApi
      .get(`${CONTEST_PATH}/${contestSeq}/Awards`)
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  postContestAwardLayout: async ({
    contestSeq,
    awardId,
    layoutType,
  }: {
    contestSeq: number;
    awardId: string;
    layoutType: LayoutType;
  }): Promise<ApiResponse<ContestLayoutProps>> => {
    return coreApi
      .post(`${CONTEST_PATH}/${contestSeq}/Awards/${awardId}/Layouts`, { layoutType })
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  removeContestAwardLayout: async ({
    contestSeq,
    awardId,
    layoutId,
  }: {
    contestSeq: number;
    awardId: string;
    layoutId: string;
  }): Promise<ApiResponse> => {
    return coreApi
      .delete(`${CONTEST_PATH}/${contestSeq}/Awards/${awardId}/Layouts/${layoutId}`)
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  /* Contest에 지원한 ItemList */
  getContestItemList: async ({
    contestSeq,
    page = 1,
    limit = CONTEST_LIMIT_SIZE,
    sort = ContestParticipatingSortType.AWARD,
    ...restParams
  }: ContestItemListParams): Promise<ApiResponse<ContestItemListProps>> => {
    return coreApi
      .get(`${CONTEST_PATH}/${contestSeq}/Items`, { params: { page, limit, sort, ...restParams } })
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  /* Contest에서 수상작으로 등록된 ItemList */
  getContestAwardItemList: async ({
    contestSeq,
    awardId,
  }: {
    contestSeq: number;
    awardId: string;
  }): Promise<ApiResponse<ContestAwardItemProps[]>> => {
    return coreApi
      .get(`${CONTEST_PATH}/${contestSeq}/Awards/${awardId}/Layouts/Items`)
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  postContestAwardLayoutItem: async ({
    contestSeq,
    awardId,
    layoutId,
    itemId,
    layoutOrder,
  }: {
    contestSeq: number;
    awardId: string;
    layoutId: string;
    layoutOrder: number;
    itemId: string;
  }): Promise<ApiResponse> => {
    return coreApi
      .post(
        `${CONTEST_PATH}/${contestSeq}/Awards/${awardId}/Layouts/${layoutId}/Items/${layoutOrder}`,
        { itemId },
      )
      .then(res => ({ data: res.data })) // Always respond true on success
      .catch(error => ({ error }));
  },
  updateContestAwardItem: async ({
    contestSeq,
    itemId,
    awardId,
  }: {
    contestSeq: number;
    itemId: string;
    awardId: Nullable<string>;
  }): Promise<ApiResponse> => {
    return coreApi
      .patch(`${CONTEST_PATH}/${contestSeq}/Items/${itemId}/Awards`, { awardId })
      .catch(error => ({ error }));
  },
  getContestOngoingList: async (): Promise<ApiResponse<ContestOngoingProps[]>> => {
    return coreApi
      .get(`${CONTEST_PATH}/titles/ongoing`)
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
  updateContestLayoutMove: async ({
    contestSeq,
    awardId,
    layoutId,
    move,
  }: {
    contestSeq: number;
    awardId: string;
    layoutId: string;
    move: ContestLayoutMoveOptions;
  }): Promise<ApiResponse> => {
    return coreApi
      .put(
        `${CONTEST_PATH}/${contestSeq}/Awards/${awardId}/layouts/${layoutId}/move`,
        {}, // empty body
        {
          params: { move },
        },
      )
      .then(res => ({ data: res.data }))
      .catch(error => ({ error }));
  },
};
