import { SearchDateTerms } from 'types/common/searchfilter';
import {
  getUtcDateDash,
  getUtcDateDashDifference,
  getUtcFirstDayDash,
  getUtcLastDayDash,
} from 'modules/utils/time';

type ShortcutInfo = {
  terms: number;
  date: { from: string; to: string };
  selected?: boolean;
};

const date = new Date();
const TODAY = getUtcDateDash(date);
const THIS_YEAR = date.getFullYear();
const LAST_YEAR = THIS_YEAR - 1;

export const shortcuts: ShortcutInfo[] = [
  {
    terms: SearchDateTerms['TODAY'],
    date: {
      from: getUtcDateDash(TODAY),
      to: TODAY,
    },
  },
  {
    terms: SearchDateTerms['3_DAYS'],
    date: {
      from: getUtcDateDashDifference(TODAY, 2, 'day'),
      to: TODAY,
    },
  },
  {
    terms: SearchDateTerms['1_WEEK'],
    date: {
      from: getUtcDateDashDifference(TODAY, 6, 'day'),
      to: TODAY,
    },
  },
  {
    terms: SearchDateTerms['1_MONTH'],
    date: {
      from: getUtcDateDashDifference(TODAY, 1, 'month'),
      to: TODAY,
    },
  },
  {
    terms: SearchDateTerms['3_MONTHS'],
    date: {
      from: getUtcDateDashDifference(TODAY, 3, 'month'),
      to: TODAY,
    },
  },
  {
    terms: SearchDateTerms['6_MONTHS'],
    date: {
      from: getUtcDateDashDifference(TODAY, 6, 'month'),
      to: TODAY,
    },
  },
  {
    terms: SearchDateTerms['LAST_YEAR'],
    date: {
      from: getUtcFirstDayDash('year', LAST_YEAR),
      to: getUtcLastDayDash('year', LAST_YEAR),
    },
  },
  {
    terms: SearchDateTerms['THIS_YEAR'],
    date: {
      from: getUtcFirstDayDash('year', THIS_YEAR),
      to: getUtcLastDayDash('year', THIS_YEAR),
    },
  },
  {
    terms: SearchDateTerms['ALL'],
    date: {
      from: getUtcDateDash(new Date('2020-01-01')),
      to: TODAY,
    },
  },
];
