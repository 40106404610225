import { STORAGE_LOCATION } from 'services/config';

export const urls: { [key: string]: { name: string; url: string } } = {
  closet: { name: 'CLO-SET', url: 'https://style.clo-set.com' },
  connect: { name: 'CONNECT', url: 'https://connect.clo-set.com' },
  helpDesk: { name: 'Help Desk', url: 'https://support.clo-set.com/hc/en-us' },
};

export const AZURE_STORAGE_PREFIX = `https://${STORAGE_LOCATION}.clo-set.com`;
export const MAINBANNER_IMAGE_PATH = `${AZURE_STORAGE_PREFIX}/public/admin/images/mainbanner`;
