import { AxiosResponse } from 'axios';
import coreApi from 'api/coreApi';
import { ApiResponse } from 'types/api';
import { PageSection } from 'types/api/marketplace/operation/sectionManage';
import { createApi } from '@reduxjs/toolkit/query/react';
import coreApiBaseQuery from 'api/coreApiBaseQuery';

const PAGE_SECTIONS_API_URL = `/connects/page/sections`;

export const getPageSections = (): Promise<ApiResponse<PageSection[]>> => {
  return coreApi
    .get<PageSection[]>(PAGE_SECTIONS_API_URL)
    .then(({ data }: AxiosResponse<PageSection[]>) => ({ data }))
    .catch(error => ({ error }));
};

export const postPageSections = (data: PageSection[]): Promise<ApiResponse> => {
  return coreApi
    .post(PAGE_SECTIONS_API_URL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(({ status }: AxiosResponse) => ({ status }))
    .catch(error => ({ error }));
};

export const pageSectionApi = createApi({
  reducerPath: 'pageSectionApi',
  baseQuery: coreApiBaseQuery(),
  endpoints: build => ({
    getPageSections: build.query<PageSection[], void>({
      query: () => ({ url: PAGE_SECTIONS_API_URL, method: 'get' }),
    }),
  }),
});

export const { useLazyGetPageSectionsQuery } = pageSectionApi;
