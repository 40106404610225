import { FIRST_PAGE_INDEX } from 'constants/common';
import {
  CONNECT_ABUSING_COMMUNITY,
  CONNECT_ABUSING_PAYMENT,
  INITIAL_MEMBER_FILTER_VALUES,
} from 'constants/member/connect';
import { memberListActions } from 'features/member/reducer/memberListSlice';
import keyboardKey from 'keyboard-key';
import { getEnumValues } from 'modules/utils/enum';
import { addQueryString, getNewValueForQuery, getParsedQuery } from 'modules/utils/queryString';
import { ChangeEvent, useEffect, useState, KeyboardEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CheckObjectValue } from 'types/common';
import {
  ConnectEverywearStatus,
  ConnectUserType,
  ConnectCommunityUserType,
  ConnectCommunityUserStatus,
  ConnectUserStatus,
  ConnectInitItemProps,
} from 'types/member';

const useMemberSearchFilter = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [keyword, setKeyword] = useState<string>('');
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(FIRST_PAGE_INDEX);
  const [connectAbusingUserStatus, setConnectAbusingUserStatus] = useState<CheckObjectValue>(
    INITIAL_MEMBER_FILTER_VALUES.connectAbusingUserStatus,
  );
  const [connectUserStatus, setConnectUserStatus] = useState<CheckObjectValue>({
    [ConnectUserStatus.INACTIVE]: false,
  });
  const [connectCommunityUserStatus, setConnectCommunityUserStatus] = useState<CheckObjectValue>({
    [ConnectCommunityUserStatus.INACTIVE]: false,
  });
  const [everywearCreatorStatus, setEverywearCreatorStatus] = useState<CheckObjectValue>(
    INITIAL_MEMBER_FILTER_VALUES.everywearCreatorStatus,
  );
  const [connectUserType, setConnectUserType] = useState<CheckObjectValue>(
    INITIAL_MEMBER_FILTER_VALUES.connectUserType,
  );
  const [connectCommunityUserType, setConnectCommunityUserType] = useState<CheckObjectValue>(
    INITIAL_MEMBER_FILTER_VALUES.connectCommunityUserType,
  );

  const getMemberStatus = (parsedQueryObj: string[], statuses: number[]): ConnectInitItemProps => {
    const statusFromQuery =
      parsedQueryObj ??
      statuses.map(status => {
        return String(status);
      });
    return statuses.reduce((acc, status) => {
      acc[status] = statusFromQuery.includes(String(status));
      return acc;
    }, {} as CheckObjectValue);
  };

  const onChangeConnectAbusingUser = (values: string[]): void => {
    const isInactivePayment = values.includes(CONNECT_ABUSING_PAYMENT);
    const isInactiveCommunity = values.includes(CONNECT_ABUSING_COMMUNITY);
    setConnectAbusingUserStatus({
      payment: isInactivePayment,
      community: isInactiveCommunity,
    });
    setConnectUserStatus({
      [ConnectUserStatus.INACTIVE]: isInactivePayment,
    });
    setConnectCommunityUserStatus({
      [ConnectCommunityUserStatus.INACTIVE]: isInactiveCommunity,
    });
  };

  const onChangeConnectEverywearStatus = (status: ConnectEverywearStatus[]): void => {
    setEverywearCreatorStatus({
      [ConnectEverywearStatus.ACTIVE]: status.includes(ConnectEverywearStatus.ACTIVE),
      [ConnectEverywearStatus.INACTIVE]: status.includes(ConnectEverywearStatus.INACTIVE),
    });
  };

  const onChangeConnectUserType = (values: ConnectUserType[]): void => {
    setConnectUserType({
      [ConnectUserType.GENERAL]: values.includes(ConnectUserType.GENERAL),
      [ConnectUserType.OFFICIAL]: values.includes(ConnectUserType.OFFICIAL),
      [ConnectUserType.SUPPLIER]: values.includes(ConnectUserType.SUPPLIER),
      [ConnectUserType.CLO_CREATOR_COLLECTIVE]: values.includes(
        ConnectUserType.CLO_CREATOR_COLLECTIVE,
      ),
    });
  };

  const onChangeConnectCommunityUserType = (values: ConnectCommunityUserType[]): void => {
    setConnectCommunityUserType({
      [ConnectCommunityUserType.CLOVER]: values.includes(ConnectCommunityUserType.CLOVER),
      [ConnectCommunityUserType.GENERAL]: values.includes(ConnectCommunityUserType.GENERAL),
      [ConnectCommunityUserType.CONTRIBUTOR]: values.includes(ConnectCommunityUserType.CONTRIBUTOR),
    });
  };

  const onChangePage = (pageIndex: number) => {
    addQueryString({ history, objValue: { page: pageIndex } });
    setCurrentPageIndex(pageIndex);
    dispatch(
      memberListActions.getMemberList({
        keyword,
        page: pageIndex,
        connectUserStatus,
        connectCommunityUserStatus,
        everywearCreatorStatus,
        connectUserType,
        connectCommunityUserType,
      }),
    );
  };

  const onKeyDownKeyword = (e: KeyboardEvent<HTMLInputElement>) => {
    const inputKey = keyboardKey.getCode(e);
    if (inputKey === keyboardKey.Enter) {
      addQueryString({
        history,
        objValue: {
          keyword,
          page: FIRST_PAGE_INDEX,
          connectUserStatus: getNewValueForQuery(connectUserStatus),
          connectCommunityUserStatus: getNewValueForQuery(connectCommunityUserStatus),
          everywearCreatorStatus: getNewValueForQuery(everywearCreatorStatus),
          connectUserType: getNewValueForQuery(connectUserType),
          connectCommunityUserType: getNewValueForQuery(connectCommunityUserType),
        },
      });
      setCurrentPageIndex(FIRST_PAGE_INDEX);
      dispatch(
        memberListActions.getMemberList({
          keyword,
          page: FIRST_PAGE_INDEX,
          connectUserStatus,
          connectCommunityUserStatus,
          everywearCreatorStatus,
          connectUserType,
          connectCommunityUserType,
        }),
      );
      return;
    }
  };

  const onChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const getSearchResult = () => {
    addQueryString({
      history,
      objValue: {
        keyword,
        connectUserStatus: getNewValueForQuery(connectUserStatus),
        connectCommunityUserStatus: getNewValueForQuery(connectCommunityUserStatus),
        everywearCreatorStatus: getNewValueForQuery(everywearCreatorStatus),
        connectUserType: getNewValueForQuery(connectUserType),
        connectCommunityUserType: getNewValueForQuery(connectCommunityUserType),
      },
    });
  };

  const resetFilter = () => {
    addQueryString({ history, reset: true });
    setKeyword('');
    setCurrentPageIndex(FIRST_PAGE_INDEX);
    setConnectAbusingUserStatus(INITIAL_MEMBER_FILTER_VALUES.connectAbusingUserStatus);
    setEverywearCreatorStatus(INITIAL_MEMBER_FILTER_VALUES.everywearCreatorStatus);
    setConnectUserType(INITIAL_MEMBER_FILTER_VALUES.connectUserType);
    setConnectCommunityUserType(INITIAL_MEMBER_FILTER_VALUES.connectCommunityUserType);

    dispatch(
      memberListActions.getMemberList({
        keyword: '',
        page: FIRST_PAGE_INDEX,
        everywearCreatorStatus: INITIAL_MEMBER_FILTER_VALUES.everywearCreatorStatus,
        connectUserType: INITIAL_MEMBER_FILTER_VALUES.connectUserType,
        connectCommunityUserType: INITIAL_MEMBER_FILTER_VALUES.connectCommunityUserType,
      }),
    );
  };

  useEffect(() => {
    // 새로고침시 url query 읽어와서 세팅
    let keyword = '';
    let page = FIRST_PAGE_INDEX;
    let {
      connectAbusingUserStatus,
      everywearCreatorStatus,
      connectUserType,
      connectCommunityUserType,
    } = INITIAL_MEMBER_FILTER_VALUES;
    let connectUserStatus = {};
    let connectCommunityUserStatus = {};
    const queries = history.location.search;
    if (queries.length) {
      const parsedQuery = getParsedQuery(queries);
      keyword = parsedQuery['keyword']?.[0] ?? '';
      page = +(parsedQuery['page']?.[0] ?? FIRST_PAGE_INDEX);

      const connectUserStatusFromQuery = parsedQuery['connectUserStatus'] ?? [];
      const isInactiveConnectUser =
        connectUserStatusFromQuery.filter(item => item === String(ConnectUserStatus.INACTIVE))
          .length === 1;

      const connectCommunityUserStatusFromQuery = parsedQuery['connectCommunityUserStatus'] ?? [];
      const inactiveConnectCommunityUser =
        connectCommunityUserStatusFromQuery.filter(
          item => item === String(ConnectCommunityUserStatus.INACTIVE),
        ).length === 1;

      connectAbusingUserStatus = {
        payment: isInactiveConnectUser,
        community: inactiveConnectCommunityUser,
      };

      connectUserStatus = {
        [ConnectUserStatus.INACTIVE]: isInactiveConnectUser,
      };
      connectCommunityUserStatus = {
        [ConnectCommunityUserStatus.INACTIVE]: inactiveConnectCommunityUser,
      };

      everywearCreatorStatus = getMemberStatus(
        parsedQuery['everywearCreatorStatus'],
        getEnumValues(ConnectEverywearStatus),
      );

      connectUserType = getMemberStatus(
        parsedQuery['connectUserType'],
        getEnumValues(ConnectUserType),
      );

      connectCommunityUserType = getMemberStatus(
        parsedQuery['connectCommunityUserType'],
        getEnumValues(ConnectCommunityUserType),
      );
    }

    setKeyword(keyword);
    setCurrentPageIndex(page);
    setConnectAbusingUserStatus(connectAbusingUserStatus);
    setConnectUserStatus(connectUserStatus);
    setConnectCommunityUserStatus(connectCommunityUserStatus);
    setEverywearCreatorStatus(everywearCreatorStatus);
    setConnectUserType(connectUserType);
    setConnectCommunityUserType(connectCommunityUserType);
    dispatch(
      memberListActions.getMemberList({
        keyword,
        page,
        connectUserStatus,
        connectCommunityUserStatus,
        everywearCreatorStatus,
        connectUserType,
        connectCommunityUserType,
      }),
    );
  }, [history.location.search]);

  return {
    currentPageIndex,
    keyword,
    connectAbusingUserStatus,
    everywearCreatorStatus,
    connectUserType,
    connectCommunityUserType,
    onChangeConnectAbusingUser,
    onChangeConnectEverywearStatus,
    onChangeConnectUserType,
    onChangeConnectCommunityUserType,
    onChangeKeyword,
    onKeyDownKeyword,
    getSearchResult,
    onChangePage,
    resetFilter,
  };
};

export default useMemberSearchFilter;
