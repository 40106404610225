import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  selectWithholdingTaxAllQueryString,
  selectWithholdingTaxQueryString,
  withholdingTaxActions,
} from 'features/revenue/withhodingTaxSlice';
import { toastAction } from 'features/toast/toastSlice';
import { AxiosError } from 'axios';
import * as api from 'api/marketplace/revenue';
import { SagaIterator } from 'redux-saga';

const {
  resetFilter,
  initialize,
  search,
  pageChange,
  loadData,
  loadDataSuccess,
  loadDataFailure,
  setSortType,
  loadExcelData,
  loadExcelDataSuccess,
  loadExcelDataFailure,
} = withholdingTaxActions;

function* updateDataSaga(): SagaIterator {
  yield put(loadData());
}

function* loadDataSaga(): SagaIterator {
  const query: string = yield select(selectWithholdingTaxQueryString);
  const { data, error } = yield call(api.getWithholdingTax, query);
  if (error) {
    if (error?.response.status === 400) {
      // Notice to resultMessage for Error.
      yield put(toastAction.toastMessage(error?.response?.data?.resultMessage ?? 'Bad Request'));
    } else {
      yield put(toastAction.errorResponse(error as AxiosError));
    }
    yield put(loadDataFailure());
  } else {
    yield put(loadDataSuccess(data));
  }
}

function* loadExcelDataSaga(): SagaIterator {
  const query: string = yield select(selectWithholdingTaxAllQueryString);
  try {
    const { data } = yield call(api.getWithholdingTax, query);
    yield put(loadExcelDataSuccess(data));
  } catch (error) {
    yield put(toastAction.errorResponse(error as AxiosError));
    yield put(loadExcelDataFailure());
  }
}

export default function* withholdingTaxSaga(): SagaIterator {
  yield all([
    takeLatest(loadData, loadDataSaga),
    takeLatest(loadExcelData, loadExcelDataSaga),
    takeLatest(initialize, updateDataSaga),
    takeLatest(resetFilter, updateDataSaga),
    takeLatest(search, updateDataSaga),
    takeLatest(pageChange, updateDataSaga),
    takeLatest(setSortType, updateDataSaga),
  ]);
}
