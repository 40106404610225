import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import {
  GetSellerListParams,
  GetSellerListResponse,
  GetSellerListResponse as SellerInfoListType,
  PutNewSellerStatus,
} from 'types/api/marketplace/confirmSellerInfo';
import { ConfirmHistory, SellerStatus } from 'types/marketplace/confirmSellerInfo';

export type ConfirmSellerState = {
  sellerInfoList: { loading: boolean; error: Error | null; data: SellerInfoListType };
  history: { loading: boolean; error: Error | null; data: ConfirmHistory };
  statusChange: { loading: boolean };
};

const initialState: ConfirmSellerState = {
  sellerInfoList: {
    loading: false,
    error: null,
    data: {
      pageNo: -1,
      pageSize: 20,
      totalCount: 0,
      sellerPaymentInfoes: [],
    },
  },
  history: {
    loading: false,
    error: null,
    data: { userId: -1, status: SellerStatus.PENDING, histories: [] },
  },
  statusChange: {
    loading: false,
  },
};

export const sellerPaymentSlice = createSlice({
  name: 'confirmSellerInfo',
  initialState,
  reducers: {
    getSellerList: (state: ConfirmSellerState, _action: PayloadAction<GetSellerListParams>) => {
      state.sellerInfoList.loading = true;
    },
    getSellerListSuccess: (
      state: ConfirmSellerState,
      action: PayloadAction<GetSellerListResponse>,
    ) => {
      state.sellerInfoList.data = action.payload;
      state.sellerInfoList.loading = false;
    },
    getSellerListFailure: (state: ConfirmSellerState) => {
      state.sellerInfoList.loading = false;
    },
    getSellerConfirmCommentHistory: (
      state: ConfirmSellerState,
      _action: PayloadAction<{ userId: number }>,
    ) => {
      state.history.loading = true;
    },
    getSellerConfirmCommentHistorySuccess: (state: ConfirmSellerState, action) => {
      state.history.data = action.payload;
      state.history.loading = false;
    },
    getSellerConfirmCommentHistoryFailure: (state: ConfirmSellerState) => {
      state.history.loading = false;
    },
    resetCommentHistory: (state: ConfirmSellerState) => {
      state.history.loading = false;
      state.history.error = null;
      state.history.data = { userId: -1, status: SellerStatus.PENDING, histories: [] };
    },
    putNewSellerStatus: (state: ConfirmSellerState, _action: PayloadAction<PutNewSellerStatus>) => {
      state.statusChange.loading = true;
    },
    putNewSellerStatusSuccess: (state: ConfirmSellerState, _action: any) => {
      state.statusChange.loading = false;
    },
    putNewSellerStatusFailure: (state: ConfirmSellerState, _action: any) => {
      state.statusChange.loading = false;
    },
  },
});

export const confirmSellerActions = sellerPaymentSlice.actions;
export const confirmSellerReducer = sellerPaymentSlice.reducer;

export const selectSellerInfo = (state: RootState) => state.confirmSeller.sellerInfoList;
export const selectStatusChange = (state: RootState) => state.confirmSeller.statusChange;
export const selectSellerConfirmCommentHistory = (state: RootState) => state.confirmSeller.history;
