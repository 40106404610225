import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import { parseStoreQueryString } from 'modules/revenue';
import qs, { ParsedQs } from 'qs';
import { StoreType } from 'types/common';
import { TotalNetIncomeFilter, TotalNetIncomeInfo } from 'types/marketplace/netIncome';

interface State {
  loading: boolean;
  data?: TotalNetIncomeInfo;
  error: boolean;
  filter: TotalNetIncomeFilter;
}

const defaultFilter = {
  year: new Date().getFullYear().toString(),
  store: [StoreType.CONNECT, StoreType.MD],
};

const initialState: State = {
  loading: false,
  error: false,
  filter: { ...defaultFilter },
};

export const totalNetIncomeSlice = createSlice({
  name: 'totalNetIncome',
  initialState,
  reducers: {
    initialize: (state, { payload }: PayloadAction<ParsedQs>) => {
      const { year, store } = payload;

      if (!year) {
        state.filter = initialState.filter;
      } else {
        state.filter = {
          year,
          store: parseStoreQueryString(store, initialState.filter.store),
        } as unknown as TotalNetIncomeFilter;
      }
    },
    loadData: state => {
      state.loading = true;
      state.error = false;
    },
    loadDataSuccess: (state, { payload }: PayloadAction<TotalNetIncomeInfo>) => {
      state.loading = false;
      state.data = payload;
    },
    loadDataFailure: state => {
      state.loading = false;
      state.error = true;
    },
    resetFilter: state => {
      state.filter = { ...defaultFilter };
    },
    search: (state, { payload }: PayloadAction<TotalNetIncomeFilter>) => {
      state.filter = payload;
    },
  },
});

export const totalNetIncomeActions = totalNetIncomeSlice.actions;
export const totalNetIncomeReducer = totalNetIncomeSlice.reducer;

export const selectTotalNetIncomeQueryString = (state: RootState) =>
  qs.stringify(state.netIncome.total.filter, { arrayFormat: 'repeat' });
export const selectTotalNetIncomeFilter = (state: RootState) => state.netIncome.total.filter;
export const selectTotalNetIncomeAmount = (state: RootState) =>
  state.netIncome.total.data?.totalNetIncome;
export const selectTotalNetIncomeDataList = (state: RootState) => {
  const rawData = state.netIncome.total.data;
  if (!rawData) return [];

  const { netIncomes } = rawData;
  return netIncomes.reduce<{ [key: string]: number | string }[]>(
    ([itemRow, revenueRow, totalRow], { month, item, fee, total }) => {
      const key = month.toString();
      return [
        { ...itemRow, [key]: item },
        { ...revenueRow, [key]: fee },
        { ...totalRow, [key]: total },
      ];
    },
    [{ title: 'Item' }, { title: 'Revenue Share' }, { title: 'Total' }],
  );
};
