import * as React from 'react';
import { LinkProps as RouterLinkProps, NavLink as RouterNavLink } from 'react-router-dom';

interface LinkProps extends RouterLinkProps {
  isExternal?: boolean;
}

const NavLink = React.forwardRef<LinkProps, any>(
  ({ children, to, exact, activeClassName, isExternal, ...props }, ref) => {
    const NavProps = { to, exact, activeClassName, children };

    const isATag = isExternal || to === undefined;

    return isATag ? (
      <a href={to} {...props}>
        {children}
      </a>
    ) : (
      <RouterNavLink innerRef={ref} {...NavProps} {...props} />
    );
  },
);

export default NavLink;
