import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import { PageSection } from 'types/api/marketplace/operation/sectionManage';

export interface SectionManageState {
  loading: boolean;
  data: PageSection[];
}

const initialState: SectionManageState = {
  loading: false,
  data: [],
};

export const sectionManageSlice = createSlice({
  name: 'sectionManage',
  initialState,
  reducers: {
    setLoading: (state: SectionManageState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setData: (state: SectionManageState, { payload }: PayloadAction<PageSection[]>) => {
      state.data = payload;
    },
    getPageSections: _ => {},
    postPageSections: (_, _action: PayloadAction<PageSection[]>) => {},
  },
});

export const sectionManageActions = sectionManageSlice.actions;
export const sectionManageReducer = sectionManageSlice.reducer;

export const selectSectionManageState = (state: RootState): SectionManageState =>
  state.marketplace.sectionManage;
