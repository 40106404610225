import { all, takeLatest, put, call } from 'redux-saga/effects';
import * as api from 'api/member';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  PostCouponInformationParams,
  GetCouponListParams,
  PostMemberCommentParams,
  PutMemberCommentParams,
  GetCouponListParams as GetCommentListParams,
  DeleteMemberCommentParams,
  GetCouponListParams as GetLoginHistoryParams,
  DeleteAccountParams,
  GetBrandSettingParams,
  UpdateBrandSettingParams,
  BrandSettingForm,
  GetBillingAddressParams,
  PutConnectUserStatusParams,
  GetEmailVerificationLinkResponse,
  PutConnectUserTypeParams,
} from 'types/member';
import { memberDetailActions } from '../reducer/memberDetailSlice';
import { GetOrderInformationParams } from 'types/member/backorder';
import { AxiosResponse } from 'axios';
import { toastAction } from 'features/toast/toastSlice';

export function* getCommentListSaga(action: PayloadAction<GetCommentListParams>) {
  const { data } = yield call(api.getCommentList, action.payload);
  if (data) {
    yield put(memberDetailActions.getCommentListSuccess(data));
  } else {
    yield put(memberDetailActions.getCommentListFailure(data));
  }
}

export function* getMemberDetailSaga(action: PayloadAction<GetOrderInformationParams>) {
  const { data } = yield call(api.getMemberDetail, action.payload);
  if (data) {
    yield put(memberDetailActions.getMemberDetailSuccess(data));
  } else {
    yield put(memberDetailActions.getMemberDetailFailure(data));
  }
}

export function* getBillingHistorySaga() {
  const { data } = yield call(api.getBillingHistory);
  if (data) {
    yield put(memberDetailActions.getBillingHistorySuccess(data));
  } else {
    yield put(memberDetailActions.getBillingHistoryFailure(data));
  }
}

export function* addMemberCommentSaga(action: PayloadAction<PostMemberCommentParams>) {
  const { userId, reFetch = false } = action.payload;
  const { data } = yield call(api.addMemberComment, action.payload);
  if (data) {
    yield put(memberDetailActions.addMemberCommentSuccess(data));
    if (reFetch) yield put(memberDetailActions.getCommentList({ userId }));
  } else {
    yield put(memberDetailActions.addMemberCommentFailure(data));
  }
}

export function* getLoginHistorySaga(action: PayloadAction<GetLoginHistoryParams>) {
  const { data } = yield call(api.getLoginHistory, action.payload);
  if (data) {
    yield put(memberDetailActions.getLoginHistorySuccess(data));
  } else {
    yield put(memberDetailActions.getLoginHistoryFailure(data));
  }
}

export function* getCouponListSaga(action: PayloadAction<GetCouponListParams>) {
  const { data } = yield call(api.getCoupon, action.payload);
  if (data) {
    yield put(memberDetailActions.getCouponListSuccess(data));
  } else {
    yield put(memberDetailActions.getCouponListFailure(data));
  }
}

export function* getPricingPlanSaga() {
  const { data } = yield call(api.getPricingPlan);
  if (data) {
    yield put(memberDetailActions.getPricingPlanSuccess(data));
  } else {
    yield put(memberDetailActions.getPricingPlanFailure(data));
  }
}

export function* addNewCouponSaga(action: PayloadAction<PostCouponInformationParams>) {
  const { userId, reFetch = false } = action.payload;
  const { data } = yield call(api.addNewCoupon, action.payload);
  if (data) {
    yield put(memberDetailActions.addNewCouponSuccess(data));
    if (reFetch) yield put(memberDetailActions.getCouponList({ userId }));
  } else {
    yield put(memberDetailActions.addNewCouponFailure(data));
  }
}

export function* updateMemberCommentSaga(action: PayloadAction<PutMemberCommentParams>) {
  const { userId, reFetch = false } = action.payload;
  const { data } = yield call(api.updateComment, action.payload);
  if (data) {
    yield put(memberDetailActions.updateMemberCommentSuccess(data));
    if (reFetch) yield put(memberDetailActions.getCommentList({ userId }));
  } else {
    yield put(memberDetailActions.updateMemberCommentFailure(data));
  }
}

export function* deleteMemberCommentSaga(action: PayloadAction<DeleteMemberCommentParams>) {
  const { userId, reFetch = false } = action.payload;
  const { data } = yield call(api.deleteComment, action.payload);
  if (data) {
    yield put(memberDetailActions.deleteMemberCommentSuccess(data));
    if (reFetch) yield put(memberDetailActions.getCommentList({ userId }));
  } else {
    yield put(memberDetailActions.deleteMemberCommentFailure(data));
  }
}

export function* getBrandSettingSaga(action: PayloadAction<GetBrandSettingParams>) {
  const { data } = yield call(api.getBrandSetting, action.payload);
  if (data) {
    yield put(memberDetailActions.getBrandSettingSuccess(data));
  } else {
    yield put(memberDetailActions.getBrandSettingFailure(data));
  }
}

export function* updateBrandSettingSaga(
  action: PayloadAction<UpdateBrandSettingParams<BrandSettingForm>>,
) {
  const { userId, formData } = action.payload;
  const form = new FormData();
  form.append('isBrand', String(formData.isBrand));
  form.append('showOnPage', String(formData.showOnPage));
  if (formData.file) {
    form.append('file', formData.file);
  }

  const { data } = yield call(api.updateBrandSetting, { userId, formData: form });
  if (data) {
    yield put(memberDetailActions.updateBrandSettingSuccess(data));
  } else {
    yield put(memberDetailActions.updateBrandSettingFailure(data));
  }
}

export function* getBillingAddressSaga(action: PayloadAction<GetBillingAddressParams>) {
  const { userId } = action.payload;
  const { data } = yield call(api.getConnectBillingAddress, { userId });
  if (data) {
    yield put(memberDetailActions.getBillingAddressSuccess(data));
  } else {
    yield put(memberDetailActions.getBillingAddressFailure(data));
  }
}

export function* deleteAccountSaga(action: PayloadAction<DeleteAccountParams>) {
  const { data, error } = yield call(api.deleteMemberAccountTemp, action.payload);
  // FIXME: delete 기획 후 이부분 backend와 맞춰서 수정 필요
  // https://test-admin.api.clo-set.com/swagger/index.html
  // 임시로 만들어놓은 코드에서 삭제가 완료된 경우 data response: true (boolean)
  // 삭제가 불가능한 경우 (modal 노출) error response: { resultCode: number, resultMessage: string } (object)
  if (data) {
    yield put(memberDetailActions.deleteAccountSuccess({ data }));
  } else {
    yield put(memberDetailActions.deleteAccountFailure(error));
  }
}

export function* updateConnectUserStatusSaga(action: PayloadAction<PutConnectUserStatusParams>) {
  const data: AxiosResponse = yield call(api.updateConnectUserStatus, action.payload);
  if (data.status === 200) {
    yield put(memberDetailActions.updateConnectUserStatusSuccess());
  } else {
    yield put(memberDetailActions.updateConnectUserStatusFailure());
  }
}

export function* updateConnectCommunityUserStatusSaga(
  action: PayloadAction<PutConnectUserStatusParams>,
) {
  const data: AxiosResponse = yield call(api.updateConnectCommunityUserStatus, action.payload);
  if (data.status === 200) {
    yield put(memberDetailActions.updateConnectCommunityUserStatusSuccess());
  } else {
    yield put(memberDetailActions.updateConnectCommunityUserStatusFailure());
  }
}

export function* updateEverywearStatusSaga(action: PayloadAction<PutConnectUserStatusParams>) {
  const data: AxiosResponse = yield call(api.updateEverywearStatus, action.payload);
  if (data.status === 200) {
    yield put(memberDetailActions.updateEverywearStatusSuccess());
  } else {
    yield put(memberDetailActions.updateEverywearStatusFailure());
  }
}

export function* updateConnectUserTypeSaga(action: PayloadAction<PutConnectUserTypeParams>) {
  const data: AxiosResponse = yield call(api.updateConnectUserType, action.payload);
  if (data.status === 200) {
    yield put(memberDetailActions.updateUserTypeSuccess());
  } else {
    yield put(
      toastAction.toastMessage({ autoHide: true, content: 'Fail to update Connect User Type' }),
    );
    yield put(memberDetailActions.updateUserTypeFailure());
  }
}

export function* updateConnectCommunityUserTypeSaga(
  action: PayloadAction<PutConnectUserTypeParams>,
) {
  const data: AxiosResponse = yield call(api.updateConnectCommunityUserType, action.payload);
  if (data.status === 200) {
    yield put(memberDetailActions.updateCommunityUserTypeSuccess());
  } else {
    yield put(memberDetailActions.updateCommunityUserTypeFailure());
  }
}

export function* getEmailVerificationLinkSaga(action: PayloadAction<{ userId: number }>) {
  const data: GetEmailVerificationLinkResponse = yield call(
    api.getEmailVerificationLink,
    action.payload,
  );
  if (data.link) {
    yield put(memberDetailActions.getEmailVerificationEmailSuccess(data));
  } else {
    yield put(memberDetailActions.getEmailVerificationEmailFailure());
  }
}

export function* getEmailChangeHistorySaga(action: PayloadAction<{ userId: number }>) {
  const { data } = yield call(api.getEmailChangeHistoryList, action.payload);
  if (data) {
    yield put(memberDetailActions.getEmailChangeHistorySuccess(data));
  } else {
    yield put(memberDetailActions.getEmailChangeHistoryFailure());
  }
}

export default function* memberDetailSaga() {
  yield all([
    takeLatest(memberDetailActions.getCommentList.type, getCommentListSaga),
    takeLatest(memberDetailActions.addMemberComment.type, addMemberCommentSaga),
    takeLatest(memberDetailActions.getMemberDetail.type, getMemberDetailSaga),
    takeLatest(memberDetailActions.getBillingHistory.type, getBillingHistorySaga),
    takeLatest(memberDetailActions.getLoginHistory.type, getLoginHistorySaga),
    takeLatest(memberDetailActions.getCouponList.type, getCouponListSaga),
    takeLatest(memberDetailActions.getPricingPlan.type, getPricingPlanSaga),
    takeLatest(memberDetailActions.addNewCoupon.type, addNewCouponSaga),
    takeLatest(memberDetailActions.updateMemberComment.type, updateMemberCommentSaga),
    takeLatest(memberDetailActions.deleteMemberComment.type, deleteMemberCommentSaga),
    takeLatest(memberDetailActions.getBrandSetting.type, getBrandSettingSaga),
    takeLatest(memberDetailActions.updateBrandSetting.type, updateBrandSettingSaga),
    takeLatest(memberDetailActions.getBillingAddress.type, getBillingAddressSaga),
    takeLatest(memberDetailActions.deleteAccount.type, deleteAccountSaga),
    takeLatest(memberDetailActions.updateConnectUserStatus.type, updateConnectUserStatusSaga),
    takeLatest(
      memberDetailActions.updateConnectCommunityUserStatus.type,
      updateConnectCommunityUserStatusSaga,
    ),
    takeLatest(memberDetailActions.updateEverywearStatus.type, updateEverywearStatusSaga),
    takeLatest(memberDetailActions.updateUserType.type, updateConnectUserTypeSaga),
    takeLatest(
      memberDetailActions.updateCommunityUserType.type,
      updateConnectCommunityUserTypeSaga,
    ),
    takeLatest(memberDetailActions.getEmailVerificationLink.type, getEmailVerificationLinkSaga),
    takeLatest(memberDetailActions.getEmailChangeHistory.type, getEmailChangeHistorySaga),
  ]);
}
