import * as React from 'react';
import AccordionBox from 'components/memberdetail/detail/AccordionBox';
import { TableCell } from 'types/common/table';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import { getUtcUSFormatDateWithTime } from 'modules/utils/time';
import { LoginHistory as LoginHistoryType } from 'types/member';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';
import { TotalCount } from 'components/memberdetail/detail/AuthProvider';

interface Props {
  loginHistoryInformation: LoginHistoryType[];
  onChangeDisplay: (event: React.ChangeEvent, expanded: boolean, name: string) => void;
  loginHistoryLoading: boolean;
  loginHistoryTotalPages: number;
  historyPageIndex: number;
  onChangeLoginHistoryPage: (pageIndex: number) => void;
  loginHistoryTotalItems: number;
}

const LoginHistory = ({
  loginHistoryInformation,
  onChangeDisplay,
  loginHistoryLoading,
  loginHistoryTotalPages,
  historyPageIndex,
  onChangeLoginHistoryPage,
  loginHistoryTotalItems,
}: Props) => {
  const loginHistoryColumns: TableCell<LoginHistoryType>[] = [
    {
      align: 'center',
      name: 'Seq',
      width: '10%',
      key: 'seq',
      render: (rowData: LoginHistoryType) => rowData.seq,
    },
    {
      align: 'center',
      name: 'Login Date',
      key: 'loginDate',
      render: (rowData: LoginHistoryType) => getUtcUSFormatDateWithTime(rowData.loginDate, true),
    },
  ];

  return (
    <AccordionBox
      title={'Login History'}
      margin={'15px 0 0 0'}
      onChangeDisplay={onChangeDisplay}
      name={'loginHistory'}
    >
      <DataTableLayout
        showPagination
        totalPages={loginHistoryTotalPages}
        customTitleComponent={<TotalCount>total {loginHistoryTotalItems}</TotalCount>}
        maxHeight={'300px'}
        currentPageIndex={historyPageIndex}
        onChangePage={onChangeLoginHistoryPage}
      >
        <DataTableHeader header={loginHistoryColumns} />
        <NewDataTableBody
          loading={loginHistoryLoading}
          dataList={loginHistoryInformation}
          columns={loginHistoryColumns}
        />
      </DataTableLayout>
    </AccordionBox>
  );
};

export default LoginHistory;
