import * as React from 'react';
import NavLink from './NavLink';
import LinkBadge from './LinkBadge';
import styled from 'styled-components';
import { ListItem, ListItemText } from '@material-ui/core';
import { darken, rgba } from 'polished';
import { SidebarLinkPropsType } from 'types/common/routes';

const SidebarLink: React.FC<SidebarLinkPropsType> = ({
  name,
  to,
  isExternal,
  badge,
  isSubMenuLink,
}) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact={false}
      to={to}
      activeClassName="active"
      isExternal={isExternal}
    >
      <Spacing isSubMenuLink={isSubMenuLink} />
      <LinkText>{name}</LinkText>
      {badge && <LinkBadge label={badge} />}
    </Link>
  );
};

// ListItem에 CustomProps 를 추가하면 Warning이 생겨 Spacing을 위한 DOM 생성
const Spacing = styled.div<{ isSubMenuLink?: boolean }>`
  padding-left: ${props =>
    props.isSubMenuLink ? props.theme.spacing(20) : props.theme.spacing(15)}px;
`;

const Link = styled(ListItem)<{
  activeClassName: string;
  component: typeof NavLink;
  exact: boolean;
  to?: string;
  isExternal?: boolean;
}>`
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;

  span {
    color: ${props => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

export default SidebarLink;
