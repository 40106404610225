import { all, put, takeLatest, call } from 'redux-saga/effects';
import * as api from 'api/notice/featureupdate';
import { featureUpdateNoticeListActions } from './featureUpdateNoticeListSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  DeleteFeatureUpdateNoticeParams,
  GetFeatureUpdateNoticeParams,
} from 'types/api/notice/featureUpdateNotice';
import { FeatureUpdateNoticeStatus } from '../../types/notice/featureUpdateNotice';

export function* getOnGoingListSaga(action: PayloadAction<GetFeatureUpdateNoticeParams>) {
  const { data, error } = yield call(api.getFeatureUpdateNotice, action.payload);
  if (data) {
    yield put(featureUpdateNoticeListActions.getOnGoingListSuccess(data));
  } else {
    yield put(featureUpdateNoticeListActions.getOnGoingListFailure(error));
  }
}

export function* getHistoryListSaga(action: PayloadAction<GetFeatureUpdateNoticeParams>) {
  const { data, error } = yield call(api.getFeatureUpdateNotice, action.payload);
  if (data) {
    yield put(featureUpdateNoticeListActions.getHistoryListSuccess(data));
  } else {
    yield put(featureUpdateNoticeListActions.getHistoryListFailure(error));
  }
}

export function* deleteModal(action: PayloadAction<DeleteFeatureUpdateNoticeParams>) {
  const { error } = yield call(api.deleteFeatureUpdateNotice, action.payload);
  if (error) {
    yield put(featureUpdateNoticeListActions.deleteModalFailure(error));
  } else {
    yield put(featureUpdateNoticeListActions.deleteModalSuccess());
    yield put(
      featureUpdateNoticeListActions.getHistoryList({
        page: 1,
        statuses: [FeatureUpdateNoticeStatus.EXPIRED],
      }),
    );
  }
}

export default function* featureUpdateNoticeListSaga() {
  yield all([
    takeLatest(featureUpdateNoticeListActions.getOnGoingList.type, getOnGoingListSaga),
    takeLatest(featureUpdateNoticeListActions.getHistoryList.type, getHistoryListSaga),
    takeLatest(featureUpdateNoticeListActions.deleteModal.type, deleteModal),
  ]);
}
