import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'features/rootSaga';
import rootReducer from 'features/rootReducer';
import axiosInterceptor from 'services/axiosInterceptor';
import { Store } from 'redux';
import { confirmItemApi } from 'api/marketplace/confirmItem';
import { pageSectionApi } from 'api/marketplace/operation/sectionManage';
import { communityApi } from 'api/marketplace/community';
import { operationMessageApi } from 'api/marketplace/operation/message';

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        sagaMiddleware,
        confirmItemApi.middleware,
        pageSectionApi.middleware,
        communityApi.middleware,
        operationMessageApi.middleware,
      ),
  }) as Store;

  store.sagaTask = sagaMiddleware.run(rootSaga);
  axiosInterceptor.run();
  return store;
};

export default makeStore();
