import * as React from 'react';
import styled from 'styled-components';
import { TableCell } from 'types/common/table';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';
import { DownloadTestData } from 'types/member';

interface Props {
  downloadTestData: DownloadTestData[];
}

const DownloadTest = ({ downloadTestData }: Props) => {
  const testColumns: TableCell<any>[] = [
    { align: 'center', name: 'URL', key: 'url' },
    {
      align: 'center',
      name: 'Progress',
      key: '2',
      render: rowData => <div>{rowData?.progress}</div>,
    },
    {
      align: 'center',
      name: 'Time',
      key: '3',
      render: rowData => <div>{`${rowData?.time}s`}</div>,
    },
    { align: 'center', name: 'bps', key: '4', render: rowData => <div>{rowData?.bps}</div> },
    {
      align: 'center',
      name: 'CDN Cached',
      key: '5',
      render: rowData => <div>{rowData?.cached}</div>,
    },
  ];

  return (
    <TableBlock margin={'20px 0 0 0'}>
      <DataTableLayout tableTitle={'Download Test'}>
        <DataTableHeader header={testColumns} />
        {downloadTestData && <NewDataTableBody dataList={downloadTestData} columns={testColumns} />}
      </DataTableLayout>
    </TableBlock>
  );
};

export default DownloadTest;

export const TableBlock = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  margin: ${({ margin = '0' }) => margin};
`;
