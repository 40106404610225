import Cookies from 'universal-cookie';
import { tokenName } from 'services/config';
import { redirectSigIn } from 'services/redirect';
import { decodeJwtToken, isExpiredToken } from '../modules/signin';

const preRequest = (cookies: Cookies) => {
  const headerToken = cookies.get(tokenName);

  // token 이 없는 요청도 가능하므로 token 이 존저할때 + expired 만 체크 할 것
  if(!headerToken) return;
  if (isExpiredToken(decodeJwtToken(headerToken))) {
    redirectSigIn();
  }
};

export default preRequest;
