import coreApi from 'api/coreApi';
import { TotalNetIncomeResponse } from 'types/api/marketplace/netIncome';
import {
  CommissionNetIncomeInfo,
  ItemNetIncomeInfo,
  NetIncomeStatusTab,
} from 'types/marketplace/netIncome';

/**
 * NetIncome
 * https://clo.atlassian.net/wiki/spaces/NX/pages/1974370523/Connect+Seller+Revenue+part3
 * author: Henry
 */

export const getTotalNetIncome = (query: string) => {
  return coreApi.get<TotalNetIncomeResponse>(`/connects/netIncome/total?${query}`);
};

export const getCommissionNetIncome = (query: string) => {
  return coreApi.get<CommissionNetIncomeInfo>(`/connects/netIncome/commission?${query}`);
};

export const getItemNetIncome = ({
  status,
  query,
}: {
  status?: NetIncomeStatusTab;
  query: string;
}) => {
  return coreApi.get<ItemNetIncomeInfo>(
    `/connects/netIncome/items${status ? `/${status}` : ''}?${query}`,
  );
};
