import * as React from 'react';
import { RouteInfoType } from 'types/common/routes';
import { AirplanemodeActive } from '@material-ui/icons';
import {
  CONTEST_PATH,
  MAIN_BANNER_PATH,
  MainBanner,
  MID_BANNER_PATH,
  MidBanner,
  SECTION_MANAGE_PATH,
  SectionManage,
  Contest,
  OPERATION_MESSAGE_PATH,
  OperationMessage,
} from 'routes/marketplace/operation';
import { REVENUE_SHARE_PATH, RevenueShare } from 'routes/marketplace/revenueShare';
import { CONFIRM_SELLER_INFO_PATH, ConfirmSellerInfo } from 'routes/marketplace/confirmSellerInfo';
import { ConfirmItem } from 'routes/marketplace/confirmItem';
import { OrderList, ORDER_LIST_PATH } from 'routes/marketplace/order';
import { Community } from 'routes/marketplace/community';

export const MARKETPLACE_PATH = `/marketplace`;

export const MarketPlaceRoutes: RouteInfoType = {
  id: 'marketplace',
  path: MARKETPLACE_PATH,
  name: 'Marketplace',
  icon: <AirplanemodeActive />,
  component: null,
  children: [
    {
      name: 'Operation',
      path: `${MARKETPLACE_PATH}/operation`,
      children: [
        {
          path: SECTION_MANAGE_PATH,
          name: 'Section Manage',
          component: SectionManage,
        },
        {
          path: MAIN_BANNER_PATH,
          name: 'Main Banner',
          component: MainBanner,
        },
        {
          path: `${MID_BANNER_PATH}/:tab?`,
          to: MID_BANNER_PATH,
          name: 'Mid Banner',
          component: MidBanner,
        },
        {
          path: `${OPERATION_MESSAGE_PATH}/:tab(confirm-item|seller-info|community)?`,
          to: `${OPERATION_MESSAGE_PATH}`,
          name: 'Message',
          component: OperationMessage,
        },
        {
          path: CONTEST_PATH,
          name: 'Contest',
          component: Contest,
        },
      ],
      component: null,
    },
    {
      name: 'Revenue Share',
      path: `${REVENUE_SHARE_PATH}/:tab(seller|monthly|withholding-tax)?`,
      to: REVENUE_SHARE_PATH,
      component: RevenueShare,
    },
    {
      name: 'Order',
      path: ORDER_LIST_PATH,
      to: ORDER_LIST_PATH,
      component: OrderList,
    },
    {
      name: 'Confirm Seller Info',
      path: CONFIRM_SELLER_INFO_PATH,
      to: CONFIRM_SELLER_INFO_PATH,
      component: ConfirmSellerInfo,
    },
    {
      name: 'Confirm Item',
      path: `${MARKETPLACE_PATH}/confirmItem/:tab(confirm-item|everywear-item)?`,
      to: `${MARKETPLACE_PATH}/confirmItem`,
      component: ConfirmItem,
    },
    {
      name: 'Community',
      path: `${MARKETPLACE_PATH}/community/:tab(post|comment)?`,
      to: `${MARKETPLACE_PATH}/community`,
      component: Community,
    },
    // {
    // // 현재 사용되지 않는 기능으로 숨김처리(Ticket: CLOSET-4511)
    //   name: 'Net Income',
    //   path: `${NET_INCOME_PATH}/:tab?/:status?`,
    //   to: NET_INCOME_PATH,
    //   component: NetIncome,
    // },
  ],
};
