import {
  ClosetPlanType,
  InvoiceItemType,
  PaymentMethod,
  PaymentStatus,
} from 'types/plansales/billing';

export const paymentStatusLabel = {
  [PaymentStatus.CREATED]: 'Pending', // db 에만 invoice 있고, stripe 에는 아직 생성 전 일때
  [PaymentStatus.TRIED_YET]: 'Pending', // db 에 invoice 가 생성되고, stripe 에도 invoice 생성되었으나 아직 해당 invoice 가 결제진행되지는 않은 경우
  [PaymentStatus.FAIL]: 'Fail',
  [PaymentStatus.SUCCESS]: 'Completed',
  [PaymentStatus.REFUND]: 'Refunded', // cancel after stripe payment
  [PaymentStatus.VOID]: 'Void', // cancel before stripe payment
};

export const paymentMethodLabel = {
  [PaymentMethod.STRIPE]: 'Stripe',
  [PaymentMethod.CREDITS]: 'Credits',
};

export const planTypeLabel = {
  [ClosetPlanType.NONE]: 'None',
  [ClosetPlanType.FREE]: 'Free Plan',
  [ClosetPlanType.ADVANCED_PLAN]: 'Advanced Plan',
  [ClosetPlanType.ADVANCED_PLAN_TRIAL]: 'Advanced Plan Trial',
  [ClosetPlanType.ENTERPRISE]: 'Enterprise Plan',
  [ClosetPlanType.ADVANCED_PLAN_INV]: 'Advanced Plan INV',
};

export const billingInvoiceItemLabel = {
  [InvoiceItemType.FIRST_SUBSCRIPTION]: 'Advanced Plan',
  [InvoiceItemType.RENEW_SUBSCRIPTION]: 'Advanced Plan',
  [InvoiceItemType.CARRY_OVER_BALANCE]: 'Carryover Balance',
  [InvoiceItemType.DOWNGRADE_SUBSCRIPTION]: 'Downgrade to free',
  [InvoiceItemType.REFUND]: 'Refund',
};
