import * as React from 'react';
import styled from 'styled-components';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import PayStatusFilters from 'components/plansales/billing/searchfilter/filtercontents/PayStatusFilters';
import PayMethodFilters from 'components/plansales/billing/searchfilter/filtercontents/PayMethodFilters';
import { PaymentMethod, PaymentStatus } from 'types/plansales/billing';
import FilterElement from 'components/common/searchfilter/FilterElement';
import { SearchDateTerms } from 'types/common/searchfilter';
import KeywordInput from 'components/common/searchfilter/contents/KeywordInput';
import DateRangeFilter from 'components/common/searchfilter/contents/DateRangeFilter';
import { CheckObjectValue } from 'types/common';

interface Props {
  dateInterval: string[];
  setDateInterval: Dispatch<SetStateAction<string[]>>;
  payStatus: CheckObjectValue;
  paymentMethod: CheckObjectValue;
  keyword: string;
  onChangePayStatus: (status: PaymentStatus) => (e: ChangeEvent<HTMLInputElement>) => void;
  onChangePaymentMethod: (status: PaymentMethod) => (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedShortcut: SearchDateTerms | null;
  setSelectedShortcut: Dispatch<SetStateAction<SearchDateTerms | null>>;
  onKeyDownKeyword: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const FilterContents = ({
  dateInterval,
  setDateInterval,
  payStatus,
  paymentMethod,
  keyword,
  onChangePayStatus,
  onChangePaymentMethod,
  onChangeKeyword,
  selectedShortcut,
  setSelectedShortcut,
  onKeyDownKeyword,
}: Props) => {
  return (
    <SearchContentBlock>
      <DateRangeFilter
        label={'Date'}
        dateInterval={dateInterval}
        setDateInterval={setDateInterval}
        selectedShortcut={selectedShortcut}
        setSelectedShortcut={setSelectedShortcut}
      />
      <FilterElement label={'Pay Status'}>
        <PayStatusFilters payStatus={payStatus} onChangePayStatus={onChangePayStatus} />
      </FilterElement>
      <FilterElement label={'Payment Method'}>
        <PayMethodFilters
          paymentMethod={paymentMethod}
          onChangePaymentMethod={onChangePaymentMethod}
        />
      </FilterElement>
      <FilterElement label={'Keyword'}>
        <KeywordInput
          keyword={keyword}
          onChangeKeyword={onChangeKeyword}
          onKeyDownKeyword={onKeyDownKeyword}
          placeholder={'Invoice No, Group ID, Group Name, Admin Email, PaymentTxId, AvalaraDocCode'}
          maxLength={200}
        />
      </FilterElement>
    </SearchContentBlock>
  );
};

export default FilterContents;

export const SearchContentBlock = styled.div`
  width: 100%;
  padding-left: ${props => props.theme.spacing(3)}px;
`;
