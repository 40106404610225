export const decodeJwtToken = (token: string): JwtTokenBody | null => {
  try {
    return JSON.parse(atob(token.split('.')[1])) as JwtTokenBody;
  } catch (_) {
    return null;
  }
};

export const isExpiredToken = (decoded: JwtTokenBody | null): boolean => {
  if (!decoded) return true;
  return new Date() > new Date(decoded.exp * 1000);
};

export interface JwtTokenBody {
  exp: number;
  userId: number;
  email: string;
  provider: number; // GOOGLE = 2
  subdomain: string;
  iss: string;
  webUser: boolean;
  instanceId: string | null;
  uuid: string;
  accountType: number;
}
