import * as React from 'react';
import { ReactElement } from 'react';
import { LayoutType, TwoThreePieceType } from 'types/marketplace/operation/contest';
import AwardThumbnail, {
  AwardThumbnailBox,
} from 'components/marketplace/operation/contest/preview/common/AwardThumbnail';
import ThreePieceTypeWrapped from 'components/marketplace/operation/contest/preview/desktop/threepiecelayout/ThreePieceTypeWrapped';
import ThreePieceTypeColumn from 'components/marketplace/operation/contest/preview/desktop/threepiecelayout/ThreePieceTypeColumn';

const THREE_PIECE_THUMBNAIL_RATIO = 1.58;

const ThreePieceType = ({ badgeId, layoutType, items }: TwoThreePieceType): ReactElement => {
  return (
    <ThreePieceTypeWrapped layoutType={layoutType ?? LayoutType.THREE_PIECE_1}>
      {items.map((item, index) => (
        <ThreePieceTypeColumn key={index} layoutType={layoutType ?? LayoutType.THREE_PIECE_1}>
          {item ? (
            <AwardThumbnail
              badgeId={badgeId}
              imagePath={item.imagePath}
              creatorImagePath={item.creatorImagePath}
              creator={item.creator}
              ratio={THREE_PIECE_THUMBNAIL_RATIO}
            />
          ) : (
            <AwardThumbnailBox ratio={THREE_PIECE_THUMBNAIL_RATIO} />
          )}
        </ThreePieceTypeColumn>
      ))}
    </ThreePieceTypeWrapped>
  );
};

export default ThreePieceType;
