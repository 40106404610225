import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import * as api from 'api/member/backorder';
import { PayloadAction } from '@reduxjs/toolkit';
import { backOrderActions, selectOrderInformation } from 'features/member/reducer/backorderSlice';
import {
  GetOrderInformationParams,
  SaveBackOrderRequest,
  SaveInitializeCommentParams,
  GetCommentListParams,
  PostPlanFreeParams,
  PostCreditParams,
  PutCurrentPlanEndDateParams,
  GetTrueUpFeeParams,
} from 'types/member/backorder';
import { HttpStatusCode } from 'types/api';
import { AxiosError } from 'axios';

export function* getOrderInformationSaga(action: PayloadAction<GetOrderInformationParams>) {
  const { data, error } = yield call(api.getOrderInformation, action.payload.userId);
  if (data) {
    yield put(backOrderActions.getOrderInformationSuccess(data));
  } else {
    yield put(backOrderActions.getOrderInformationFailure(error.response.data));
  }
}

export function* getCommentListSaga(action: PayloadAction<GetCommentListParams>) {
  const { data, error } = yield call(api.getCommentList, action.payload.groupId);
  if (data) {
    yield put(backOrderActions.getCommentListSuccess(data));
  } else {
    yield put(backOrderActions.getCommentListFailure(error.response.data));
  }
}

export function* getTrueUpFeeSaga(action: PayloadAction<GetTrueUpFeeParams>) {
  const { data, error } = yield call(api.getTrueUpFee, action.payload);
  if (data) {
    yield put(backOrderActions.getTrueUpFeeSuccess(data));
  } else {
    yield put(backOrderActions.getTrueUpFeeFailure(error.response.data));
  }
}

export function* saveBackOrderSaga(action: PayloadAction<SaveBackOrderRequest>) {
  const { closetUserId, group } = yield select(selectOrderInformation);
  const { reFetch } = action.payload;
  const { data, error } = yield call(api.addBackOrder, action.payload);
  if (data === HttpStatusCode.OK) {
    yield put(backOrderActions.saveBackOrderSuccess(data));
    if (reFetch) {
      yield put(backOrderActions.getCommentList({ groupId: group.groupId }));
      yield put(backOrderActions.getOrderInformation({ userId: String(closetUserId) }));
    }
  } else {
    yield put(backOrderActions.saveBackOrderFailure(error.response.data));
  }
}

export function* saveInitializeCreditCommentSaga(
  action: PayloadAction<SaveInitializeCommentParams>,
) {
  const { closetUserId, group } = yield select(selectOrderInformation);
  const { data, error } = yield call(api.postInitializeCreditComment, action.payload);
  if (data === HttpStatusCode.OK) {
    yield put(backOrderActions.saveInitializeCreditCommentSuccess());
    // refetch
    yield put(backOrderActions.getCommentList({ groupId: group.groupId }));
    yield put(backOrderActions.getOrderInformation({ userId: String(closetUserId) }));
  } else {
    yield put(backOrderActions.saveInitializeCreditCommentFailure(error.response.data));
  }
}

export function* savePlanFreeSaga(action: PayloadAction<PostPlanFreeParams>) {
  const { closetUserId, group } = yield select(selectOrderInformation);
  const { data } = yield call(api.postPlanFree, action.payload);
  if (data === 204) {
    yield put(backOrderActions.getCommentList({ groupId: group.groupId }));
    yield put(backOrderActions.getOrderInformation({ userId: String(closetUserId) }));
  }
}

export function* saveCreditSaga(action: PayloadAction<PostCreditParams>) {
  const { closetUserId, group } = yield select(selectOrderInformation);
  const { data, error } = yield call(api.postCredit, action.payload);
  if (data === 200) {
    yield put(backOrderActions.getCommentList({ groupId: group.groupId }));
    yield put(backOrderActions.getOrderInformation({ userId: String(closetUserId) }));
  } else {
    yield put(backOrderActions.saveCreditFailure(error.response.data));
  }
}

export function* updateCurrentPlanEnddateSaga(action: PayloadAction<PutCurrentPlanEndDateParams>) {
  const { closetUserId, group } = yield select(selectOrderInformation);
  const { data } = yield call(api.putCurrentPlanEndDate, action.payload);
  if (data === 200) {
    yield put(backOrderActions.getCommentList({ groupId: group.groupId }));
    yield put(backOrderActions.getOrderInformation({ userId: String(closetUserId) }));
  }
}

export default function* backorderSaga() {
  yield all([
    takeLatest(backOrderActions.getCommentList.type, getCommentListSaga),
    takeLatest(backOrderActions.savePlanFree.type, savePlanFreeSaga),
    takeLatest(backOrderActions.saveCredit.type, saveCreditSaga),
    takeLatest(backOrderActions.updateCurrentPlanEnddate.type, updateCurrentPlanEnddateSaga),
    takeLatest(backOrderActions.getTrueUpFee.type, getTrueUpFeeSaga),
    takeLatest(backOrderActions.getOrderInformation.type, getOrderInformationSaga),
    takeLatest(backOrderActions.saveBackOrder.type, saveBackOrderSaga),
    takeLatest(backOrderActions.saveInitializeCreditComment.type, saveInitializeCreditCommentSaga),
  ]);
}
