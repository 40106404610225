import * as React from 'react';
import { TableCellProps } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';

interface Props extends TableCellProps {}
const DataTableCell = ({ children, ...cellProps }: Props) => {
  return <TableCell {...cellProps}>{children}</TableCell>;
};

export default DataTableCell;
