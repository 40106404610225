import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';
import { parseStoreQueryString } from 'modules/revenue';
import qs, { ParsedQs } from 'qs';
import { StoreType } from 'types/common';
import {
  ItemNetIncomeFilter,
  ItemNetIncomeInfo,
  NetIncomeStatusTab,
} from 'types/marketplace/netIncome';

interface State {
  status?: NetIncomeStatusTab;
  filter: ItemNetIncomeFilter;
  loading: boolean;
  data?: ItemNetIncomeInfo;
  error: boolean;
}

const defaultFilter: ItemNetIncomeFilter = {
  date: new Date(),
  store: [StoreType.CONNECT, StoreType.MD],
  keyword: '',
};

const initialState: State = {
  loading: false,
  error: false,
  filter: defaultFilter,
};

export const itemNetIncomeSlice = createSlice({
  name: 'itemNetIncome',
  initialState,
  reducers: {
    initialize: (state, { payload }: PayloadAction<ParsedQs>) => {
      const { year, month, store, keyword } = payload;

      if (!year || !month) {
        state.filter = initialState.filter;
      } else {
        const date = new Date();
        date.setFullYear(Number(year));
        date.setMonth(Number(month) - 1);
        state.filter = {
          date,
          store: parseStoreQueryString(store, initialState.filter.store),
          keyword: (keyword ?? initialState.filter.keyword) as string,
        };
      }
    },
    setStatus: (state, { payload }: PayloadAction<NetIncomeStatusTab | undefined>) => {
      state.status = payload;
    },
    loadData: state => {
      state.loading = true;
      state.error = false;
      state.data = undefined;
    },
    loadDataSuccess: (state, { payload }: PayloadAction<ItemNetIncomeInfo>) => {
      state.loading = false;
      state.data = payload;
    },
    loadDataFailure: state => {
      state.loading = false;
      state.error = true;
    },
    resetFilter: state => {
      state.filter = { ...defaultFilter };
    },
    search: (state, { payload }: PayloadAction<ItemNetIncomeFilter>) => {
      state.filter = payload;
    },
  },
});

export const itemNetIncomeActions = itemNetIncomeSlice.actions;
export const itemNetIncomeReducer = itemNetIncomeSlice.reducer;

export const selectItemNetIncomeStatus = (state: RootState) => state.netIncome.item.status;
export const selectItemNetIncomeFilter = (state: RootState) => state.netIncome.item.filter;
export const selectItemNetIncomeIsLoading = (state: RootState) => state.netIncome.item.loading;
export const selectItemNetIncomeDataList = (state: RootState) => state.netIncome.item.data?.details;
export const selectItemNetIncomeTotalCount = (state: RootState) =>
  state.netIncome.item.data?.totalCount;
export const selectItemNetIncomeTotalRevenue = (state: RootState) =>
  state.netIncome.item.data?.totalRevenue;
export const selectItemNetIncomeQueryString = (state: RootState): string => {
  const {
    filter: { date, ...rest },
    status,
  } = state.netIncome.item;

  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  return qs.stringify({ year, month, ...rest, status }, { arrayFormat: 'repeat' });
};
