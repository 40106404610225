import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import backorderSaga from './backorderSaga';
import memberListSaga from 'features/member/saga/memberListSaga';
import memberDetailSaga from 'features/member/saga/memberDetailSaga';

function* memberRootSaga(): SagaIterator {
  yield all([fork(backorderSaga), fork(memberListSaga), fork(memberDetailSaga)]);
}

export default memberRootSaga;
