import * as React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import {
  FeatureUpdateNoticeData,
  FeatureUpdateNoticeFormType,
  FeatureUpdateNoticeStatus,
  FeatureUpdateNoticeTab,
} from 'types/notice/featureUpdateNotice';
import styled from 'styled-components';
import classNames from 'classnames';
import Button from '../../common/Button';
import {
  featureUpdateNoticeStatusLabel,
  featureUpdateNoticeTargetSpaceLabel,
} from 'constants/notice/featureUpdateNotice';
import { getUtcLocalDate } from 'modules/utils/time';
import { useHistory } from 'react-router-dom';
import { FEATURE_UPDATE_NOTICE_PATH } from 'routes/ClosetAdminRoutes';

interface Props {
  data: FeatureUpdateNoticeData;
  index: number;
  tab: FeatureUpdateNoticeTab;
  onDeleteModalOpen: () => void;
}

const FeatureUpdateNoticeTableRow = ({ data, index, tab, onDeleteModalOpen }: Props) => {
  const { status, displayModalFrom, displayModalTo, imageUrl, title, linkUrl, seq, targetSpace } =
    data;
  const isHistoryList = tab === FeatureUpdateNoticeTab.HISTORY;

  const history = useHistory();
  const onClickEditButton = () => {
    history.push(`${FEATURE_UPDATE_NOTICE_PATH}/${FeatureUpdateNoticeFormType.EDIT}/${seq}`);
  };

  return (
    <TableRow>
      <TableCell>{index}</TableCell>
      <TableCell>
        <DetailWrapper>
          {imageUrl ? (
            <DetailImage src={imageUrl} />
          ) : (
            <NoImageWrapper>
              <Typography variant={'body2'}>No Image</Typography>
            </NoImageWrapper>
          )}
          <DetailInfo>
            <Typography variant={'body2'} gutterBottom color={'textSecondary'}>
              [ {featureUpdateNoticeTargetSpaceLabel[targetSpace]} ]
            </Typography>
            <Typography variant={'h6'} gutterBottom>
              {title}
            </Typography>
            <DetailLink href={linkUrl}>
              <Typography variant={'body2'}>{linkUrl}</Typography>
            </DetailLink>
            <Typography variant={'body2'}>
              {getUtcLocalDate(displayModalFrom)}~{getUtcLocalDate(displayModalTo)}
            </Typography>
          </DetailInfo>
        </DetailWrapper>
      </TableCell>
      <TableCell>
        <Status
          className={classNames({
            upcoming: status === FeatureUpdateNoticeStatus.UPCOMING,
            expired: status === FeatureUpdateNoticeStatus.EXPIRED,
          })}
        >
          {featureUpdateNoticeStatusLabel[status]}
        </Status>
      </TableCell>
      <TableCell>
        {isHistoryList ? (
          <Button variant="outlined" color="error" onClick={onDeleteModalOpen}>
            Delete
          </Button>
        ) : (
          <Button variant="outlined" color="default" onClick={onClickEditButton}>
            Edit
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default FeatureUpdateNoticeTableRow;

const DetailWrapper = styled.div`
  display: flex;
`;

const DetailImage = styled.img`
  max-width: 400px;
  width: 400px;
`;

const NoImageWrapper = styled.div`
  width: 400px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DetailInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${props => props.theme.spacing(5)}px;
`;

const DetailLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const Status = styled(Typography)`
  color: ${({ theme }) => theme.palette.success.main};
  &.upcoming {
    color: ${({ theme }) => theme.palette.info.dark};
  }
  &.expired {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;
