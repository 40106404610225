import * as React from 'react';
import styled from 'styled-components';
import { urls } from 'constants/common/url';
import {
  Grid,
  ListItemText,
  List,
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
} from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';

interface ListItemProps extends MuiListItemProps {
  component?: string;
  href?: string;
  button: boolean | undefined;
}

const Footer = () => {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid container item xs={12} md={6}>
          <IconSpacing>
            <OpenInNew fontSize={'small'} />
          </IconSpacing>
          <List>
            {Object.keys(urls).map((key: string) => (
              <ListItem key={key} button={true} onClick={() => window.open(urls[key].url)}>
                <ListItemText primary={urls[key].name} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Footer;

const IconSpacing = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: ${props => props.theme.spacing(2)}px;
`;

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(1) / 4}px ${props => props.theme.spacing(4)}px;
  background: ${props => props.theme.palette.grey['300']};
  position: sticky;
  bottom: 0;
  z-index: 99;
`;

const ListItem = styled(MuiListItem)<ListItemProps>`
  display: inline-block;
  width: auto;
  height: 100%;
  padding-left: ${props => props.theme.spacing(2)}px;
  padding-right: ${props => props.theme.spacing(4)}px;
  &:hover {
    text-decoration: underline;
    background-color: transparent;
  }
`;
