import * as React from 'react';
import { forwardRef, Ref } from 'react';
import { Button as MuiButton, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface Props<T> {
  fileName: string;
  sheetName?: string;
  exportData: T[];
  width?: number;
  exportLoading?: boolean;
  onClickExport: () => void;
  buttonText?: string;
}

const ExcelExportButton = forwardRef(
  <T extends {}>(
    {
      fileName,
      exportData,
      sheetName = 'data',
      width = 100,
      exportLoading = false,
      onClickExport,
      buttonText = 'Export',
    }: Props<T>,
    ref: Ref<HTMLButtonElement>,
  ) => {
    return (
      <>
        <Button width={width} variant={'contained'} onClick={onClickExport} size={'small'}>
          {exportLoading ? <CircularProgress size={22} thickness={5} /> : buttonText}
        </Button>
        {exportData.length > 0 && (
          <ExcelFile filename={fileName} element={<button hidden ref={ref} />}>
            <ExcelSheet data={exportData} name={sheetName}>
              {Object.keys(exportData[0]).map(key => (
                <ExcelColumn key={key} label={key} value={key} />
              ))}
            </ExcelSheet>
          </ExcelFile>
        )}
      </>
    );
  },
);

export default ExcelExportButton;

const Button = styled(MuiButton)<{ width: number }>`
  width: ${props => props.width}px;
`;
