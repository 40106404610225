import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import ContentLayout from 'components/common/layouts/ContentLayout';
import GeneralInformation from 'components/memberdetail/detail/GeneralInformation';
import AuthProvider from 'components/memberdetail/detail/AuthProvider';
import LoginHistory from 'components/memberdetail/detail/LoginHistory';
import PricingPlanInformation from 'components/memberdetail/detail/pricingplan/PricingPlanInformation';
import {
  memberDetailActions,
  selectAuthProvider,
  selectBillingHistory,
  selectDeleteAccountData,
  selectLoginHistory,
  selectLoginHistoryLoading,
  selectMemberDetail,
  selectMemberDetailLoading,
  selectPlan,
  selectedIsDeletedAccount,
  selectConnectUserStatusError,
} from 'features/member/reducer/memberDetailSlice';
import DeleteModal from 'components/common/modal/DeleteModal';
import CommentHistoryModalContainer from 'containers/memberdetail/CommentHistoryModalContainer';
import CouponContainer from 'containers/memberdetail/CouponContainer';
import MemberCommentContainer from 'containers/memberdetail/MemberCommentContainer';
import { FIRST_PAGE_INDEX } from 'constants/common';
import InformModal from 'components/common/modal/InformModal';
import { ButtonSection } from 'components/plansales/billing/detail/ActionButtons';
import DeleteButton from 'components/memberdetail/detail/DeleteButton';
import BillingAddressContainer from 'containers/memberdetail/connect/BillingAddressContainer';
import ConnectInformationContainer from 'containers/memberdetail/connect/ConnectInformationContainer';
import { ConnectCommunityUserStatus, ConnectUserStatus } from 'types/member';
import EmailChangeHistory from '../../components/memberdetail/detail/EmailChangeHistory';

interface Props {
  userId: string;
}

const MemberDetailContainer = ({ userId }: Props) => {
  const dispatch = useDispatch();
  const generalInformation = useSelector(selectMemberDetail);
  const generalInformationLoading = useSelector(selectMemberDetailLoading);
  const authProviderList = useSelector(selectAuthProvider);
  const { data: accountDeleteData, loading: accountDeleteLoading } =
    useSelector(selectDeleteAccountData);
  const {
    items: loginHistoryInformation,
    totalPages: loginHistoryTotalPages,
    totalItems: loginHistoryTotalItems,
  } = useSelector(selectLoginHistory);
  const connectStatusError = useSelector(selectConnectUserStatusError);
  const [historyPageIndex, setHistoryPageIndex] = useState<number>(FIRST_PAGE_INDEX);
  const billingHistory = useSelector(selectBillingHistory);
  const pricingPlan = useSelector(selectPlan);
  const loginHistoryLoading = useSelector(selectLoginHistoryLoading);

  const [commentHistoryModalOpen, setCommentHistoryModalOpen] = useState<boolean>(false);
  const [commentDeleteModalOpen, setCommentDeleteModalOpen] = useState<boolean>(false);

  // 임시로 운영팀 사용하도록 account delete 추가함.
  const isDeletedAccount = useSelector(selectedIsDeletedAccount);
  const [deleteAccountModal, setDeleteAccountModal] = useState<boolean>(false); // account delete confirm modal
  const [deleteInformMessage, setDeleteInformMessage] = useState<string>(`Successfully Deleted!`);
  const [deleteInformModal, setDeleteInformModal] = useState<boolean>(false); // delete api 이후 complete/error 나타내는 modal

  useEffect(() => {
    if (accountDeleteData?.resultCode) {
      setDeleteInformMessage(
        `Delete Failed (${accountDeleteData.resultMessage} / Error Code: ${accountDeleteData.resultCode})`,
      );
      return;
    }
    if (accountDeleteData) {
      setDeleteInformMessage(`Successfully Deleted!`);
      return;
    }
  }, [accountDeleteData]);

  useEffect(() => {
    dispatch(memberDetailActions.getMemberDetail({ userId }));
    dispatch(memberDetailActions.getCommentList({ userId }));
    dispatch(memberDetailActions.getEmailChangeHistory({ userId }));
  }, []);

  const onChangeDisplay = (event: React.ChangeEvent, expanded: boolean, name: string) => {
    if (expanded) {
      name === 'loginHistory' && dispatch(memberDetailActions.getLoginHistory({ userId }));
    }
  };

  // 임시로 운영팀 사용하도록 account delete 추가함.팀
  const onClickDelete = () => {
    if (deleteAccountModal) {
      const { email } = generalInformation;
      dispatch(memberDetailActions.deleteAccount({ userId, userEmail: email }));
      setTimeout(() => {
        // 어느정도 시간을 두어 message가 먼저 사라지는 현상 방지
        onCloseDeleteModal();
        setDeleteInformModal(true);
      }, 1000);
      return;
    }
  };

  const onChangeLoginHistoryPage = (pageIndex: number) => {
    setHistoryPageIndex(pageIndex);
    dispatch(memberDetailActions.getLoginHistory({ userId, page: pageIndex }));
  };

  // 임시로 운영팀 사용하도록 account delete 추가함.
  const onCloseDeleteModal = () => {
    setCommentDeleteModalOpen(false);
    setDeleteAccountModal(false);
  };

  const onCloseErrorModal = () => {
    dispatch(memberDetailActions.updateConnectUserStatusSuccess());
  };

  const {
    connectUserStatus = ConnectUserStatus.ACTIVE,
    connectCommunityUserStatus = ConnectCommunityUserStatus.ACTIVE,
    connectUserType,
    connectCommunityUserType,
    connectUserSupplierTypes,
    connectBlockChainWalletAddress,
    everyWearCreatorStatus,
  } = generalInformation;

  return (
    <>
      <CommentHistoryModalContainer
        commentHistoryModalOpen={commentHistoryModalOpen}
        closeCommentHistoryModal={() => {
          setCommentHistoryModalOpen(false);
        }}
      />
      {/* 임시로 운영팀 사용하도록 account delete 추가함*/}
      <DeleteModal
        open={commentDeleteModalOpen || deleteAccountModal}
        onClose={onCloseDeleteModal}
        onDelete={onClickDelete}
        content={<>Are you sure you want to delete {deleteAccountModal ? 'this account' : ''}?</>}
        loading={accountDeleteLoading}
      />
      <InformModal
        open={deleteInformModal}
        onClose={() => {
          setDeleteInformModal(false);
          if (!accountDeleteData?.resultCode)
            dispatch(memberDetailActions.getMemberDetail({ userId }));
        }}
        description={deleteInformMessage}
        maxWidth={'sm'}
      />
      {/* error modal*/}
      <InformModal
        open={connectStatusError}
        onClose={onCloseErrorModal}
        description={'Something went wrong.'}
      />
      {/* 임시로 운영팀 사용하도록 account delete 추가함.*/}
      <ContentLayout pageTitle={'Member Detail'} loading={generalInformationLoading}>
        <Grid container>
          <ButtonSection>
            {/* 임시로 운영팀 사용하도록 account delete 추가함.*/}
            <DeleteButton
              onClickButton={() => setDeleteAccountModal(true)}
              disabled={isDeletedAccount}
            />
          </ButtonSection>
        </Grid>
        <Grid container>
          <GeneralInformation generalInformation={generalInformation} />
          <ConnectInformationContainer
            userId={userId}
            status={connectUserStatus}
            communityStatus={connectCommunityUserStatus}
            walletAddress={connectBlockChainWalletAddress}
            everywearCreatorStatus={everyWearCreatorStatus}
            userType={connectUserType}
            communityUserType={connectCommunityUserType}
            userSupplierTypes={connectUserSupplierTypes}
          />
          <BillingAddressContainer userId={userId} />
          {/* brand page 기획 무산으로 일단 삭제 */}
          {/*<BrandSettingContainer userId={userId} />*/}
          <AuthProvider authProviderList={authProviderList} />
          <LoginHistory
            loginHistoryInformation={loginHistoryInformation}
            loginHistoryLoading={loginHistoryLoading}
            onChangeDisplay={onChangeDisplay}
            loginHistoryTotalPages={loginHistoryTotalPages}
            onChangeLoginHistoryPage={onChangeLoginHistoryPage}
            historyPageIndex={historyPageIndex}
            loginHistoryTotalItems={loginHistoryTotalItems}
          />
          <CouponContainer userId={userId} />
          <PricingPlanInformation
            userId={userId}
            billingHistory={billingHistory}
            pricingPlan={pricingPlan}
            setCommentHistoryModalOpen={setCommentHistoryModalOpen}
            addBackOrderDisabled={isDeletedAccount}
          />
          <EmailChangeHistory />
          <MemberCommentContainer userId={userId} />
        </Grid>
      </ContentLayout>
    </>
  );
};

export default MemberDetailContainer;
