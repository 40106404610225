import { downloadsActions } from 'features/worker/reducer/downloadsSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useDownload = () => {
  const dispatch = useDispatch();
  const [actualFilePath, setActualFilePath] = useState<string>('');
  const [sRest, setSRest] = useState<{ file: File | null; fileName: string }>({
    file: null,
    fileName: '',
  });

  const onActualFileDownload = (itemId: string, version: number) => {
    dispatch(downloadsActions.getActualFilePath({ styleId: itemId, version }));
  };

  const onSRestDownload = (itemId: string, version: number) => {
    dispatch(downloadsActions.getSRest({ styleId: itemId, version }));
  };

  useEffect(() => {
    if (!actualFilePath) return;
    const a = document.createElement('a');
    a.href = actualFilePath;
    try {
      a.click();
    } catch (e) {
      console.log(e);
    }

    a.remove();
  }, [actualFilePath]);

  useEffect(() => {
    if (!sRest.file) return;
    const { file, fileName } = sRest;
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
    a.remove();
  }, [sRest]);

  useEffect(() => {
    return () => {
      setActualFilePath('');
      setSRest({
        file: null,
        fileName: '',
      });
    };
  }, []);

  return {
    setSRest,
    setActualFilePath,
    onActualFileDownload,
    onSRestDownload,
  };
};

export default useDownload;
