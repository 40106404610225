import { createApi } from '@reduxjs/toolkit/query/react';
import coreApiBaseQuery from 'api/coreApiBaseQuery';
import { toastAction } from 'features/toast/toastSlice';
import { useDispatch } from 'react-redux';
import store from 'store/configureStore';
import {
  CommentConfirmHistoryResponse,
  CommentParams,
  CommentResponseData,
  PostConfirmHistoryResponse,
  PostParams,
  PostResponseData,
} from 'types/api/marketplace/community';
import useSearchParam from 'hooks/useSearchParam';
import {
  getCommentParamsFromQuery,
  getPostParamsFromQuery,
  getSearchApiQueryString,
} from 'modules/community';
import { COMMENT_ARRAY_VALUE_KEYS, POST_ARRAY_VALUE_KEYS } from 'constants/marketplace/community';

export const communityApi = createApi({
  reducerPath: 'communityApi',
  baseQuery: coreApiBaseQuery(),
  endpoints(build) {
    return {
      getCommunityPosts: build.query<PostResponseData, PostParams>({
        query: queryParams => {
          /**
           * string으로 받는 경우 필터 선택 순서에 따라 key가 달라질 수 있어서
           * queryParams를 객체로 받아 내부에서 문자열로 변환하여 사용.
           */
          const searchApiQueryString = getSearchApiQueryString(queryParams, POST_ARRAY_VALUE_KEYS);
          return {
            url: `/connects/community/posts${
              searchApiQueryString ? `?${searchApiQueryString}` : ''
            }`,
            params: { limit: 40 },
            method: 'get',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      updateCommunityPostMainPick: build.mutation<void, { postId: string; mainPick: boolean }>({
        query: ({ postId, mainPick }) => ({
          url: `/connects/community/posts/${postId}/mainPick`,
          params: { mainPick },
          method: 'put',
          onError: error => {
            store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
          },
        }),
      }),
      updateCommunityPostConfirm: build.mutation<
        void,
        { paramId: string | number; confirm: FormData }
      >({
        query: ({ paramId: postId, confirm }) => ({
          url: `/connects/community/posts/${postId}/confirm`,
          data: confirm,
          method: 'post',
          onError: error => {
            store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
          },
        }),
      }),
      getCommunityPostConfirmHistory: build.query<
        PostConfirmHistoryResponse,
        { paramId: string | number }
      >({
        query: ({ paramId: postId }) => ({
          url: `/connects/community/posts/${postId}/confirm/histories`,
          method: 'get',
          onError: error => {
            store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
          },
        }),
      }),
      getCommunityPostComments: build.query<CommentResponseData, CommentParams>({
        query: queryParams => {
          const searchApiQueryString = getSearchApiQueryString(
            queryParams,
            COMMENT_ARRAY_VALUE_KEYS,
          );
          return {
            url: `/connects/community/comments${
              searchApiQueryString ? `?${searchApiQueryString}` : ''
            }`,
            params: { limit: 40 },
            method: 'get',
            onError: error => {
              store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
            },
          };
        },
      }),
      updateCommunityCommentConfirm: build.mutation<
        void,
        { paramId: string | number; confirm: FormData }
      >({
        query: ({ paramId: commentSeq, confirm }) => ({
          url: `/connects/community/comments/${commentSeq}/confirm`,
          data: confirm,
          method: 'post',
          onError: error => {
            store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
          },
        }),
      }),
      getCommunityCommentConfirmHistory: build.query<
        CommentConfirmHistoryResponse,
        { paramId: string | number }
      >({
        query: ({ paramId: commentSeq }) => ({
          url: `/connects/community/comments/${commentSeq}/confirm/histories`,
          method: 'get',
          onError: error => {
            store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
          },
        }),
      }),
      updateCommunityPostCategory: build.mutation<
        void,
        { postId: string; postCategoryData: FormData }
      >({
        query: ({ postId, postCategoryData }) => ({
          url: `/connects/community/posts/${postId}/categories`,
          data: postCategoryData,
          method: 'put',
          onError: error => {
            store.dispatch(toastAction.toastMessage({ autoHide: true, content: error.data }));
          },
        }),
      }),
    };
  },
});

export const {
  useLazyGetCommunityPostsQuery,
  useUpdateCommunityPostMainPickMutation,
  useUpdateCommunityPostConfirmMutation,
  useLazyGetCommunityPostConfirmHistoryQuery,
  useLazyGetCommunityPostCommentsQuery,
  useUpdateCommunityCommentConfirmMutation,
  useLazyGetCommunityCommentConfirmHistoryQuery,
  useUpdateCommunityPostCategoryMutation,
} = communityApi;

export const useRefetchCommunityPostApi = (): { refetchCommunityPosts: () => void } => {
  const dispatch = useDispatch();
  const queryParams = useSearchParam();
  const updatedSearchParams = getPostParamsFromQuery(queryParams.toString());
  const refetchCommunityPosts = (): void => {
    dispatch(
      communityApi.endpoints.getCommunityPosts.initiate(updatedSearchParams, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
  };

  return { refetchCommunityPosts };
};

export const useRefetchCommunityCommentApi = (): { refetchCommunityComments: () => void } => {
  const dispatch = useDispatch();
  const queryParams = useSearchParam();
  const updatedSearchParams = getCommentParamsFromQuery(queryParams.toString());
  const refetchCommunityComments = (): void => {
    dispatch(
      communityApi.endpoints.getCommunityPostComments.initiate(updatedSearchParams, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
  };

  return { refetchCommunityComments };
};
