import * as api from 'api/marketplace/revenue';
import {
  revenueDetailActions,
  selectRevenueDetailLoading,
  selectRevenueDetailQueryString,
} from 'features/revenue/revenueDetailSlice';
import { toastAction } from 'features/toast/toastSlice';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

const { loadData, loadDataSuccess, loadDataFailure } = revenueDetailActions;

function* loadDataSaga(action: PayloadAction<{ tab: string }>) {
  const loading: boolean = yield select(selectRevenueDetailLoading);
  if (!loading) return;
  const query: string = yield select(selectRevenueDetailQueryString);
  let tab = action.payload.tab;
  tab = `${tab[0].toUpperCase()}${tab.slice(1)}`;
  const { data, error } = yield call(api.getSellerRevenueDetails, tab, query);
  if (error) {
    yield put(toastAction.errorResponse(error));
    yield put(loadDataFailure());
    return;
  }
  yield put(loadDataSuccess(data));
}

export default function* revenueDetailSaga() {
  yield all([takeLatest(loadData, loadDataSaga)]);
}
