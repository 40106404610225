import * as React from 'react';
import { useState, ChangeEvent, useEffect, useRef } from 'react';
import ContentLayout from 'components/common/layouts/ContentLayout';
import SearchFilter from 'components/plansales/billing/searchfilter/SearchFilter';
import BillingList from 'components/plansales/billing/billingtable/BillingList';
import { getUtcDateDash, getUtcDateDashDifference } from 'modules/utils/time';
import { PaymentMethod, PaymentStatus } from 'types/plansales/billing';
import { useDispatch, useSelector } from 'react-redux';
import {
  billingActions,
  selectExportData,
  selectExportLoading,
  selectExportReady,
  selectSearchResult,
  selectSearchResultLoading,
} from 'features/billing/billingSlice';
import { addQueryString, getNewValueForQuery, getParsedQuery } from 'modules/utils/queryString';
import { useHistory } from 'react-router-dom';
import { SearchDateTerms } from 'types/common/searchfilter';
import { FIRST_PAGE_INDEX } from 'constants/common';
import keyboardKey from 'keyboard-key';
import { CheckObjectValue } from 'types/common';

const defaultInterval = [
  getUtcDateDashDifference(new Date(), 1, 'month'),
  getUtcDateDash(new Date()),
];
const initialValue = {
  dateInterval: defaultInterval,
  payStatus: {
    [PaymentStatus.TRIED_YET]: true,
    [PaymentStatus.SUCCESS]: true,
    [PaymentStatus.REFUND]: true,
    [PaymentStatus.VOID]: true,
  },
  paymentMethod: {
    [PaymentMethod.STRIPE]: true,
    [PaymentMethod.CREDITS]: true,
  },
  keyword: '',
  shortcut: SearchDateTerms['1_MONTH'],
};

const LegacyBillingContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const exportLoading = useSelector(selectExportLoading);
  const { invoices: billingInvoiceList, totalPages, totalItems } = useSelector(selectSearchResult);
  const searchResultDataLoading = useSelector(selectSearchResultLoading);
  const exportData = useSelector(selectExportData);
  const exportReady = useSelector(selectExportReady);

  const [dateInterval, setDateInterval] = useState<string[]>(initialValue.dateInterval);
  const [payStatus, setPayStatus] = useState<CheckObjectValue>(initialValue.payStatus);
  const [paymentMethod, setPaymentMethod] = useState<CheckObjectValue>(initialValue.paymentMethod);
  const [keyword, setKeyword] = useState<string>(initialValue.keyword);
  const [selectedShortcut, setSelectedShortcut] = useState<SearchDateTerms | null>(
    initialValue.shortcut,
  );

  const exportRef = useRef<HTMLButtonElement>(null);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(FIRST_PAGE_INDEX);

  useEffect(() => {
    // excel export
    if (exportData.length && exportRef.current && exportReady) {
      exportRef.current.click();
      dispatch(billingActions.setExportReady(false));
    }
  }, [exportData, exportReady]);

  useEffect(() => {
    // 새로고침시 url query 읽어와서 세팅
    let payStatus = initialValue.payStatus;
    let paymentMethod = initialValue.paymentMethod;
    let keyword = initialValue.keyword;
    let dateInterval = initialValue.dateInterval;
    let page = FIRST_PAGE_INDEX;
    let shortcut = initialValue.shortcut;
    const queries = history.location.search;
    if (queries.length) {
      const parsedQuery = getParsedQuery(queries);
      const payStatusFromQuery = parsedQuery['payStatus'] ?? [
        String(PaymentStatus.TRIED_YET),
        String(PaymentStatus.SUCCESS),
        String(PaymentStatus.REFUND),
        String(PaymentStatus.VOID),
      ];
      const paymentMethodFromQuery = parsedQuery['paymentMethod'] ?? [
        String(PaymentMethod.CREDITS),
        String(PaymentMethod.STRIPE),
      ];
      page = +(parsedQuery['page']?.[0] ?? FIRST_PAGE_INDEX);
      dateInterval = parsedQuery['date'] ?? initialValue.dateInterval;
      keyword = parsedQuery['keyword']?.[0] ?? initialValue.keyword;
      shortcut =
        (parsedQuery['shortcut']?.[0] as unknown as SearchDateTerms) ?? initialValue.shortcut;
      payStatus = {
        [PaymentStatus.TRIED_YET]: payStatusFromQuery.includes(String(PaymentStatus.TRIED_YET)),
        [PaymentStatus.SUCCESS]: payStatusFromQuery.includes(String(PaymentStatus.SUCCESS)),
        [PaymentStatus.REFUND]: payStatusFromQuery.includes(String(PaymentStatus.REFUND)),
        [PaymentStatus.VOID]: payStatusFromQuery.includes(String(PaymentStatus.VOID)),
      };
      paymentMethod = {
        [PaymentMethod.CREDITS]: paymentMethodFromQuery.includes(String(PaymentMethod.CREDITS)),
        [PaymentMethod.STRIPE]: paymentMethodFromQuery.includes(String(PaymentMethod.STRIPE)),
      };
    }
    setDateInterval(dateInterval);
    setKeyword(keyword);
    setPayStatus(payStatus);
    setPaymentMethod(paymentMethod);
    setCurrentPageIndex(page);
    setSelectedShortcut(shortcut);
    dispatch(
      billingActions.getSearchResult({ dateInterval, keyword, payStatus, paymentMethod, page }),
    );
  }, [history.location.search]);

  const onChangePayStatus =
    (status: PaymentStatus) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const newValue = {
        ...payStatus,
        [value]: !payStatus[status],
      };
      setPayStatus(newValue);
    };

  const onChangePaymentMethod =
    (method: PaymentMethod) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const newValue = {
        ...paymentMethod,
        [value]: !paymentMethod[method],
      };
      setPaymentMethod(newValue);
    };

  const onChangeKeyword = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setKeyword(value);
  };

  const resetFilter = () => {
    addQueryString({ history, reset: true });
    const { dateInterval, payStatus, paymentMethod, keyword } = initialValue;
    setDateInterval(dateInterval);
    setPayStatus(payStatus);
    setPaymentMethod(paymentMethod);
    setKeyword(keyword);
    setSelectedShortcut(null);
  };

  const getSearchResult = (newKeyword: string = keyword) => {
    addQueryString({
      history,
      objValue: {
        date: dateInterval,
        keyword: newKeyword,
        payStatus: getNewValueForQuery(payStatus),
        paymentMethod: getNewValueForQuery(paymentMethod),
        page: FIRST_PAGE_INDEX,
        shortcut: selectedShortcut ?? '',
      },
    });
    setCurrentPageIndex(FIRST_PAGE_INDEX);
    dispatch(
      billingActions.getSearchResult({
        dateInterval,
        keyword: newKeyword,
        payStatus,
        paymentMethod,
        page: FIRST_PAGE_INDEX,
      }),
    );
  };

  const searchCellClick = (searchValue: string) => () => {
    setKeyword(searchValue);
    getSearchResult(searchValue);
  };

  const onClickExport = () => {
    dispatch(
      billingActions.getExportData({
        dateInterval,
        keyword,
        payStatus,
        paymentMethod,
        pageSize: totalItems,
      }),
    );
  };

  const onChangePage = (pageIndex: number) => {
    addQueryString({ history, objValue: { page: pageIndex } });
    setCurrentPageIndex(pageIndex);
    dispatch(
      billingActions.getSearchResult({
        dateInterval,
        keyword,
        payStatus,
        paymentMethod,
        page: pageIndex,
      }),
    );
  };

  const onKeyDownKeyword = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputKey = keyboardKey.getCode(e);
    if (inputKey === keyboardKey.Enter) {
      getSearchResult();
    }
  };

  return (
    <ContentLayout pageTitle={'Billing (Legacy)'}>
      <SearchFilter
        dateInterval={dateInterval}
        setDateInterval={setDateInterval}
        payStatus={payStatus}
        onChangePayStatus={onChangePayStatus}
        paymentMethod={paymentMethod}
        onChangePaymentMethod={onChangePaymentMethod}
        keyword={keyword}
        onChangeKeyword={onChangeKeyword}
        resetFilter={resetFilter}
        getSearchResult={getSearchResult}
        selectedShortcut={selectedShortcut}
        setSelectedShortcut={setSelectedShortcut}
        onKeyDownKeyword={onKeyDownKeyword}
      />
      <BillingList
        dateInterval={dateInterval}
        searchCellClick={searchCellClick}
        exportLoading={exportLoading}
        exportData={exportData}
        searchResultData={billingInvoiceList}
        searchResultDataLoading={searchResultDataLoading}
        onClickExport={onClickExport}
        ref={exportRef}
        totalPages={totalPages}
        onChangePage={onChangePage}
        currentPageIndex={currentPageIndex}
        totalItems={totalItems}
      />
    </ContentLayout>
  );
};

export default LegacyBillingContainer;
