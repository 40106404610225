import * as React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { BillDetailType, PaymentStatus, BillType } from 'types/plansales/billing';
import { ExtraDescriptionStatus } from 'types/plansales/billing/invoice';
import { getUtcDateDash } from 'modules/utils/time';
import { toMantissaWithDollarSign } from 'modules/utils/number';
import { getBillingMessage } from 'modules/billing/BillingLanguage';
import { Link } from 'react-router-dom';
import { NULL_TEXT } from 'constants/common';

const subscriptionAction = [BillDetailType.FIRST_SUBSCRIPTION, BillDetailType.RENEW_SUBSCRIPTION];
const notPaidStatus = [PaymentStatus.TRIED_YET, PaymentStatus.FAIL];

export const historyDescriptionRenderer = (rowData: any) => {
  const { GroupBillDetails, Invoice: isInvoiceRow, BillType: billType } = rowData;
  const [currentRowBillDetail] = GroupBillDetails;
  const isRefunded = billType === BillType.REFUND;
  const isDowngraded =
    currentRowBillDetail.BillDetailType === BillDetailType.DOWNGRADE_SUBSCRIPTION;
  const showPayingUserMessage = subscriptionAction.includes(currentRowBillDetail?.BillDetailType);
  const downgradeLanguageKey =
    isDowngraded && isInvoiceRow
      ? 'billing_down_to_free_minus_credits' // credit 정산 결제 이루어지고 Invoice 발행 후 downgrade 된 경우
      : 'billing_down_to_free_plus_credits'; // 정산 필요없이 그냥 downgrade 된 경우

  const getExtraDescriptionStatus = () => {
    if (isInvoiceRow && showPayingUserMessage && !isRefunded)
      return ExtraDescriptionStatus.SHOW_PAYING_USER;
    if (isDowngraded && !isRefunded) return ExtraDescriptionStatus.SHOW_CREDIT_SETTLEMENT;
    return ExtraDescriptionStatus.NO_MESSAGE;
  };

  return (
    <>
      {/*복수 row 가능하므로 map*/}
      {GroupBillDetails.map((detail: any, index: number) => (
        <React.Fragment key={index}>{descriptionMessage(detail, isRefunded)}</React.Fragment>
      ))}
      {!isRefunded && (
        <>
          <br />
          {
            extraDescriptionMessage(currentRowBillDetail, downgradeLanguageKey)[
              getExtraDescriptionStatus()
            ]
          }
        </>
      )}
    </>
  );
};

const extraDescriptionMessage = (billDetail: any, downgradeLanguageKey: string): any => ({
  [ExtraDescriptionStatus.SHOW_PAYING_USER]: (
    <>
      {`${getBillingMessage('billing_plan_user', {
        count: billDetail.Quantity,
      })} ${getBillingMessage('billing_plan_period', {
        fromDate: getUtcDateDash(billDetail.StartDate),
        toDate: getUtcDateDash(billDetail.EndDate),
      })}`}
    </>
  ),
  [ExtraDescriptionStatus.SHOW_CREDIT_SETTLEMENT]: (
    <>
      {`${getBillingMessage(downgradeLanguageKey, {
        count: billDetail.Quantity,
      })}`}
    </>
  ),
  [ExtraDescriptionStatus.NO_MESSAGE]: <></>,
});

const descriptionMessage = (billDetail: any, isRefunded: boolean = false) => {
  const { Quantity, BillDetailType: billDetailType, StartDate, EndDate, Amount } = billDetail;
  const fromDate = getUtcDateDash(StartDate);
  const toDate = getUtcDateDash(EndDate);
  if (isRefunded) return getBillingMessage('billing_payment_canceled_summary');
  switch (billDetailType) {
    case BillDetailType.FIRST_SUBSCRIPTION:
      // Invoice Row : Started the {{ planName }} 0 Paying Users (2020-10-10 to 2021-10-10)
      return getBillingMessage('billing_start_plan_summary');
    case BillDetailType.RENEW_SUBSCRIPTION:
      // Invoice Row : Renew the {{ planName }} 0 Paying Users (2020-10-10 to 2021-10-10)
      return getBillingMessage('billing_renew_plan_summary');
    case BillDetailType.ADD_MEMBER:
      return `${getBillingMessage('billing_plan_user_added', {
        count: Quantity,
      })} ${getBillingMessage('billing_plan_period', {
        fromDate,
        toDate,
      })}`;
    case BillDetailType.DELETE_MEMBER: {
      // plan 마지막날 delete member 하게될 경우 어차피 부과되는 credit 은 0 이고,
      // 해당 delete 유저는 다음 plan renew 에 포함되지 않으므로 plan 기간을 보여주지 않음
      const periodMessage =
        Amount === 0
          ? ''
          : getBillingMessage('billing_plan_period', {
              fromDate,
              toDate,
            });
      // Not Invoice Row : 20 Paying User Removed (2020-10-10 to 2020-20-20)
      return `${getBillingMessage('billing_plan_user_removed', {
        count: Quantity,
      })} ${periodMessage}`;
    }
    case BillDetailType.DOWNGRADE_SUBSCRIPTION:
      return getBillingMessage('billing_down_to_free_plan_summary');
    default:
      return '';
  }
};

export const historyInvoiceRenderer = (invoiceNo?: string) => {
  return invoiceNo && <InvoiceNumber to={`/sales/billing/${invoiceNo}`}>{invoiceNo}</InvoiceNumber>;
};

export const historyChargeRenderer = (detail: any) => {
  const { Amount, BillType: billType, GroupBillDetails } = detail;
  const chargeLanguageKey =
    // Credited $55.00 또는  Additional $20.00 will be billed
    Amount > 0 ? 'billing_charge_credited' : 'billing_charge_additional_billed';
  const isDowngrade =
    detail.GroupBillDetails[0].BillDetailType === BillDetailType.DOWNGRADE_SUBSCRIPTION;
  // downgrade 의 경우 (invoice 유무 동일하게 null text)
  if (GroupBillDetails.length === 1 && isDowngrade) {
    return NULL_TEXT;
  }
  if (!detail.Invoice) {
    // 인원이 변동되어 최종 계산결과 amount 가 0 인 경우 존재 => 그냥 $0.00 으로 보여줌
    if (Amount === 0) return toMantissaWithDollarSign(Amount, 2);
    // amount 에 따라 description 달라짐 (복합 Row 의 경우 최종 계산 결과로 판단해야하기 때문)
    return getBillingMessage(chargeLanguageKey, {
      amount: toMantissaWithDollarSign(Math.abs(Amount), 2),
    });
  }
  const {
    Invoice: { PaymentStatus: paymentStatus },
  } = detail;
  switch (billType) {
    case BillType.CHARGE:
      //  Billed $50.00
      return getBillingMessage('billing_charge_billed', {
        amount: toMantissaWithDollarSign(Amount, 2),
      });
    case BillType.REFUND: {
      // Refunded $50.00
      return paymentStatus === PaymentStatus.REFUND
        ? getBillingMessage('billing_charge_refunded', {
            amount: toMantissaWithDollarSign(Math.abs(Amount), 2),
          })
        : '';
    }
    default:
      return '';
  }
};

export const historyPaymentRenderer = (detail: any) => {
  if (!detail?.Invoice) return ''; // No Invoice Row : ''
  const {
    BillType: billType,
    Invoice: { PaymentStatus: paymentStatus, Subtotal },
  } = detail;

  if (billType === BillType.CHARGE) {
    switch (paymentStatus) {
      case PaymentStatus.FAIL: // -
      case PaymentStatus.TRIED_YET: // -
        return NULL_TEXT;
      case PaymentStatus.SUCCESS: // $22.00
      case PaymentStatus.REFUND: // $22.00 (payment info row)
        return toMantissaWithDollarSign(Subtotal, 2);
      default:
        return '';
    }
  }
  return '';
};

export const historyCreditRenderer = (rowData: any) => {
  const { Balance, Invoice } = rowData;
  const isNotPaid =
    Invoice && notPaidStatus.includes(Invoice?.PaymentStatus || PaymentStatus.TRIED_YET);
  return (
    <CreditCell className={classNames({ warning: !isNotPaid && Balance < 0 })}>
      {creditMessage(rowData)}
    </CreditCell>
  );
};

const creditMessage = (detail: any) => {
  const { Balance, Invoice, GroupBillDetails } = detail;
  // downgrade 된 경우 credit 어떻게든 청산완료 되므로 $0.00
  if (GroupBillDetails?.[0].BillDetailType === BillDetailType.DOWNGRADE_SUBSCRIPTION)
    return toMantissaWithDollarSign(0, 2);
  if (!Invoice) {
    return toMantissaWithDollarSign(Balance, 2);
  }
  const { PaymentStatus: paymentStatus } = Invoice;
  switch (paymentStatus) {
    case PaymentStatus.SUCCESS:
      return toMantissaWithDollarSign(Balance, 2); // Invoice Row : $200.00
    case PaymentStatus.FAIL:
    case PaymentStatus.TRIED_YET:
      return NULL_TEXT; // Invoice Row : '-'
    case PaymentStatus.REFUND:
      return toMantissaWithDollarSign(Balance, 2); // Invoice Row : $22.00
    default:
      return '';
  }
};

export const CreditCell = styled.span`
  &.warning {
    color: red;
  }
`;

const InvoiceNumber = styled(Link)`
  text-decoration: underline;
  color: ${props => props.theme.palette.grey[900]};
`;
