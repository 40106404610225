import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FeatureUpdateNoticeColorType,
  FeatureUpdateNoticeFormData,
  FeatureUpdateNoticeFormValidation,
  FeatureUpdateNoticeLinkOpenType,
  FeatureUpdateNoticeModalFormData,
  FeatureUpdateNoticeModalLinkButton,
  FeatureUpdateNoticeModalType,
  FeatureUpdateNoticeTargetSpace,
} from 'types/notice/featureUpdateNotice';
import {
  checkFeatureUpdateNoticeFormValidation,
  getFeatureUpdateNoticeFormValidation,
  getFeatureUpdateNoticeInitialFormData,
  getFeatureUpdateNoticeInitialFormValidation,
} from 'modules/notice/featureUpdateNotice';
import { RootState } from '../rootReducer';
import { History } from 'history';
import { GetFeatureUpdateNoticeDetailParams } from 'types/api/notice/featureUpdateNotice';

export interface FeatureUpdateNoticeFormState {
  loading: boolean;
  data: FeatureUpdateNoticeFormData;
  validation: FeatureUpdateNoticeFormValidation;
  error: any;
}
export const initialState: FeatureUpdateNoticeFormState = {
  loading: false,
  data: getFeatureUpdateNoticeInitialFormData(),
  validation: getFeatureUpdateNoticeInitialFormValidation(),
  error: null,
};

export const featureUpdateNoticeFormSlice = createSlice({
  name: 'featureUpdateNoticeForm',
  initialState,
  reducers: {
    setTitle: (state: FeatureUpdateNoticeFormState, { payload }: PayloadAction<string>) => {
      state.data.title = payload;
    },
    setTargetModalSpace: (
      state: FeatureUpdateNoticeFormState,
      { payload }: PayloadAction<FeatureUpdateNoticeTargetSpace>,
    ) => {
      state.data.targetSpace = payload;
    },
    setModalType: (
      state: FeatureUpdateNoticeFormState,
      { payload }: PayloadAction<FeatureUpdateNoticeModalType>,
    ) => {
      state.data.modalType = payload;

      if(payload !== FeatureUpdateNoticeModalType.IMAGE && state.data.colorType !== FeatureUpdateNoticeColorType.DARK) {
        state.data.colorType = FeatureUpdateNoticeColorType.DARK;
      }
    },
    setColorType: (state : FeatureUpdateNoticeFormState, {payload}: PayloadAction<FeatureUpdateNoticeColorType>) => {
      state.data.colorType = payload
    },
    setEnModalForm: (
      state: FeatureUpdateNoticeFormState,
      { payload }: PayloadAction<FeatureUpdateNoticeModalFormData>,
    ) => {
      state.data.modals.english = payload;
    },
    setCnModalForm: (
      state: FeatureUpdateNoticeFormState,
      { payload }: PayloadAction<FeatureUpdateNoticeModalFormData>,
    ) => {
      state.data.modals.chinese = payload;
    },
    setUseLinkButton: (
      state: FeatureUpdateNoticeFormState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.data.useLinkButton = payload;
    },
    setLinkOpenType: (
      state: FeatureUpdateNoticeFormState,
      { payload }: PayloadAction<FeatureUpdateNoticeLinkOpenType>,
    ) => {
      state.data.linkButton.linkOpenType = payload;
    },
    setLinkButton: (
      state: FeatureUpdateNoticeFormState,
      { payload }: PayloadAction<FeatureUpdateNoticeModalLinkButton>,
    ) => {
      state.data.linkButton = payload;
    },
    setFromDate: (state: FeatureUpdateNoticeFormState, { payload }: PayloadAction<Date>) => {
      state.data.displayModalFrom = payload;
    },
    setToDate: (state: FeatureUpdateNoticeFormState, { payload }: PayloadAction<Date>) => {
      state.data.displayModalTo = payload;
    },
    setNumberOfDays: (state: FeatureUpdateNoticeFormState, { payload }: PayloadAction<number>) => {
      state.data.hidingPediod = payload;
    },
    resetFormData: (state: FeatureUpdateNoticeFormState) => {
      state.data = getFeatureUpdateNoticeInitialFormData();
      state.validation = getFeatureUpdateNoticeInitialFormValidation();
      state.loading = false;
    },
    validateFormData: (state: FeatureUpdateNoticeFormState) => {
      state.validation = getFeatureUpdateNoticeFormValidation(state.data);
    },
    setLoading: (state: FeatureUpdateNoticeFormState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    createModal: (state: FeatureUpdateNoticeFormState, _action: PayloadAction<History>) => {},
    createModalSuccess: (state: FeatureUpdateNoticeFormState) => {
      state.loading = false;
    },
    editModal: (state: FeatureUpdateNoticeFormState, _action: PayloadAction<History>) => {},
    editModalSuccess: (state: FeatureUpdateNoticeFormState) => {
      state.loading = false;
    },
    getDetailData: (
      state: FeatureUpdateNoticeFormState,
      _action: PayloadAction<GetFeatureUpdateNoticeDetailParams>,
    ) => {
      state.loading = true;
    },
    getDetailDataSuccess: (
      state: FeatureUpdateNoticeFormState,
      action: PayloadAction<FeatureUpdateNoticeFormData>,
    ) => {
      state.data = action.payload;
      state.loading = false;
    },
    getDetailDataFailure: (state: FeatureUpdateNoticeFormState, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
  },
});

export const featureUpdateNoticeFormActions = featureUpdateNoticeFormSlice.actions;
export const featureUpdateNoticeFormReducer = featureUpdateNoticeFormSlice.reducer;

export const selectFeatureUpdateNoticeFormData = (root: RootState): FeatureUpdateNoticeFormData =>
  root.featureUpdateNotice.featureUpdateNoticeForm.data;
export const selectFeatureUpdateNoticeFormValidation = (
  root: RootState,
): FeatureUpdateNoticeFormValidation => root.featureUpdateNotice.featureUpdateNoticeForm.validation;
export const selectCheckFormValidation = (root: RootState): boolean =>
  checkFeatureUpdateNoticeFormValidation(
    root.featureUpdateNotice.featureUpdateNoticeForm.validation,
  );
export const selectFeatureUpdateNoticeFormLoading = (root: RootState): boolean =>
  root.featureUpdateNotice.featureUpdateNoticeForm.loading;
