import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Slide, SlideProps, Snackbar } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Alert } from '@material-ui/lab';
import {
  selectToastAutoHide,
  selectToastContent,
  selectToastOpen,
  selectToastType,
  toastAction,
} from 'features/toast/toastSlice';
import { TOAST_AUTO_HIDE_DURATION } from 'constants/common';
import { ReactElement, SyntheticEvent } from 'react';
import { SnackbarCloseReason } from '@material-ui/core/Snackbar/Snackbar';

const ToastMessageContainer = (): ReactElement => {
  const dispatch = useDispatch();
  const open = useSelector(selectToastOpen);
  const type = useSelector(selectToastType);
  const content = useSelector(selectToastContent);
  const autoHide = useSelector(selectToastAutoHide);

  const onClickAwayClose = (): void => {
    dispatch(toastAction.close());
  };

  const onClose = (event: SyntheticEvent, reason?: SnackbarCloseReason): void => {
    /* Add a conditional statement to prevent the click event
    from being called during rendering.
     * */
    if (reason === 'clickaway') return;
    dispatch(toastAction.close());
  };

  return (
    <>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClickAwayClose}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          onClose={onClose}
          TransitionComponent={SlideUp}
          autoHideDuration={autoHide ? TOAST_AUTO_HIDE_DURATION : null}
        >
          <Alert variant="filled" severity={type}>
            {content}
          </Alert>
        </Snackbar>
      </ClickAwayListener>
    </>
  );
};

const SlideUp = (props: SlideProps): ReactElement => {
  return <Slide {...props} direction="up" />;
};

export default ToastMessageContainer;
