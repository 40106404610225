import * as React from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';

import {
  Badge,
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from '@material-ui/core';
import { ExitToApp, Notifications, Menu } from '@material-ui/icons';

import { redirectSigIn } from 'services/redirect';

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${props => props.theme.header.indicator.background};
    color: ${props => props.theme.palette.common.white};
  }
`;

type HeaderProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Header: React.FC<HeaderProps> = ({ onDrawerToggle }) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center" justify="space-between">
          <Hidden mdUp>
            <Grid item>
              <IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle}>
                <Menu />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item />
          <Grid item>
            <IconButton color="inherit" title="Notifications">
              <Indicator>
                <Notifications />
              </Indicator>
            </IconButton>
            <IconButton
              title="Sign Out"
              color="inherit"
              onClick={() => {
                redirectSigIn();
              }}
            >
              <ExitToApp />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

export default connect()(withTheme(Header));
