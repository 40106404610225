import * as React from 'react';
import { ReactElement } from 'react';
import { AZURE_STORAGE_PREFIX } from 'constants/common/url';
import styled from 'styled-components';

const onePieceType2SemiCircleUrl = `${AZURE_STORAGE_PREFIX}/public/connect/contest/layout/onePieceType2_semi_circle.svg`;
const onePieceType2CircleUrl = `${AZURE_STORAGE_PREFIX}/public/connect/contest/layout/onePieceType2_circle.svg`;

const OnePieceType2Background = (): ReactElement => {
  return (
    <OnePieceType2BackgroundRoot>
      <SemiCircleWrap>
        <img src={onePieceType2SemiCircleUrl} alt={'semi circle'} />
      </SemiCircleWrap>
      <CircleWrap>
        <img src={onePieceType2CircleUrl} alt={'circle'} />
      </CircleWrap>
    </OnePieceType2BackgroundRoot>
  );
};

export default OnePieceType2Background;

const OnePieceType2BackgroundRoot = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  padding: 14px;
`;

const SemiCircleWrap = styled.div`
  height: 100%;
  img {
    height: 100%;
  }
`;
const CircleWrap = styled.div`
  margin-left: -5%;
  img {
    height: 100%;
  }
`;
