import * as React from 'react';
import { ReactElement } from 'react';
import SignInLayout from '../../containers/signin/SignInLayout';
import SignInContainer from '../../components/signin/SignInContainer';

const SignIn = (): ReactElement => {
  return (
    <SignInLayout>
      <SignInContainer />
    </SignInLayout>
  );
};

export default SignIn;
