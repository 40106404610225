import { Nullable } from 'types/common';
import { LicenseType } from 'types/common';
import { OrderKeywordType } from 'types/api/marketplace/order';

export interface OrderCheckedItemInfo {
  id: string;
  requestStatus: Nullable<ConfirmStatus>;
  liveStatus: Nullable<ConfirmStatus>;
  isGarment: boolean;
}

export interface OrderSearchParams {
  dateFrom: string;
  dateTo: string;
  dateTerm: number | null;
  salesTypes: number[];
  itemTypes: string[];
  keyword: string;
  page: number;
  sort: number;
}

export enum ConfirmStatus {
  PENDING = 10,
  CHECKING = 3,
  PUBLISHED = 1,
  REJECTED = -1,
  WITHDRAW = 0,
  BLOCKED = -2,
  DELETED = 14,
  DRAFT = 2,
}

export enum Sort {
  LATEST_ORDER_DATE = 0,
  HIGH_PAYMENT_PRICE = 1,
}

export enum OrderPaymentStatus {
  ERROR = -10,
  REFUNDED = -1,
  INCOMPLETE = 0,
  COMPLETED = 1,
}

export enum OrderPaymentMethod {
  FREE = -1,
  STRIPE = 0,
  PAYPAL = 1,
  ALIPAY = 2,
}

export enum OrderRefundStatus {
  FAILED = -1,
  COMPLETE = 1,
}

export type OrderInfo = {
  orderNo: number;
  email: string;
  nickname: string;
  totalPrice: number;
  couponDiscountAmount: number;
  tax: number;
  paymentPrice: number;
  paymentType: OrderPaymentMethod;
  paymentStatus: OrderPaymentStatus;
  orderedDate: string;
  paymentId: string;
  avalaraId: string;
  paymentHistory: { status: PaymentHistoryLogCode; message: string };
};

export type BillingAddress = {
  country: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  vatNumber: string; // 유럽의 특정회사의 경우에만 값 존재.
};

export type Items = {
  itemNumber: number;
  itemType: string;
  alreadyDownload: boolean;
  index: number;
  itemId: string;
  name: string;
  thumbnail: string;
  detailPageUrl: string;
  price: number;
  licenseType: LicenseType;
};

export type OrderDetail = {
  orderInfo: OrderInfo;
  billingAddress: BillingAddress | null;
  items: Items[];
};

export type OrderRefundHistory = {
  refundInfo: RefundInfo;
  comments: Comments[];
};

export type RefundInfo = {
  paymentRefundStatus: OrderRefundStatus;
  paymentRefundId: string;
  taxRefundStatus: OrderRefundStatus;
  taxRefundDocCode: string;
};

export type Comments = {
  registeredBy: string;
  registeredDate: string;
  comment: string;
};

export type GetOrderDetailParams = { orderNo: number };

export enum ItemType {
  GARMENT = 'E6B30',
  FABRIC = '82B1A',
  TRIM = '36D98',
  AVATAR = 'C6997',
  SCENE = 'SCE00',
}

export enum ItemTypeSubcategory {
  // -- Trim -- //
  BUTTONS = 'C5375',
  FASTENERS = '32337',
  ZIPPERS = 'BC576',
  HARDWARES = 'AC05A',
  NOTIONS = '258D3',
  // -- Avatar -- //
  MODEL = '48282',
  MOTION = 'MOTIN',
  POSE = '585BB',
  HAND_POSE = 'HNDPS',
  HAIR = '3EC8C',
  SHOES = '36620',
  GLASSES = '8B2Z5',
  EARRING = '9B1Z7',
  MANNEQUIN = '7A3D4',
}

export enum PaymentHistoryLogCode {
  FAILED_AUTHENTICATION = -40,
  FAILED_VERIFICATION = -30,
  FAILED = -20,
  FAILED_BUT_CHARGE_SUCCESS = -10,
  PREPARED = 0,
  ORDER_CREATED = 10,
  COMPLETED = 20,
}

export interface OrderFilterForm {
  itemTypes: string[];
  salesTypes: number[];
  keyword: string;
  keywordType: OrderKeywordType;
  coupon: boolean;
  licenseTypes: number[];
  paymentTypes: OrderPaymentMethod[];
  paymentStatuses: OrderPaymentStatus[];
}
