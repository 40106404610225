import * as React from 'react';
import styled from 'styled-components';
import { BenchmarkResult } from 'types/member';
import ApiTest from './tests/ApiTest';
import DownloadTest from './tests/DownloadTest';
import UploadTest from './tests/UploadTest';
import ViewerTest from './tests/ViewerTest';

interface Props extends BenchmarkResult {}

const BenchmarkTests = ({
  apiTestData,
  downloadData,
  viewer1Data,
  viewer2Data,
  uploadData,
}: Props) => {
  return (
    <PlanInformationBlock>
      <ApiTest apiTestData={[apiTestData]} />
      <DownloadTest downloadTestData={downloadData} />
      <UploadTest uploadTestData={uploadData} />
      <ViewerTest viewerTestData={[viewer1Data, viewer2Data]} />
    </PlanInformationBlock>
  );
};

export default BenchmarkTests;

const PlanInformationBlock = styled.div`
  width: 100%;
  padding: 0 ${props => props.theme.spacing(2)}px;
`;
