import { LicenseType, StoreType } from 'types/common';
import { PaginationProps } from 'types/common/pagination';
import { SellerStatus } from 'types/marketplace/confirmSellerInfo';

export enum RevenueSortType {
  SELLER_REVENUE_DESC = 1,
  SELLER_REVENUE_ASC,
  ORIGINAL_ITEM_REVENUE_DESC,
  ORIGINAL_ITEM_REVENUE_ASC,
}

export enum MonthlyPayoutSortType {
  SELLER_PAID_DESC = 1,
  SELLER_REVENUE_DESC = 3,
}

export interface SellerRevenueFilter {
  startDate: string;
  endDate: string;
  keyword: string;
  store: StoreType[];
  sortType: RevenueSortType;
  userId?: number;
  pageNo: number;
  pageSize: number;
}

export interface SellerRevenueDetailFilter extends SellerRevenueFilter {
  email: string;
}

export interface MonthlyPayoutFilter {
  year: string; // yyyy
  sortType: MonthlyPayoutSortType;
  keyword: string;
  pageSize?: number;
  pageNo: number;
}

export interface RevenueInfo {
  userId: number;
  sellerName: string;
  email: string;
  itemSales: number;
  originalItemSales: number;
  originalItemRevenueShare: number;
  totalSales: number;
  feeRate: number;
  netIncome: number;
  revenue: number;
}

export interface SellerRevenueData {
  sellerSales: RevenueInfo[];
  total: RevenueInfo & PaginationProps;
  pageNo: number;
  pageSize: number;
  totalCount: number;
}

export interface RevenueOriginalItem {
  itemId: string;
  itemName: string;
  price: number;
  imageFilePath: string;
}

export interface SellerRevenueDetail extends RevenueOriginalItem {
  orderNo: number;
  licenseType: LicenseType;
  store: StoreType;
  status: RevenueStatus;
  orderDate: {
    utc: string;
    kst: string;
  };
  originalItems: RevenueOriginalItem[];
  derivedItem: DerivedItem | null;
}

export interface DerivedItem {
  itemName: string;
  itemId: string;
  imageFilePath: string;
}

export interface SellerRevenueDetailInfo {
  email: string;
  paypalAccount: string | null;
  startDate: string;
  endDate: string;
  totalCount: number;
  totalRevenue: number;
  itemSalesAndOriginalSales: number;
  originalItemRevenueShare: number;
  store: StoreType | null;
  details: SellerRevenueDetail[];
}

export interface SellerMonthPayoutInfo {
  amount: number | null;
  status: MonthlyPayoutStatus;
}

export enum SellerPayoutStatus {
  CONTINUE = 1,
  PAUSE,
}

export const SellerPayoutStatusLabel = {
  [SellerPayoutStatus.CONTINUE]: 'Continue',
  [SellerPayoutStatus.PAUSE]: 'Pause',
};

export interface SellerMonthlyPayoutItem {
  sellerName: string;
  email: string;
  userId: number;
  sellerStatus: SellerPayoutStatus;
  payoutInfo: SellerStatus | null;
  paidAmount: number;
  status: SellerPayoutStatus;
  jan: SellerMonthPayoutInfo;
  feb: SellerMonthPayoutInfo;
  mar: SellerMonthPayoutInfo;
  apr: SellerMonthPayoutInfo;
  may: SellerMonthPayoutInfo;
  jun: SellerMonthPayoutInfo;
  jul: SellerMonthPayoutInfo;
  aug: SellerMonthPayoutInfo;
  sep: SellerMonthPayoutInfo;
  oct: SellerMonthPayoutInfo;
  nov: SellerMonthPayoutInfo;
  dec: SellerMonthPayoutInfo;
}

export interface TotalMonthlyPayoutItem extends SellerMonthlyPayoutItem {
  year: number;
  isCompletedYear?: boolean;
}

export interface SellerMonthlyPayout {
  totalInfo: TotalMonthlyPayoutItem & PaginationProps;
  list: SellerMonthlyPayoutItem[];
}

export enum MonthlyPayoutModalType {
  TOTAL,
  USER,
}

export interface SellerStatusModalInfo {
  email: string;
  userId: number;
}

export interface PayoutDetailModalInfo {
  type: MonthlyPayoutModalType;
  userId?: number;
  month: number;
}

export interface SellerPayoutStatusComment {
  seq: number;
  userId: number;
  registerAdminEmail: string;
  status: SellerPayoutStatus;
  comment: string;
  registeredDate: string;
}

export interface SellerPayoutStatusInfo {
  userId: number;
  email: string;
  status: number;
  comments: SellerPayoutStatusComment[];
}

export interface SellerPayoutDetailItem {
  orderDate: string;
  orderNo: string;
  itemId: string;
  itemName: string;
  store: StoreType;
  price: number;
  revenue: number;
  taxCountry: string | null;
  withholdingTax: number;
  withholdingTaxRate: number | null;
}

export enum MonthlyPayoutStatus {
  DISABLE = -1,
  NONE,
  PAYOUT,
  CONFIRM,
  UNPAID,
  FUTURE = 99,
}

export interface SellerPayoutDetailInfo {
  year: number;
  month: number;
  sellerName: string;
  email: string;
  status: MonthlyPayoutStatus;
  payoutInfoStatus: SellerStatus | null;
  sellerStatus: SellerPayoutStatus;
  payout: {
    payoutDay: string;
    payoutPeriod: {
      startDate: string;
      endDate: string;
    };
    monthlySalesPeriod: {
      startDate: string;
      endDate: string;
    };
    finalPayoutAmount: number;
  };
  item: {
    itemSalesAmount: number;
    originalItemSalesAmount: number;
    originalItemRevenueShare: number;
    totalSalesAmount: number;
  };
  fee: {
    averageFeeRate: number;
    totalFee: number;
    withholdingTax: number;
  };
  payoutList: SellerPayoutDetailItem[];
  deduction: number;
}

export enum PayoutDetailAction {
  CONFIRM,
  COMPLETION,
}

export interface TotalPayoutDetailInfo {
  year: number;
  month: number;
  status: MonthlyPayoutStatus;
  confirm: { adminEmail: string; date: string } | null;
  payout: {
    adminEmail: string | null;
    payoutDay: string | null;
    payoutPeriod: {
      startDate: string;
      endDate: string;
    };
    monthlySalesPeriod: {
      startDate: string;
      endDate: string;
    };
    finalPayoutAmount: number;
  };
  item: {
    itemSalesAmount: number;
    originalItemSalesAmount: number;
    originalItemRevenueShare: number;
    totalSalesAmount: number;
  };
  fee: {
    averageFeeRate: number;
    totalFee: number;
    withholdingTax: number;
  };
  deduction: number;
}

export interface SellerPayoutStatusParams {
  userId: number;
  status: SellerPayoutStatus;
  comment: string;
}

export type OnChangePayoutStatusHandler = (
  params: Omit<SellerPayoutStatusParams, 'userId'>,
) => void;

export interface TotalPayoutDetailBaseProps {
  confirmInfo: { loading: boolean };
  payoutInfo: { loading: boolean };
  confirm: () => void;
  payout: () => void;
  onPaypalDownload: () => void;
}

export enum RevenueStatus {
  REFUND = -1,
  WAITING = 0,
  COMPLETE = 1,
}

export enum SellerMonthlyPayoutKey {
  PAID_AMOUNT = 'paidAmount',
}

export interface MonthYearParams {
  month: number;
  year: number;
}

export enum WithholdingTaxSortType {
  SELLER_COUNTRY = 1,
  SELLER_ACCOUNT,
  ORDER_NUMBER,
}

export interface SellerWithholdingTaxItem {
  isPayout: boolean;
  payoutDate: string;
  orderNo: number;
  orderedDate: string;
  itemName: string;
  userId: number;
  sellerRevenue: number;
  sellerAccount: string;
  sellerCountry: string | null;
  taxRate: number;
  withholdingTax: number;
}

export interface SellerWithholdingTax extends PaginationProps {
  items: SellerWithholdingTaxItem[];
}

export interface SellerWithholdingTaxFilter {
  year: string;
  month: string;
  sortBy: WithholdingTaxSortType;
  keyword: string;
  pageSize?: number;
  pageNo: number;
}
