import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';

interface AuthState {
  isLogin: boolean;
  loginUserInfo: {
    userId: string;
    name: string;
    email: string;
  };
}

const initialState: AuthState = {
  isLogin: false,
  loginUserInfo: {
    userId: '',
    name: '',
    email: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getLoginUser: (state: AuthState, _action: PayloadAction<any>) => {},
    getLoginUserSuccess: (state: AuthState, action: PayloadAction<any>) => {
      state.loginUserInfo = action.payload;
      state.isLogin = true;
    },
    getLoginUserFailure: (state: AuthState, _action: PayloadAction<any>) => {
      state.isLogin = false;
    },
  },
});

export const authActions = authSlice.actions;

export const selectLoginUserInfo = (state: RootState) => state.auth.loginUserInfo;

export default authSlice.reducer;
