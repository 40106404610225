import * as React from 'react';
import { ChildElementType } from 'types/common/routes';
import { ReactNode } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

const RouteChildComponent = ({
  Layout,
  element,
  key,
}: {
  Layout: React.ElementType;
  element: ChildElementType;
  key: string | number;
}): ReactNode => {
  const { component: Component, path } = element;

  return Component ? (
    <Route
      key={key}
      path={path}
      exact
      render={(props: RouteComponentProps) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  ) : null;
};

export default RouteChildComponent;
