import * as React from 'react';
import { Typography as MuiTypography, Paper as MuiPaper } from '@material-ui/core';
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { InvoiceDescriptionInformation } from 'types/plansales/billing';
import { getBillingMessage } from 'modules/billing/BillingLanguage';

interface Props {
  descriptionMessage: InvoiceDescriptionInformation;
}

const InvoiceSummaryDescription = ({ descriptionMessage }: Props) => {
  const showAdditionalMessage = !!descriptionMessage.additionalMessage?.languageKey;
  return (
    <InvoiceSummaryDescriptionBlock>
      <Typography variant={'subtitle1'} mb={2}>
        Invoice Message
      </Typography>
      <Paper p={3}>
        <Description mb={1}>
          {getBillingMessage(
            descriptionMessage.summaryMessage.languageKey,
            descriptionMessage.summaryMessage.values,
          )}
        </Description>
        {showAdditionalMessage && (
          <Description>
            {getBillingMessage(
              descriptionMessage?.additionalMessage?.languageKey,
              descriptionMessage?.additionalMessage?.values,
            )}
          </Description>
        )}
      </Paper>
    </InvoiceSummaryDescriptionBlock>
  );
};

export default InvoiceSummaryDescription;

const Paper = styled(MuiPaper)(spacing);
export const Typography = styled(MuiTypography)(spacing);
const Description = styled.div(spacing);

const InvoiceSummaryDescriptionBlock = styled.div`
  margin-top: ${props => props.theme.spacing(2)}px;
  margin-bottom: ${props => props.theme.spacing(5)}px;
`;
