import * as React from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  onClickButton: () => void;
  disabled: boolean;
}

const DeleteButton = ({ onClickButton, disabled }: Props) => {
  return (
    <DeleteAccountButton
      variant={'contained'}
      color={'primary'}
      size={'small'}
      onClick={onClickButton}
      disabled={disabled}
    >
      {disabled ? `Deleted` : `Delete Account`}
    </DeleteAccountButton>
  );
};

export default DeleteButton;

const DeleteAccountButton = styled(Button)`
  width: 120px;
  margin-left: ${props => props.theme.spacing(3)}px;
`;
