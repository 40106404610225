import Url from 'url-parse';

const replaceDomain = (url: string) => {
  if (url?.indexOf('http') === -1) {
    throw Error('require protocol');
  }
  const { hostname } = new Url(url);
  return hostname.replace(/.clo-set.com/g, '');
};

export const getTokenName = (subdomain: string) => {
  return `${subdomain ? subdomain + '_' : ''}jwt_token`;
};

export const getSubdomain = (url: string) => {
  /* api server의 subdomain 알아내기 www는 공백으로 처리한다. */
  const subdomain = replaceDomain(url);

  if (subdomain !== 'www') {
    return subdomain;
  } else {
    return '';
  }
};
export const getOnlySubdomain = (url: string) => {
  // 무조건 subdomain만 반환 한다
  return replaceDomain(url);
};
