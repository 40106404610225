import * as React from 'react';
import AccordionBox from 'components/memberdetail/detail/AccordionBox';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import { TableCell } from 'types/common/table';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import { getUtcUSFormatDateWithTime } from 'modules/utils/time';
import { AuthProvider as AuthProviderType } from 'types/member';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';
import styled from 'styled-components';

interface Props {
  authProviderList: AuthProviderType[];
}

const AuthProvider = ({ authProviderList }: Props) => {
  const authProviderColumns: TableCell<AuthProviderType>[] = [
    {
      align: 'center',
      width: '50%',
      name: 'Auth Provider',
      key: 'providerName',
      render: (rowData: AuthProviderType) => <UpperText>{rowData.authProviderName}</UpperText>,
    },
    {
      align: 'center',
      name: 'Registered Date',
      key: 'registeredDate',
      render: (rowData: AuthProviderType) =>
        getUtcUSFormatDateWithTime(rowData.registrationDate, true),
    },
  ];
  return (
    <AccordionBox title={'Auth Provider'} margin={'15px 0 0 0'} name={'authProvider'}>
      <DataTableLayout
        customTitleComponent={<TotalCount>total {authProviderList.length}</TotalCount>}
      >
        <DataTableHeader header={authProviderColumns} />
        <NewDataTableBody dataList={authProviderList} columns={authProviderColumns} />
      </DataTableLayout>
    </AccordionBox>
  );
};

export default AuthProvider;

export const UpperText = styled.span`
  text-transform: uppercase;
`;

export const TotalCount = styled.div`
  margin-left: 5px;
`;
