import * as React from 'react';
import { useEffect, useState } from 'react';
import ContentLayout from 'components/common/layouts/ContentLayout';
import {
  FeatureUpdateNoticeFormType,
  FeatureUpdateNoticeStatus,
  FeatureUpdateNoticeTab,
} from 'types/notice/featureUpdateNotice';
import { Grid } from '@material-ui/core';
import TabBar from 'components/common/tabs/TabBar';
import Tab from 'components/common/tabs/Tab';
import Button from 'components/common/Button';
import { Add as AddIcon } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import FeatureUpdateNoticeTable from 'components/notice/featureupdate/FeatureUpdateNoticeTable';
import { TabContainer, TabPanel } from 'components/common/tabs';
import { useDispatch, useSelector } from 'react-redux';
import {
  featureUpdateNoticeListActions,
  selectDeleteLoading,
  selectHistoryList,
  selectOnGoingList,
  selectOnGoingListLoading,
  selectHistoryListLoading,
} from 'features/featureupdatenotice/featureUpdateNoticeListSlice';
import { FEATURE_UPDATE_NOTICE_PATH } from 'routes/ClosetAdminRoutes';
import { addQueryString, getParsedQueryByName } from 'modules/utils/queryString';

const PAGE_TITLE = 'Feature Update Notice';
const TAB_LIST = [
  {
    label: 'On Going',
    value: FeatureUpdateNoticeTab.ON_GOING,
    to: `/notice/feature-update/${FeatureUpdateNoticeTab.ON_GOING}`,
  },
  {
    label: 'History',
    value: FeatureUpdateNoticeTab.HISTORY,
    to: `/notice/feature-update/${FeatureUpdateNoticeTab.HISTORY}`,
  },
];
const FIRST_PAGE_INDEX = 1;

const FeatureUpdateNoticeContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // tab 은 router params 로 관리
  const { tab } = useParams<{ tab?: FeatureUpdateNoticeTab }>();
  const [selectedTab, setSelectedTab] = useState<FeatureUpdateNoticeTab>(
    tab ?? FeatureUpdateNoticeTab.ON_GOING,
  );

  const onChangeTab = (value: FeatureUpdateNoticeTab): void => setSelectedTab(value);
  const getList = (pageIndex: number) => {
    addQueryString({ history, objValue: { page: pageIndex } });
    if (selectedTab === FeatureUpdateNoticeTab.ON_GOING) {
      dispatch(
        featureUpdateNoticeListActions.getOnGoingList({
          page: pageIndex,
          statuses: [FeatureUpdateNoticeStatus.SHOWING, FeatureUpdateNoticeStatus.UPCOMING],
        }),
      );
      return;
    }

    if (selectedTab === FeatureUpdateNoticeTab.HISTORY) {
      dispatch(
        featureUpdateNoticeListActions.getHistoryList({
          page: pageIndex,
          statuses: [FeatureUpdateNoticeStatus.EXPIRED],
        }),
      );
      return;
    }
  };

  useEffect(() => {
    const queries = history.location.search;
    const pageQuery = Number(getParsedQueryByName(queries, 'page') as string);
    getList(pageQuery > 0 ? pageQuery : FIRST_PAGE_INDEX);
  }, [selectedTab]);

  const onGoingList = useSelector(selectOnGoingList);
  const historyList = useSelector(selectHistoryList);
  const onGoingListLoading = useSelector(selectOnGoingListLoading);
  const historyListLoading = useSelector(selectHistoryListLoading);

  const onClickCreateButton = () => {
    history.push(`${FEATURE_UPDATE_NOTICE_PATH}/${FeatureUpdateNoticeFormType.CREATE}`);
  };

  const tabs = [
    {
      tab: FeatureUpdateNoticeTab.ON_GOING,
      data: onGoingList,
      loading: onGoingListLoading,
    },
    {
      tab: FeatureUpdateNoticeTab.HISTORY,
      data: historyList,
      loading: historyListLoading,
    },
  ];

  const deleteLoading = useSelector(selectDeleteLoading);
  const onDelete = (seq: string) => {
    dispatch(featureUpdateNoticeListActions.deleteModal({ seq }));
  };

  return (
    <ContentLayout pageTitle={PAGE_TITLE} loading={false}>
      <TabContainer selectedTab={selectedTab}>
        <Grid container justify="space-between">
          <Grid item>
            <TabBar<FeatureUpdateNoticeTab> selectedValue={selectedTab} onChange={onChangeTab}>
              {TAB_LIST.map((tabProps, index) => (
                <Tab key={index} {...tabProps} />
              ))}
            </TabBar>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onClickCreateButton}>
              <AddIcon />
              Create Modal
            </Button>
          </Grid>
        </Grid>
        {tabs.map(({ tab, data, loading }, index) => (
          <TabPanel value={tab} spacing={8} key={index}>
            <FeatureUpdateNoticeTable
              tab={tab}
              data={data}
              onChangePage={getList}
              onDelete={onDelete}
              deleteLoading={deleteLoading}
              loading={loading}
            />
          </TabPanel>
        ))}
      </TabContainer>
    </ContentLayout>
  );
};

export default FeatureUpdateNoticeContainer;
