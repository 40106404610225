import { Nullable } from 'types/common';

export const isNumericString = (text: string) => /^[0-9]*$/.test(text);

/**
 * This is a function changes from ignoreLetter to replaceLetter
 * @param {string} value - input Text
 * @param {string} ignoreLetter - Spellings to be exception-processed.
 * @param {string} replaceLetter - default: `_`
 * @return {string}
 */
export const replaceIgnoreText = (
  value: string,
  ignoreLetter: string,
  replaceLetter: string = '_',
): string => {
  const regexIgnoreLetter = new RegExp(`[${ignoreLetter}]`, 'g');
  return value.trim().replace(regexIgnoreLetter, replaceLetter);
};

/** Convert enum value to regex pattern
 * @param {object} enumObject
 */
const enumToRegexPattern = (enumObject: object): string => {
  return Object.values(enumObject)
    .map(value => value.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
    .join('|');
};

/** Separate by pattern value
 * If there is a pattern identical to the value of the enum passed to filteredPattern
 * at the end of the passed text, it is separated by the pattern and returned as a string array.
 * example: testFileName_aaa_bbb
 *          ['testFileName', '_aaa_bbb' ] = splitTrailingPattern(testFileName_aaa_bbb, {AAA_BBB: '_aaa_bbb'})
 * @param {string} text
 * @param {object} filteredPattern
 */
export const splitTrailingPattern = (
  text: string,
  filteredPattern: object,
): Nullable<[string, string]> => {
  const pattern = enumToRegexPattern(filteredPattern);
  const regexPattern = new RegExp(`^(.*?)(${pattern})$`);
  const match = text.match(regexPattern);
  if (!match) return null;
  return [match[1], match[2]];
};
