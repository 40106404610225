import axios from 'axios';
// 파라미터 전달 시 camelCase -> snake_case 로 변경되어 해제
// import applyCaseMiddleware from "axios-case-converter";

const config = {
  headers: {
    // "api-version": "2.0", // TODO 확인 필요
    // "x-content-case": "Camel",
  },
};

export const PATH = '/api/PCS';

export default axios.create(config);
