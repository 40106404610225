import styled from 'styled-components';
import { mq } from '@closet-design-system/theme';

export const HideInMobile = styled.div<{ display?: string }>(({ theme, display = 'block' }) =>
  mq({ m: theme.breakpoints.s })({
    display: ['none', display],
  }),
);

export const ShowInMobile = styled.div<{ display?: string }>(({ theme, display = 'block' }) =>
  mq({ m: theme.breakpoints.s })({
    display: [display, 'none'],
  }),
);
