import { combineReducers } from '@reduxjs/toolkit';
import { monthlyPayoutReducer } from 'features/revenue/monthlyPayoutSlice';
import { revenueDetailReducer } from 'features/revenue/revenueDetailSlice';
import { sellerRevenueReducer } from 'features/revenue/sellerRevenueSlice';
import { withholdingTaxReducer } from 'features/revenue/withhodingTaxSlice';

export const revenueReducer = combineReducers({
  seller: sellerRevenueReducer,
  monthly: monthlyPayoutReducer,
  detail: revenueDetailReducer,
  withholdingTax: withholdingTaxReducer,
});
