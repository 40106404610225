import { ApiError, ErrorResult } from 'types/api';

const checkIsErrorResult = (param: ErrorResult): param is ErrorResult => {
  return (param as ErrorResult).resultMessage !== undefined;
};

export const alertApiError = (error: ApiError) => {
  const { response, request, message } = error;
  if (response) {
    const { data } = response;
    if (checkIsErrorResult(data)) {
      alert(data.resultMessage);
      return;
    }
  }

  if (request && request.responseText) {
    alert(request.responseText);
    return;
  }

  if (message) {
    alert(error.message);
    return;
  }

  alert('오류가 발생했습니다.');
};

export const generateErrorMessage = (field: string, method: string): string =>
  `${field}: ${method} Error`;
