import * as React from 'react';
import styled from 'styled-components';
import InformationTable from 'components/common/table/InformationTable';
import { InformationObject } from 'types/common/informationTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  memberDetailActions,
  selectBillingAddressData,
} from 'features/member/reducer/memberDetailSlice';
import { useEffect } from 'react';
import { NATIONAL_CODE } from 'constants/common/nationalCode';

interface Props {
  userId: string;
}

const BillingAddressContainer = ({ userId }: Props) => {
  const dispatch = useDispatch();
  const { country, address, address2, city, state, zipCode, vatNumber } =
    useSelector(selectBillingAddressData);
  const region = NATIONAL_CODE[country];

  const billingAddressInformation: InformationObject[] = [
    { title: 'Region', render: region?.label, titleWidth: '20%' },
    { title: 'Address 1', render: address, titleWidth: '20%' },
    { title: 'Address 2', render: address2, titleWidth: '20%' },
    { title: 'City', render: city, titleWidth: '20%' },
    { title: 'State', render: state, titleWidth: '20%' },
    { title: 'Zip Code', render: zipCode, titleWidth: '20%' },
    { title: 'Vat Number', render: vatNumber, titleWidth: '20%' },
  ];

  useEffect(() => {
    dispatch(memberDetailActions.getBillingAddress({ userId }));
    return () => {
      dispatch(memberDetailActions.resetBillingAddress());
    };
  }, []);

  return (
    <BillingAddressGroup>
      <InformationTable title={'[CONNECT] Billing Address'} data={billingAddressInformation} />
    </BillingAddressGroup>
  );
};

export default BillingAddressContainer;

const BillingAddressGroup = styled.div`
  width: 100%;
  margin-top: 15px;
`;
