import * as React from 'react';
import { ReactElement } from 'react';
import { ArrowTriangleRightIcon } from '@closet-design-system/core';
import { mq, theme } from '@closet-design-system/theme';
import styled from 'styled-components';

const ContestTimelineDesktop = (): ReactElement => {
  return (
    <DesktopProgressbarLayout>
      <ProgressbarBackground>
        <ProgressBar>
          <ProgressCircle>
            <ArrowTriangleRightIcon size={24} color={theme.colors.GRAY_900} />
          </ProgressCircle>
        </ProgressBar>
      </ProgressbarBackground>
    </DesktopProgressbarLayout>
  );
};

const DesktopProgressbarLayout = styled.div(
  ({
    theme: {
      breakpoints: { m },
    },
  }) =>
    mq({ m })({
      maxWidth: [516, 748],
      padding: '10px 14px',
      margin: '0 auto',
    }),
);

const ProgressbarBackground = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 30px;
  background: ${({ theme }) => theme.colors.GRAY_600};
`;

const ProgressBar = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.AQUA_200};
  height: 100%;
  border-radius: 30px;
`;

const ProgressCircle = styled.div`
  position: absolute;
  right: -14px;
  width: 24px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.AQUA_200};
`;

export default ContestTimelineDesktop;
