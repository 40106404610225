import { combineReducers } from '@reduxjs/toolkit';
import { commissionNetIncomeReducer } from 'features/netincome/commissionNetIncomeSlice';
import { itemNetIncomeReducer } from 'features/netincome/ItemNetIncomeSlice';
import { totalNetIncomeReducer } from 'features/netincome/totalNetIncomeSlice';

export const netIncomeReducer = combineReducers({
  total: totalNetIncomeReducer,
  item: itemNetIncomeReducer,
  commission: commissionNetIncomeReducer,
});
