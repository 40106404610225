import { ArtworkInfo, ArtworkParams, HistoryType } from 'types/api/jinny';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'features/rootReducer';

export interface JinnyState {
  artwork: {
    loading: boolean;
    data: ArtworkInfo | null;
    error: any;
  };
}

const initialState: JinnyState = {
  artwork: {
    loading: false,
    data: null,
    error: null,
  },
};

export const jinnySlice = createSlice({
  name: 'jinny',
  initialState,
  reducers: {
    resetArtwork: (state: JinnyState) => {
      state.artwork.loading = false;
      state.artwork.data = null;
      state.artwork.error = null;
    },
    getArtwork: (state: JinnyState) => {
      state.artwork.loading = true;
    },
    getArtworkSuccess: (state: JinnyState, action: PayloadAction<any>) => {
      state.artwork.loading = false;
      state.artwork.data = action.payload;
    },
    getArtworkFailure: (state: JinnyState, action: PayloadAction<any>) => {
      state.artwork.loading = false;
      state.artwork.error = action.payload;
    },
    editArtwork: (state: JinnyState, _action: PayloadAction<ArtworkParams & HistoryType>) => {
      state.artwork.loading = true;
    },
    editArtworkSuccess: (state: JinnyState, action: PayloadAction<any>) => {
      state.artwork.loading = false;
      state.artwork.data = action.payload;
      state.artwork.error = null;
    },
    editArtworkFailure: (state: JinnyState, action: PayloadAction<any>) => {
      state.artwork.loading = false;
      state.artwork.data = null;
      state.artwork.error = action.payload?.errors;
    },
  },
});

export const jinnyReducer = jinnySlice.reducer;
export const jinnyActions = jinnySlice.actions;

export const selectArtworkLoading = (state: RootState) => state.jinny.artwork.loading;
export const selectArtworkData = (state: RootState) => state.jinny.artwork.data;
export const selectArtworkError = (state: RootState) => state.jinny.artwork.error;
