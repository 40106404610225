import { Paper, Table, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { StyledBodyTitleCell, StyledHeaderCell } from 'components/common/table/TableStyle';
import * as React from 'react';
import styled from 'styled-components';
import { BenchmarkListItem, BenchmarkResult } from 'types/member';

interface Props {
  detailItem: BenchmarkListItem;
}

const BenchmarkUser = ({ detailItem }: Props) => {
  const { email, result } = detailItem;
  const { cfInfo } = result as BenchmarkResult;
  const { country, city, timezone, region } = cfInfo || { country: '-' };
  return (
    <TableContainer component={Paper}>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <StyledHeaderCell>
              <CellText>Email</CellText>
            </StyledHeaderCell>
            <StyledBodyTitleCell colSpan={7}>
              <CellText>{email}</CellText>
            </StyledBodyTitleCell>
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            <StyledHeaderCell>
              <CellText>Country</CellText>
            </StyledHeaderCell>
            <StyledBodyTitleCell>
              <CellText>{country}</CellText>
            </StyledBodyTitleCell>
            <StyledHeaderCell>
              <CellText>Region</CellText>
            </StyledHeaderCell>
            <StyledBodyTitleCell>
              <CellText>{region}</CellText>
            </StyledBodyTitleCell>
            <StyledHeaderCell>
              <CellText>City</CellText>
            </StyledHeaderCell>
            <StyledBodyTitleCell>
              <CellText>{city}</CellText>
            </StyledBodyTitleCell>
            <StyledHeaderCell>
              <CellText>Timezone</CellText>
            </StyledHeaderCell>
            <StyledBodyTitleCell>
              <CellText>{timezone}</CellText>
            </StyledBodyTitleCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export default BenchmarkUser;

const CellText = styled.span`
  margin: 0 20px;
`;
