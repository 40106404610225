import * as React from 'react';
import { ReactElement, useState } from 'react';
import Text from '../../common/Text';
import styled from 'styled-components';
import CommonButton from '../../common/Button';
import { spacing } from '@material-ui/system';
import copy from 'copy-to-clipboard';
import EmailVerificationLinkModal from './EmailVerificationLinkModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  memberDetailActions,
  selectEmailVerificationLinkData,
} from '../../../features/member/reducer/memberDetailSlice';

const CreateAnEmailVerificationLinkButton = ({ userId }: { userId: number }): ReactElement => {
  const dispatch = useDispatch();
  const emailVerificationLinkData = useSelector(selectEmailVerificationLinkData);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  const fetchEmailVerificationLink = (): void => {
    dispatch(memberDetailActions.getEmailVerificationLink({ userId }));
  };

  const openLinkCopyModal = (): void => {
    setModalVisibility(true);
  };

  const onClose = (): void => {
    setModalVisibility(false);
  };

  const onClickCreate = (): void => {
    fetchEmailVerificationLink();
    openLinkCopyModal();
  };

  const onCopyLink = (): void => {
    if (!emailVerificationLinkData?.link) return;
    copy(emailVerificationLinkData.link);
  };

  return (
    <>
      <Button onClick={onClickCreate} variant="contained" color={'default'} mt={2} size={'small'}>
        <Text>Create an email verification link</Text>
      </Button>
      {modalVisibility && (
        <EmailVerificationLinkModal
          modalVisibility={modalVisibility}
          emailVerificationLink={emailVerificationLinkData?.link}
          onCopyLink={onCopyLink}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default CreateAnEmailVerificationLinkButton;

const Button = styled(CommonButton)(spacing);
