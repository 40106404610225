import * as React from 'react';
import { ReactElement } from 'react';
import { mq } from '@closet-design-system/theme';
import OnePieceType2Background from 'components/marketplace/operation/contest/preview/desktop/onepiecelayout/OnePieceType2Background';
import OnePieceType1Background from 'components/marketplace/operation/contest/preview/desktop/onepiecelayout/OnePieceType1Background';
import styled from 'styled-components';
import { LayoutType } from 'types/marketplace/operation/contest';

interface Props {
  layoutType: LayoutType;
  children: ReactElement;
}

const OnePieceLayoutTemplate = ({ children, layoutType }: Props): ReactElement => {
  const onePieceItemJustifyContentOption: { [key: string]: string } = {
    [LayoutType.ONE_PIECE_1]: 'start',
    [LayoutType.ONE_PIECE_2]: 'end',
    [LayoutType.ONE_PIECE_3]: 'center',
  };

  return (
    <OnePieceLayoutTemplateWrap>
      <OnePieceLayoutTemplateRoot justifyContent={onePieceItemJustifyContentOption[layoutType]}>
        <ThumbnailWrap>{children}</ThumbnailWrap>
        {layoutType === LayoutType.ONE_PIECE_1 && <OnePieceType1Background />}
        {layoutType === LayoutType.ONE_PIECE_2 && <OnePieceType2Background />}
      </OnePieceLayoutTemplateRoot>
    </OnePieceLayoutTemplateWrap>
  );
};

export default OnePieceLayoutTemplate;

const OnePieceLayoutTemplateWrap = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 8px 0;
`;

const OnePieceLayoutTemplateRoot = styled.div<{ justifyContent: string }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const ThumbnailWrap = styled.div(
  ({
    theme: {
      breakpoints: { l },
    },
  }) =>
    mq({ l })({
      width: ['50%', 706],
      zIndex: 1,
    }),
);
