import { all, takeLatest, put, call } from 'redux-saga/effects';
import { billingActions } from './billingSlice';
import * as api from 'api/plansales/billing';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  GetBillingDetailParams,
  GetBillingInvoiceListParams,
  PostRefundRequestParams,
} from 'types/plansales/billing';
import { AxiosResponse } from 'axios';

export function* getSearchResultSaga(action: PayloadAction<GetBillingInvoiceListParams>) {
  const { data } = yield call(api.getSearchResult, action.payload);
  if (data) {
    yield put(billingActions.getSearchResultSuccess(data));
  } else {
    yield put(billingActions.getSearchResultFailure(data));
  }
}

export function* getExportDataSaga(action: PayloadAction<GetBillingInvoiceListParams>) {
  const { data } = yield call(api.getExportData, action.payload);
  if (data) {
    yield put(billingActions.getExportDataSuccess(data));
    yield put(billingActions.setExportReady(true));
  } else {
    yield put(billingActions.getExportDataFailure(data));
  }
}

export function* getBillingDetailSaga(action: PayloadAction<GetBillingDetailParams>) {
  const { data } = yield call(api.getBillingDetail, action.payload);
  if (data) {
    yield put(billingActions.getBillingDetailSuccess(data));
  } else {
    yield put(billingActions.getBillingDetailFailure(data));
  }
}

export function* requestRefundSaga(action: PayloadAction<PostRefundRequestParams>) {
  const data: AxiosResponse = yield call(api.postRefundRequest, action.payload);
  if (data.status === 200) {
    yield put(billingActions.requestRefundSuccess(data));
  } else {
    yield put(billingActions.requestRefundFailure(data));
  }
}

export default function* billingSaga() {
  yield all([
    takeLatest(billingActions.getExportData.type, getExportDataSaga),
    takeLatest(billingActions.getSearchResult.type, getSearchResultSaga),
    takeLatest(billingActions.getBillingDetail.type, getBillingDetailSaga),
    takeLatest(billingActions.requestRefund.type, requestRefundSaga),
  ]);
}
