import * as React from 'react';
import styled from 'styled-components';
import { Ref } from 'react';
import ExcelExportButton from 'components/common/file/ExcelExportButton';
import DataTableHeader from 'components/common/table/datatable/DataTableHeader';
import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import { ExcelMemberExport, Member, MemberExcel } from 'types/member';
import NewDataTableBody from 'components/common/table/datatable/NewDataTableBody';
import { memberListColumns } from './MemberListColumns';
import { getUtcDateNoDash } from 'modules/utils/time';

interface Props {
  memberList: Member[];
  totalPages: number;
  mailListExportData: MemberExcel[];
  mailListExportLoading: boolean;
  marketingAgreeExportData: MemberExcel[];
  marketingAgreeExportLoading: { closet: boolean; connect: boolean };
  onClickMailListExport: () => void;
  onClickMarketingAgreeExport: (type: ExcelMemberExport) => () => void;
  refs: { [key: string]: Ref<HTMLButtonElement> };
  memberListLoading: boolean;
  onChangePage: (pageIndex: number) => void;
  currentPageIndex: number;
  totalItems: number;
}

const MemberListTable = ({
  memberList,
  onClickMailListExport,
  onClickMarketingAgreeExport,
  mailListExportData,
  mailListExportLoading,
  marketingAgreeExportData,
  marketingAgreeExportLoading,
  refs, // multiple ref 넘기기 위해 props 로 전달
  totalPages,
  memberListLoading,
  onChangePage,
  currentPageIndex,
  totalItems,
}: Props) => {
  const { mailListExportRef, closetMarketingAgreeExportRef, connectMarketingAgreeExportRef } = refs;
  const memberListColumnData = memberListColumns(totalItems, currentPageIndex);
  const today = getUtcDateNoDash(new Date()); // YYYYMMDD 형태
  return (
    <DataTableLayout
      showPagination
      totalPages={totalPages}
      onChangePage={onChangePage}
      currentPageIndex={currentPageIndex}
      upperButton={
        <UpperButtons>
          <ExcelExportButton
            width={140}
            buttonText={'Export Email List'}
            fileName={`closet_member_mailing_list_${today}`}
            sheetName={today}
            ref={mailListExportRef}
            onClickExport={onClickMailListExport}
            exportData={mailListExportData}
            exportLoading={mailListExportLoading}
          />
          <ExcelExportButton
            width={280}
            buttonText={'Export CLO-SET Marketing Agreement List'}
            fileName={`closet_member_marketing_list_${today}`}
            sheetName={today}
            ref={closetMarketingAgreeExportRef}
            onClickExport={onClickMarketingAgreeExport(ExcelMemberExport.CLOSET_MARKETING)}
            exportData={marketingAgreeExportData}
            exportLoading={marketingAgreeExportLoading.closet}
          />
          <ExcelExportButton
            width={290}
            buttonText={'Export CONNECT Marketing Agreement List'}
            fileName={`connect_member_marketing_list_${today}`}
            sheetName={today}
            ref={connectMarketingAgreeExportRef}
            onClickExport={onClickMarketingAgreeExport(ExcelMemberExport.CONNECT_MARKETING)}
            exportData={marketingAgreeExportData}
            exportLoading={marketingAgreeExportLoading.connect}
          />
        </UpperButtons>
      }
    >
      <DataTableHeader header={memberListColumnData} />
      <NewDataTableBody
        loading={memberListLoading}
        dataList={memberList}
        columns={memberListColumnData}
      />
    </DataTableLayout>
  );
};
export default MemberListTable;

const UpperButtons = styled.div`
  display: flex;
  gap: 8px;
`;
