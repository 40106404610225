export enum SellerStatus {
  CONFIRMED = 1,
  PENDING,
  REJECTED,
  PAUSED,
}

export enum ConfirmSellerInfoSort {
  UPDATED_DATE = 1,
  CLOSET_ID,
  NAME,
}

export interface SellerListInfo {
  billingAddress: string;
  city: string;
  confirmedBy: string;
  connectAccount: string;
  externalIds: { [key: string]: string };
  firstName: string;
  lastName: string;
  paypalAccount: string;
  region: string;
  sellerName: string;
  state: string;
  status: number;
  tax: {
    taxFileName: string;
    taxFilePath: string;
    taxFormType: string;
  };
  updatedDate: { kst: string; utc: string };
  userId: number;
  zipcode: string;
}

export type ConfirmHistory = {
  userId: number;
  status: SellerStatus;
  histories: ConfirmComment[];
};

export type ConfirmComment = {
  changedBy: string;
  status: SellerStatus;
  changedDate: string;
  comment: string;
  reason: string | null;
};
