import * as api from 'api/marketplace/netIncome';
import {
  selectTotalNetIncomeQueryString,
  totalNetIncomeActions,
} from 'features/netincome/totalNetIncomeSlice';
import { toastAction } from 'features/toast/toastSlice';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosError } from 'axios';

const { initialize, loadDataSuccess, loadDataFailure, search, loadData, resetFilter } =
  totalNetIncomeActions;

function* updateDataSaga() {
  yield put(loadData());
}

function* loadDataSaga() {
  const queryString: string = yield select(selectTotalNetIncomeQueryString);
  try {
    const { data } = yield call(api.getTotalNetIncome, queryString);
    yield put(loadDataSuccess(data));
  } catch (error) {
    yield put(toastAction.errorResponse(error as AxiosError));
    yield put(loadDataFailure());
  }
}

export default function* totalNetIncomeSaga() {
  yield all([
    takeLatest(loadData, loadDataSaga),
    takeLatest(initialize, updateDataSaga),
    takeLatest(search, updateDataSaga),
    takeLatest(resetFilter, updateDataSaga),
  ]);
}
