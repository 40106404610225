import { SellerWithholdingTax, SellerWithholdingTaxItem } from 'types/marketplace/revenue';
import { NULL_TEXT } from 'constants/common';
import { ExcelMultiDataSet } from 'types/react-export-excel';
import { getUtcUsFormatDate } from 'modules/utils/time';

const convertExcelRow = ({
  payoutDate,
  orderNo,
  itemName,
  sellerRevenue,
  sellerAccount,
  sellerCountry,
  taxRate,
  withholdingTax,
}: SellerWithholdingTaxItem) => [
  getUtcUsFormatDate(payoutDate),
  orderNo,
  itemName,
  sellerRevenue,
  sellerAccount,
  sellerCountry ?? NULL_TEXT,
  taxRate,
  withholdingTax,
];

export const withholdingTaxDataToExcelMultiDataSet = (
  data: SellerWithholdingTax,
): ExcelMultiDataSet[] => {
  const { items: dataList } = data;

  return [
    {
      columns: [
        { value: 'Order Info', widthPx: 80 },
        { value: '', widthPx: 80 },
        { value: '', widthPx: 80 },
        { value: 'Seller Info', widthPx: 60 },
        { value: '', widthPx: 60 },
        { value: '', widthPx: 60 },
        { value: '', widthPx: 60 },
        { value: 'Tax', widthPx: 60 },
      ],
      data: [],
    },
    {
      columns: [
        'Payout Date (UTC)',
        'Order Number',
        'Item Name',
        'Seller Revenue',
        'Seller Account',
        'Seller Country',
        'Tax Rate',
        'Withholding Tax',
      ],
      data: [...dataList.map(convertExcelRow)],
    },
  ];
};
