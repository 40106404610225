import { MainBannerFormState } from 'features/marketplace/operation/mainBannerForm/mainBannerFormSlice';

export enum LayoutType {
  CENTER,
  LEFT,
  RIGHT,
  IMAGE,
}

export enum TextType {
  TYPE1,
  TYPE2,
  TYPE3,
  TYPE4,
}

export enum BannerThemeType {
  DARK,
  LIGHT,
}

export enum LinkType {
  NONE,
  BUTTON,
  BANNER,
}

export enum TabType {
  CURRENT,
  NEW,
}

export type ImageUse = 'background' | 'mainObject';
export type ImageFormData = {
  [Key in ImageUse]: File | null;
};

export interface ImagePayload {
  key: ImageUse;
  file: File | null;
}

export interface TextFormData {
  type: string;
  subtitle: string;
  title: string;
  artworkSource: string;
  sourceLink: string;
}

export interface LinkFormData {
  type: string;
  buttonText: string;
  url: string;
  tab: string;
}

export interface MainBannerFormData {
  bannerTitle: string;
  bannerLayout: string;
  image: ImageFormData;
  text: TextFormData;
  bannerTheme: string;
  link: LinkFormData;
  isShow: boolean;
}

export interface TextFormValidation {
  subtitle: boolean;
  title: boolean;
  artworkSource: boolean;
  sourceLink: boolean;
}

export interface LinkTypeValidation {
  buttonText: boolean;
  url: boolean;
}

export interface ImageValidation {
  background: boolean;
  mainObject: boolean;
}

export interface MainBannerFormValidation {
  bannerTitle: boolean;
  image: ImageValidation;
  text: TextFormValidation;
  link: LinkTypeValidation;
}

export enum TextFormDataKey {
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  ARTWORK_SOURCE = 'artworkSource',
  SOURCE_LINK = 'sourceLink',
}

type OnChangeStringValue = (value: string) => void;

export interface UseMainBannerForm extends MainBannerFormState {
  onChangeBannerTitle: OnChangeStringValue;
  onChangeBannerLayout: OnChangeStringValue;
  onChangeBackgroundImage: (file: File | null) => void;
  onChangeMainObjectImage: (file: File | null) => void;
  onChangeText: (text: TextFormData) => void;
  onChangeBannerTheme: OnChangeStringValue;
  onChangeLink: (link: LinkFormData) => void;
  onClickSaveButton: () => void;
  resetForm: () => void;
}
