import numbro from 'numbro';

// 1230, -123 -> $1,230, -$123 (mantissa: 소수점몇째자리, 3자리 콤마 표기) with 맨앞에 dollar sign
export const toMantissaWithDollarSign = (v: string | number = 0, mantissa = 2): string => {
  return numbro(v).formatCurrency({ mantissa, thousandSeparated: true });
};

// 1230, -123 -> 1,230, -123 (mantissa: 소수점몇째자리, 3자리 콤마 표기)
export const toMantissaWithThousandComma = (v: string | number = 0, mantissa = 2): string => {
  return numbro(v).format({ mantissa, thousandSeparated: true });
};

// 10, 30.0 -> 10.00%, 30.00% (mantissa: 소수점몇째자리(default 2), % 추가)
export const toMantissaWithPercentage = (v: string | number = 0, mantissa = 2): string => {
  return `${numbro(v).format({ mantissa })}%`;
};

export const sumNumbers = (numbers: number[]): number => {
  return numbers.reduce((accumulated: number, current: number) => accumulated + current, 0);
};
