import DataTableLayout from 'components/common/table/datatable/DataTableLayout';
import * as React from 'react';
import styled from 'styled-components';
import { TableCell } from '../../../types/common/table';
import { getUtcUsFormatDate } from '../../../modules/utils/time';
import DataTableHeader from '../../common/table/datatable/DataTableHeader';
import NewDataTableBody from '../../common/table/datatable/NewDataTableBody';
import { useSelector } from 'react-redux';
import {
  selectEmailChangeHistoryData,
  selectEmailChangeHistoryLoading,
} from '../../../features/member/reducer/memberDetailSlice';

const emailHistoryColumns: TableCell[] = [
  {
    align: 'center',
    name: 'Changed Date',
    key: 'changedDate',
    render: rowData => getUtcUsFormatDate(rowData.changedDate),
  },
  {
    align: 'center',
    name: 'Previous Email',
    key: 'previousEmail',
    render: rowData => rowData.previousEmail,
  },
  {
    align: 'center',
    name: 'Changed Email',
    key: 'changedEmail',
    render: rowData => rowData.changedEmail,
  },
];

const EmailChangeHistory = (): React.ReactElement => {
  const emailChangeHistoryData = useSelector(selectEmailChangeHistoryData);
  const isLoading = useSelector(selectEmailChangeHistoryLoading);

  return (
    <TableBlock>
      <DataTableLayout tableTitle={'Email Change History'} showPagination={false}>
        <DataTableHeader header={emailHistoryColumns} />
        <NewDataTableBody
          columns={emailHistoryColumns}
          dataList={emailChangeHistoryData}
          loading={isLoading}
        />
      </DataTableLayout>
    </TableBlock>
  );
};

export default EmailChangeHistory;

export const TableBlock = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 10px;
  width: 100%;
`;
