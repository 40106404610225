import * as React from 'react';
import MemberDetailContainer from 'containers/memberdetail/MemberDetailContainer';
import { RouteComponentProps } from 'react-router';

const MemberDetail = ({ match }: RouteComponentProps<{ userId: string }>) => {
  const { userId } = match.params;
  return <MemberDetailContainer userId={userId} />;
};

export default MemberDetail;
