import * as React from 'react';
import Button from '../Button';
import { CircularProgress, Typography } from '@material-ui/core';
import BasicModal from './BasicModal';
import { ReactNode } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onDelete?: () => void;
  content?: ReactNode;
  loading?: boolean;
}

const DeleteModal = ({ open, onDelete, onClose, content, loading }: Props) => {
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      contentDividers={false}
      actionButton={
        <>
          <Button variant={'text'} color={'default'} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={'text'} color={'error'} onClick={onDelete}>
            {loading ? <CircularProgress size={22} thickness={5} color={'secondary'} /> : `Delete`}
          </Button>
        </>
      }
    >
      {typeof content === 'string' ? <Typography variant={'h6'}>{content}</Typography> : content}
    </BasicModal>
  );
};

export default DeleteModal;
