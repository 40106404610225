import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { InvoiceItemType, PaymentStatus, InvoiceItem } from './invoice';
import { BasicListInfo, GetPageParams } from 'types/api';
import { CheckObjectValue } from 'types/common';

export enum DateInterval {
  FROM = 0,
  TO = 1,
}

export enum PaymentMethod {
  CREDITS = 0,
  STRIPE = 1,
}

export interface ValidationParams {
  type: DateInterval;
  newDateValue: MaterialUiPickersDate;
  from: string;
  to: string;
}

export {
  BillType,
  BillDetailType,
  PaymentStatus,
  InvoiceItemType,
  InvoiceDescriptionStatus,
} from './invoice';

export type { InvoiceDescriptionInformation, InvoiceItem, InvoiceItemDetail } from './invoice';

//  billing 플랜 타입 (from CLOSET) -> !! backorder item type 과  value 다름 주의 !!
export enum ClosetPlanType {
  NONE,
  FREE,
  ADVANCED_PLAN_TRIAL,
  ADVANCED_PLAN,
  ENTERPRISE,
  ADVANCED_PLAN_INV,
}

export type GetBillingInvoiceListResponse = BasicListInfo & {
  invoices: BillingInvoice[];
};

export type BillingInvoice = {
  invoiceNo: string;
  groupId: string;
  groupName: string;
  adminEmail: string;
  adminUserId: number;
  planType: ClosetPlanType; //GroupPlanType;
  taxAmount: number;
  totalAmount: number;
  paymentMethod: number;
  paymentStatus: number;
  paymentSuccessedAt: string;
  invoiceItems: BillingInvoiceItem[];
  avalaraDocCode: string | null;
  pgInvoiceId: string | null;
};

export type BillingInvoiceItem = {
  invoiceItemType: InvoiceItemType;
  amount: number;
};

export type GetBillingInvoiceListParams = {
  dateInterval: string[];
  keyword: string;
  payStatus: CheckObjectValue;
  paymentMethod: CheckObjectValue;
} & GetPageParams;

export type GetBillingDetailParams = { invoiceNo: string };

export type PostRefundRequestParams = {
  invoiceNo: string;
  comment: string;
  // https://clo.atlassian.net/wiki/spaces/NX/pages/2035384331/group+invoice+refund
  // CancelSubscription: boolean; // 구독 cancel 여부. stripe subscription cancel 및 group plan downgrade 현재 일단 default 로 true
};

export type BillingAddress = {
  country: string; // 국가코드
  address: string;
  address2: string | null;
  city: string;
  state: string;
  zipCode: string;
  vatNumber: string; // 유럽의 특정회사의 경우에만 값 존재.
};
