import * as React from 'react';
import { Paper, Grid, TableContainer, Table } from '@material-ui/core';
import Pagination from 'components/common/table/datatable/Pagination';
import styled from 'styled-components';
import DataTableTitle from './DataTableTitle';

interface Props {
  // title
  tableTitle?: string;
  customTitleComponent?: React.ReactNode;
  upperButton?: React.ReactNode;
  description?: React.ReactNode;
  summary?: React.ReactNode;
  showPagination?: boolean;
  children?: React.ReactNode;
  stickyHeader?: boolean;
  maxHeight?: string;
  totalPages?: number;
  onChangePage?: (pageIndex: number) => void;
  currentPageIndex?: number;
}

const DataTableLayout = ({
  children,
  tableTitle,
  upperButton,
  description,
  summary,
  showPagination = false,
  stickyHeader = true,
  maxHeight,
  totalPages = 10,
  onChangePage,
  currentPageIndex = 1,
  customTitleComponent,
}: Props) => {
  return (
    <Grid container justify="center">
      <DataTableTitle
        tableTitle={tableTitle}
        upperButton={upperButton}
        description={description}
        customTitleComponent={customTitleComponent}
      />
      <TableWrapper maxHeight={maxHeight}>
        {summary && <TableSummaryWrapper>{summary}</TableSummaryWrapper>}
        <TableContainer component={Paper}>
          <Table size={'small'} stickyHeader={stickyHeader}>
            {children}
          </Table>
        </TableContainer>
      </TableWrapper>
      {showPagination && (
        <Pagination
          count={totalPages}
          mt={3}
          onChange={onChangePage}
          currentPageIndex={currentPageIndex}
        />
      )}
    </Grid>
  );
};

export default DataTableLayout;

const TableWrapper = styled.div<{ maxHeight?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${({ maxHeight = 'initial' }) => maxHeight};
`;

const TableSummaryWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(3)}px;
  width: 100%;
`;
