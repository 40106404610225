import { SearchDateTerms } from 'types/common/searchfilter';

const date = new Date();

export const searchDateTermsLabel = {
  [SearchDateTerms.TODAY]: 'Today',
  [SearchDateTerms['3_DAYS']]: '3 Days',
  [SearchDateTerms['1_WEEK']]: '1 Week',
  [SearchDateTerms['1_MONTH']]: '1 Month',
  [SearchDateTerms['3_MONTHS']]: '3 Months',
  [SearchDateTerms['6_MONTHS']]: '6 Months',
  [SearchDateTerms['2022_YEAR']]: '2022',
  [SearchDateTerms['2023_YEAR']]: '2023',
  [SearchDateTerms['LAST_YEAR']]: `${date.getFullYear() - 1}`,
  [SearchDateTerms['THIS_YEAR']]: `${date.getFullYear()}`,
  [SearchDateTerms['7_DAYS']]: '7 Days',
  [SearchDateTerms['14_DAYS']]: '14 Days',
  [SearchDateTerms['30_DAYS']]: '30 Days',
  [SearchDateTerms['90_DAYS']]: '90 Days',
  [SearchDateTerms['UNTIL_2099']]: '~2099',
  [SearchDateTerms['UNLIMITED']]: 'Unlimited',
  [SearchDateTerms['ALL']]: 'All',
  [SearchDateTerms['1_YEAR']]: '1 Year',
  [SearchDateTerms['2_YEAR']]: '2 Year',
  [SearchDateTerms['3_YEAR']]: '3 Year',
  [SearchDateTerms['TWO_YEARS_AGO']]: `${date.getFullYear() - 2}`,
  [SearchDateTerms['THREE_YEARS_AGO']]: `${date.getFullYear() - 3}`,
};
