import * as React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  tableTitle?: string;
  upperButton?: React.ReactNode;
  description?: React.ReactNode;
  customTitleComponent?: React.ReactNode;
}
const DataTableTitle = ({ tableTitle, upperButton, description, customTitleComponent }: Props) => {
  return (
    <TableTitle>
      <TitleLeftSection>
        {customTitleComponent ?? <Typography variant={'subtitle1'}>{tableTitle}</Typography>}
      </TitleLeftSection>
      <TitleRightSection>
        <UpperButtonSpacing>{upperButton}</UpperButtonSpacing>
        <Description>{description}</Description>
      </TitleRightSection>
    </TableTitle>
  );
};
export default DataTableTitle;

const TableTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: ${props => props.theme.spacing(2)}px;
`;

const TitleLeftSection = styled.div``;

const TitleRightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const UpperButtonSpacing = styled.div``;

const Description = styled.div`
  margin-top: ${props => props.theme.spacing(2)}px;
`;
