import * as React from 'react';
import { ReactElement } from 'react';
import { TypoSubTitle } from '@closet-design-system/core';
import { ContestBadge, ContestLayoutProps } from 'types/marketplace/operation/contest';
import { Nullable } from 'types/common';
import { Title } from 'components/marketplace/operation/contest/preview/ContestAwardsTab';
import AwardThumbnail, {
  AwardThumbnailBox,
} from 'components/marketplace/operation/contest/preview/common/AwardThumbnail';
import { Grid } from '@material-ui/core';

interface Props {
  awardTitle: string;
  awardLayouts: Nullable<ContestLayoutProps[]>;
  badgeId: ContestBadge;
}

const MobileAwardsTabContent = ({ awardTitle, awardLayouts, badgeId }: Props): ReactElement => {
  return (
    <>
      <Title>
        <TypoSubTitle variant={3}>{awardTitle}</TypoSubTitle>
      </Title>
      <Grid container>
        {awardLayouts?.map(({ items }) => {
          if (!items)
            return (
              <Grid item xs={6}>
                <AwardThumbnailBox />
              </Grid>
            );
          return items.map(({ itemId, imagePath, creatorImagePath, creator }) => (
            <Grid item xs={6} key={itemId}>
              <AwardThumbnail
                badgeId={badgeId}
                imagePath={imagePath}
                creatorImagePath={creatorImagePath}
                creator={creator}
              />
            </Grid>
          ));
        })}
      </Grid>
    </>
  );
};

export default MobileAwardsTabContent;
