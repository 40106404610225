import * as React from 'react';
import { ListItemText, MenuItem, Select, FormControl, Checkbox, GridSize } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { ReactNode } from 'react';
import { SelectChangeEvent, SelectOption } from 'types/common/select';
import classNames from 'classnames';
import FilterElementLayout from 'components/common/searchfilter/FilterElementLayout';

interface Props<T = number> {
  label: string;
  values: T[];
  options: SelectOption<T>[];
  onChange: (value: T[]) => void;
  gridSize?: GridSize;
}

const MultipleSelectFilter = <T extends string | number>({
  label,
  values,
  options,
  onChange,
  gridSize = 2,
}: Props<T>) => {
  const renderSelectValue = (value: unknown): ReactNode => {
    const selectedIds = value as T[];
    if (selectedIds.length > 0) {
      return `Filter (${selectedIds.length})`;
    }
    return <Placeholder>-</Placeholder>;
  };

  const handleChange = (event: SelectChangeEvent): void => {
    const checkedValues = event.target.value as T[];
    onChange(checkedValues);
  };

  return (
    <FilterElementLayout label={label} gridSize={gridSize}>
      <FormControl variant={'outlined'} size={'small'} style={{ width: '30%' }} hiddenLabel>
        <SelectWrapper
          value={values}
          renderValue={renderSelectValue}
          onChange={handleChange}
          multiple
          displayEmpty
        >
          {options.map(({ label, value, disabled, divider }) => (
            <MenuItemWrapper
              className={classNames({
                [`divider-${divider}`]: divider,
              })}
              value={value}
              key={value}
              disabled={disabled}
            >
              <Checkbox checked={values.includes(value)} />
              <ListItemText primary={label} />
            </MenuItemWrapper>
          ))}
        </SelectWrapper>
      </FormControl>
    </FilterElementLayout>
  );
};

export default MultipleSelectFilter;

const getMenuDividerStyle = (): FlattenSimpleInterpolation => css`
  content: '';
  display: block;
  width: 90%;
  height: 1px;
  background-color: #b0b0b0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const MenuItemWrapper = styled(MenuItem)`
  position: relative;

  &.divider-top::before {
    ${getMenuDividerStyle()};
    top: 0;
  }

  &.divider-bottom::after {
    ${getMenuDividerStyle()};
    bottom: 0;
  }
`;

const SelectWrapper = styled(Select)`
  color: ${blue['800']};
  background-color: white;
`;

const Placeholder = styled.span`
  color: black;
`;
