import * as React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import {
  FeatureUpdateNoticeTab,
  FeatureUpdateNoticeTableData,
} from 'types/notice/featureUpdateNotice';
import Pagination from 'components/common/table/datatable/Pagination';
import FeatureUpdateNoticeTableRow from './FeatureUpdateNoticeTableRow';
import DeleteModal from 'components/common/modal/DeleteModal';
import { useState } from 'react';
import SpinnerRow from 'components/common/table/datatable/SpinnerRow';

interface Props {
  tab: FeatureUpdateNoticeTab;
  data: FeatureUpdateNoticeTableData;
  onChangePage: (pageIndex: number) => void;
  onDelete: (seq: string) => void;
  deleteLoading: boolean;
  loading: boolean;
}
const FeatureUpdateNoticeTable = ({
  tab,
  onChangePage,
  data,
  onDelete,
  deleteLoading,
  loading,
}: Props) => {
  const { totalPages, items, currentPage } = data;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteModalSeq, setDeleteModalSeq] = useState<string | null>(null);
  const onDeleteModalOpen = (seq: string) => {
    setDeleteModalOpen(true);
    setDeleteModalSeq(seq);
  };

  const onDeleteModal = () => {
    if (deleteModalSeq) onDelete(deleteModalSeq);
    setDeleteModalOpen(false);
  };

  return (
    <Grid container justify="center">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Detail</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <SpinnerRow colspan={4} />}
          {!loading &&
            items?.map((data, index) => (
              <FeatureUpdateNoticeTableRow
                data={data}
                index={(currentPage - 1) * 10 + index + 1}
                key={index}
                tab={tab}
                onDeleteModalOpen={() => onDeleteModalOpen(data.seq)}
              />
            ))}
        </TableBody>
      </Table>
      <Pagination
        count={totalPages || 1}
        mt={3}
        onChange={onChangePage}
        currentPageIndex={currentPage}
      />
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        content={'Are you sure you want to delete this modal?'}
        loading={deleteLoading}
        onDelete={onDeleteModal}
      />
    </Grid>
  );
};

export default FeatureUpdateNoticeTable;
