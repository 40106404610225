import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { mq } from '@closet-design-system/theme';
import {
  BannerTheme,
  LinkOpenTabType,
  LinkType,
  MainBanner as MainBannerData,
} from 'types/marketplace/operation/mainBanner/mainBannerPreview';
import MainObjectLayout from './MainObjectLayout';
import TextLayout from './TextLayout';
import LinkLayout from './LinkLayout';
import { getMediaQueryRangeValues } from 'modules/mainBannerPreview';

interface Props {
  data: MainBannerData;
  showBanner: boolean;
}

const MainBanner = ({ data, showBanner }: Props): ReactElement => {
  const {
    textType,
    title,
    subTitle,
    artworkSource,
    sourceLink,
    layout,
    theme,
    backgroundImagePath,
    buttonText,
    link,
    linkType,
    linkOpenTab,
    mainObjectPath,
  } = data;
  const darkMode = theme === BannerTheme.DARK;
  const openNewTab = linkOpenTab === LinkOpenTabType.NEW;
  const isBannerLink = linkType === LinkType.BANNER;

  const children: ReactNode = (
    <Background backgroundImage={backgroundImagePath}>
      <InnerContentsWrapper show={showBanner}>
        <MainObjectLayout layoutType={layout} mainObjectPath={mainObjectPath} />
        <TextLayout
          darkMode={darkMode}
          textType={textType}
          title={title || ''}
          subtitle={subTitle || ''}
          artworkSource={artworkSource || ''}
          sourceLink={sourceLink}
          layoutType={layout}
          buttonText={buttonText || ''}
          buttonUrl={link}
          openNewTab={openNewTab}
          hasLinkButton={linkType === LinkType.BUTTON}
        />
      </InnerContentsWrapper>
    </Background>
  );

  if (isBannerLink) {
    return (
      <LinkLayout url={link} openNewTab={openNewTab}>
        {children}
      </LinkLayout>
    );
  }

  return <>{children}</>;
};

export default MainBanner;

const Background = styled.div<{ backgroundImage: string }>`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.GRAY_850};
  background-image: url(${props => props.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const bannerInnerContentsWidth = mq()({
  width: getMediaQueryRangeValues<string>({ s: '662px', m: '934px', l: '1400px' }),
});

const InnerContentsWrapper = styled.div<{ show: boolean }>`
  ${bannerInnerContentsWidth};
  height: 100%;
  display: ${props => (props.show ? 'inline-flex' : 'none')};
  align-items: center;
  z-index: 2;
  position: relative;
`;
