import * as React from 'react';
import { ReactElement } from 'react';
import ConnectGlobalStyle from 'styles/ConnectGlobalStyle';
import styled, { ThemeProvider } from 'styled-components';
import { theme as connectTheme } from '@closet-design-system/theme';
import ContestPreviewContainer from 'containers/marketplace/operation/contest/preview/ContestPreviewContainer';

const ContestPreview = (): ReactElement => {
  return (
    <ThemeProvider theme={connectTheme}>
      <ConnectGlobalStyle />
      <Wrapper>
        <ContestPreviewContainer />
      </Wrapper>
    </ThemeProvider>
  );
};

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.GRAY_850};
`;

export default ContestPreview;
