import * as React from 'react';
import { FeatureUpdateNoticeModalFormData } from 'types/notice/featureUpdateNotice';
import styled from 'styled-components';
import useImagePreview from 'hooks/useImagePreview';

interface Props {
  data: FeatureUpdateNoticeModalFormData;
}
const ImageBackgroundModalContent = ({ data }: Props) => {
  const { imageUrl } = data;
  const imageDataUrl = useImagePreview(imageUrl);

  return <BackgroundImage imageUrl={imageDataUrl ?? ''} />;
};

export default ImageBackgroundModalContent;

const BackgroundImage = styled.div<{ imageUrl: string }>`
  width: 700px;
  height: 390px;
  ${({ imageUrl }) => `background: url('${imageUrl}') no-repeat center;`}
  background-size: contain;
`;
