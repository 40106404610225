import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import capitalize from 'lodash/capitalize';

import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import classNames from 'classnames';
import styled from 'styled-components';

export type ColorTypes =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'success'
  | 'warning'
  | 'default'
  | 'inherit'
  | 'info';

export type ButtonProps = { color?: ColorTypes; isLoading?: boolean } & Omit<
  MuiButtonProps,
  'color'
>;

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    outlinedSuccess: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
    outlinedError: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
    outlinedWarning: {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
    },
    outlinedInfo: {
      borderColor: theme.palette.info.main,
      color: theme.palette.info.main,
    },
    containedSuccess: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    },
    containedError: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    containedWarning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.warning.dark,
      },
    },
    containedInfo: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.info.dark,
      },
    },
    textSuccess: {
      color: theme.palette.success.main,
    },
    textError: {
      color: theme.palette.error.main,
    },
    textWarning: {
      color: theme.palette.warning.main,
    },
    textInfo: {
      color: theme.palette.info.main,
    },
  }),
);

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  color = 'inherit',
  disabled,
  isLoading,
  ...props
}) => {
  const classes = useStyles();
  const buttonClassName = classes?.[`${props.variant}${capitalize(color)}`];

  const colorProp =
    ['default', 'inherit', 'primary', 'secondary'].indexOf(color) > -1 // Material UI 에서 제공하는 color는 그대로 props로 전달하고, 그 외에는 props 대신 css style로 처리한다.
      ? (color as 'default' | 'inherit' | 'primary' | 'secondary')
      : undefined;
  const buttonDisabled = disabled || isLoading;

  return (
    <MuiButton
      {...props}
      color={colorProp}
      className={classNames(className, buttonClassName)}
      disabled={buttonDisabled}
    >
      {isLoading ? (
        <Typography variant={'button'} align={'center'}>
          <ButtonCircularProgress size={18} />
        </Typography>
      ) : typeof children === 'string' ? (
        <Typography variant={'button'}>{children}</Typography>
      ) : (
        children
      )}
    </MuiButton>
  );
};

const ButtonCircularProgress = styled(CircularProgress)`
  vertical-align: sub;
`;

Button.displayName = 'Button';

export default Button;
